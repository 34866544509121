/* eslint-disable react/no-danger */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Notiflix from 'notiflix';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import api from '../services/api/api';
import { useAuth } from './auth';

import Modal2 from '../components/Modal2';
import Button from '../components/Button';

import imgScroll from '../assets/images/scrollToBottom.png';

interface ContainerCheckProps {
  themeColor?: string;
}

const ModalContainer = styled.div`
  h1 {
    font-size: 1.7rem;
    font-family: 'Unimed-Slab-Bold';
    color: ${(props) => props.theme.colors.text.primary};
    margin-bottom: 56px;
    text-align: left;
    padding: 0 20px;
    @media (max-width: 768px) {
      font-size: 1.3rem;
      margin-bottom: 32px;
    }
  }
  > div {
    max-height: calc(100vh - 320px);
    overflow-y: auto;
    @media (max-width: 768px) {
      max-height: calc(100vh - 350px);
    }
    font-size: 1rem;
    padding: 0 20px 15px 0;
    margin-bottom: 30px;
    text-align: left;
    font-family: 'Unimed-Sans-Book';
    color: ${(props) => props.theme.colors.text.primary};
  }
  p {
    font-size: 1rem;
    text-align: left;
    font-family: 'Unimed-Sans-Book';
    color: ${(props) => props.theme.colors.text.primary};
  }
  .btn-reject-term {
    margin-right: 10px;
  }
  .removeDisplay {
    display: none !important;
  }
`;

const ContainerCheck = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  span {
    margin: 10px 0 15px 0;
  }
`;

const ContentCheck = styled.div<ContainerCheckProps>`
  border: 1px solid #c4cbcf;
  box-sizing: border-box;
  border-radius: 3px;
  min-width: 20px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  position: relative;
  margin: 10px 0 15px 0;
  input {
    border: none;
    outline: none;
    appearance: none;
    position: relative;

    width: 100%;
    height: 100%;
    transition: background 300ms;
    &:checked {
      background: ${(props) => props.theme.colors.palet.institutional3};
      ${(props) =>
        props.themeColor &&
        css`
          background: ${props.themeColor};
        `}
    }

    &:checked::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.293056 5.72123C-0.600029 4.68471 0.750382 3.09982 1.64331 4.13634L4.3843 7.30374L10.3502 0.340876C11.2433 -0.701482 12.601 0.883217 11.7079 1.92558L5.06654 9.67267C4.69361 10.1081 4.08874 10.1094 3.71441 9.67485L0.293056 5.72123Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }
  & + div {
    margin-top: 8px;
  }
  span {
    display: block;
    width: 100%;
    color: black;
    font-size: 12px;
  }
`;

const ButtonScroll = styled.button`
  position: absolute;
  width: 50px;
  height: 50px;
  right: 50px;
  bottom: 50px;
  border: 1px solid #e4e4e4;
  background: #fff;
  border-radius: 100%;
  img {
    width: 20px;
  }
`;

export interface ReleaseProps {
  emit: string;
  id: number;
  read: boolean;
  // eslint-disable-next-line camelcase
  reading_date: string | null;
  requiredReading: boolean;
  text: string;
  title: string;
}

interface ReleasesProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: ReleaseProps[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  importants: any[];
}

export interface Term {
  acceptanceTerm: string;
  acceptanceTermId: number;
  acceptanceTermTitle: string;
  changeable: string;
  confirmationDate: string | null;
  ieExibirLogin: string;
  initialsSituation: string;
  insuredName: string;
  registerAcceptanceTermId: string | null;
  required: string;
  revogationDate: string | null;
  termUserUnimedId: number;
  ieStatus: string;
  nmSegurado: string;
}
interface TermsProps {
  items: Term[];
  importants: {
    acceptanceTermId: number;
    registerAcceptanceTermId: string | null;
    termUserUnimedId: number;
  }[];
}

interface ContextProps {
  releases: ReleasesProps;
  checkRead(id: number, notiflixId?: string): Promise<void>;
  decrementMemo(id: number): void;
}

const releasesContext = createContext<ContextProps>({} as ContextProps);

export const ReleasesProvider: React.FC = ({ children }) => {
  const { user, isAuthendicated } = useAuth();
  const [releases, setReleases] = useState({} as ReleasesProps);
  const [terms, setTerms] = useState({} as TermsProps);

  const inputRef = useRef<HTMLInputElement>(null);

  const decrementMemo = useCallback((id: number) => {
    setReleases((prev) => {
      const newValues = {
        items: [
          ...prev.items.map((i) => {
            if (i.id === id) {
              // eslint-disable-next-line no-param-reassign
              i.read = true;
            }
            return i;
          }),
        ],
        importants: prev.importants.filter((i) => i !== id),
      };
      return newValues;
    });
  }, []);

  const checkReleaseRead = useCallback(
    async (id: number, notiflixId = '') => {
      const itemChange = releases.items.find((i) => i.id === id);
      if (itemChange && itemChange.read) {
        return;
      }

      try {
        if (notiflixId) {
          Notiflix.Block.circle(`.${notiflixId}`);
        }
        const { data: dataMyIp } = await axios.get(
          'https://api.ipify.org/?format=json',
        );

        await api.put(`/memo/set-read`, {
          memo_id: id,
          ip: dataMyIp.ip,
        });

        setReleases((prev) => {
          const newValues = {
            items: [
              ...prev.items.map((i) => {
                if (i.id === id) {
                  // eslint-disable-next-line no-param-reassign
                  i.read = true;
                }
                return i;
              }),
            ],
            importants: prev.importants.filter((i) => i !== id),
          };
          return newValues;
        });
      } catch (err) {
        Notiflix.Notify.failure(
          'Não conseguimos informar ao servidor que esse documento foi lido.',
        );
      } finally {
        if (notiflixId) {
          Notiflix.Block.remove(`.${notiflixId}`);
        }
      }
    },
    [releases],
  );

  const checkTermRead = useCallback(
    async (row: {
      acceptanceTermId: number;
      registerAcceptanceTermId: string | null;
      termUserUnimedId: number;
    }) => {
      try {
        const status = 'C'; // "C" (accepted) | "R" (revogued)
        await api.put(`/beneficiary/agreement-acceptance-terms`, {
          termUserUnimedId: row.termUserUnimedId,
          acceptanceTermId: row.acceptanceTermId,
          registerAcceptanceTermId: row.registerAcceptanceTermId ?? '',
          status,
        });

        setTerms((prev) => ({
          ...prev,
          importants: [
            ...prev.importants.filter((item) => {
              if (
                item.acceptanceTermId === row.acceptanceTermId &&
                item.registerAcceptanceTermId ===
                  row.registerAcceptanceTermId &&
                item.termUserUnimedId === row.termUserUnimedId
              ) {
                return false;
              }
              return true;
            }),
          ],
        }));
      } catch (err) {
        Notiflix.Notify.failure(
          'Não conseguimos informar ao servidor que esse documento foi lido.',
        );
      }
    },
    [],
  );

  const handleRejectTerm = useCallback(
    async (row: {
      acceptanceTermId: number;
      registerAcceptanceTermId: string | null;
      termUserUnimedId: number;
    }) => {
      Modal2.Close();
      try {
        const status = 'R'; // "C" (accepted) | "R" (revogued)
        await api.put(`/beneficiary/agreement-acceptance-terms`, {
          termUserUnimedId: row.termUserUnimedId,
          acceptanceTermId: row.acceptanceTermId,
          registerAcceptanceTermId: row.registerAcceptanceTermId ?? '',
          status,
        });

        setTerms((prev) => ({
          ...prev,
          importants: [
            ...prev.importants.filter((item) => {
              if (
                item.acceptanceTermId === row.acceptanceTermId &&
                item.registerAcceptanceTermId ===
                  row.registerAcceptanceTermId &&
                item.termUserUnimedId === row.termUserUnimedId
              ) {
                return false;
              }
              return true;
            }),
          ],
        }));
      } catch (err) {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos recusar esse termo no momento. Tente novamente mais tarde.',
        );
      }
    },
    [],
  );

  const closeModalRelease = useCallback(
    async (id: number) => {
      // console.log('fechando modal id=>', id);
      Modal2.Close();
      await checkReleaseRead(id);
    },
    [checkReleaseRead],
  );

  const closeModalTerm = useCallback(
    async (row: {
      acceptanceTermId: number;
      registerAcceptanceTermId: string | null;
      termUserUnimedId: number;
    }) => {
      // console.log('fechando modal id=>', id);
      Modal2.Close();
      await checkTermRead(row);
    },
    [checkTermRead],
  );

  const openImportantRelease = useCallback(
    (id: number) => {
      if (!user.name) return;
      const release = releases.items.find((item) => item.id === id);
      if (release) {
        Modal2.Generic({
          closable: false,
          children: (
            <ModalContainer>
              <h1>{release.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: release.text }} />
              <Button
                onClick={() => closeModalRelease(id)}
                modal
                autoWidth
                float="right"
              >
                Lido
              </Button>
            </ModalContainer>
          ),
        });
      } else {
        closeModalRelease(id);
      }
    },
    [releases, closeModalRelease, user],
  );

  const getReleasesAndTerms = useCallback(async () => {
    try {
      const listMemo = await api.get('/memo/list-memo');
      const listTerms =
        user.profile === 1
          ? await api.get(
              `/beneficiary/list-acceptance-terms?userUnimedId=${user.userUnimedId}`,
            )
          : { data: { content: [] } };

      const allMemos = listMemo.data.content as ReleaseProps[];
      const allTerms = listTerms.data.content as Term[];

      const importantsMemoNotReaded =
        allMemos
          ?.filter((memo) => memo.requiredReading && !memo.read)
          .map((memo) => memo.id) || [];

      setReleases({ items: allMemos, importants: importantsMemoNotReaded });

      setTerms({
        items: allTerms,
        importants: allTerms.reduce((acc, act: Term) => {
          if (act.ieExibirLogin === 'S') {
            acc.push({
              acceptanceTermId: act.acceptanceTermId,
              registerAcceptanceTermId: act.registerAcceptanceTermId,
              termUserUnimedId: act.termUserUnimedId,
            });
          }
          return acc;
        }, [] as { acceptanceTermId: number; registerAcceptanceTermId: string | null; termUserUnimedId: number }[]),
      });
    } catch (err) {
      // console.log(err);
    }
  }, [user]);

  useEffect(() => {
    if (isAuthendicated) {
      getReleasesAndTerms();
    }
  }, [getReleasesAndTerms, isAuthendicated]);

  const ativar = useCallback(() => {
    const button = document.querySelector('#acceptTerms');
    button?.toggleAttribute('disabled');
  }, []);

  const scrollToBottom = useCallback(() => {
    inputRef.current?.scrollIntoView({ behavior: 'smooth' });
    // if (inputRef.current) {
    //   inputRef.current.checked = true;
    // }

    // ativar();
    const button = document.querySelector('#scrollToBottom');
    if (button) {
      button.setAttribute('disabled', '');
      button.classList.add('removeDisplay');
    }
  }, []);

  const getTermContent = useCallback(
    async (row: {
      acceptanceTermId: number;
      registerAcceptanceTermId: string | null;
      termUserUnimedId: number;
    }) => {
      setTimeout(() => {
        const imgElement1 = document.querySelector(
          'img[alt="Narrativa estrutura de tópicos"]',
        );
        const imgElement2 = document.querySelector(
          'img[alt="Interface do Usuário/Experiência do Usuário estrutura de tópicos"]',
        );
        const imgElement3 = document.querySelector(
          'img[alt="Rede social estrutura de tópicos"]',
        );
        const imgElement4 = document.querySelector(
          'img[alt="Nenhum sinal com preenchimento sólido"]',
        );
        const imgElement5 = document.querySelector(
          'img[alt="Relógio estrutura de tópicos"]',
        );

        if (imgElement1) {
          imgElement1.classList.add('removeDisplay');
        }
        if (imgElement2) {
          imgElement2.classList.add('removeDisplay');
        }
        if (imgElement3) {
          imgElement3.classList.add('removeDisplay');
        }
        if (imgElement4) {
          imgElement4.classList.add('removeDisplay');
        }
        if (imgElement5) {
          imgElement5.classList.add('removeDisplay');
        }
      }, 200);
      try {
        const termFinded = terms.items.find(
          (item) =>
            item.acceptanceTermId === row.acceptanceTermId &&
            item.registerAcceptanceTermId === row.registerAcceptanceTermId &&
            item.termUserUnimedId === row.termUserUnimedId,
        );
        Modal2.Generic({
          closable: false,
          children: (
            <ModalContainer>
              <h1
                dangerouslySetInnerHTML={{
                  __html: termFinded?.acceptanceTermTitle ?? '<></>',
                }}
              />
              <div>
                <span
                  dangerouslySetInnerHTML={{
                    __html: termFinded?.acceptanceTerm ?? '<p></p>',
                  }}
                />
                <ContainerCheck>
                  <ContentCheck>
                    <input
                      type="checkbox"
                      ref={inputRef}
                      name="concordo"
                      id="checkboxTerms"
                      onChange={() => ativar()}
                    />
                  </ContentCheck>
                  <span>Li e concordo</span>
                </ContainerCheck>
                {termFinded?.required !== 'S' && (
                  <Button
                    onClick={() => {
                      setTerms((prev) => ({
                        ...prev,
                        importants: [
                          ...prev.importants.filter((item) => {
                            if (
                              item.acceptanceTermId === row.acceptanceTermId &&
                              item.registerAcceptanceTermId ===
                                row.registerAcceptanceTermId &&
                              item.termUserUnimedId === row.termUserUnimedId
                            ) {
                              return false;
                            }
                            return true;
                          }),
                        ],
                      }));
                      Modal2.Close();
                    }}
                    modal
                    outlined
                    autoWidth
                    float="left"
                  >
                    Ver depois
                  </Button>
                )}
                <Button
                  onClick={() => closeModalTerm(row)}
                  modal
                  autoWidth
                  float="right"
                  id="acceptTerms"
                >
                  Aceito
                </Button>
                <Button
                  onClick={() => handleRejectTerm(row)}
                  modal
                  secondary
                  className="btn-reject-term"
                  autoWidth
                  float="right"
                >
                  Recusar
                </Button>
              </div>
              <ButtonScroll
                type="button"
                onClick={scrollToBottom}
                id="scrollToBottom"
              >
                <img src={imgScroll} alt="" />
              </ButtonScroll>
            </ModalContainer>
          ),
        });
        setTimeout(() => {
          const btn = document.querySelector<HTMLButtonElement>('#acceptTerms');
          if (btn) {
            btn.disabled = true;
          }
        }, 1000);
      } catch (err) {
        Notiflix.Notify.failure(
          'Ops..Não conseguimos buscar um termo importante para você. Tente novamente mais tarde',
        );
      }
    },
    [ativar, closeModalTerm, handleRejectTerm, scrollToBottom, terms.items],
  );

  useEffect(() => {
    if (terms?.importants?.length > 0) {
      getTermContent(terms.importants[0]);
    }
    // first open terms
    else if (releases?.importants?.length > 0) {
      const id = releases.importants[0];
      openImportantRelease(id);
    }
  }, [releases, terms, openImportantRelease, getTermContent]);

  return (
    <releasesContext.Provider
      value={{ releases, checkRead: checkReleaseRead, decrementMemo }}
    >
      {children}
    </releasesContext.Provider>
  );
};

export function useReleases(): ContextProps {
  const context = useContext(releasesContext);

  if (!context) {
    throw new Error('useReleases must be used within an ReleasesProvider');
  }

  return context;
}
