import styled, { css } from 'styled-components';
import FormWrapperElement from '../../components/FormWrapper';

export const Title = styled.h1`
  font-family: 'Unimed-Slab-Bold';
  font-size: 2.55rem;
  line-height: 2.4rem;
  grid-column: 2;

  text-align: center;
  letter-spacing: -1.19px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional};
  padding: 30px 0;
  @media (max-width: 1200px) {
    font-size: 2.25rem;
    line-height: 2.1rem;
  }
  @media (max-width: 768px) {
    font-size: 1.7rem;
    line-height: 1.5rem;
    padding: 30px;
    grid-column: 1;
  }
`;

export const TitleIntern = styled.h2`
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 1.6rem;
  line-height: 1.6rem;

  text-align: left;
  letter-spacing: -1.19px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional};
  margin-bottom: 8px;
  @media (max-width: 768px) {
    font-family: 'Unimed-Sans-Bold';
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
  }
`;

export const Container = styled.div`
  margin-bottom: 30px;

  @media (min-width: 769px) and (max-width: 1199px) {
    grid-column: 1/11;
    padding: 0 30px;
  }
  @media (min-width: 1200px) {
    grid-column: 2/10;
  }

  > form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    h2 {
      font-family: 'Unimed-Sans-SemiBold';
      font-size: 1.6rem;
      line-height: 1.6rem;
      width: 100%;

      text-align: left;
      letter-spacing: -1.19px;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.palet.institutional};
      margin-bottom: 8px;
      @media (max-width: 768px) {
        font-family: 'Unimed-Sans-Bold';
        font-size: 1rem;
        line-height: 1rem;
        text-align: center;
      }
    }
    div.normalize-text {
      width: 100%;
      font-family: 'Unimed-Sans-Book';
      font-size: 1rem;
      line-height: 1rem;
      color: ${(props) => props.theme.colors.text.grayishToWhite};
      p,
      span {
        font-family: 'Unimed-Sans-Book';
        font-size: 1rem;
        line-height: 1rem;
        color: ${(props) => props.theme.colors.text.grayishToWhite};
      }
    }

    > button {
      text-transform: uppercase;
    }
  }
  @media (max-width: 768px) {
    > form {
      > button {
        width: 100%;
      }
    }
  }
`;

export const FormWrapper = styled(FormWrapperElement)`
  grid-column: 2;
  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

interface StepContainerProps {
  defineHeight?: string;
}

export const Steps = styled.section<StepContainerProps>`
  position: relative;
  grid-column: 2/10;
  /* margin-bottom: 30px; */
  ${(props) =>
    props.defineHeight &&
    css`
      height: ${props.defineHeight};
    `}
  @media(max-width: 768px) {
    grid-column: 1;
  }
`;

interface StepsProps {
  show: boolean;
}

const initialStateSteps = css`
  left: 0;
  top: 0;
  width: 100%;
  position: absolute;
  transform: translateX(-20px);
  visibility: hidden;
  opacity: 0;
  transition: 300ms;
  height: 0;
  overflow: hidden;
`;

const showSteps = css`
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
  height: initial;
  overflow: initial;
`;

export const Step1 = styled.section<StepsProps>`
  ${initialStateSteps}
  ${(props) => props.show && showSteps}
  >form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    h2 {
      font-family: 'Unimed-Sans-SemiBold';
      font-size: 1.6rem;
      line-height: 1.6rem;
      width: 100%;

      text-align: left;
      letter-spacing: -1.19px;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.palet.institutional};
      margin-bottom: 8px;
      @media (max-width: 768px) {
        font-family: 'Unimed-Sans-Bold';
        font-size: 1rem;
        line-height: 1rem;
        text-align: center;
      }
    }
    div.normalize-text {
      width: 100%;
      font-family: 'Unimed-Sans-Book';
      font-size: 1rem;
      line-height: 1rem;
      color: ${(props) => props.theme.colors.text.grayishToWhite};
      p,
      span {
        font-family: 'Unimed-Sans-Book';
        font-size: 1rem;
        line-height: 1rem;
        color: ${(props) => props.theme.colors.text.grayishToWhite};
      }
    }

    > button {
      text-transform: uppercase;
    }
  }
  @media (max-width: 768px) {
    margin: 0 30px;
    width: calc(100% - 60px);

    > form {
      > button {
        width: 100%;
      }
    }
  }
`;

export const Step2 = styled.section<StepsProps>`
  ${initialStateSteps}
  ${(props) => props.show && showSteps}
  >form {
    display: flex;
    flex-wrap: wrap;
    > button {
      width: 150px;
      & + button {
        margin-left: auto;
      }
    }
    @media (max-width: 768px) {
      > div {
        &:nth-child(2),
        &:nth-child(3) {
          flex: 1 1 calc(50% - 4px);
        }
        &:nth-child(9),
        &:nth-child(10) {
          flex: 1 1;
          width: auto;
          max-width: calc(50% - 4px);
        }
      }
    }
  }
`;

export const CheckboxGroup = styled.div`
  flex: 1 1 100%;
`;

export const Step3 = styled.section<StepsProps>`
  ${initialStateSteps}
  ${(props) => props.show && showSteps}
  form {
    display: flex;
    flex-wrap: wrap;
    & + form {
      margin-top: 24px;
    }
    &:nth-of-type(1) {
      > button {
        width: 150px;
        & + button {
          margin-left: auto;
        }
      }
    }
  }
  > h2 {
    margin-top: 24px;
  }
`;
