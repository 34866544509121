import React, { memo, useCallback } from 'react';
import { Carousel } from 'primereact/carousel';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';

import { Container, Acessibility } from './styled';

interface Props {
  className?: string;
  imgs: BannerProps[];
  autoplayInterval: number;
}
export interface BannerProps {
  alt: string;
  desktop: string;
  id: string;
  link: string;
  mobile: string;
}

const CarouselImage: React.FC<Props> = ({
  imgs,
  className,
  autoplayInterval,
}) => {
  const productTemplate = useCallback((item: BannerProps) => {
    return (
      <picture>
        <source media="(max-width:748px)" srcSet={item.mobile} />
        <img src={item.desktop} alt={item.alt} />
        {item.link && (
          <Acessibility
            onClick={() => window.open(item.link, '_blank')}
            onKeyPress={() => window.open(item.link, '_blank')}
            role="presentation"
          />
        )}
      </picture>
    );
  }, []);

  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 1,
      numScroll: 1,
    },

    {
      breakpoint: '480px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  if (imgs) {
    return (
      <Container className={className}>
        <Carousel
          value={imgs}
          responsiveOptions={responsiveOptions}
          itemTemplate={productTemplate}
          autoplayInterval={imgs && imgs.length > 1 ? autoplayInterval : 600000}
          circular={imgs && imgs.length > 1}
        />
      </Container>
    );
  }
  return null;
};

export default memo(CarouselImage);
