/* eslint-disable max-len */
import React, { memo } from 'react';

const Sucess: React.FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9994 15.9739V18.9847C21.0004 19.2647 20.9431 19.5407 20.8315 19.7966C20.7188 20.0535 20.5539 20.2833 20.3478 20.472C20.1416 20.6607 19.8983 20.8042 19.6328 20.8945C19.3673 20.9839 19.0858 21.017 18.8072 20.9919C15.713 20.6567 12.7404 19.6009 10.1289 17.9108C7.69939 16.3703 5.63892 14.3139 4.09533 11.8892C2.39588 9.27078 1.33798 6.29009 1.00815 3.18795C0.983006 2.90996 1.01621 2.63096 1.1057 2.36701C1.1942 2.10206 1.338 1.86017 1.52604 1.65443C1.71409 1.44869 1.94334 1.28415 2.19876 1.17174C2.45418 1.05834 2.72972 1.00011 3.00927 1.00011H6.02606C6.51377 0.995088 6.98741 1.16772 7.35747 1.48586C7.72753 1.803 7.96887 2.24354 8.03725 2.72627C8.16496 3.68973 8.40028 4.63619 8.74118 5.54646C8.87693 5.90575 8.90611 6.29612 8.82566 6.67147C8.74522 7.04682 8.55915 7.39107 8.28865 7.66405L7.01155 8.93858C8.44352 11.4516 10.5271 13.5311 13.0451 14.9602L14.3222 13.6857C14.5957 13.4157 14.9407 13.23 15.3168 13.1497C15.6929 13.0695 16.084 13.0985 16.444 13.234C17.3561 13.5743 18.3044 13.8091 19.2698 13.9366C19.7585 14.0058 20.204 14.2507 20.5238 14.627C20.8425 15.0024 21.0115 15.4821 20.9994 15.9739Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default memo(Sucess);
