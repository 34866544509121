/* eslint-disable max-len */
import styled, { css } from 'styled-components';
import Button from '../../components/Button';

import DatePickerComponent from '../../components/DatePicker';

export const Container = styled.div`
  margin-bottom: 30px;

  @media (min-width: 769px) and (max-width: 1400px) {
    grid-column: 1/11;
    padding: 0 30px;
  }
  @media (min-width: 1400px) {
    grid-column: 2/10;
  }
  @media (max-width: 768) {
    grid-column: 1;
  }
`;
export const Title = styled.h1`
  font-family: 'Unimed-Slab-Bold';
  font-size: 2.55rem;
  line-height: 2.4rem;

  text-align: center;
  letter-spacing: -1.19px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional8};
  padding: 30px 0;
  @media (max-width: 1200px) {
    font-size: 2.25rem;
    line-height: 2.1rem;
  }
  @media (max-width: 768px) {
    font-size: 1.7rem;
    line-height: 1.5rem;
    padding: 30px;
  }
`;

export const DatePicker = styled(DatePickerComponent)``;

export const TableContainer = styled.div`
  filter: drop-shadow(0px 0px 10px rgb(196, 203, 207, 0.4));
  ${(props) =>
    props.theme.title === 'dark' &&
    css`
      filter: drop-shadow(0px 0px 10px rgb(24, 24, 24, 0.6));
    `}
  .positionRight {
    text-align: right !important;
    padding-right: 1vw !important;
  }

  #authorization .p-datatable-wrapper > table {
    /* width: calc(100% - 16px);
    margin-left: 8px; */
    tbody {
      tr {
        &.p-row-expanded {
          & > td > div {
            margin: 0 10px;
            & > div > div.p-datatable-footer {
              display: none;
            }
          }
        }
      }
    }
  }

  #authorization .p-datatable-wrapper table tbody .p-row-expanded {
    background: transparent;
    padding: 24px;
    @media (max-width: 768px) {
      background: ${(props) => props.theme.colors.palet.institutional11};
    }

    tr[role='row'] {
      padding: 0px;
      max-width: 100%;
    }

    & > td {
      padding: 0;

      &
        .p-datatable
        .p-datatable-wrapper
        table
        thead.p-datatable-thead
        tr
        th:first-child {
        border-radius: 0;
      }
      &
        .p-datatable
        .p-datatable-wrapper
        table
        thead.p-datatable-thead
        tr
        th:last-child {
        border-radius: 0;
      }
    }
  }
  @media (max-width: 768px) {
    #authorization .p-datatable-wrapper table {
      & thead {
        display: none;
      }
      & tbody .p-row-expanded {
        display: block !important;
        padding: 0 !important;
        & > td > div {
          margin: 0 !important;
        }
      }
    }
  }
`;

export const ModalDetail = styled.div`
  font-family: 'Unimed-Sans-Book';
  min-width: 280px;
`;

export const ModalDetailRow = styled.div`
  padding: 0px 30px;
  & + div {
    margin-top: 24px;
  }
`;

export const ModalDetailTitle = styled.div`
  font-size: 0.85rem;
  text-align: left;
`;

export const ModalDetailValue = styled.div`
  font-size: 1.55rem;
  text-align: left;
`;

export const ModalObservation = styled.div`
  font-family: 'Unimed-Sans-Book';
`;

export const ModalObservationTitle = styled.div`
  font-family: 'Unimed-Slab-Bold';
  font-size: 1.55rem;
  text-align: left;
  padding: 0 30px;
  color: ${(props) => props.theme.colors.text.primary};
`;

export const ModalObservationContent = styled.div`
  font-size: 1rem;
  text-align: left;
  margin-top: 56px;
  padding: 0 30px 30px;
  color: ${(props) => props.theme.colors.text.primary};
`;

interface StepContainerProps {
  defineHeight?: string;
}

export const Steps = styled.section<StepContainerProps>`
  position: relative;
  grid-column: 2/10;
  margin-bottom: 30px;
  ${(props) =>
    props.defineHeight &&
    css`
      height: ${props.defineHeight};
    `}
  @media(max-width: 768px) {
    grid-column: 1;
  }
`;

interface StepsProps {
  show: boolean;
}

const initialStateSteps = css`
  left: 0;
  top: 0;
  width: 100%;
  position: absolute;
  transform: translateX(-20px);
  visibility: hidden;
  opacity: 0;
  transition: 300ms;
  height: 0;
  overflow: hidden;
`;
const showSteps = css`
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
  height: initial;
  overflow: initial;
`;

export const Step1 = styled.section<StepsProps>`
  ${initialStateSteps}
  position: initial;
  display: none;

  ${(props) => props.show && showSteps}
  ${(props) =>
    props.show &&
    css`
      display: block;
    `}
  .table-home {
    .p-datatable-tbody tr[role='row'].p-row-expanded {
      background: transparent;
      @media (max-width: 768px) {
        display: flex;
        padding: 0px;
        > td {
          padding: 0px;
          thead {
            display: none;
          }
          > div > div.p-datatable.p-component > div.p-datatable-wrapper {
            position: relative;
            &:after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              width: 40px;
              height: 100%;
              background: ${(props) => props.theme.colors.palet.institutional4};
            }
          }
        }
      }
    }
  }
  form {
    @media (max-width: 768px) {
      > div {
        &:nth-child(6),
        &:nth-child(7) {
          width: auto;
          flex: 1 1 0;
        }
      }
    }
  }
`;

export const ReportsContainer = styled.div`
  margin-bottom: 24px;
  form {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const CheckboxGroup = styled.div`
  display: flex;
  row-gap: 8px;
  column-gap: 16px;
`;

export const ButtonsContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  @media (min-width: 769px) {
    margin-left: auto;
    display: flex;
  }
`;

export const ReportsButton = styled(Button)`
  margin-left: auto;
  min-width: 150px;
  margin-top: auto;
  text-transform: uppercase;

  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 769px) {
    margin-left: 20px;
  }
`;

export const ClearButton = styled(Button)`
  margin-left: auto;
  min-width: 150px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const Step2 = styled.section<StepsProps>`
  ${initialStateSteps}

  ${(props) => props.show && showSteps}

  form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    /* > div {
      flex: 1;
    } */

    @media (max-width: 768px) {
      > div {
        &:nth-of-type(1) {
          width: 100%;
          flex: 1 0 100%;
        }
        &:nth-of-type(10),
        &:nth-of-type(11) {
          width: 50%;
          flex: 1 1 0;
        }
      }
      > button {
        flex: 1;
      }
    }
  }

  .table-intern {
    .p-datatable-tbody tr[role='row'].p-row-expanded {
      background: transparent;
      @media (max-width: 768px) {
        display: flex;
        padding: 0px;
        > td {
          padding: 0px;
          thead {
            display: none;
          }
          > div > div.p-datatable.p-component > div.p-datatable-wrapper {
            position: relative;
            &:after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              width: 40px;
              height: 100%;
              background: ${(props) => props.theme.colors.palet.institutional4};
            }
          }
        }
      }
    }
  }
`;
export const Step3 = styled.section<StepsProps>`
  ${initialStateSteps}

  ${(props) => props.show && showSteps}
`;

export const ButtonsStep2 = styled.div`
  flex: 1 0 100% !important;
  margin-top: 16px;
  display: flex;
  > button {
    &:nth-child(1) {
      margin-right: auto;
    }
    & + button {
      margin-left: 8px;
    }
  }
`;

export const TableWrapper = styled.div`
  margin-top: 24px;
`;

export const OcurrenceDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  margin-bottom: 5px;

  background-color: #f4f6f6;
  border-radius: 5px;
  border-bottom: #eaedef 1px solid;

  &:last-child {
    margin-bottom: 30px;
  }

  > p {
    word-break: break-all !important;
    margin: 5px 0;
    font-size: 1rem;
    font-weight: 200;
    white-space: normal;
  }

  @media (max-width: 768px) {
    padding: 30px;
  }
`;
