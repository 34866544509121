/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';
import { v4 } from 'uuid';
import Button from '../../components/Button';
import FormWrapper from '../../components/FormWrapper';
import Input from '../../components/Input';
import Modal2 from '../../components/Modal2';
import PageWrapper from '../../components/PageWrapper';
import SelectUnform from '../../components/SelectUnform';
import { BeneficiaryUser, UserCommon, useAuth } from '../../hooks/auth';
import api from '../../services/api/api';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';
import { ButtonWrapper, ModalContainer } from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import { TitleIntern } from '../../components/Title';
import { useContract } from '../../hooks/contract';

const CoparticipationExtract: React.FC = () => {
  const history = useHistory();
  const { user: userT } = useAuth();
  const user = userT as UserCommon & BeneficiaryUser;
  const formRef = useRef<FormHandles>(null);
  const { contract } = useContract();

  const openModal = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/report/list-of-beneficiary-reports?exhibitionLocation=1&contractId=${user.contractId}`,
      );
      const { content } = data;
      const coParticipation = content.find(
        (i: any) =>
          i.dsRelatorio === 'Extrato de Coparticipações - Beneficiário',
      );
      if (coParticipation) {
        const { nrSeqRelatorio, Parametros: P } = coParticipation;
        // eslint-disable-next-line camelcase
        const nr_seq_segurado_p = user.userUnimedId;

        const submit = async (formData: any) => {
          try {
            Notiflix.Block.circle('.notiflix-generate');
            formRef.current?.setErrors({});
            const shape = P.reduce(
              (
                acc: {
                  [x: string]: RequiredStringSchema<
                    string | null | undefined,
                    Record<string, any>
                  >;
                },
                act: { ieObrigatorio: string; cdParametro: string },
              ) => {
                if (act.ieObrigatorio === 'S') {
                  acc[act.cdParametro] =
                    Yup.string().required('Campo obrigatório');
                }
                return acc;
              },
              {},
            );
            const schema = Yup.object().shape(shape);
            await schema.validate(formData, { abortEarly: false });

            const params = Object.entries(formData).reduce((acc, act) => {
              acc.push({
                id: act[0],
                valor: act[1] as string,
              });
              return acc;
            }, [] as { id: string; valor: string }[]);

            const { data: dataReport } = await api.post(
              `/report/generate-report`,
              {
                nrSeqRelatorio,
                Parametros: params,
              },
              {
                responseType: 'blob',
              },
            );
            downloadFileOfBlob(dataReport);
          } catch (err) {
            if (err instanceof Yup.ValidationError) {
              const errors = getValidationErrors(err);
              formRef.current?.setErrors(errors);
            }
          } finally {
            Notiflix.Block.remove('.notiflix-generate');
          }
        };

        Modal2.Generic({
          closable: false,
          autoWidth: true,
          children: (
            <ModalContainer>
              <TitleIntern>Extrato de Coparticipação</TitleIntern>
              <FormWrapper onSubmit={submit} ref={formRef} formLook>
                {P.map((param: any) => {
                  if (param.ieFormaApresentacao !== 'I') {
                    if (param.API) {
                      return (
                        <SelectUnform
                          key={v4()}
                          name={param.cdParametro}
                          title={param.dsParametro}
                          apiSearch={param.API.replace('http://', 'https://')}
                          formLook
                        />
                      );
                    }
                    return <Input name={param.cdParametro} hidden />;
                  }
                  return (
                    <Input
                      key={v4()}
                      name={param.cdParametro}
                      hidden
                      // eslint-disable-next-line camelcase
                      value={nr_seq_segurado_p}
                    />
                  );
                })}
                <ButtonWrapper>
                  <Button
                    secondary
                    modal
                    autoWidth
                    onClick={() => {
                      Modal2.Close();
                      history.push('/');
                    }}
                  >
                    Fechar
                  </Button>
                  <Button
                    modal
                    autoWidth
                    type="submit"
                    className="notiflix-generate"
                  >
                    Gerar
                  </Button>
                </ButtonWrapper>
              </FormWrapper>
            </ModalContainer>
          ),
        });
      }
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar as informações necessárias no servidor. Por favor, tente novamente mais tarde.',
      );
      history.push('/');
    }
  }, [history, user]);

  useEffect(() => {
    if (user.userUnimedId) {
      openModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return <PageWrapper />;
};

export default CoparticipationExtract;
