import styled from 'styled-components';
import Translation from '../../services/api/Translation';

export const Translations = styled(Translation)`
  & > * {
    font-family: 'Unimed-Sans-Bold' !important;
    font-size: 0.85rem;
    color: ${(props) => props.theme.colors.text.blackToWhite};
    word-break: break-word;
  }
`;

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.text.whiteToBlack};
  padding: 32px;
  * {
    font-family: 'Unimed-Sans-Book' !important;
    color: ${(props) => props.theme.colors.text.grayishToWhite} !important;
  }
  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  b {
    font-family: 'Unimed-Sans-Bold' !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 8px;
  }
  @media (max-width: 768px) {
    padding: 8px 24px;
  }
`;
