import React, { useState, useCallback, useEffect, useRef } from 'react';

import { FiChevronDown, FiLogOut } from 'react-icons/fi';
import { HiMinusSm, HiPlus } from 'react-icons/hi';
import { mask } from 'remask';
import { useAuth } from '../../hooks/auth';
import { useContract } from '../../hooks/contract';
import { useReleases } from '../../hooks/releasesAndTerms';

import { useTheme } from '../../hooks/theme';
import { LogoUnimedSvg } from '../Svg';
import {
  SunIcon,
  MoonIcon,
  HamburgerIcon,
  RefreshIcon,
  Container,
  SwitcherTheme,
  FontAjust,
  AccessibilityWrapper,
  AcessibilityTitle,
  UserInfoContent,
  ProtocolInfo,
  UserDropDown,
  SwitchComponent,
  AcessibilityContent,
  ButtonReset,
  WelcomeUserText,
  Communications,
  ExitButtonUser,
  HamburgerButton,
  AvatarImage,
  LogoWrapper,
  Bullet,
} from './styled';

interface HeaderProps {
  className?: string;
  toggleAside(): void;
}

const Header: React.FC<HeaderProps> = ({ className, toggleAside }) => {
  const { toggleTheme, theme } = useTheme();
  const { logout, user } = useAuth();
  const { contract } = useContract();
  const { releases } = useReleases();

  const [notificationCount, setNotificationCount] = useState(0);
  const [userDropDownCollapse, setUserDropDownCollapse] = useState(false);
  const [acessibilityDropdonwCollapse, setAcessibilityDropdonwCollapse] =
    useState(false);

  const dropdownUserRef = useRef<HTMLDivElement>(null);

  const handleToggleUserDropdown = useCallback(() => {
    setUserDropDownCollapse((prev) => !prev);

    if (acessibilityDropdonwCollapse === true) {
      setAcessibilityDropdonwCollapse(false);
    }
  }, [acessibilityDropdonwCollapse]);

  const handleToggleAcessibilityDropdown = useCallback(() => {
    if (userDropDownCollapse === true) {
      setUserDropDownCollapse(false);
    }

    setAcessibilityDropdonwCollapse((prev) => !prev);
  }, [userDropDownCollapse]);

  const setDarkTheme = useCallback(() => {
    if (theme.title === 'light') {
      toggleTheme();
    }
  }, [toggleTheme, theme.title]);

  const setLightTheme = useCallback(() => {
    if (theme.title === 'dark') {
      toggleTheme();
    }
  }, [toggleTheme, theme.title]);

  const handleIncrementFontBase = useCallback(() => {
    const htmlElement = document.querySelector('html');
    const fontbase = htmlElement?.getAttribute('data-fontbase');

    if (fontbase && htmlElement) {
      htmlElement.style.fontSize = `${parseInt(fontbase, 10) + 1}px`;
      htmlElement.setAttribute(
        'data-fontbase',
        (parseInt(fontbase, 10) + 1).toString(),
      );
    }
  }, []);

  const handleDecrementFontBase = useCallback(() => {
    const htmlElement = document.querySelector('html');
    const fontbase = htmlElement?.getAttribute('data-fontbase');

    if (fontbase && htmlElement) {
      htmlElement.style.fontSize = `${parseInt(fontbase, 10) - 1}px`;
      htmlElement.setAttribute(
        'data-fontbase',
        (parseInt(fontbase, 10) - 1).toString(),
      );
    }
  }, []);

  const resetSettingsPage = useCallback(() => {
    const htmlElement = document.querySelector('html');
    const fontbase = htmlElement?.getAttribute('data-fontbase');

    if (fontbase && htmlElement) {
      htmlElement.style.fontSize = `14px`;
      htmlElement.setAttribute(
        'data-fontbase',
        parseInt(String(14), 10).toString(),
      );
    }

    if (theme.title === 'dark') {
      toggleTheme();
    }
  }, [toggleTheme, theme.title]);

  useEffect(() => {
    setNotificationCount(
      releases?.items?.filter((item) => !item.read).length || 0,
    );
  }, [releases]);

  return (
    <>
      <Container className={className}>
        <HamburgerButton type="button" onClick={toggleAside}>
          <HamburgerIcon />
        </HamburgerButton>
        <AccessibilityWrapper>
          <AcessibilityTitle onClick={handleToggleAcessibilityDropdown}>
            Acessibilidade
            <FiChevronDown
              size={20}
              className={acessibilityDropdonwCollapse ? 'open' : 'close'}
            />
          </AcessibilityTitle>
          <AcessibilityContent
            acessibilityCollapse={acessibilityDropdonwCollapse}
          >
            <SwitcherTheme>
              <button type="button" onClick={setLightTheme}>
                <SunIcon />
                <span>Normal</span>
              </button>
              <SwitchComponent
                onChange={toggleTheme}
                checked={theme.title === 'dark'}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={40}
                handleDiameter={20}
                offColor="#c2c2c2"
                onColor="#c2c2c2"
              />
              <button type="button" onClick={setDarkTheme}>
                <MoonIcon />
                <span>Alto Contraste</span>
              </button>
            </SwitcherTheme>
            <FontAjust>
              <h2>Aa</h2>
              <button type="button" onClick={handleIncrementFontBase}>
                <div>A+</div>
                <span>Aumentar texto</span>
                <HiPlus />
              </button>
              <button type="button" onClick={handleDecrementFontBase}>
                <div>A-</div>
                <span>Diminuir texto</span>
                <HiMinusSm />
              </button>
              <ButtonReset type="button" onClick={resetSettingsPage}>
                <RefreshIcon /> <span> Reset</span>
              </ButtonReset>
            </FontAjust>
          </AcessibilityContent>
        </AccessibilityWrapper>

        <LogoWrapper to="/">
          <LogoUnimedSvg />
        </LogoWrapper>

        <ProtocolInfo>
          {user.profile === 1 && (
            <>
              <p>
                <strong>Protocolo</strong>: {user.protocolNumber}
              </p>
              <p>
                <strong>{`Último acesso: `}</strong>
                {user?.lastAccessDateTime}
              </p>
            </>
          )}
          {user.profile === 2 && (
            <>
              {/* <p>
                <strong>Estipulante</strong>: {user?.name}
              </p> */}
              <p>
                <strong>Código empresa</strong>: {contract?.companyCode}
              </p>
              <p>
                <strong>Código Contrato</strong>: {contract?.contractId}
              </p>
              <p>
                <strong>Último acesso</strong>: {user?.lastAccessDateTime}
              </p>
            </>
          )}
        </ProtocolInfo>
        <UserInfoContent
          onClick={handleToggleUserDropdown}
          className={user.profile === 2 ? 'company' : ''}
        >
          {user.profile === 1 && (
            <>
              <AvatarImage>
                <img
                  src={`https://api.unimedblumenau.com.br/beneficiary/get-beneficiary-image?phisicalPersonCode=${user.physicalPersonalCode}`}
                  alt="Foto do usuário"
                  id="user_avatar"
                  onError={(e) => {
                    try {
                      const { target } = e as unknown as {
                        target: HTMLImageElement;
                      };
                      target.src = '/images/unimed_placeholder.png';
                    } catch (err) {
                      //
                    }
                  }}
                />
                {notificationCount > 0 && (
                  <Bullet className="beneficiary">
                    <p>{notificationCount > 99 ? `99+` : notificationCount}</p>
                  </Bullet>
                )}
              </AvatarImage>
              <WelcomeUserText className="welcome-user">
                Olá, <strong>{user.name.split(' ')[0]}</strong>
              </WelcomeUserText>
              <FiChevronDown
                size={20}
                className={userDropDownCollapse ? 'open' : 'close'}
              />
            </>
          )}
          {user.profile === 2 && (
            <>
              <WelcomeUserText className="welcome-company">
                Olá, <strong>{contract?.stipulator?.split(' ')[0]}</strong>
                {notificationCount > 0 && (
                  <Bullet className="company">
                    <p>{notificationCount > 99 ? `99+` : notificationCount}</p>
                  </Bullet>
                )}
              </WelcomeUserText>
              <FiChevronDown
                size={20}
                className={userDropDownCollapse ? 'open' : 'close'}
              />
            </>
          )}
        </UserInfoContent>
        <UserDropDown ref={dropdownUserRef} userCollapse={userDropDownCollapse}>
          {user.profile === 1 && (
            <>
              <div>
                <small>Beneficiário</small>
                <h3>{user.name}</h3>
                <small>{user.email}</small>
              </div>

              <div>
                <small>Carteira</small>
                {user.card && (
                  <h3>{mask(user.card, '9 999 999999999999 9')}</h3>
                )}
              </div>

              <div>
                <small>Produto</small>
                <h3>{user?.nameProduct}</h3>
              </div>
            </>
          )}
          {user.profile === 2 && (
            <>
              <div>
                <small>Empresa</small>
                <h3>{contract.stipulator}</h3>
              </div>

              {/* <div>
                <small>Usuário</small>
                <h3>{user.name}</h3>
                <small>{user.email}</small>
              </div> */}
            </>
          )}

          <Communications to="/comunicados">
            <h3>
              {notificationCount > 0 && (
                <span>
                  <p>{notificationCount > 99 ? `99+` : notificationCount}</p>
                </span>
              )}{' '}
              Comunicados
            </h3>
          </Communications>
          <ExitButtonUser onClick={logout}>
            <h3>Sair</h3>
            <FiLogOut color={theme.colors.text.grayishToLightGray} />
          </ExitButtonUser>
        </UserDropDown>
      </Container>
    </>
  );
};

export default Header;
