import React, { useCallback, useState, useEffect, useMemo } from 'react';
import Notiflix from 'notiflix';
import { v4 } from 'uuid';
import {
  Container,
  Title,
  PeriodContainer,
  PeriodButton,
  SelectedPeriodContainer,
  FormWrapperPeriodDate,
} from './styles';
import PageWrapper from '../../components/PageWrapper';
import DatePicker from '../../components/DatePicker';
import api from '../../services/api/api';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';
import { dateToPTBR, ptBRToDate } from '../../utils/formatt';

import Consult, { ConsultProps } from './Consult';
import { useAuth } from '../../hooks/auth';

const UsageServicesComponent: React.FC = () => {
  const { user } = useAuth();
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const [selectedFromDate, setSelectedFromDate] = useState<Date>();
  const [selectedToDate, setSelectedToDate] = useState<Date>();
  const [consults, setConsults] = useState<ConsultProps[]>(
    [] as ConsultProps[],
  );
  const [errors, setErrors] = useState({ from: '', to: '' });

  const [dateFilter, setDateFilter] = useState<{
    from: Date | null;
    to: Date | null;
  }>({
    from: null,
    to: null,
  });

  const handleGenerateReport = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-print-report');
      const { data } = await api.get(
        `/report/list-of-beneficiary-reports?contractId=${user.id}&exhibitionLocation=2`,
      );

      const { nrSeqRelatorio, Parametros: P } = data.content[0];
      const fromPT = selectedFromDate ? dateToPTBR(selectedFromDate) : '';
      const toPT = selectedToDate ? dateToPTBR(selectedToDate) : '';

      const params = [user.id, fromPT, toPT];
      const body = {
        nrSeqRelatorio: nrSeqRelatorio ?? '',
        Parametros: [
          ...P.map((p: { cdParametro: string }, index: number) => {
            return {
              id: p.cdParametro,
              valor: params[index],
            };
          }),
        ],
      };

      const { data: dataReport } = await api.post(
        `/report/generate-report`,
        body,
        {
          responseType: 'blob',
        },
      );
      downloadFileOfBlob(dataReport);
    } catch (err) {
      if (err?.response?.status === 400) {
        Notiflix.Notify.info(err.response.data?.message);
      } else {
        Notiflix.Notify.failure(
          'Ops...Não conseguimos buscar seu relatório. Por favor, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-print-report');
    }
  }, [selectedFromDate, selectedToDate, user]);

  const getDate = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-date-loader');
      const { data } = await api.get(
        `/medical-consultation/consult-period-released-for-consultation`,
      );
      const { content } = data;
      if (content) {
        setFromDate(ptBRToDate(content[0].startDate));
        setToDate(ptBRToDate(content[0].finalDate));
        setSelectedFromDate(ptBRToDate(content[0].startDate));
        setSelectedToDate(ptBRToDate(content[0].finalDate));
        setDateFilter({
          from: ptBRToDate(content[0].startDate),
          to: ptBRToDate(content[0].finalDate),
        });
      }
    } catch (error) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar seus dados. Tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-date-loader');
    }
  }, []);

  const getData = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-search');
      const initial = dateFilter.from && dateToPTBR(dateFilter.from);
      const final = dateFilter.to && dateToPTBR(dateFilter.to);
      if (!initial || !final) {
        return;
      }
      const { data } = await api.get(
        `medical-consultation/query-data-with-filter-by-synthetic-period?startDate=${initial}&finalDate=${final}`,
      );

      const { content } = data;
      setConsults(content);
    } catch (error) {
      setConsults([]);
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar suas utilizações de serviço. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-search');
    }
  }, [dateFilter]);

  const consultsItems = useMemo(() => {
    return (
      <>
        {consults?.map((consult: ConsultProps) => {
          return <Consult key={v4()} data={consult} />;
        })}
      </>
    );
  }, [consults]);

  useEffect(() => {
    getDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dateFilter.from && dateFilter.to) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  const handleSetFilter = useCallback(() => {
    setDateFilter({
      from: selectedFromDate ?? null,
      to: selectedToDate ?? null,
    });
    if (!selectedFromDate && selectedToDate) {
      setErrors((prev) => ({ ...prev, from: 'Informe uma data' }));
    } else if (selectedFromDate && !selectedToDate) {
      setErrors((prev) => ({ ...prev, to: 'Informe uma data' }));
    } else if (!selectedFromDate && !selectedToDate) {
      setErrors(() => ({
        from: 'Informe uma data',
        to: 'Informe uma data',
      }));
    }
  }, [selectedFromDate, selectedToDate]);

  return (
    <PageWrapper
      gridTemplateColumns="repeat(10,1fr)"
      gridTemplateRows="1fr auto"
    >
      <Container className="notiflix-loading-releases">
        <Title>Extrato de Utilização de Serviços</Title>
        <SelectedPeriodContainer>
          <h2 className="notiflix-date-loader">
            {`Período liberado para consulta: ${
              fromDate && dateToPTBR(fromDate)
            } até ${toDate && dateToPTBR(toDate)}`}
          </h2>
        </SelectedPeriodContainer>
        <PeriodContainer>
          <FormWrapperPeriodDate onSubmit={handleSetFilter} formLook>
            <DatePicker
              name="from"
              onChange={(date: Date) => {
                setSelectedFromDate(date);
                setErrors((prev) => ({ ...prev, from: '' }));
              }}
              selected={selectedFromDate}
              icon
              formLook
              title="Período de:"
              minDate={fromDate}
              maxDate={toDate}
              widthContainerDesktop="20%"
              error={errors.from}
            />

            <DatePicker
              name="to"
              onChange={(date: Date) => {
                setSelectedToDate(date);
                setErrors((prev) => ({ ...prev, to: '' }));
              }}
              selected={selectedToDate}
              icon
              formLook
              title="Até:"
              minDate={fromDate}
              maxDate={toDate}
              widthContainerDesktop="20%"
              error={errors.to}
            />

            <PeriodButton
              type="submit"
              autoWidth
              grayButton
              formLook
              className="notiflix-search"
            >
              Buscar
            </PeriodButton>
            <PeriodButton
              autoWidth
              formLook
              greenButton
              onClick={handleGenerateReport}
              className="notiflix-print-report"
            >
              Relatório
            </PeriodButton>
          </FormWrapperPeriodDate>
          {consultsItems}
        </PeriodContainer>
      </Container>
    </PageWrapper>
  );
};

export default UsageServicesComponent;
