import styled from 'styled-components';
import Button from '../../components/Button';
import FormWrapper from '../../components/FormWrapper';
import Select from '../../components/Select';

export const Container = styled.div`
  display: grid;
  grid-column: 2/10;
  /* max-height: 500px; */
  height: 200px;
  row-gap: 8px;
  /* padding-bottom: 0px; */

  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

export const Title = styled.h1`
  align-self: center;
  text-transform: uppercase;
  font-size: 2.55rem;
  line-height: 2.4rem;
  font-family: 'Unimed-Slab-Bold';
  color: ${(props) => props.theme.colors.palet.institutional};
  text-align: center;
  padding: 52px;

  @media (max-width: 768px) {
    grid-column: 1;
    font-size: 1.7rem;
    line-height: 1.5rem;
    padding: 25px;
  }
`;

export const GenerateIncomeContainer = styled(FormWrapper)`
  display: flex;
  justify-content: space-between;
  padding: 0px;

  > div {
    max-width: 411px;
  }

  @media (max-width: 768px) {
    margin-bottom: 60px;
  }
`;

export const GenerateButton = styled(Button)`
  max-width: 201px;
`;

export const YearSelect = styled(Select)`
  div {
    max-height: 200px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
