import styled from 'styled-components';
import Button from '../../../components/Button';
import TableComponent from '../../../components/Table';

export const Table = styled(TableComponent)`
  grid-column: 2/3;

  @media (max-width: 768px) {
    grid-column: 1;
    margin-bottom: 30px;

    td.to-bottom {
      display: flex;
      align-items: flex-end;
    }
  }
`;

export const CancelButton = styled(Button)`
  /* max-width: 98px; */
`;

export const ChangeRegisterDataConsultTitle = styled.h1`
  grid-column: 2/3;
  text-transform: uppercase;
  font-size: 2.55rem;
  line-height: 2.4rem;
  font-family: 'Unimed-Slab-Bold';
  color: ${(props) => props.theme.colors.palet.institutional9};
  text-align: center;
  padding: 30px;

  @media (max-width: 768px) {
    grid-column: 1;
    font-size: 1.7rem;
    line-height: 1.5rem;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 769px) {
    width: 300px;
  }
  h2 {
    margin-bottom: 36px;
    font-family: 'Unimed-Sans-Bold';
    color: ${(props) => props.theme.colors.text.primary};
  }
  > button {
    margin-top: 16px;
  }
`;
