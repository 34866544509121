import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { FiX } from 'react-icons/fi';

interface ModalProps {
  failure?: boolean;
  sucess?: boolean;
  fitContent?: boolean;
}
interface ModalOverlayProps {
  visible: boolean;
}

export const ModalOverlay = styled.div<ModalOverlayProps>`
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  min-width: 100vw;
  max-width: 100vw;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  @media (max-width: 748px) {
    padding: 16px;
  }
  ${(props) =>
    !props.visible &&
    css`
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    `}
`;

export const ModalWrapper = styled.div<ModalProps>`
  z-index: 99;
  background: #fff;
  position: relative;

  margin: 0 auto;
  max-height: calc(100vh - 15vw);
  height: auto;
  overflow-y: auto;
  border-radius: 30px;
  padding: 24px;
  text-align: center;
  width: 80vw;
  > svg {
    margin-left: 8px;
  }
  ${(props) =>
    (props.sucess || props.failure) &&
    css`
      width: 475px;
    `};

  ${({ fitContent }) =>
    fitContent &&
    css`
      width: fit-content;
    `}
`;

export const TitleParagraph = styled.p`
  font-size: 3rem;
  color: ${(props) => props.theme.colors.palet.institutional11};
  font-family: 'Unimed-Sans-Bold';
  padding-top: 20px;
  margin-bottom: 8px;
`;
export const SubtitleParagraph = styled.p`
  color: ${(props) => props.theme.colors.palet.institutional11};
  font-family: 'Unimed-Sans-Bold';
  font-size: 1.7rem;
  margin-bottom: 8px;
`;
export const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.palet.institutional11};
  font-family: 'Unimed-Sans-Book';
  font-size: 1.7rem;
  font-weight: 300;
  margin-bottom: 8px;
`;

export const CloseButton = styled(FiX)`
  position: absolute;
  right: 20px;

  &:hover {
    color: ${shade(0.7, '#fff')};
    cursor: pointer;
  }
`;

export const DivWrapper = styled.div`
  padding-top: 30px;
`;
