/* eslint-disable @typescript-eslint/no-explicit-any */
import Notiflix from 'notiflix';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { v4 } from 'uuid';
import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import FormWrapper from '../../components/FormWrapper';
import Input from '../../components/Input';
import Modal2 from '../../components/Modal2';

import PageWrapper from '../../components/PageWrapper';
import Select, { SelectHandles } from '../../components/Select';
import Table, { Column } from '../../components/Table';
import { ButtonsActionsContainer } from '../../components/Table/styles';
import api from '../../services/api/api';
import {
  dateInFirstDayOfYear,
  dateInLastDayOfYear,
  dateToPTBR,
} from '../../utils/formatt';
import AddField, {
  getTag,
  setPositionOfRowExpanded,
} from '../../utils/tableUtils';

import { Container, Title, ModalContainer, ModalRow } from './styles';

const ConsultServiceProtocol: React.FC = () => {
  const [protocols, setProtocols] = useState<any>([]);
  const [from, setFrom] = useState(dateInFirstDayOfYear());
  const [to, setTo] = useState(dateInLastDayOfYear());
  const [dateError, setDateError] = useState({
    from: false,
    to: false,
  });
  const [statusOptions, setStatusOptions] = useState([
    {
      title: 'Todos',
      value: '',
    },
  ]);
  const statusSelectRef = useRef<SelectHandles>(null);

  const getOptionsToStatus = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-status');
      const { data } = await api.get('/protocol/protocol-status-list');
      const { content } = data;
      if (content) {
        setStatusOptions([
          {
            title: 'Todos',
            value: '',
          },
          ...content.reduce(
            (
              acc: { title: string; value: string }[],
              act: { name: string; id: string },
            ) => {
              acc.push({
                title: act.name,
                value: act.id,
              });
              return acc;
            },
            [],
          ),
        ]);
      }
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops.. Não conseguimos buscar os status no servidor. Tente novamente mais tarde',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-status');
    }
  }, []);

  const getProtocols = useCallback(
    async (protocol = null, protocolReference = null, status = null) => {
      try {
        Notiflix.Block.circle('.notiflix-protocols');
        const fromPT = dateToPTBR(from); // 32102
        const toPT = dateToPTBR(to);

        let route = `/protocol/list-of-beneficiary-service-protocols?startDate=${fromPT}&finalDate=${toPT}`;
        if (protocol) {
          route += `&protocolNumber=${protocol}`;
        }
        if (protocolReference) {
          route += `&referenceProtocolNumber=${protocolReference}`;
        }
        if (status) {
          route += `&status=${status}`;
        }
        const { data } = await api.get(route);
        setProtocols(data.content);
      } catch (err) {
        if (err.response.status === 400) {
          setProtocols([]);
        } else {
          Notiflix.Notify.failure(
            'Ops.. Não conseguimos buscar os protocolos no servidor. Tente novamente mais tarde',
          );
        }
      } finally {
        Notiflix.Block.remove('.notiflix-protocols');
      }
    },
    [from, to],
  );

  const handleSearchSubmit = useCallback(
    (data) => {
      try {
        const status = statusSelectRef.current?.value;
        dateToPTBR(from);
        dateToPTBR(to);
        const { protocol } = data;
        const { protocolReference } = data;
        getProtocols(protocol, protocolReference, status);
      } catch (err) {
        if (err instanceof TypeError) {
          setDateError({
            from: from === null,
            to: to === null,
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Algo deu errado com sua solicitação. Por favor, tente novamente mais tarde',
          );
        }
      }
    },
    [from, getProtocols, to],
  );

  const statusTemplateBody = useCallback((status: string, header: string) => {
    switch (status) {
      case 'Finalizado':
        return getTag(header, status, 'gray');
      case 'Cancelado':
        return getTag(header, status, 'red');
      case 'Em andamento':
        return getTag(header, status, 'green');
      case 'Pendente':
        return getTag(header, status, 'orange');
      default:
        return <></>;
    }
  }, []);

  const handleOpenDetails = useCallback(async (data, notiflix: string) => {
    try {
      Notiflix.Block.circle(notiflix);
      const { data: dataApi } = await api.get(
        `/protocol/get-protocol-details?protocolNumber=${data.nrSeqProtocolo}`,
      );
      const { content } = dataApi;
      Modal2.Generic({
        autoWidth: true,
        closable: true,
        children: (
          // <ModalContainer dangerouslySetInnerHTML={{ __html: content }} />
          <ModalContainer>
            {content.map((row: any) => {
              return (
                <ModalRow key={row.historicalDate}>
                  <strong>{row.historicalDate}</strong> - {row.historical}
                </ModalRow>
              );
            })}
          </ModalContainer>
        ),
      });
    } catch (err) {
      if (err.response.status === 400) {
        Notiflix.Notify.info('Não há detalhes desse protocolo');
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar os detalhes no servidor. Por favor, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove(notiflix);
    }
  }, []);

  useEffect(() => {
    getProtocols();
    getOptionsToStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [expandedRows, setExpandedRows] = useState([] as any[]);

  const rowExpansionTemplate = useCallback(
    (rowData: any) => {
      const buttons = [
        {
          title: 'Detalhes',
          onClick: handleOpenDetails,
        },
      ];
      return (
        <ButtonsActionsContainer>
          {buttons.map((button) => {
            const uuid = v4();
            return (
              <Button
                key={button.title}
                modal
                autoWidth
                sausageLook
                whiteButton
                className={`notiflix-${uuid} marginLeft`}
                onClick={(e) => {
                  e.stopPropagation();
                  button.onClick(rowData, `notiflix-${uuid}`);
                }}
              >
                {button.title}
              </Button>
            );
          })}
        </ButtonsActionsContainer>
      );
    },
    [handleOpenDetails],
  );

  return (
    <PageWrapper gridTemplateRows="auto auto 1fr">
      <Title>Consulta protocolo de Atendimento</Title>

      <Container>
        <FormWrapper onSubmit={handleSearchSubmit} formLook>
          <Input
            name="protocol"
            title="Protocolo:"
            formLook
            widthContainerDesktop="50%"
          />
          <Input
            name="protocolReference"
            title="Protocolo referência:"
            formLook
            widthContainerDesktop="50%"
          />
          <DatePicker
            name="from"
            title="Data de:"
            formLook
            icon
            selected={from}
            onChange={(date: Date) => {
              setFrom(date);
              setDateError((prev) => ({
                ...prev,
                from: false,
              }));
            }}
            widthContainerDesktop="20%"
            error={dateError.from ? 'É necessário informar uma data' : ''}
          />
          <DatePicker
            name="to"
            title="Data até:"
            icon
            formLook
            selected={to}
            onChange={(date: Date) => {
              setTo(date);
              setDateError((prev) => ({
                ...prev,
                to: false,
              }));
            }}
            widthContainerDesktop="20%"
            error={dateError.to ? 'É necessário informar uma data' : ''}
          />
          <Select
            name="status"
            title="Status"
            formLook
            options={statusOptions}
            ref={statusSelectRef}
            widthContainerDesktop="60%"
            className="notiflix-status"
          />
          <Button formLook greenPrimary type="submit" autoWidth>
            Consultar
          </Button>
        </FormWrapper>
        <Table
          items={protocols}
          className="notiflix-protocols"
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows([e.data[e.data.length - 1]])}
          onRowClick={(e) => {
            if (
              expandedRows.length > 0 &&
              expandedRows[0]?.protocolNumber !== e.data?.protocolNumber
            ) {
              setExpandedRows([]);
              return setExpandedRows([e.data]);
            }
            return expandedRows.length === 0
              ? setExpandedRows([e.data])
              : setExpandedRows([]);
          }}
          rowExpansionTemplate={rowExpansionTemplate}
          onRowExpand={(e) =>
            window.innerWidth <= 768 &&
            setPositionOfRowExpanded(e.originalEvent)
          }
        >
          <Column
            field="protocolNumber"
            header="Protocolo"
            body={(data) => AddField(data.protocolNumber, 'Protocolo')}
            sortable
          />
          <Column
            field="referenceProtocolNumber"
            header="Protocolo referência"
            body={(data) =>
              AddField(data.referenceProtocolNumber, 'Protocolo referência')
            }
            sortable
          />
          <Column
            field="protocolDate"
            header="Data"
            className="date"
            body={(data) => AddField(data.protocolDate, 'Data')}
          />
          <Column
            field="descriptionOriginProtocol"
            header="Origem"
            body={(data) => AddField(data.descriptionOriginProtocol, 'Origem')}
            sortable
          />
          <Column
            field="descriptionStatusProtocol"
            header="Status"
            className="tag"
            body={(data) =>
              statusTemplateBody(data.descriptionStatusProtocol, 'Status')
            }
            sortable
          />
          <Column header="" expander headerStyle={{ width: '80px' }} />
        </Table>
      </Container>
    </PageWrapper>
  );
};

export default ConsultServiceProtocol;
