import React, { useCallback, useRef, useState } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Notiflix from 'notiflix';
import Input from '../../components/Input';
import {
  Container,
  Background,
  Content,
  BemVindo,
  BemVindoMobile,
  ContentMobile,
  ButtonSubmit,
  ButtonGoBack,
  ButtonWrapper,
  ContainerLogoW,
} from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import Select from '../../components/Select';
import Datepicker from '../../components/DatePicker';
import { GrupowSvg, LogoUnimedSvg } from '../../components/Svg';
import { dateToPTBR } from '../../utils/formatt';
import Modal2 from '../../components/Modal2';
import Button from '../../components/Button';
import api from '../../services/api/api';

interface ForgotPasswordProps {
  document: string;
  user: string;
  birthDate: string;
}

const ForgotPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [typeUser, setTypeUser] = useState('1');
  const history = useHistory();

  const [birthdayValue, setBirthdayValue] = useState<Date>();
  const [birthdayError, setBirthdayError] = useState('');

  const handleChangeBirthday = useCallback((data: Date) => {
    setBirthdayValue(data);
    setBirthdayError('');
  }, []);

  const handleTypeUserChange = useCallback((value: string) => {
    setTypeUser(value);
  }, []);

  const handleFormSubmit = useCallback(
    async (data: ForgotPasswordProps) => {
      try {
        formRef.current?.setErrors({});
        setBirthdayError('');
        try {
          // eslint-disable-next-line no-param-reassign
          if (birthdayValue) data.birthDate = dateToPTBR(birthdayValue);
        } catch (error) {
          // eslint-disable-next-line no-param-reassign
          data.birthDate = '';
        }

        // console.log(typeUser);
        if (typeUser === '1') {
          const schema = Yup.object().shape({
            document: Yup.number()
              .required('Informe seu CPF ou número da Carteirinha')
              .typeError('Informe seu CPF ou número da Carteirinha'),
            birthDate: Yup.string().required('Informe sua data de nascimento'),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
        }

        if (typeUser === '2') {
          const schema = Yup.object().shape({
            user: Yup.string().required('Informe seu usuário'),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
        }

        const toSend =
          typeUser === '1'
            ? {
                typeUser,
                document: data.document,
                birthDate: data.birthDate,
              }
            : {
                typeUser,
                user: data.user,
              };

        Notiflix.Loading.circle();
        const { data: dataApi } = await api.post(
          '/user/forgot-your-password',
          toSend,
        );
        const { content, message } = dataApi;

        if (content) {
          Modal2.Success({
            closable: true,
            title: 'Perfeito!',
            text: `Enviamos um e-mail para ${content[0]} com o restante das instruções para prosseguir com a alteração da sua senha. Por favor, cheque seu e-mail.`,
            children: (
              <Button onClick={() => Modal2.Close()} modal>
                Ok
              </Button>
            ),
          });
        } else if (message) {
          Modal2.Warning({
            closable: true,
            title: 'Atenção!',
            text: message,
            children: (
              <Button onClick={() => Modal2.Close()} modal>
                Ok
              </Button>
            ),
          });
        }
        history.push('/login');
      } catch (err) {
        // console.log(err.errors);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          if (errors.birthDate) {
            setBirthdayError(errors.birthDate);
          }
        } else if (err.response?.data?.message) {
          Notiflix.Notify.failure(err.response.data.message, {
            timeout: 10 * 1000,
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos nos comunicar com o servidor. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Loading.remove();
      }
    },
    [typeUser, birthdayValue, history],
  );

  return (
    <Container>
      <Background>
        <BemVindo>
          Esqueci <br />
          minha senha
        </BemVindo>
        <LogoUnimedSvg />
        <ContainerLogoW>
          <a
            href="https://www.grupow.com.br/"
            title="GrupoW Softwares para Internet"
            target="_blanck"
          >
            Desenvolvido por <strong>GrupoW Softwares para Internet</strong>
            <GrupowSvg />
          </a>
        </ContainerLogoW>
      </Background>
      <Content>
        <Form ref={formRef} onSubmit={handleFormSubmit}>
          <ContentMobile>
            <h2>
              Selecione seu perfil <br />e digite seus dados
            </h2>
            <LogoUnimedSvg />
            <BemVindoMobile>
              Esqueci <br />
              minha senha
            </BemVindoMobile>
            <Select
              name="typeuser"
              changeCallback={handleTypeUserChange}
              options={[
                { value: '1', title: 'Beneficiário' },
                { value: '2', title: 'Empresa' },
              ]}
            />
            {typeUser === '1' && (
              <>
                <Input
                  name="document"
                  placeholder="CPF ou Carteirinha"
                  masks={['999.999.999-99', '9 999 999999999999 9']}
                />
                <Datepicker
                  name="birthDate"
                  placeholder="Data de nascimento"
                  onChange={handleChangeBirthday}
                  selected={birthdayValue}
                  error={birthdayError}
                  maxDate={new Date()}
                />
              </>
            )}
            {typeUser === '2' && <Input name="user" placeholder="Usuário" />}
          </ContentMobile>

          <ButtonWrapper>
            <ButtonGoBack
              onClick={() => history.goBack()}
              type="button"
              secondary
            >
              Voltar
            </ButtonGoBack>
            <ButtonSubmit type="submit">Enviar</ButtonSubmit>
          </ButtonWrapper>
        </Form>
        <ContainerLogoW className="mobile">
          <a
            href="https://www.grupow.com.br/"
            title="GrupoW Softwares para Internet"
            target="_blanck"
          >
            Desenvolvido por <strong>GrupoW Softwares para Internet</strong>
            <GrupowSvg />
          </a>
        </ContainerLogoW>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
