import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  grid-column: 1/4;
  @media (max-width: 768px) {
    grid-column: 1;
    margin-bottom: 30px;
  }
  hr {
    width: 100%;
    border-color: #c4cbcf;
    opacity: 0.5;
  }
  form {
    > button {
      min-width: 150px;
    }
    &.actions-include-beneficiary {
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;
      justify-content: flex-end;
      padding: 16px 20px;
    }
    &.actions-include-dependent {
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;
      justify-content: flex-end;
      padding: 16px 20px;

      > button {
        &.marginLeft {
          margin-left: auto;
        }
      }
    }
    &.full-form-beneficiary {
      > * {
        &:nth-child(1) {
          width: 20%;
          max-width: 20%;
        }
      }
      @media (max-width: 768px) {
        > * {
          &:nth-child(3) {
            width: 100%;
          }
          &:nth-child(6),
          &:nth-child(7) {
            width: auto;
            flex: 1 1 45%;
          }

          &:nth-child(18),
          &:nth-child(19) {
            width: auto;
            flex: 1 1 45%;
          }
          &:nth-child(20),
          &:nth-child(21) {
            max-width: calc(50% - 4px);
            width: auto;
            flex: 1 1 45%;
          }

          &:nth-child(26),
          &:nth-child(27) {
            max-width: calc(50% - 4px);
            width: calc(50% - 4px);
            flex: 1 1 calc(50% - 4px);
          }

          &:nth-child(30),
          &:nth-child(32) {
            max-width: calc(30% - 4px);
            width: calc(30% - 4px);
            flex: 1 1 calc(30% - 4px);
          }
          &:nth-child(31),
          &:nth-child(33) {
            max-width: calc(70% - 4px);
            width: calc(70% - 4px);
            flex: 1 1 calc(70% - 4px);
          }
          &:nth-child(36),
          &:nth-child(38) {
            max-width: calc(30% - 4px);
            width: calc(30% - 4px);
            flex: 1 1 calc(30% - 4px);
          }

          &:nth-child(37),
          &:nth-child(39) {
            max-width: calc(70% - 4px);
            width: calc(70% - 4px);
            flex: 1 1 calc(70% - 4px);
          }
        }
      }
    }
    &.depentent-include {
      @media (max-width: 768px) {
        > div {
          &:nth-child(5),
          &:nth-child(6) {
            width: auto;
            flex: 1 1;
          }
          &:nth-child(14),
          &:nth-child(15) {
            width: auto;
            min-width: 45%;
            flex: 1 1;
          }
          &:nth-child(16),
          &:nth-child(17) {
            width: auto;
            min-width: 45%;
            flex: 1 1;
          }
        }
      }
    }
    .notiflix-block-position {
      pointer-events: none;
    }
  }
`;
export const ExternalContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 30px;

  @media (max-width: 768px) {
  }
  hr {
    width: 100%;
    border-color: #c4cbcf;
    opacity: 0.5;
  }
  form {
    > button {
      min-width: 150px;
    }

    &.actions-include-dependent {
      > button {
        &.marginLeft {
          margin-left: auto;
        }
      }
    }
  }
`;

interface loadingProps {
  inLoading?: boolean;
}
export const UnimedLogo = styled.div<loadingProps>`
  width: 100%;
  height: 70px;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  svg {
    height: 50px;
  }
  ${(props) =>
    props.inLoading &&
    css`
      height: 100vh;
    `}
`;

export const OptionalParams = styled.div`
  display: none;
  width: 100%;
  row-gap: 27px;
  column-gap: 8px;
  padding: 34px 20px 16px 20px;

  border-radius: 5px;
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 5px 20px rgba(196, 203, 207, 0.6);
  ${(props) =>
    props.theme.title === 'dark' &&
    css`
      box-shadow: 0px 5px 20px rgba(54, 54, 56, 0.48);
    `}
  &.show {
    display: flex;
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    padding: 38px 30px 24px 30px;
    row-gap: 24px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  column-gap: 8px;
  &.spaceBetween {
    justify-content: space-between;
    > button:nth-child(2) {
      margin-left: auto;
    }
  }

  @media (max-width: 768px) {
    width: calc(100vw - 100px);
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    row-gap: 16px;
    > button {
      width: 100%;
    }
  }
`;

export const DependentIncludeButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 24px 24px 0;

  > button {
    border: none;
    outline: none;
    background-color: transparent;
    border-radius: 36px;
    display: flex;
    align-items: center;
    /* overflow: hidden; */
    position: relative;
    height: 36px;

    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 8px;
      padding: 4px;
      z-index: 0;
      position: absolute;
    }
    div {
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      width: 36px;
      height: 36px;
      border-radius: 36px;
      transition: 300ms;
      box-shadow: 0px 5px 20px
        ${(props) =>
          transparentize(0.4, props.theme.colors.text.lightGrayToBlack)};
    }
    span {
      font-size: 1rem;
      font-family: 'Unimed-Sans-Bold';
      color: ${(props) => props.theme.colors.text.primary};
      z-index: 0;
      padding: 0 16px 0 40px;
      text-transform: uppercase;
      text-align: center;
      display: block;
      width: 100%;
      transition: 300ms;
    }

    &:hover div {
      transition: 300ms;
      width: 230px;
    }
    /* &:hover span {
      transition: 300ms;
      color: ${(props) => props.theme.colors.text.inverse};
    } */
    @media (max-width: 768px) {
      width: 100%;
      div {
        width: 100% !important;
      }
      img {
        display: none;
      }
    }
  }
`;

export const DependentContainer = styled.section`
  form {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
  }
`;
export const DepententContent = styled.div`
  margin-top: 24px;
  padding: 8px;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 5px 20px
    ${(props) => transparentize(0.4, props.theme.colors.text.lightGrayToBlack)};
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 16px;
  row-gap: 16px;
  > button {
    flex: 1;
  }
`;

export const OwnerContainer = styled.div`
  padding: 8px;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 5px 20px
    ${(props) => transparentize(0.4, props.theme.colors.text.lightGrayToBlack)};
`;
