import React, { useState } from 'react';
import Title from '../../../components/Title';
import { useTheme } from '../../../hooks/theme';
import {
  Container,
  ContentContainer,
  IconBackground,
  BookIcon,
  ChecklistIcon,
  OpenBookIcon,
  Content,
  EditIcon,
  BarcodeIcon,
  ListIcon,
  // CurativeIcon,
} from './styles';
import PageWrapper from '../../../components/PageWrapper';

const WhatsappLink: React.FC = () => {
  const { theme } = useTheme();
  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <Container>
        <Title themeColor={theme.colors.palet.institutional11}>
          Ambulatório de curativos
        </Title>
        <Content>
          <ContentContainer to="/ambulatorio-curativos">
            <IconBackground>
              <EditIcon />
            </IconBackground>
            <h2>Agendamentos</h2>
          </ContentContainer>
          <ContentContainer to="/orientacao-nutricional">
            <IconBackground>
              <EditIcon />
            </IconBackground>
            <h2>Orientação Nutricional</h2>
          </ContentContainer>
          <ContentContainer to="/alterar-dados-cadastrais">
            <IconBackground>
              <EditIcon />
            </IconBackground>
            <h2>Tipos de lesões</h2>
          </ContentContainer>

          <ContentContainer to="/demonstrativos-e-segunda-via-boleto">
            <IconBackground>
              <BarcodeIcon />
            </IconBackground>
            <h2>Fotos</h2>
          </ContentContainer>

          <ContentContainer to="/extrato-coparticipacao">
            <IconBackground>
              <ChecklistIcon />
            </IconBackground>
            <h2>Link Whatsapp</h2>
          </ContentContainer>

          <ContentContainer to="/declaracao-imposto-renda">
            <IconBackground>
              <ListIcon />
            </IconBackground>
            <h2>Meta de cuidado</h2>
          </ContentContainer>

          <ContentContainer to="/tabela-referencia-unimed">
            <IconBackground>
              <BookIcon />
            </IconBackground>
            <h2>Exames</h2>
          </ContentContainer>
        </Content>
      </Container>
    </PageWrapper>
  );
};

export default WhatsappLink;
