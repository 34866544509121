// create
import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import * as fns from 'date-fns';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiEye, FiTrash, FiInfo } from 'react-icons/fi';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { mask, unMask } from 'remask';
import * as Helper from '../Scheduling/helpers';
import Button from '../../components/Button';
import FormWrapper from '../../components/FormWrapper';
import Input from '../../components/Input';
import { ModalHandles } from '../../components/Modal';
import PageWrapper from '../../components/PageWrapper';
import Select, { SelectHandles } from '../../components/Select';
import Table, { Column } from '../../components/Table';
import Title from '../../components/Title';
import { IBeneficiariesOfAnInsured, IUserTimeTable } from '../Scheduling/types';
import * as S from './styles';
import Modal2 from '../../components/Modal2';
import getValidationErrors from '../../utils/getValidationErrors';
import DatePicker from '../../components/DatePicker';
import { ButtonGoBack } from '../RegisterBeneficiary/styles';
import RadioSelectHour from './components/RadioSelectHour';
// import { SubTitle } from '../Refund/styles';
import { CloseButton } from '../../components/Modal2/styles';
import { handleGoBackClicked } from '../../utils/localStorageConsulta';

const ON_DUTY = 'Presencial';
const TELECONSULTATION = 'Online';

interface IScheduleCreate {
  timetable?: IUserTimeTable;
  beneficiary?: IBeneficiariesOfAnInsured;
}
const SchedulingCreate: React.FC<IScheduleCreate> = () => {
  const { colors } = useTheme();
  const [beneficiary, setBeneficiary] = useState<IBeneficiariesOfAnInsured>();
  const [timetable, setTimetable] = useState<IBeneficiariesOfAnInsured>();

  const history = useHistory();
  const location = useLocation().state;
  useEffect(() => {
    const locationData = location as any;
    if (
      !locationData ||
      !locationData?.timetable ||
      !locationData.beneficiary
    ) {
      history.push('/pesquisa-e-marcacao-de-agenda');
      return;
    }
    setTimetable(locationData?.timetable);
    setBeneficiary(locationData.beneficiary);
  }, [location, timetable, beneficiary, history]);

  const hourSelectInputRef = useRef<SelectHandles>(null);

  const confirmModalRef = useRef<ModalHandles>(null);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedHour, setSelectedHour] = useState<string>();
  const [selectedTimeTable, setSelectedTimeTable] = useState<
    IUserTimeTable['schedules'][0] | null
  >();
  const [hoursAvailable, setHoursAvailable] = useState<string[] | any>([]);
  const [scheduleInfos, setSchedulesInfos] = useState<
    IUserTimeTable['schedules'] | undefined
  >([]);
  const [beneficiaryInfo, setBeneficiaryInfo] = useState(beneficiary);
  const [scheduleInfo, setScheduleInfo] = useState(null);
  const scheduleInfoRef = useRef(null as any);
  const [phoneuser, setPhoneuser] = useState<any>();
  const [useremail, setuseremail] = useState<any>('');

  const formRef = useRef<FormHandles>(null);
  const emailRef = useRef<any>(null);
  const formatDateToApiValue = useCallback(
    (date: Date) => fns.format(date, 'dd/MM/yyyy HH:mm'),
    [],
  );

  const formatApiValueToDate = useCallback(
    (value: any) => fns.parse(value, 'dd/MM/yyyy HH:mm', new Date()),
    [],
  );

  const schedulesFormatted = useMemo(() => {
    return timetable?.schedules?.map((date) =>
      formatApiValueToDate(date?.scheduledTo),
    );
  }, [formatApiValueToDate, timetable?.schedules]);

  // console.log(schedulesFormatted);

  const isOnDuty = useMemo(
    () =>
      timetable?.doctorName === ON_DUTY &&
      timetable?.doctorOffice === TELECONSULTATION,
    [timetable],
  );

  const getScheduleDateHours = useCallback(
    (date: Date) => {
      if (!timetable) return [];
      const result = timetable?.schedules.reduce((acc, curr) => {
        const currDate = formatApiValueToDate(curr.scheduledTo);
        const isSameDay = fns.isSameDay(date, currDate);

        if (isSameDay) {
          const accHourString = fns.format(currDate, 'HH:mm');
          if (!acc.includes(accHourString)) acc.push(accHourString);
        }

        return acc;
      }, [] as string[]);

      setSelectedDate(date);
      setHoursAvailable(result);

      if (selectedHour) {
        setSelectedHour(result[0]);
        hourSelectInputRef?.current?.setValue(result[0], result[0]);
      }

      return result;
    },
    [timetable, selectedHour, formatApiValueToDate],
  );

  const getScheduleInfos = useCallback(
    // eslint-disable-next-line no-shadow
    (date: Date, time: string) => {
      Notiflix.Block.circle('.notiflix-table-loading');
      const [hour, minutes] = time.split(':');
      const formattedDate = fns.setMinutes(
        fns.setHours(date, Number(hour)),
        Number(minutes),
      );

      const apiDate = formatDateToApiValue(formattedDate);

      const schedules = timetable?.schedules.filter(
        (item) => item.scheduledTo === apiDate,
      );

      setSchedulesInfos(schedules);

      Notiflix.Block.remove('.notiflix-table-loading');
    },
    [formatDateToApiValue, timetable],
  );
  const getAddressLine = useCallback(() => {
    if (timetable && beneficiary) {
      const { houseNumber, streetDescription, district, zipCode, city } =
        timetable;

      if (houseNumber && streetDescription && district && zipCode && city) {
        return `${timetable?.houseNumber} ${timetable?.streetDescription}, ${timetable?.district}, ${timetable?.zipCode} - ${timetable?.city}`;
      }
    }
    return timetable?.doctorOffice;
  }, [timetable]);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Informe um email válido')
      .required('Informe seu E-mail.'),
    phone: Yup.string()
      .required('Informe um telefone')
      .min(10, 'Informe um telefone válido'), // Minimum phone length
  });

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => {
    if (name === 'email' || name === 'phone') {
      const result = event.target.value;
      formRef.current?.setFieldValue(name, result);
    } else {
      const result = event.target.value;
      formRef.current?.setFieldValue(name, result);
    }
  };

  const handleConfirm = useCallback(async () => {
    formRef.current?.setErrors({});
    const phone = formRef.current?.getFieldValue('phone');
    const email = formRef.current?.getFieldValue('email');
    const data = formRef.current?.getData();
    try {
      await schema.validate(data, {
        abortEarly: false,
      });
      Notiflix.Loading.circle(undefined, {
        zindex: 999999,
      });

      if (selectedTimeTable && beneficiary) {
        const changedContactInformation =
          phone !== beneficiary.dddCellPhone + beneficiary.cellphone ||
          email !== beneficiary?.email;

        const emailIfUser = email || beneficiary.email;
        const phoneIfUser =
          phone || beneficiary.dddCellPhone + beneficiary.cellphone;
        const result = await Helper.createUserTimeTable({
          idTime: selectedTimeTable?.idTime ?? '',
          userUnimedId: beneficiary?.userUnimedId,
          email: emailIfUser,
          phone: phoneIfUser,
          changedContactInformation: changedContactInformation ? 'S' : 'N',
        });
        if (result) {
          Notiflix.Notify.success('Agendamento cadastrado com sucesso!');
          confirmModalRef.current?.close();
          Modal2.Close();
          history.push('/visualizacao-e-cancelamentos-de-agendamentos');
        }
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Set errors from Yup validation
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        Notiflix.Notify.failure(
          'Campos vazios ou inválidos, tente novamente',
          undefined,
          {
            zindex: 999999,
          },
        );
      }

      if (error?.response?.data?.message) {
        const e = error as AxiosError;
        const message =
          e.response?.data.message ??
          'Ops.. Não conseguimos processar a requisição no momento, tente novamente mais tarde.';
        Notiflix.Notify.failure(message, undefined, {
          zindex: 999999,
        });
      }
      if (!error?.response?.data?.message && !Yup.ValidationError) {
        Notiflix.Notify.failure(
          'Não conseguimos processar a requisição,tenta mais tarde',
          undefined,
          {
            zindex: 999999,
          },
        );
      }
    } finally {
      Notiflix.Loading.remove();
    }
  }, [beneficiary, beneficiaryInfo, history, selectedTimeTable]);

  const handleSelectSchedule = useCallback(
    (_scheduleInfo: IUserTimeTable['schedules'][0]) => {
      setSelectedTimeTable(_scheduleInfo);
    },
    [selectedTimeTable],
  );

  const getDataUser = useCallback(async () => {
    const pattern = ['(99) 9999-9999', '(99) 9 9999-9999'];

    if (formRef.current && beneficiary) {
      const formattedValue = mask(
        beneficiary?.dddCellPhone + beneficiary?.cellphone,
        pattern,
      );
      setPhoneuser(formattedValue);
      // updateFieldValue("email", beneficiary.email);
    }
  }, [beneficiary]);

  useEffect(() => {
    getDataUser();
    setBeneficiaryInfo(beneficiary);
  }, [beneficiary, beneficiaryInfo, selectedTimeTable]);

  useEffect(() => {
    if (selectedTimeTable) {
      Modal2.Generic({
        closable: false,
        autoWidth: true,
        title: 'Confirmar Agenda',
        subtitle: `${timetable?.doctorName} - ${timetable?.doctorOffice}`,
        children: selectedTimeTable && (
          <S.ModalConfirmContainer>
            <CloseButton
              onClick={() => {
                Modal2.Close();
                setSelectedTimeTable(null);
              }}
              size={30}
              style={{ top: '20px', right: '30px' }}
            >
              Voltar
            </CloseButton>
            {selectedTimeTable && (
              <S.ModalSubtitle>
                Horário da agenda: {selectedTimeTable?.scheduledTo}
              </S.ModalSubtitle>
            )}
            <S.ModalText>
              <b>Endereço:</b> {getAddressLine()}
            </S.ModalText>

            {selectedTimeTable?.procedureDescription && (
              <S.ModalText>
                <b>Descrição:</b> {selectedTimeTable?.procedureDescription}
              </S.ModalText>
            )}

            <S.ModalText
              style={{ padding: '16px 0 8px 0', fontWeight: 'bold' }}
            >
              <b>Confirme os dados do beneficiário:</b>
            </S.ModalText>

            <S.FieldContainer>
              <FormWrapper
                onSubmit={() => {
                  //
                }}
                // formLook
                ref={formRef}
                style={{
                  gap: '38px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  boxShadow: 'none',
                }}
              >
                {beneficiary && (
                  <>
                    <Input
                      name="phone"
                      title="DDD/Telefone:"
                      formLook
                      masks={['(99) 9999-9999', '(99) 9 9999-9999']}
                      defaultValue={phoneuser}
                      onChange={(event) => handleChangeInput(event, 'phone')}
                    />

                    <Input
                      name="email"
                      title="E-mail:"
                      defaultValue={beneficiary?.email}
                      onChange={(event) => handleChangeInput(event, 'email')}
                      formLook
                    />
                  </>
                )}
                <S.ModalConfirmButtonsContainer>
                  <Button
                    modal
                    autoWidth
                    secondary
                    onClick={() => {
                      Modal2.Close();
                      setSelectedTimeTable(null);
                    }}
                  >
                    Voltar
                  </Button>
                  <Button
                    modal
                    type="submit"
                    autoWidth
                    className="notifix-modal-cancel"
                    onClick={handleConfirm}
                  >
                    Enviar
                  </Button>
                </S.ModalConfirmButtonsContainer>
              </FormWrapper>
            </S.FieldContainer>
          </S.ModalConfirmContainer>
        ),
      });
    }
  }, [
    selectedTimeTable,
    scheduleInfo,
    timetable,
    getAddressLine,
    handleConfirm,
    beneficiary,
    phoneuser,
  ]);

  //
  // const confirmValidation = Yup.object().shape({
  //   email: Yup.string().email("Email inválido").required("Email obrigatório"),
  //   phone: Yup.number()
  //     .required("Telefone obrigatório")
  //     .min(10, "Digite um telefone válido"),
  // });
  //
  useEffect(() => {
    if (selectedDate && selectedHour) {
      getScheduleInfos(selectedDate, selectedHour);
    }
  }, [getScheduleInfos, selectedDate, selectedHour, scheduleInfo]);

  useEffect(() => {
    if (isOnDuty) {
      const today = new Date();
      setSelectedDate(today);
      const hours = getScheduleDateHours(today);
      if (hours.length > 0) {
        getScheduleInfos(today, hours[0]);
      }
    }
  }, [
    getScheduleDateHours,
    timetable,
    hourSelectInputRef,
    getScheduleInfos,
    isOnDuty,
  ]);
  return (
    <PageWrapper gridTemplateRows="auto 1fr">
      <S.Container>
        <Title themeColor={colors.palet.institutional}>
          Consultar Disponibilidade
        </Title>

        <FormWrapper
          name="agendamento-de-consulta"
          onSubmit={() => {
            //
          }}
          formLook
          ref={formRef}
          style={{ display: 'flex', flexWrap: 'wrap', marginTop: '0px' }}
        >
          <Title
            themeColor={colors.palet.institutional11}
            style={{
              marginTop: '0px',
              textAlign: 'center',
              fontSize: '1.5rem',
              padding: '0px 0 20px 0',
              width: '100%',
            }}
          >
            {timetable?.doctorName} - {timetable?.doctorOffice}
          </Title>
          <S.DateSelectRow>
            <DatePicker
              name="selectDate"
              formLook
              title="Selecione a Data"
              autoComplete="off"
              icon
              // startOpen
              // disabled={isOnDuty}
              onChange={getScheduleDateHours}
              value={
                selectedDate
                  ? fns.format(selectedDate, 'dd/MM/yyyy')
                  : undefined
              }
              includeDates={schedulesFormatted}
            />
          </S.DateSelectRow>
          <S.RowSelectHours>
            {!!hoursAvailable.length && (
              <>
                {/* <Select
                  ref={hourSelectInputRef}
                  options={
                    hoursAvailable
                      ? hoursAvailable.map((item: any) => ({
                          title: item,
                          value: item,
                        }))
                      : []
                  }
                  name="hours"
                  formLook
                  title="Selecione a Hora"
                  disabled={!hoursAvailable}
                  changeCallback={setSelectedHour}
                /> */}
                <RadioSelectHour
                  hoursAvailable={hoursAvailable}
                  selectedHour={selectedHour}
                  onHourChange={setSelectedHour}
                />
              </>
            )}
          </S.RowSelectHours>
        </FormWrapper>
        {!!scheduleInfos?.length && (
          <Table
            items={scheduleInfos}
            className="notiflix-table-loading"
            autoLayout
          >
            <Column header="Data e Hora" field="scheduledTo" />
            <Column
              header="Tipo atendimento"
              field="isTeleconsultation"
              body={(row) =>
                row.isTeleconsultation === 'S' ? TELECONSULTATION : ON_DUTY
              }
            />
            <Column
              body={(row) => (
                <Button
                  type="button"
                  formLook
                  onClick={() => {
                    handleSelectSchedule(row);
                  }}
                >
                  Selecionar
                </Button>
              )}
            />
          </Table>
        )}
        <S.FooterButtons>
          <ButtonGoBack
            onClick={() => {
              handleGoBackClicked();
              history.goBack();
            }}
            type="button"
            autoWidth
            secondary
          >
            Voltar para pesquisa
          </ButtonGoBack>
        </S.FooterButtons>
      </S.Container>
    </PageWrapper>
  );
};

export default SchedulingCreate;
