import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Notiflix from 'notiflix';
import Input from '../../components/Input';

import {
  Container,
  Background,
  Content,
  BemVindo,
  BemVindoMobile,
  ContentMobile,
  InputTerms,
  Terms,
  ModalTitle,
  ModalContent,
  ButtonGoBack,
  ButtonSubmit,
  ContainerLogoW,
  ModalButton,
  ModalButtonContainer,
  ButtonWrapper,
} from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import { GrupowSvg, LogoUnimedSvg } from '../../components/Svg';
import Datepicker from '../../components/DatePicker';
import {
  dateToPTBR,
  ObjectGetValueOfKey,
  ptBRToDate,
} from '../../utils/formatt';
import api from '../../services/api/api';
import Modal2 from '../../components/Modal2';
import Button from '../../components/Button';
import PasswordList from '../../components/PasswordList';

interface DateProps {
  value: Date | null;
  error: string;
  valid: boolean;
}
interface LoginProps {
  card: string;
  cpf: string;
  birthday: string;
  email: string;
  password: string;
  confirmpassword: string;
}

const cadastroBeneficiarioDesktop = (
  <>
    Cadastro de
    <br />
    beneficiário
  </>
);
Notiflix.Block.init({ svgSize: '20px' });

const RegisterBeneficiary: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [userHasCpf, setUserHasCpf] = useState(true);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [membershipValid, setMembershipValid] = useState(false);
  const [documentIsValid, setDocumentIsValid] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  const [allValid, setAllValid] = useState(false);
  const [birthDate, setBirthDate] = useState<DateProps>({
    value: null,
    error: '',
    valid: false,
  });

  const handleToogleModal = useCallback(async () => {
    try {
      Notiflix.Loading.circle();
      const { data } = await api.get(
        '/gw/translation?key=termo-de-uso&language=PT',
      );
      const text = ObjectGetValueOfKey(data.content, 'termo-de-uso');

      const content = (
        <>
          <ModalTitle>Termos de Uso & Aviso de Privacidade</ModalTitle>
          <ModalContent dangerouslySetInnerHTML={{ __html: text }} />
          <ModalButton
            type="button"
            onClick={() => Modal2.Close()}
            outlined
            modal
            autoWidth
          >
            Fechar
          </ModalButton>
        </>
      );

      Modal2.Generic({
        children: content,
        closable: false,
      });
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Estamos com algum erro no servidor... tente mais tarde...',
      );
    } finally {
      Notiflix.Loading.remove();
    }
  }, []);

  const fillFormByURL = () => {
    const cpf = query.get('cpf');
    const carteirinha = query.get('carteirinha');
    const datanasc = query.get('datanasc');
    const email = query.get('email');
    const password = '';
    const confirmpassword = '';

    formRef.current?.setData({
      card: carteirinha,
      cpf,
      email,
      password,
      confirmpassword,
    });

    if (query.get('datanasc')) {
      try {
        setBirthDate({
          value: ptBRToDate(datanasc || ''),
          error: '',
          valid: false,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const checkCardIsCpfOrRG = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-block-cpf');
      const card = formRef.current?.getFieldValue('card');
      if (card) {
        const { data } = await api.get(
          `/beneficiary/fields-to-insert?card=${card}`,
        );
        const { ieCampo } = data.content[0];
        setUserHasCpf(ieCampo === 'CPF');
      }
    } catch (error) {
      Notiflix.Notify.failure(
        'Não pudemos determinar se sua carteirinha precisa de CPF ou RG. Por favor tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-block-cpf');
    }
  }, []);

  const handleChangeBirthday = useCallback(async (data: Date) => {
    try {
      const card = formRef.current?.getFieldValue('card');
      if (!card) {
        setBirthDate({ value: data, error: '', valid: false });
        return;
      }
      const birth = dateToPTBR(data);
      await api.get(
        `/beneficiary/validate-registration-fields?card=${card}&birthDate=${birth}`,
      );
      setBirthDate({ value: data, error: '', valid: true });
    } catch (error) {
      if (error.response?.data?.message) {
        setBirthDate({
          value: data,
          error: error.response.data.message,
          valid: false,
        });
      } else {
        setBirthDate({
          value: data,
          error: 'Não conseguimos validar no servidor.',
          valid: false,
        });
      }
    }
  }, []);

  const handleAcceptTerms = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAcceptedTerms(event.target.checked);
    },
    [],
  );

  const handleSearchForThisMember = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-block-card');
      formRef.current?.setFieldError('card', '');
      if (!formRef.current?.getFieldValue('card')) return;
      const { data } = await api.get(
        `/beneficiary/validate-registration-fields?card=${formRef.current?.getFieldValue(
          'card',
        )}`,
      );
      const { content } = data;
      if (content) {
        /**
         * 8.6 RN05
         * O sistema já deve validar se a carteira é existente e se tem cadastro assim que o campo for preenchido.
         * Caso carteira inexistente, já bloqueia o avanço e apresenta mensagem ao usuário conforme retorno da API.
         * Caso carteira já tenha login ativo, voltar o usuário para a tela 5. Autenticação.
         */
        if (content.beneficiaryRegisteredAndActive) {
          Modal2.Success({
            closable: true,
            subtitle: 'Sua carteirinha já está ativa',
            text: 'Sua carteirinha já está ativa, vamos te direcionar ao login, ok?',
            children: (
              <ModalButtonContainer>
                <Button onClick={() => Modal2.Close()} modal outlined>
                  Não, obrigado
                </Button>
                <Button
                  onClick={() => {
                    history.push('/login');
                    Modal2.Close();
                  }}
                  modal
                >
                  Ok, vamos lá
                </Button>
              </ModalButtonContainer>
            ),
          });
          formRef.current?.setFieldError(
            'card',
            'Sua carteirinha já está ativa.',
          );
        }
        if (content.beneficiaryRegisteredAndPendent) {
          Modal2.Failure({
            closable: true,
            subtitle: 'Você ainda não ativou sua carteirinha',
            text: 'Verificamos que sua carteirinha já esta cadastrada, mas ainda está pendente de ativação. Cheque seu e-mail para ativar.',
            children: (
              <Button
                onClick={() => {
                  history.push('/login');
                  Modal2.Close();
                }}
                modal
              >
                Ok. Vou checar meu email
              </Button>
            ),
          });
          formRef.current?.setFieldError(
            'card',
            'Ative seu cadastro pelo seu email.',
          );
        }
        setMembershipValid(false);
      } else {
        setMembershipValid(true);
        checkCardIsCpfOrRG();
      }
    } catch (err) {
      formRef.current?.setFieldError('card', 'Não encontramos sua carteirinha');
      setMembershipValid(false);
    } finally {
      Notiflix.Block.remove('.notiflix-block-card');
    }
  }, [checkCardIsCpfOrRG, history]);

  const handleCheckDocument = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-block-cpf');
      setDocumentIsValid(false);
      formRef.current?.setFieldError('cpf', '');
      const card = formRef.current?.getFieldValue('card');
      const document = formRef.current?.getFieldValue('cpf');
      if (!document || !card) {
        return;
      }

      await api.get(
        `/beneficiary/validate-registration-fields?card=${card}&document=${document}`,
      );
      setDocumentIsValid(true);
    } catch (err) {
      if (err?.response?.data?.message) {
        formRef.current?.setFieldError('cpf', err.response.data.message);
      }
    } finally {
      Notiflix.Block.remove('.notiflix-block-cpf');
    }
  }, []);

  const handleCheckEmail = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-block-email');
      setEmailIsValid(false);
      formRef.current?.setFieldError('email', '');
      const card = formRef.current?.getFieldValue('card');
      const email = formRef.current?.getFieldValue('email');
      if (!card || !email) {
        return;
      }
      await api.get(
        `/beneficiary/validate-registration-fields?card=${card}&email=${email}`,
      );
      setEmailIsValid(true);
    } catch (err) {
      if (err?.response?.data?.message) {
        formRef.current?.setFieldError(
          'email',
          `E-mail diferente do que existe no seu cadastro<br/>
          junto a Operadora. Entre em contato para<br/>
          confirmar a informação (SAC) 0800-6470026<br/>
          ou (Whats +55 47 9901-0459).`,
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-block-email');
    }
  }, []);

  const handleFormSubmit = useCallback(
    async (data: LoginProps) => {
      try {
        formRef.current?.setErrors({});
        try {
          // eslint-disable-next-line no-param-reassign
          data.birthday = birthDate.value ? dateToPTBR(birthDate.value) : '';
        } catch (error) {
          // eslint-disable-next-line no-param-reassign
          data.birthday = '';
        }

        const schema = Yup.object().shape({
          card: Yup.string().required('Informe sua Carteirinha'),
          cpf: Yup.string().required('Informe seu CPF'),
          birthday: Yup.string().required('Informe sua data de nascimento'),
          email: Yup.string()
            .required('Informe seu e-mail')
            .email('Informe um e-mail válido'),
          password: Yup.string()
            .required('Informe uma senha')
            .min(6, 'Mínimo 6 caracteres'),
          confirmpassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'As senhas não coincidem',
          ),
          // check: Yup.boolean().oneOf([true], 'Checked Obrigatorio'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        if (
          membershipValid &&
          documentIsValid &&
          emailIsValid &&
          birthDate.valid
        ) {
          const { password, card } = data;
          Notiflix.Loading.circle('');

          const { data: dataApi } = await api.put(
            '/beneficiary/create-beneficiary',
            {
              card,
              password,
            },
          );
          if (dataApi.content) {
            const { content } = dataApi;
            if (content.beneficiaryRegisteredAndActive) {
              Modal2.Success({
                closable: true,
                subtitle: 'Sua carteirinha já está ativa',
                text: 'Sua carteirinha já está ativa, vamos te direcionar ao login, ok?',
                children: (
                  <ModalButtonContainer>
                    <Button onClick={() => Modal2.Close()} modal outlined>
                      Não, obrigado
                    </Button>
                    <Button
                      onClick={() => {
                        history.push('/login');
                        Modal2.Close();
                      }}
                      modal
                    >
                      Ok, vamos lá
                    </Button>
                  </ModalButtonContainer>
                ),
              });
            }
            if (content.beneficiaryRegisteredAndPendent) {
              Modal2.Failure({
                closable: true,
                subtitle: 'Você ainda não ativou sua carteirinha',
                text: 'Verificamos que sua carteirinha já esta cadastrada, mas ainda está pendente de ativação. Cheque seu e-mail para ativar.',
                children: (
                  <Button
                    onClick={() => {
                      history.push('/login');
                      Modal2.Close();
                    }}
                    modal
                  >
                    Ok. Vou checar meu email
                  </Button>
                ),
              });
            }
          } else {
            Modal2.Success({
              closable: true,
              subtitle: 'Cadastro enviado com sucesso!',
              text: 'Enviamos um e-mail para você com o restante das instruções para validar seu cadastro. Por favor, cheque seu e-mail.',
              children: (
                <Button modal onClick={() => Modal2.Close()}>
                  Ok
                </Button>
              ),
            });
            history.push('/login');
          }
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          if (errors.birthday) {
            setBirthDate((prev) => ({ ...prev, error: errors.birthday }));
          }
        } else {
          Notiflix.Notify.failure(
            err.response.message ??
              'Ops, algo deu errado. Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Loading.remove();
      }
    },
    [membershipValid, documentIsValid, emailIsValid, birthDate, history],
  );

  useEffect(() => {
    fillFormByURL();
  }, []);

  return (
    <Container>
      <Background>
        <BemVindo>{cadastroBeneficiarioDesktop}</BemVindo>
        <LogoUnimedSvg />
        <ContainerLogoW>
          <a
            href="https://www.grupow.com.br/"
            title="GrupoW Softwares para Internet"
            target="_blanck"
          >
            Desenvolvido por <strong>GrupoW Softwares para Internet</strong>
            <GrupowSvg />
          </a>
        </ContainerLogoW>
      </Background>
      <Content>
        <Form ref={formRef} onSubmit={handleFormSubmit}>
          <ContentMobile>
            <BemVindoMobile>{cadastroBeneficiarioDesktop}</BemVindoMobile>

            <Input
              name="card"
              placeholder="Carteirinha"
              isValid={membershipValid}
              onBlur={handleSearchForThisMember}
              masks={['9 999 999999999999 9']}
              className="notiflix-block-card"
            />
            <Input
              name="cpf"
              placeholder={userHasCpf ? 'CPF' : 'RG'}
              masks={
                userHasCpf ? ['999.999.999-99'] : ['9.999.999', '99.999.999']
              }
              className="notiflix-block-cpf"
              onBlur={handleCheckDocument}
              isValid={documentIsValid}
            />
            <Datepicker
              name="birthday"
              placeholder="Data de nascimento"
              onChange={handleChangeBirthday}
              selected={birthDate.value}
              error={birthDate.error}
              isValid={birthDate.valid}
              maxDate={new Date()}
            />
            <Input
              name="email"
              type="email"
              placeholder="E-mail"
              isValid={emailIsValid}
              onBlur={handleCheckEmail}
              className="notiflix-block-email"
            />
            <Input
              name="password"
              id="password"
              type="password"
              placeholder="Nova senha"
            />
            <Input
              name="confirmpassword"
              type="password"
              placeholder="Confirmar nova senha"
            />
            <PasswordList query="password" validation={setAllValid} />
          </ContentMobile>
          <InputTerms
            name="check"
            type="checkbox"
            label={
              <Terms type="button" onClick={handleToogleModal}>
                Eu concordo com o(s) TERMOS DE USO e AVISO DE PRIVACIDADE
              </Terms>
            }
            onChange={handleAcceptTerms}
          />
          <ButtonWrapper>
            <ButtonGoBack
              secondary
              onClick={() => history.goBack()}
              type="button"
            >
              Voltar
            </ButtonGoBack>
            <ButtonSubmit type="submit" disabled={!acceptedTerms || !allValid}>
              Salvar
            </ButtonSubmit>
          </ButtonWrapper>
        </Form>
        <ContainerLogoW className="mobile">
          <a
            href="https://www.grupow.com.br/"
            title="GrupoW Softwares para Internet"
            target="_blanck"
          >
            Desenvolvido por <strong>GrupoW Softwares para Internet</strong>
            <GrupowSvg />
          </a>
        </ContainerLogoW>
      </Content>
    </Container>
  );
};

export default RegisterBeneficiary;
