import styled from 'styled-components';
import Button from '../../../components/Button';
import FormWrapper from '../../../components/FormWrapper';
import SelectComponent from '../../../components/Select';
import TableComponent from '../../../components/Table';

export const Select = styled(SelectComponent)``;

export const Table = styled(TableComponent)`
  grid-column: 2/3;
  margin-bottom: 150px;

  @media (max-width: 768px) {
    grid-column: 1;
    margin-bottom: 30px;
  }
`;

export const Title = styled.h1`
  grid-column: 2/3;
  text-transform: uppercase;
  font-size: 2.55rem;
  line-height: 2.4rem;
  font-family: 'Unimed-Slab-Bold';
  /* color: ${(props) => props.theme.colors.palet.institutional3}; */
  color: red;

  text-align: center;
  padding: 30px;

  @media (max-width: 768px) {
    grid-column: 1;
    font-size: 1.7rem;
    line-height: 1.5rem;
  }
`;

export const CancelButton = styled(Button)`
  font-size: 0.7rem;
`;

export const ConfirmReceiveButtton = styled(Button)`
  font-size: 0.7rem;
  line-height: 0.9rem;
  max-width: 90.1px;
  font-family: 'Unimed-Sans-SemiBold';
  text-align: center;
`;

export const FormWrapperPeriodDate = styled(FormWrapper)`
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-column: 2/3;
  > button {
    text-transform: uppercase;
    &:first-of-type {
      margin-left: auto;
    }
  }
  @media (max-width: 768px) {
    grid-column: 1;
    > div {
      flex: 1 1 100%;
    }
  }
`;

export const Container = styled.div`
  grid-column: 2/3;
  margin-bottom: 30px;
`;

export const ActionsButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 8px;
  > button {
    text-transform: capitalize;
    white-space: normal;
  }
`;
