import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useState } from 'react';
import produce from 'immer';
import PageWrapper from '../../../components/PageWrapper';
import { Column } from '../../../components/Table';
import api from '../../../services/api/api';
import AddField, { getTag } from '../../../utils/tableUtils';
import Button from '../../../components/Button';

import {
  ActionsButtons,
  RequestDuplicateCardConsultTitle,
  Table,
} from './styles';
import Modal2 from '../../../components/Modal2';

const RequestDuplicateCardConsult: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [consultDatas, setConsultDatas] = useState<any[]>([]);

  const handleCancelDuplicateCard = useCallback(async (cardId) => {
    Notiflix.Block.circle(`.notiflix-cancel-${cardId}`);
    try {
      await api.put(`/beneficiary/cancel-card-request`, { cardId });

      setConsultDatas((prev) =>
        produce(prev, (draftState) => {
          const item = draftState.find((i) => i.cardId === cardId);
          item.status = 'Cancelado';
          item.canCancel = 'N';
        }),
      );

      Modal2.Success({
        closable: false,
        title: 'Perfeito!',
        text: `Pedido da segunda via foi cancelado com sucesso!`,
        children: (
          <>
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          </>
        ),
      });
    } catch (err) {
      Notiflix.Notify.failure(
        'Opss... Algo deu errado, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove(`.notiflix-cancel-${cardId}`);
    }
  }, []);

  const handleConfirmReceive = useCallback(async (cardId) => {
    try {
      Notiflix.Block.circle(`.notiflix-confirm-${cardId}`);

      await api.put(`/beneficiary/confirm-card-receipt`, {
        cardId,
      });

      setConsultDatas((prev) =>
        produce(prev, (draft) => {
          const row = draft.find((item) => item.cardId === cardId);
          row.status = 'Recebida pelo beneficiário/empresa';
        }),
      );

      Modal2.Success({
        closable: false,
        title: 'Perfeito!',
        text: 'Atualizamos o status para recebido.',
        children: (
          <>
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          </>
        ),
      });
    } catch (err) {
      Notiflix.Notify.failure(
        'Opss... Algo deu errado, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove(`.notiflix-confirm-${cardId}`);
    }
  }, []);

  const statusTemplate = useCallback(
    (field, data) => {
      switch (field) {
        case 'Encaminhado ao beneficiário/empresa':
          // return getTag('Status', 'Encaminhado', 'orange');
          return (
            <Button
              autoWidth
              sausageLook
              onClick={() => handleConfirmReceive(data.cardId)}
              className={`notiflix-confirm-${data.cardId}`}
            >
              Confirmar entrega
            </Button>
          );
        case 'Cancelado':
          return getTag('Status', 'Cancelado', 'red');
        case 'Recebido':
        case 'Recebida pelo beneficiário/empresa':
          return getTag('Status', 'Recebido', 'green');
        case 'Solicitado':
          return getTag('Status', 'Solicitado', 'orange');
        case 'Em análise pela operadora':
          return getTag('Status', 'Em análise', 'orange');
        default:
          return field;
      }
    },
    [handleConfirmReceive],
  );

  const getData = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');
      const { data } = await api.get(`/beneficiary/list-card-requests`);

      const { content } = data;
      if (content) {
        setConsultDatas(content);
      }
    } catch (err) {
      if (err.response?.status === 400) {
        setConsultDatas([]);
      } else {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dado do servidor. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper
      gridTemplateColumns="repeat(10, 1fr)"
      gridTemplateRows="auto 1fr"
    >
      <RequestDuplicateCardConsultTitle>
        Consultar segunda via do cartão
      </RequestDuplicateCardConsultTitle>

      <Table
        items={consultDatas}
        className="notiflix-table-loading"
        // columnResizeMode="fit"
      >
        <Column
          field="cardNumber"
          header="Carteirinha"
          sortable
          body={(data) => AddField(data.cardNumber, 'Carteirinha')}
          bodyStyle={{ wordBreak: 'break-word' }}
        />
        <Column
          field="insuredName"
          header="Nome do beneficiário"
          sortable
          body={(data) => AddField(data.insuredName, 'Nome do beneficiário')}
        />
        <Column
          field="sentDate"
          header="Envio"
          className="date"
          body={(data) => AddField(data.sentDate, 'Envio')}
        />
        <Column
          field="requestDate"
          header="Solicitação"
          className="date"
          body={(data) => AddField(data.requestDate, 'Solicitação')}
        />
        <Column
          field="expirationDate"
          header="Validade"
          className="date"
          body={(data) => AddField(data.expirationDate, 'Validade')}
        />
        <Column
          field="status"
          header="Status"
          sortable
          className="tag"
          body={(data) => statusTemplate(data.status, data)}
        />
        <Column
          style={{
            width: '100px',
          }}
          body={(data) => (
            <ActionsButtons>
              {data.canCancel !== 'N' && (
                <Button
                  onClick={() => handleCancelDuplicateCard(data.cardId)}
                  orangeButton
                  sausageLook
                  autoWidth
                  noChangeColor
                  className={`notiflix-cancel-${data.cardId}`}
                >
                  Cancelar
                </Button>
              )}
            </ActionsButtons>
          )}
        />
      </Table>
    </PageWrapper>
  );
};

export default RequestDuplicateCardConsult;
