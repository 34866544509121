import React from 'react';

const GrupowSvg: React.FC = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5945 0L13.7139 17.6545L12.8987 15.231L12.6997 15.8279L13.4232 18H14.0041L20 0H19.5945Z"
        fill="white"
      />
      <path
        d="M17.1547 0L11.2806 17.6545L9.25233 11.5664L9.0496 12.1753L10.9899 18H11.5704L17.5663 0H17.1547Z"
        fill="white"
      />
      <path
        d="M4.994 13.393L2.83873 6.92315H2.43326L4.79127 14.0018L4.994 13.393Z"
        fill="white"
      />
      <path
        d="M8.4414 10.3492L8.64414 9.74031L7.70573 6.92315H7.55474L3.98034 17.6545L0.405466 6.92315H0L3.68964 18H4.27056L7.63 7.91374L8.4414 10.3492Z"
        fill="white"
      />
      <path
        d="M12.0915 14.0018L12.2943 13.393L10.139 6.92315H9.98801L6.4136 17.6545L5.6022 15.2191L5.39947 15.8279L6.12291 18H6.70383L10.0637 7.91374L12.0915 14.0018Z"
        fill="white"
      />
    </svg>
  );
};

export default GrupowSvg;
