import styled from 'styled-components';

export const IncludeNoteText = styled.h2`
  color: ${(props) => props.theme.colors.palet.institutional3};
  text-transform: uppercase;
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 1rem;
  margin-top: 1rem;
  text-align: right;
  cursor: pointer;
  transition: all 500ms cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    color: ${(props) => props.theme.colors.palet.institutional2};
  }
`;

export const NoteText = styled.h2`
  color: ${(props) => props.theme.colors.palet.institutional3};
  text-transform: uppercase;
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  text-align: left;
  cursor: pointer;
  transition: all 500ms cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    color: ${(props) => props.theme.colors.palet.institutional2};
  }
`;
export const ContainerBlock = styled.div`
  @media (max-width: 768px) {
    padding-inline: 18px;
  }
`;
