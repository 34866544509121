/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { Link } from 'react-router-dom';
import Notiflix from 'notiflix';
import { v4 } from 'uuid';
import axios from 'axios';
import {
  BeneficiaryAndProductTitle,
  BeneficiaryFormContainer,
  AvatarContainer,
  ImformationsAdjustsContainer as InformationsAdjustsContainer,
  InformartionAdjustForm,
  ConsultButtonsContainer,
  ProductContainer,
  ProductForm,
  ContactServiceContainer,
  ServiceAttendanceConsumerContact,
  ANSContact,
  ANSIcon,
  Input,
  CameraIcon,
  RiskSharingContainer,
  RiskSharingTitle,
  RiskForm,
  ConsultButton,
} from './styles';
import Select from '../../components/Select';
import PageWrapper from '../../components/PageWrapper';
import api from '../../services/api/api';
import { BeneficiaryUser, UserCommon, useAuth } from '../../hooks/auth';
import Button from '../../components/Button';
import { ptBRToDate } from '../../utils/formatt';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';
import { FormWrapper } from '../TerminateBeneficiary/styles';

const BeneficiaryAndProduct: React.FC = () => {
  const { user: userT } = useAuth();
  const user = userT as UserCommon & BeneficiaryUser;
  const [beneficiariesOptions, setBeneficiariesOptions] = useState(() => {
    const options = [
      {
        title: '',
        value: '',
      },
    ];

    return {
      items: options,
      all: [] as any,
    };
  });
  const [dispatch, setDispatch] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [beneficiary, setBeneficiary] = useState({} as any);

  const getBeneficiaryAndProductInfos = useCallback(async (id: string) => {
    try {
      Notiflix.Block.circle('.notiflix-beneficiary-page');
      Notiflix.Block.circle('.notiflix-product');
      Notiflix.Block.circle('.notiflix-readjustments');
      Notiflix.Block.circle('.notiflix-avatar-loading');
      const { data } = await api.get(
        `/beneficiary/list-beneficiary-and-all-product-data?userUnimedId=${id}`,
      );
      const { content } = data;
      setBeneficiary(content[0]);
    } catch (err) {
      setBeneficiary([]);
      Notiflix.Notify.failure(
        'Ops, não conseguimos preencher o formulários de beneficiários e produtos... Tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-beneficiary-page');
      Notiflix.Block.remove('.notiflix-product');
      Notiflix.Block.remove('.notiflix-readjustments');
      Notiflix.Block.remove('.notiflix-avatar-loading');
    }
  }, []);

  const getParents = useCallback(async (userId: number) => {
    try {
      Notiflix.Block.circle('.notiflix-parents');
      const { data } = await api.get(
        `/contract/list-holder-and-dependents?userUnimedId=${userId}`,
      );

      if (data.content) {
        setBeneficiariesOptions(() => {
          const newValues = data.content.reduce(
            (
              acc: { title: string; value: string }[],
              act: { insuredName: string; userUnimedId: string },
            ) => {
              acc.push({
                title: act.insuredName,
                value: act.userUnimedId,
              });
              return acc;
            },
            [],
          );
          return {
            items: newValues,
            all: data.content,
          };
        });
      }
    } catch (err) {
      if (err?.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dependentes. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-parents');
    }
  }, []);

  const handleGenerateReport = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-report-loading');
      const { data } = await api.get(
        `/report/list-of-beneficiary-reports?contractId=${beneficiary.userUnimedId}&exhibitionLocation=3`,
      );
      const { nrSeqRelatorio, Parametros: P } = data.content[0];
      const params = [beneficiary.userUnimedId];
      const body = {
        nrSeqRelatorio: nrSeqRelatorio ?? '',
        Parametros: [
          ...P.map((p: { cdParametro: string }, index: number) => {
            return {
              id: p.cdParametro,
              valor: params[index],
            };
          }),
        ],
      };

      const { data: dataReport } = await api.post(
        `/report/generate-report`,
        body,
        {
          responseType: 'blob',
        },
      );
      downloadFileOfBlob(dataReport);
    } catch (err) {
      if (err?.response?.status === 400) {
        Notiflix.Notify.info(err.response.data?.message);
      } else {
        Notiflix.Notify.failure(
          'Não conseguimos buscar seu relatório. Por favor, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-report-loading');
    }
  }, [beneficiary]);

  const handleAvatarChange = useCallback(
    async (e) => {
      try {
        Notiflix.Block.circle('.notiflix-avatar-loading');

        const founded = beneficiariesOptions.all.find((i: any) => {
          return i.userUnimedId === beneficiary.userUnimedId;
        });

        const file = e.currentTarget.files[0];
        if (!file) return;
        const form = new FormData();

        form.append('beneficiaryImage', file);
        form.append('phisicalPersonCode', founded?.phisicalPersonCode);

        await api.post('/beneficiary/send-beneficiary-image', form);
        setDispatch(v4());

        // Modal2.Success({
        //   closable: false,
        //   title: 'Perfeito!',
        //   subtitle: 'Enviamos sua imagem',
        //   text: 'A sua imagem passará por um processo de avaliação',
        //   children: (
        //     <>
        //       <Button modal onClick={() => Modal2.Close()}>
        //         Ok
        //       </Button>
        //     </>
        //   ),
        // });
      } catch (err) {
        if (axios.isAxiosError(err) && err?.response?.data?.message) {
          Notiflix.Notify.info(err.response.data.message);
        } else {
          Notiflix.Notify.info(
            'Ops... Não conseguimos concluir a troca da sua imagem. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove('.notiflix-avatar-loading');
      }
    },
    [beneficiariesOptions, beneficiary],
  );

  const gridAreas = [
    'a   b     b g',
    'a avatar  f g',
    'a   i     f g',
    'a   x     d g',
    'a   x     d g',
    'a   x     d g',
    'a   x     h g',
    'ft ft    ft ft',//eslint-disable-line
  ];

  const urlImg = useMemo(() => {
    const founded = beneficiariesOptions.all.find(
      (i: any) => i.userUnimedId === beneficiary.userUnimedId,
    );
    if (founded?.phisicalPersonCode === user?.physicalPersonalCode) {
      const userAvatar =
        document.querySelector<HTMLImageElement>('#user_avatar');
      if (userAvatar) {
        userAvatar.src = `https://api.unimedblumenau.com.br/beneficiary/get-beneficiary-image?phisicalPersonCode=${user.physicalPersonalCode}&dispatch=${dispatch}`;
      }
    }
    return (
      <img
        src={`https://api.unimedblumenau.com.br/beneficiary/get-beneficiary-image?phisicalPersonCode=${
          founded?.phisicalPersonCode ?? user.physicalPersonalCode
        }&dispatch=${dispatch}`}
        alt={beneficiary?.name}
        key={dispatch}
      />
    );
  }, [beneficiariesOptions, beneficiary, user, dispatch]);

  const isAdult = useCallback((birthDate: string) => {
    const birth = ptBRToDate(birthDate);
    birth.setFullYear(birth.getFullYear() + 18);
    return birth.getTime() < new Date().getTime();
  }, []);

  const showCPT = useMemo(() => {
    return (
      (user.isOwner && user.name === beneficiary.beneficiaryName) ||
      (beneficiary.birthDate && !isAdult(beneficiary.birthDate)) ||
      !user.isOwner
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, beneficiary]);

  useEffect(() => {
    if (user.userUnimedId) {
      getParents(user.userUnimedId);
      getBeneficiaryAndProductInfos(`${user.userUnimedId}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <PageWrapper
      gridTemplateColumns="1fr 2fr 5fr 2fr"
      gridTemplateAreas={gridAreas}
      gridTemplateRows="repeat(7,auto) 1fr"
    >
      <BeneficiaryAndProductTitle>
        <h1>Dados do beneficiário e do produto</h1>
      </BeneficiaryAndProductTitle>
      <AvatarContainer className="notiflix-avatar-loading">
        <div className="avatar-notiflix-loading">
          <label>
            <CameraIcon />
            <span>Trocar Foto</span>
            <input type="file" onChange={handleAvatarChange} accept=".jpg" />
          </label>
        </div>
        {urlImg}
      </AvatarContainer>
      <BeneficiaryFormContainer>
        <h1>Beneficiário</h1>
        <FormWrapper onSubmit={handleGenerateReport}>
          <Select
            name="typeuser"
            changeCallback={getBeneficiaryAndProductInfos}
            options={beneficiariesOptions.items}
            disabled={!user?.isOwner}
            className="notiflix-parents text-bold"
            widthContainerDesktop="50%"
            formLook
            liveReload
          />
        </FormWrapper>
        <FormWrapper
          onSubmit={() => {
            //
          }}
          className="notiflix-beneficiary-page"
          style={{ marginTop: '24px' }}
        >
          <Input
            name="birthDate"
            insideInputTitle="Data de Nascimento"
            value={beneficiary?.birthDate ?? ''}
            widthContainerDesktop="35%"
            disabled
          />
          <Input
            name="cns"
            insideInputTitle="CNS"
            value={beneficiary?.nationalSusCardNumber ?? ''}
            widthContainerDesktop="65%"
            disabled
          />
          <Input
            name="wallet"
            insideInputTitle="Carteira"
            value={beneficiary?.cardNumber ?? ''}
            widthContainerDesktop="65%"
            disabled
          />
          <Input
            name="walletValid"
            insideInputTitle="Validade Carteira"
            value={beneficiary?.cardExpirateDate ?? ''}
            widthContainerDesktop="35%"
            disabled
          />
          <Input
            name="stipulator"
            insideInputTitle="Estipulante"
            value={beneficiary?.stipulatorName ?? ''}
            disabled
          />
          <Input
            name="contractDate"
            insideInputTitle="Data de Contratação"
            widthContainerDesktop="65%"
            value={beneficiary?.hiringDate ?? ''}
            disabled
          />
          <Input
            name="contractDateAtOperation"
            insideInputTitle="Data Inclusão na Operadora"
            value={beneficiary?.operatorInclusionDate ?? ''}
            widthContainerDesktop="35%"
            disabled
          />
          <Input
            name="ansRecord"
            insideInputTitle="Registro operadora ANS"
            // value={beneficiary?.AnsProtocol ?? ''}
            value="334561"
            widthContainerDesktop="40%"
            disabled
          />
          <Input
            name="payerName"
            insideInputTitle="Nome do Pagador"
            value={beneficiary?.payerSName ?? ''}
            widthContainerDesktop="60%"
            disabled
          />
        </FormWrapper>
      </BeneficiaryFormContainer>
      <ProductContainer className="notiflix-parents">
        <h2>Produto</h2>
        <ProductForm
          onSubmit={() => {
            //
          }}
          className="notiflix-product"
        >
          <Input
            name="contractName"
            insideInputTitle="Nome do plano"
            value={beneficiary?.productName ?? ''}
            disabled
            widthContainerDesktop="60%"
          />
          <Input
            name="contractStatus"
            insideInputTitle="Situação do plano"
            widthContainerDesktop="40%"
            value={beneficiary?.situation ?? ''}
            disabled
          />
          <Input
            name="segmentation"
            insideInputTitle="Segmentação"
            widthContainerDesktop="50%"
            value={beneficiary?.assistanceSegmentation ?? ''}
            disabled
          />
          <Input
            name="regulation"
            insideInputTitle="Regulamentação"
            widthContainerDesktop="50%"
            value={beneficiary?.regulation ?? ''}
            disabled
          />
          <Input
            name="contractKind"
            insideInputTitle="tipo de contratação"
            widthContainerDesktop="35%"
            value={beneficiary?.hiringType ?? ''}
            disabled
          />
          <Input
            name="standardAccommodation"
            insideInputTitle="Padrão Acomodação"
            widthContainerDesktop="65%"
            value={beneficiary?.accommodation ?? ''}
            disabled
          />
          <Input
            name="numberRecordAns"
            insideInputTitle="Número registro ans"
            widthContainerDesktop="50%"
            value={beneficiary?.AnsProtocol ?? ''}
            disabled
          />
          <Input
            name="scpa"
            insideInputTitle="scpa"
            widthContainerDesktop="50%"
            value={beneficiary?.scpaCode ?? ''}
            disabled
          />
          <Input
            name="moderatingFactor"
            insideInputTitle="Fator moderador (coparticipação)"
            widthContainerDesktop="40%"
            value={beneficiary?.moderatingFactor ?? ''}
            disabled
          />
          <Input
            name="couverage"
            insideInputTitle="Abrangência"
            widthContainerDesktop="60%"
            value={beneficiary?.geographicCoverage ?? ''}
            disabled
          />
        </ProductForm>
      </ProductContainer>

      <RiskSharingContainer>
        {beneficiary?.riskSharing && (
          <>
            <RiskSharingTitle>Compartilhamento de risco</RiskSharingTitle>
            <RiskForm
              onSubmit={() => {
                //
              }}
            >
              {beneficiary.riskSharing?.map((individualRisk: any) => {
                return (
                  <React.Fragment
                    key={`ANS-${individualRisk?.operatorRegistration}`}
                  >
                    <Input
                      name="ansRecordOperator"
                      insideInputTitle="Registro and operador intermediária"
                      widthContainerDesktop="35%"
                      value={individualRisk?.operatorRegistration}
                      disabled
                    />
                    <Input
                      name="operatorName"
                      insideInputTitle="Nome da Operadora"
                      widthContainerDesktop="65%"
                      value={individualRisk?.operatorName}
                      disabled
                    />
                  </React.Fragment>
                );
              })}
            </RiskForm>
          </>
        )}
      </RiskSharingContainer>
      <ConsultButtonsContainer>
        {/* {(beneficiary.birthDate
          ? !isAdult(beneficiary.birthDate) ||
            beneficiary.payerSName === beneficiary.beneficiaryName
          : false) && ( */}
        <>
          <Link
            to={`/carencia/${beneficiary.userUnimedId}/${beneficiary.beneficiaryName}`}
          >
            <ConsultButton greenButton>Consultar Carência</ConsultButton>
          </Link>
          {showCPT && (
            <Link
              to={`/cobertura-parcial-temporaria/${beneficiary?.userUnimedId}`}
            >
              <ConsultButton greenButton>Consultar CPT</ConsultButton>
            </Link>
          )}
        </>
        {/* )} */}
        <Button
          type="button"
          greenButton
          className="notiflix-report-loading"
          onClick={() => handleGenerateReport()}
        >
          Relatório
        </Button>
      </ConsultButtonsContainer>
      {beneficiary?.readjustmentInformation && (
        <InformationsAdjustsContainer className="notiflix-readjustments">
          <>
            <h2>Informações sobre reajustes</h2>
            <InformartionAdjustForm
              onSubmit={() => {
                //
              }}
            >
              <Input
                name="adjustMonth"
                insideInputTitle="Mês Do Reajuste"
                value={
                  beneficiary.readjustmentInformation[0]?.readjustmentMonth
                }
                disabled
              />
              <Input
                name="percentSetted"
                insideInputTitle="Percentual Aplicado"
                value={
                  beneficiary.readjustmentInformation[0]?.percentageApplied
                }
                disabled
              />
              <Input
                name="birthday"
                insideInputTitle="Motivo de Reajuste"
                value={
                  beneficiary.readjustmentInformation[0]?.readjustmentReason
                }
                disabled
              />
            </InformartionAdjustForm>
          </>
        </InformationsAdjustsContainer>
      )}
      <ContactServiceContainer>
        <ServiceAttendanceConsumerContact>
          <h3>Serviços de atendimento ao consumidor</h3>
          <a href="tel:+08006470026">0800 647.0026</a>
        </ServiceAttendanceConsumerContact>
        <ANSContact>
          <a target="_blank" rel="noreferrer" href="https://www.gov.br/ans">
            <ANSIcon />
          </a>
          <a href="tel:+08007019656">0800 701.9656</a>
          <a target="_blank" rel="noreferrer" href="https://www.gov.br/ans">
            www.gov.br/ans
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.gov.br/ans/pt-br/canais_atendimento/nossos-enderecos"
          >
            Núcleos de fiscalizações ANS
          </a>
        </ANSContact>
      </ContactServiceContainer>
    </PageWrapper>
  );
};

export default BeneficiaryAndProduct;
