import Notiflix from 'notiflix';
import api from '../services/api/api';

export const readURL = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        res(e.target.result);
      }
      res(null);
    };
    reader.onerror = (e) => rej(e);
    reader.readAsDataURL(file);
  });
};

export const downloadFile = async (
  url: string,
  path: string,
): Promise<void> => {
  const { data: dataApi } = await api.get(url, {
    responseType: 'blob',
  });
  const urlGenerated = window.URL.createObjectURL(new Blob([dataApi]));
  const link = document.createElement('a');
  link.href = urlGenerated;
  link.setAttribute('download', path);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadFileOfBlob = (blob: Blob): void => {
  const urlGenerated = window.URL.createObjectURL(blob);
  if (blob.type !== 'application/pdf') {
    const link = document.createElement('a');
    link.href = urlGenerated;
    link.setAttribute(
      'download',
      `arquivo.${blob.type.replace('application/', '')}`,
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  } else {
    window.open(urlGenerated, '_blank');
  }
  Notiflix.Notify.info(
    'Caso não esteja visualizando nenhum arquivo, lembre-se de liberar acesso à pop-ups.',
  );
  // const link = document.createElement('a');
  // link.href = urlGenerated;
  // link.setAttribute('download', 'download');
  // document.body.appendChild(link);
  // link.click();
  // link.remove();
};

export const dataURLtoFile = (dataurl: string, filename: string): void => {
  const arr = dataurl.split(',');
  if (arr && arr[0] && arr[1]) {
    const mime = arr[0].match(/:(.*?);/);
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n) {
      n -= 1;
      u8arr[n] = bstr.charCodeAt(n);
    }
    if (mime && mime[1]) {
      const urlTemp = window.URL.createObjectURL(
        new File([u8arr], filename, { type: mime[1] }),
      );
      window.open(urlTemp, 'blank');
    }
  }
};
