import axios from 'axios';
import produce from 'immer';
import Notiflix from 'notiflix';
import React, { useState, useCallback, useMemo, useEffect } from 'react';

import Button from '../../components/Button';
import FormWrapper from '../../components/FormWrapper';

import PageWrapper from '../../components/PageWrapper';
import { ReleaseProps, useReleases } from '../../hooks/releasesAndTerms';
import api from '../../services/api/api';
import { dateToPTBR } from '../../utils/formatt';

import Title from '../../components/Title';

import {
  Container,
  Content,
  Dropdown,
  DatePicker,
  ReleaseContainer,
  ReleaseBody,
  ReleaseTitle,
  ReleaseNoContent,
  ShowMoreContainer,
} from './styles';
import { getTag } from '../../utils/tableUtils';

const Releases: React.FC = () => {
  const { decrementMemo } = useReleases();
  // eslint-disable-next-line prettier/prettier
  const [from, setFrom] = useState(new Date(`01/01/${new Date().getFullYear()}`));
  const [to, setTo] = useState(new Date(`12/31/${new Date().getFullYear()}`));
  const [fromError, setFromError] = useState('');
  const [toError, setToError] = useState('');

  const [releasesToShow, setReleasesToShow] = useState([] as ReleaseProps[]);
  const [pagination, setPagination] = useState({
    actual: 1,
    max: 1,
  });

  const checkRead = useCallback(
    async (id: number, notiflix: string) => {
      try {
        Notiflix.Block.circle(notiflix);
        const { data: dataMyIp } = await axios.get(
          'https://api.ipify.org/?format=json',
        );

        await api.put(`/memo/set-read`, {
          memo_id: id,
          ip: dataMyIp.ip,
        });
        decrementMemo(id);
      } catch (err) {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos informar ao servidor que esse documento foi lido. Por favor, tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [decrementMemo],
  );

  const handleSearch = useCallback(
    async (page = 1, concat = false) => {
      try {
        Notiflix.Block.circle('.notiflix-search-reasons');
        const fromString = dateToPTBR(from) ?? '';
        const toString = dateToPTBR(to) ?? '';
        // const { data } = await api.get(`/memo/list-memo?page=${page}`);
        const { data } = await api.get(
          `/memo/list-memo?startDate=${fromString}&finalDate=${toString}&page=${page}`,
        );
        const { content } = data;
        const { pageCount } = data;
        setPagination((prev) => ({
          ...prev,
          actual: page,
          max: pageCount,
        }));
        if (content) {
          if (concat) {
            setReleasesToShow((prev) => [...prev, ...content]);
          } else {
            setReleasesToShow(content);
          }
        } else {
          setReleasesToShow([]);
        }
      } catch (err) {
        if (err instanceof TypeError) {
          console.log(from, to);
          if (!from) {
            setFromError('É necessário informar uma data válida');
          }
          if (!to) {
            setToError('É necessário informar uma data válida');
          }
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos buscar do servidor os comunicados. Por favor, tente novamente mais tarde',
          );
        }
      } finally {
        Notiflix.Block.remove('.notiflix-search-reasons');
      }
    },
    [from, to],
  );

  const handleChangeReleaseStatus = useCallback(
    async (id: number, classNotiflix: string) => {
      try {
        setReleasesToShow((prev) => {
          return produce(prev, (draft) => {
            const find = draft.find((d) => d.id === id);
            if (find) {
              find.read = true;
            }
          });
        });
        await checkRead(id, classNotiflix);
      } catch (err) {
        Notiflix.Notify.failure(
          'Ops.. Não conseguimos marcar como "Lido" no momento, tente novamente mais tarde...',
        );
      }
    },
    [checkRead],
  );

  const handleShowMore = useCallback(() => {
    const concat = true;
    handleSearch(pagination.actual + 1, concat);
  }, [handleSearch, pagination.actual]);

  const releasesContent = useMemo(() => {
    if (!releasesToShow || releasesToShow.length === 0) {
      return (
        <ReleaseNoContent>
          Não encontramos nenhum comunicado para você no período selecionado.
        </ReleaseNoContent>
      );
    }
    return (
      <>
        {releasesToShow.map((release) => {
          return (
            <Dropdown
              className={`notiflix-release-${release.id}`}
              key={`${release.id}`}
              callbackClickOpen={() =>
                !release.read
                  ? handleChangeReleaseStatus(
                      release.id,
                      `notiflix-release-${release.id}`,
                    )
                  : {}
              }
              titleJSX={
                <ReleaseTitle className={!release.read ? 'pending' : ''}>
                  <h2>{release.title}</h2>
                  {release.read && getTag('', 'Lida', 'gray', 'Lida')}
                  {!release.read && getTag('', 'Não Lida', 'red', 'Não Lida')}
                  <p>{release.emit || '01/01/2021'}</p>
                </ReleaseTitle>
              }
              body={
                <>
                  <ReleaseBody>
                    <h2>{release.title}</h2>
                    <p>{release.text}</p>
                  </ReleaseBody>
                </>
              }
            />
          );
        })}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releasesToShow]);

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper gridTemplateRows="auto 1fr">
      <Container>
        <Title>Comunicados</Title>
        <Content>
          <FormWrapper formLook onSubmit={() => handleSearch()}>
            <DatePicker
              name="from"
              selected={from}
              icon
              onChange={(data: Date) => {
                setFrom(data);
                if (data) {
                  setFromError('');
                }
              }}
              title="Período de:"
              formLook
              error={fromError}
            />
            <DatePicker
              name="to"
              selected={to}
              icon
              onChange={(data: Date) => {
                setTo(data);
                if (data) {
                  setToError('');
                }
              }}
              title="Até:"
              formLook
              error={toError}
            />
            <Button
              type="submit"
              modal
              grayButton
              formLook
              className="notiflix-search-reasons"
            >
              Buscar
            </Button>
          </FormWrapper>
        </Content>
        <ReleaseContainer>
          {releasesContent}
          {releasesToShow.length > 0 && pagination.max > pagination.actual && (
            <ShowMoreContainer>
              <Button outlined formLook autoWidth onClick={handleShowMore}>
                Ver mais
              </Button>
            </ShowMoreContainer>
          )}
        </ReleaseContainer>
      </Container>
    </PageWrapper>
  );
};

export default Releases;
