import styled from 'styled-components';
import TableElement from '../../components/Table';
import FormWrapper from '../../components/FormWrapper';

export const Container = styled.section`
  grid-column: 2/10;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

export const Form = styled(FormWrapper)`
  display: flex;
  grid-column: 2/10;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    grid-column: 1;
    flex-wrap: wrap;
  }
`;

export const Document = styled.a`
  svg {
    color: ${(props) => props.theme.colors.palet.institutional11};
  }
`;

export const Table = styled(TableElement)`
  margin-bottom: 30px;
  @media (max-width: 768px) {
    .p-datatable-tbody tr[role='row'] > td {
      max-width: 80%;
      min-width: 80%;
      &:nth-child(2) {
        min-width: 20%;
        max-width: 20%;
      }
    }
  }
  td.download {
    text-align: right !important;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
