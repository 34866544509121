/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Notiflix from 'notiflix';
import { FormHandles } from '@unform/core';
import axios from 'axios';
import Button from '../../components/Button';

import FormWrapper from '../../components/FormWrapper';
import Modal2 from '../../components/Modal2';
import Select, { SelectHandles } from '../../components/Select';
import api from '../../services/api/api';

import Input from '../../components/Input';
import DatePicker from '../../components/DatePicker';
import InputFile, { InputFileHandles } from '../../components/InputFile';
import { TitleIntern } from '../../components/Title';
import { RowProps } from '.';
import {
  dateToPTBR,
  getAllDDIForSelectOptions,
  ObjectGetValueOfKey,
  ptBRToDate,
} from '../../utils/formatt';
import { EnterpriseUser, useAuth, UserCommon } from '../../hooks/auth';

interface ChangeRegisterProps {
  defaultColor: string;
  callbackSetStep: (prev: number) => void;
  rowsSelecteds: RowProps[];
}

interface DateProps {
  value: Date | null;
  error: string;
  disabled: boolean;
}

export function ChangeRegister({
  defaultColor,
  callbackSetStep,
  rowsSelecteds,
}: ChangeRegisterProps): JSX.Element {
  const { user: userT } = useAuth();
  const user = userT as UserCommon & EnterpriseUser;

  const formRefDatasheet = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const countryRgIdRef = useRef<SelectHandles>(null);
  const birthCityCodeRef = useRef<SelectHandles>(null);
  const brazilianStateRef = useRef<SelectHandles>(null);
  const cityIdRef = useRef<SelectHandles>(null);
  const brazilianstatesWhichIssuedRgRef = useRef<SelectHandles>(null);
  const maritalRef = useRef<SelectHandles>(null);
  const physicalSexRef = useRef<SelectHandles>(null);
  const attachmentRef = useRef<InputFileHandles>(null);
  const DDINumberRef = useRef<SelectHandles>(null);
  const DDINumberCellphoneRef = useRef<SelectHandles>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [prevData, setPrevData] = useState({} as any);

  const [countryOptions, setCountryOptions] = useState([
    { title: 'País', value: '' },
  ]);

  const [birthCityCodeOptions, setHometownOptions] = useState([
    { title: '', value: '' },
  ]);
  const [ufOptions, setUfOptions] = useState([{ title: 'Estado', value: '' }]);
  const [cityIdOptions, setCityOptions] = useState([
    { title: 'Cidades', value: '' },
  ]);
  const [brazilianstatesWhichIssuedRgOptions, setUfRgOptions] = useState([
    { title: 'UF', value: '' },
  ]);
  const [maritalOptions, setMaritalOptions] = useState([
    { title: 'Estado civil', value: '' },
  ]);
  const [phisicalSexOptions, setPhisicalSexOptions] = useState([
    { title: 'Sexo', value: '' },
  ]);
  const [birthDate, setBirthDate] = useState<DateProps>({
    value: null,
    error: '',
    disabled: false,
  });
  const [emissionDate, setEmissionDate] = useState<DateProps>({
    value: null,
    error: '',
    disabled: false,
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        Notiflix.Block.circle('.notiflix-request-change-register-send-button');
        data.birthDate = birthDate.value ? dateToPTBR(birthDate.value) : '';
        data.maritalStatus = maritalRef.current?.value;
        data.physicalSex = physicalSexRef.current?.value;
        data.emissionDate = emissionDate.value
          ? dateToPTBR(emissionDate.value)
          : '';
        data.brazilianstatesWhichIssuedRg =
          brazilianstatesWhichIssuedRgRef.current?.value;
        data.countryRgId = countryRgIdRef.current?.value;
        data.birthCityCode = birthCityCodeRef.current?.value;
        data.brazilianState = brazilianStateRef.current?.value;
        data.cityId = cityIdRef.current?.value;
        data.DDINumber = DDINumberRef.current?.value;
        data.DDDNumber = data.phone.slice(0, 2);
        data.phone = data.phone.slice(2);
        data.DDINumberCellphone = DDINumberCellphoneRef.current?.value;
        data.DDDNumberCellphone = data.cellphone.slice(0, 2);
        data.cellphone = data.cellphone.slice(2);
        const {
          userUnimedId,
          physicalPersonCode: phisicalPersonCode,
          contractId,
        } = rowsSelecteds[0];
        const userName = user.webUsername;
        const allPromises = [] as {
          userUnimedId: string;
          phisicalPersonCode: string;
          contractId: string;
          field: string;
          userName: string;
          oldValue: string;
          newValue: string;
          accessType: 'E';
        }[];

        let askAttachment = false;

        Object.entries(data).forEach((fieldArray) => {
          const field = fieldArray[0];
          const newValue = fieldArray[1];
          const oldValueSaved = ObjectGetValueOfKey(prevData, field).value;
          // console.log(`new -> ${newValue} | old -> ${oldValueSaved}`);
          let oldValueToCompare = '';

          if (oldValueSaved === null) {
            oldValueToCompare = '';
          } else {
            oldValueToCompare = String(oldValueSaved);
          }

          if (
            String(newValue) !== oldValueToCompare &&
            askAttachment !== true
          ) {
            if (
              field === 'email' ||
              field === 'phone' ||
              field === 'DDDNumber' ||
              field === 'DDINumber' ||
              field === 'cellphone' ||
              field === 'DDDNumberCellphone' ||
              field === 'DDINumberCellphone'
            ) {
              askAttachment = false;
            } else {
              askAttachment = true;
            }
          }

          if (!(newValue === '' && oldValueSaved === null)) {
            if (String(newValue) !== String(oldValueSaved)) {
              allPromises.push({
                userUnimedId: String(userUnimedId),
                phisicalPersonCode,
                contractId,
                userName,
                field,
                oldValue: oldValueSaved,
                newValue: String(newValue),
                accessType: 'E',
              });
            }
          }
        });
        if (allPromises.length === 0) {
          Notiflix.Notify.info('Não há alterações para enviar...');
          return;
        }

        const { data: response } = await api.put(
          '/beneficiary/register-change-request',
          [...allPromises],
        );

        const { content } = response;
        const requestIdApi = content[0].requestId;
        const formData = new FormData();
        formData.append('requestId', requestIdApi);
        formData.append(
          'userUnimedId',
          rowsSelecteds[0].userUnimedId.toString(),
        );

        // Condicional para que o email, DDInumber e DDDNumber seja permitido editar sem precisar enviar anexo obrigatoriamente.
        if (askAttachment) {
          if (!attachmentRef.current?.files) {
            attachmentRef.current?.setError(
              'Ao realizar uma alteração é necessário incluir um anexo.',
            );
            return;
          }
          attachmentRef.current?.files?.forEach((file, index) => {
            formData.append(`changeFile[${index}]`, file);
          });
          formData.append('userName', user.webUsername);
          await api.post('/company/register-attachment-change', formData);
        }

        Modal2.Success({
          closable: true,
          title: 'Perfeito!',
          subtitle: 'Enviamos sua solicitação.',
          children: (
            <>
              <Button
                onClick={() => {
                  Modal2.Close();
                  callbackSetStep(1);
                }}
                modal
              >
                Ok
              </Button>
            </>
          ),
        });
      } catch (err) {
        if (err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Algo deu errado ao tentar processar sua solicitação. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove('.notiflix-request-change-register-send-button');
      }
    },
    [
      birthDate.value,
      callbackSetStep,
      emissionDate.value,
      prevData,
      rowsSelecteds,
      user.webUsername,
    ],
  );

  const getCitysOfState = useCallback(async (state) => {
    try {
      Notiflix.Block.circle('.notiflix-city');
      const { data } = await api.get(`/address/list-of-cities?state=${state}`);
      const { content } = data;
      setCityOptions(
        content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { city: string; ibgeCityCode: string },
          ) => {
            acc.push({
              title: act.city,
              value: act.ibgeCityCode,
            });
            return acc;
          },
          [],
        ),
      );
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops não conseguimos buscar as cidades para seleção. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-city');
    }
  }, []);
  const getHomeCities = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-city-home');
      const { data } = await api.get(`/address/list-of-cities`);
      const { content } = data;
      setHometownOptions([
        { title: '', value: '' },
        ...content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { state: string; city: string; ibgeCityCode: string },
          ) => {
            acc.push({
              title: `${act.state} - ${act.city}`,
              value: act.ibgeCityCode,
            });
            return acc;
          },
          [],
        ),
      ]);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops não conseguimos buscar as cidades para seleção. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-city-home');
    }
  }, []);
  const getAllCountry = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-country');
      const { data } = await api.get(`/address/list-of-countries`);
      const { content } = data;
      setCountryOptions(
        content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      );
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops não conseguimos buscar os países para seleção. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-country');
    }
  }, []);

  const getAllStates = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-states');
      Notiflix.Block.circle('.notiflix-uf');
      const { data } = await api.get('/address/list-of-states');
      const { content } = data;
      setUfRgOptions(
        content.reduce(
          (acc: { title: string; value: string }[], act: { id: string }) => {
            acc.push({
              title: act.id,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      );
      setUfOptions(
        content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      );
      getCitysOfState(content[0].id);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops não conseguimos buscar os estados para seleção. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-states');
      Notiflix.Block.remove('.notiflix-uf');
    }
  }, [getCitysOfState]);

  const getAllMaritalStatus = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-marital');
      const { data } = await api.get('/generic/list-of-marital-status');
      const { content } = data;
      setMaritalOptions(
        content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({ title: act.name, value: act.id });
            return acc;
          },
          [],
        ),
      );
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os estados civis para seleção. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-marital');
    }
  }, []);
  const getAllPhisicalSex = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-phisical-sex');
      const { data } = await api.get('/generic/list-of-phisical-sex');
      const { content } = data;
      setPhisicalSexOptions(
        content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({ title: act.name, value: act.id });
            return acc;
          },
          [],
        ),
      );
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar a lista de seleção de sexo no servidor. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-phisical-sex');
    }
  }, []);

  const getAllInitialData = useCallback(async () => {
    Notiflix.Block.circle('.form-change-request-datasheet');
    Notiflix.Block.circle('.request-copy-card');
    await Promise.allSettled([
      getAllStates(),
      getAllCountry(),
      getAllMaritalStatus(),
      getAllPhisicalSex(),
      // getHomeCities(),
    ]);
    DDINumberRef.current?.setValue('', '55');
    DDINumberCellphoneRef.current?.setValue('', '55');
    try {
      const { data } = await api.get(
        `/beneficiary/data-to-change-fields?physicalPersonalCode=${rowsSelecteds[0].physicalPersonCode}&accessType=E`,
      );
      const { content } = data;
      setPrevData(content);
      const benef = rowsSelecteds[0];
      if (formRefDatasheet.current) {
        formRefDatasheet.current.setFieldValue(
          'cardNumber',
          benef.cardNumber ?? '',
        );
        formRefDatasheet.current.setFieldValue(
          'beneficiary',
          benef.beneficiaryName ?? '',
        );
        formRefDatasheet.current.setFieldValue(
          'cardNumberValidDate',
          benef.cardExpiryDate ?? '',
        );
        formRefDatasheet.current.setFieldValue(
          'hiringDate',
          benef.hiringDate ?? '',
        );

        formRefDatasheet.current?.setFieldValue(
          'product',
          benef.productName ?? '',
        );
        formRefDatasheet.current?.setFieldValue(
          'register',
          benef.registrationCode ?? '',
        );
      }
      if (formRef.current) {
        formRef.current.setFieldValue(
          'beneficiaryName',
          content.beneficiaryName.value ?? '',
        );
        formRef.current.getFieldRef('beneficiaryName').current.disabled =
          !content.beneficiaryName.isEditable;

        setBirthDate({
          value: content.birthDate.value
            ? ptBRToDate(content.birthDate.value)
            : null,
          error: '',
          disabled: !content.birthDate.isEditable,
        });

        maritalRef.current?.setValue('', content.maritalStatus.value ?? '');
        maritalRef.current?.setDisabled(!content.maritalStatus.isEditable);

        physicalSexRef.current?.setValue('', content.physicalSex.value ?? '');
        physicalSexRef.current?.setDisabled(!content.physicalSex.isEditable);

        formRef.current.setFieldValue('cpf', content.cpf.value ?? '');
        formRef.current.getFieldRef('cpf').current.disabled =
          !content.cpf.isEditable;

        formRef.current.setFieldValue(
          'cnsNumber',
          content.cnsNumber.value ?? '',
        );
        formRef.current.getFieldRef('cnsNumber').current.disabled =
          !content.cnsNumber.isEditable;

        formRef.current.setFieldValue('email', content.email.value ?? '');
        formRef.current.getFieldRef('email').current.disabled =
          !content.email.isEditable;

        formRef.current.setFieldValue('rg', content.rg.value ?? '');
        formRef.current.getFieldRef('rg').current.disabled =
          !content.rg.isEditable;

        setEmissionDate({
          value: content.emissionDate.value
            ? ptBRToDate(content.emissionDate.value)
            : null,
          error: '',
          disabled: !content.emissionDate.isEditable,
        });

        brazilianstatesWhichIssuedRgRef.current?.setValue(
          '',
          content.brazilianstatesWhichIssuedRg.value ?? '',
        );
        brazilianstatesWhichIssuedRgRef.current?.setDisabled(
          !content.brazilianstatesWhichIssuedRg.isEditable,
        );

        countryRgIdRef.current?.setValue('', content.countryRgId.value ?? '');
        countryRgIdRef.current?.setDisabled(!content.countryRgId.isEditable);

        formRef.current.setFieldValue(
          'issuingBody',
          content.issuingBody.value ?? '',
        );
        formRef.current.getFieldRef('issuingBody').current.disabled =
          !content.issuingBody.isEditable;

        formRef.current.setFieldValue(
          'foreignRgId',
          content.foreignRgId.value ?? '',
        );
        formRef.current.getFieldRef('foreignRgId').current.disabled =
          !content.foreignRgId.isEditable;

        if (content.birthCityCode.isEditable) {
          getHomeCities().then(() => {
            birthCityCodeRef.current?.setValue(
              '',
              content.birthCityCode.value ?? '',
            );
            birthCityCodeRef.current?.setDisabled(
              !content.birthCityCode.isEditable,
            );
          });
        } else {
          birthCityCodeRef.current?.setDisabled(
            !content.birthCityCode.isEditable,
          );
        }

        formRef.current.setFieldValue('zipCode', content.zipCode.value ?? '');
        formRef.current.getFieldRef('zipCode').current.disabled =
          !content.zipCode.isEditable;

        formRef.current.setFieldValue('address', content.address.value ?? '');
        formRef.current.getFieldRef('address').current.disabled =
          !content.address.isEditable;

        formRef.current.setFieldValue(
          'addressNumber',
          content.addressNumber.value ?? '',
        );
        formRef.current.getFieldRef('addressNumber').current.disabled =
          !content.addressNumber.isEditable;

        formRef.current.setFieldValue(
          'complement',
          content.complement.value ?? '',
        );
        formRef.current.getFieldRef('complement').current.disabled =
          !content.complement.isEditable;

        formRef.current.setFieldValue(
          'neighborhood',
          content.neighborhood.value ?? '',
        );
        formRef.current.getFieldRef('neighborhood').current.disabled =
          !content.neighborhood.isEditable;

        brazilianStateRef.current?.setValue(
          '',
          content.brazilianState.value ?? '',
        );
        brazilianStateRef.current?.setDisabled(
          !content.brazilianState.isEditable,
        );

        if (content.brazilianState.value) {
          getCitysOfState(content.brazilianState.value).then(() => {
            cityIdRef.current?.setValue('', content.cityId.value ?? '');
            cityIdRef.current?.setDisabled(!content.cityId.isEditable);
          });
        }

        DDINumberRef.current?.setValue('', content.DDINumber.value);
        DDINumberRef.current?.setDisabled(!content.DDINumber.isEditable);

        formRef.current.setFieldValue(
          'phone',
          `${content.DDDNumber.value}${content.phone.value}`,
        );
        formRef.current.getFieldRef('phone').current.disabled =
          !content.phone.isEditable;

        DDINumberCellphoneRef.current?.setValue(
          '',
          content.DDINumberCellphone.value,
        );
        DDINumberCellphoneRef.current?.setDisabled(
          !content.DDINumberCellphone.isEditable,
        );

        formRef.current.setFieldValue(
          'cellphone',
          `${content.DDDNumberCellphone.value}${content.cellphone.value}`,
        );
        formRef.current.getFieldRef('cellphone').current.disabled =
          !content.cellphone.isEditable;

        formRef.current.setFieldValue(
          'birthCertificateCodeAlive',
          content.birthCertificateCodeAlive.value ?? '',
        );
        formRef.current.getFieldRef(
          'birthCertificateCodeAlive',
        ).current.disabled = !content.birthCertificateCodeAlive.isEditable;

        formRef.current.setFieldValue(
          'fatherName',
          content.fatherName.value ?? '',
        );
        formRef.current.getFieldRef('fatherName').current.disabled =
          !content.fatherName.isEditable;

        formRef.current.setFieldValue(
          'motherName',
          content.motherName.value ?? '',
        );
        formRef.current.getFieldRef('motherName').current.disabled =
          !content.motherName.isEditable;
      }
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos pré carregar os dados do beneficiário. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.form-change-request-datasheet');
      Notiflix.Block.remove('.request-copy-card');
    }
  }, [
    getAllStates,
    getAllCountry,
    getAllMaritalStatus,
    getAllPhisicalSex,
    prevData,
    rowsSelecteds,
    getHomeCities,
    getCitysOfState,
  ]);

  useEffect(() => {
    // console.log('Beneficiario selecionado->', rowsSelecteds[0]);
    getAllInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [cepToSearch, setCepToSearch] = useState('');
  /**
   * @description Search CEP and in success set default values to State, County, Street and District
   */
  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    const timer = window.setTimeout(() => {
      if (!cepToSearch) {
        source.cancel();
        return;
      }
      Notiflix.Block.circle('.notiflix-cep-search');
      api
        .get(`/address/search-address-by-zip-code?zipCode=${cepToSearch}`, {
          cancelToken: source.token,
        })
        .then((resp) => {
          const { data } = resp;
          const { content } = data;
          if (content && content[0]) {
            formRef.current?.setFieldValue('neighborhood', content[0].district);
            formRef.current?.setFieldValue('address', content[0].streetName);
            brazilianStateRef.current?.setValue('', content[0].states);
            if (content[0].city) {
              api
                .get(`/address/list-of-cities?state=${content[0].states}`)
                .then((respCity) => {
                  if (respCity.data.content) {
                    setCityOptions(
                      respCity.data.content.reduce(
                        (
                          acc: { title: string; value: string }[],
                          act: { city: string; ibgeCityCode: string },
                        ) => {
                          acc.push({
                            title: act.city,
                            value: act.ibgeCityCode,
                          });
                          return acc;
                        },
                        [],
                      ),
                    );
                    cityIdRef.current?.setValue(content[0].city, '');
                  }
                });
            }
          }
        })
        .finally(() => {
          Notiflix.Block.circle('.notiflix-cep-search');
          Notiflix.Block.remove('.notiflix-cep-search');
        });
    }, 1000);

    return () => {
      source.cancel();
      window.clearTimeout(timer);
    };
  }, [cepToSearch]);

  return (
    <>
      <TitleIntern themeColor={defaultColor}>
        Titular:{' '}
        {rowsSelecteds[0].holderName ?? rowsSelecteds[0].beneficiaryName}
      </TitleIntern>
      <FormWrapper
        onSubmit={() => console.log('N/A')}
        ref={formRefDatasheet}
        className="form-change-request-datasheet"
      >
        <Input
          name="cardNumber"
          insideInputTitle="Carteirinha:"
          widthContainerDesktop="33.33%"
          themeColor={defaultColor}
        />
        <Input
          name="beneficiary"
          insideInputTitle="Beneficiário:"
          widthContainerDesktop="33.33%"
          themeColor={defaultColor}
        />
        <Input
          name="product"
          insideInputTitle="Produto:"
          widthContainerDesktop="33.33%"
          themeColor={defaultColor}
        />
        <Input
          name="cardNumberValidDate"
          insideInputTitle="Data valid. carteirinha:"
          widthContainerDesktop="33.33%"
          themeColor={defaultColor}
        />
        <Input
          name="hiringDate"
          insideInputTitle="Data contratação:"
          widthContainerDesktop="33.33%"
          themeColor={defaultColor}
        />
        <Input
          name="register"
          insideInputTitle="Matrícula:"
          type="number"
          widthContainerDesktop="33.33%"
          themeColor={defaultColor}
        />
      </FormWrapper>
      <FormWrapper
        onSubmit={handleSubmit}
        formLook
        className="request-copy-card"
        ref={formRef}
      >
        <Input
          // onChange={handleChange}

          name="beneficiaryName"
          title="Nome:"
          formLook
          widthContainerDesktop="40%"
          themeColor={defaultColor}
        />
        <DatePicker
          name="birhDate"
          title="Data de nascimento"
          selected={birthDate.value}
          error={birthDate.error}
          onChange={(date: Date) =>
            setBirthDate({ value: date, error: '', disabled: false })
          }
          disabled={birthDate.disabled}
          maxDate={new Date()}
          themeColor={defaultColor}
          widthContainerDesktop="20%"
          formLook
          icon
        />
        <Select
          name="maritalStatus"
          title="Estado civil:"
          ref={maritalRef}
          options={maritalOptions}
          className="notiflix-marital"
          liveReload
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="25%"
        />
        <Select
          name="phisicalSex"
          title="Sexo:"
          ref={physicalSexRef}
          options={phisicalSexOptions}
          className="notiflix-phisical-sex"
          liveReload
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="15%"
        />

        <Input
          name="cpf"
          title="CPF:"
          masks={['999.999.999-99']}
          formLook
          widthContainerDesktop="25%"
          themeColor={defaultColor}
        />
        <Input
          name="cnsNumber"
          title="CNS:"
          formLook
          widthContainerDesktop="25%"
          themeColor={defaultColor}
        />
        <Input
          name="email"
          title="E-mail:"
          formLook
          widthContainerDesktop="50%"
          themeColor={defaultColor}
        />

        <Input
          name="rg"
          title="RG:"
          formLook
          widthContainerDesktop="20%"
          themeColor={defaultColor}
        />
        <DatePicker
          name="emissionDate"
          title="Data Exp. RG:"
          selected={emissionDate.value}
          error={emissionDate.error}
          onChange={(date: Date) =>
            setEmissionDate({ value: date, error: '', disabled: false })
          }
          disabled={emissionDate.disabled}
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="15%"
          icon
        />
        <Select
          name="brazilianstatesWhichIssuedRg"
          title="UF emissora RG:"
          ref={brazilianstatesWhichIssuedRgRef}
          options={brazilianstatesWhichIssuedRgOptions}
          className="notiflix-uf"
          liveReload
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="10%"
        />
        <Select
          name="countryRgId"
          title="País emissor:"
          ref={countryRgIdRef}
          options={countryOptions}
          className="notiflix-country"
          liveReload
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="25%"
        />
        <Input
          name="issuingBody"
          title="Órgão emissor:"
          formLook
          widthContainerDesktop="10%"
          themeColor={defaultColor}
        />
        <Input
          name="foreignRgId"
          title="RG estrangeiro:"
          formLook
          widthContainerDesktop="20%"
          themeColor={defaultColor}
        />

        {/* <Select
          name="state"
          title="Estado de nascimento:"
          ref={homeStateRef}
          options={homeStateOptions}
          changeCallback={getHomeCitysOfState}
          className="notiflix-states"
          liveReload
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="100%"
        /> */}
        <Select
          name="birthCityCode"
          title="Município de nascimento:"
          ref={birthCityCodeRef}
          options={birthCityCodeOptions}
          className="notiflix-city-home"
          liveReload
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="100%"
        />

        <Input
          name="zipCode"
          title="CEP:"
          formLook
          widthContainerDesktop="25%"
          themeColor={defaultColor}
          onChange={(e) => setCepToSearch(e.currentTarget.value)}
          className="notiflix-cep-search"
        />
        <Select
          name="brazilianState"
          title="UF:"
          ref={brazilianStateRef}
          options={ufOptions}
          changeCallback={getCitysOfState}
          className="notiflix-states"
          liveReload
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="25%"
        />

        <Select
          name="cityId"
          title="Cidade:"
          ref={cityIdRef}
          options={cityIdOptions}
          className="notiflix-city"
          liveReload
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="25%"
        />
        <Input
          name="neighborhood"
          title="Bairro:"
          formLook
          widthContainerDesktop="25%"
          themeColor={defaultColor}
        />

        <Input
          name="address"
          title="Rua:"
          formLook
          widthContainerDesktop="40%"
          themeColor={defaultColor}
        />
        <Input
          name="addressNumber"
          title="Numero:"
          formLook
          widthContainerDesktop="10%"
          themeColor={defaultColor}
        />
        <Input
          name="complement"
          title="Complemento:"
          formLook
          widthContainerDesktop="40%"
          themeColor={defaultColor}
        />

        <Select
          name="DDINumber"
          title="DDI - Telefone:"
          ref={DDINumberRef}
          options={[{ value: '', title: '' }, ...getAllDDIForSelectOptions()]}
          formLook
          inputMode
          themeColor={defaultColor}
          widthContainerDesktop="15%"
        />
        <Input
          name="phone"
          title="DDD/Telefone:"
          masks={['(99) 9999-9999', '(99) 9 9999-9999']}
          formLook
          widthContainerDesktop="35%"
          themeColor={defaultColor}
        />
        <Select
          name="DDINumberCellphone"
          title="DDI - Celular:"
          ref={DDINumberCellphoneRef}
          options={[{ value: '', title: '' }, ...getAllDDIForSelectOptions()]}
          formLook
          inputMode
          themeColor={defaultColor}
          widthContainerDesktop="15%"
        />
        <Input
          name="cellphone"
          title="DDD/Celular:"
          masks={['(99) 9999-9999', '(99) 9 9999-9999']}
          formLook
          widthContainerDesktop="35%"
          themeColor={defaultColor}
        />

        <Input
          name="birthCertificateCodeAlive"
          title="Declaração de nascido vivo:"
          formLook
          widthContainerDesktop="33%"
          themeColor={defaultColor}
        />
        <Input
          name="fatherName"
          title="Filiação (pai):"
          formLook
          widthContainerDesktop="33%"
          themeColor={defaultColor}
        />
        <Input
          name="motherName"
          title="Filiação (mãe):"
          formLook
          widthContainerDesktop="33%"
          themeColor={defaultColor}
        />

        <InputFile
          themeColor={defaultColor}
          name="attachments"
          ref={attachmentRef}
          placeholder="Anexos"
          multiple
        />
        <Button
          className="opa"
          secondary
          autoWidth
          formLook
          onClick={() => callbackSetStep(1)}
        >
          Voltar
        </Button>
        <Button
          type="submit"
          greenButton
          autoWidth
          formLook
          className="notiflix-request-change-register-send-button marginLeft"
        >
          Enviar
        </Button>
      </FormWrapper>
    </>
  );
}
