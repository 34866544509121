/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import Notiflix from 'notiflix';
import { FormHandles } from '@unform/core';
import { isAfter } from 'date-fns';
import { useTheme } from 'styled-components';
import PageWrapper from '../../components/PageWrapper';
import Table, { Column } from '../../components/Table';
import api from '../../services/api/api';
import AddField, { AddField as addField, getTag } from '../../utils/tableUtils';
import {
  Container,
  Title,
  TableContainer,
  ReportsContainer,
  ReportsButton,
  DatePicker,
  Steps,
  Step1,
  Step2,
  ButtonsStep2,
  CheckboxGroup,
  ClearButton,
  ButtonsContainer,
  TableWrapper,
  Step3,
  OcurrenceDiv,
} from './styles';
import FormWrapper from '../../components/FormWrapper';
import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import { dateToPTBR, ptBRToDate } from '../../utils/formatt';
import Select, { SelectHandles } from '../../components/Select';
import { BeneficiaryUser, UserCommon, useAuth } from '../../hooks/auth';
import { TitleIntern } from '../../components/Title';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';
import Modal2 from '../../components/Modal2';

const Authorization: React.FC = () => {
  const { user: userT } = useAuth();
  const user = userT as UserCommon & BeneficiaryUser;
  const { colors } = useTheme();
  const defaultColor = colors.palet.institutional8;
  const providerCodeRef = useRef<SelectHandles>(null);
  const procedureTypeRef = useRef<SelectHandles>(null);
  const procedureCodeRef = useRef<SelectHandles>(null);
  const guideRequisitionCodeRef = useRef<SelectHandles>(null);
  const typeOfGuideCodeRef = useRef<SelectHandles>(null);
  const formRef = useRef<FormHandles>(null);

  const step1Ref = useRef<HTMLDivElement>(null);
  const step2Ref = useRef<HTMLDivElement>(null);
  const step3Ref = useRef<HTMLDivElement>(null);
  const formRefStep2 = useRef<FormHandles>(null);
  const formRefStep3 = useRef<FormHandles>(null);

  const [tableData, setTableData] = useState([] as any[]);
  const [internTable, setInternTable] = useState([] as any[]);
  const [internCIDTable, setInternCIDTable] = useState([] as any[]);

  const [reportFrom, setReportFrom] = useState({
    value: new Date(`01/01/${new Date().getFullYear()}`),
    error: '',
  });
  const [reportTo, setReportTo] = useState({
    value: new Date(`12/31/${new Date().getFullYear()}`),
    error: '',
  });

  const [materialGuide, setMaterialGuide] = useState([]);
  const [procedureGuide, setProcedureGuide] = useState([]);
  const [internProcedureTable, setInternProcedureTable] = useState([]);
  const [procedureGlosa, setProcedureGlosa] = useState<any[]>([]);
  const [internMaterialTable, setInternMaterialTable] = useState([]);
  const [materialGlosa, setMaterialGlosa] = useState([]);

  const [expandedRows, setExpandedRows] = useState([] as any[]);
  const [expandedRows2, setExpandedRows2] = useState([] as any[]);
  const [expandedRows3, setExpandedRows3] = useState([] as any[]);
  const [actualStep, setActualStep] = useState(1);
  const [heightStep, setHeightStep] = useState(['']);
  const [providerCodeOptions, setProviderCodeOptions] = useState([
    {
      title: '',
      value: '',
    },
  ]);

  const [procedureOptions, setProcedureOptions] = useState([
    {
      title: 'Todos',
      value: '',
    },
  ]);

  const [procedureCodeOptions, setProcedureCodeOptions] = useState([
    {
      title: '',
      value: '',
    },
  ]);

  const [typeOfGuideCodeOptions, setTypeOfGuideCodeOptions] = useState([
    {
      title: 'Todos',
      value: '',
    },
  ]);

  const handleDownloadReportRequest = useCallback(
    async (row) => {
      const { requestId } = row;
      try {
        Notiflix.Block.circle(`.loading-button-${requestId}`);
        const { data } = await api.get(
          `/report/list-of-beneficiary-reports?contractId=${user.id}&exhibitionLocation=4`,
        );
        const { nrSeqRelatorio, Parametros: P } = data.content[0];
        const body = {
          nrSeqRelatorio: nrSeqRelatorio ?? '',
          Parametros: [
            ...P.map((p: { cdParametro: any }) => {
              return {
                id: p.cdParametro,
                valor: requestId,
              };
            }),
          ],
        };

        const { data: dataReport } = await api.post(
          `/report/generate-report`,
          body,
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(dataReport);
      } catch (err) {
        if (err?.response?.status === 400) {
          Notiflix.Notify.info(err.response.data?.message);
        } else {
          Notiflix.Notify.failure(
            'Não conseguimos buscar seu relatório. Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove(`.loading-button-${requestId}`);
      }
    },
    [user],
  );

  const handleDownloadReportGuide = useCallback(
    async (row) => {
      const { guideId } = row;
      if (!guideId) {
        Modal2.Failure({
          closable: true,
          title: 'Ops..',
          text: 'Não é possível buscar os dados para este relatório.',
          children: <Button onClick={() => Modal2.Close()}>Ok</Button>,
        });
        return;
      }
      try {
        Notiflix.Block.circle(`.notiflix-report-guide-${row?.guideId}`);
        const { data } = await api.get(
          `/report/list-of-beneficiary-reports?contractId=${user.id}&exhibitionLocation=5`,
        );
        const { nrSeqRelatorio, Parametros: P } = data.content[0];
        const body = {
          nrSeqRelatorio: nrSeqRelatorio ?? '',
          Parametros: [
            {
              id: P[0].cdParametro,
              valor: guideId,
            },
          ],
        };

        const { data: dataReport } = await api.post(
          `/report/generate-report`,
          body,
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(dataReport);
      } catch (err) {
        if (err?.response?.status === 400) {
          Notiflix.Notify.info(
            'Ops... Não encontramoes esse relatório no servidor.',
          );
        } else {
          Notiflix.Notify.failure(
            'Não conseguimos buscar seu relatório. Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove(`.notiflix-report-guide-${row?.requestId}`);
      }
    },
    [user],
  );

  const getProcedureGuide = useCallback(async (content) => {
    try {
      Notiflix.Block.circle('.procedure-data-notiflix');
      if (content[0]) {
        const { guideId } = content[0];
        const { data } = await api.get(
          `/beneficiary-authorization/guide-procedure?guideId=${guideId}`,
        );
        const { content: contentAPI } = data;
        setProcedureGuide(contentAPI);
      }
    } catch (error) {
      if (error.response) {
        if (error?.response.status === 400) {
          Notiflix.Notify.info(error.response.data.message);
        } else {
          Notiflix.Notify.failure(
            'Não foi possível buscar pela lista de guias. Tente novamente mais tarde.',
          );
        }
      }
    } finally {
      Notiflix.Block.remove('.procedure-data-notiflix');
    }
  }, []);

  const getMaterialGuide = useCallback(async (content) => {
    try {
      Notiflix.Block.circle('.material-data-notiflix');
      if (content[0]) {
        const { guideId } = content[0];
        const { data } = await api.get(
          `/beneficiary-authorization/guide-material?guideId=${guideId}`,
        );
        const { content: contentAPI } = data;
        setMaterialGuide(contentAPI);
      }
    } catch (error) {
      if (error?.response.status === 400) {
        Notiflix.Notify.info(error.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Não foi possível buscar pela lista de guias. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.material-data-notiflix');
    }
  }, []);

  const getTableDetails = useCallback(
    async (rowData) => {
      const { requestId } = rowData;
      try {
        const { data } = await api.get(
          `/beneficiary-authorization/requisition-guide-data?requestId=${requestId}`,
        );
        const { content } = data;
        getProcedureGuide(content);
        getMaterialGuide(content);
      } catch (error) {
        if (error.response) {
          if (error?.response?.status === 400) {
            Notiflix.Notify.info(error.response.data.message);
          } else {
            Notiflix.Notify.failure(
              'Tivemos problemas ao carregar os detalhes da autorização. Tente novamente mais tarde.',
            );
          }
        }
      }
    },
    [getMaterialGuide, getProcedureGuide],
  );

  const handleChangeDetailStep2 = useCallback(
    async (rowData) => {
      getTableDetails(rowData);
      setActualStep(3);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getProcedureGuide, getMaterialGuide],
  );

  const getProcedureGlosa = useCallback(async (rowData) => {
    try {
      Notiflix.Block.circle('.procedure-table');
      if (rowData[rowData.length - 1]) {
        const { procRequestNumber } = rowData[rowData.length - 1];

        const { data } = await api.get(
          `/beneficiary-authorization/glosa-procedure-request?procRequestNumber=${procRequestNumber}`,
        );
        const { content } = data;

        setProcedureGlosa(content);
        setExpandedRows2(rowData);
        Notiflix.Block.remove(`.procedure-table`);

        return;
      }

      setExpandedRows2([]);
      setProcedureGlosa([]);
    } catch (error) {
      if (error?.response.status === 400) {
        Notiflix.Notify.info(error.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dados de Glosa de procedimento.',
        );
      }
    } finally {
      Notiflix.Block.remove(`.procedure-table`);
    }
  }, []);

  const getMaterialGlosa = useCallback(async (rowData) => {
    try {
      if (rowData[0]) {
        const { procRequestNumber } = rowData[0];
        const { data } = await api.get(
          `/beneficiary-authorization/glosa-material-request?materialRequisitionNumber=${procRequestNumber}`,
        );
        const { content } = data;
        setMaterialGlosa(content);
        setExpandedRows3(rowData);
      }
    } catch (error) {
      if (error?.response.status === 400) {
        Notiflix.Notify.info(error.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dados de Glosa de material.',
        );
      }
    }
  }, []);

  const rowExpansionTemplate = useCallback(() => {
    return (
      <div className="notiflix-intern-table">
        <Table
          items={internTable}
          style={{
            marginBottom: '40px',
          }}
        >
          <Column
            field="requestProviderName"
            header="Prestador"
            body={(row) => AddField(row.providerName, 'Prestador')}
            sortable
          />
          <Column
            field="guideDescription"
            header="Guia"
            body={(row) => AddField(row.guideId, 'Guia')}
            sortable
            style={{
              width: '110px',
            }}
          />
          <Column
            field="executionDate"
            header="Data de Execução"
            className="date"
            body={(row) => AddField(row.executionDate, 'Data de Execução')}
          />
          <Column
            field="id"
            style={{ width: '120px' }}
            body={(row) => (
              <Button
                sausageLook
                autoWidth
                noChangeColor
                onClick={() => handleDownloadReportGuide(row)}
                className={`notiflix-report-guide-${row?.requestId}`}
              >
                Relatório
              </Button>
            )}
          />
          <Column
            field="id"
            style={{ width: '120px' }}
            body={(row) => (
              <Button
                sausageLook
                grayButton
                autoWidth
                noChangeColor
                onClick={() => handleChangeDetailStep2(row)}
              >
                Detalhes
              </Button>
            )}
          />
        </Table>
      </div>
    );
  }, [handleChangeDetailStep2, handleDownloadReportGuide, internTable]);

  const rowExpansionsProductGlosa = useCallback(() => {
    return (
      <div className="notiflix-procedureGlosa-table">
        {procedureGlosa?.length !== 0 && (
          <>
            {/* <Table items={procedureGlosa}>
              <Column
                field="occurrence"
                header="Ocorrência"
                sortable
                bodyStyle={{ wordBreak: 'break-word' }}
                body={(row) => AddField(row.occurrence, 'Ocorrência')}
              />

              <Column
                field="occurrenceRules"
                header="Regras"
                sortable
                style={{
                  width: '120px',
                }}
                body={(row) => AddField(row.occurrenceRules, 'Regras')}
              />
            </Table> */}
            {procedureGlosa.map((data: any) => (
              <OcurrenceDiv key={data.occurrence || data.occurrenceRules}>
                {data.occurrence && (
                  <p>
                    <strong>Ocorrência:</strong> {data.occurrence}
                  </p>
                )}
                {data.occurrenceRules && (
                  <p>
                    <strong>Regras:</strong> {data.occurrenceRules}
                  </p>
                )}
              </OcurrenceDiv>
            ))}
          </>
        )}
      </div>
    );
  }, [procedureGlosa]);

  const rowExpansionMaterialGlosa = useCallback(() => {
    return (
      <div className="notiflix-material-table">
        <Table items={materialGlosa}>
          <Column
            field="occurrenceNumber"
            header="Nº Ocorrência"
            sortable
            body={(row) => AddField(row.occurrenceNumber, 'Nº Ocorrência')}
          />
          <Column
            field="occurrence"
            header="Ocorrência"
            sortable
            bodyStyle={{ wordBreak: 'break-word' }}
            body={(row) => AddField(row.occurrence, 'Ocorrência')}
          />
          <Column
            field="occurrenceTypeDescription"
            header="Descrição"
            sortable
            body={(row) => AddField(row.occurrenceTypeDescription, 'Descrição')}
          />
          <Column
            field="occurrenceRules"
            header="Regras"
            sortable
            body={(row) => AddField(row.occurrenceRules, 'Regras')}
          />
          <Column
            field="group"
            header="Grupo"
            sortable
            body={(row) => AddField(row.group, 'Grupo')}
          />
          <Column
            field="observation"
            header="Observações"
            sortable
            body={(row) => AddField(row.observation, 'Observações')}
          />
        </Table>
      </div>
    );
  }, [materialGlosa]);

  const getTableData = useCallback(async (filter) => {
    try {
      Notiflix.Block.circle('.table-notiflix');

      const params = filter.join('&');

      const { data } = await api.get(
        `/beneficiary-authorization/list-authorizations-request?${params}`,
      );
      const { content } = data;
      setTableData(content);
    } catch (error) {
      setTableData([]);
      if (error.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar as informações do servidor. Por favor, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.table-notiflix');
    }
  }, []);

  const handleSubmitFilter = useCallback(
    async (data) => {
      const toFilter = [] as string[];

      try {
        const from = dateToPTBR(reportFrom.value);
        toFilter.push(`startDate=${from}`);
      } catch (error) {
        setReportFrom((prev) => ({ ...prev, error: 'Informe uma data' }));
      }
      try {
        const to = dateToPTBR(reportTo.value);
        toFilter.push(`finalDate=${to}`);
      } catch (error) {
        setReportTo((prev) => ({ ...prev, error: 'Informe uma data' }));
      }
      if (providerCodeRef.current?.value) {
        toFilter.push(`providerId=${providerCodeRef.current.value}`);
      }

      if (procedureCodeRef.current?.value) {
        toFilter.push(`procedureCode=${procedureCodeRef.current.value}`);
      }

      if (guideRequisitionCodeRef.current?.value) {
        toFilter.push(
          `guideRequisitionCode=${guideRequisitionCodeRef.current.value}`,
        );
      }

      if (typeOfGuideCodeRef.current?.value) {
        toFilter.push(`guideType=${typeOfGuideCodeRef.current.value}`);
      }

      if (data.checkboxGuide) {
        toFilter.push(`queryType=G`);
      }

      if (data.checkboxRequest) {
        toFilter.push(`queryType=R`);
      }

      const guideRequisition =
        formRef.current?.getFieldValue('guideRequisition');
      if (guideRequisition) {
        toFilter.push(`requestId=${guideRequisition}`);
      }
      getTableData(toFilter);
    },
    [getTableData, reportFrom, reportTo],
  );

  const cleanAuthorizationInputs = useCallback(() => {
    providerCodeRef.current?.setValue('', '');
    procedureTypeRef.current?.setValue('', '');
    typeOfGuideCodeRef.current?.setValue('', '');
    procedureCodeRef.current?.setValue('', '');
    formRef.current?.setFieldValue('guideRequisition', '');
  }, []);

  const isExpired = useCallback((data, days) => {
    if (data) {
      try {
        const date = ptBRToDate(data);
        date.setDate(date.getDate() + days + 1);

        if (isAfter(date, new Date())) {
          return false;
        }
        return true;
      } catch (err) {
        console.log(err);
      }
    }
    return false;
  }, []);

  /* Busca por Prestador */
  const getProviders = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-provider-code');
      const id = providerCodeRef.current?.filtered;
      if (!id) return;
      const { data: providerList } = await api.get(
        `/beneficiary-authorization/find-a-provider?providerName=${id}`,
      );
      setProviderCodeOptions([
        { title: '', value: '' },
        ...providerList.content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { providerName: string; providerCode: string },
          ) => {
            acc.push({
              title: act.providerName,
              value: act.providerCode,
            });
            return acc;
          },
          [],
        ),
      ]);
    } catch (error) {
      if (error.response?.data?.message) {
        Notiflix.Notify.info(error.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos obter os resultados do servidor...',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-provider-code');
    }
  }, []);

  /* Busca por Código do Procedimento */
  const getProceduresCode = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-procedure-code');
      const id = procedureCodeRef.current?.filtered;
      if (!id) return;
      const dataApi = await Promise.allSettled([
        api.get(
          `/beneficiary-authorization/find-a-procedure?procedureCode=${id}`,
        ),
        api.get(
          `/beneficiary-authorization/find-a-procedure?procedureName=${id}`,
        ),
      ]);

      const procedureListCode =
        dataApi[0].status === 'fulfilled'
          ? dataApi[0].value
          : { data: { content: [] } };
      const procedureListName =
        dataApi[1].status === 'fulfilled'
          ? dataApi[1].value
          : { data: { content: [] } };

      if (procedureListCode.data.content && procedureListName.data.content) {
        const { content: contentCode } = procedureListCode.data;
        const { content: contentName } = procedureListName.data;

        // eslint-disable-next-line prefer-const
        let newValues = contentCode.reduce(
          (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            acc: { [x: string]: any },
            act: {
              procedureCode: string | number;
              procedureName: string | number;
            },
          ) => {
            acc[act.procedureCode] = {
              key: act.procedureCode,
              value: act.procedureName,
            };
            return acc;
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          [] as any,
        );
        contentName.forEach(
          (item: {
            procedureCode: string | number;
            procedureName: string | number;
          }) => {
            if (!newValues[item.procedureCode]) {
              newValues[item.procedureCode] = {
                key: item.procedureCode,
                value: item.procedureName,
              };
            }
          },
        );

        setProcedureCodeOptions([
          { title: '', value: '' },
          ...newValues.reduce(
            (
              acc: { title: string; value: string }[],
              act: { key: string; value: string },
            ) => {
              acc.push({
                title: `${act.key} - ${act.value}`,
                value: act.key,
              });
              return acc;
            },
            [],
          ),
        ]);
      }
    } catch (error) {
      Notiflix.Notify.failure(
        'Não conseguimos buscar pelos procedimentos. Tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-procedure-code');
    }
  }, []);

  const defineHeightOfSteps = useMemo(() => {
    switch (actualStep) {
      case 1:
        return heightStep[0];
      case 2:
        return heightStep[1];
      case 3:
        return heightStep[2];
      default:
        return '0px';
    }
  }, [actualStep, heightStep]);

  const revalidatePasswordStep2 = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-revalidate-password');
      const param = internTable[0]?.requestId;
      const { data } = await api.put(
        `/beneficiary-authorization/password-revalidation`,
        { requestId: param },
      );
      const { message } = data;
      Notiflix.Notify.success(message);
    } catch (error) {
      if (error?.response?.data.message) {
        Notiflix.Notify.info(error.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops. Algo deu errado enquanto processavamos sua solicitação. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-revalidate-password');
    }
  }, [internTable]);

  const requestReanalysisStep2 = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-request-reanalysis');
      const { data } = await api.get(
        `/beneficiary-authorization/request-reanalysis?=${user.serviceProtocolNumber}`,
      );
      const { message } = data;
      Notiflix.Notify.success(message);
    } catch (error) {
      if (error?.response?.data.message) {
        Notiflix.Notify.info(error.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops. Algo deu errado enquanto processavamos sua solicitação. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-request-reanalysis');
    }
  }, [user]);

  /* Busca por Procedimento */
  const getProcedureOptions = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-procedure-type');
      const { data } = await api.get(
        `/beneficiary-authorization/list-of-procedure-classification`,
      );
      setProcedureOptions([
        { title: '', value: '' },
        ...data.content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      ]);
    } catch (error) {
      Notiflix.Notify.failure('erro Procedimento');
    } finally {
      Notiflix.Block.remove('.notiflix-procedure-type');
    }
  }, []);

  /* Busca por Tipo de Guia */
  const getTypeofGuides = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-typeOfGuide-code');
      const { data } = await api.get(
        `/beneficiary-authorization/list-type-of-guide`,
      );
      setTypeOfGuideCodeOptions([
        { title: '', value: '' },
        ...data.content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      ]);
    } catch (error) {
      if (error.response?.data?.message) {
        Notiflix.Notify.info(error.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar os tipos da guia.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-typeOfGuide-code');
    }
  }, []);

  const getProcedureData = useCallback(async (requestId) => {
    try {
      const { data } = await api.get(
        `/beneficiary-authorization/requisition-procedures?requestId=${requestId}`,
      );
      const { content } = data;
      setInternProcedureTable(content);
    } catch (error) {
      if (error?.response?.status === 400) {
        Notiflix.Notify.info(error.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Não foi possível buscar o procedimento de requisição. Tente novamente mais tarde.',
        );
      }
    }
  }, []);

  const getMaterialData = useCallback(async (requestId) => {
    try {
      const { data } = await api.get(
        `/beneficiary-authorization/material-requisition?procRequestNumber=${requestId}`,
      );
      const { content } = data;
      setInternMaterialTable(content);
    } catch (error) {
      if (error?.response?.status === 400) {
        Notiflix.Notify.info(error.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Não foi possível buscar o procedimento de requisição. Tente novamente mais tarde.',
        );
      }
    }
  }, []);

  const getCIDdata = useCallback(async (requestId) => {
    try {
      const { data } = await api.get(
        `/beneficiary-authorization/cid-request?requestId=${requestId}`,
      );
      const { content } = data;
      setInternCIDTable(content);
    } catch (error) {
      if (error?.response?.status === 400) {
        Notiflix.Notify.info(error.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Não foi possível buscar o CID. Tente novamente mais tarde.',
        );
      }
    }
  }, []);

  const getOnlyAuthDetail = useCallback(async (rowData) => {
    const { requestId } = rowData;
    try {
      Notiflix.Block.circle('.table-notiflix');
      const { data } = await api.get(
        `beneficiary-authorization/get-authorization-detail?requestId=${requestId}`,
      );
      const { content } = data;
      setInternTable(
        content.reduce((acc: any[], act: any) => {
          acc.push({
            ...act,
            requestId,
          });

          return acc;
        }, []),
      );
    } catch (error) {
      console.log(error);
    } finally {
      Notiflix.Block.remove('.table-notiflix');
    }
  }, []);

  const getAuthDetail = useCallback(async (rowData) => {
    if (!rowData.data) {
      return;
    }
    if (rowData.data.length > 0) {
      const { requestId } =
        rowData.data.length > 1 ? rowData.data[1] : rowData.data[0];

      try {
        Notiflix.Block.circle('.table-notiflix');

        const { data } = await api.get(
          `beneficiary-authorization/requisition-guide-data?requestId=${requestId}`,
        );

        const { content } = data;
        setInternTable(
          content.reduce((acc: any[], act: any) => {
            acc.push({
              ...act,
              requestId,
            });

            return acc;
          }, []),
        );
        if (rowData.data.length > 1) {
          setExpandedRows([rowData.data[1]]);
          return;
        }
        setExpandedRows(rowData.data);
      } catch (error) {
        if (error?.response?.status === 400) {
          Notiflix.Notify.info(error.response.data?.message);
        } else {
          Notiflix.Notify.failure(
            'Não conseguimos buscar seu relatório. Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove('.table-notiflix');
      }
    } else {
      setExpandedRows([]);
    }
  }, []);

  const handleViewDetailsOfStep2 = useCallback(
    async (rowData) => {
      getCIDdata(rowData.requestId);
      getProcedureData(rowData.requestId);
      getMaterialData(rowData.requestId);
      getOnlyAuthDetail(rowData);
      setActualStep(2);
    },
    [getCIDdata, getProcedureData, getMaterialData, getOnlyAuthDetail],
  );
  /**
   * @description get initial values for selects
   */
  useEffect(() => {
    getProcedureOptions();
    getTypeofGuides();
    const from = `01/01/${new Date().getFullYear()}`;
    const to = `31/12/${new Date().getFullYear()}`;
    getTableData([`startDate=${from}`, `finalDate=${to}`]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateHeightOfSteps = useCallback(() => {
    setHeightStep([
      `auto`,
      `${step2Ref.current?.scrollHeight}px`,
      `${step3Ref.current?.scrollHeight}px`,
    ]);
  }, []);

  useEffect(() => {
    calculateHeightOfSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableData,
    actualStep,
    internCIDTable,
    internProcedureTable,
    internMaterialTable,
    procedureGuide,
    materialGuide,
  ]);

  useEffect(() => {
    const delaySetHeight = () => {
      window.setTimeout(() => {
        calculateHeightOfSteps();
      }, 500);
    };

    window.addEventListener('resize', delaySetHeight);
    return () => {
      window.removeEventListener('resize', delaySetHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper gridTemplateColumns="repeat(10,1fr)">
      <Container>
        <Title>Autorização</Title>
        <Steps defineHeight={defineHeightOfSteps}>
          <Step1 show={actualStep === 1} ref={step1Ref}>
            <ReportsContainer>
              <FormWrapper
                className="formWrapper-notiflix"
                ref={formRef}
                onSubmit={handleSubmitFilter}
                formLook
              >
                <Select
                  className="notiflix-provider-code provider-select"
                  ref={providerCodeRef}
                  title="Código de prestador"
                  name="providerCode"
                  formLook
                  widthContainerDesktop="30%"
                  options={providerCodeOptions}
                  inputMode
                  placeholder="Digite aqui pra buscar..."
                  apiSearch={getProviders}
                  themeColor={colors.palet.institutional8}
                />
                <Select
                  className="notiflix-procedure-type"
                  ref={procedureTypeRef}
                  title="Procedimento"
                  name="procedureType"
                  formLook
                  widthContainerDesktop="20%"
                  placeholder="Digite por um procedimento"
                  options={procedureOptions}
                  inputMode
                  themeColor={colors.palet.institutional8}
                />
                <Select
                  className="notiflix-procedure-code"
                  ref={procedureCodeRef}
                  title="Código de procedimento"
                  name="procedureCode"
                  formLook
                  placeholder="Digite aqui pra buscar..."
                  widthContainerDesktop="50%"
                  options={procedureCodeOptions}
                  inputMode
                  apiSearch={getProceduresCode}
                  themeColor={colors.palet.institutional8}
                />

                <Input
                  className="notiflix-guideRequisition-code"
                  title="Guia / Requisição"
                  name="guideRequisition"
                  formLook
                  widthContainerDesktop="25%"
                  themeColor={colors.palet.institutional8}
                />

                <Select
                  className="notiflix-typeOfGuide-code"
                  ref={typeOfGuideCodeRef}
                  title="Tipo de guia"
                  name="typeOfGuide"
                  formLook
                  widthContainerDesktop="25%"
                  options={typeOfGuideCodeOptions}
                  themeColor={colors.palet.institutional8}
                />

                <DatePicker
                  name="from"
                  onChange={(date: Date) =>
                    setReportFrom({ value: date, error: '' })
                  }
                  selected={reportFrom.value}
                  error={reportFrom.error}
                  icon
                  formLook
                  title="Período de:"
                  widthContainerDesktop="25%"
                  themeColor={colors.palet.institutional8}
                />

                <DatePicker
                  name="to"
                  onChange={(date: Date) =>
                    setReportTo({ value: date, error: '' })
                  }
                  selected={reportTo.value}
                  error={reportTo.error}
                  icon
                  formLook
                  title="Até:"
                  widthContainerDesktop="25%"
                  themeColor={colors.palet.institutional8}
                />

                <CheckboxGroup>
                  <Checkbox
                    value="Guia"
                    name="checkboxGuide"
                    onChange={() =>
                      formRef.current?.setFieldValue('checkboxRequest', false)
                    }
                    themeColor={colors.palet.institutional8}
                  />
                  <Checkbox
                    value="Requisição"
                    name="checkboxRequest"
                    defaultChecked
                    onChange={() =>
                      formRef.current?.setFieldValue('checkboxGuide', false)
                    }
                    themeColor={colors.palet.institutional8}
                  />
                </CheckboxGroup>

                <ButtonsContainer>
                  <ClearButton
                    onClick={cleanAuthorizationInputs}
                    type="button"
                    autoWidth
                    formLook
                    secondary
                  >
                    Limpar
                  </ClearButton>

                  <ReportsButton type="submit" autoWidth purple2Button formLook>
                    Consultar
                  </ReportsButton>
                </ButtonsContainer>
              </FormWrapper>
            </ReportsContainer>

            <TableContainer>
              <Table
                items={tableData}
                expandedRows={expandedRows}
                onRowToggle={(e) => {
                  getAuthDetail(e);
                }}
                rowExpansionTemplate={rowExpansionTemplate}
                className="table-notiflix table-home"
              >
                <Column
                  field="requestId"
                  header="Requisição"
                  sortable
                  body={(data) => addField(data.requestId, 'Requisição')}
                />
                <Column
                  field="requestDate"
                  header="Data da Requisição"
                  className="date"
                  body={(data) =>
                    addField(data.requestDate, 'Data da Requisição')
                  }
                />
                <Column
                  field="guideDescription"
                  header="Tipo de Requisição"
                  sortable
                  body={(data) =>
                    addField(data.guideDescription, 'Tipo de Requisição')
                  }
                />
                <Column
                  field="expirationDate"
                  header="Data da Validade"
                  className="date"
                  body={(data) =>
                    addField(data.expirationDate, 'Data da Validade')
                  }
                />
                <Column
                  field="phaseDescription"
                  header="Estágio"
                  sortable
                  className="tag"
                  body={(data) => {
                    switch (data.phaseDescription) {
                      case 'Aprovada':
                        return getTag(
                          'Estágio',
                          'Aprovada',
                          'green',
                          data.phaseDescription,
                        );
                      case 'Aberta':
                        return getTag(
                          'Estágio',
                          'Aberta',
                          'orange',
                          data.phaseDescription,
                        );
                      default:
                        return <>{data.phaseDescription}</>;
                    }
                  }}
                />
                <Column
                  field="id"
                  style={{ width: '120px' }}
                  body={(data) => (
                    <Button
                      sausageLook
                      className={`loading-button-${data.requestId}`}
                      autoWidth
                      noChangeColor
                      onClick={() => handleDownloadReportRequest(data)}
                    >
                      Relatório
                    </Button>
                  )}
                />
                <Column
                  field="id"
                  style={{ width: '120px' }}
                  body={(data) => {
                    return (
                      <Button
                        sausageLook
                        grayButton
                        autoWidth
                        noChangeColor
                        onClick={() => handleViewDetailsOfStep2(data)}
                      >
                        Detalhes
                      </Button>
                    );
                  }}
                />
                <Column
                  header=""
                  expander
                  headerStyle={{ width: '80px' }}
                  className="detalhe"
                />
                {/* <Column
                  header=""
                  body={(data) => {
                    if (data.phaseDescription !== 'Aberta') {
                      return (
                        <button
                          type="button"
                          onClick={() => {
                            if (expandedRows.length > 0) {
                              if (expandedRows[0] === data) {
                                setExpandedRows([]);
                              } else {
                                getAuthDetail({ data: [data] });
                              }
                            } else {
                              getAuthDetail({ data: [data] });
                            }
                          }}
                          className="p-row-toggler p-link"
                          aria-expanded="false"
                          aria-controls="content_5_expanded"
                        >
                          <span
                            className={`p-row-toggler-icon pi pi-fw p-clickable pi-chevron-${
                              (expandedRows.length &&
                                expandedRows[0] === data &&
                                'down') ||
                              'right'
                            }`}
                          />
                        </button>
                      );
                    }
                    return <> </>;
                  }}
                  className="detalhe"
                /> */}
              </Table>
            </TableContainer>
          </Step1>
          <Step2
            show={actualStep === 2}
            ref={step2Ref}
            className="notiflix-step.block"
          >
            <Container>
              <FormWrapper ref={formRefStep2} onSubmit={() => console.log('')}>
                <Input
                  value={internTable[0]?.nrSeqProtocoloReq ?? ''}
                  disabled
                  insideInputTitle="Protocolo"
                  name="protocolo"
                  widthContainerDesktop="20%"
                />
                <Input
                  value={internTable[0]?.insuredName ?? ''}
                  disabled
                  insideInputTitle="Beneficiário"
                  name="insuredName"
                  widthContainerDesktop="30%"
                />
                <Input
                  value={internTable[0]?.doctorName ?? ''}
                  disabled
                  insideInputTitle="Médico"
                  name="doctorName"
                  widthContainerDesktop="50%"
                />
                <Input
                  value={internTable[0]?.typeOfService ?? ''}
                  disabled
                  insideInputTitle="Tipo de atendimento"
                  name="typeOfService"
                  widthContainerDesktop="30%"
                />
                <Input
                  value={internTable[0]?.executeProviderName ?? ''}
                  disabled
                  insideInputTitle="Prestador executante"
                  name="executeProviderName"
                  widthContainerDesktop="40%"
                />
                <Input
                  value={internTable[0]?.requestId ?? ''}
                  disabled
                  insideInputTitle="Requisição"
                  name="requestId"
                  widthContainerDesktop="30%"
                />
                <Input
                  value={internTable[0]?.productName ?? ''}
                  disabled
                  insideInputTitle="Produto"
                  name="produto"
                  widthContainerDesktop="33.33%"
                />
                <Input
                  value={internTable[0]?.serviceCharacter ?? ''}
                  disabled
                  insideInputTitle="Caráter de atendimento"
                  name="serviceCharacter"
                  widthContainerDesktop="20%"
                />
                <Input
                  value={internTable[0]?.requestProviderName ?? ''}
                  disabled
                  insideInputTitle="Prestador"
                  name="requestProviderName"
                  widthContainerDesktop="33.33%"
                />
                <Input
                  value={internTable[0]?.emergencyConsultation ?? ''}
                  disabled
                  insideInputTitle="Consulta de urgência"
                  name="emergencyConsultation"
                  widthContainerDesktop="13.30%"
                />
                <Input
                  value={internTable[0]?.clinicalIndication ?? ''}
                  disabled
                  insideInputTitle="Indicação clínica"
                  name="clinicalIndication"
                  widthContainerDesktop="20%"
                />
                <Input
                  value={internTable[0]?.solicitationDate ?? ''}
                  disabled
                  insideInputTitle="Data de requisição"
                  name="solicitationDate"
                  widthContainerDesktop="20%"
                />
                <Input
                  value={internTable[0]?.guideDescription ?? ''}
                  disabled
                  insideInputTitle="Tipo"
                  name="processType"
                  widthContainerDesktop="20%"
                />
                <Input
                  value={internTable[0]?.consultationType ?? ''}
                  disabled
                  insideInputTitle="Tipo de consulta"
                  name="consultationType"
                  widthContainerDesktop="20%"
                />
                <Input
                  value={internTable[0]?.phaseDescription ?? ''}
                  disabled
                  insideInputTitle="Estágio"
                  name="phaseDescription"
                  widthContainerDesktop="20%"
                />

                <ButtonsStep2>
                  <Button
                    formLook
                    autoWidth
                    style={{ minWidth: '150px' }}
                    secondary
                    onClick={() => setActualStep(1)}
                  >
                    Voltar
                  </Button>

                  {isExpired(internTable[0]?.passwordExpirationDate, 29) && (
                    <Button
                      grayButton
                      formLook
                      autoWidth
                      style={{ minWidth: '150px' }}
                      onClick={() => revalidatePasswordStep2()}
                      className="notiflix-revalidate-password"
                    >
                      Revalidar Senha
                    </Button>
                  )}

                  {formRefStep2.current?.getFieldValue('estagio') ===
                    'negada' &&
                    !isExpired(
                      formRefStep2.current?.getFieldValue('solicitationDate'),
                      7,
                    ) && (
                      <Button
                        formLook
                        grayButton
                        style={{ minWidth: '150px' }}
                        autoWidth
                        onClick={() => requestReanalysisStep2()}
                        className="notiflix-request-reanalysis"
                      >
                        Solicitar reanalise
                      </Button>
                    )}
                </ButtonsStep2>
              </FormWrapper>

              <TableWrapper>
                {internCIDTable?.length !== 0 && (
                  <>
                    <TitleIntern themeColor={defaultColor}>CID</TitleIntern>
                    <Table
                      items={internCIDTable}
                      style={{
                        marginBottom: '30px',
                      }}
                    >
                      <Column
                        field="cidNumber"
                        header="Número CID"
                        sortable
                        body={(row) => AddField(row.cidNumber, 'Número CID')}
                      />
                      <Column
                        field="diseaseNumber"
                        header="Número da doença"
                        sortable
                        body={(row) =>
                          AddField(row.diseaseNumber, 'Número da doença')
                        }
                      />
                      <Column
                        field="diseaseName"
                        header="Nome da doença"
                        sortable
                        body={(row) =>
                          AddField(row.diseaseName, 'Nome da doença')
                        }
                      />
                      <Column
                        field="diseaseType"
                        header="Tipo de doença"
                        sortable
                        body={(row) =>
                          AddField(row.diseaseType, 'Tipo de doença')
                        }
                      />
                      <Column
                        field="accidentType"
                        header="Tipo de acidente"
                        sortable
                        body={(row) =>
                          AddField(row.accidentType, 'Tipo de acidente')
                        }
                      />
                    </Table>
                  </>
                )}

                {internProcedureTable?.length !== 0 && (
                  <>
                    <TitleIntern themeColor={defaultColor}>
                      Procedimento
                    </TitleIntern>
                    <Table
                      items={internProcedureTable}
                      onRowToggle={(e) => {
                        return getProcedureGlosa(e.data);
                      }}
                      rowExpansionTemplate={rowExpansionsProductGlosa}
                      expandedRows={expandedRows2}
                      columnResizeMode="fit"
                      key="authorization2"
                      className="table-notiflix table-intern procedure-table"
                      id="authorization"
                    >
                      <Column
                        field="code"
                        header="Código"
                        sortable
                        body={(row) => AddField(row.code, 'Código')}
                        style={{
                          width: '120px',
                        }}
                      />
                      <Column
                        field="procedureName"
                        header="Nome do procedimento"
                        sortable
                        body={(row) =>
                          AddField(row.procedureName, 'Nome do procedimento')
                        }
                      />
                      <Column
                        field="quantityOrdered"
                        header="Quantidade solicitada"
                        sortable
                        body={(row) =>
                          AddField(row.quantityOrdered, 'Quantidade solicitada')
                        }
                        style={{
                          width: '120px',
                          textAlign: 'center',
                        }}
                      />
                      <Column
                        field="quantityApproved"
                        header="Quantidade Aprovada"
                        sortable
                        body={(row) =>
                          AddField(row.quantityApproved, 'Quantidade Aprovada')
                        }
                        style={{
                          width: '120px',
                          textAlign: 'center',
                        }}
                      />
                      <Column
                        field="status"
                        header="Status"
                        sortable
                        body={(row) => AddField(row.status, 'Status')}
                        style={{
                          width: '120px',
                        }}
                      />

                      <Column
                        expander
                        headerStyle={{ width: '80px' }}
                        className="detalhe"
                      />
                    </Table>
                  </>
                )}

                {internMaterialTable?.length !== 0 && (
                  <>
                    <TitleIntern themeColor={defaultColor}>
                      Material
                    </TitleIntern>
                    <Table
                      items={internMaterialTable}
                      onRowToggle={(e) => getMaterialGlosa(e.data)}
                      rowExpansionTemplate={rowExpansionMaterialGlosa}
                      expandedRows={expandedRows3}
                      key="authorization3"
                      className="table-notiflix table-intern"
                      columnResizeMode="fit"
                      id="authorization"
                    >
                      <Column
                        field="code"
                        header="Código"
                        sortable
                        body={(row) => AddField(row.code, 'Código')}
                      />
                      <Column
                        field="procedureName"
                        header="Nome do procedimento"
                        sortable
                        body={(row) =>
                          AddField(row.procedureName, 'Nome do procedimento')
                        }
                      />
                      <Column
                        field="quantityOrdered"
                        header="Quantidade solicitada"
                        sortable
                        body={(row) =>
                          AddField(row.quantityOrdered, 'Quantidade solicitada')
                        }
                        style={{
                          width: '100px',
                          textAlign: 'right',
                        }}
                      />
                      <Column
                        field="quantityApproved"
                        header="Quantidade Aprovada"
                        sortable
                        body={(row) =>
                          AddField(row.quantityApproved, 'Quantidade Aprovada')
                        }
                        style={{
                          width: '100px',
                          textAlign: 'right',
                        }}
                      />
                      <Column
                        field="status"
                        header="Status"
                        sortable
                        body={(row) => AddField(row.status, 'Status')}
                      />

                      <Column
                        expander
                        headerStyle={{ width: '80px' }}
                        className="detalhe"
                      />
                    </Table>
                  </>
                )}
              </TableWrapper>
            </Container>
          </Step2>
          <Step3
            show={actualStep === 3}
            ref={step3Ref}
            className="notiflix-step.block"
          >
            <TableContainer>
              {procedureGuide?.length !== 0 && (
                <>
                  <TitleIntern themeColor={defaultColor}>
                    Procedimento
                  </TitleIntern>
                  <Table
                    items={procedureGuide}
                    key="procedureList"
                    className="procedure-data-notiflix"
                    columnResizeMode="fit"
                  >
                    <Column
                      field="code"
                      header="Código"
                      sortable
                      body={(data) => addField(data.code, 'Código')}
                      style={{
                        width: '110px',
                      }}
                    />
                    <Column
                      field="procedureName"
                      header="Nome do Procedimento"
                      sortable
                      body={(data) =>
                        addField(data.procedureName, 'Nome do Procedimento')
                      }
                    />
                    <Column
                      field="quantityOrdered"
                      header="Quantidade requisitada"
                      sortable
                      body={(data) =>
                        addField(data.quantityOrdered, 'Quantidade requisitada')
                      }
                      style={{
                        width: '120px',
                        textAlign: 'center',
                      }}
                    />
                    <Column
                      field="quantityApproved"
                      header="Quantidade aprovada"
                      sortable
                      body={(data) =>
                        addField(data.quantityApproved, 'Quantidade aprovada')
                      }
                      style={{
                        width: '120px',
                        textAlign: 'center',
                      }}
                    />
                    <Column
                      field="status"
                      header="Status"
                      sortable
                      style={{
                        width: '120px',
                      }}
                      body={(data) => addField(data.status, 'Status')}
                    />
                  </Table>
                </>
              )}

              {materialGuide?.length !== 0 && (
                <>
                  <TitleIntern themeColor={colors.palet.institutional8}>
                    Material
                  </TitleIntern>
                  <Table
                    items={materialGuide}
                    className="material-data-notiflix"
                    key="materialList"
                    columnResizeMode="fit"
                  >
                    <Column
                      field="code"
                      header="Código"
                      sortable
                      body={(data) => addField(data.code, 'Código')}
                    />
                    <Column
                      field="material"
                      header="Material"
                      sortable
                      body={(data) => addField(data.material, 'Material')}
                    />
                    <Column
                      field="quantityOrdered"
                      header="Quantidade requisitada"
                      sortable
                      body={(data) =>
                        addField(data.quantityOrdered, 'Quantidade requisitada')
                      }
                    />
                    <Column
                      field="quantityApproved"
                      header="Quantidade aprovada"
                      sortable
                      body={(data) =>
                        addField(data.quantityApproved, 'Quantidade aprovada')
                      }
                    />
                    <Column
                      field="status"
                      header="Status"
                      sortable
                      body={(data) => addField(data.status, 'Status')}
                    />
                  </Table>
                </>
              )}
            </TableContainer>

            <FormWrapper ref={formRefStep3} onSubmit={() => console.log('')}>
              <Button
                formLook
                autoWidth
                style={{ minWidth: '150px' }}
                secondary
                onClick={() => setActualStep(1)}
              >
                Voltar
              </Button>
            </FormWrapper>
          </Step3>
        </Steps>
      </Container>
    </PageWrapper>
  );
};

export default Authorization;
