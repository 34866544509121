import React, { useCallback, useEffect, useState } from 'react';
import { FiTwitter } from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api/api';
import { ObjectGetValueOfKey } from '../../utils/formatt';

import { WhatsappSvg, PhoneSvg, GrupowSvg } from '../Svg';
import {
  ContainerDesktop,
  Label,
  Address,
  ANSContainer,
  ContainerMobile,
  Row,
  LabelMobile,
} from './styles';

interface FooterProps {
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ className }) => {
  const { isAuthendicated } = useAuth();

  const { user } = useAuth();
  return (
    <>
      <ContainerDesktop className={className}>
        <Label>
          Fale Conosco:
          <a href="tel:08006470026" target="_blank" rel="noreferrer">
            0800 647-0026
          </a>
        </Label>
        <Label>
          Whatsapp:
          <a href="https://wa.me/47999010459" target="_blank" rel="noreferrer">
            (47)99901-0459
          </a>
        </Label>
        <Address>
          <p>Rua das Missões, 455 • Ponta Aguda</p>
          <p>CEP: 89051-900 • Blumenau - Santa Catarina</p>
          <div>
            <a
              href="https://www.unimedblumenau.coop.br"
              target="_blank"
              rel="noreferrer"
            >
              www.unimedblumenau.coop.br
            </a>
            |
            <a
              href="https://twitter.com/unimedblumenau"
              target="_blank"
              rel="noreferrer"
            >
              <FiTwitter size={10} /> @unimedblumenau
            </a>
          </div>
        </Address>
        <ANSContainer>
          <p>ANS - nº 33456-1</p>
        </ANSContainer>
      </ContainerDesktop>

      <ContainerMobile>
        <Row>
          <LabelMobile>
            <PhoneSvg />
            <div>
              <p>Fale Conosco:</p>
              <a href="tel:08006470026" target="_blank" rel="noreferrer">
                0800 647.0026
              </a>
            </div>
          </LabelMobile>
          <LabelMobile>
            <WhatsappSvg />
            <div>
              <p>WhatsApp:</p>
              <a
                href="https://wa.me/47999010459"
                target="_blank"
                rel="noreferrer"
              >
                (47)99901-0459
              </a>
            </div>
          </LabelMobile>
        </Row>
        <Row>
          <ANSContainer>
            <p>ANS - nº 33456-1</p>
          </ANSContainer>
        </Row>
        <Row className="grupow">
          <a href="https://www.grupow.com.br" target="_blank" rel="noreferrer">
            Desenvolvido por <strong>GrupoW Softwares para Internet</strong>{' '}
            <GrupowSvg />{' '}
          </a>
        </Row>
      </ContainerMobile>
    </>
  );
};

export default Footer;
