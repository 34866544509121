import styled from 'styled-components';
import FormWrapper from '../../components/FormWrapper';

export const Container = styled.div`
  grid-column: 2;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

export const FormContent = styled(FormWrapper)`
  display: flex;
  flex-wrap: wrap;

  > div {
    @media (min-width: 769px) {
      &:nth-child(2),
      &:nth-child(3) {
        width: calc(50% - 4px);
      }
    }
  }
  button {
    margin-left: auto;
    width: 150px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
