import React, { useState, useEffect } from 'react';
import { Pie, Cell } from 'recharts';
import { v4 } from 'uuid';
import { TiLocationArrow } from 'react-icons/ti';
import { useTheme } from '../../hooks/theme';

import {
  PointerPie,
  ChartContainer,
  PieChartContainer,
  Container,
  ZeroToTwoHundredTitle,
  ValuesContainer,
  Title,
} from './styles';

interface LossRatioGeneralValues {
  goalContractPercent: number;
  pointerPercentContract: number;
}

const LossRatioGeneralGraphic: React.FC<LossRatioGeneralValues> = ({
  goalContractPercent,
  pointerPercentContract,
}) => {
  const { theme } = useTheme();

  const [svgColor, setSvgColor] = useState('#5B5C65');

  useEffect(() => {
    if (theme.title === 'light') {
      setSvgColor('#5B5C65');
    } else {
      setSvgColor('#fff');
    }
  }, [theme]);

  const COLORS = ['#00C49F', '#FFBB28', '#FF8042', '#D76C6C'];
  const formatNumber = (defaultValue: number, floatNumber: number) =>
    Number.parseFloat((defaultValue * floatNumber).toFixed(2));
  const convertPercentToDegree = (choosedPercent: number) => {
    return (choosedPercent * 220) / 120 - 110;
  };
  const yellow = formatNumber(goalContractPercent, 0.08);
  const orange = formatNumber(goalContractPercent, 0.04);
  const restoContratual = Number(
    Number(120 - goalContractPercent - yellow - orange).toFixed(2),
  );
  const lossRationGeneralData = [
    { name: 'Group A', value: goalContractPercent },
    { name: 'Group B', value: yellow },
    { name: 'Group C', value: orange },
    { name: 'Group D', value: restoContratual },
  ];

  let valuePointer = pointerPercentContract;

  if (pointerPercentContract > 120) {
    valuePointer = 130;
  }

  if (pointerPercentContract < 0) {
    valuePointer = 0;
  }

  return (
    <Container>
      <ChartContainer>
        <PieChartContainer>
          <Pie
            data={lossRationGeneralData}
            startAngle={200}
            endAngle={-20}
            innerRadius={80}
            outerRadius={95}
            dataKey="value"
            label={(data) => {
              return `${data.value}%`;
            }}
          >
            {lossRationGeneralData.map((entry, index) => (
              <Cell key={`cell-${v4()}}`} fill={COLORS[index]} />
            ))}
          </Pie>
        </PieChartContainer>
      </ChartContainer>
      <ValuesContainer>
        <ZeroToTwoHundredTitle>
          <h3>0%</h3>
          <h3>120%</h3>
        </ZeroToTwoHundredTitle>
        <Title>{`${pointerPercentContract}%`}</Title>
        <PointerPie
          className="pointer"
          pointerContract={String(goalContractPercent)}
          pointerValue={convertPercentToDegree(valuePointer)}
        >
          <TiLocationArrow size={24} color={svgColor} />
        </PointerPie>
      </ValuesContainer>
    </Container>
  );
};

export default LossRatioGeneralGraphic;
