/* eslint-disable max-len */
import React, { memo } from 'react';

const Sucess: React.FC = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.244 24C43.9307 23.9973 43.6259 23.8979 43.3714 23.7153C43.1168 23.5327 42.9249 23.2759 42.822 22.98C42.6118 22.4008 42.2284 21.9005 41.7238 21.5468C41.2193 21.1932 40.6181 21.0035 40.002 21.0035C39.3859 21.0035 38.7847 21.1932 38.2802 21.5468C37.7756 21.9005 37.3922 22.4008 37.182 22.98C37.0792 23.276 36.8874 23.5329 36.6328 23.7155C36.3782 23.8982 36.0733 23.9975 35.76 24C35.5223 24.001 35.2878 23.9453 35.0759 23.8377C34.8639 23.73 34.6807 23.5734 34.5414 23.3808C34.402 23.1882 34.3106 22.9652 34.2746 22.7302C34.2386 22.4952 34.2591 22.2551 34.3344 22.0296C34.743 20.8515 35.5084 19.8299 36.5244 19.107C37.5403 18.384 38.7563 17.9955 40.0032 17.9955C41.2501 17.9955 42.4661 18.384 43.482 19.107C44.498 19.8299 45.2634 20.8515 45.672 22.0296C45.7476 22.2553 45.7683 22.4957 45.7323 22.731C45.6963 22.9663 45.6047 23.1895 45.465 23.3823C45.3254 23.575 45.1417 23.7316 44.9293 23.839C44.717 23.9464 44.482 24.0016 44.244 24Z"
        fill="#B1D34B"
      />
      <path
        d="M30 0C13.4316 0 0 13.4316 0 30C0 46.5684 13.4316 60 30 60C46.5684 60 60 46.5684 60 30C60 13.4316 46.5684 0 30 0ZM30 54C16.7448 54 6 43.2552 6 30C6 16.7448 16.7448 6 30 6C43.2552 6 54 16.7448 54 30C54 43.2552 43.2552 54 30 54Z"
        fill="#B1D34B"
      />
      <path
        d="M44.7252 30C45.1648 29.9993 45.5992 30.096 45.997 30.2831C46.3948 30.4703 46.7463 30.7433 47.026 31.0824C47.3057 31.4216 47.5069 31.8186 47.6149 32.2447C47.7229 32.6709 47.7352 33.1157 47.6508 33.5472C46.8351 37.6279 44.6306 41.3 41.4125 43.9384C38.1944 46.5769 34.1615 48.0188 30 48.0188C25.8385 48.0188 21.8056 46.5769 18.5875 43.9384C15.3694 41.3 13.1649 37.6279 12.3492 33.5472C12.2648 33.1157 12.2771 32.6709 12.3851 32.2447C12.4931 31.8186 12.6943 31.4216 12.974 31.0824C13.2537 30.7433 13.6051 30.4703 14.003 30.2831C14.4008 30.096 14.8351 29.9993 15.2748 30H15.318C16.009 30.0048 16.6772 30.2481 17.2096 30.6887C17.742 31.1292 18.106 31.7401 18.24 32.418C18.7943 35.1268 20.267 37.5611 22.4092 39.3093C24.5513 41.0575 27.2314 42.0124 29.9964 42.0124C32.7614 42.0124 35.4415 41.0575 37.5836 39.3093C39.7258 37.5611 41.1985 35.1268 41.7528 32.418C41.8871 31.7389 42.2521 31.1271 42.786 30.6864C43.3199 30.2457 43.9897 30.0032 44.682 30H44.7252Z"
        fill="#B1D34B"
      />
      <path
        d="M20.0004 24C21.6573 24 23.0004 22.6569 23.0004 21C23.0004 19.3431 21.6573 18 20.0004 18C18.3435 18 17.0004 19.3431 17.0004 21C17.0004 22.6569 18.3435 24 20.0004 24Z"
        fill="#B1D34B"
      />
    </svg>
  );
};
export default memo(Sucess);
