export default {
  title: 'dark',

  colors: {
    background: '#18181A',

    text: {
      normal: '#fff',
      black: '#000',
      blackToWhite: '#fff',
      primary: '#2C2D35',
      grayishToWhite: '#fff',
      grayishToBlack: '#18181A',
      grayishToLightGray: '#EAEDEF',
      secondary: '#C4CBCF',
      lightGrayToBlack: '#18181A',
      grayToHeavyGray: '#2C2D35',
      grayToHeavyGrayish: '#2C2D35',
      grayToGrayish: '#2C2D35',
      white: '#fff',
      inverse: '#000',
      whiteToGrayish: '#5B5C65',
      whiteToHeavyGray: '#2C2D35',
      whiteToBlack: '#18181A',
    },
    palet: {
      institutional: '#00995D',
      institutional2: '#006228',
      institutional3: '#008575',
      institutional4: '#B1D34B',
      institutional5: '#FFF0C7',
      institutional6: '#F47920',
      institutional7: '#FB1C37',
      institutional8: '#6A00BE',
      institutional9: '#9C0083',
      institutional10: '#007163',
      institutional11: '#18181A',
      institutional12: '#5B5C66',
      institutional13: '#EAEDEF',
      institutional14: '#FAFAFA',
      institutional15: '#6018F9',
    },
  },
};
