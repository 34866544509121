import styled from 'styled-components';

export const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  > h3 {
    width: 100%;
    margin-bottom: 4px;
    font-family: 'Unimed-Sans-Bold';
    font-size: 1rem;
  }
  > ol {
    > li {
      > span {
        margin-left: 4px;
      }
      list-style: none;
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      overflow: hidden;
      color: ${(props) => props.theme.colors.text.lightGrayToBlack};
      background: linear-gradient(
        to right,
        #59db4d,
        #59db4d 50%,
        ${(props) => props.theme.colors.text.lightGrayToBlack} 50%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 201% 100%;
      background-position: 100%;
      font-family: 'Unimed-Sans-Bold';
      cursor: default;
      transition: background-position 0.5s ease-in-out;
      &.check {
        color: #59db4d;
        transition: 0.5s ease-in-out;
        background-position: 0 100%;
      }
    }
  }
`;
