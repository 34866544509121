import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';

import Notiflix from 'notiflix';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Container, Title, DebtAccountForm, TextAuthorization } from './styles';
import PageWrapper from '../../components/PageWrapper';
import { BeneficiaryUser, UserCommon, useAuth } from '../../hooks/auth';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import Modal2 from '../../components/Modal2';
import api from '../../services/api/api';
import Checkbox from '../../components/Checkbox';
import Select, { SelectHandles } from '../../components/Select';

const AccountDebit: React.FC = () => {
  const history = useHistory();
  const { user: userT } = useAuth();
  const user = userT as UserCommon & BeneficiaryUser;

  const formRef = useRef<FormHandles>(null);
  const bankRef = useRef<SelectHandles>(null);
  const accountRef = useRef<SelectHandles>(null);
  const dueDateRef = useRef<SelectHandles>(null);
  const [productData, setProductData] = useState({} as { hiringType: string });

  const [disabled, setDisabled] = useState(false);

  const [textAuth, setTextAuth] = useState('');

  const [bankOptions, setBankOptions] = useState([
    {
      title: 'Selecione',
      value: '',
    },
  ]);

  const [allBanks, setAllBanks] = useState(
    [] as {
      agencyNumber: string | null;
      bankCode: number;
      bankName: string;
    }[],
  );

  const accountTypeOptions = [
    {
      title: 'Selecione',
      value: '',
    },
    {
      title: 'Conta Corrente de Pessoa Física',
      value: '001',
    },
    {
      title: 'Poupança de Pessoa Física',
      value: '013',
    },
    {
      title: 'Conta Corrente de Pessoa Jurídica',
      value: '003',
    },
  ];

  const dueDate = [
    {
      title: '10',
      value: '10',
    },
    {
      title: '15',
      value: '15',
    },
    {
      title: '20',
      value: '20',
    },
  ];

  const handleDebtSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (data: any) => {
      try {
        Notiflix.Loading.circle();

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          accountHolderName: Yup.string().required(
            'Informe o nome do Correntista',
          ),
          cpfCnpj: Yup.string().required('Informe o seu cpf/cnpj'),
          bankBranch: Yup.string().required('Informe a sua agência'),
          accountNumber: Yup.string().required('Informe a sua conta'),
        });

        bankRef.current?.setError(
          !bankRef.current.value ? 'Selecione um banco' : '',
        );
        accountRef.current?.setError(
          !accountRef.current.value ? 'Selecione um tipo de Conta' : '',
        );
        dueDateRef.current?.setError(
          !dueDateRef.current.value ? 'Selecione uma data de vencimento' : '',
        );

        await schema.validate(data, {
          abortEarly: false,
        });

        if (
          !bankRef.current?.value ||
          !accountRef.current?.value ||
          !dueDateRef.current?.value
        )
          return;

        const realData = {
          ...data,
          bankCode: bankRef.current?.value,
          accountType: accountRef.current?.value,
          dueDay: dueDateRef.current?.value,
        };

        if (realData?.authorizeSendingEmail) {
          realData.authorizeSendingEmail = 'S';
        } else {
          realData.authorizeSendingEmail = 'N';
        }

        const { data: dataApi } = await api.post(
          `/financial/save-account-debit-data`,
          realData,
        );
        const { message } = dataApi;
        Modal2.Success({
          closable: false,
          title: 'Perfeito!',
          text: message,
          children: (
            <>
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            </>
          ),
        });
        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response?.data?.message) {
          Notiflix.Notify.failure(err.response.data.message);
        } else {
          Notiflix.Notify.failure('Algo de errado ocorreu...');
        }
      } finally {
        Notiflix.Loading.remove();
      }
    },
    [history],
  );

  const getTextAuth = useCallback(async () => {
    try {
      Notiflix.Block.circle('.container-loading-notiflix');

      const { data } = await api.get(
        `/financial/account-debit-authorization?userUnimedId=${user.userUnimedId}`,
      );
      const { content } = data;
      setTextAuth(content[0].message);
      Notiflix.Block.remove('.container-loading-notiflix');
    } catch (error) {
      if (error.response?.status === 400) {
        Modal2.Warning({
          closable: true,
          title: 'Ops...',
          text: error.response.data?.message,
          children: (
            <Button
              orangeButton
              onClick={() => {
                Modal2.Close();
                history.goBack();
              }}
            >
              Voltar
            </Button>
          ),
        });
        setTextAuth(error.response.data.message);
        setDisabled(true);
      } else {
        Notiflix.Notify.failure('Não foi possivel acessar os dados da tela.');
      }
    } finally {
      Notiflix.Block.remove('.container-loading-notiflix');
    }
  }, [history, user]);

  const getBankOptions = useCallback(async () => {
    try {
      Notiflix.Block.circle('.form-loading-notiflix');

      const { data } = await api.get(
        `/financial/list-of-banks-available-for-debit-account-registration`,
      );

      if (data.content) {
        setBankOptions(() => {
          const newValues = data.content.reduce(
            (
              acc: { title: string; value: string }[],
              act: { bankName: string; bankCode: string },
            ) => {
              acc.push({
                title: act.bankName,
                value: act.bankCode,
              });
              return acc;
            },
            [],
          );
          return newValues;
        });
        setAllBanks([...data.content]);
      }
      Notiflix.Block.remove('.container-loading-notiflix');
    } catch (error) {
      Notiflix.Block.remove('.container-loading-notiflix');
      if (error.response?.err) {
        Notiflix.Notify.failure(error.response.err);
      } else {
        Notiflix.Notify.failure('Não foi possivel buscar pelos bancos.');
      }
    }
  }, []);

  const handleChangeBank = useCallback(
    (option) => {
      const agency = allBanks.find((row) => row.bankCode === option);
      if (agency) {
        formRef.current?.setFieldValue('bankBranch', agency.agencyNumber);
      }
    },
    [allBanks],
  );

  const getProductData = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-title-loading');

      const { data } = await api.get(
        'beneficiary/list-beneficiary-and-product',
      );
      const { content } = data;
      if (content && content[0]) {
        setProductData(content[0]);

        if (content[0].hiringType !== 'Individual/Familiar') {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: 'Funcionalidade disponível somente para beneficiário titular e do plano individual/familiar.',
            children: (
              <Button
                modal
                onClick={() => {
                  Modal2.Close();
                  history.push('/');
                }}
              >
                Ok
              </Button>
            ),
          });
        }
      }
    } catch (error) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar qual é o seu plano. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-title-loading');
    }
  }, [history]);

  useEffect(() => {
    getProductData();
    getTextAuth();
    getBankOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showDebtform = useMemo(() => {
    return user.isOwner && productData.hiringType === 'Individual/Familiar';
  }, [productData.hiringType, user.isOwner]);

  return (
    <PageWrapper
      gridTemplateColumns="repeat(10,1fr)"
      gridTemplateRows="auto 1fr"
    >
      <Container className="container-loading-notiflix">
        <Title className="notiflix-title-loading">
          Solicitar débito em conta
        </Title>
        {showDebtform && (
          <DebtAccountForm ref={formRef} onSubmit={handleDebtSubmit} formLook>
            <TextAuthorization
              className="form-loading-notiflix text-from-api"
              dangerouslySetInnerHTML={{ __html: textAuth }}
            />
            <Input
              formLook
              name="accountHolderName"
              title="Nome / Razão Social"
              widthContainerDesktop="50%"
              disabled={disabled}
            />
            <Input
              masks={['999.999.999-99', '99.999.999/9999-99']}
              formLook
              name="cpfCnpj"
              title="CPF/CNPJ"
              widthContainerDesktop="50%"
              disabled={disabled}
            />
            <Select
              formLook
              name="bankCode"
              ref={bankRef}
              title="Banco"
              options={bankOptions}
              changeCallback={handleChangeBank}
              className="bank-notiflix"
              widthContainerDesktop="40%"
              liveReload
              disabled={disabled}
            />
            <Select
              formLook
              options={dueDate}
              ref={dueDateRef}
              name="dueDate"
              title="Vencimento"
              widthContainerDesktop="20%"
              disabled={disabled}
            />
            <Input
              formLook
              name="bankBranch"
              title="Agência"
              widthContainerDesktop="20%"
              disabled={disabled}
              masks={['9999999999999']}
            />
            <Input
              formLook
              name="bankBranchDigit"
              title="DV da Agência"
              widthContainerDesktop="10%"
              disabled={disabled}
              masks={['99999']}
            />
            <Select
              formLook
              ref={accountRef}
              options={accountTypeOptions}
              name="accountType"
              title="Tipo da Conta"
              widthContainerDesktop="39%"
              disabled={disabled}
            />

            <Input
              formLook
              name="accountNumber"
              title="Número da Conta"
              widthContainerDesktop="40%"
              disabled={disabled}
              masks={['9999999999999999999']}
            />
            <Input
              formLook
              name="accountDigit"
              title="DV do Número da Conta"
              widthContainerDesktop="10%"
              disabled={disabled}
              masks={['9999']}
            />

            <Checkbox
              value="Autorizo a Unimed Blumenau enviar meu título somente por e-mail."
              name="authorizeSendingEmail"
              disabled={disabled}
            />
            {!disabled && (
              <Button formLook autoWidth greenPrimary type="submit">
                Solicitar Débito
              </Button>
            )}
          </DebtAccountForm>
        )}
      </Container>
    </PageWrapper>
  );
};

export default AccountDebit;
