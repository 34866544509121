import React, {
  useCallback,
  useImperativeHandle,
  useState,
  forwardRef,
} from 'react';

import ReactDOM from 'react-dom';
import { FailureSvg, SucessSvg } from '../Svg';
import {
  ModalOverlay,
  ModalWrapper,
  CloseButton,
  TitleParagraph,
  SubtitleParagraph,
  Paragraph,
  DivWrapper,
} from './styles';

interface ModalProps {
  closeble?: boolean;
  sucess?: boolean;
  failure?: boolean;
  title?: string;
  subtitle?: string;
  text?: string;
  fitContent?: boolean;
}
export interface ModalHandles {
  close: () => void;
  show: () => void;
  success: {
    title?: string;
    subtitle?: string;
    text?: string;
  };
}

const Moodal: React.ForwardRefRenderFunction<
  ModalHandles,
  React.PropsWithChildren<ModalProps>
> = (
  {
    children,
    failure = false,
    sucess = false,
    title,
    subtitle,
    text,
    closeble = true,
    fitContent = false,
  },
  ref,
): React.ReactPortal => {
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(
    {} as {
      title?: string;
      subtitle?: string;
      text?: string;
    },
  );

  const close = useCallback(() => {
    setVisible((prev) => !prev);
  }, []);

  const show = useCallback(() => {
    setVisible(true);
  }, []);

  useImperativeHandle(ref, () => {
    return {
      close,
      show,
      success,
    };
  });

  return ReactDOM.createPortal(
    <ModalOverlay visible={visible}>
      <ModalWrapper failure={failure} sucess={sucess} fitContent={fitContent}>
        {closeble && !failure && !sucess && (
          <CloseButton size={24} className="" onClick={close} />
        )}
        {sucess && (
          <DivWrapper>
            <SucessSvg />
          </DivWrapper>
        )}
        {failure && (
          <DivWrapper>
            <FailureSvg />
          </DivWrapper>
        )}
        {title && <TitleParagraph>{title}</TitleParagraph>}
        {subtitle && <SubtitleParagraph>{subtitle}</SubtitleParagraph>}
        {text && <Paragraph>{text}</Paragraph>}
        {children && <DivWrapper>{children}</DivWrapper>}
      </ModalWrapper>
    </ModalOverlay>,
    document.body,
  );
};

export default forwardRef(Moodal);
