/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
import React, {
  useState,
  useRef,
  useMemo,
  useLayoutEffect,
  useCallback,
  useEffect,
} from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Notiflix from 'notiflix';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Form } from '@unform/web';
import axios from 'axios';
import produce from 'immer';
import PageWrapper from '../../components/PageWrapper';
import {
  Container,
  Title,
  Steps,
  Step1,
  Step2,
  Step3,
  FormWrapper,
  ModalButtonsStep2,
  SubTitle,
  CheckboxGroup,
  AcceptTermsContainer,
  AcceptTermsContent,
  AcceptTermsFooter,
  Paragraph,
  ModalButtonContainer,
  ErrorRequiredRefundType,
} from './styles';
import Button from '../../components/Button';
import { BeneficiaryUser, UserCommon, useAuth } from '../../hooks/auth';
import Select, { SelectHandles } from '../../components/Select';
import api from '../../services/api/api';
import Input from '../../components/Input';
import getValidationErrors from '../../utils/getValidationErrors';
import { formatDate, ptBRToDate } from '../../utils/formatt';
import Modal2 from '../../components/Modal2';
import DatePicker from '../../components/DatePicker';
import { TitleIntern } from '../../components/Title/styles';
import Checkbox from '../../components/Checkbox';
import { RefoundTypeCheckboxList } from './__types__/refoundTypeCheckbox';
import RefundForm, { FormConsultList } from './__components__/Form';
import { Error } from '../../components/Input/styles';

interface DateProps {
  value: Date | null;
  error: string;
}

const Refund: React.FC = () => {
  const { user: userT } = useAuth();
  const user = userT as UserCommon & BeneficiaryUser;
  const history = useHistory();
  const { colors } = useTheme();
  const [actualStep, setActualStep] = useState(1);
  const step1Ref = useRef<HTMLDivElement>(null);
  const step2Ref = useRef<HTMLDivElement>(null);
  const step3Ref = useRef<HTMLDivElement>(null);
  const actualFormRef = useRef<FormHandles>(null);
  const formRefStep1 = useRef<FormHandles>(null);
  const formRefStep2 = useRef<FormHandles>(null);
  const formRefStep3 = useRef<FormConsultList>(null);
  const currentStepRef = useRef('1');
  const reasonFormRef = useRef<FormHandles>(null);
  const bankRef = useRef<SelectHandles>(null);
  const [actualBeneficiarySelected, setActualBeneficiarySelected] =
    useState<any>();
  const [reason, setReason] = useState<any>('');
  const [termToTermination, setTermToTermination] = useState<any>();
  const [newRequestProtocolNumber, setNewRequestProtocolNumber] =
    useState<any>();
  const [stateRefoundTypeCheckboxList, setRefoundTypeCheckboxList] =
    useState<RefoundTypeCheckboxList>([]);
  const [stateRefoundTypeCheckboxListValue, setRefoundTypeCheckboxListValue] =
    useState<number | null>(null);
  const [birthDate, setBirthDate] = useState<DateProps>({
    value: null,
    error: '',
  });

  const [errorRequiredRefundType, setErrorRequiredRefundType] = useState('');
  const [bankOptions, setBankOptions] = useState([{ title: '', value: '' }]);
  const [heightStep, setHeightStep] = useState(['']);
  const [documentTypeList, setDocumetTypeList] = useState<Array<unknown>>([]);
  const [documentAttachmentsList, setDocumentAttachmentsList] = useState<
    Array<unknown>
  >([]);
  const defineHeightOfSteps = useMemo(() => {
    switch (actualStep) {
      case 1:
        return heightStep[0];
      case 2:
        return heightStep[1];
      case 3:
        return heightStep[2];
      default:
        return '0px';
    }
  }, [actualStep, heightStep]);
  const [beneficiariesOptions, setBeneficiariesParents] = useState(() => {
    const options = [
      {
        title: user.name,
        value: `${user.id}`,
      },
    ];

    return { items: options, all: [user] as any };
  });

  const requestGetTerms = async () => {
    try {
      const { data } = await api.get(`/refund/show-refund-message`);

      const { content } = data;

      if (content && content[0]) {
        setTermToTermination(content[0]);
      }
    } catch (error) {
      //
    }
  };

  const requestDocumentsList = async () => {
    try {
      const { data } = await api.get(`/refund/document-type`);

      const { content } = data;

      if (content) {
        const formatToSelectOption = content.map((item: any) => ({
          title: item.documentName,
          value: item.documentId,
        }));

        const defaultOption = {
          title: 'Selecione o tipo do documento',
          value: '',
        };

        setDocumetTypeList([defaultOption, ...formatToSelectOption]);
      }
    } catch (error) {
      //
    }
  };

  const requestDocumentTypeList = async () => {
    try {
      const optionSelected = stateRefoundTypeCheckboxList.filter(
        (item) => item.checked,
      );
      if (optionSelected.length <= 0) {
        return;
      }

      const { data } = await api.get(
        `/refund/refund-attachment?refundType=${optionSelected[0].value}`,
      );

      const { content } = data;

      if (content) {
        const formatToSelectOption = content.map((item: any) => ({
          name: item.name,
          required: item.required,
        }));

        setDocumentAttachmentsList([...formatToSelectOption]);
      }
    } catch (error) {
      //
    }
  };

  const requestRefundTypes = async () => {
    try {
      const { data } = await api.get(`/refund/refund-type`);

      const { content } = data;

      if (content) {
        const refundTypeOptions = content.map((item: any) => ({
          title: item.refundType,
          value: item.id,
          defaultChecked: false,
          checked: false,
          name: item.refundType,
        }));
        setRefoundTypeCheckboxList(refundTypeOptions);
        setRefoundTypeCheckboxListValue(null);
      }
    } catch (error) {
      //
    }
  };

  const requestSendRefund = async (payload: any) => {
    try {
      const response = await api.post(`/refund/request-refund`, payload);

      const { content } = response.data;
      return content;
    } catch (error) {
      return {
        result: 0,
      };
    }
  };

  const requestCompressAttachments = async (attachments: Array<any>) => {
    if (!attachments || attachments.length <= 0) {
      return { base64: '' };
    }

    try {
      const formData = new FormData();

      attachments.forEach((item, index) => {
        formData.append(`file${index + 1}`, item[0]);
      });

      const response = await api.post(`/refund/compress-zip`, formData);

      const { content } = response.data;
      return content;
    } catch (error) {
      return {
        base64: '',
      };
    }
  };

  const handleChangeActualBeneficiary = useCallback(async (option) => {
    try {
      const { data } = await api.get(
        `/beneficiary/list-beneficiary-and-product?userUnimedId=${option}`,
      );

      const { content } = data;
      if (content && content[0]) {
        setActualBeneficiarySelected(content[0]);
        actualFormRef.current?.setFieldValue(
          'name',
          content[0]?.beneficiaryName,
        );
        formRefStep2.current?.setFieldValue(
          'name',
          content[0]?.beneficiaryName,
        );
        actualFormRef.current?.setFieldValue(
          'cardNumber',
          content[0]?.cardNumber,
        );
        try {
          setBirthDate({ value: ptBRToDate(content[0]?.birthDate), error: '' });
        } catch (e) {
          console.log(e);
        }
      }
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os dados do beneficiário selecionado no servidor. Por favor, tente novamente mais tarde.',
      );
    }
  }, []);

  const getDataForInitialDataOnStep2 = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-step2');
      const phisical = beneficiariesOptions.all?.find(
        (i: any) => i.userUnimedId === actualBeneficiarySelected?.userUnimedId,
      );
      try {
        const { data } = await api.get(
          `/beneficiary/list-of-bank-data?phisicalPersonCode=${phisical.phisicalPersonCode}`,
        );
        const { content } = data;
        if (content && content[0]) {
          bankRef.current?.setValue(content[0].bankName, '');
          // formRefStep2.current?.setFieldValue(
          //   'bankCode',
          //   content[0].bankName ?? '',
          // );
          formRefStep2.current?.setFieldValue(
            'agencyCode',
            content[0].bankAgencyCode ?? '',
          );
          formRefStep2.current?.setFieldValue(
            'digitNumberAgency',
            content[0].digitAgency ?? '',
          );
          formRefStep2.current?.setFieldValue(
            'accountNumber',
            content[0].accountNumber ?? '',
          );
          formRefStep2.current?.setFieldValue(
            'digitNumberAccount',
            content[0].digitAccount ?? '',
          );
        }
      } catch (e: unknown) {
        bankRef.current?.setValue('', '');
        formRefStep2.current?.setFieldValue('agencyCode', '');
        formRefStep2.current?.setFieldValue('digitNumberAgency', '');
        formRefStep2.current?.setFieldValue('accountNumber', '');
        formRefStep2.current?.setFieldValue('digitNumberAccount', '');
        if (axios.isAxiosError(e) && e.response?.status !== 400) {
          Notiflix.Notify.failure(
            'Ops, não conseguimos buscar seus dados bancários. Por favor, tente novamente mais tarde.',
          );
        }
      }
      try {
        const { data } = await api.get(
          `/beneficiary/data-to-change-fields?accessType=B&physicalPersonalCode=${phisical.phisicalPersonCode}&serviceProtocolNumber=${user.serviceProtocolNumber}`,
        );
        const { content } = data;
        formRefStep2.current?.setFieldValue('cpf', content.cpf.value);
      } catch (e) {
        if (axios.isAxiosError(e) && e?.response?.status !== 400) {
          Notiflix.Notify.failure(
            'Ops, não conseguimos buscar seu CPF. Por favor, tente novamente mais tarde.',
          );
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar alguns dados no servido... Por favor, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-step2');
    }
  }, [actualBeneficiarySelected, beneficiariesOptions, user]);

  const getParents = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-parents');
      const { data } = await api.get(`/contract/list-holder-and-dependents`);
      const { content } = data;

      if (content && content[0]) {
        setBeneficiariesParents(() => {
          const newValues = content.reduce(
            (
              acc: { title: string; value: string }[],
              act: { insuredName: string; userUnimedId: string },
            ) => {
              acc.push({
                title: act.insuredName,
                value: act.userUnimedId,
              });
              return acc;
            },
            [],
          );
          return { items: newValues, all: content };
        });
        handleChangeActualBeneficiary(content[0].userUnimedId);
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err?.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dependentes. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-parents');
    }
  }, [handleChangeActualBeneficiary]);

  const getAllBanks = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-bank-options');
      const { data } = await api.get('/generic/list-banks-active');
      const { content } = data;
      setBankOptions([
        {
          title: '',
          value: '',
        },
        ...content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      ]);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar a lista de bancos disponíveis no servidor. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-bank-options');
    }
  }, []);

  const handleClickAcceptedTerms = useCallback(() => {
    Modal2.Close();
  }, []);

  const openModalToAcceptTerms = useCallback(
    (callback) => {
      Modal2.Generic({
        closable: false,

        children: (
          <AcceptTermsContainer>
            <AcceptTermsContent
              dangerouslySetInnerHTML={{
                __html: termToTermination?.RefundMessage,
              }}
            />
            <AcceptTermsFooter>
              <SubTitle style={{ textAlign: 'center' }}>
                Deseja prosseguir?
              </SubTitle>
              <Form
                onSubmit={callback}
                style={{ justifyContent: 'center', marginTop: 32, gap: 16 }}
              >
                <Button
                  outlined
                  autoWidth
                  modal
                  onClick={() => {
                    Modal2.Close();
                    history.push('/');
                  }}
                >
                  Não
                </Button>
                <Button type="submit" autoWidth modal orangeButton>
                  SIM
                </Button>
              </Form>
            </AcceptTermsFooter>
          </AcceptTermsContainer>
        ),
      });
    },
    [termToTermination, history],
  );

  const openModalReason = (callback: any) => {
    Modal2.Generic({
      autoWidth: true,
      closable: true,
      children: (
        <AcceptTermsContainer>
          <AcceptTermsFooter>
            <SubTitle>
              Detalhamento do ocorrido. Por qual motivo o atendimento ocorreu de
              forma particular?{' '}
            </SubTitle>
            <Form
              ref={reasonFormRef}
              onSubmit={callback}
              style={{
                justifyContent: 'center',
                marginTop: 16,
                gap: 16,
                flexDirection: 'column',
              }}
            >
              <Input
                name="reason"
                type="textarea"
                // title="Detalhamento do ocorrido:"
                maxLength={1000}
                minLength={20}
                widthContainerDesktop="100%"
                width="100%"
              />
              <Button
                type="submit"
                autoWidth
                modal
                orangeButton
                id="acceptedTerms"
              >
                Confirmar
              </Button>
            </Form>
          </AcceptTermsFooter>
        </AcceptTermsContainer>
      ),
    });
  };

  const openModalAlert = (callback: any) => {
    Modal2.Warning({
      closable: true,
      subtitle: 'Atenção!',
      text: 'Certifique-se dos dados bancários inseridos.',
      children: (
        <div style={{ paddingTop: -40 }}>
          <Paragraph>
            Se o beneficiário que teve atendimento é menor de idade deve ser
            preenchido com os dados bancários do responsável que assinou
            contrato com a Unimed.
          </Paragraph>

          <Paragraph style={{ paddingBlock: 40 }}>Deseja prosseguir?</Paragraph>

          <ModalButtonsStep2>
            <Button modal outlined onClick={() => Modal2.Close()}>
              Não
            </Button>
            <Button modal onClick={callback}>
              Sim
            </Button>
          </ModalButtonsStep2>
        </div>
      ),
    });
  };

  const validateStepFields = async (step: string) => {
    switch (step) {
      case '1':
        try {
          formRefStep1.current?.setErrors({});
          const dataStep1 = formRefStep1.current?.getData() || {};
          const schema = Yup.object().shape({
            phone: Yup.string()
              .required('Informe o seu telefone')
              .min(10, 'Informe com um telefone válido'),
            email: Yup.string()
              .email('Informe um email válido')
              .required('Informe um email'),
          });

          await schema.validate(
            { ...dataStep1 },
            {
              abortEarly: false,
            },
          );

          const isValidRefundType =
            stateRefoundTypeCheckboxList.filter((item) => item.checked).length >
            0;

          if (!isValidRefundType) {
            setErrorRequiredRefundType('Informe o tipo de reembolso');

            return false;
          }

          return true;
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErrors(err);
            formRefStep1.current?.setErrors(errors);

            return false;
          }

          return false;
        }

      case '2':
        try {
          reasonFormRef.current?.setErrors({});
          const dataStep1 = reasonFormRef.current?.getData() || {};
          const schema = Yup.object().shape({
            reason: Yup.string()
              .required('Informe o detalhamento do ocorrido')
              .min(20, 'Deve conter pelo menos 20 caracteres')
              .max(1000, 'Máximo de 1000 caracteres'),
          });

          await schema.validate(
            { ...dataStep1 },
            {
              abortEarly: false,
            },
          );

          return true;
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErrors(err);
            reasonFormRef.current?.setErrors(errors);

            return false;
          }

          return false;
        }

      case '3':
        try {
          formRefStep2.current?.setErrors({});

          const schema = Yup.object().shape({
            name: Yup.string().required('Informe seu nome completo.'),
            cpf: Yup.string().required('Informe seu CPF'),
            // bankCode: Yup.string().required('Informe seu Banco'),
            agencyCode: Yup.string()
              .required('Informe sua agência')
              .max(8, 'Máximo de 8 caracteres.'),
            // digitNumberAgency: Yup.string()
            //   .required('Informe o dig. verificador da sua agência')
            //   .max(1, 'Informe somente um digito'),
            accountNumber: Yup.string()
              .required('informe sua conta')
              .max(12, 'Máximo de 12 caracteres.'),
            digitNumberAccount: Yup.string()
              .required('Informe o díg. verificador da conta')
              .max(1, 'Informe somente um digito'),
          });

          await schema.validate(formRefStep2.current?.getData(), {
            abortEarly: false,
          });

          if (!birthDate.value) {
            setBirthDate((prev) => ({
              ...prev,
              error: 'Informe a data de nascimento',
            }));
            return false;
          }
          if (!bankRef.current?.value) {
            bankRef.current?.setError('Selecione seu banco');
            return false;
          }

          return true;
        } catch (err) {
          console.log(err);
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErrors(err);
            formRefStep2.current?.setErrors(errors);

            return false;
          }
          return false;
        }
      default:
        return false;
    }
  };

  const resetAllForms = () => {
    setRefoundTypeCheckboxList((prev) =>
      produce(prev, (draft) => {
        draft.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.checked = false;
        });
      }),
    );
    setRefoundTypeCheckboxListValue(null);

    formRefStep1.current?.reset();
    formRefStep3.current?.reset();
  };

  const formatPayload = async (data: any) => {
    const bankFormData = formRefStep2.current?.getData();
    const userFormData = actualFormRef.current?.getData();
    const contactForm = formRefStep1.current?.getData();
    const isValidRefundType = stateRefoundTypeCheckboxList.filter(
      (item) => item.checked,
    );

    const formattedBody = {
      userUnimedId: actualBeneficiarySelected?.userUnimedId,
      cardNumber: userFormData?.cardNumber,
      protocolNumber: data.protocolNumber,
      refundType: isValidRefundType[0].value,
      serviceDate: data.serviceDate.toLocaleDateString('pt-BR'),
      serviceValue: String(data.careValue),
      reason: reason.reason,
      name: userFormData?.name,
      birthDate: birthDate.value
        ? birthDate.value.toLocaleDateString('pt-BR')
        : new Date(1970, 1, 1).toLocaleDateString('pt-BR'),
      cpf: bankFormData?.cpf,
      bankCode: String(bankRef.current?.value),
      agencyCode: bankFormData?.agencyCode,
      digitNumberAgency: bankFormData?.digitNumberAgency,
      accountNumber: bankFormData?.accountNumber,
      digitNumberAccount: bankFormData?.digitNumberAccount,
      email: contactForm?.email,
      phone: contactForm?.phone,
      notes: await Promise.all(
        data.notes.map(async (item: any) => {
          const attachmentsZipBase64 = await requestCompressAttachments(
            item.attachments,
          );

          return {
            documentType: item.documentType,
            documentDate: item.documentDate.toLocaleDateString('pt-BR'),
            documentLocale: item.documentLocale,
            documentNumber: item.documentNumber,
            attachments: attachmentsZipBase64.base64,
          };
        }),
      ),
    };

    return formattedBody;
  };

  const handleSubmit = async () => {
    try {
      const payload = await formRefStep3.current?.formSubmit();

      if (!payload) return;

      Notiflix.Block.circle('.notiflix-submit-refund');

      const formattedPayload = await formatPayload(payload);

      const response = await requestSendRefund(formattedPayload);

      Notiflix.Block.remove('.notiflix-submit-refund');

      if (response.result === 0) {
        Modal2.Failure({
          closable: true,
          title: 'Ops..',
          text: response.message,
          children: (
            <ModalButtonContainer>
              <Button
                onClick={() => {
                  Modal2.Close();
                }}
                modal
              >
                Editar solicitação
              </Button>
              <Button
                outlined
                onClick={() => {
                  Modal2.Close();
                  history.push('/');
                }}
                modal
              >
                Cancelar solicitação
              </Button>
            </ModalButtonContainer>
          ),
        });

        return;
      }

      const protocolNumber = response.data.protocolo;

      Modal2.Success({
        title: 'Sucesso!',
        text: response.message,
        children: (
          <ModalButtonContainer>
            <Button
              onClick={() => {
                setActualStep(1);
                Modal2.Close();
                resetAllForms();
                setNewRequestProtocolNumber(protocolNumber);
              }}
              modal
            >
              Registrar nova solicitação
            </Button>
            <div style={{}} />
            <Button
              outlined
              onClick={() => {
                Modal2.Close();
                history.push('/');
              }}
              modal
            >
              Fechar
            </Button>
          </ModalButtonContainer>
        ),
      });
    } catch (error) {
      console.log(error);
      Notiflix.Block.remove('.notiflix-submit-refund');
      Modal2.Failure({
        closable: true,
        title: 'Erro!',
        text: 'Ocorreu algum erro, tente novamente',
        children: (
          <>
            <Button
              onClick={() => {
                Modal2.Close();
              }}
              modal
            >
              Ok
            </Button>
          </>
        ),
      });
    }
  };

  const handleNextStep = (currentStep: string) => {
    let text = {};
    switch (currentStep) {
      case '1':
        currentStepRef.current = '2';
        openModalReason(() => handleConfirmSecondStep());
        break;

      case '2':
        if (reasonFormRef.current) {
          text = reasonFormRef.current?.getData();
        }
        setReason(text);
        currentStepRef.current = '3';
        Modal2.Close();
        setActualStep(2);
        break;

      case '3':
        currentStepRef.current = '4';

        openModalAlert(() => {
          setActualStep(3);
          Modal2.Close();
        });
        break;

      case '4':
        currentStepRef.current = '5';
        break;
      default:
        break;
    }
  };

  const handleConfirmFirstStep = async () => {
    currentStepRef.current = '1';

    if (await validateStepFields(currentStepRef.current)) {
      handleNextStep(currentStepRef.current);
    }
  };

  const handleConfirmSecondStep = async () => {
    currentStepRef.current = '2';

    if (await validateStepFields(currentStepRef.current)) {
      handleNextStep(currentStepRef.current);
    }
  };

  const handleConfirmThirdStep = async () => {
    currentStepRef.current = '3';

    if (await validateStepFields(currentStepRef.current)) {
      handleNextStep(currentStepRef.current);
    }
  };

  const checkSingleOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.currentTarget.value) ?? -1;
    setErrorRequiredRefundType('');
    setRefoundTypeCheckboxList((prev) =>
      produce(prev, (draft) => {
        draft.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.checked = Number(item.value) === value;
        });
      }),
    );
    setRefoundTypeCheckboxListValue(value);
  };

  const goBackLastForm = () => {
    formRefStep3.current?.resetNotes();
    setActualStep(2);
  };

  useEffect(() => {
    requestGetTerms();
    requestRefundTypes();
    requestDocumentsList();

    getAllBanks().finally(() => {
      getParents();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    requestDocumentTypeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRefoundTypeCheckboxList]);

  useEffect(() => {
    if (actualBeneficiarySelected?.userUnimedId) {
      getDataForInitialDataOnStep2();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualBeneficiarySelected]);

  useLayoutEffect(() => {
    setHeightStep([
      `${step1Ref.current?.scrollHeight}px`,
      `${step2Ref.current?.scrollHeight}px`,
      `${step3Ref.current?.scrollHeight}px`,
    ]);
  }, []);

  useEffect(() => {
    if (termToTermination) {
      openModalToAcceptTerms(handleClickAcceptedTerms);
    }
  }, [termToTermination, handleClickAcceptedTerms, openModalToAcceptTerms]);

  const renderFormByRefoundType = useMemo(() => {
    if (!stateRefoundTypeCheckboxListValue) {
      return null;
    }

    switch (stateRefoundTypeCheckboxListValue) {
      case 1:
        // eslint-disable-next-line consistent-return
        return (
          <RefundForm
            ref={formRefStep3}
            documentTypeList={documentTypeList}
            documentAttachmentsList={documentAttachmentsList}
            newRequestProtocolNumber={newRequestProtocolNumber}
            type={String(stateRefoundTypeCheckboxListValue)}
            formTitle="Exames"
          />
        );

      case 2:
        // eslint-disable-next-line consistent-return
        return (
          <RefundForm
            ref={formRefStep3}
            documentTypeList={documentTypeList}
            documentAttachmentsList={documentAttachmentsList}
            newRequestProtocolNumber={newRequestProtocolNumber}
            type={String(stateRefoundTypeCheckboxListValue)}
            formTitle="Atendimento com profissional não-médico (Fonoaudiologo, Psicologo, Nutricionista, Terapeuta Ocupacional, Fisioterapeuta)"
          />
        );

      case 3:
        // eslint-disable-next-line consistent-return
        return (
          <RefundForm
            ref={formRefStep3}
            documentTypeList={documentTypeList}
            documentAttachmentsList={documentAttachmentsList}
            newRequestProtocolNumber={newRequestProtocolNumber}
            type={String(stateRefoundTypeCheckboxListValue)}
            formTitle="Internações ou despesas ambulatoriais/cirúrgicas"
          />
        );

      case 4:
        // eslint-disable-next-line consistent-return
        return (
          <RefundForm
            ref={formRefStep3}
            documentTypeList={documentTypeList}
            documentAttachmentsList={documentAttachmentsList}
            newRequestProtocolNumber={newRequestProtocolNumber}
            type={String(stateRefoundTypeCheckboxListValue)}
            formTitle="Honorário Médico"
          />
        );

      case 5:
        // eslint-disable-next-line consistent-return
        return (
          <RefundForm
            ref={formRefStep3}
            documentTypeList={documentTypeList}
            documentAttachmentsList={documentAttachmentsList}
            newRequestProtocolNumber={newRequestProtocolNumber}
            type={String(stateRefoundTypeCheckboxListValue)}
            formTitle="Remoção por ambulância"
          />
        );

      case 6:
        // eslint-disable-next-line consistent-return
        return (
          <RefundForm
            ref={formRefStep3}
            documentTypeList={documentTypeList}
            documentAttachmentsList={documentAttachmentsList}
            newRequestProtocolNumber={newRequestProtocolNumber}
            type={String(stateRefoundTypeCheckboxListValue)}
            formTitle="Consulta Médica"
          />
        );

      default:
        // eslint-disable-next-line consistent-return
        return false;
    }
  }, [
    documentAttachmentsList,
    documentTypeList,
    newRequestProtocolNumber,
    stateRefoundTypeCheckboxListValue,
  ]);

  return (
    <PageWrapper
      gridTemplateColumns="repeat(10,1fr)"
      gridTemplateRows="auto"
      scrollable
    >
      <Container>
        <Title>Solicitar Reembolso</Title>
        <Steps defineHeight={defineHeightOfSteps}>
          <Step1 show={actualStep === 1} ref={step1Ref}>
            <FormWrapper onSubmit={() => console.log('N/A')} formLook>
              <Select
                title="Beneficiário:"
                formLook
                themeColor={colors.palet.institutional11}
                name="typeuser"
                changeCallback={handleChangeActualBeneficiary}
                options={beneficiariesOptions.items}
                liveReload
                className="notiflix-parents"
              />
            </FormWrapper>
            <FormWrapper
              onSubmit={() => console.log('N/A')}
              ref={actualFormRef}
            >
              <Input
                name="name"
                disabled
                insideInputTitle="Nome Completo"
                widthContainerDesktop="50%"
                themeColor={colors.palet.institutional6}
              />
              <Input
                name="cardNumber"
                themeColor={colors.palet.institutional6}
                insideInputTitle="Carteira"
                disabled
                widthContainerDesktop="50%"
              />
            </FormWrapper>

            <FormWrapper
              ref={formRefStep1}
              onSubmit={() => console.log('N/A')}
              formLook
            >
              <Input
                formLook
                name="phone"
                themeColor={colors.palet.institutional11}
                title="Telefone de contato:"
                widthContainerDesktop="30%"
                style={{ textAlign: 'right' }}
                masks={[
                  '(99) 9999-9999',
                  '(99) 9 9999-9999',
                  '(+99) 99 9 9999-9999',
                ]}
              />
              <Input
                formLook
                name="email"
                themeColor={colors.palet.institutional11}
                title="E-mail:"
                widthContainerDesktop="70%"
              />
            </FormWrapper>

            <FormWrapper
              onSubmit={handleConfirmFirstStep}
              formLook
              style={{ rowGap: 12, padding: 16, flexDirection: 'column' }}
            >
              <h2>Selecione o tipo de reembolso abaixo:</h2>
              {!!errorRequiredRefundType && (
                <ErrorRequiredRefundType>
                  <Error />
                  {errorRequiredRefundType}
                </ErrorRequiredRefundType>
              )}

              <CheckboxGroup>
                {stateRefoundTypeCheckboxList.map((item) => (
                  <Checkbox
                    value={item.value}
                    defaultChecked={item.defaultChecked}
                    name={item.name}
                    title={item.title}
                    checked={item.checked}
                    onChange={checkSingleOption}
                    themeColor={colors.palet.institutional8}
                  />
                ))}
              </CheckboxGroup>
              <Button orangeButton formLook autoWidth type="submit">
                Próximo
              </Button>
            </FormWrapper>
          </Step1>
          <Step2 show={actualStep === 2} ref={step2Ref}>
            <Container>
              <TitleIntern themeColor={colors.palet.institutional6}>
                Dados bancários
              </TitleIntern>
              <FormWrapper
                ref={formRefStep2}
                onSubmit={handleConfirmThirdStep}
                formLook
                className="notiflix-step2"
              >
                <Input
                  formLook
                  name="name"
                  title="Nome Completo:"
                  widthContainerDesktop="70%"
                />
                <DatePicker
                  name="birthDate"
                  formLook
                  title="Data de de nascimento:"
                  widthContainerDesktop="30%"
                  icon
                  selected={birthDate.value}
                  error={birthDate.error}
                  onChange={(date: Date) => {
                    setBirthDate({ value: date, error: '' });
                  }}
                />
                <Input
                  formLook
                  name="cpf"
                  title="CPF:"
                  widthContainerDesktop="50%"
                  masks={['999.999.999-99']}
                />

                <Select
                  formLook
                  name="bankCode"
                  title="Banco:"
                  widthContainerDesktop="50%"
                  className="notiflix-bank-options"
                  liveReload
                  options={bankOptions}
                  ref={bankRef}
                  inputMode
                />
                <Input
                  formLook
                  name="agencyCode"
                  title="Agência:"
                  widthContainerDesktop="40%"
                  masks={['999999999999']}
                />
                <Input
                  formLook
                  name="digitNumberAgency"
                  title="Dig. Agência:"
                  widthContainerDesktop="10%"
                  masks={['9']}
                />
                <Input
                  formLook
                  name="accountNumber"
                  title="Conta:"
                  widthContainerDesktop="40%"
                  masks={['999999999999']}
                />
                <Input
                  formLook
                  name="digitNumberAccount"
                  title="Dig. Conta:"
                  widthContainerDesktop="10%"
                  masks={['9999']}
                />

                <Button
                  secondary
                  formLook
                  autoWidth
                  onClick={() => setActualStep(1)}
                >
                  Voltar
                </Button>
                <Button orangeButton formLook autoWidth type="submit">
                  Próximo
                </Button>
              </FormWrapper>
            </Container>
          </Step2>
          <Step3 show={actualStep === 3} ref={step3Ref}>
            {renderFormByRefoundType}
            <FormWrapper
              onSubmit={() => handleSubmit()}
              style={{ marginBottom: 20 }}
            >
              <Button
                secondary
                formLook
                autoWidth
                onClick={() => goBackLastForm()}
              >
                Voltar
              </Button>
              <Button
                orangeButton
                formLook
                autoWidth
                type="submit"
                className="notiflix-submit-refund"
              >
                Próximo
              </Button>
            </FormWrapper>
          </Step3>
        </Steps>
      </Container>
    </PageWrapper>
  );
};

export default Refund;
