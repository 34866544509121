/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import Notiflix from 'notiflix';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';
import { useTheme } from 'styled-components';
import ClipboardJS from 'clipboard';
import axios from 'axios';
import Button from '../../../components/Button';
import Modal2 from '../../../components/Modal2';

import PageWrapper from '../../../components/PageWrapper';
import Table, { Column } from '../../../components/Table';
import api from '../../../services/api/api';
import {
  AddField as addField,
  getTag,
  setPositionOfRowExpanded,
} from '../../../utils/tableUtils';

import {
  Container,
  Title,
  FilterContainer,
  TableContainer,
  ModalObservation,
  ModalObservationTitle,
  ModalObservationContent,
  ModalDetailValue,
  Steps,
  ModalWrapper,
  Step1,
  Step2,
} from './styles';
import Input from '../../../components/Input';
import { downloadFileOfBlob } from '../../../utils/geterateFileURL';
import { BeneficiaryUser, UserCommon, useAuth } from '../../../hooks/auth';
import FormWrapper from '../../../components/FormWrapper';
import { ButtonsActionsContainer } from '../../../components/Table/styles';
import { FormWrapper2 } from '../Company/styles';

interface TableProps {
  expirationDate: string;
  ieOpen: string;
  liquidationDate: string;
  payerName: string;
  payerNumber: string;
  payerType: string;
  referenceDate: string;
  titleNumber: string;
  totalCoparcenaryValue: string;
  totalTuitionValue: string;
  tuitionNumber: string;
}
interface ProductProps {
  beneficiaryName: string;
  cardNumber: number;
  hiringDate: string;
  hiringType: string;
  productName: string;
}

const BeneficiaryDemonstrativeAndDuplicate: React.FC = () => {
  const { user: userT } = useAuth();
  const user = userT as UserCommon & BeneficiaryUser;
  const { colors } = useTheme();
  const clipboard = new ClipboardJS('.btn2');
  const step1Ref = useRef<HTMLDivElement>(null);
  const step2Ref = useRef<HTMLDivElement>(null);
  const [actualStep, setActualStep] = useState(1);
  const [heightStep, setHeightStep] = useState(['']);
  const [productData, setProductData] = useState<ProductProps>();
  const [tableData, setTableData] = useState<TableProps[]>([]);
  const [tableDetail, setTableDetail] = useState([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [expandedRows, setExpandedRows] = useState([] as any[]);

  const defineHeightOfSteps = useMemo(() => {
    switch (actualStep) {
      case 1:
        return heightStep[0];
      case 2:
        return heightStep[1];
      default:
        return '0px';
    }
  }, [actualStep, heightStep]);

  const calculateHeightOfSteps = useCallback(() => {
    setHeightStep([`auto`, `${step2Ref.current?.scrollHeight}px`]);
  }, []);

  const handleOpenDetail = useCallback(async (data, notiflix) => {
    const { tuitionNumber } = data;
    try {
      Notiflix.Block.circle(notiflix);
      const { data: dataApi } = await api.get(
        `/financial/beneficiary-tuition-details?tuitionNumber=${tuitionNumber}`,
      );
      const { content } = dataApi;
      setTableDetail(content);
    } catch (error) {
      if (error?.response?.status === 400) {
        Notiflix.Notify.info('Não há detalhes para este demonstrativo.');
        return;
      }
      if (error?.response?.status !== 400) {
        Notiflix.Notify.info(error.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Não conseguimos buscar pelos detalhes. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove(notiflix);
    }
    setExpandedRows([]);
    setActualStep(2);
  }, []);

  const handleOpenObservation = useCallback(async (data, notiflix) => {
    const { tuitionNumber } = data;
    try {
      Notiflix.Block.circle(notiflix);
      const { data: dataApi } = await api.get(
        `/financial/beneficiary-tuition-observation?tuitionNumber=${tuitionNumber}`,
      );
      const { content } = dataApi;
      Modal2.Generic({
        closable: false,
        autoWidth: true,
        children: (
          <ModalObservation>
            <ModalWrapper>
              {content[0].dischargeMessage && (
                <>
                  <ModalObservationTitle>
                    Mensagem quitação anual de débitos
                  </ModalObservationTitle>
                  <ModalObservationContent
                    dangerouslySetInnerHTML={{
                      __html: content[0].dischargeMessage,
                    }}
                  />
                </>
              )}
              {content[0].observation && (
                <>
                  <ModalObservationTitle>Observações</ModalObservationTitle>
                  <ModalObservationContent
                    dangerouslySetInnerHTML={{
                      __html: content[0].observation,
                    }}
                  />
                </>
              )}
              <Button
                outlined
                modal
                autoWidth
                float="right"
                onClick={(e) => {
                  e.stopPropagation();
                  Modal2.Close();
                }}
              >
                Ok
              </Button>
            </ModalWrapper>
          </ModalObservation>
        ),
      });
    } catch (error) {
      if (error?.response?.status === 400) {
        Notiflix.Notify.info(error?.response?.data?.message);
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar as observações. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove(notiflix);
    }
  }, []);

  const copyToClipboard = useCallback(
    async (rowData, notiflix) => {
      const { titleNumber } = rowData;
      try {
        Notiflix.Block.circle(notiflix);
        const { data } = await api.get(
          `financial/beneficiary-invoice-barcode?titleNumber=${titleNumber}`,
        );
        Modal2.Generic({
          closable: true,
          children: (
            <ModalWrapper>
              <FormWrapper2
                formLook
                onSubmit={() => {
                  try {
                    Notiflix.Block.circle('.btn2');
                    clipboard
                      .on('success', () => {
                        Notiflix.Notify.success('Copiado com Sucesso!');
                        Modal2.Close();
                      })
                      .on('error', () => {
                        Notiflix.Notify.failure(
                          'Não foi possível copiar o seu código de barras... Tente novamente mais tarde',
                        );
                      });
                  } catch (error) {
                    Notiflix.Notify.failure(
                      'Não foi possível copiar o seu código de barras... Tente novamente mais tarde',
                    );
                  } finally {
                    Notiflix.Block.circle('.btn2');
                    Notiflix.Block.remove('.btn2');
                  }
                }}
              >
                <Input
                  id="bar2"
                  name="barcodeNumber"
                  formLook
                  title="Código de Barras"
                  value={data.content[0].barcodeNumber}
                  autoFocus
                />

                <Button
                  type="submit"
                  className="btn2"
                  data-clipboard-action="cut"
                  data-clipboard-target="#bar2"
                >
                  Copiar
                </Button>
              </FormWrapper2>
            </ModalWrapper>
          ),
        });
      } catch (err) {
        if (err.response?.data?.message) {
          Notiflix.Notify.failure(err.response.data.message);
        } else if (err.message) {
          Notiflix.Notify.failure(err.message);
        }
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [clipboard],
  );

  const handleSendOnEmail = useCallback(
    async (data, notiflix) => {
      const { titleNumber } = data;
      try {
        Notiflix.Block.circle(notiflix);
        const { data: dataApi } = await api.get(
          `/financial/send-second-copy-invoice-by-email?invoiceId=${titleNumber}&serviceProtocolNumber=${user.serviceProtocolNumber}`,
        );
        Modal2.Success({
          autoWidth: true,
          closable: true,
          title: 'Sucesso!',
          children: (
            <>
              <ModalDetailValue>{dataApi.message}</ModalDetailValue>
              <Button
                style={{ marginTop: '8px' }}
                modal
                onClick={() => Modal2.Close()}
              >
                Fechar
              </Button>
            </>
          ),
        });
      } catch (err) {
        if (err?.response?.status === 400) {
          Notiflix.Notify.info(err.response.data?.message);
        } else {
          Notiflix.Notify.failure(
            'Opss... Algo deu errado, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [user],
  );

  const handleRequestReport = useCallback(
    async (row: any, notiflix) => {
      const { titleNumber } = row;
      try {
        Notiflix.Block.circle(notiflix);
        const { data } = await api.get(
          `/report/list-of-beneficiary-reports?contractId=${user.id}&exhibitionLocation=7`,
        );
        const { nrSeqRelatorio, Parametros } = data.content[0];
        const body = {
          nrSeqRelatorio: nrSeqRelatorio ?? '',
          Parametros: [
            {
              id: Parametros[0].cdParametro,
              valor: titleNumber,
            },
            {
              id: Parametros[1].cdParametro,
              valor: user.nameLogin,
            },
          ],
        };

        const { data: dataReport } = await api.post(
          `/report/generate-report`,
          body,
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(dataReport);
      } catch (err) {
        Notiflix.Notify.failure(
          'Ops.. Algo deu errado ao tentar abrir seu relatório. Por favor, tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [user],
  );

  const situationTemplate = useCallback((ieOpen) => {
    switch (ieOpen) {
      case 'Aberto':
        return getTag('Aberto', 'Aberto', 'orange', 'Em aberto');
      case 'Pago':
        return getTag('Pago', 'Pago', 'green', 'Pago');
      default:
        return '';
    }
  }, []);

  const getData = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');
      const { data } = await api.get(
        `/financial/beneficiary-list-of-tuition?userUnimedId=${user.id}`,
      );
      const { content } = data;
      setTableData(content);
    } catch (err) {
      if (err?.response?.status === 400) return;

      if (err?.response?.data?.message) {
        Notiflix.Notify.info(err.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar os dados, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, [user]);

  const getProductData = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-wrapper');
      const { data } = await api.get(
        'beneficiary/list-beneficiary-and-product',
      );
      const { content } = data;
      if (content && content[0]) {
        setProductData(content[0]);
      }
    } catch (error) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os dados do beneficiário. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-wrapper');
    }
  }, []);

  const tableDetails = useMemo(() => {
    if (user.isPayer) {
      return (
        <Table
          items={tableDetail}
          key="demonstrativo-e-segunda-via-do-boleto"
          className="notiflix-table-detail-loading"
          columnResizeMode="fit"
          id="demonstrativo-e-segunda-via-do-boleto"
        >
          <Column
            field="referenceDate"
            header="Mês de referência"
            body={(data) => addField(data.referenceDate, 'Mês de referência')}
          />
          <Column
            field="insuredName"
            header="Segurado"
            sortable
            body={(data) => addField(data.insuredName, 'Segurado')}
          />
          <Column
            field="cardNumber"
            header="Número do Cartão"
            sortable
            body={(data) => addField(data.cardNumber, 'Número do Cartão')}
          />
          <Column
            field="coparcenaryValue"
            header="Valor de Coparticipação"
            sortable
            className="currency"
            body={(data) =>
              addField(data.coparcenaryValue, 'Valor de Coparticipação', true)
            }
          />
          <Column
            field="tuitionValue"
            header="Valor Mensalidade"
            sortable
            className="currency"
            body={(data) =>
              addField(data.tuitionValue, 'Valor Mensalidade', true)
            }
          />
        </Table>
      );
    }
    return (
      <Table
        items={tableDetail}
        key="demonstrativo-e-segunda-via-do-boleto"
        className="notiflix-table-detail-loading"
        columnResizeMode="fit"
        id="demonstrativo-e-segunda-via-do-boleto"
      >
        <Column
          field="referenceDate"
          header="Mês de referência"
          body={(data) => addField(data.referenceDate, 'Mês de referência')}
        />
        <Column
          field="insuredName"
          header="Segurado"
          sortable
          body={(data) => addField(data.insuredName, 'Segurado')}
        />
        <Column
          field="cardNumber"
          header="Número do Cartão"
          sortable
          body={(data) => addField(data.cardNumber, 'Número do Cartão')}
        />
        <Column
          field="coparcenaryValue"
          header="Valor de Coparticipação"
          className="currency"
          sortable
          body={(data) =>
            addField(data.coparcenaryValue, 'Valor de Coparticipação', true)
          }
        />
      </Table>
    );
  }, [tableDetail, user]);

  const rowExpansionTemplate = useCallback(
    (rowData: any) => {
      if (rowData.ieOpen !== 'Aberto') {
        const buttons = [
          {
            title: 'Detalhes',
            float: 'left',
            onClick: handleOpenDetail,
            last: true,
          },
          {
            title: 'Observações',
            float: 'left',
            onClick: handleOpenObservation,
            last: true,
          },
          {
            title: '2ª via Boleto',
            float: 'left',
            onClick: handleRequestReport,
          },
        ];
        return (
          <ButtonsActionsContainer>
            {buttons.map((button, index) => {
              const uuid = v4();
              return (
                <Button
                  key={button.title}
                  modal
                  autoWidth
                  sausageLook
                  whiteButton
                  className={`notiflix-${uuid} ${index === 1 && 'marginRight'}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    button.onClick(rowData, `notiflix-${uuid}`);
                  }}
                >
                  {button.title}
                </Button>
              );
            })}
          </ButtonsActionsContainer>
        );
      }
      const buttons = [
        {
          title: 'Detalhes',
          float: 'left',
          onClick: handleOpenDetail,
          last: true,
        },
        {
          title: 'Observações',
          float: 'left',
          onClick: handleOpenObservation,
          last: true,
        },
        {
          title: '2ª via Boleto',
          float: 'left',
          onClick: handleRequestReport,
        },
        {
          title: 'Enviar boleto por e-mail',
          float: 'right',
          onClick: handleSendOnEmail,
        },
        {
          title: 'Copiar código de barras',
          float: 'right',
          onClick: copyToClipboard,
        },
      ];
      return (
        <ButtonsActionsContainer>
          {buttons.map((button, index) => {
            const uuid = v4();
            return (
              <Button
                key={button.title}
                modal
                autoWidth
                sausageLook
                whiteButton
                className={`notiflix-${uuid} ${index === 1 && 'marginRight'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  button.onClick(rowData, `.notiflix-${uuid}`);
                }}
              >
                {button.title}
              </Button>
            );
          })}
        </ButtonsActionsContainer>
      );
    },
    [
      copyToClipboard,
      handleOpenDetail,
      handleOpenObservation,
      handleRequestReport,
      handleSendOnEmail,
    ],
  );

  const rowExpansionTemplate2 = useCallback(
    (rowData: any) => {
      const buttons = [
        {
          title: 'Detalhes',
          onClick: handleOpenDetail,
        },
      ];
      return (
        <ButtonsActionsContainer>
          {buttons.map((button) => {
            const uuid = v4();
            return (
              <Button
                key={button.title}
                modal
                autoWidth
                sausageLook
                whiteButton
                className={`notiflix-${uuid} marginLeft`}
                onClick={(e) => {
                  e.stopPropagation();
                  button.onClick(rowData, `notiflix-${uuid}`);
                }}
              >
                {button.title}
              </Button>
            );
          })}
        </ButtonsActionsContainer>
      );
    },
    [handleOpenDetail],
  );

  const table = useMemo(() => {
    if (user.isPayer) {
      return (
        <Table
          items={tableData}
          key="demonstrativo-e-segunda-via-do-boleto"
          className="notiflix-table-loading"
          columnResizeMode="fit"
          id="demonstrativo-e-segunda-via-do-boleto"
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows([e.data[e.data.length - 1]])}
          onRowClick={(e) => {
            if (
              expandedRows.length > 0 &&
              expandedRows[0]?.titleNumber !== e.data?.titleNumber
            ) {
              setExpandedRows([]);
              return setExpandedRows([e.data]);
            }
            return expandedRows.length === 0
              ? setExpandedRows([e.data])
              : setExpandedRows([]);
          }}
          rowExpansionTemplate={rowExpansionTemplate}
          onRowExpand={(e) =>
            window.innerWidth <= 768 &&
            setPositionOfRowExpanded(e.originalEvent)
          }
        >
          <Column
            field="payerName"
            header="Pagador"
            sortable
            body={(data) => addField(data.payerName, 'Pagador')}
          />
          <Column
            field="referenceDate"
            header="Mensalidade"
            className="date"
            style={{
              width: '130px',
            }}
            body={(data) => addField(data.referenceDate, 'Mensalidade')}
          />
          <Column
            field="expirationDate"
            header="Vencimento"
            className="date"
            body={(data) => addField(data.expirationDate, 'Vencimento')}
          />
          <Column
            field="liquidationDate"
            header="Data de Pagamento"
            className="date"
            style={{
              width: '130px',
            }}
            body={(data) => addField(data.liquidationDate, 'Data de Pagamento')}
          />

          <Column
            field="titleNumber"
            header="Título"
            sortable
            style={{
              width: '100px',
            }}
            body={(data) => addField(data.titleNumber, 'Título')}
          />
          <Column
            field="totalTuitionValue"
            header="Valor"
            body={(data) => addField(data.totalTuitionValue, 'Valor', true)}
            className="currency"
            sortable
          />
          <Column
            field="totalCoparcenaryValue"
            className="currency"
            header="Coparticipação"
            style={{
              width: '130px',
            }}
            body={(data) => {
              return addField(
                data.totalCoparcenaryValue,
                'Coparticipação.',
                true,
              );
            }}
            sortable
          />
          <Column
            field="ieOpen"
            header="Situação"
            className="tag"
            body={(data) => situationTemplate(data.ieOpen)}
            sortable
            style={{
              width: '120px',
            }}
          />
          <Column header="" expander headerStyle={{ width: '40px' }} />
        </Table>
      );
    }
    return (
      <Table
        items={tableData}
        key="demonstrativo-e-segunda-via-do-boleto"
        className="notiflix-table-loading"
        columnResizeMode="fit"
        id="demonstrativo-e-segunda-via-do-boleto"
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate2}
        onRowExpand={(e) =>
          window.innerWidth <= 768 && setPositionOfRowExpanded(e.originalEvent)
        }
      >
        <Column
          field="referenceDate"
          header="Mensalidade"
          body={(data) => addField(data.referenceDate, 'Mensalidade')}
        />
        <Column
          field="expirationDate"
          header="Vencimento"
          className="date"
          body={(data) => addField(data.expirationDate, 'Vencimento')}
        />
        <Column
          field="payerName"
          header="Pagador"
          sortable
          body={(data) => addField(data.payerName, 'Pagador')}
        />
        <Column
          field="totalCoparcenaryValue"
          header="Coparticipação"
          className="currency"
          body={(data) =>
            addField(data.totalCoparcenaryValue, 'R$ Copartic.', true)
          }
          sortable
        />
        <Column header="" expander headerStyle={{ width: '80px' }} />
        {/* <Column
          bodyClassName="options"
          body={optionConstructorCNPJ}
          headerStyle={{ width: '60px' }}
        /> */}
      </Table>
    );
  }, [
    expandedRows,
    rowExpansionTemplate,
    rowExpansionTemplate2,
    situationTemplate,
    tableData,
    user,
  ]);

  useEffect(() => {
    getProductData();
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const delaySetHeight = () => {
      window.setTimeout(() => {
        calculateHeightOfSteps();
      }, 500);
    };

    window.addEventListener('resize', delaySetHeight);
    return () => {
      window.removeEventListener('resize', delaySetHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    calculateHeightOfSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableDetails]);

  return (
    <PageWrapper
      gridTemplateColumns="repeat(10,1fr)"
      gridTemplateRows="auto 1fr"
    >
      <Steps defineHeight={defineHeightOfSteps}>
        <Step1 show={actualStep === 1} ref={step1Ref}>
          <Container>
            <Title>Demonstrativo e segunda via do boleto</Title>
            <FilterContainer hiddenTable={!user.isPayer}>
              <FormWrapper
                className="notiflix-wrapper"
                onSubmit={() => {
                  //
                }}
              >
                <div>
                  <Input
                    name="beneficiaryName"
                    insideInputTitle="Beneficiário"
                    disabled
                    value={productData?.beneficiaryName}
                    themeColor={colors.palet.institutional}
                  />
                  <Input
                    name="productName"
                    insideInputTitle="Produto"
                    disabled
                    value={productData?.productName}
                    themeColor={colors.palet.institutional}
                  />
                  <Input
                    name="cardNumber"
                    insideInputTitle="Carteira"
                    disabled
                    value={productData?.cardNumber}
                    themeColor={colors.palet.institutional}
                  />
                  <Input
                    name="hiringDate"
                    insideInputTitle="Data de adesão"
                    disabled
                    value={productData?.hiringDate}
                    themeColor={colors.palet.institutional}
                  />
                </div>
                <Link
                  hidden={
                    !(
                      user.isPayer &&
                      productData?.hiringType === 'Individual/Familiar'
                    )
                  }
                  to="/debito-em-conta"
                >
                  SOLICITAR DÉBITO EM CONTA
                </Link>
              </FormWrapper>
            </FilterContainer>
            <TableContainer>{table}</TableContainer>
          </Container>
        </Step1>
        <Step2 show={actualStep === 2} ref={step2Ref}>
          <Container>
            <Title>Demonstrativo e segunda via do boleto</Title>

            <TableContainer>{tableDetails}</TableContainer>
            <FormWrapper
              onSubmit={() => {
                //
              }}
            >
              <Button
                autoWidth
                formLook
                secondary
                onClick={() => setActualStep(1)}
              >
                Voltar
              </Button>
            </FormWrapper>
          </Container>
        </Step2>
      </Steps>
    </PageWrapper>
  );
};

export default BeneficiaryDemonstrativeAndDuplicate;
