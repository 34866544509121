import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Notiflix from 'notiflix';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import axios, { AxiosResponse } from 'axios';
import { v4 } from 'uuid';
import Button from '../../components/Button';

import FormWrapper from '../../components/FormWrapper';
import Modal2 from '../../components/Modal2';
import Select, { SelectHandles } from '../../components/Select';
import api from '../../services/api/api';

import Input from '../../components/Input';
import DatePicker from '../../components/DatePicker';
import InputFile, { InputFileHandles } from '../../components/InputFile';
import { TitleIntern } from '../../components/Title';
import { RowProps } from '.';
import { dateToPTBR } from '../../utils/formatt';
import getValidationErrors, { TestaCPF } from '../../utils/getValidationErrors';
import { EnterpriseUser, UserCommon, useAuth } from '../../hooks/auth';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';
import { ModalButtonContainer } from '../BeneficiaryInclude/styles';
import SelectUnform from '../../components/SelectUnform';
import { OptionalParams } from './styles';
import DatePickerUnform from '../../components/DatePickerUnform';

interface DepentendIncludeProps {
  defaultColor: string;
  callbackSetStep: (prev: number) => void;
  rowsSelecteds: RowProps[];
  showTitle: boolean;
  showActionsButtons?: boolean;
  contractId?: string;
  selectedProduct?: string;
  showNewButton?: boolean;
  isExternalLink?: boolean;
  preloadOfRequestId?: string;
  initialAllDisable?: boolean;
  requestHolderId?: string;
  callbackShowReleaseButton?: () => void;
}

export interface DependentIncludeHandles {
  containAllFiles(): boolean;
  saveAndRelease: () => Promise<boolean>;
  firstContainerRefError(): HTMLDivElement | null;
  validateForm(): Promise<void>;
  dispatchSaveEdit(): Promise<string>;
}
interface AttachmentRules {
  beneficiaryType: string;
  id: string;
  mandatory: string;
  name: string;
  relationship: string;
}

interface DateProps {
  value: Date | null;
  error: string;
}

const DepentendInclude: React.ForwardRefRenderFunction<
  DependentIncludeHandles,
  DepentendIncludeProps
> = (
  {
    defaultColor,
    callbackSetStep,
    rowsSelecteds,
    showTitle = true,
    isExternalLink = false,
    preloadOfRequestId,
    initialAllDisable = false,
    showNewButton = true,
    requestHolderId: requestHolderIdProps,
    callbackShowReleaseButton,
    showActionsButtons = true,
    contractId,
    selectedProduct,
  },
  ref,
) => {
  const { user: userT } = useAuth();
  const user = userT as UserCommon & EnterpriseUser;

  const formRef = useRef<FormHandles>(null);
  const formRef2 = useRef<FormHandles>(null);
  const parentesRef = useRef<SelectHandles>(null);
  const countryRef = useRef<SelectHandles>(null);
  const nationalityRef = useRef<SelectHandles>(null);
  const stateRef = useRef<SelectHandles>(null);
  const cityRef = useRef<SelectHandles>(null);
  const ufRef = useRef<SelectHandles>(null);
  const maritalRef = useRef<SelectHandles>(null);
  const phisicalSexRef = useRef<SelectHandles>(null);
  const reasonForInclusionRef = useRef<SelectHandles>(null);
  const [disabled, setDisabled] = useState<boolean>(initialAllDisable);
  const [requestHolderId, setRequestHolderId] = useState(requestHolderIdProps);
  const [idOfOwnerRequiredByContract, setIdOfOwnerRequiredByContract] =
    useState<string | null>(null);
  const [requestId, setRequestId] = useState('');

  const [hiddenRelease, setHiddenRelease] = useState(false);
  const [refreshedAttachments, refreshAttachments] = useState('');

  const attachmentsRefs = useRef<InputFileHandles[]>([]);
  const [allAttachmentsTypes, setAllAttachmentsTypes] = useState(
    [] as AttachmentRules[],
  );
  const [attachmentsTypes, setAttachmentsTypes] = useState(
    [] as AttachmentRules[],
  );

  const [parents, setParents] = useState('');
  const [parentsOptions, setParentsOptions] = useState([
    { title: '', value: '' },
  ]);

  const [countryOptions, setCountryOptions] = useState([
    { title: 'País', value: '' },
  ]);

  const [nationalityOptions, setNationalityOptions] = useState([
    { title: 'Nacionalidade', value: '' },
  ]);

  const [statesOptions, setStateOptions] = useState([
    { title: 'Estado', value: '' },
  ]);

  const [cityOptions, setCityOptions] = useState([
    { title: 'Cidades', value: '' },
  ]);

  const [ufOptions, setUfOptions] = useState([{ title: 'UF', value: '' }]);
  const [maritalOptions, setMaritalOptions] = useState([
    { title: 'Estado civil', value: '' },
  ]);

  const [phisicalSexOptions, setPhisicalSexOptions] = useState([
    { title: 'Sexo', value: '' },
  ]);

  const [reasonForInclusionOptions, setReasonForInclusionOptions] = useState([
    { title: 'Razão', value: '' },
  ]);
  const [includeReason, setIncludeReason] = useState({ name: '', id: '' });

  const [birthDate, setBirthDate] = useState<DateProps>({
    value: null,
    error: '',
  });
  // const [effectiveDate, setEffectiveDate] = useState<DateProps>({
  //   value: null,
  //   error: '',
  // });
  const [rgDispatchDate, setRgDispatchDate] = useState<DateProps>({
    value: null,
    error: '',
  });

  const handleReport = useCallback(
    async (request: string) => {
      try {
        Notiflix.Block.circle(`.notiflix-dependent-report-${request}`);
        const { data } = await api.get(
          `/report/list-of-company-portal-reports?contractId=${user.id}&exhibitionLocation=2`,
        );
        const { nrSeqRelatorio, Parametros: P } = data.content[0];
        const body = {
          nrSeqRelatorio: nrSeqRelatorio ?? '',
          Parametros: [
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...P.map((p: { cdParametro: any }) => {
              return {
                id: p.cdParametro,
                valor: request,
              };
            }),
          ],
        };

        const { data: dataReport } = await api.post(
          `/report/generate-report`,
          body,
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(dataReport);
      } catch (err) {
        if (err?.response?.status === 400) {
          Notiflix.Notify.info(err.response.data?.message);
        } else {
          Notiflix.Notify.failure(
            'Não conseguimos buscar seu relatório. Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove(`.notiflix-dependent-report-${request}`);
      }
    },
    [user],
  );

  const handleReleaseRegister = useCallback(
    async (request: string) => {
      try {
        const { data } = await api.put(
          `/company/release-dependent-registered`,
          {
            requestId: request,
            origin: 'Inclusao',
          },
        );
        const { message } = data;
        Modal2.Close();
        Notiflix.Notify.success(message);
        // Modal2.Success({
        //   closable: true,
        //   autoWidth: true,
        //   title: 'Perfeito!',
        //   text: message,
        //   children: (
        //     <Button modal onClick={() => Modal2.Close()}>
        //       Ok
        //     </Button>
        //   ),
        // });
        setHiddenRelease(true);
        if (!isExternalLink && (!requestHolderId || showActionsButtons)) {
          callbackSetStep(1);
        }
      } catch (err) {
        Modal2.Close();
        if (axios.isAxiosError(err) && err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data?.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos liberar esse registro no servidor no momento. Por favor, tente novamente mais tarde.',
          );
        }
      }
    },
    [isExternalLink, requestHolderId, showActionsButtons, callbackSetStep],
  );

  // const isLegalAge = (date) => console.log(date);

  const handleOpenInconsistency = useCallback(async (id) => {
    try {
      Notiflix.Block.circle('notiflix-button');
      const { data } = await api.get(
        `/company/get-inconsistency-inclusion?requestNumber=${id}`,
      );
      if (data.result !== 2) {
        const { data: realData } = data;
        const text = realData.map((e: any) => {
          return (
            <p key={e.inconsistencyDescription.slice(0, 10)}>
              {e.inconsistencyDescription}
            </p>
          );
        });

        if (!data) {
          return;
        }

        Modal2.Generic({
          closable: true,
          title: 'Inconsistências',
          autoWidth: true,
          text,
        });
      }
    } catch (err: any) {
      if (err.response?.data?.message) {
        Notiflix.Notify.info(err.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar o relatório no servidor. Por favor, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('notiflix-button');
    }
  }, []);

  const validateForm = useCallback(async () => {
    const data = formRef.current?.getData();
    try {
      formRef.current?.setErrors({});
      setRgDispatchDate((prev) => ({
        ...prev,
        error: '',
      }));
      parentesRef.current?.setError(
        !parentesRef.current?.value ? 'Informe o grau de parentesco.' : '',
      );

      nationalityRef.current?.setError(
        !nationalityRef.current?.value ? 'Informe uma nacionalidade.' : '',
      );

      maritalRef.current?.setError(
        !maritalRef.current?.value ? 'Informe uma estado civil.' : '',
      );

      // reasonForInclusionRef.current?.setError(
      //   !reasonForInclusionRef.current?.value ? 'Informe uma motivo.' : '',
      // );

      countryRef.current?.setError('');

      ufRef.current?.setError('');

      if (!birthDate.value) {
        setBirthDate((prev) => ({ ...prev, error: 'Informe uma data.' }));
      }
      // if (!effectiveDate.value) {
      //   setEffectiveDate((prev) => ({ ...prev, error: 'Informe uma data.' }));
      // }

      let hasError = false;

      const beneficiaryUnderAgeValidation = {};

      if (!data?.reasonForInclusion) {
        formRef.current?.setFieldError(
          'reasonForInclusion',
          'Informe um motivo.',
        );
        hasError = true;
      }
      if (nationalityRef.current?.value === '10') {
        stateRef.current?.setError(
          !stateRef.current?.value ? 'Informe um estado.' : '',
        );
        cityRef.current?.setError(
          !cityRef.current?.value ? 'Informe uma cidade.' : '',
        );

        if (birthDate.value) {
          const birthDateFormatted = dateToPTBR(birthDate.value);
          const [, , year] = birthDateFormatted.split('/');
          const age = new Date().getFullYear() - Number(year);
          // if (nationalityRef.current?.value === '10') {
          if (age > 14) {
            if (formRef.current?.getFieldValue('rg').length < 2) {
              Object.assign(beneficiaryUnderAgeValidation, {
                rg: Yup.string().required('Informe o RG.'),
              });
            }

            if (!rgDispatchDate.value) {
              setRgDispatchDate((prev) => ({
                ...prev,
                error:
                  'O dependente maior de 14 anos deve ter a Data Exp. RG preenchido.',
              }));
              hasError = true;
            }

            if (!countryRef.current?.value) {
              countryRef.current?.setError('Informe um país');
              hasError = true;
            }

            if (!formRef.current?.getFieldValue('organ')) {
              Object.assign(beneficiaryUnderAgeValidation, {
                organ: Yup.string().required('Informe o orgão.'),
              });
            }

            ufRef.current?.setError(
              !ufRef.current?.value ? 'Informe o estado.' : '',
            );
          }
        }
      }
      if (data?.cpf && !TestaCPF(data.cpf)) {
        formRef.current?.setFieldError('cpf', 'Informe um cpf válido.');
        hasError = true;
      }

      const noBirthdateValidation = {};
      if (!birthDate.value) {
        if (formRef.current?.getFieldValue('rg').length < 2) {
          Object.assign(noBirthdateValidation, {
            rg: Yup.string().required(
              'Informe a data de nascimento para preencher o rg.',
            ),
          });
        }
        if (nationalityRef.current?.value === '10') {
          if (!rgDispatchDate.value) {
            setRgDispatchDate((prev) => ({
              ...prev,
              error:
                'Informe a data de nascimento para prencher a Data Exp. RG.',
            }));
            hasError = true;
          }
        }
        if (!countryRef.current?.value) {
          countryRef.current?.setError(
            'Informe a data de nascimento para preencher País Emissor.',
          );
          hasError = true;
        }

        if (!phisicalSexRef.current?.value) {
          phisicalSexRef.current?.setError('Informe seu sexo.');
          hasError = true;
        }

        if (!formRef.current?.getFieldValue('organ')) {
          Object.assign(noBirthdateValidation, {
            organ: Yup.string().required(
              'Informe a data de nascimento para preencher o orgão.',
            ),
          });
        }

        ufRef.current?.setError(
          !ufRef.current?.value
            ? 'Informe a data de nascimento para preencher a UF.'
            : '',
        );
      }

      const schema = Yup.object().shape({
        dependentName: Yup.string().required('Informe o nome do dependente.'),
        phone: Yup.string().required('Informe um telefone'),
        cpf: Yup.string().required('Informe o CPF.'),
        // rg: Yup.string().required(
        //   'O dependente maior de 14 anos deve ter o RG preenchidoa.',
        // ),
        // organ: Yup.string().required('Informe o órgão.'),
        father: Yup.string().required('Informe o nome do pai.'),
        mother: Yup.string().required('Informe o nome da mãe.'),
        organ: Yup.string(),
        rg: Yup.string(),
        ...beneficiaryUnderAgeValidation,
        ...noBirthdateValidation,
      });
      await schema.validate(data, { abortEarly: false });

      if (
        !parentesRef.current?.value ||
        // !nationalityRef.current?.value ||
        // !stateRef.current?.value ||
        // !cityRef.current?.value ||
        !maritalRef.current?.value ||
        !phisicalSexRef.current?.value ||
        !birthDate.value ||
        // !effectiveDate.value ||
        // !reasonForInclusion.value ||
        hasError
      ) {
        throw new Error('Alguns campos necessários estão vazios.');
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        const scrollToFirstError = formRef.current?.getFieldRef(
          Object.keys(errors)[0],
        );
        if (scrollToFirstError) {
          const el: HTMLElement = scrollToFirstError.current;
          smoothScrollIntoView(el, { behavior: 'smooth' });
        }
      }
      throw new Error('Alguns campos necessários estão vazios.');
    }
  }, [birthDate, rgDispatchDate]); // effectiveDate

  const handleSubmit = useCallback(
    async (data, notiflix): Promise<string> => {
      try {
        await validateForm();

        Notiflix.Block.circle(notiflix);
        const files = attachmentsTypes.reduce((acc, act) => {
          if (attachmentsRefs.current[Number(act.id)].files !== null) {
            // const file = attachmentsRefs.current[Number(act.id)].files?.filter(
            //   (f, index) => index === 0,
            // )[0];
            attachmentsRefs.current[Number(act.id)].files?.forEach((f) => {
              acc.push(f);
            });
          }
          return acc;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }, [] as any);
        const toSend = new FormData();
        if (rowsSelecteds[0].contractId) {
          toSend.append('contractId', rowsSelecteds[0].contractId);
        } else if (contractId) {
          toSend.append('contractId', contractId);
        }
        if (!isExternalLink) {
          toSend.append('webUsername', user.webUsername);
        } else {
          toSend.append('webUsername', rowsSelecteds[0].webUsername);
        }
        if (!requestHolderId) {
          toSend.append(
            'userUnimedIdHolder',
            (rowsSelecteds[0].userUnimedId &&
              String(rowsSelecteds[0].userUnimedId)) ||
              idOfOwnerRequiredByContract ||
              '',
          );
          // toSend.append('requestHolderId', '');
        } else {
          // toSend.append('userUnimedIdHolder', '');
          toSend.append('requestHolderId', requestHolderId);
        }
        toSend.append('previousRequestId', requestId || data.previousRequestId);
        toSend.append('dependentName', data.dependentName);
        toSend.append('email', data.email);
        toSend.append('planId', data.product ?? '');

        toSend.append('relationshipNumber', parentesRef.current?.value ?? '');
        toSend.append(
          'birthDate',
          birthDate.value ? dateToPTBR(birthDate.value) : '',
        );
        // toSend.append(
        //   'hiringDate',
        //   effectiveDate.value ? dateToPTBR(effectiveDate.value) : '',
        // );
        toSend.append('dddNumberPhone', data.phone.slice(0, 2));
        toSend.append('phone', data.phone.slice(2));

        const titleCityOfBirth = cityOptions.find(
          (i) => i.value === cityRef.current?.value,
        );

        if (titleCityOfBirth) {
          toSend.append('municipalityOfBirth', titleCityOfBirth.title);
        }

        toSend.append('initialsBirthState', stateRef.current?.value ?? '');
        toSend.append('cpf', data.cpf);
        toSend.append('rg', data.rg);
        toSend.append('issuingAgency', data.organ);
        toSend.append(
          'brazilianstatesWhichIssuedRg',
          ufRef.current?.value ?? '',
        );
        if (rgDispatchDate.value) {
          toSend.append('emissionDate', dateToPTBR(rgDispatchDate.value));
        }
        toSend.append('issuingAgencyCountry', countryRef.current?.value ?? '');
        toSend.append('maritalStatus', maritalRef.current?.value ?? '');
        toSend.append('sex', phisicalSexRef.current?.value ?? '');
        toSend.append('motherName', data.mother);
        toSend.append('fatherName', data.father);
        toSend.append('motiveIncludeId', data.reasonForInclusion);
        toSend.append('datePlanAgreementOrigin', data.contractDateOfOriginPlan);
        toSend.append(
          'sourcePlanPriceValue',
          String(Number(data.priceOfOriginPlan) / 100).replace(',', '.'),
        );
        // toSend.append('sourcePlanPriceValue', data.priceOfOriginPlan);
        toSend.append('ansGuideProtocolNumber', data.ANSGuideProtocolNumber);
        toSend.append('protocolIssueDate', data.issueProtocolDate);
        toSend.append('descriptionOperatorOrigin', data.originOperator);
        toSend.append(
          'codeCoveragePlanOrigin',
          data.coverageGeographicalOriginalPlan,
        );
        toSend.append(
          'sourcePlanRegistrationCode',
          data.registerOfOriginalProduct,
        );
        toSend.append('typePlanContractOrigin', data.typeOfContract);

        toSend.append('passportId', data.passportNumber);

        toSend.append('nationalityCode', nationalityRef.current?.value ?? '');

        files.forEach((f: Blob, index: number) => {
          toSend.append(`dependentsFiles[${index}]`, f);
        });

        const { data: dataApi } = await api.post(
          '/beneficiary-inclusion/add-dependent',
          toSend,
        );
        Notiflix.Block.remove(notiflix);
        const { content, message } = dataApi;

        setRequestId(content[0].requestId);

        if (isExternalLink) {
          Modal2.Success({
            closable: false,
            autoWidth: true,
            title: 'Perfeito!',
            subtitle: message,
            text: 'Deseja adicionar um dependente?',
            children: (
              <ModalButtonContainer>
                <Button
                  modal
                  outlined
                  onClick={() => {
                    Modal2.Close();
                    window.location.href =
                      'https://portal.unimedblumenau.com.br';
                  }}
                >
                  Sair
                </Button>
                <Button
                  modal
                  onClick={() => {
                    Modal2.Close();
                  }}
                >
                  Sim
                </Button>
              </ModalButtonContainer>
            ),
          });
          setDisabled(true);
          // return '';
        } else if (requestHolderId) {
          Notiflix.Notify.success(message);
        } else {
          Modal2.Success({
            closable: true,
            autoWidth: true,
            title: 'Perfeito!',
            text: message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        }
        handleOpenInconsistency(content[0].requestId);

        return content[0].requestId;
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.data?.message) {
          Notiflix.Block.remove(notiflix);
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else if (axios.isAxiosError(err)) {
          Notiflix.Block.remove(notiflix);
          Notiflix.Notify.failure(
            'Ops... Algo deu errado ao tentar processar sua solicitação. Por favor, tente novamente mais tarde.',
          );
        }
        return '';
      }
    },
    [
      validateForm,
      attachmentsTypes,
      rowsSelecteds,
      contractId,
      isExternalLink,
      requestHolderId,
      requestId,
      birthDate.value,
      // effectiveDate.value,
      cityOptions,
      rgDispatchDate.value,
      user.webUsername,
      idOfOwnerRequiredByContract,
    ],
  );

  const handleSubmitEdit = useCallback(
    async (
      data = formRef.current?.getData(),

      notiflix = `.notiflix-dependent-send-edit-${requestId}`,
    ): Promise<string> => {
      try {
        await validateForm();
        Notiflix.Block.circle(notiflix);
        const files = attachmentsTypes.reduce((acc, act) => {
          if (attachmentsRefs.current[Number(act.id)].files !== null) {
            // const file = attachmentsRefs.current[Number(act.id)].files?.filter(
            //   (f, index) => index === 0,
            // )[0];
            attachmentsRefs.current[Number(act.id)].files?.forEach((f) => {
              acc.push(f);
            });
          }
          return acc;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }, [] as any);

        const toSend = new FormData();

        if (rowsSelecteds[0].contractId) {
          toSend.append('contractId', rowsSelecteds[0].contractId);
        } else if (contractId) {
          toSend.append('contractId', contractId);
        }
        toSend.append('webUsername', user.webUsername);

        if (!requestHolderId) {
          toSend.append(
            'userUnimedIdHolder',
            (rowsSelecteds[0].userUnimedId &&
              String(rowsSelecteds[0].userUnimedId)) ||
              idOfOwnerRequiredByContract ||
              '',
          );
          // toSend.append('requestHolderId', '');
        } else {
          // toSend.append('userUnimedIdHolder', '');
          toSend.append('requestHolderId', requestHolderId);
        }
        toSend.append('dependentName', data.dependentName);
        toSend.append('email', data.email);
        toSend.append('planId', data.product);
        toSend.append('relationshipNumber', parentesRef.current?.value ?? '');
        toSend.append(
          'birthDate',
          birthDate.value ? dateToPTBR(birthDate.value) : '',
        );
        // toSend.append(
        //   'hiringDate',
        //   effectiveDate.value ? dateToPTBR(effectiveDate.value) : '',
        // );
        toSend.append('dddNumberPhone', data.phone.slice(0, 2));
        toSend.append('phone', data.phone.slice(2));
        const titleCityOfBirth = cityOptions.find(
          (i) => i.value === cityRef.current?.value,
        );

        if (titleCityOfBirth) {
          toSend.append('municipalityOfBirth', titleCityOfBirth.title);
        }
        toSend.append('initialsBirthState', stateRef.current?.value ?? '');
        toSend.append('cpf', data.cpf);
        toSend.append('rg', data.rg);
        toSend.append('issuingAgency', data.organ);
        toSend.append(
          'brazilianstatesWhichIssuedRg',
          ufRef.current?.value ?? '',
        );
        if (rgDispatchDate.value) {
          toSend.append('emissionDate', dateToPTBR(rgDispatchDate.value));
        }
        toSend.append('issuingAgencyCountry', countryRef.current?.value ?? '');
        toSend.append('maritalStatus', maritalRef.current?.value ?? '');
        toSend.append('sex', phisicalSexRef.current?.value ?? '');
        toSend.append('motherName', data.mother);
        toSend.append('fatherName', data.father);
        toSend.append('motiveIncludeId', data.reasonForInclusion);
        toSend.append('datePlanAgreementOrigin', data.contractDateOfOriginPlan);
        toSend.append(
          'sourcePlanPriceValue',
          String(Number(data.priceOfOriginPlan) / 100).replace('.', ','),
        );
        // toSend.append('sourcePlanPriceValue', data.priceOfOriginPlan);
        toSend.append('ansGuideProtocolNumber', data.ANSGuideProtocolNumber);
        toSend.append('protocolIssueDate', data.issueProtocolDate);
        toSend.append('descriptionOperatorOrigin', data.originOperator);
        toSend.append(
          'codeCoveragePlanOrigin',
          data.coverageGeographicalOriginalPlan,
        );
        toSend.append(
          'sourcePlanRegistrationCode',
          data.registerOfOriginalProduct,
        );
        toSend.append('typePlanContractOrigin', data.typeOfContract);
        toSend.append('passportId', data.passportNumber);
        toSend.append('nationalityCode', nationalityRef.current?.value ?? '');

        toSend.append('requestId', requestId);
        toSend.append('previousRequestId', requestId || data.previousRequestId);

        files.forEach((f: Blob, index: number) => {
          toSend.append(`dependentsFiles[${index}]`, f);
        });

        const { data: dataApi } = await api.post(
          '/beneficiary-inclusion/add-dependent',
          toSend,
        );

        Notiflix.Block.remove(notiflix);

        const { message } = dataApi;

        Notiflix.Notify.success(message);
        handleOpenInconsistency(requestId);
        return requestId;
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.data?.message) {
          Notiflix.Block.remove(notiflix);
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else if (axios.isAxiosError(err)) {
          Notiflix.Block.remove(notiflix);
          Notiflix.Notify.failure(
            'Ops... Algo deu errado ao tentar processar sua solicitação. Por favor, tente novamente mais tarde.',
          );
        }
        return '';
      }
    },
    [
      requestId,
      validateForm,
      attachmentsTypes,
      rowsSelecteds,
      contractId,
      user.webUsername,
      idOfOwnerRequiredByContract,
      requestHolderId,
      birthDate.value,
      // effectiveDate.value,
      cityOptions,
      rgDispatchDate.value,
    ],
  );

  const getAllParents = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-parents');
      const { data } = await api.get('/generic/relationship');
      const { content } = data;
      setParentsOptions(
        content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      );
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar a lista de parentes do servidor. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-parents');
    }
  }, []);

  const getNationalities = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-nationality');
      const { data } = await api.get(`/generic/list-of-nationality`);
      const { content } = data;
      setNationalityOptions([
        {
          title: '',
          value: '',
        },
        ...content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name.toUpperCase(),
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      ]);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops não conseguimos buscar as cidades para seleção. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-nationality');
    }
  }, []);

  const getCitysOfState = useCallback(async (state) => {
    try {
      Notiflix.Block.circle('.notiflix-city');
      const { data } = await api.get(`/address/list-of-cities?state=${state}`);
      Notiflix.Block.circle('.notiflix-city');
      Notiflix.Block.remove('.notiflix-city');
      const { content } = data;

      setCityOptions([
        {
          title: '',
          value: '',
        },
        ...content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { city: string; ibgeCityCode: string },
          ) => {
            acc.push({
              title: act.city,
              value: act.ibgeCityCode,
            });
            return acc;
          },
          [],
        ),
      ]);
    } catch (err) {
      Notiflix.Block.remove('.notiflix-city');
      Notiflix.Notify.failure(
        'Ops não conseguimos buscar as cidades para seleção. Por favor, tente novamente mais tarde.',
      );
    }
  }, []);
  const getAllCountry = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-country');
      const { data } = await api.get(`/address/list-of-countries`);
      const { content } = data;
      setCountryOptions([
        { title: '', name: '' },
        ...content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      ]);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops não conseguimos buscar os países para seleção. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-country');
    }
  }, []);

  const getAllStates = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-states');
      Notiflix.Block.circle('.notiflix-uf');
      const { data } = await api.get('/address/list-of-states');
      const { content } = data;
      setStateOptions([
        {
          title: '',
          name: '',
        },
        ...content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      ]);
      setUfOptions([
        { title: '', value: '' },
        ...content.reduce(
          (acc: { title: string; value: string }[], act: { id: string }) => {
            acc.push({
              title: act.id,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      ]);
      // getCitysOfState(content[0].id);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops não conseguimos buscar os estados para seleção. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-states');
      Notiflix.Block.remove('.notiflix-uf');
    }
  }, [getCitysOfState]);

  const getAllMaritalStatus = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-marital');
      const { data } = await api.get('/generic/list-of-marital-status');
      const { content } = data;
      setMaritalOptions([
        {
          title: '',
          value: '',
        },
        ...content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({ title: act.name.toUpperCase(), value: act.id });
            return acc;
          },
          [],
        ),
      ]);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os estados civis para seleção. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-marital');
    }
  }, []);
  const getAllPhisicalSex = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-phisical-sex');
      const { data } = await api.get('/generic/list-of-phisical-sex');
      const { content } = data;
      setPhisicalSexOptions([
        { title: '', value: '' },
        ...content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({ title: act.name.toUpperCase(), value: act.id });
            return acc;
          },
          [],
        ),
      ]);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar a lista de seleção de sexo no servidor. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-phisical-sex');
    }
  }, []);
  const getAllReasonForInclusion = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-reason-inclusion');
      const { data } = await api.get(
        '/beneficiary/list-of-reasons-for-inclusion',
      );
      const { content } = data;
      setReasonForInclusionOptions(
        content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      );
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os motivos para inclusão para seleção. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-reason-inclusion');
    }
  }, [reasonForInclusionOptions]);

  const getAllAttachmentsTypes = useCallback(async () => {
    try {
      const DEPENDENT = 1;
      const { data } = await api.get(
        `/beneficiary-inclusion/attachment-rules?typeOfBeneficiary=${DEPENDENT}`,
      );
      const { content } = data;
      setAllAttachmentsTypes(content);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar as regras de anexo. Por favor, tente novamente mais tarde.',
      );
    }
  }, []);

  const getAllpreloadedUser = useCallback(async () => {
    const { data } = await api.get(
      `/beneficiary-inclusion/consult-solicitation-for-editing?requestId=${preloadOfRequestId}`,
    );
    const { content } = data;

    if (formRef.current) {
      const fullPhone = content[0].DDDNumberPhone + content[0].telephone;
      formRef.current.setFieldValue(
        'dependentName',
        content[0].beneficiaryName || '',
      );
      formRef.current.setFieldValue('email', content[0].email || '');
      formRef.current.setFieldValue('phone', fullPhone || '');
      formRef.current.setFieldValue('cpf', content[0].cpf || '');
      formRef.current.setFieldValue('rg', content[0].rg || '');
      formRef.current.setFieldValue(
        'organ',
        content[0].issuingBodyDescriptionRg || '',
      );
      formRef.current.setFieldValue('father', content[0].fatherName || '');
      formRef.current.setFieldValue('mother', content[0].mother || '');
      formRef.current.setFieldValue(
        'passportNumber',
        content[0].passportId || '',
      );

      formRef.current.setFieldValue('product', content[0].planId || '');
      // reasonForInclusionRef.current?.setValue('', content[0].motiveIncludeId);
      formRef.current?.setFieldValue(
        'reasonForInclusion',
        content[0].motiveIncludeId,
      );
      // formRef.current.setFieldValue('product', content.planId ?? '');

      if (content[0].datePlanAgreementOrigin) {
        const [datePlanDay, datePlanMonth, datePlanYear] =
          content[0].datePlanAgreementOrigin.split('/');
        formRef.current.setFieldValue(
          'contractDateOfOriginPlan',
          new Date(datePlanYear, datePlanMonth - 1, datePlanDay),
        );
      }

      // formRef.current.setFieldValue(
      //   'contractDateOfOriginPlan',
      //   content.datePlanAgreementOrigin || '',
      // );
      // const planValue = String(content[0].sourcePlanPriceValue)
      //   .replace('.', '')
      //   .replace(',', '');

      formRef.current.setFieldValue(
        'priceOfOriginPlan',
        String(content[0].sourcePlanPriceValue)
          .replace('.', '')
          .replace(',', ''),
      );
      // toSend.append(
      //   'priceOfOriginPlan',
      //   String(Number(content[0].sourcePlanPriceValue) / 100).replace('.', ','),
      // );
      formRef.current.setFieldValue(
        'ANSGuideProtocolNumber',
        content[0].ansGuideProtocolNumber,
      );

      if (content[0].protocolIssueDate) {
        const [issueProtocolDay, issueProtocolMonth, issueProtocolYear] =
          content[0].protocolIssueDate.split('/');
        formRef.current.setFieldValue(
          'issueProtocolDate',
          new Date(issueProtocolYear, issueProtocolMonth - 1, issueProtocolDay),
        );
      }
      // formRef.current.setFieldValue(
      //   'issueProtocolDate',
      //   content.protocolIssueDate || '',
      // );

      formRef.current.setFieldValue(
        'originOperator',
        content[0].descriptionOperatorOrigin,
      );

      formRef.current.setFieldValue(
        'coverageGeographicalOriginalPlan',
        content[0].codeCoveragePlanOrigin,
      );
      formRef.current.setFieldValue(
        'registerOfOriginalProduct',
        content[0].sourcePlanRegistrationCode,
      );
      formRef.current.setFieldValue(
        'typeOfContract',
        content[0].typePlanContractOrigin,
      );
      parentesRef.current?.setValue('', content[0].relationshipNumber);
      setParents(content[0].relationshipNumber);
      nationalityRef.current?.setValue('', content[0].nationalityCode);

      stateRef.current?.setValue('', content[0].initialsBirthState);

      getCitysOfState(content[0].initialsBirthState).then(() => {
        cityRef.current?.setValue(content[0].descriptionMunicipalityOfBirth);
      });

      countryRef.current?.setValue('', content[0].issuingCountryCode);

      ufRef.current?.setValue('', content[0].issuingAgencyState);
      phisicalSexRef.current?.setValue('', content[0].physicalSex);
      maritalRef.current?.setValue('', content[0].maritalStatus);
      formRef.current.setFieldValue('previousRequestId', content[0].requestId);

      if (content[0].birthDate) {
        const [birthDay, birthMonth, birthYear] =
          content[0].birthDate.split('/');
        setBirthDate({
          value: new Date(birthYear, birthMonth - 1, birthDay),
          error: '',
        });
      }

      // if (content[0].hiringDate) {
      //   const [hiringDateDay, hiringDateMonth, hiringDateYear] =
      //     content[0].hiringDate.split('/');
      //   setEffectiveDate({
      //     value: new Date(hiringDateYear, hiringDateMonth - 1, hiringDateDay),
      //     error: '',
      //   });
      // }

      if (content[0].emissionDate) {
        const [rgDispDay, rgDispMonth, rgDispYear] =
          content[0].emissionDate.split('/');
        setRgDispatchDate({
          value: new Date(rgDispYear, rgDispMonth - 1, rgDispDay),
          error: '',
        });
      }

      if (content[0].nrSeqTitularContrato) {
        setIdOfOwnerRequiredByContract(content[0].nrSeqTitularContrato);
      }
      setRequestHolderId(content[0].nrSeqTitularSolicitacao);
    }
  }, [preloadOfRequestId, getCitysOfState]);

  const getAttachments = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/beneficiary-inclusion/consult-solicitation-for-editing?requestId=${preloadOfRequestId}`,
      );
      const { content } = data;

      if (content) {
        // eslint-disable-next-line prefer-const
        let files: File[] = [];
        const allFiles = await Promise.allSettled<AxiosResponse<Blob>[]>(
          content[0].attachments.map((resp: { id: string; name: string }) =>
            api.get(
              `/beneficiary-inclusion/consult-solicitation-for-editing?requestId=${preloadOfRequestId}&fileId=${resp.id}&fileName=${resp.name}`,
              { responseType: 'blob' },
            ),
          ),
        );

        allFiles.forEach((promise) => {
          if (promise.status === 'fulfilled') {
            const field = promise.value.config.url?.split('?')[1].split('&');

            if (field) {
              files.push(
                new File(
                  [promise.value.data],
                  field[0].split('=')[1] + field[1].split('=')[1],
                ),
              );
            }
          }
        });

        attachmentsRefs.current[3]?.setFiles(files);
      }
    } catch (err) {
      if (err?.response.status === 400) return;
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os anexos para o servidor. Por favor, tente novamente mais tarde.',
      );
    }
  }, [preloadOfRequestId]);

  const unlockAllInputs = useCallback(() => {
    if (formRef.current) {
      Object.keys(formRef.current.getData()).forEach((key) => {
        if (formRef.current?.getFieldRef(key).current) {
          formRef.current?.getFieldRef(key).current.removeAttribute('disabled');
        }
      });
    }
    if (callbackShowReleaseButton) {
      callbackShowReleaseButton();
    }
  }, [callbackShowReleaseButton]);

  const handleSaveAndReleaseRegister = useCallback(async () => {
    if (!isExternalLink && !requestHolderId) {
      Notiflix.Block.circle('.notiflix-dependent-save-release');
    }
    let reqId = '';
    if (requestId) {
      reqId = await handleSubmitEdit(
        formRef.current?.getData(),
        `.notiflix-dependent-send-edit-${requestId}`,
      );
    } else {
      reqId = await handleSubmit(
        formRef.current?.getData(),
        `.notiflix-dependent-send-${requestId}`,
      );
    }

    if (reqId) {
      await handleReleaseRegister(reqId);
    }

    if (!isExternalLink && !requestHolderId) {
      Notiflix.Block.remove('.notiflix-dependent-save-release');
    }
    return !!reqId;
  }, [
    handleReleaseRegister,
    handleSubmit,
    handleSubmitEdit,
    isExternalLink,
    requestHolderId,
    requestId,
  ]);

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => {
    if (
      name === 'dependentName' ||
      name === 'mother' ||
      name === 'father' ||
      name === 'organ'
    ) {
      const result = event.target.value
        .normalize('NFD')
        .replace(/[^a-zA-Z( )]/g, '')
        .toUpperCase();
      formRef.current?.setFieldValue(name, result);
    } else {
      const result = event.target.value.toUpperCase();
      formRef.current?.setFieldValue(name, result);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getAttachments();
    }, 1000);
  }, [getAttachments]);

  useEffect(() => {
    Promise.all([
      getAllParents(),
      getAllStates(),
      getAllCountry(),
      getAllMaritalStatus(),
      getAllPhisicalSex(),
      getAllReasonForInclusion(),
      getAllAttachmentsTypes(),
      getNationalities(),
    ]).then(() => {
      if (preloadOfRequestId) {
        // carregar todos os dados do usuário a ser editado;
        getAllpreloadedUser();
        if (!disabled) {
          setRequestId(preloadOfRequestId);
        }
      }
    });

    // dispatch action to controller button on url path 'incluir-beneficiario'
    window.dispatchEvent(new Event('controllerOfSaveAndReleaseAction'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preloadOfRequestId]);

  useEffect(() => {
    if (rowsSelecteds[0].beneficiaryName && rowsSelecteds[0].productName) {
      formRef.current?.setFieldValue('product', rowsSelecteds[0].productName);
    } else if (selectedProduct) {
      formRef.current?.setFieldValue('product', selectedProduct);
    }
  }, [selectedProduct, rowsSelecteds]);

  const formActions = useMemo(() => {
    if (isExternalLink && requestId) {
      return <></>;
    }

    if (requestId) {
      return (
        <FormWrapper
          onSubmit={() => {
            //
          }}
          formLook
          className="actions-include-dependent"
        >
          {showActionsButtons && (
            <Button
              secondary
              autoWidth
              formLook
              onClick={() => callbackSetStep(1)}
              style={{ marginRight: 'auto' }}
            >
              Voltar
            </Button>
          )}
          {showActionsButtons && (
            <Button
              greenCriticalButton
              autoWidth
              formLook
              onClick={() => handleReport(requestId)}
              className={`notiflix-dependent-report-${requestId}`}
            >
              Imprimir
            </Button>
          )}
          {!hiddenRelease && (
            <Button
              greenPrimary
              autoWidth
              formLook
              className={`notiflix-dependent-send-edit-${requestId}`}
              onClick={() =>
                handleSubmitEdit(
                  formRef.current?.getData(),
                  `.notiflix-dependent-send-edit-${requestId}`,
                )
              }
            >
              Salvar
            </Button>
          )}
          {showActionsButtons &&
            !hiddenRelease &&
            refreshedAttachments &&
            attachmentsRefs.current.filter((r) => r.files && r.files.length > 0)
              .length > 0 && (
              <Button
                orangeButton
                autoWidth
                formLook
                onClick={() => handleSaveAndReleaseRegister()}
                style={{ marginLeft: '0' }}
                className="notiflix-dependent-save-release"
              >
                Salvar e Liberar
              </Button>
            )}
        </FormWrapper>
      );
    }
    return (
      <FormWrapper
        onSubmit={() => {
          //
        }}
        formLook
        className="actions-include-dependent"
      >
        {showTitle && (
          <Button
            secondary
            autoWidth
            formLook
            onClick={() => callbackSetStep(1)}
            style={{ marginRight: 'auto' }}
          >
            Voltar
          </Button>
        )}

        {!disabled && (
          <Button
            greenPrimary
            autoWidth
            formLook
            className={`notiflix-dependent-send-${requestId} ${
              isExternalLink && 'marginLeft'
            }`}
            onClick={() => {
              handleSubmit(
                formRef.current?.getData(),
                `.notiflix-dependent-send-${requestId}`,
              );
            }}
          >
            Salvar
          </Button>
        )}
        {!isExternalLink &&
          !requestHolderId &&
          !hiddenRelease &&
          refreshedAttachments &&
          attachmentsRefs.current.filter((r) => r.files && r.files.length > 0)
            .length > 0 && (
            <Button
              orangeButton
              autoWidth
              formLook
              onClick={() => handleSaveAndReleaseRegister()}
              style={{ marginLeft: '0' }}
              className="notiflix-dependent-save-release"
            >
              Salvar e Liberar
            </Button>
          )}
        {disabled && showNewButton && (
          <Button
            grayButton
            autoWidth
            formLook
            className={`notiflix-dependent-send-${requestId}`}
            onClick={() => {
              setDisabled(false);
              unlockAllInputs();
            }}
          >
            Novo
          </Button>
        )}
      </FormWrapper>
    );
  }, [
    callbackSetStep,
    disabled,
    handleReport,
    handleSubmit,
    handleSubmitEdit,
    hiddenRelease,
    refreshedAttachments,
    requestId,
    showTitle,
    unlockAllInputs,
    handleSaveAndReleaseRegister,
    isExternalLink,
    requestHolderId,
    showActionsButtons,
    showNewButton,
  ]);

  useEffect(() => {
    if (!allAttachmentsTypes) return;
    const found = allAttachmentsTypes.filter(
      (i) => String(i.relationship) === String(parents),
    );
    if (found.length > 0) {
      setAttachmentsTypes(found);
    } else {
      setAttachmentsTypes([]);
    }
  }, [allAttachmentsTypes, parents]);

  const attachments = useMemo(() => {
    if (isExternalLink && requestId) {
      return <></>;
    }

    if (attachmentsTypes.length > 0) {
      return (
        <FormWrapper
          onSubmit={() => {
            //
          }}
          formLook
          ref={formRef2}
          style={{ display: 'flex', flexWrap: 'wrap', marginTop: '24px' }}
        >
          <TitleIntern themeColor={defaultColor}>Anexos</TitleIntern>
          {attachmentsTypes.map((rel) => {
            return (
              <InputFile
                multiple
                key={`attachment-${rel.id}`}
                name={`attachment-${rel.id}`}
                title={rel.name}
                placeholder={rel.name}
                required={rel.mandatory === '1'}
                themeColor={defaultColor}
                callbackHasChanged={() => {
                  refreshAttachments(v4());

                  // dispatch action to controller button on url path 'incluir-beneficiario'
                  window.dispatchEvent(
                    new Event('controllerOfSaveAndReleaseAction'),
                  );
                }}
                ref={(el) => {
                  if (el) {
                    attachmentsRefs.current[Number(rel.id)] = el;
                  }
                }}
              />
            );
          })}
        </FormWrapper>
      );
    }
    return <></>;
  }, [attachmentsTypes, defaultColor, isExternalLink, requestId]);

  const getFirsContainerError = useCallback(() => {
    const types = attachmentsTypes.find((type) => {
      return (
        type.mandatory === '1' &&
        (!attachmentsRefs.current[Number(type.id)].files ||
          attachmentsRefs.current[Number(type.id)].files?.length === 0)
      );
    });
    if (types) {
      return attachmentsRefs.current[Number(types.id)].containerRef;
    }
    return null;
  }, [attachmentsTypes]);

  const containAllFiles = useCallback(() => {
    return (
      attachmentsTypes.filter((type) => {
        let error = false;
        if (
          type.mandatory === '1' &&
          (!attachmentsRefs.current[Number(type.id)].files ||
            attachmentsRefs.current[Number(type.id)].files?.length === 0)
        ) {
          attachmentsRefs.current[Number(type.id)].setError(
            'É necessário informar esse anexo.',
          );
          error = true;
        }
        return error;
      }).length === 0
    );
  }, [attachmentsTypes]);

  useImperativeHandle(ref, () => {
    return {
      containAllFiles,
      saveAndRelease: handleSaveAndReleaseRegister,
      firstContainerRefError: getFirsContainerError,
      validateForm,
      dispatchSaveEdit: handleSubmitEdit,
    };
  });

  /**
   * When has change attachments render, is dispatch event to controllerOfSaveAndReleaseAction to work in page beneficiary include
   */
  useEffect(() => {
    window.dispatchEvent(new Event('controllerOfSaveAndReleaseAction'));
  }, [attachments]);

  return (
    <>
      {showTitle && (
        <TitleIntern themeColor={defaultColor}>
          Titular: {rowsSelecteds[0].beneficiaryName}
        </TitleIntern>
      )}
      {!showTitle && (
        <TitleIntern themeColor={defaultColor}>
          Inclusão de dependente
        </TitleIntern>
      )}
      <FormWrapper
        onSubmit={() => {
          //
        }}
        formLook
        className="depentent-include"
        ref={formRef}
      >
        <Input
          name="dependentName"
          title="Nome do dependente:"
          formLook
          widthContainerDesktop="25%"
          themeColor={defaultColor}
          disabled={disabled}
          onChange={(event) => handleChangeInput(event, 'dependentName')}
        />
        <Input
          name="email"
          title="E-mail:"
          formLook
          widthContainerDesktop="25%"
          themeColor={defaultColor}
          disabled={disabled}
          onChange={(event) => handleChangeInput(event, 'email')}
        />

        <SelectUnform
          name="reasonForInclusion"
          title="Motivo de inclusão:"
          apiSearch="/beneficiary/list-of-reasons-for-inclusion"
          formLook
          callbackOnChange={(name, id) => {
            setIncludeReason({ name, id });
          }}
          widthContainerDesktop="50%"
          disabled={disabled}
          themeColor={defaultColor}
        />

        {/* <Select
          name="reasonForInclusion"
          title="Motivo de inclusão:"
          ref={reasonForInclusionRef}
          options={reasonForInclusionOptions}
          className="notiflix-reason-inclusion"
          callbackOnChange={(name, id) => {
            setIncludeReason({ name, id });
          }}
          liveReload
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="25%"
          disabled={disabled}
        /> */}

        <Select
          name="parents"
          title="Grau de parentesco:"
          ref={parentesRef}
          options={parentsOptions}
          className="notiflix-parents"
          formLook
          themeColor={defaultColor}
          changeCallback={setParents}
          widthContainerDesktop="50%"
          disabled={disabled}
        />

        <SelectUnform
          name="product"
          title="Produto:"
          apiSearch={
            rowsSelecteds[0].contractId || contractId
              ? `/company/product-listing-for-selection?contractId=${
                  rowsSelecteds[0].contractId || contractId
                }`
              : ''
          }
          formLook
          widthContainerDesktop="50%"
          disabled={
            rowsSelecteds[0].beneficiaryName || selectedProduct
              ? true
              : disabled
          }
          themeColor={defaultColor}
        />
        <OptionalParams
          className={includeReason.name === 'PORTABILIDADE' ? 'show' : ''}
        >
          <DatePickerUnform
            name="contractDateOfOriginPlan"
            title="Data de contratação do plano de origem:"
            icon
            formLook
            widthContainerDesktop="25%"
            disabled={disabled}
            themeColor={defaultColor}
          />
          <Input
            name="priceOfOriginPlan"
            title="Preço do plano de origem:"
            formLook
            widthContainerDesktop="25%"
            disabled={disabled}
            themeColor={defaultColor}
            masks={[
              '9,99',
              '99,99',
              '999,99',
              '9.999,99',
              '99.999,99',
              '999.999,99',
              '9.999.999,99',
              '99.999.999,99',
              '999.999.999,99',
              '9.999.999.999,99',
            ]}
          />
          <Input
            name="ANSGuideProtocolNumber"
            title="Nº protocolo guia ANS:"
            formLook
            widthContainerDesktop="25%"
            disabled={disabled}
            themeColor={defaultColor}
            onChange={(event) =>
              handleChangeInput(event, 'ANSGuideProtocolNumber')
            }
          />
          <DatePickerUnform
            name="issueProtocolDate"
            title="Data de emissão do protocolo:"
            icon
            formLook
            widthContainerDesktop="25%"
            disabled={disabled}
            themeColor={defaultColor}
          />
          <Input
            name="originOperator"
            title="Operadora de origem:"
            formLook
            widthContainerDesktop="25%"
            disabled={disabled}
            themeColor={defaultColor}
            onChange={(event) => handleChangeInput(event, 'originOperator')}
          />
          <SelectUnform
            name="coverageGeographicalOriginalPlan"
            title="Abrangência geográfica do plano de origem:"
            apiSearch="/domain/geographical-coverage"
            formLook
            widthContainerDesktop="25%"
            disabled={disabled}
            themeColor={defaultColor}
          />
          <Input
            name="registerOfOriginalProduct"
            title="Registro do produto de origem:"
            formLook
            widthContainerDesktop="25%"
            disabled={disabled}
            themeColor={defaultColor}
            onChange={(event) =>
              handleChangeInput(event, 'registerOfOriginalProduct')
            }
          />
          <SelectUnform
            name="typeOfContract"
            title="Tipo de contratação:"
            apiSearch="/domain/hiring-type-list"
            formLook
            widthContainerDesktop="25%"
            disabled={disabled}
            themeColor={defaultColor}
          />
        </OptionalParams>

        <DatePicker
          name="birhDate"
          title="Data de nascimento"
          selected={birthDate.value}
          error={birthDate.error}
          onChange={(date: Date) => setBirthDate({ value: date, error: '' })}
          themeColor={defaultColor}
          widthContainerDesktop="25%"
          disabled={disabled}
          maxDate={new Date()}
          formLook
          icon
        />
        {/* <DatePicker
          name="effectiveDate"
          title="Data de vigência"
          selected={effectiveDate.value}
          error={effectiveDate.error}
          onChange={(date: Date) =>
            setEffectiveDate({ value: date, error: '' })
          }
          themeColor={defaultColor}
          widthContainerDesktop="25%"
          disabled={disabled}
          formLook
          icon
        /> */}
        <Input
          name="phone"
          title="DDD/Telefone:"
          masks={['(99) 9999-9999', '(99) 9 9999-9999']}
          formLook
          widthContainerDesktop="25%"
          themeColor={defaultColor}
          disabled={disabled}
        />

        <Select
          name="nationality"
          title="Nacionalidade:"
          ref={nationalityRef}
          options={nationalityOptions}
          inputMode
          className="notiflix-nationality select-nationality"
          liveReload
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="25%"
          disabled={disabled}
        />

        <Select
          name="state"
          title="Estado de nascimento:"
          ref={stateRef}
          options={statesOptions}
          changeCallback={getCitysOfState}
          inputMode
          className="notiflix-states select-state"
          liveReload
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="25%"
          disabled={disabled}
        />
        <Select
          name="city"
          title="Cidade de nascimento:"
          ref={cityRef}
          options={cityOptions}
          className="notiflix-city select-city"
          liveReload
          inputMode
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="25%"
          disabled={disabled}
        />
        <Input
          name="cpf"
          title="CPF:"
          masks={['999.999.999-99']}
          formLook
          widthContainerDesktop="20%"
          themeColor={defaultColor}
          disabled={disabled}
        />

        <Input
          name="rg"
          title="RG:"
          formLook
          widthContainerDesktop="20%"
          disabled={disabled}
          themeColor={defaultColor}
          onChange={(event) => handleChangeInput(event, 'rg')}
        />
        <Select
          name="country"
          title="País emissor:"
          ref={countryRef}
          options={countryOptions}
          className="notiflix-country"
          liveReload
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="10%"
          disabled={disabled}
        />
        <Input
          name="organ"
          title="Órgão:"
          formLook
          widthContainerDesktop="10%"
          themeColor={defaultColor}
          disabled={disabled}
          onChange={(event) => handleChangeInput(event, 'organ')}
        />
        <Select
          name="uf"
          title="UF:"
          ref={ufRef}
          options={ufOptions}
          className="notiflix-uf select-uf"
          liveReload
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="10%"
          disabled={disabled}
        />
        <DatePicker
          name="rgDispatchDate"
          title="Data Exp. RG:"
          selected={rgDispatchDate.value}
          error={rgDispatchDate.error}
          onChange={(date: Date) =>
            setRgDispatchDate({ value: date, error: '' })
          }
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="15%"
          icon
          disabled={disabled}
        />
        <Select
          name="maritalStatus"
          title="Estado civil:"
          ref={maritalRef}
          options={maritalOptions}
          className="notiflix-marital select-marital-status"
          liveReload
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="15%"
          disabled={disabled}
        />

        <Select
          name="phisicalSex"
          title="Sexo:"
          ref={phisicalSexRef}
          options={phisicalSexOptions}
          className="notiflix-phisical-sex select-genre"
          liveReload
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="20%"
          disabled={disabled}
        />
        <Input
          name="passportNumber"
          title="Nº Passaporte:"
          formLook
          widthContainerDesktop="20%"
          themeColor={defaultColor}
          disabled={disabled}
          onChange={(event) => handleChangeInput(event, 'passportNumber')}
        />
        <Input
          name="father"
          title="Filiação (pai):"
          formLook
          widthContainerDesktop="50%"
          themeColor={defaultColor}
          disabled={disabled}
          onChange={(event) => handleChangeInput(event, 'father')}
        />
        <Input
          name="mother"
          title="Filiação (mãe):"
          formLook
          widthContainerDesktop="50%"
          themeColor={defaultColor}
          disabled={disabled}
          onChange={(event) => handleChangeInput(event, 'mother')}
        />
        <Input name="previousRequestId" hidden />
        <Input name="userUnimedIdHolder" hidden />
      </FormWrapper>
      {!disabled && <>{attachments}</>}

      {formActions}
    </>
  );
};

export default forwardRef(DepentendInclude);
