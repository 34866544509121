import React, { useState, useEffect, useCallback, useRef } from 'react';
import { v4 } from 'uuid';
import { mask } from 'remask';
import Notiflix from 'notiflix';
import { DropdownHandles } from '../../components/Dropdown';
import { Column } from '../../components/Table';
import api from '../../services/api/api';
import AddField from '../../utils/tableUtils';
import { DropdownTitle, Table, Dropdown } from './styles';

export interface ConsultProps {
  ansGroupType: string;
  quantityUsage: string;
  transactionId: string;
  valueUtilization: string;
  ansGroupDescription: string;
}

interface ConsultData {
  data: ConsultProps;
}

interface dataApiProps {
  cboDescription: string;
  dateRealize: string;
  descriptionReasonForClosing: string;
  descriptionReasonForExit: string;
  documentCode: string;
  dtRealizacao: string;
  municipalityDescription: string;
  procedureCode: string;
  procedureDescription: string;
  providerName: string;
}

const Consult: React.FC<ConsultData> = ({ data: dataProps }) => {
  const [data, setData] = useState<dataApiProps[]>([]);
  const dropdownRef = useRef<DropdownHandles>(null);
  const getData = useCallback(async () => {
    try {
      const { data: dataApi } = await api.get(
        `medical-consultation/query-data-with-filter-by-analytical-period?transactionId=${dataProps.transactionId}&ansGroupType=${dataProps.ansGroupType}`,
      );
      const { content: contentApi } = dataApi;
      setData(contentApi);
    } catch (error) {
      if (error.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar os dados para a tabela. Por favor, tente novamente mais tarde.',
        );
      }
      // console.log(error);
    }
  }, [dataProps]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dropdown
      ref={dropdownRef}
      key={v4()} // force reload
      titleJSX={
        <DropdownTitle>
          {dataProps.ansGroupDescription} - Valor: R${' '}
          {dataProps.valueUtilization} - Quantidade de Procedimentos{' '}
          {dataProps.quantityUsage}
        </DropdownTitle>
      }
      body={
        <Table
          items={data}
          className="notiflix-table-loading"
          columnResizeMode="fit"
          id="tabela-utilizacao-servicos"
          callbackShowMore={() =>
            window.setTimeout(() => {
              dropdownRef.current?.resize();
            }, 100)
          }
        >
          <Column
            field="procedureDescription"
            header="Procedimento"
            sortable
            body={(row) => AddField(row.procedureDescription, 'Procedimento')}
            className="column-table"
          />
          <Column
            field="providerName"
            className="column-table"
            header="Prestador"
            sortable
            body={(row) => AddField(row.providerName, 'Prestador')}
          />
          <Column
            style={{ width: '80px' }}
            field="quantityUsage"
            header="Qtd."
            sortable
            body={(row) => AddField(row.quantityUsage, 'Quantidade')}
            className="column-table date"
          />
          <Column
            field="dtRealizacao"
            header="Data Realização"
            className="date"
            body={(row) => AddField(row.dtRealizacao, 'Data Realização')}
          />
          <Column
            className="column-table"
            field="documentCode"
            header="CPF ou CNPJ"
            sortable
            body={(row) =>
              AddField(
                mask(row.documentCode, [
                  '999.999.999-99',
                  '999.999.99/9999-99',
                ]),
                'CPF ou CNPJ',
              )
            }
          />
          <Column
            className="column-table"
            field="cboDescription"
            header="CBO"
            bodyStyle={{ wordBreak: 'break-word' }}
            sortable
            body={(row) => AddField(row.cboDescription, 'CBO')}
          />
          <Column
            className="column-table"
            field="municipalityDescription"
            header="Município"
            sortable
            style={{ width: '100px' }}
            body={(row) => AddField(row.municipalityDescription, 'Município')}
          />
        </Table>
      }
    />
  );
};

export default Consult;
