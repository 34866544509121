import React from 'react';

import ReactDOM from 'react-dom';
import { AuthProvider } from '../../hooks/auth';
import { ContractProvider } from '../../hooks/contract';
import { ThemeProvider } from '../../hooks/theme';
import { FailureSvg, SucessSvg, ImportantSvg } from '../Svg';
import {
  ModalOverlay,
  ModalWrapper,
  CloseButton,
  TitleParagraph,
  SubtitleParagraph,
  Paragraph,
  DivWrapper,
} from './styles';

interface ModalProps {
  closable?: boolean;
  title?: string;
  subtitle?: string;
  text?: string | any;
  children?: JSX.Element;
  autoWidth?: boolean;
}

const Modal2 = {
  Generic({
    children,
    closable,
    autoWidth,
    subtitle,
    text,
    title,
  }: ModalProps): void {
    const modal = this.getContainer();
    if (modal) {
      ReactDOM.render(
        <AuthProvider>
          <ContractProvider>
            <ThemeProvider>
              <ModalOverlay>
                <ModalWrapper autoWidth={autoWidth}>
                  {closable && (
                    <CloseButton size={24} className="" onClick={this.Close} />
                  )}
                  {title && <TitleParagraph>{title}</TitleParagraph>}
                  {subtitle && (
                    <SubtitleParagraph>{subtitle}</SubtitleParagraph>
                  )}
                  {text && <Paragraph>{text}</Paragraph>}
                  {children && <DivWrapper>{children}</DivWrapper>}
                </ModalWrapper>
              </ModalOverlay>
            </ThemeProvider>
          </ContractProvider>
        </AuthProvider>,
        modal,
      );
    }
  },
  Success({
    closable: closeble,
    children,
    subtitle,
    text,
    title,
  }: ModalProps): void {
    const modal = this.getContainer();

    if (modal) {
      ReactDOM.render(
        <AuthProvider>
          <ContractProvider>
            <ThemeProvider>
              <ModalOverlay>
                <ModalWrapper className="small">
                  {closeble && (
                    <CloseButton size={24} className="" onClick={this.Close} />
                  )}
                  <DivWrapper>
                    <SucessSvg />
                  </DivWrapper>

                  {title && <TitleParagraph>{title}</TitleParagraph>}
                  {subtitle && (
                    <SubtitleParagraph>{subtitle}</SubtitleParagraph>
                  )}
                  {text && <Paragraph>{text}</Paragraph>}
                  {children && <DivWrapper>{children}</DivWrapper>}
                </ModalWrapper>
              </ModalOverlay>
            </ThemeProvider>
          </ContractProvider>
        </AuthProvider>,
        modal,
      );
    }
  },
  Warning({
    closable: closeble,
    children,
    subtitle,
    text,
    title,
  }: ModalProps): void {
    const modal = this.getContainer();

    if (modal) {
      ReactDOM.render(
        <AuthProvider>
          <ContractProvider>
            <ThemeProvider>
              <ModalOverlay>
                <ModalWrapper className="small">
                  {closeble && (
                    <CloseButton size={24} className="" onClick={this.Close} />
                  )}
                  <DivWrapper>
                    <ImportantSvg />
                  </DivWrapper>

                  {title && <TitleParagraph>{title}</TitleParagraph>}
                  {subtitle && (
                    <SubtitleParagraph>{subtitle}</SubtitleParagraph>
                  )}
                  {text && <Paragraph>{text}</Paragraph>}
                  {children && <DivWrapper>{children}</DivWrapper>}
                </ModalWrapper>
              </ModalOverlay>
            </ThemeProvider>
          </ContractProvider>
        </AuthProvider>,
        modal,
      );
    }
  },
  Failure({
    closable: closeble,
    children,
    subtitle,
    text,
    title,
  }: ModalProps): void {
    const modal = this.getContainer();

    if (modal) {
      ReactDOM.render(
        <AuthProvider>
          <ContractProvider>
            <ThemeProvider>
              <ModalOverlay>
                <ModalWrapper className="small">
                  {closeble && (
                    <CloseButton size={24} className="" onClick={this.Close} />
                  )}

                  <DivWrapper>
                    <FailureSvg />
                  </DivWrapper>
                  {title && <TitleParagraph>{title}</TitleParagraph>}
                  {subtitle && (
                    <SubtitleParagraph>{subtitle}</SubtitleParagraph>
                  )}
                  {text && <Paragraph>{text}</Paragraph>}
                  {children && <DivWrapper>{children}</DivWrapper>}
                </ModalWrapper>
              </ModalOverlay>
            </ThemeProvider>
          </ContractProvider>
        </AuthProvider>,
        modal,
      );
    }
  },

  Close(): void {
    const modal = document.getElementById('modal2');
    if (modal) {
      ReactDOM.unmountComponentAtNode(modal);
    }
  },
  getContainer(): HTMLElement {
    let modal = document.getElementById('modal2');
    if (!modal) {
      const root = document.getElementById('root');
      modal = document.createElement('div');
      modal.id = 'modal2';
      if (root) {
        root.appendChild(modal);
      }
    }
    return modal;
  },
};

export default Modal2;
