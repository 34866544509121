import Notiflix from 'notiflix';
import React, { useState, useEffect, useCallback } from 'react';

import { useParams } from 'react-router-dom';
import Form from './Form';
import Button from '../../../components/Button';
import Modal2 from '../../../components/Modal2';
import { LogoUnimedSvg } from '../../../components/Svg';
import Title from '../../../components/Title';
import api from '../../../services/api/api';

import { ExternalContainer, UnimedLogo, ModalButtonContainer } from './styles';
import { ReleasesProvider } from '../../../hooks/releasesAndTerms';

const DeclarationIncludeExternal: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [userDS, setUserDS] = useState({
    beneficiaryId: 0,
    name: '',
    telephone: '',
    birthdate: '',
    cpf: '',
    healthDeclarationId: 0,
    modelType: 0,
    is_additional: false,
  });

  useEffect(() => {
    const userDSlocalStorage = localStorage.getItem(
      '@unimedBlumenauDeclaracaoSaude',
    );
    if (userDSlocalStorage) {
      setUserDS(JSON.parse(userDSlocalStorage));
    }
  }, []);

  // console.log('userds', userDS);

  const handleSubmit = useCallback(
    async (dataSubmitParam) => {
      const arrAnswer = [] as { questionId: number; answer: string }[];
      Object.entries(dataSubmitParam).forEach((item) => {
        const [key, value] = item;

        if (key === `inputTextQuestion-${key.split('-')[1]}`) {
          arrAnswer.push({
            questionId: Number(key.split('-')[1]),
            answer: String(value),
          });
        }
        if (key === `optionQuestion-${key.split('-')[1]}-S`) {
          arrAnswer.push({
            questionId: Number(key.split('-')[1]),
            answer: String(value),
          });
        }
        if (key === `optionQuestion-${key.split('-')[1]}-A` && value === true) {
          arrAnswer.push({
            questionId: Number(key.split('-')[1]),
            answer: 'A',
          });
        } else if (
          key === `optionQuestion-${key.split('-')[1]}-B` &&
          value === true
        ) {
          arrAnswer.push({
            questionId: Number(key.split('-')[1]),
            answer: 'B',
          });
        } else if (
          key === `optionQuestion-${key.split('-')[1]}-C` &&
          value === true
        ) {
          arrAnswer.push({
            questionId: Number(key.split('-')[1]),
            answer: 'C',
          });
        }
        if (
          key ===
          `optionQuestionSubitem-${key.split('-')[1]}-${key.split('-')[2]}-S`
        ) {
          arrAnswer.push({
            questionId: Number(key.split('-')[2]),
            answer: String(value),
          });
        }
        if (key === `subItemPergunta-${key.split('-')[1]}`) {
          arrAnswer.push({
            questionId: Number(key.split('-')[1]),
            answer: String(value),
          });
        }
        if (key === `subItemPergunta-${key.split('-')[1]}-obrigatoria`) {
          arrAnswer.push({
            questionId: Number(key.split('-')[1]),
            answer: String(value),
          });
        }
      });
      try {
        Notiflix.Block.circle('.notiflix-save-beneficiary');
        const params = {
          beneficiaryId: userDS?.beneficiaryId,
          beneficiaryName: userDS.name,
          telephone: userDS.telephone,
          birthDate: userDS.birthdate,
          cpf: userDS.cpf,
          acceptTerms: true,
          answers: arrAnswer,
          is_additional: false,
        };

        const { data: dataApi } = await api.post(
          `/health-declaration/send-health-declaration`,
          params,
        );

        const { success, message, needEQ } = dataApi;
        if (success === true) {
          Modal2.Success({
            closable: false,
            autoWidth: true,
            title: 'Perfeito!',
            // subtitle: message,
            text: 'Declaração de saúde preenchida com sucesso',
            children: (
              <ModalButtonContainer>
                <Button
                  modal
                  outlined
                  onClick={() => {
                    Modal2.Close();
                    if (needEQ === true) {
                      window.location.href = '/agendamento-entrevista';
                    } else {
                      window.location.href =
                        'https://portal.unimedblumenau.com.br';
                    }
                  }}
                >
                  Prosseguir
                </Button>
              </ModalButtonContainer>
            ),
          });
        } else {
          Modal2.Failure({
            closable: false,
            autoWidth: true,
            title: 'Oops!',
            subtitle: message,
            children: (
              <ModalButtonContainer>
                <Button
                  modal
                  outlined
                  onClick={() => {
                    Modal2.Close();
                    // window.location.href = '/agendamento-entrevista';
                  }}
                >
                  Ok
                </Button>
              </ModalButtonContainer>
            ),
          });
        }
        return null;
      } catch (err) {
        if (err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos salvar sua requisição no servidor. Por favor, tente novamente mais tarde.',
          );
        }
        return null;
      } finally {
        Notiflix.Block.remove('.notiflix-save-beneficiary');
      }
    },
    [
      userDS?.beneficiaryId,
      userDS.birthdate,
      userDS.cpf,
      userDS.healthDeclarationId,
      userDS.name,
      userDS.telephone,
    ],
  );

  return (
    <ReleasesProvider>
      <ExternalContainer>
        <UnimedLogo inLoading={loading}>
          <LogoUnimedSvg />
        </UnimedLogo>
        {!loading && (
          <>
            <Title>
              <span id="titleDS">Declaração de saúde</span>
            </Title>
            <Form
              callbackSubmit={handleSubmit}
              isExternal
              declarationId={userDS.healthDeclarationId}
              isAdditional={userDS.is_additional}
            />
          </>
        )}
      </ExternalContainer>
    </ReleasesProvider>
  );
};

export default DeclarationIncludeExternal;
