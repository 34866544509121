import styled, { css } from 'styled-components';

import TableComponent from '../../components/Table';

interface ContainerCheckProps {
  themeColor?: string;
}

export const Title = styled.h1`
  grid-column: 2/10;
  margin: 30px 0;
  font-family: 'Unimed-Slab-Bold';
  text-align: center;
  font-size: 2.55rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional};
  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

export const Content = styled.div`
  grid-column: 2/10;
  @media (max-width: 768px) {
    grid-column: 1;
    margin: 0 30px;
  }
`;

export const Table = styled(TableComponent)`
  grid-column: 2/10;
  @media (max-width: 768px) {
    grid-column: 1;
    margin: 0;
    margin-bottom: 30px;
  }
  td.clicable {
    cursor: pointer;
  }
`;

export const ModalContainer = styled.div`
  > button {
    @media (max-width: 768px) {
      width: 100%;
      float: none;
      & + button {
        margin-top: 16px;
      }
    }
  }
  .removeDisplay {
    display: none !important;
  }
`;

export const ModalTitle = styled.h1`
  font-size: 1.7rem;
  font-family: 'Unimed-Slab-Bold';
  color: ${(props) => props.theme.colors.text.primary};
  margin-bottom: 56px;
  text-align: left;
  padding: 0 20px;
  @media (max-width: 768px) {
    font-size: 1.3rem;
    margin-bottom: 32px;
  }
`;
export const ModalContent = styled.p`
  font-size: 1rem;
  padding: 0 20px 50px 0;
  margin-bottom: 30px;
  text-align: left;
  font-family: 'Unimed-Sans-Book';
  color: ${(props) => props.theme.colors.text.primary};
  max-height: calc(100vh - 320px);
  overflow-y: auto;
  @media (max-width: 768px) {
    max-height: calc(100vh - 350px);
  }
  #acceptTerms {
    margin-left: 10px;
  }
`;

export const ContainerCheck = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
`;

export const ContentCheck = styled.div<ContainerCheckProps>`
  border: 1px solid #c4cbcf;
  box-sizing: border-box;
  border-radius: 3px;
  min-width: 20px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  position: relative;
  input {
    border: none;
    outline: none;
    appearance: none;
    position: relative;

    width: 100%;
    height: 100%;
    transition: background 300ms;
    &:checked {
      background: ${(props) => props.theme.colors.palet.institutional3};
      ${(props) =>
        props.themeColor &&
        css`
          background: ${props.themeColor};
        `}
    }

    &:checked::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.293056 5.72123C-0.600029 4.68471 0.750382 3.09982 1.64331 4.13634L4.3843 7.30374L10.3502 0.340876C11.2433 -0.701482 12.601 0.883217 11.7079 1.92558L5.06654 9.67267C4.69361 10.1081 4.08874 10.1094 3.71441 9.67485L0.293056 5.72123Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }
  & + div {
    margin-top: 8px;
  }
  span {
    display: block;
    width: 100%;
    color: black;
    font-size: 12px;
  }
`;

export const ButtonScroll = styled.button`
  position: absolute;
  width: 50px;
  height: 50px;
  right: 50px;
  bottom: 50px;
  border: 1px solid #e4e4e4;
  background: #fff;
  border-radius: 100%;
  img {
    width: 20px;
  }
`;

export const Tag = styled.span`
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 0.7rem;
  position: relative;
  padding-left: 16px;
  display: flex;
  white-space: nowrap;
  > svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &.pendente {
    color: ${(props) => props.theme.colors.palet.institutional6};
    > svg {
      path {
        stroke: ${(props) => props.theme.colors.palet.institutional6};
      }
      circle {
        fill: ${(props) => props.theme.colors.palet.institutional6};
      }
    }
  }
  &.concluido {
    color: ${(props) => props.theme.colors.palet.institutional};
    > svg {
      path {
        stroke: ${(props) => props.theme.colors.palet.institutional};
      }
      circle {
        fill: ${(props) => props.theme.colors.palet.institutional};
      }
    }
  }
`;
