import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Notiflix from 'notiflix';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useTheme } from 'styled-components';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Input from '../../components/Input';

import Modal2 from '../../components/Modal2';

import PageWrapper from '../../components/PageWrapper';
import Select, { SelectHandles } from '../../components/Select';
import { BeneficiaryUser, UserCommon, useAuth } from '../../hooks/auth';
import api from '../../services/api/api';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  Title,
  Steps,
  Step1,
  Step2,
  Step3,
  AcceptTermsContainer,
  AcceptTermsContent,
  AcceptTermsFooter,
  PendingTermAccept,
  CheckboxGroup,
  Error,
  FormWrapper,
} from './styles';

import { getAllDDIForSelectOptions } from '../../utils/formatt';
import { TitleIntern } from '../../components/Title';

const TerminateBeneficiary: React.FC = () => {
  const { colors } = useTheme();
  const { user: userT } = useAuth();
  const user = userT as UserCommon & BeneficiaryUser;
  const history = useHistory();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showSteps, setShowSteps] = useState(false);
  const [actualStep, setActualStep] = useState(1);
  const formRefStep1 = useRef<FormHandles>(null);
  const formRefStep2 = useRef<FormHandles>(null);
  const formRefStep3 = useRef<FormHandles>(null);
  const step1Ref = useRef<HTMLDivElement>(null);
  const step2Ref = useRef<HTMLDivElement>(null);
  const step3Ref = useRef<HTMLDivElement>(null);
  const causeOfTerminateSelectRef = useRef<SelectHandles>(null);
  const bankRef = useRef<SelectHandles>(null);
  const cellDDIRef = useRef<SelectHandles>(null);
  const phoneDDIRef = useRef<SelectHandles>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [termToTermination, setTermToTermination] = useState({} as any);
  const [checkBoxGroupError, setCheckBoxGroupError] = useState('');
  const [parentsOptions, setParentsOptions] = useState([
    {
      title: '',
      value: '',
    },
  ]);
  const [parents, setParents] = useState([
    {
      title: user.name,
      value: `${user.id}`,
    },
  ]);
  const [newOwner, setNewOwner] = useState('');
  const [optionsSelectStep1, setOptionsSelectStep1] = useState([
    {
      title: 'Selecione',
      value: '',
    },
  ]);
  const [bankOptions, setBankOptions] = useState({
    select: [
      {
        title: 'Bancos',
        value: '',
      },
    ],
    all: [
      {
        bankCode: '',
        bankName: '',
        agencyNumber: '',
      },
    ],
  });

  const [stepHeight, setStepHeight] = useState('250px');

  const defineHeightOfSteps = useCallback(() => {
    switch (actualStep) {
      case 1:
        setStepHeight(`${step1Ref.current?.scrollHeight}px`);
        break;
      case 2:
        setStepHeight(`${step2Ref.current?.scrollHeight}px`);
        break;
      case 3:
        setStepHeight(`${step3Ref.current?.scrollHeight}px`);
        break;
      default:
        setStepHeight('500px');
    }
  }, [actualStep]);

  const handleClickAcceptedTerms = useCallback((data) => {
    if (!data.acceptTerms) {
      Notiflix.Notify.info('É necessário aceitar os termos para prosseguir');
      Modal2.Close();
      return;
    }
    // api.put('/beneficiary/agreement-acceptance-terms', {
    //   termUserUnimedId: termToTermination.userUnimedId,
    //   acceptanceTermId: termToTermination.acceptanceTermId,
    //   registerAcceptanceTermId: termToTermination.registerAcceptanceTermId,
    //   status: 'C',
    // });

    Modal2.Close();
    setAcceptedTerms(true);
    setShowSteps(true);
  }, []);

  const openModalToAcceptTerms = useCallback(
    (callback) => {
      Modal2.Generic({
        closable: true,
        children: (
          <AcceptTermsContainer>
            <AcceptTermsContent
              dangerouslySetInnerHTML={{ __html: termToTermination.term }}
            />
            <AcceptTermsFooter>
              <Form onSubmit={callback}>
                <Input
                  type="checkbox"
                  name="acceptTerms"
                  label="Eu li e concordo com os termo apresentados"
                />
                <Button
                  type="submit"
                  grayButton
                  autoWidth
                  modal
                  id="acceptedTerms"
                >
                  Aceitar
                </Button>
              </Form>
            </AcceptTermsFooter>
          </AcceptTermsContainer>
        ),
      });
    },
    [termToTermination],
  );

  const fetchInitialApiData = useCallback(async () => {
    try {
      const [dataTerms, dataTerminateCauses, dataParents] = await Promise.all([
        api.get(
          `/contract/get-term-to-termination?userUnimedId=${user.id}&protocolId=${user.serviceProtocolNumber}`,
        ),
        api.get(`/contract/list-of-termination-cause?accessType=B`),
        api.get(
          `/contract/list-of-beneficiaries-to-termination?userUnimedId=${user.id}`,
        ),
      ]);
      const { content: contentTerm } = dataTerms.data;
      const { content: contentTerminate } = dataTerminateCauses.data;
      const { content: contentParents } = dataParents.data;
      if (contentTerm && contentTerm[0]) {
        setTermToTermination(contentTerm[0]);
      }
      if (contentTerminate) {
        setOptionsSelectStep1(
          contentTerminate.reduce(
            (
              acc: { title: string; value: string }[],
              act: { name: string; id: string },
            ) => {
              acc.push({
                title: act.name,
                value: act.id,
              });
              return acc;
            },
            [],
          ),
        );
      }
      if (contentParents) {
        setParents(
          contentParents.reduce(
            (
              acc: { title: string; value: string }[],
              act: { beneficiaryName: string; userUnimedId: string },
            ) => {
              acc.push({
                title: act.beneficiaryName,
                value: `${act.userUnimedId}`,
              });
              return acc;
            },
            [],
          ),
        );
      }
      defineHeightOfSteps();
    } catch (err) {
      if (err?.response?.status === 400) {
        Modal2.Failure({
          closable: false,
          title: 'Ops...',
          text: err?.response?.data?.message,
          children: (
            <Button
              modal
              onClick={() => {
                Modal2.Close();
                history.push('/');
              }}
            >
              Ok
            </Button>
          ),
        });
      } else {
        Notiflix.Notify.failure(
          'Ops... Algo deu errado ao buscar o termo no servidor... Por favor, tente novamente.',
        );
      }
    }
  }, [defineHeightOfSteps, history, user]);

  const getAllBanks = useCallback(async () => {
    try {
      const { data } = await api.get('/generic/list-banks-active');
      const { content } = data;
      if (content) {
        const banksOfApi = content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        );
        setBankOptions({
          select: [
            {
              title: 'Selecione',
              value: '',
            },
            ...banksOfApi,
          ],
          all: content,
        });
      }
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops.. Não conseguimos carregar a lista de bancos disponíveis.',
      );
    }
  }, []);

  /**
   * @description fetch initial api data
   */
  useEffect(() => {
    fetchInitialApiData();
    getAllBanks();
    phoneDDIRef.current?.setValue('BRA +55', '');
    cellDDIRef.current?.setValue('BRA +55', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description Open required accept terms to proceed
   */
  useEffect(() => {
    if (termToTermination.term) {
      openModalToAcceptTerms(handleClickAcceptedTerms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termToTermination]);

  const sendFullForm = useCallback(async () => {
    try {
      const ddiPhone = formRefStep1.current?.getFieldValue('tel')
        ? phoneDDIRef.current?.value
        : '';
      const dddPhone = formRefStep1.current
        ?.getFieldValue('tel')
        .substring(0, 2);
      const phone = formRefStep1.current?.getFieldValue('tel').substring(2);
      const ddiCellPhone = formRefStep1.current?.getFieldValue('cell')
        ? cellDDIRef.current?.value
        : '';
      const dddCellPhone = formRefStep1.current
        ?.getFieldValue('cell')
        .substring(0, 2);
      const cellphone = formRefStep1.current
        ?.getFieldValue('cell')
        .substring(2);

      const dataToSendStep1 = {
        requestUserUnimedId: [
          ...Object.entries(formRefStep1.current?.getData().parent)
            .filter((item) => item[1])
            .map((item) => {
              return Number.parseInt(item[0], 10);
            }),
        ],
        terminationMotiveId: causeOfTerminateSelectRef.current?.value,
        email: formRefStep1.current?.getFieldValue('email'),
        ddiPhone,
        dddPhone,
        phone,
        ddiCellPhone,
        dddCellPhone,
        cellphone,
      };

      const form3 = formRefStep3.current?.getData();
      const dataToSendStep3 = {
        ...form3,
        bankCode: bankRef.current?.value,
      };

      const fullForm = {
        ...dataToSendStep1,
        newHolderId: newOwner,
        ...dataToSendStep3,
      };

      Notiflix.Loading.circle();
      const { data } = await api.post(
        `/contract/save-termination-request`,
        fullForm,
      );
      const { content } = data;

      Modal2.Success({
        closable: true,
        title: 'Perfeito!',
        subtitle: 'Solicitação enviada com sucesso!',
        text: `O protocolo gerado foi: ${content[0].protocolId}. Nº Sequência da Solicitação: ${content[0].nrSeqSolicitação}.`,
        children: (
          <>
            <Button
              onClick={() => {
                Modal2.Close();
                history.push('/');
              }}
              modal
            >
              Ok
            </Button>
          </>
        ),
      });
      // history.push('/');
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Nâo conseguimos enviar a solicitação para o servidor. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Loading.remove();
    }
  }, [history, newOwner]);

  const handleSubmitStep2 = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-select-step2-owner');
      if (
        termToTermination.financialData === 'S' ||
        termToTermination.financialData === 'O'
      ) {
        setActualStep(3);
        // getDataStep3();
      } else {
        sendFullForm();
      }
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops...Não conseguimos solicitar a alteração do titular no momento. Tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-select-step2-owner');
    }
  }, [termToTermination, sendFullForm]);

  const getAllParentsExeptSelectedInCheckbox = useCallback(
    (dataParents) => {
      const all = parents;

      const filtered = all.filter((item) => {
        return !dataParents[item.value];
      });

      const valueToReturn =
        filtered.length > 0
          ? filtered
          : [
              {
                title: 'Não sobrou nenhum beneficiário',
                value: '',
              },
            ];
      return valueToReturn;
    },
    [parents],
  );

  const hasOptions = useCallback((options) => {
    setNewOwner(options[0].value);
    return options[0].value !== '';
  }, []);

  const iWasIncluded = useCallback(
    (parent) => {
      const includeMe = Object.entries(parent).filter(
        (item) => item[1] && item[0].toString() === user.id.toString(),
      );
      return includeMe.length === 1;
    },
    [user],
  );

  const handleSubmitStep1 = useCallback(
    async (data) => {
      try {
        causeOfTerminateSelectRef.current?.setError('');
        if (!causeOfTerminateSelectRef.current?.value) {
          causeOfTerminateSelectRef.current?.setError(
            'Selecione uma das causas de rescisão.',
          );
        }
        formRefStep1.current?.setErrors({});
        setCheckBoxGroupError('');

        const tel = formRefStep1.current?.getFieldValue('tel');
        const cell = formRefStep1.current?.getFieldValue('cell');
        // eslint-disable-next-line prefer-const
        let others = {};
        if (!tel && !cell) {
          others = {
            tel: Yup.string().required(
              'É necessário informar um telefone e/ou celular',
            ),
            cell: Yup.string().required(
              'É necessário informar um telefone e/ou celular',
            ),
          };
        }
        if (tel && !cell) {
          others = {
            tel: Yup.number()
              .typeError('Informe somente números')
              .min(111111111, 'Informe um número válido: (99) 9999-9999'),
          };
          phoneDDIRef.current?.setError(
            !phoneDDIRef.current.value ? 'Infome o DDI do seu país.' : '',
          );
        }
        if (!tel && cell) {
          others = {
            cell: Yup.number()
              .typeError('Informe somente números')
              .min(1111111111, 'Informe um número válido: (99) 9 9999-9999'),
          };
          cellDDIRef.current?.setError(
            !cellDDIRef.current.value ? 'Infome o DDI do seu país.' : '',
          );
        }
        if (tel && cell) {
          others = {
            tel: Yup.number()
              .typeError('Informe somente números')
              .min(111111111, 'Informe um número válido: (99) 9999-9999'),
            cell: Yup.number()
              .typeError('Informe somente números')
              .min(1111111111, 'Informe um número válido: (99) 9 9999-9999'),
          };
          phoneDDIRef.current?.setError(
            !phoneDDIRef.current.value ? 'Infome o DDI do seu país.' : '',
          );
          cellDDIRef.current?.setError(
            !cellDDIRef.current.value ? 'Infome o DDI do seu país.' : '',
          );
        }

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Informe um email válido')
            .required('É necessário informar seu email'),
          parent: Yup.array().test(
            'Tudo ok',
            'É necessário selecionar ao menos 1 (um) beneficiário.',
            (value) => {
              if (!value) return false;
              return Object.entries(value).filter((item) => item[1]).length > 0;
            },
          ),
          ...others,
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!causeOfTerminateSelectRef.current?.value) {
          return;
        }

        if (!phoneDDIRef.current?.value && tel) {
          return;
        }

        if (!cellDDIRef.current?.value && cell) {
          return;
        }

        /**
         * use cases
         *
         *    >if the user selected himself
         *      >actionRefundBeneficiary is => 2
         *        >if any user remains to pass ownership
         *          >show step 2 to select new owner
         *            >financialData !== "N"
         *              >show step 3
         *            >financialData === "N"
         *              >send full form
         *        >if there is no user left to pass ownership
         *          >financialData !== "N"
         *            >show step 3
         *          >financialData === "N"
         *            >send full form
         *      >actionRefundBeneficiary is => 3
         *        >select all depentend
         *            >financialData !== "N"
         *              >show step 3
         *            >financialData === "N"
         *              >send full form
         *    >if the user not selected himself
         *       >financialData !== "N"
         *        >show step 3
         *       >financialData === "N"
         *        >send full form
         */
        if (
          iWasIncluded(data.parent) &&
          termToTermination.actionRefundBeneficiary === '3'
        ) {
          Modal2.Warning({
            closable: true,
            autoWidth: true,
            title: 'Importante',
            subtitle:
              'Ao rescindir o titular do contrato todos os seus dependentes serão rescindidos automaticamente.',
            text: 'Deseja prosseguir?',
            children: (
              <>
                <Button modal secondary onClick={() => Modal2.Close()}>
                  Cancelar
                </Button>
                <Button
                  style={{ marginTop: '8px' }}
                  modal
                  onClick={() => {
                    Modal2.Close();
                    parents.forEach((parent) => {
                      formRefStep1.current?.setFieldValue(
                        `parent.${parent.value}`,
                        true,
                      );
                    });
                    setNewOwner('');
                    if (
                      termToTermination.financialData === 'S' ||
                      termToTermination.financialData === 'O'
                    ) {
                      setActualStep(3);
                    } else {
                      sendFullForm();
                    }
                  }}
                >
                  Sim
                </Button>
              </>
            ),
          });
        } else if (iWasIncluded(data.parent)) {
          const filteredParents = getAllParentsExeptSelectedInCheckbox(
            data.parent,
          );
          if (hasOptions(filteredParents)) {
            setActualStep(2);
            setParentsOptions(filteredParents);
          } else if (
            termToTermination.financialData === 'S' ||
            termToTermination.financialData === 'O'
          ) {
            setActualStep(3);
          } else {
            sendFullForm();
          }
        } else if (
          termToTermination.financialData === 'S' ||
          termToTermination.financialData === 'O'
        ) {
          setNewOwner('');
          setActualStep(3);
        } else {
          setNewOwner('');
          sendFullForm();
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefStep1.current?.setErrors(errors);
          setCheckBoxGroupError(
            formRefStep1.current?.getFieldError('parent') ?? '',
          );
        }
      }
    },
    [
      iWasIncluded,
      termToTermination,
      getAllParentsExeptSelectedInCheckbox,
      hasOptions,
      sendFullForm,
      parents,
    ],
  );

  const handleSubmitStep3 = useCallback(
    async (data) => {
      try {
        formRefStep3.current?.setErrors({});
        bankRef.current?.setError('');

        if (
          termToTermination.financialData === 'O' ||
          formRefStep3.current?.getFieldValue('bankAgencyCode') ||
          formRefStep3.current?.getFieldValue('digitAgency') ||
          formRefStep3.current?.getFieldValue('accountNumber') ||
          formRefStep3.current?.getFieldValue('digitAccount') ||
          formRefStep3.current?.getFieldValue('accountHolder')
        ) {
          const schema = Yup.object().shape({
            bankAgencyCode: Yup.string().required(
              'É necessário informar sua agência',
            ),
            digitAgency: Yup.string()
              .required('É necessário informar o digito da sua agência')
              .max(1, 'Informe somente um caracter.'),
            accountNumber: Yup.string().required(
              'É necessário informar sua conta',
            ),
            digitAccount: Yup.string()
              .required('É necessário informar o digito da sua conta')
              .max(1, 'Informe somente um caracter.'),
            cpfCnpj: Yup.string().required(
              'É necessário informar o seu CPF ou CNPJ',
            ),
            accountHolder: Yup.string().required(
              'É necessário informar o correntista',
            ),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
          if (!bankRef.current?.value) {
            bankRef.current?.setError('É necessário informar seu banco');
            return;
          }
        }

        sendFullForm();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefStep3.current?.setErrors(errors);
        }
      }
    },
    [sendFullForm, termToTermination],
  );

  useEffect(() => {
    defineHeightOfSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualStep]);

  useCallback(() => {
    window.addEventListener('resize', defineHeightOfSteps);

    return () => {
      window.removeEventListener('resize', defineHeightOfSteps);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper gridTemplateRows="auto auto 1fr">
      <Title>Rescindir Contrato</Title>
      {(!acceptedTerms || !showSteps) && (
        <PendingTermAccept
          onClick={() => {
            openModalToAcceptTerms(handleClickAcceptedTerms);
          }}
        >
          É necessário aceitar os termos para prosseguir. Clique aqui para
          continuar.
        </PendingTermAccept>
      )}
      <Steps defineHeight={stepHeight}>
        <Step1
          show={actualStep === 1 && acceptedTerms && showSteps}
          ref={step1Ref}
        >
          <FormWrapper formLook onSubmit={handleSubmitStep1} ref={formRefStep1}>
            <Select
              name="causeOfTerminate"
              ref={causeOfTerminateSelectRef}
              title="Causa da rescisão:"
              options={optionsSelectStep1}
              className="notiflix-select-step1"
              liveReload
              formLook
              widthContainerDesktop="50%"
            />
            <Input
              title="E-mail:"
              formLook
              name="email"
              widthContainerDesktop="50%"
            />
            <Select
              name="telDDI"
              title="DDI - Telefone:"
              ref={phoneDDIRef}
              inputMode
              options={getAllDDIForSelectOptions()}
              widthContainerDesktop="10%"
              formLook
            />
            <Input
              title="DDD/Telefone:"
              formLook
              name="tel"
              masks={['(99) 9999-9999', '(99) 9 9999-9999']}
              widthContainerDesktop="30%"
            />
            <Select
              name="cellDDI"
              title="DDI - Celular:"
              ref={cellDDIRef}
              inputMode
              options={getAllDDIForSelectOptions()}
              widthContainerDesktop="10%"
              formLook
            />
            <Input
              title="DDD/Celular:"
              formLook
              name="cell"
              masks={['(99) 9999-9999', '(99) 9 9999-9999']}
              widthContainerDesktop="30%"
            />
            <CheckboxGroup error={checkBoxGroupError}>
              <span>Selecione os beneficiários que serão rescindidos:</span>
              {parents.map((parent) => {
                return (
                  <Checkbox
                    name={`parent.${parent.value}`}
                    value={parent.title}
                    key={parent.value}
                    defaultChecked={false}
                  />
                );
              })}
              {checkBoxGroupError && (
                <Error>
                  <span>{checkBoxGroupError}</span>
                </Error>
              )}
            </CheckboxGroup>
            <Button type="submit" orangeButton autoWidth formLook>
              Enviar
            </Button>
          </FormWrapper>
        </Step1>
        <Step2
          show={actualStep === 2 && acceptedTerms && showSteps}
          ref={step2Ref}
        >
          <TitleIntern themeColor={colors.palet.institutional6}>
            Transferência de Titularidade
          </TitleIntern>
          <FormWrapper formLook onSubmit={handleSubmitStep2} ref={formRefStep2}>
            <p>{termToTermination.changeBeneficiaryMessage}</p>
            <Select
              title="Novo titular:"
              name="newOwner"
              options={parentsOptions}
              formLook
              changeCallback={(option) => setNewOwner(option)}
              liveReload
            />
            <Button
              formLook
              autoWidth
              secondary
              onClick={() => setActualStep(1)}
            >
              Voltar
            </Button>
            <Button
              type="submit"
              formLook
              grayButton
              autoWidth
              className="notiflix-select-step2-owner"
            >
              Confirmar
            </Button>
          </FormWrapper>
        </Step2>
        <Step3
          show={actualStep === 3 && acceptedTerms && showSteps}
          className="notiflix-step3-container"
          ref={step3Ref}
        >
          <TitleIntern themeColor={colors.palet.institutional6}>
            Dados Bancários
          </TitleIntern>
          <FormWrapper formLook onSubmit={handleSubmitStep3} ref={formRefStep3}>
            <Select
              name="bank"
              title="Banco:"
              widthContainerDesktop="30%"
              formLook
              options={bankOptions.select}
              ref={bankRef}
              // changeCallback={handleChangeBankSelected}
              liveReload
            />
            <Input
              name="bankAgencyCode"
              widthContainerDesktop="25%"
              formLook
              title="Agência:"
              masks={['9999999999999']}
            />
            <Input
              name="digitAgency"
              widthContainerDesktop="10%"
              formLook
              title="Dig. Agência:"
              masks={['9999']}
            />
            <Input
              name="accountNumber"
              widthContainerDesktop="25%"
              formLook
              masks={['9999999999999']}
              title="Conta:"
            />
            <Input
              name="digitAccount"
              widthContainerDesktop="10%"
              formLook
              masks={['9999']}
              title="Dig. Conta:"
            />
            <Input
              name="cpfCnpj"
              widthContainerDesktop="50%"
              masks={['999.999.999-99', '99.999.999/9999-99']}
              formLook
              title="CPF ou CNPJ:"
            />
            <Input
              name="accountHolder"
              widthContainerDesktop="50%"
              formLook
              title="Correntista:"
            />
            <div>
              <Button
                formLook
                autoWidth
                secondary
                onClick={() => setActualStep(1)}
              >
                Voltar
              </Button>
              <Button
                type="submit"
                formLook
                grayButton
                autoWidth
                className="notiflix-select-step3-owner"
              >
                Confirmar
              </Button>
            </div>
          </FormWrapper>
        </Step3>
      </Steps>
    </PageWrapper>
  );
};

export default TerminateBeneficiary;
