import Notiflix from 'notiflix';
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Button from '../../components/Button';
import PageWrapper from '../../components/PageWrapper';
import { Column } from '../../components/Table';
import { HeaderMobile } from '../../components/Table/styles';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api/api';
import { PartialTitle, Table, ContainerTitleIntern } from './styles';

const PartialTemporaryCover: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [partial, setPartial] = useState([] as any[]);
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const getCPT = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const transformToBolder = (dataFromContent: any) => {
        const [sday, smonth, syear] = dataFromContent.startDate.split('/');
        const [fday, fmonth, fyear] = dataFromContent.finalDate.split('/');
        return {
          ...dataFromContent,
          toStartDateBolder: new Date(syear, smonth, sday) > new Date(),
          toFinalDateBolder: new Date(fyear, fmonth, fday) > new Date(),
        };
      };

      const { data } = await api.get(
        `/beneficiary/list-temporary-partial-coverage?userUnimedId=${
          id || user.id
        }`,
      );
      const { content } = data;

      setPartial(content.map(transformToBolder));
    } catch (error) {
      if (error?.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dados do servidor. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, [id, user.id]);

  useEffect(() => {
    getCPT();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper
        gridTemplateColumns="1fr 8fr 1fr"
        gridTemplateRows="auto auto auto 1fr"
      >
        <PartialTitle>Consultar Cobertura Parcial Temporária</PartialTitle>

        <Table
          items={partial}
          key="partial-table"
          className="notiflix-table-loading"
          columnResizeMode="fit"
          id="partial-table"
        >
          <Column
            field="cpt"
            header="Classificação"
            sortable
            body={(data) => {
              return (
                <>
                  <HeaderMobile>Classificação</HeaderMobile>
                  {(data.toFinalDateBolder && <strong>{data.cpt}</strong>) ||
                    data.cpt}
                </>
              );
            }}
          />
          <Column
            field="startDate"
            header="Data de Vigência"
            className="date"
            body={(data) => {
              return (
                <>
                  <HeaderMobile>Data de Vigência</HeaderMobile>
                  {(data.toFinalDateBolder && (
                    <strong>{data.startDate}</strong>
                  )) ||
                    data.startDate}
                </>
              );
            }}
          />
          <Column
            field="finalDate"
            header="Data fim de vigência"
            className="date"
            body={(data) => {
              return (
                <>
                  <HeaderMobile>Data Fim de Vigência</HeaderMobile>
                  {(data.toFinalDateBolder && (
                    <strong>{data.finalDate}</strong>
                  )) ||
                    data.finalDate}
                </>
              );
            }}
          />
        </Table>
        <ContainerTitleIntern formLook onSubmit={() => history.goBack()}>
          <Button autoWidth secondary formLook type="submit">
            Voltar
          </Button>
        </ContainerTitleIntern>
      </PageWrapper>
    </>
  );
};

export default PartialTemporaryCover;
