import styled from 'styled-components';
import TableComponent from '../../components/Table';

export const Container = styled.section`
  grid-column: 2/10;
  @media (max-width: 768px) {
    grid-column: 1;
    margin: 0px;
    padding: 20px;
  }

  font-family: 'Unimed-Sans-Book';
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & form {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
`;

export const FormContainer = styled.div`
  display: grid;
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 32px;

  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const ModalButton = styled.button`
  all: unset;
  cursor: pointer;
  width: auto;
  border-radius: 6px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 12px;
  background: ${(props) => props.theme.colors.palet.institutional12};
  color: ${(props) => props.theme.colors.text.black};
  gap: 4px;
  font-weight: 600;
  svg {
    color: ${(props) => props.theme.colors.text.white};
  }
  &:hover {
    background: ${(props) => props.theme.colors.palet.institutional4};
    color: #fff;
  }
`;

export const ModalContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  min-height: 400px;
  text-align: center;
`;

export const DateSelectRow = styled.div`
  display: grid;
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  grid-template-columns: 1fr 3fr;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const RowSelectHours = styled.div`
  display: grid;
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  grid-template-columns: 1fr;
`;

export const ModalConfirmContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  text-align: center;
`;

export const ModalConfirmButtonsContainer = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

export const FieldContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 760px) {
    width: 60%;
  }
`;

export const EditField = styled.input`
  all: unset;
  border: 1px solid ${(props) => props.theme.colors.text.secondary};
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  flex: 1;
  padding: 15px 20px;
  position: relative;
  display: flex;
  background: white;
  color: ${(props) => props.theme.colors.palet.institutional11};
  text-align: start;
  font-family: 'Unimed-Sans-Book';
  font-size: 1rem;
  font-weight: 300;
  width: 100%;
`;

export const ModalText = styled.p`
  color: ${(props) => props.theme.colors.palet.institutional11};
  text-align: center;
  font-family: 'Unimed-Sans-Book';
  font-size: 1rem;
  font-weight: 300;
  width: 100%;
`;
export const ModalSubtitle = styled.h3`
  font-weight: bold;
  color: ${(props) => props.theme.colors.palet.institutional11};
  text-align: center;
  font-family: 'Unimed-Sans-Book';
  font-size: 1.8rem;
  margin-bottom: 8px;
  width: 100%;
`;

export const FooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 14px;
  background: #fff;
  padding: 14px;
  border-radius: 10px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
`;
