/* eslint-disable no-new */
import React, { useEffect, useRef } from 'react';

interface Props {
  code: string;
}

declare global {
  interface Window {
    QRCode: any;
  }
}

const QRCodeComponent: React.FC<Props> = ({ code }) => {
  const divRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const generateQRCODE = (cardNumber: string) => {
      const { QRCode } = window;
      const qrCodeElement = divRef.current;
      if (qrCodeElement) {
        qrCodeElement.innerHTML = '';
        new QRCode(qrCodeElement, {
          text: cardNumber,
          width: 500,
          height: 500,
          colorDark: '#004d4a',
          colorLight: '#b0d249',
          correctLevel: QRCode.CorrectLevel.H,
        });
      }
    };
    generateQRCODE(code);
  }, [code]);
  return <div ref={divRef} />;
};

export default QRCodeComponent;
