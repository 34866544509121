import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Notiflix from 'notiflix';
import { useHistory } from 'react-router-dom';
import Title from '../../../components/Title';
import { useTheme } from '../../../hooks/theme';
import {
  Col,
  Container,
  Content,
  Message,
  Photo,
  Row,
  TitlePeriod,
  ButtonPhoto,
  ContentGrid,
  ButtonGoBack,
  Pdf,
} from './styles';
import PageWrapper from '../../../components/PageWrapper';
import api from '../../../services/api/api';
import SelectUnform from '../../../components/SelectUnform';
import Modal2 from '../../../components/Modal2';
import Button from '../../../components/Button';
import FormWrapper from '../../../components/FormWrapper';
import { downloadFileOfBlob } from '../../../utils/geterateFileURL';

interface PeriodProp {
  id: string;
  name: string;
}
interface ExamPeriodProp {
  examId: number;
  dtExame: string;
  arqExame: string;
  type: string;
}

const Exams: React.FC = () => {
  const { theme } = useTheme();
  const defaultColor = theme.colors.palet.institutional8;
  const history = useHistory();

  const [period, setPeriod] = useState<PeriodProp[]>();
  const [periodId, setPeriodId] = useState('');
  const [examPeriod, setExamPeriod] = useState<ExamPeriodProp[]>();
  const [titlePeriod, setTitlePeriod] = useState('');
  const [exam, setExam] = useState('');
  const [viewExam, setViewExam] = useState('');
  const [loadingExam, setLoadingExam] = useState(false);
  const [loadingPeriod, setLoadingPeriod] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [checkExamId, setCheckExamId] = useState(0);

  const getExams = useCallback(async (examId) => {
    try {
      setLoadingExam(true);
      Notiflix.Block.circle('.notiflix-loading-exam', 'Carregando', {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
      });
      const { data } = await api.get(
        `/first-aid/curative-exam-code?examId=${examId}`,
      );

      if (data.content.data[0].type === 'application/pdf') {
        setViewExam('');
        const archive = await fetch(
          `data:application/pdf;base64,${data.content.data[0].arqExame}`,
        );
        const myBlob = await archive.blob();
        const linkSource = window.URL.createObjectURL(myBlob);
        window.open(linkSource, '_blank');
      } else {
        setViewExam(data.content.data[0].arqExame);
      }

      setLoadingExam(false);
    } catch (err) {
      if (err?.response?.status === 400) {
        Notiflix.Notify.info(err.response.data?.message);
      } else {
        Notiflix.Notify.failure(
          'Não conseguimos buscar o exame selecionado. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-loading-exam');
    }
  }, []);

  const getExamsPeriod = useCallback(async (periodExamId) => {
    try {
      setLoadingExam(true);
      Notiflix.Block.circle('.notiflix-loading-exam', 'Carregando', {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
      });
      const { data } = await api.get(
        `/first-aid/curative-exam?periodDate=${periodExamId}`,
      );
      setExamPeriod(data.content.data);
      setViewExam(data.content.data[0].arqExame);
      setLoadingExam(false);
    } catch (err) {
      if (err?.response?.status === 400) {
        Notiflix.Notify.info(err.response.data?.message);
      } else {
        Notiflix.Notify.failure(
          'Não conseguimos buscar as exames para o período selecionado. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-loading-exam');
    }
  }, []);

  const getPeriod = useCallback(async () => {
    try {
      // setLoadingPeriod(true);
      Notiflix.Block.circle('.notiflix-loading-exam', 'Carregando', {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
      });
      const { data } = await api.get(`/first-aid/curative-exam-period`);
      const { content } = data;
      setPeriod(
        content.data.reduce(
          (
            acc: { id: string; name: string }[],
            act: {
              dtPeriodo: string;
              dsPeriodo: string;
            },
          ) => {
            acc.push({
              id: String(act.dtPeriodo),
              name: act.dsPeriodo,
            });
            return acc;
          },
          [],
        ),
      );
      // setLoadingPeriod(false);
    } catch (err) {
      if (err.response) {
        Modal2.Failure({
          closable: true,
          autoWidth: true,
          title: 'Ooops...',
          text: err.response.data.message,
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });
      }
    } finally {
      Notiflix.Block.remove('.notiflix-loading-exam');
    }
  }, []);

  const handleOpenModal = useCallback(() => {
    if (period) {
      Modal2.Generic({
        autoWidth: true,
        title: 'Selecione o período',
        children: (
          <Col className="modalPeriod">
            <FormWrapper onSubmit={() => console.log()}>
              <SelectUnform
                name="periodExam"
                options={period}
                formLook
                widthContainerDesktop="33.33%"
                callbackOnChange={(name, id) => {
                  setPeriodId(id);
                  setTitlePeriod(name);
                  setDisabled(false);
                }}
              />
              <Row className="buttonModal">
                <Button
                  modal
                  grayButton
                  onClick={() => {
                    Modal2.Close();
                  }}
                >
                  Fechar
                </Button>
                <Button
                  modal
                  disabled={disabled}
                  onClick={() => {
                    getExamsPeriod(periodId);
                    Modal2.Close();
                  }}
                >
                  Procurar
                </Button>
              </Row>
            </FormWrapper>
          </Col>
        ),
      });
    }
  }, [getExamsPeriod, periodId, period, disabled]);

  useEffect(() => {
    getPeriod();
  }, [getPeriod]);

  useEffect(() => {
    if (period) {
      handleOpenModal();
    }
  }, [handleOpenModal, period]);

  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <Container>
        <Title themeColor={theme.colors.palet.institutional2}>Exames</Title>
        <ButtonGoBack onClick={() => history.push('/ambulatorio-curativos')}>
          Voltar
        </ButtonGoBack>
        <Content className="notiflix-loading-exam">
          {!examPeriod && !loadingPeriod && (
            <Message>Você não possui exames a serem exibidos</Message>
          )}
          <ContentGrid>
            {examPeriod && (
              <>
                <Col>
                  <TitlePeriod>{titlePeriod}</TitlePeriod>
                  {examPeriod?.map((item, i) => {
                    return (
                      <Row
                        key={item.examId}
                        className={
                          item.examId === checkExamId
                            ? 'rowTable rowSelected'
                            : 'rowTable'
                        }
                      >
                        <span>{item.dtExame}</span>
                        <ButtonPhoto
                          onClick={() => {
                            getExams(item.examId);
                            setCheckExamId(item.examId);
                          }}
                        >
                          {item.examId === checkExamId
                            ? 'Visualizando'
                            : 'Visualizar'}
                        </ButtonPhoto>
                      </Row>
                    );
                  })}
                </Col>
                {viewExam && !loadingExam && (
                  <Photo src={`data:image/png;base64,${viewExam}`} alt="" />
                )}
              </>
            )}
          </ContentGrid>
          <Row className="center rowButtonSearch">
            <Button
              modal
              onClick={() => {
                handleOpenModal();
                setDisabled(true);
              }}
            >
              Pesquisar novo período
            </Button>
          </Row>
          {/* <Message>Você não possui fotos a serem exibidas</Message> */}
        </Content>
      </Container>
    </PageWrapper>
  );
};

export default Exams;
