/* eslint-disable max-len */
import React, { memo } from 'react';

const OmbudsmanSvg: React.FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 11.5199C15 11.9935 14.8359 12.4483 14.5442 12.7834C14.2526 13.1185 13.8567 13.3071 13.4444 13.3071H4.11111L1 16.8816V2.58363C1 2.11001 1.16411 1.65516 1.45578 1.32005C1.74744 0.98494 2.14333 0.796387 2.55556 0.796387H13.4444C13.8567 0.796387 14.2526 0.98494 14.5442 1.32005C14.8359 1.65516 15 2.11001 15 2.58363V11.5199Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default memo(OmbudsmanSvg);
