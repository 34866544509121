/* eslint-disable max-len */
import React, { memo } from 'react';

const Sucess: React.FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3844 13.2943V14.6839C16.3848 14.8131 16.3584 14.9405 16.3069 15.0587C16.2549 15.1772 16.1788 15.2833 16.0836 15.3704C15.9885 15.4575 15.8761 15.5237 15.7536 15.5654C15.6311 15.6066 15.5011 15.6219 15.3726 15.6103C13.9445 15.4556 12.5725 14.9683 11.3672 14.1883C10.2459 13.4773 9.29491 12.5282 8.58248 11.4091C7.79812 10.2006 7.30986 8.82488 7.15763 7.39313C7.14603 7.26482 7.16135 7.13605 7.20266 7.01423C7.2435 6.89195 7.30987 6.7803 7.39666 6.68535C7.48345 6.59039 7.58926 6.51445 7.70714 6.46257C7.82503 6.41023 7.9522 6.38335 8.08123 6.38335H9.47359C9.69869 6.38103 9.91729 6.46071 10.0881 6.60754C10.2589 6.75392 10.3703 6.95724 10.4018 7.18004C10.4608 7.62472 10.5694 8.06154 10.7267 8.48167C10.7894 8.64749 10.8028 8.82767 10.7657 9.0009C10.7286 9.17414 10.6427 9.33302 10.5179 9.45902L9.92843 10.0473C10.5893 11.2071 11.551 12.1669 12.7132 12.8265L13.3026 12.2382C13.4288 12.1136 13.588 12.0279 13.7616 11.9909C13.9352 11.9538 14.1157 11.9672 14.2819 12.0298C14.7029 12.1868 15.1405 12.2952 15.5861 12.354C15.8116 12.386 16.0172 12.499 16.1648 12.6727C16.312 12.8459 16.3899 13.0673 16.3844 13.2943Z"
        fill="white"
      />
      <path
        d="M21 10.4445C21.0033 11.9111 20.6611 13.3578 20 14.6667C19.2155 16.2355 18.0111 17.5545 16.5189 18.4767C15.0278 19.3989 13.3089 19.8878 11.5555 19.8889C10.0889 19.8922 8.64223 19.55 7.33334 18.8889L1 21L3.1111 14.6667C2.44999 13.3578 2.10778 11.9111 2.11111 10.4445C2.11222 8.69113 2.6011 6.97226 3.52333 5.48115C4.44555 3.98893 5.76445 2.78449 7.33334 2.00005C8.64223 1.33894 10.0889 0.996691 11.5555 1.00002H12.1111C14.4267 1.1278 16.6144 2.10559 18.2544 3.74559C19.8944 5.38558 20.8722 7.57335 21 9.8889V10.4445Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default memo(Sucess);
