import styled from 'styled-components';
import { transparentize } from 'polished';

import { WhatsappFirstAidSvg } from '../../../components/Svg';

export const WhatsappFirstAidIcon = styled(WhatsappFirstAidSvg)`
  width: 30px;
  height: 30px;
`;

export const Container = styled.div`
  margin-bottom: 30px;
  padding: 0 2em;
  @media (max-width: 768px) {
    grid-column: 1;
  }
  @media (min-width: 769px) and (max-width: 1600px) {
    grid-column: 1/4;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 8px;
  row-gap: 3em;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  padding: 3em 2em;
  margin: 5em 0;
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 5px 20px
    ${(props) => transparentize(0.4, props.theme.colors.text.lightGrayToBlack)};
  border-radius: 1em;
  @media (max-width: 768px) {
    row-gap: 8px;
    border-radius: 0;
    padding: 1.5em;
    align-items: flex-start;
  }
  @media (max-width: 320px) {
    grid-template-columns: 1fr;
  }

  p {
    font-family: 'Unimed-Sans-Book';
    font-size: 1.2em;
    font-weight: bold;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
    padding: 0.3em 2em;
    gap: 0.5em;

    img {
      width: 30px !important;
    }
  }

  .center {
    text-align: center;
  }
`;

export const ContentSchedule = styled.div`
  height: 100%;
  text-align: center;
`;

export const ScheduleTitle = styled.p`
  font-family: 'Unimed-Sans-SemiBold' !important;
  font-size: 2em !important;
  margin-bottom: 1em;
  color: ${(props) =>
    props.theme.title === 'light'
      ? props.theme.colors.palet.institutional11
      : props.theme.colors.text.white};
`;
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
  flex-wrap: wrap;
  color: ${(props) =>
    props.theme.title === 'light'
      ? props.theme.colors.text.primary
      : props.theme.colors.text.white};

  .buttonWhatsapp a {
    padding: 0.3em 0 !important;
  }
`;

export const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start !important;
  gap: 4em;
`;

export const Message = styled.p`
  font-family: 'Unimed-Sans-Book';
  color: #5b5c65;
  text-align: center;
  color: ${(props) =>
    props.theme.title === 'light'
      ? props.theme.colors.text.primary
      : props.theme.colors.text.white};
`;

export const Schedule = styled.span`
  width: 13em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5em;
  font-size: 1em;
  gap: 5px;
  color: ${(props) =>
    props.theme.title === 'light'
      ? props.theme.colors.text.primary
      : props.theme.colors.text.white};
  font-family: 'Unimed-Sans-Book';
  box-shadow: 0px 5px 20px
    ${(props) => transparentize(0.4, props.theme.colors.text.lightGrayToBlack)};
  border-radius: 1em;

  .dot {
    font-size: 5em;
    height: 50px;
    margin-top: -45px;
    color: ${(props) => props.theme.colors.palet.institutional};
  }

  .hour {
    text-align: left;
  }

  .bold {
    font-weight: bold;
  }
`;

export const ButtonGoBack = styled.button`
  float: right;
  border: none;
  padding: 0;
  background: transparent;
  font-family: 'Unimed-Sans-Book';
  color: ${(props) =>
    props.theme.title === 'light'
      ? props.theme.colors.text.primary
      : props.theme.colors.text.white};
  &:hover {
    box-shadow: none;
    border: none;
  }
`;
