import React from 'react';
import { AuthProvider } from './auth';
import { ThemeProvider } from './theme';
import { ReleasesProvider } from './releasesAndTerms';
import { ContractProvider } from './contract';
import PWACacheProvider from './PWACache';
import { OnlineModeProvider } from './useOnlineMode';

const Hooks: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <OnlineModeProvider>
        <PWACacheProvider>
          <ContractProvider>
            <ReleasesProvider>
              <ThemeProvider>{children}</ThemeProvider>
            </ReleasesProvider>
          </ContractProvider>
        </PWACacheProvider>
      </OnlineModeProvider>
    </AuthProvider>
  );
};

export default Hooks;
