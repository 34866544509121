import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import Input from '../../../../components/Input';
import Select, {
  OptionProps,
  SelectHandles,
} from '../../../../components/Select';
import DatePicker from '../../../../components/DatePicker';
import { FormWrapper } from '../../styles';
import { NoteText } from './styles';
import InputFile from '../../../../components/InputFile';
import getValidationErrors from '../../../../utils/getValidationErrors';
import {
  isValidDate,
  oneYearBefore,
  oneYearLater,
} from '../../../../utils/date';
import { RefundFormItem as RefundFormItemType } from '../../__mocks__/formList';

export interface HandleRefundFormItem {
  validForm: () => Promise<boolean>;
  reset: () => void;
  validFiles: () => boolean;
  getFiles: () => { files: Array<any> };
  getDates: () => { document: Date | null };
  formData: any;
}

type Props = {
  item: RefundFormItemType;
  index: number;
  type: string;
  documentTypeList: Array<OptionProps>;
  documentAttachmentsList: Array<{ name: string; required?: number }>;
  onRemoveFormItem: (index: number) => void;
};

const RefundFormItem: React.ForwardRefRenderFunction<
  HandleRefundFormItem,
  Props
> = (
  { item, index, documentTypeList, documentAttachmentsList, onRemoveFormItem },
  ref,
) => {
  const formRef = useRef<FormHandles | null>(null);
  const selectRef = useRef<SelectHandles>(null);
  const attachmentsListRef = useRef<any>([]);
  const [attachmentOptionList, setAttachmentOptionList] = useState<
    Array<{ name: string; required?: number }>
  >(documentAttachmentsList);
  const [documentType, setDocumentType] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [documentDateState, setDocumentDateState] = useState<{
    value: Date | null;
    error: string;
  }>({
    value: null,
    error: '',
  });
  const [errorDocumentType, setErrorDocumentType] = useState('');

  const onChangeDocumentType = async (option: string) => {
    if (option === '') {
      setDocumentType('');
      return;
    }

    setErrorDocumentType('');
    try {
      setDocumentType(option);
      setDocumentName(
        documentTypeList.filter((_item) => _item.value === option)[0].title,
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckValid = useCallback(async () => {
    try {
      const schema = Yup.object().shape({
        documentNumber: Yup.string().required(
          'Informe seu número de documento',
        ),
        documentLocale: Yup.string()
          .required('Informe o CPF/CNPJ do local do serviço')
          .min(11, 'Mínimo de 11 caracteres.')
          .max(14, 'Máximo de 14 caracteres.'),
      });

      if (
        !documentDateState.value ||
        !isValidDate(String(documentDateState.value).toString())
      ) {
        setDocumentDateState(() => ({
          value: documentDateState.value,
          error: 'Informe uma data do documento válida',
        }));

        return false;
      }

      if (documentType === '') {
        setErrorDocumentType('Informe um tipo de documento');

        return false;
      }

      setErrorDocumentType('');
      setDocumentDateState(() => ({
        value: documentDateState.value,
        error: '',
      }));

      const data = formRef.current?.getData() ?? {};
      await schema.validate(data, {
        abortEarly: false,
      });
      return true;
    } catch (err) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        const scrollToFirstError = formRef.current?.getFieldRef(
          Object.keys(errors)[0],
        );
        if (scrollToFirstError) {
          const el: HTMLElement = scrollToFirstError.current;
          smoothScrollIntoView(el, { behavior: 'smooth' });
        }
      }
      return false;
    }
  }, [documentDateState, documentType]);

  function checkIfDuplicateExists<T>(arr: T[]): boolean {
    return new Set(arr).size !== arr.length;
  }

  const validFiles = useCallback(() => {
    let uniqueFiles = true;

    const requiredFiles = attachmentsListRef.current.filter(
      (_item: any, indexref: number) => {
        return (
          _item &&
          _item.files == null &&
          attachmentOptionList[indexref].required === 1
        );
      },
    );
    attachmentsListRef.current.forEach((_item: any, indexref: number) => {
      if (
        _item &&
        _item.files == null &&
        attachmentOptionList[indexref]?.required === 1
      ) {
        _item.setError('É necessário incluir um anexo');
      }
    });

    const parsedAttachmentsListRef =
      attachmentsListRef.current.flatMap((_item: any) => _item?.files) || [];
    const fileNameArray = parsedAttachmentsListRef.map(
      (_item: any) => _item?.name,
    );

    if (checkIfDuplicateExists(fileNameArray)) {
      uniqueFiles = false;
    }

    return !(requiredFiles.length > 0) && uniqueFiles;
  }, [attachmentOptionList]);

  const getFormData = useCallback(() => {
    return (
      {
        ...formRef.current?.getData(),
        documentType: selectRef.current?.value,
      } ?? {}
    );
  }, []);

  const resetForm = () => {
    formRef.current?.reset();

    if (attachmentsListRef.current && attachmentsListRef.current.length > 0) {
      attachmentsListRef.current.forEach(
        (_item: { setFiles: (file: null) => void }) => {
          if (_item) {
            _item.setFiles(null);
          }
        },
      );
    }

    setDocumentDateState({
      value: new Date(),
      error: '',
    });
  };

  const getFiles = useCallback(() => {
    const requiredFiles = attachmentsListRef.current.filter(
      (_item: any, indexref: number) => {
        return (
          _item &&
          _item.files == null &&
          attachmentOptionList[indexref].required === 1
        );
      },
    );

    const filesData = {
      valid: !(requiredFiles.length > 0),
      files: attachmentsListRef.current.map((_item: any) => _item?.files),
    };

    return filesData ?? [];
  }, [attachmentOptionList]);

  const getDates = useCallback(() => {
    return {
      document: documentDateState.value,
    };
  }, [documentDateState.value]);

  const removeFormItem = (_index: number) => {
    onRemoveFormItem(_index);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        validForm: handleCheckValid,
        validFiles,
        formData: getFormData,
        getFiles,
        getDates,
        reset: resetForm,
      };
    },
    [handleCheckValid, getFiles, getFormData, validFiles, getDates],
  );

  useEffect(() => {
    if (documentAttachmentsList && documentAttachmentsList.length > 0) {
      setAttachmentOptionList(documentAttachmentsList);
    }
  }, [documentAttachmentsList]);

  useEffect(() => {
    const handleCheckDuplicateFile = (event: Event) => {
      const selectedFile = (event.target as HTMLInputElement)
        .files?.[0] as File;
      const selectedFileName = selectedFile.name;

      const parsedAttachmentsListRef =
        attachmentsListRef.current.flatMap(
          (_item: unknown) => (_item as { files: Array<unknown> }).files,
        ) || [];
      const fileNameArray = parsedAttachmentsListRef.map(
        (_item: unknown) => (_item as { name: string })?.name,
      );
      const duplicateNameArray = checkIfDuplicateExists([
        ...fileNameArray,
        selectedFileName,
      ]);

      if (duplicateNameArray) {
        attachmentsListRef.current.forEach((fileName: any) => {
          if (
            fileName &&
            fileName.files &&
            String(selectedFileName) === String(fileName.files[0].name)
          ) {
            fileName.setError('Não é possível incluir anexos com nomes iguais');
          }
        });
      } else {
        attachmentsListRef.current.forEach((fileName: any) => {
          fileName.setError('');
        });
      }
    };

    const removeEventListeners = () => {
      if (attachmentOptionList && attachmentOptionList.length > 0) {
        const inputFiles = document.querySelectorAll<HTMLInputElement>(
          `.refund__form-item-${index} .refund-attachments`,
        );
        inputFiles.forEach((itemTemp) => {
          itemTemp.removeEventListener('change', handleCheckDuplicateFile);
        });
      }
    };

    if (attachmentOptionList && attachmentOptionList.length > 0) {
      const inputFiles = document.querySelectorAll<HTMLInputElement>(
        `.refund__form-item-${index} .refund-attachments`,
      );
      inputFiles.forEach((itemFileInput) => {
        itemFileInput.addEventListener('change', handleCheckDuplicateFile);
      });
    }

    return removeEventListeners;
  }, [attachmentOptionList, attachmentsListRef, index]);

  return (
    <>
      <NoteText>Nota {index}</NoteText>
      <FormWrapper
        key={item.id}
        ref={formRef}
        className={`refund__form-item-${index}`}
        formLook
        onSubmit={() => console.log('')}
        style={{ marginBottom: 16 }}
      >
        {index - 1 !== 0 && (
          <NoteText
            style={{ width: '100%', textAlign: 'right', marginTop: 0 }}
            onClick={() => removeFormItem(index - 1)}
          >
            Remover nota
          </NoteText>
        )}
        {documentTypeList && documentTypeList.length > 0 && (
          <Select
            formLook
            name="documentType"
            title="Tipo de documento:"
            widthContainerDesktop="100%"
            className="notiflix-bank-options"
            liveReload
            error={errorDocumentType}
            changeCallback={(option) => onChangeDocumentType(option)}
            options={documentTypeList}
            ref={selectRef}
            inputMode
          />
        )}

        <DatePicker
          name="documentDate"
          formLook
          maxDate={oneYearLater()}
          minDate={oneYearBefore()}
          title="Data documento:"
          themeColor="red"
          widthContainerDesktop="30%"
          icon
          error={documentDateState.error}
          selected={documentDateState.value}
          onBlur={(e) => {
            let error = '';

            let value: string | null = e.target?.value;
            if (!isValidDate(e.target.value)) {
              error = 'Informe uma data do documento válida';
              value = null;
            }

            if (value) {
              const date = new Date(value).getTime();
              const min = oneYearBefore().getTime();
              const max = oneYearLater().getTime();

              if (date > max || date < min) {
                error = 'Informe uma data dentro do período válido';
                value = null;
              }
            }

            const [day, month, year] = (value?.split('/') ?? [1, 1, 1970]).map(
              Number,
            );

            setDocumentDateState({
              value: value ? new Date(year, month - 1, day, 0, 0, 0) : null,
              error,
            });
          }}
          onChange={(date: Date) => {
            setDocumentDateState({
              value: date,
              error: '',
            });
          }}
        />
        <Input
          formLook
          name="documentNumber"
          title="Número documento:"
          themeColor="red"
          widthContainerDesktop="30%"
          type="number"
        />
        {documentName === 'Nota Fiscal' ? (
          <Input
            formLook
            name="documentLocale"
            title="CPF/CNPJ do local do serviço:"
            themeColor="red"
            widthContainerDesktop="30%"
            masks={['999.999.999-99', '99.999.999/9999-99']}
          />
        ) : (
          <Input
            formLook
            name="documentLocale"
            title="CPF do local do serviço:"
            themeColor="red"
            widthContainerDesktop="30%"
            masks={['999.999.999-99']}
          />
        )}
        {attachmentOptionList &&
          attachmentOptionList.length > 0 &&
          attachmentOptionList.map((_item, indexFile) => {
            return (
              <InputFile
                ref={(el: any) => {
                  attachmentsListRef.current[indexFile] = el;
                }}
                key={_item.name}
                name={`attachments-${index + 1}`}
                className="refund-attachments"
                placeholderExternal="Anexo"
                placeholder={_item?.name}
              />
            );
          })}
      </FormWrapper>
    </>
  );
};

export default forwardRef(RefundFormItem);
