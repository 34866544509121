import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  outlined?: boolean;
  modal?: boolean;
  secondary?: boolean;
  disabled?: boolean;
  autoWidth?: boolean;
  float?: 'right' | 'left';
  sausageLook?: boolean;
  formLook?: boolean;
  whiteButton?: boolean;
  greenButton?: boolean;
  blueButton?: boolean;
  grayButton?: boolean;
  orangeButton?: boolean;
  purpleButton?: boolean;
  purple2Button?: boolean;
  greenCriticalButton?: boolean;
  greenPrimary?: boolean;
  noChangeColor?: boolean;
}

const Button: React.FC<ButtonProps> = ({ children, className, ...rest }) => {
  return (
    <Container type="button" {...rest} className={className}>
      {children}
    </Container>
  );
};

export default Button;
