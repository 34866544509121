import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import PageWrapper from '../../components/PageWrapper';
import Title from '../../components/Title';
import { HomeContainer } from './Home';
import { RequestCopyCard } from './RequestCopyCard';
import { TerminateBeneficiary } from './TerminateBeneficiary';
import { ChangeContract } from './ChangeContract';
import { ChangeProduct } from './ChangeProduct';
import DepentendInclude from './DepentendInclude';
import { ChangeRegister } from './ChangeRegister';
import { Container } from './styles';

export interface RowProps {
  contractId: string;
  productsLength: number;
  userUnimedId: number;
  beneficiaryName: string;
  cardNumber: string;
  hiringDate: string;
  expectedTerminationDate?: string;
  terminationDate?: string;
  holderName?: string;
  ownership?: string;
  situation: string;
  birthDate: string;
  productId: number;
  walletSituation: string;
  physicalPersonCode: string;
  productName: string;
  cardExpiryDate: string;
  registrationCode: string;
  allowsTermination: string;
  webUsername: string;
  requestId: string;
}

const Beneficiaries: React.FC = () => {
  const { step, params } = useParams<{ step: string; params: string }>();
  const [externalParams] = useState(() => {
    if (params) {
      if (step === '3') {
        return {
          requestId: String(params).split('/')[0],
          userUnimedId: String(params).split('/')[1],
          editMode: String(params).split('/')[2],
        };
      }
      if (step === '5') {
        return {
          userUnimedId: String(params).split('/')[0],
          productId: String(params).split('/')[0],
        };
      }
    }
    return {};
  });
  const { colors } = useTheme();
  const defaultColor = colors.palet.institutional3;

  const [actualStep, setActualStep] = useState(step ? Number(step) : 1);
  const [rowsSelecteds, setRowsSelecteds] = useState([] as RowProps[]);

  const title = useMemo(() => {
    switch (actualStep) {
      case 1:
        return 'Beneficiários';
      case 2:
        return 'Solicitar Segunda via do Cartão';
      case 3:
        return 'Rescindir beneficiário';
      case 4:
        return 'Alterar contrato/CNPJ';
      case 5:
        return 'Alterar produto';
      case 6:
        return 'Incluir dependente';
      case 7:
        return 'Alterar dados cadastrais';
      default:
        return '';
    }
  }, [actualStep]);

  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <Container>
        <Title themeColor={defaultColor}>{title}</Title>

        {actualStep === 1 && (
          <HomeContainer
            defaultColor={defaultColor}
            callbackSetStep={setActualStep}
            callbackSetSelecteds={setRowsSelecteds}
          />
        )}
        {actualStep === 2 && (
          <RequestCopyCard
            defaultColor={defaultColor}
            callbackSetStep={setActualStep}
            rowsSelecteds={rowsSelecteds}
          />
        )}
        {actualStep === 3 && (
          <TerminateBeneficiary
            defaultColor={defaultColor}
            callbackSetStep={setActualStep}
            rowsSelecteds={rowsSelecteds}
            onEdit={step === '3'}
            externalParams={externalParams}
          />
        )}
        {actualStep === 4 && (
          <ChangeContract
            defaultColor={defaultColor}
            callbackSetStep={setActualStep}
            rowsSelecteds={rowsSelecteds}
          />
        )}
        {actualStep === 5 && (
          <ChangeProduct
            defaultColor={defaultColor}
            callbackSetStep={setActualStep}
            rowsSelecteds={rowsSelecteds}
            externalParams={externalParams}
          />
        )}
        {actualStep === 6 && (
          <DepentendInclude
            defaultColor={defaultColor}
            callbackSetStep={setActualStep}
            rowsSelecteds={rowsSelecteds}
            showTitle
          />
        )}
        {actualStep === 7 && (
          <ChangeRegister
            defaultColor={defaultColor}
            callbackSetStep={setActualStep}
            rowsSelecteds={rowsSelecteds}
          />
        )}
      </Container>
    </PageWrapper>
  );
};

export default Beneficiaries;
