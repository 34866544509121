import React from 'react';

import { Title, Container } from './style';

import ReportComponent from './Report';
import PageWrapper from '../../components/PageWrapper';

const Report: React.FC = () => {
  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <Container>
        <Title> Relatórios </Title>
        <ReportComponent location={1} />
      </Container>
    </PageWrapper>
  );
};

export default Report;
