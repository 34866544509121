/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import produce from 'immer';
import Notiflix from 'notiflix';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { number } from 'yup';
import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';

import Input from '../../components/Input';
import InputFile, { InputFileHandles } from '../../components/InputFile';
import Modal2 from '../../components/Modal2';
import PageWrapper from '../../components/PageWrapper';
import Select, { SelectHandles } from '../../components/Select';
import { Column } from '../../components/Table';
import Title from '../../components/Title';
import { EnterpriseUser, UserCommon, useAuth } from '../../hooks/auth';
import { useContract } from '../../hooks/contract';
import api from '../../services/api/api';
import { dateToPTBR } from '../../utils/formatt';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';
import AddField, {
  getTag,
  setPositionOfRowExpanded,
} from '../../utils/tableUtils';

import {
  Container,
  SerchDataToTableContainer,
  Table,
  ActionsContainer,
  ModalFormWrapper,
  ModalContainer,
  FilesContainer,
  ButtonContainerModal,
  ModalButton,
} from './styles';

interface SelectOptionProps {
  value: string;
  title: string;
}

interface DateProps {
  value: Date | null;
  error: string;
}

interface Row {
  allowEdit: 'N' | 'S';
  beneficiaryName: string;
  disapproved: 'N' | 'S';
  holderName: string;
  imgAttachmentBase64: string | null;
  observation: null | string;
  reasonForDisapproval: null | string;
  requestId: number;
  solicitationDate: string;
  status: string;
  inclusionType: number;
  userUnimedId: number;
}

const ConsultInclusionOfBeneficiaries: React.FC = () => {
  const { colors } = useTheme();
  const { contract } = useContract();
  const history = useHistory();
  const statusRef = useRef<SelectHandles>(null);
  const dateOptionRef = useRef<SelectHandles>(null);
  const cancelRef = useRef<FormHandles>(null);
  const inputFilesRef = useRef<InputFileHandles>(null);
  const { user: userT } = useAuth();
  const user = userT as UserCommon & EnterpriseUser;

  const [statusOptions, setStatusOptions] = useState<SelectOptionProps[]>([
    {
      title: 'Todos',
      value: '',
    },
  ]);

  const [infoRejectedObsevation, setInfoRejectedObsevation] = useState([]);

  const dateUntilSelectOptions = [
    {
      title: 'Ambos',
      value: 'ambos',
    },
    {
      title: 'Somente com lote',
      value: 'comLote',
    },
    {
      title: 'Somente sem lote',
      value: 'semLote',
    },
  ];

  const [selectedFromDate, setSelectedFromDate] = useState<DateProps>({
    value: new Date(
      new Date().getFullYear() - 1,
      new Date().getMonth(),
      new Date().getDate(),
    ),
    error: '',
  });
  const [selectedToDate, setSelectedToDate] = useState<DateProps>({
    value: new Date(),
    error: '',
  });

  const getFiltersToSelect = useCallback(async () => {
    const response = await api.get(
      `/beneficiary-inclusion/inclusion-status-filter`,
    );
    const { data } = response;
    const { content } = data;

    const options = content.map((option: any) => {
      return {
        value: option.id,
        title: option.name,
      };
    });

    setStatusOptions([
      {
        title: 'Todos',
        value: '',
      },
      ...options,
    ]);
  }, []);

  const [dataTable, setDataTable] = useState([] as Row[]);

  const getDataToTable = useCallback(
    async (filter = [] as string[]) => {
      try {
        Notiflix.Block.circle('.notiflix-table-loading');
        const startDate =
          selectedFromDate.value && dateToPTBR(selectedFromDate.value);
        const finalDate =
          selectedToDate.value && dateToPTBR(selectedToDate.value);

        const params =
          filter.join('&') || `startDate=${startDate}&finalDate=${finalDate}`;

        const { data } = await api.get(
          `/beneficiary-inclusion/consult-records?contractId=${contract.contractId}&${params}`,
        );
        // const { data } = await api.get(
        //   `/beneficiary-inclusion/consult-records?contractId=149537&${params}`,
        // );

        const { content } = data;

        setDataTable(content);
        return;
      } catch (error) {
        if (error?.response?.status === 400) {
          setDataTable([]);
        } else {
          Notiflix.Notify.failure(
            'Ops, não conseguimos buscar os dados do servidor... Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove('.notiflix-table-loading');
      }
    },
    [contract.contractId, selectedFromDate.value, selectedToDate.value],
  );

  const handleSearchDate = useCallback(
    (data) => {
      const filters = [] as string[];
      if (!selectedToDate.value) {
        setSelectedToDate((prev) => ({ ...prev, error: 'Informe uma data.' }));
        return;
      }
      if (!selectedFromDate.value) {
        setSelectedFromDate((prev) => ({
          ...prev,
          error: 'Informe uma data.',
        }));
        return;
      }
      filters.push(`startDate=${dateToPTBR(selectedFromDate.value)}`);
      filters.push(`finalDate=${dateToPTBR(selectedToDate.value)}`);
      if (statusRef.current?.value) {
        filters.push(`status=${statusRef.current.value}`);
      }
      if (dateOptionRef.current?.value) {
        filters.push(`timeInterval=${dateOptionRef.current.value}`);
      }
      if (data.batch) {
        filters.push(`batch=${data.batch}`);
      }
      getDataToTable(filters);
    },
    [getDataToTable, selectedFromDate.value, selectedToDate.value],
  );

  useEffect(() => {
    if (contract.contractId) {
      getFiltersToSelect();
      getDataToTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  const handleOpenInfoRejected = useCallback((data: Row) => {
    const rejectObs = data.observation!.split('.');
    const obs = rejectObs.map((index) => {
      return <p key={index.slice(0, 10)}>{index};</p>;
    });

    Modal2.Warning({
      closable: true,
      autoWidth: true,
      title: 'Motivo',
      subtitle: data.reasonForDisapproval ?? '',
      text: obs,
    });
  }, []);

  const handleOpenDetailRejected = useCallback(
    (data: Row) => {
      history.push(
        `/incluir-beneficiario/${data.requestId}/rejeitado/${
          data.inclusionType === 1 ? 'titular' : 'dependente'
        }/${data.holderName ?? ''}`,
      );
    },
    [history],
  );

  const handleRelease = useCallback(
    async (row: Row) => {
      try {
        Notiflix.Block.circle(`.notiflix-release-${row.requestId}`);
        const { data } = await api.put(`company/release-dependent-registered`, {
          requestId: row.requestId,
          origin: 'Consulta',
        });
        const { message } = data;
        Modal2.Success({
          closable: true,
          autoWidth: true,
          title: 'Perfeito!',
          text: message,
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });

        getDataToTable();
        setDataTable((prev) =>
          produce(prev, (draft) => {
            const found = draft.find((i) => i.requestId === row.requestId);
            if (found) {
              found.status = 'Aguardando análise Operadora';
            }
          }),
        );
      } catch (err) {
        if (err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos enviar sua solicitação para o servidor. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.circle(`.notiflix-release-${row.requestId}`);
      }
    },
    [getDataToTable],
  );

  const handleUndoRelease = useCallback(
    async (row: Row) => {
      try {
        Notiflix.Block.circle(`.notiflix-release-${row.requestId}`);
        const resp = localStorage.getItem('@unimedBlumenau');
        if (resp) {
          const userData = JSON.parse(resp);
          const { webUsername } = userData;
          const { data } = await api.put(
            `beneficiary-inclusion/undo-release-solicitation-request`,
            {
              requestId: row.requestId,
              origin: 'Consulta',
              webUsername,
            },
          );
          const { message } = data;
          Modal2.Success({
            closable: true,
            autoWidth: true,
            title: 'Perfeito!',
            text: message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
          getDataToTable();
          setDataTable((prev) =>
            produce(prev, (draft) => {
              const found = draft.find((i) => i.requestId === row.requestId);
              if (found) {
                found.status = 'Aguardando liberação';
              }
            }),
          );
        }
      } catch (err) {
        if (err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos enviar sua solicitação para o servidor. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.circle(`.notiflix-release-${row.requestId}`);
      }
    },
    [getDataToTable],
  );

  const handleOpenDetailAndEdit = useCallback(
    (data) => {
      history.push(
        `/incluir-beneficiario/${data.requestId}/edicao/${
          data.inclusionType === 1 ? 'titular' : 'dependente'
        }/${data.holderName ?? ''}`,
      );
    },
    [history],
  );
  const handleOpenReport = useCallback(async (data: Row) => {
    try {
      Notiflix.Block.circle(`.notiflix-report-${data.requestId}`);
      const { data: dataApi } = await api.get(
        '/report/list-of-company-portal-reports?exhibitionLocation=2',
      );
      const { content } = dataApi;
      const { nrSeqRelatorio, Parametros: P } = content[0];

      const { data: dataReport } = await api.post(
        `/report/generate-report`,
        {
          nrSeqRelatorio: nrSeqRelatorio ?? '',
          Parametros: [
            {
              id: P[0].cdParametro,
              valor: data.requestId,
            },
          ],
        },
        {
          responseType: 'blob',
        },
      );
      downloadFileOfBlob(dataReport);
    } catch (err) {
      if (err.response?.data?.message) {
        Notiflix.Notify.info(err.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar o relatório no servidor. Por favor, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove(`.notiflix-report-${data.requestId}`);
    }
  }, []);

  const handleOpenInconsistency = useCallback(async (row: Row) => {
    try {
      Notiflix.Block.circle('notiflix-button');
      const { data } = await api.get(
        `/company/get-inconsistency-inclusion?requestNumber=${row.requestId}`,
      );

      const { data: realData } = data;
      const text = realData.map((e: any) => {
        return (
          <p key={e.inconsistencyDescription.slice(0, 10)}>
            {e.inconsistencyDescription}
          </p>
        );
      });

      if (!data) {
        return;
      }

      Modal2.Generic({
        closable: true,
        title: 'Inconsistências',
        autoWidth: true,
        text,
      });
    } catch (err: any) {
      if (err.response?.data?.message) {
        Notiflix.Notify.info(err.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar o relatório no servidor. Por favor, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('notiflix-button');
    }
  }, []);

  const handleResendEmail = useCallback(async (row: Row) => {
    try {
      Notiflix.Block.circle('.notiflix-button');
      const { data } = await api.get(
        `beneficiary-inclusion/resend-email?requestId=${row.requestId}`,
      );

      if (!data) {
        return;
      }

      if (data.message) {
        Modal2.Success({
          closable: true,
          title: 'Perfeito!',
          autoWidth: true,
          subtitle: data.message,
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });
      }
    } catch (err: any) {
      if (err.response?.data?.message) {
        Notiflix.Notify.info(err.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos reenviar o e-mail. Por favor, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('notiflix-button');
    }
  }, []);

  const handleCancelRegister = useCallback(async (row: Row) => {
    try {
      Notiflix.Block.circle(`.notiflix-cancel-${row.requestId}`);
      const submitCancel = async (form: any) => {
        try {
          Notiflix.Block.circle('.notifix-modal-cancel');

          if (!cancelRef.current?.getFieldValue('observation')) {
            cancelRef.current?.setFieldError(
              'observation',
              'É necessário informar a observação',
            );
            return;
          }
          cancelRef.current?.setFieldError('observation', '');

          const { data } = await api.put(
            `/beneficiary-inclusion/cancel-inclusion-request`,
            {
              observation: form.observation,
              requestId: row.requestId,
              origin: 'Consulta',
            },
          );
          const { message } = data;
          Modal2.Close();
          Modal2.Success({
            closable: true,
            autoWidth: true,
            title: 'Perfeito!',
            text: message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
          setDataTable((prev) =>
            produce(prev, (draft) => {
              const founded = draft.find((i) => i.requestId === row.requestId);
              if (founded) {
                founded.status = 'Solicitação cancelada';
              }
            }),
          );
        } catch (er) {
          Modal2.Close();
          if (er.response?.data?.message) {
            Modal2.Failure({
              closable: true,
              autoWidth: true,
              title: 'Ooops...',
              text: er.response.data.message,
              children: (
                <Button modal onClick={() => Modal2.Close()}>
                  Ok
                </Button>
              ),
            });
          } else {
            Notiflix.Notify.failure(
              'Ops... Não conseguimos enviar sua solicitação de cancelamento para o servidor. Por favor, tente novamente mais tarde.',
            );
          }
        } finally {
          Notiflix.Block.remove('.notifix-modal-cancel');
        }
      };
      Modal2.Warning({
        closable: true,
        autoWidth: true,
        title: 'Cancelar Registro',
        children: (
          <ModalFormWrapper onSubmit={submitCancel} formLook ref={cancelRef}>
            <Input name="observation" title="Observação" formLook />
            <Button modal autoWidth secondary onClick={() => Modal2.Close()}>
              Voltar
            </Button>
            <Button
              modal
              type="submit"
              autoWidth
              className="notifix-modal-cancel"
            >
              Enviar
            </Button>
          </ModalFormWrapper>
        ),
      });
    } catch (err) {
      if (err.response?.data?.message) {
        Notiflix.Notify.failure(err.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos cancelar o registro no servidor. Por favor, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove(`.notiflix-cancel-${row.requestId}`);
    }
  }, []);

  const handleOpenAttachment = useCallback(
    async (archive, requestId, notiflix) => {
      try {
        Notiflix.Block.circle(notiflix);
        const { data } = await api.get(
          `/beneficiary-inclusion/attachment-sent-in-inclusion?requestId=${requestId}&fileId=${archive.id}&fileName=${archive.name}`,
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(data);
      } catch (err) {
        if (err.response?.data?.message) {
          Notiflix.Notify.failure(err.response.data.message);
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos buscar seu documento. Por favor, tente novamente mais tarde',
          );
        }
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [],
  );

  const handleViewAndAttachLineOption = useCallback(
    async (row: Row, notiflix: string) => {
      try {
        Notiflix.Block.circle(notiflix);
        let attachments = [] as { id: number; name: string }[];
        try {
          const { data } = await api.get(
            `/beneficiary-inclusion/consult-solicitation-for-editing?requestId=${row.requestId}&webUsername=${user.webUsername}`,
          );
          const { content } = data;
          attachments = content[0].attachments;
        } catch (e) {
          Notiflix.Notify.info('Ainda não há anexos.');
        }

        const addAttachment = async () => {
          if (!inputFilesRef.current?.files) return;
          try {
            Notiflix.Block.circle('.notiflix-send-attachment');
            const params = new FormData();
            params.append('requestId', row.requestId.toString());
            params.append('webUsername', user.webUsername.toString());
            for (let i = 0; i < inputFilesRef.current.files.length; i += 1) {
              params.append(`file[${i}]`, inputFilesRef.current.files[i]);
            }
            const { data: data2 } = await api.post(
              `beneficiary-inclusion/send-attachments-for-inclusion`,
              params,
            );
            const { message } = data2;
            Notiflix.Notify.success(message);
            Modal2.Close();
            handleViewAndAttachLineOption(row, notiflix);
          } catch (err) {
            if (err.response?.data?.message) {
              Notiflix.Notify.failure(err.response.data.message);
            } else {
              Notiflix.Notify.failure(
                'Ops... Não conseguimos enviar seu arquivo para o servidor. Por favor, tente novamente mais tarde.',
              );
            }
          } finally {
            Notiflix.Block.remove('.notiflix-send-attachment');
          }
        };

        Modal2.Generic({
          children: (
            <ModalContainer>
              <h2>Informações dos arquivos</h2>
              <FilesContainer>
                {attachments.map((archive) => (
                  <button
                    key={archive.id}
                    type="button"
                    onClick={() =>
                      handleOpenAttachment(
                        archive,
                        row.requestId,
                        `.notiflix-archive-${archive.id}`,
                      )
                    }
                    className={`notiflix-archive-${archive.id}`}
                  >
                    {archive.name}
                  </button>
                ))}
              </FilesContainer>

              <ButtonContainerModal>
                <InputFile
                  placeholder="Anexar arquivo"
                  ref={inputFilesRef}
                  multiple
                />
                <ModalButton
                  modal
                  onClick={addAttachment}
                  className="notiflix-send-attachment"
                >
                  Enviar Anexo
                </ModalButton>
              </ButtonContainerModal>
            </ModalContainer>
          ),
          autoWidth: true,
          closable: true,
        });
      } catch (error) {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar os dados do servidor.Por favor, tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [handleOpenAttachment],
  );

  const [rowExpanded, setRowExpanded] = useState([] as Row[]);

  const templateRowExpanded = useCallback(
    (rowData: Row) => {
      return (
        <ActionsContainer>
          {rowData.status !== 'Solicitação com inconsistência' && (
            <Button
              sausageLook
              whiteButton
              autoWidth
              onClick={() => handleOpenReport(rowData)}
              className={`notiflix-report-${rowData.requestId} marginRight`}
            >
              Relatório
            </Button>
          )}
          {rowData.disapproved === 'S' && (
            <>
              <Button
                sausageLook
                whiteButton
                autoWidth
                onClick={() => handleOpenInfoRejected(rowData)}
                className={`notiflix-reject-${rowData.requestId}`}
              >
                Motivo de rejeição
              </Button>
              <Button
                sausageLook
                whiteButton
                autoWidth
                onClick={() => handleOpenDetailRejected(rowData)}
                className={`notiflix-reject-${rowData.requestId}`}
              >
                Refazer inclusão
              </Button>
            </>
          )}
          {rowData.status === 'Aguardando liberação' && (
            <Button
              sausageLook
              whiteButton
              autoWidth
              className={`notiflix-reject-${rowData.requestId}`}
              onClick={() =>
                handleViewAndAttachLineOption(
                  rowData,
                  `notiflix-reject-${rowData.requestId}`,
                )
              }
            >
              Ver / Anexar
            </Button>
          )}

          {rowData.status === 'Solicitação com inconsistência' && (
            <Button
              sausageLook
              whiteButton
              autoWidth
              onClick={() => handleOpenInconsistency(rowData)}
            >
              Inconsistências
            </Button>
          )}
          {rowData.status === 'Aguardando liberação' && (
            <Button
              sausageLook
              whiteButton
              autoWidth
              onClick={() => handleRelease(rowData)}
            >
              Liberar
            </Button>
          )}
          {rowData.status === 'Aguardando análise Operadora' && (
            <Button
              sausageLook
              whiteButton
              autoWidth
              onClick={() => handleUndoRelease(rowData)}
            >
              Desfazer liberação
            </Button>
          )}
          {rowData.status ===
            'Aguardando preenchimento da Declaração de Saúde' && (
            <Button
              sausageLook
              whiteButton
              autoWidth
              onClick={() => handleResendEmail(rowData)}
            >
              Reenviar email
            </Button>
          )}
          {(rowData.status ===
            'Aguardando preenchimento da Declaração de Saúde' ||
            rowData.status === 'Aguardando liberação' ||
            rowData.status === 'Solicitação com inconsistência') && (
            <>
              {rowData.allowEdit === 'S' && (
                <Button
                  sausageLook
                  whiteButton
                  autoWidth
                  onClick={() => handleOpenDetailAndEdit(rowData)}
                >
                  Editar
                </Button>
              )}
            </>
          )}

          {(rowData.status === 'Aguardando análise Operadora' ||
            rowData.status === 'Solicitação com inconsistência' ||
            rowData.status === 'Aguardando liberação' ||
            rowData.status === 'Aguardando preenchimento do Funcionário') && (
            <Button
              sausageLook
              whiteButton
              autoWidth
              onClick={() => handleCancelRegister(rowData)}
              className={`notiflix-cancel-${rowData.requestId}`}
            >
              Cancelar
            </Button>
          )}
        </ActionsContainer>
      );
    },
    [
      handleOpenReport,
      handleOpenInfoRejected,
      handleOpenDetailRejected,
      handleViewAndAttachLineOption,
      handleOpenInconsistency,
      handleRelease,
      handleUndoRelease,
      handleResendEmail,
      handleOpenDetailAndEdit,
      handleCancelRegister,
    ],
  );

  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <Container>
        <Title themeColor={colors.palet.institutional}>
          Consultar Inclusão de beneficiários
        </Title>
        <SerchDataToTableContainer formLook onSubmit={handleSearchDate}>
          <Select
            widthContainerDesktop="50%"
            options={statusOptions}
            className="notiflix-option-code"
            title="Status:"
            name="filters"
            formLook
            ref={statusRef}
          />
          <Select
            widthContainerDesktop="50%"
            inputMode
            options={dateUntilSelectOptions}
            // ref={dateOptionRef}
            className="notiflix-option-code"
            title="Modelo do Lote:"
            name="filters"
            formLook
          />

          <DatePicker
            name="from"
            onChange={(date: Date) =>
              setSelectedFromDate({ value: date, error: '' })
            }
            selected={selectedFromDate.value}
            error={selectedFromDate.error}
            icon
            formLook
            title="Período de:"
            widthContainerDesktop="20%"
          />
          <DatePicker
            name="to"
            onChange={(date: Date) => {
              setSelectedToDate({ value: date, error: '' });
            }}
            selected={selectedToDate.value}
            error={selectedToDate.error}
            icon
            formLook
            title="Período até:"
            widthContainerDesktop="20%"
          />

          <Input
            name="batch"
            title="Lote"
            formLook
            widthContainerDesktop="20%"
          />
          <Button grayButton formLook type="submit">
            Buscar
          </Button>
        </SerchDataToTableContainer>
        <Table
          items={dataTable}
          className="notiflix-table-loading"
          rowExpansionTemplate={templateRowExpanded}
          onRowToggle={(e) => setRowExpanded([e.data[e.data.length - 1]])}
          onRowClick={(e) => {
            if (
              rowExpanded.length > 0 &&
              rowExpanded[0]?.requestId !== e.data?.requestId
            ) {
              setRowExpanded([]);
              return setRowExpanded([e.data]);
            }
            return rowExpanded.length === 0
              ? setRowExpanded([e.data])
              : setRowExpanded([]);
          }}
          expandedRows={rowExpanded}
          onRowExpand={(e) =>
            window.innerWidth <= 768 &&
            setPositionOfRowExpanded(e.originalEvent)
          }
        >
          <Column
            field="requestId"
            header="Solicitação"
            sortable
            body={(data) => AddField(data.requestId, 'Solicitação')}
            style={{ width: '140px' }}
          />
          <Column
            field="beneficiary"
            header="Beneficiário"
            sortable
            body={(data) => AddField(data.beneficiaryName, 'Beneficiário')}
          />
          <Column
            field="holderName"
            header="Nome do Titular"
            sortable
            body={(data) => AddField(data.holderName, 'Nome do Titular')}
          />
          <Column
            field="solicitationData"
            header="Data de Solicitação"
            className="date"
            body={(data) =>
              AddField(data.solicitationDate, 'Data de Solicitação')
            }
          />
          <Column
            field="solicitationData"
            header="Data de Liberação"
            className="date"
            body={(data) => AddField(data.releaseDate, 'Data de Solicitação')}
          />
          <Column
            field="status"
            header="Status"
            className="tag"
            style={{ width: '150px' }}
            sortable
            body={(data) => {
              switch (data.status) {
                case 'Aguardando análise da Operadora':
                case 'Aguardando análise Operadora':
                  return getTag(
                    'Status',
                    'Aguard. Análi. Operadora',
                    'orange',
                    data.status,
                  );
                case 'Aguardando liberação':
                  return getTag(
                    'Status',
                    'Aguard. Liberação',
                    'orange',
                    data.status,
                  );
                case 'Aguardando preenchimento da Declaração de Saúde':
                  return getTag(
                    'Status',
                    'Aguard. Preench. Declar. Saúde',
                    'orange',
                    data.status,
                  );
                case 'Contratação concluída':
                  return getTag(
                    'Status',
                    'Contrat. Cocluída',
                    'green',
                    data.status,
                  );
                case 'Em processo de finalização':
                  return getTag(
                    'Status',
                    'Em Proces. Finalização',
                    'orange',
                    data.status,
                  );
                case 'Solicitação com inconsistência':
                  return getTag(
                    'Status',
                    'Solic. c/ Inconsistência',
                    'orange',
                    data.status,
                  );
                case 'Solicitação em andamento':
                  return getTag(
                    'Status',
                    'Solic. Em Andamento',
                    'orange',
                    data.status,
                  );
                case 'Solicitação rejeitada pela operadora':
                  return getTag(
                    'Status',
                    'Solic. Rejeitada',
                    'red',
                    data.status,
                  );
                case 'Aguardando Análise da Declaração de Saúde':
                  return getTag(
                    'Status',
                    'Aguard. Análi. Declar. Saúde',
                    'orange',
                    data.status,
                  );
                case 'Solicitação cancelada':
                  return getTag(
                    'Status',
                    'Solicitação Cancelada',
                    'gray',
                    data.status,
                  );
                case 'Aguardando preenchimento do Funcionário':
                  return getTag(
                    'Status',
                    'Aguard. Preench. Func.',
                    'orange',
                    data.status,
                  );
                default:
                  return <>{data.status}</>;
              }
            }}
          />
          <Column expander headerStyle={{ width: '60px' }} />
        </Table>
      </Container>
    </PageWrapper>
  );
};

export default ConsultInclusionOfBeneficiaries;
