import React, {
  MouseEvent,
  useCallback,
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { FiChevronDown } from 'react-icons/fi';
import { Container, Content } from './styles';

interface DropdownProps {
  title?: string;
  titleJSX?: React.ReactNode;
  className?: string;
  body: React.ReactNode;
  callbackClickOpen?(): void;
}

export interface DropdownHandles {
  resize(): void;
}

const Dropdown: React.ForwardRefRenderFunction<DropdownHandles, DropdownProps> =
  ({ title, titleJSX, className, body, callbackClickOpen }, ref) => {
    const [heightContent, setHeightContent] = useState(0);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const handleOpenDropdown = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        const svg = e.currentTarget.querySelector('svg.chevron');
        const nextElement = e.currentTarget.nextElementSibling;
        const parent = e.currentTarget.parentElement?.parentElement;
        const allDropdowns = parent?.querySelectorAll('[data-dropdown]');
        if (nextElement) {
          nextElement.classList.toggle('open');
          svg?.classList.toggle('open');

          if (nextElement.classList.contains('open') && !!callbackClickOpen) {
            callbackClickOpen();
          }
          // if (contentRef.current?.scrollHeight) {
          //   setHeightContent(contentRef.current.scrollHeight);
          // }
        }
        allDropdowns?.forEach((dropdown) => {
          if (dropdown.children[0] !== e.currentTarget) {
            dropdown.children[1].classList.remove('open');
            dropdown.children[0].lastElementChild?.classList.remove('open');
          }
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    const resize = useCallback(() => {
      if (contentRef.current) {
        setHeightContent(contentRef.current.scrollHeight);
        if (!contentRef.current?.classList.contains('open')) {
          contentRef.current?.classList.add('open');
        }
      }
    }, []);

    useImperativeHandle(ref, () => {
      return {
        resize,
      };
    });

    useEffect(() => {
      setHeightContent(contentRef.current?.scrollHeight || 0);
    }, [contentRef, body]);

    return (
      <Container className={className} data-dropdown="data-dropdown">
        <button type="button" onClick={handleOpenDropdown} ref={buttonRef}>
          {!title && titleJSX}
          {!titleJSX && title}
          <FiChevronDown size={24} className="chevron" />
        </button>
        <Content ref={contentRef} height={heightContent}>
          {body}
        </Content>
      </Container>
    );
  };

export default forwardRef(Dropdown);
