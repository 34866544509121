import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type Response<T> = [T, Dispatch<SetStateAction<T>>];

export default function useStatePersisted<T>(
  key: string,
  initial: T,
): Response<T> {
  const [state, setState] = useState(() => {
    const storageValue = sessionStorage.getItem(key);

    if (storageValue) {
      return JSON.parse(storageValue);
    }
    return initial;
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}
