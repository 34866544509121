import styled from 'styled-components';

export const Container = styled.div`
  grid-column: 2/3;
  @media (max-width: 768px) {
    grid-column: 1;
    margin-bottom: 30px;
  }
  > form {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;

    @media (max-width: 768px) {
      > div {
        &:nth-child(2),
        &:nth-child(3) {
          width: auto;
          flex: 1 1 0;
        }
      }
    }
  }
`;
