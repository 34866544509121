import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../components/Button';
import FormWrapper from '../../components/FormWrapper';
import Input from '../../components/Input';
import Modal2 from '../../components/Modal2';
import { LogoUnimedSvg } from '../../components/Svg';
import api from '../../services/api/api';
import { formatDate } from '../../utils/formatt';

import { Container, Observation, Title, UnimedLogo } from './style';
import TermsDS from './Terms';

interface ValuesProp {
  terms: string;
  needDs: boolean;
  beneficiaryId: string;
  name: string;
  telephone: string;
  healthDeclarationId: string;
  modelType: number;
  cpf: number;
  birthdate: string;
}

const HealthDeclaration: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { hash } = useParams<{ hash: string }>();
  const [values, setValues] = useState<ValuesProp>({
    terms: '',
    needDs: false,
    beneficiaryId: '',
    name: '',
    telephone: '',
    healthDeclarationId: '',
    modelType: 0,
    cpf: 0,
    birthdate: '',
  });
  const [contentValues, setContentValues] = useState(0);
  const [termClick, setTermClick] = useState(0);

  const tokenValidate = useCallback(async () => {
    try {
      formRef.current?.setErrors({});

      const data = formRef.current?.getData();

      if (!data?.cpf) {
        formRef.current?.setFieldError('cpf', 'Informe seu CPF');

        return null;
      }

      if (!data?.birthdate) {
        formRef.current?.setFieldError(
          'birthdate',
          'Informe sua data de nascimento',
        );

        return null;
      }

      const birthDateFormatted = formatDate(data.birthdate);

      const formData = new FormData();
      formData.append('hash', String(hash));
      formData.append('cpf', data.cpf);
      formData.append('birthdate', birthDateFormatted);

      const contentApi = await api
        .post(`/health-declaration/verify-beneficiary`, formData)
        .then((response) => {
          const contentReturn = {
            terms: response.data.content.terms,
            needDs: response.data.content.need_ds,
            beneficiaryId: response.data.content.beneficiary_id,
            name: response.data.content.name,
            telephone: response.data.content.telephone,
            healthDeclarationId: response.data.content.healthDeclaration_id,
            modelType: response.data.content.modelType,
            cpf: data.cpf,
            birthdate: birthDateFormatted,
            is_additional: response.data.content.is_additional,
          };
          localStorage.setItem(
            '@unimedBlumenauDeclaracaoSaude',
            JSON.stringify({
              beneficiaryId: response.data.content.beneficiary_id,
              name: response.data.content.name,
              telephone: response.data.content.telephone,
              healthDeclarationId: response.data.content.healthDeclaration_id,
              modelType: response.data.content.modelType,
              cpf: data.cpf,
              birthdate: birthDateFormatted,
              is_additional: response.data.content.is_additional,
            }),
          );
          if (response.data.content) {
            setValues(contentReturn);
            setContentValues(1);
          }
        });

      return null;
    } catch (err) {
      if (err.response?.data?.message) {
        Modal2.Failure({
          closable: true,
          autoWidth: true,
          title: 'Ooops...',
          text: err.response.data.message,
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos salvar sua requisição no servidor. Por favor, tente novamente mais tarde.',
        );
      }
      return null;
    }
  }, [hash]);

  useEffect(() => {
    if (contentValues === 1) {
      setTermClick(1);
    }
  }, [contentValues, values]);

  return (
    <Container>
      <FormWrapper
        onSubmit={() => tokenValidate()}
        formLook
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        ref={formRef}
        className=""
      >
        <UnimedLogo>
          <LogoUnimedSvg />
        </UnimedLogo>
        <Title>Confirme seus dados</Title>
        <Observation>
          Confirme seus dados para prosseguir o preenchimento da declaração de
          saúde
        </Observation>
        <Input name="cpf" type="text" title="CPF" masks={['999.999.999-99']} />
        <Input
          name="birthdate"
          type="text"
          title="Data de nascimento"
          masks={['99/99/9999']}
        />

        <Button type="submit">Enviar</Button>
      </FormWrapper>
      {termClick === 1 && <TermsDS values={values} />}
    </Container>
  );
};

export default HealthDeclaration;
