import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ThemeContext } from 'styled-components';

import { useHistory } from 'react-router-dom';
import Notiflix from 'notiflix';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import produce from 'immer';
import {
  Container,
  Carousel,
  MyContract,
  Title,
  MyContractHeader,
  QuickAccessContainer,
  QuickRow,
  QuickColumn,
  ButtonWithOptions,
  Contract,
  TableContent,
  TableButtons,
  ModalUnitContent,
  ButtonWithOptionsBeneficiary,
} from './styles';
import { EnterpriseUser, UserCommon, useAuth } from '../../../hooks/auth';
import api from '../../../services/api/api';
import { BannerProps } from '../../../components/Carousel';
import FormWrapper from '../../../components/FormWrapper';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Table, { Column } from '../../../components/Table';
import AddField from '../../../utils/tableUtils';
import { useContract } from '../../../hooks/contract';
import Modal2 from '../../../components/Modal2';

interface ContractFormProps {
  contractId: string;
  stipulatorName: string;
  companyCode: string;
  unit?: string;
  cnpj: string;
}
interface ProductFormProps {
  productName: string;
  segmentation: string;
  AnsProtocol: string;
  coverage: string;
  accommodation: string;
  planId: number;
}

const HomeCompany: React.FC = () => {
  const { isAuthendicated, user: userT } = useAuth();
  const user = userT as UserCommon & EnterpriseUser;
  const { contract, defineContract } = useContract();
  const { colors } = useContext(ThemeContext);
  const history = useHistory();

  const contractFormRef = useRef<FormHandles>(null);
  const productFormRef = useRef<FormHandles>(null);
  const formEditNameRef = useRef<FormHandles>(null);

  const [productSelected, setProductSelected] = useState<ProductFormProps>(
    {} as ProductFormProps,
  );
  const [productsOptions, setProductsOptions] = useState([
    {
      title: 'Produto',
      value: '',
    },
  ]);

  const [contractsData, setContractsData] = useState<ContractFormProps[]>(
    [] as ContractFormProps[],
  );

  const [banners, setBanners] = useState([] as BannerProps[]);

  const getBanners = useCallback(async () => {
    try {
      const { data } = await api.get('/banner/list-banner');
      const { content } = data;
      setBanners(content);
    } catch (error) {
      Notiflix.Notify.failure(
        'Não conseguimos carregar os banners do servidor...',
      );
    }
  }, []);

  /**
   * @description call function to get initial values to show in homepage
   */
  const getInitialData = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-product-container');
      const resps = await Promise.allSettled([
        api.get(
          `/company/list-of-contracts?groupNumber=${user.groupNumber ?? ''}`,
        ),
        api.get(
          `/company/product-listing-for-selection?contractId=${user.contractId}`,
        ),
      ]);
      const [respContract, respListOfProductsOfThisContract] = resps;

      const { content: contentContract } =
        respContract.status === 'fulfilled'
          ? respContract.value.data
          : { content: [] };

      const { content: contentListOfProdutsOfThisContract } =
        respListOfProductsOfThisContract.status === 'fulfilled'
          ? respListOfProductsOfThisContract.value.data
          : { content: [] };
      if (contentContract) {
        // contract to table
        setContractsData(contentContract);
      }

      if (
        contentListOfProdutsOfThisContract &&
        contentListOfProdutsOfThisContract.length > 0
      ) {
        // all product
        setProductsOptions(
          contentListOfProdutsOfThisContract.reduce(
            (
              acc: { title: string; value: string }[],
              act: { name: string; id: string },
            ) => {
              acc.push({
                title: act.name,
                value: act.id,
              });
              return acc;
            },
            [],
          ),
        );

        // get aditional fields of first product
        const idFirst = contentListOfProdutsOfThisContract[0].id;
        const { data: dataFirstProduct } = await api.get(
          `/company/list-of-products?productCode=${idFirst}`,
        );
        const { content: contentFirstProduct } = dataFirstProduct;

        if (contentFirstProduct && contentFirstProduct[0]) {
          setProductSelected(contentFirstProduct[0]);
        }
      }
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar seus contratos no servidor. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-product-container');
    }
  }, [user]);

  const handleChangeSelectedProduct = useCallback(async (id: string) => {
    try {
      Notiflix.Block.circle('.notiflix-product-container');
      const { data } = await api.get(
        `/company/list-of-products?productCode=${id}`,
      );
      const { content } = data;

      if (content && content[0]) {
        setProductSelected(content[0]);
      }
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os dados para visualizar o produto selecionado. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-product-container');
    }
  }, []);

  const handleChangeUnitName = useCallback(async (contractId: string) => {
    let unityName = '';
    try {
      const { data } = await api.get(
        `/company/unity-name?contractId=${contractId}`,
      );
      const { content } = data;
      unityName = content && content[0].unityName;
    } catch (e) {
      unityName = '';
    }

    const submitNewUnitName = async (dataValue: { newUnitName: string }) => {
      try {
        Notiflix.Block.circle('.notiflix-button-change-unit-name');
        let newUnitAPI: any;

        // formEditNameRef.current?.setErrors({});
        if (!dataValue.newUnitName) {
          newUnitAPI = null;
        } else {
          newUnitAPI = dataValue.newUnitName;
        }

        await api.put(`/company/change-unity-name`, {
          contractId,
          unityName: newUnitAPI,
        });
        Modal2.Close();

        setContractsData((prev) => {
          return produce(prev, (draft) => {
            const item = draft.find((i) => i.contractId === contractId);
            if (item) {
              item.unit = newUnitAPI;
            }
          });
        });
        Modal2.Success({
          closable: true,
          title: 'Perfeito!',
          subtitle: 'Alteramos o nome da sua unidade',
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });
      } catch (error) {
        Notiflix.Notify.failure(
          'Ops, não conseguimos alterar o nome da unidade no servidor. Por favor, tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.remove('.notiflix-button-change-unit-name');
      }
    };

    Modal2.Generic({
      closable: true,
      autoWidth: true,
      children: (
        <ModalUnitContent>
          <h2>Alterar nome da Unidade</h2>
          <Form onSubmit={submitNewUnitName} ref={formEditNameRef}>
            <Input
              name="newUnitName"
              title="Unidade:"
              defaultValue={unityName}
            />
            <Button modal secondary onClick={() => Modal2.Close()}>
              Cancelar
            </Button>
            <Button
              modal
              type="submit"
              className="notiflix-button-change-unit-name"
            >
              Alterar
            </Button>
          </Form>
        </ModalUnitContent>
      ),
    });
  }, []);

  const handleSetDefaultContract = useCallback(
    async (contractId: string, e) => {
      e.preventDefault();
      try {
        Notiflix.Block.circle(`.notiflix-select-contract-${contractId}`);
        Notiflix.Block.circle(`.notiflix-contract`);
        // get details of selected contract
        const { data } = await api.get(
          `/company/my-contract-data?contractId=${contractId}`,
        );
        const { content } = data;
        defineContract({
          contractId: content[0].contractId,
          companyCode: content[0].companyCode,
          cnpj: content[0].cnpj,
          stipulator: content[0].stipulatorName,
          contractDate: content[0].contractDate,
        });
        // get all procuts options for this contract
        const { data: dataAllProducts } = await api.get(
          `/company/product-listing-for-selection?contractId=${contractId}`,
        );
        const { content: contentAllProducts } = dataAllProducts;
        if (contentAllProducts) {
          setProductsOptions(
            contentAllProducts.reduce(
              (
                acc: { title: string; value: string }[],
                act: { name: string; id: string },
              ) => {
                acc.push({
                  title: act.name,
                  value: act.id,
                });
                return acc;
              },
              [],
            ),
          );

          // get aditional fields of first product
          const idFirst = contentAllProducts[0].id;
          const { data: dataFirstProduct } = await api.get(
            `/company/list-of-products?productCode=${idFirst}`,
          );
          const { content: contentFirstProduct } = dataFirstProduct;

          if (contentFirstProduct && contentFirstProduct[0]) {
            setProductSelected(contentFirstProduct[0]);
          }
        }
      } catch (err) {
        Notiflix.Block.remove(`.notiflix-select-contract-${contractId}`);
        Notiflix.Notify.failure(
          'Ops.. Não conseguimos buscar os dados do contrato selecionado... Por favor, tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.remove('.notiflix-contract');
      }
    },
    [defineContract],
  );

  const actionsTemplateRow = useCallback(
    (row: ContractFormProps) => {
      return (
        <TableButtons>
          <Button
            orangeButton
            sausageLook
            noChangeColor
            onClick={() => handleChangeUnitName(row.contractId)}
          >
            Editar
          </Button>
          {row.contractId !== contract.contractId && (
            <Button
              greenCriticalButton
              sausageLook
              noChangeColor
              onClick={(e) => {
                handleSetDefaultContract(row.contractId, e);
              }}
              className={`notiflix-select-contract-${row.contractId}`}
            >
              Selecionar
            </Button>
          )}
        </TableButtons>
      );
    },
    [contract.contractId, handleChangeUnitName, handleSetDefaultContract],
  );

  const tableMemo = useMemo(() => {
    if (contractsData.length === 0) return <></>;
    return (
      <Table items={contractsData} className="notiflix-contracts-table">
        <Column
          field="contractId"
          header="Contrato"
          body={(data) => AddField(data.contractId, 'Contrato')}
        />
        <Column
          field="company"
          header="Empresa"
          body={(data) => AddField(data.companyCode, 'Empresa')}
        />
        <Column
          field="cnpj"
          header="CNPJ"
          body={(data) => AddField(data.cnpj, 'CNPJ')}
        />

        <Column
          field="unit"
          header="Unidade"
          body={(data) => AddField(data.unit, 'Unidade')}
        />
        <Column
          field="stipulatorName"
          header="Estipulante"
          body={(data) => AddField(data.stipulatorName, 'Estipulante')}
        />
        <Column
          headerStyle={{ width: '238px' }}
          bodyClassName="home-buttons-company"
          body={actionsTemplateRow}
        />
      </Table>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractsData, contract]);

  useEffect(() => {
    if (isAuthendicated) {
      getBanners();
      getInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description on update contract set new values to form fields
   */
  useEffect(() => {
    contractFormRef.current?.setData(contract);
  }, [contract]);
  /**
   * @description on update productSelected set new values to form fields
   */
  useEffect(() => {
    productFormRef.current?.setData(productSelected);
  }, [productSelected]);

  return (
    <Container
      gridTemplateAreas={[
        'cl cl cl cl',
        'sp mc mc sp2',
        'sp qa qa sp2',
        'sp ct ct sp2',
        's s s s',
      ]}
      gridTemplateColumns="1fr 4fr 4fr 1fr"
      gridTemplateRows="auto auto auto auto 1fr"
    >
      <Carousel autoplayInterval={6000} imgs={banners} />
      <MyContract>
        <Title>Meu Contrato</Title>
        <div>
          <FormWrapper
            onSubmit={() => {
              history.push('/alterar-dados-cadastrais');
            }}
            className="contract notiflix-contract"
            ref={contractFormRef}
          >
            <MyContractHeader>
              <h4>Contrato</h4>
              <Button outlined formLook type="submit">
                Alterar Dados Cadastrais
              </Button>
            </MyContractHeader>
            <Input
              name="contractId"
              insideInputTitle="Nº do contrato"
              themeColor={colors.palet.institutional}
              widthContainerDesktop="25%"
              disabled
            />
            <Input
              name="stipulator"
              insideInputTitle="Estipulante"
              themeColor={colors.palet.institutional}
              widthContainerDesktop="75%"
              disabled
            />
            <Input
              name="companyCode"
              insideInputTitle="Código da Empresa"
              themeColor={colors.palet.institutional}
              widthContainerDesktop="30%"
              disabled
            />
            <Input
              name="contractDate"
              insideInputTitle="Data da Contratação"
              themeColor={colors.palet.institutional}
              widthContainerDesktop="30%"
              disabled
            />
            <Input
              name="cnpj"
              insideInputTitle="CNPJ"
              themeColor={colors.palet.institutional}
              widthContainerDesktop="40%"
              disabled
            />
          </FormWrapper>
          <FormWrapper
            onSubmit={() => {
              //
            }}
            className="contract notiflix-product-container"
            ref={productFormRef}
          >
            <MyContractHeader>
              <h4>Produto</h4>
              <Select
                name="productSelect"
                options={productsOptions}
                changeCallback={handleChangeSelectedProduct}
                formLook
                liveReload
                className="min-select"
              />
            </MyContractHeader>
            <Input
              name="productName"
              insideInputTitle="Produto"
              themeColor={colors.palet.institutional}
              widthContainerDesktop="50%"
              disabled
            />
            <Input
              name="segmentation"
              insideInputTitle="Segmentação Assistencial"
              themeColor={colors.palet.institutional}
              widthContainerDesktop="50%"
              disabled
            />
            <Input
              name="AnsProtocol"
              insideInputTitle="Produto ANS"
              themeColor={colors.palet.institutional}
              widthContainerDesktop="25%"
              disabled
            />
            <Input
              name="coverage"
              insideInputTitle="Abrangência"
              themeColor={colors.palet.institutional}
              widthContainerDesktop="25%"
              disabled
            />
            <Input
              name="accommodation"
              insideInputTitle="Padrão de Acomodação"
              themeColor={colors.palet.institutional}
              widthContainerDesktop="50%"
              disabled
            />
          </FormWrapper>
        </div>
      </MyContract>
      <QuickAccessContainer>
        <Title>Acessos Rápidos</Title>
        <QuickRow>
          <QuickColumn>
            <ButtonWithOptionsBeneficiary
              title="Beneficiários"
              subtitle="Gerenciar Beneficiários"
              url="/beneficiarios"
              colorBase={colors.palet.institutional3}
              className="title-full-width beneficiary-button"
              options={[
                {
                  url: '/beneficiarios',
                  title: 'Solicitação 2º via carteira',
                },
                {
                  url: '/beneficiarios',
                  title: 'Alterar contrato/CNPJ',
                },
                {
                  url: '/beneficiarios',
                  title: 'Incluir dependente',
                },
                {
                  url: '/beneficiarios',
                  title: 'Alterar produto',
                },
                {
                  url: '/beneficiarios',
                  title: 'Alterar dados cadastrais',
                },
                {
                  url: '/beneficiarios',
                  title: 'Visualizar carência',
                },
                {
                  url: '/beneficiarios',
                  title: 'Rescindir beneficiário',
                },
              ]}
              closeParentsOnDocument
            />
          </QuickColumn>
          <QuickColumn>
            <ButtonWithOptions
              title="Inclusão de Beneficiário"
              options={[
                {
                  url: '/incluir-beneficiario',
                  title: 'Manualmente',
                },
                {
                  url: '/incluir-beneficiario-gerar-link',
                  title: 'Gerar link beneficiário',
                },
              ]}
              colorBase={colors.palet.institutional6}
              closeParentsOnDocument
            />
            <ButtonWithOptions
              title="Informações Financeiras"
              options={[
                {
                  url: '/demonstrativos-e-segunda-via-boleto',
                  title: 'Demonstrativos e 2º via boleto',
                },
                {
                  url: '/indice-reajustes',
                  title: 'Reajuste',
                },
                {
                  url: '/sinistralidade',
                  title: 'Sinistralidade',
                },
              ]}
              colorBase={colors.palet.institutional}
              closeParentsOnDocument
            />
          </QuickColumn>
        </QuickRow>
        <QuickRow>
          <QuickColumn>
            <ButtonWithOptions
              title="Documentos e Relatórios"
              options={[
                {
                  url: '/documentos-contrato',
                  title: 'Documentos contratuais',
                },
                {
                  url: '/relatorios',
                  title: 'Relatórios',
                },
                {
                  url: '/manuais-e-arquivos',
                  title: 'Manuais e arquivos',
                },
              ]}
              colorBase={colors.palet.institutional8}
              closeParentsOnDocument
            />
            <ButtonWithOptions
              title="T.R.U. (Tabela de Referência Unimed)"
              urlTitle="/tabela-referencia-unimed"
              options={[]}
              colorBase={colors.palet.institutional15}
              className="title-full-width"
              closeParentsOnDocument
            />
          </QuickColumn>
          <QuickColumn>
            <ButtonWithOptions
              closeParentsOnDocument
              title="Consultar Solicitações"
              options={[
                {
                  url: '/consulta-segunda-via-cartao',
                  title: '2º via da carteira',
                },
                {
                  url: '/consulta-alteracoes-cadastrais',
                  title: 'Alterações cadastrais',
                },
                {
                  url: '/consulta-alteracoes-contrato-cnpj',
                  title: 'Alterações de contrato/CNPJ',
                },
                {
                  url: '/consulta-alteracoes-produto',
                  title: 'Alterações de produto',
                },
                {
                  url: '/consulta-inclusao-beneficiario',
                  title: 'Inclusão de beneficiário',
                },
                {
                  url: '/consulta-rescisao',
                  title: 'Rescisão de beneficiários',
                },
              ]}
              colorBase={colors.palet.institutional9}
              className="title-full-width"
            />
          </QuickColumn>
        </QuickRow>
      </QuickAccessContainer>
      <Contract>
        {contractsData.length !== 0 && <Title>Contratos</Title>}
        <TableContent>{tableMemo}</TableContent>
      </Contract>
    </Container>
  );
};

export default HomeCompany;
