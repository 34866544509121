import styled from 'styled-components';

export const ModalContainer = styled.div`
  h2 {
    padding: 0 20px;
  }
  form {
    background: #fff;
    box-shadow: none;
    display: flex;
    flex-wrap: wrap;
    > button {
      & + button {
        margin-left: auto;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      padding: 30px 0 0 0 !important;
      row-gap: 8px !important;
      > div {
        > span {
          color: ${(props) => props.theme.colors.text.primary};
        }
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
`;
