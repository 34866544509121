import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useReleases } from '../../hooks/releasesAndTerms';

import { Container, Release, Title, Button } from './styles';

const Releases: React.FC = () => {
  const { releases } = useReleases();
  const releasesVisible = useMemo(() => {
    return releases.items?.slice(0, 3) ?? [];
  }, [releases]);
  const history = useHistory();

  return (
    <Container className="notiflix-loading-releases">
      <Title>Comunicados</Title>
      {releasesVisible.length === 0 && (
        <Release>Não há comunicados no momento...</Release>
      )}
      {releasesVisible.map((release) => (
        <Release
          key={release.id}
          onClick={() => history.push(`/comunicados?release=${release.id}`)}
        >
          {`${release.title} ${release.text}`} <div>[+]</div>
        </Release>
      ))}

      <Button
        modal
        autoWidth
        formLook
        onClick={() => history.push(`/comunicados`)}
      >
        Ver todos
      </Button>
    </Container>
  );
};

export default Releases;
