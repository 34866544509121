import styled from 'styled-components';

export const Container = styled.div`
  grid-column: 2/3;

  > form {
    display: flex;
    flex-wrap: wrap;

    & + form {
      margin-top: 24px;
    }
    &.form-variable {
      > button {
        margin-left: auto;
        min-width: 150px;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

export const Title = styled.h1`
  margin: 30px 0;
  font-family: 'Unimed-Slab-Bold';
  text-align: center;
  font-size: 2.55rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional};
  @media (max-width: 768px) {
    grid-column: 1;
    margin: 30px;
  }
`;
