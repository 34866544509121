import styled from 'styled-components';
import CarouselImage from '../../../components/Carousel';
import ButtonElement from '../../../components/Button';

import PageWrapper from '../../../components/PageWrapper';

export const Container = styled(PageWrapper)`
  margin-top: 20px;
  @media (max-width: 768px) {
    margin-top: 0;
    row-gap: 30px;
    margin-bottom: 30px;
  }
`;

export const Button = styled(ButtonElement)`
  font-size: 1.4rem;
  font-family: 'Unimed-Sans-Bold';
  color: ${(props) => props.theme.colors.text.white} !important;
  box-shadow: none;
  border: none;
  &.guia-medico {
    transition: all 0.3s;
    background-color: ${(props) => props.theme.colors.palet.institutional8};
    &:hover {
      transition: all 0.3s;
      box-shadow: 0 0 12px ${(props) => props.theme.colors.text.white};
      border: 1px solid white;
    }
  }

  width: 100%;
`;

export const Carousel = styled(CarouselImage)`
  @media (min-width: 769px) {
    grid-area: carousel;
  }
  @media (max-width: 768px) {
    grid-column: 1/2;
  }
`;

export const ContactBlockContainer = styled.div`
  @media (min-width: 769px) {
    grid-area: contact;
  }
`;

export const ButtonsWithOptionsContainer = styled.div`
  @media (min-width: 769px) and (max-width: 1199px) {
    grid-area: buttons;
    grid-column: 2/4;
    min-width: 430px;
  }
  @media (min-width: 1200px) {
    grid-area: buttons;
  }
  @media (max-width: 768px) {
    grid-column: 1/2;
    padding: 0 30px;
  }
`;

export const ReleasesContainer = styled.div`
  @media (min-width: 769px) {
    grid-area: releases;
    min-width: 280px;
  }
  @media (max-width: 768px) {
    display: none;
    grid-column: 1/2;
    padding: 2px 30px 30px;
  }
`;

export const QuickAccessContainer = styled.div`
  @media (min-width: 769px) and (max-width: 1199px) {
    grid-area: q;
    > div {
      > div {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
      }
    }
  }
  @media (min-width: 1200px) {
    grid-area: quick;
  }
  @media (max-width: 768px) {
    grid-column: 1/2;
    padding: 2px 30px 0;
  }
  @media (max-width: 320px) {
    grid-column: 1/2;
    padding: 2px 30px 0;
  }
`;
export const QuickAccessContainer2 = styled.div`
  @media (min-width: 769px) {
    grid-area: q2;
    display: grid;
    grid-template-rows: 48px 48px;
    row-gap: 8px;
    min-width: 200px;
  }
  @media (min-width: 1200px) {
    /* margin-top: auto; */
    grid-area: quick2;
  }

  @media (max-width: 768px) {
    grid-column: 1;
    display: flex;
    max-width: 100%;
    padding: 0 30px;
    overflow-x: scroll;
    column-gap: 8px;
    flex-wrap: wrap;
    order: 3;
    > button {
      /* font-family: 'Unimed-Sans-SemiBold'; */
      width: 100%;
      margin-top: 8px;
      font-size: 1rem;
    }
  }
`;

export const ContactContainer = styled.div`
  @media (min-width: 769px) {
    grid-area: contact;
  }

  @media (max-width: 768px) {
    grid-column: 1/2;
    display: none;
  }
`;

export const Title = styled.h1`
  font-family: 'Unimed-Slab-Bold';
  font-size: 1.8rem;
  color: ${(props) => props.theme.colors.text.grayishToWhite};
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;

  @media (min-width: 769px) {
    grid-area: txt;
  }
  @media (max-width: 768px) {
    grid-column: 1/2;
    display: none;
  }
`;
