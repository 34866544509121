import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  .p-carousel-content {
    position: relative;
    .p-carousel-container {
      .p-carousel-item {
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        picture {
          width: 100%;
          img {
            width: 100%;
          }
        }
        @media (max-width: 768px) {
          border-radius: 0px;
        }
      }
      .p-carousel-prev,
      .p-carousel-next {
        display: none !important;
      }
    }
    .p-carousel-indicators {
      position: absolute;
      bottom: 5%;
      left: 50%;
      transform: translateX(-50%);
      @media (max-width: 768px) {
        bottom: 8px;
        padding: 0;
      }

      .p-carousel-indicator {
        padding: 0 10px;
        > button {
          width: 10px;
          height: 10px;
          border: 3px solid #fff;
          border-radius: 50%;
          overflow: hidden;
        }
        &.p-highlight {
          > button {
            background: #fff;
          }
        }
      }
    }
  }
`;

export const Acessibility = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
