import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiInfo, FiTrash } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';
import Button from '../../components/Button';
import FormWrapper from '../../components/FormWrapper';
import Input from '../../components/Input';
import Modal, { ModalHandles } from '../../components/Modal';
import PageWrapper from '../../components/PageWrapper';
import { Column } from '../../components/Table';
import Title from '../../components/Title';
import * as Helper from './helpers';
import * as S from './styles';
import {
  IDeleteUserTimeTableRequestBody,
  IUserAppointmentSchedule,
} from './types';

const SchedulingPage: React.FC = () => {
  const { colors } = useTheme();
  const showAppoitmentModalRef = useRef<ModalHandles>(null);
  const deleteFormRef = useRef<FormHandles>(null);
  const deleteModalRef = useRef<ModalHandles>(null);

  const [selectedAppointment, setSelectedAppointment] =
    useState<IUserAppointmentSchedule>();
  const [appoitments, setAppoitments] = useState<IUserAppointmentSchedule[]>(
    [],
  );

  const getScheduledAppointments = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');
      const data = await Helper.getScheduledAppointments();
      setAppoitments(data);
    } catch (error) {
      console.log(error);
      setAppoitments([]);
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, []);

  const loadData = useCallback(async () => {
    await getScheduledAppointments();
  }, [getScheduledAppointments]);

  const handleSelectAppoitment = useCallback(
    (appoitment: IUserAppointmentSchedule) => {
      setSelectedAppointment(appoitment);
      showAppoitmentModalRef.current?.show();
    },
    [],
  );

  const handleDeleteAppoitment = useCallback(async () => {
    const data = deleteFormRef.current?.getData();
    deleteFormRef.current?.setErrors({});
    const shape = {} as any;

    const reason = deleteFormRef.current?.getFieldValue('reason');
    if (reason === '') {
      deleteFormRef.current?.setErrors({
        reason: 'Preencha o motivo para cancelar',
      });
      return;
    }

    const schema = Yup.object().shape(shape);
    await schema.validate(data, {
      abortEarly: false,
    });
    try {
      Notiflix.Loading.circle();
      deleteModalRef.current?.close();
      const result =
        deleteFormRef.current?.getData() as IDeleteUserTimeTableRequestBody;

      await Helper.deleteUserTimeTable(result);
      await getScheduledAppointments();

      Notiflix.Notify.success('Consulta Cancelada com sucesso');
      deleteFormRef.current?.setFieldValue('reason', '');
    } catch (error) {
      Notiflix.Notify.failure(error?.response?.data?.message);
    } finally {
      Notiflix.Loading.remove();
    }
  }, [getScheduledAppointments]);

  const getAddressLine = useCallback(() => {
    if (selectedAppointment) {
      const { houseNumber, streetDescription, district, zipCode, city } =
        selectedAppointment;

      if (houseNumber && streetDescription && district && zipCode && city) {
        return `${selectedAppointment.houseNumber} ${selectedAppointment.streetDescription}, ${selectedAppointment.district}, ${selectedAppointment.zipCode} - ${selectedAppointment.city}`;
      }
    }

    return selectedAppointment?.doctorOffice;
  }, [selectedAppointment]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <PageWrapper gridTemplateRows="auto auto auto 1fr">
        <S.Container>
          <Title themeColor={colors.palet.institutional}>Consulta</Title>
        </S.Container>

        <S.Table
          items={appoitments}
          columnResizeMode="fit"
          className="notiflix-table-loading"
        >
          <Column field="name" header="Beneficiário" />
          <Column field="scheduledTo" header="Data e Hora" />
          <Column field="typeDescription" header="Tipo de Consulta" />
          <Column field="doctorName" header="Médico" />
          <Column field="specialize" header="Especialidade" />
          <Column
            field="formUrl"
            header="Link Formulário"
            body={(row) => {
              return (
                <>
                  {row.formUrl !== null && (
                    <S.ColumnLink
                      href={row.formUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        style={{
                          backgroundColor: colors.palet.institutional,
                          minWidth: 80,
                        }}
                      >
                        <p style={{ fontSize: 12 }}>Acessar Formulário</p>
                      </Button>
                    </S.ColumnLink>
                  )}
                </>
              );
            }}
          />
          <Column
            field="teleconsultationUrl"
            header="Link Teleconsulta"
            body={(row) => (
              <>
                {row.teleconsultationUrl !== null && (
                  <S.ColumnLink
                    href={row.teleconsultationUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      style={{
                        backgroundColor: colors.palet.institutional,
                        minWidth: 80,
                      }}
                    >
                      <p style={{ fontSize: 12 }}>Acessar Consulta</p>
                    </Button>
                  </S.ColumnLink>
                )}
              </>
            )}
          />
          <Column
            body={(row) => (
              <S.ButtonsContainer>
                <S.IconButton
                  backgroundColor={colors.palet.institutional}
                  type="button"
                  onClick={() => handleSelectAppoitment(row)}
                >
                  <FiInfo size={18} title="Visualizar" />
                </S.IconButton>

                <S.IconButton
                  backgroundColor="red"
                  type="button"
                  onClick={() => {
                    setSelectedAppointment(row);
                    deleteModalRef.current?.show();
                  }}
                >
                  <FiTrash size={18} title="Cancelar" />
                </S.IconButton>
              </S.ButtonsContainer>
            )}
          />
        </S.Table>
      </PageWrapper>

      <Modal
        ref={showAppoitmentModalRef}
        title="Dados do Agendamento"
        fitContent
      >
        <S.AppoitmentModalContainer>
          <S.AppointmentInfo>
            <span className="label">Beneficiário</span>
            <span className="info">{selectedAppointment?.name}</span>
          </S.AppointmentInfo>

          <S.AppointmentInfo>
            <span className="label">Data</span>
            <span className="info">{selectedAppointment?.scheduledTo}</span>
          </S.AppointmentInfo>

          <S.AppointmentInfo>
            <span className="label">Cartão</span>
            <span className="info">{selectedAppointment?.card}</span>
          </S.AppointmentInfo>

          <S.AppointmentInfo>
            <span className="label">Procedimento</span>
            <span className="info">
              {selectedAppointment?.procedureDescription}
            </span>
          </S.AppointmentInfo>

          <S.AppointmentInfo>
            <span className="label">Médico</span>
            <span className="info">{selectedAppointment?.doctorName}</span>
          </S.AppointmentInfo>

          <S.AppointmentInfo>
            <span className="label">Conselho de classe</span>
            <span className="info">
              {selectedAppointment?.classCouncil} -{' '}
              {selectedAppointment?.classCouncilNumber} /{' '}
              {selectedAppointment?.classCouncilState}
            </span>
          </S.AppointmentInfo>

          <S.AppointmentInfo>
            <span className="label">Tipo de Consulta</span>
            <span className="info">{selectedAppointment?.typeDescription}</span>
          </S.AppointmentInfo>

          <S.AppointmentInfo>
            <span className="label">Especialidade</span>
            <span className="info">{selectedAppointment?.specialize}</span>
          </S.AppointmentInfo>

          <S.AppointmentInfo className="w-full">
            <span className="label">Endereço</span>
            <span className="info">{getAddressLine()}</span>
          </S.AppointmentInfo>

          {selectedAppointment?.formUrl && (
            <S.AppointmentInfo>
              <span className="label">Link Formulário</span>
              <span className="info">
                <a
                  href={selectedAppointment?.formUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    style={{
                      backgroundColor: colors.palet.institutional,
                      minWidth: 150,
                    }}
                  >
                    <p style={{ fontSize: '1.6rem' }}>Acessar Formulário</p>
                  </Button>
                </a>
              </span>
            </S.AppointmentInfo>
          )}

          {selectedAppointment?.isTeleconsultation === 'S' &&
            selectedAppointment.teleconsultationUrl && (
              <S.AppointmentInfo>
                <span className="label">Link Teleconsulta</span>
                <span className="info">
                  <a
                    href={selectedAppointment?.teleconsultationUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      style={{
                        backgroundColor: colors.palet.institutional,
                        minWidth: 150,
                      }}
                    >
                      <p style={{ fontSize: '1.6rem' }}>Acessar Consulta</p>
                    </Button>
                    {/* {selectedAppointment?.teleconsultationUrl} */}
                  </a>
                </span>
              </S.AppointmentInfo>
            )}
        </S.AppoitmentModalContainer>
      </Modal>

      <Modal
        ref={deleteModalRef}
        title="Cancelar Agendamento"
        subtitle="Deseja realmente cancelar o agendamento?"
        fitContent
      >
        <FormWrapper
          ref={deleteFormRef}
          onSubmit={() => {
            //
          }}
          style={{ background: 'none', boxShadow: 'none', padding: 0 }}
          formLook
        >
          <S.DeleteFormContainer>
            <div style={{ visibility: 'hidden', display: 'none' }}>
              <Input
                name="scheduleId"
                type="hidden"
                value={selectedAppointment?.scheduleId}
              />
            </div>

            <Input
              name="reason"
              formLook
              label="Informe o motivo do cancelamento"
              placeholder="Informe o motivo do cancelamento"
            />

            <Button
              type="button"
              orangeButton
              onClick={handleDeleteAppoitment}
              formLook
            >
              Cancelar Agendamento
            </Button>
          </S.DeleteFormContainer>
        </FormWrapper>
      </Modal>
    </>
  );
};

export default SchedulingPage;
