import styled from 'styled-components';
import FormWrapper from '../../../components/FormWrapper';
import TableComponent from '../../../components/Table';
import ButtonComponent from '../../../components/Button';
import SelectComponent from '../../../components/Select';
import DatePickerComponent from '../../../components/DatePicker';

export const DatePicker = styled(DatePickerComponent)`
  @media (max-width: 768px) {
    margin-bottom: 15px;
    width: 100px;
  }
`;

export const Table = styled(TableComponent)`
  grid-column: 1/11;
  margin-bottom: 150px;

  @media (max-width: 768px) {
    grid-column: 1;
    margin-bottom: 30px;
  }
`;

export const Select = styled(SelectComponent)`
  @media (max-width: 758px) {
    min-width: 200px;
  }
`;

export const Title = styled.h1`
  grid-column: 1/11;
  text-transform: uppercase;
  font-size: 2.55rem;
  line-height: 2.4rem;
  font-family: 'Unimed-Slab-Bold';
  color: ${(props) => props.theme.colors.palet.institutional9};
  text-align: center;
  padding: 30px;

  @media (max-width: 768px) {
    grid-column: 1;
    font-size: 1.7rem;
    line-height: 1.5rem;
  }
`;

export const Tag = styled.span`
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 0.7rem;
  position: relative;
  padding-left: 16px;
  display: flex;
  white-space: nowrap;
  > svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &.rescindido {
    color: ${(props) => props.theme.colors.palet.institutional};
    > svg {
      path {
        stroke: ${(props) => props.theme.colors.palet.institutional};
      }
      circle {
        fill: ${(props) => props.theme.colors.palet.institutional};
      }
    }
  }
  &.rejeitado {
    color: ${(props) => props.theme.colors.palet.institutional7};
    > svg {
      path {
        stroke: ${(props) => props.theme.colors.palet.institutional7};
      }
      circle {
        fill: ${(props) => props.theme.colors.palet.institutional7};
      }
    }
  }
  &.emAberto {
    font-size: 0.62rem;
    color: ${(props) => props.theme.colors.palet.institutional6};
    > svg {
      path {
        stroke: ${(props) => props.theme.colors.palet.institutional6};
      }
      circle {
        fill: ${(props) => props.theme.colors.palet.institutional6};
      }
    }
  }
`;

export const FormWrapperPeriodDate = styled(FormWrapper)`
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  grid-column: 1/11;

  @media (max-width: 768px) {
    grid-column: 1;
    > div {
      flex: 1 1;
    }
  }
`;

export const PeriodButton = styled(ButtonComponent)`
  text-transform: uppercase;
  margin-left: auto;
`;

export const ModalContainer = styled.div`
  h2 {
    font-family: 'Unimed-Sans-SemiBold';
  }

  display: flex;
  flex-direction: column;
  position: relative;
  width: 350px;

  @media (max-width: 768px) {
    /* width: 75%; */
    width: 220px;
  }
`;

export const ModalPart = styled.div``;

export const FilesContainer = styled.div`
  /* height: 150px; */
  max-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 24px 0;

  overflow-y: auto;
  button {
    font-family: 'Unimed-Sans-SemiBold';
    border: none;
    outline: none;
    background: transparent;
    color: ${(props) => props.theme.colors.text.primary};
    transition: 300ms;
    &:hover {
      color: ${(props) => props.theme.colors.text.black};
    }
  }
`;

export const ButtonContainerModal = styled.div`
  padding-top: 15px;
`;

export const InterfaceContainer = styled.div``;

export const ModalButton = styled(ButtonComponent)`
  margin-top: 10px;
`;
