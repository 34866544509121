import React, { useEffect, useRef, useState } from 'react';

// import { Container } from './styles';
interface Props {
  min: number;
  onExpire: () => Promise<void>;
}

const secondsToHHMMSS = (sec: number) => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec % 3600) / 60);
  const seconds = sec % 60;

  return `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }:${seconds < 10 ? `0${seconds}` : seconds}`;
};

const Timer: React.FC<Props> = ({ min, onExpire }) => {
  const [time, setTime] = useState(min * 60);
  const counter = useRef(time);

  useEffect(() => {
    const interval = setInterval(() => {
      counter.current -= 1;
      if (counter.current === 0) {
        clearInterval(interval);
        onExpire();
      }
      setTime((prev) => Math.max(prev - 1, 0));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [onExpire]);

  return (
    <div>
      <span>{secondsToHHMMSS(time)}</span>
    </div>
  );
};

export default Timer;
