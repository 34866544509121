/* eslint-disable max-len */
import styled, { css } from 'styled-components';
import Button from '../../components/Button';

import DatePickerComponent from '../../components/DatePicker';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
  > button:nth-child(1) {
    @media (max-width: 768px) {
      margin-bottom: 8px;
    }
  }
  > button {
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (min-width: 769px) {
      width: 150px;
    }
  }
`;

export const Container = styled.div`
  margin-bottom: 30px;

  @media (min-width: 769px) and (max-width: 1199px) {
    grid-column: 1/11;
    padding: 0 30px;
  }
  @media (min-width: 1200px) {
    grid-column: 2/10;
  }
`;
export const Title = styled.h1`
  font-family: 'Unimed-Slab-Bold';
  font-size: 2.55rem;
  line-height: 2.4rem;

  text-align: center;
  letter-spacing: -1.19px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional};
  padding: 30px 0;
  @media (max-width: 1200px) {
    font-size: 2.25rem;
    line-height: 2.1rem;
  }
  @media (max-width: 768px) {
    font-size: 1.7rem;
    line-height: 1.5rem;
    padding: 30px;
  }
`;

export const DatePicker = styled(DatePickerComponent)``;

export const TableContainer = styled.div`
  filter: drop-shadow(0px 0px 10px rgb(196 203 207 / 40%));
  .positionRight {
    text-align: right !important;
    padding-right: 1vw !important;
  }

  #authorization .p-datatable-wrapper > table {
    /* width: calc(100% - 16px);
    margin-left: 8px; */
    tbody {
      tr {
        &.p-row-expanded {
          & > td > div {
            margin: 0 10px;
            & > div > div.p-datatable-footer {
              display: none;
            }
          }
        }
      }
    }
  }

  #authorization .p-datatable-wrapper table tbody .p-row-expanded {
    background: transparent;
    padding: 24px;
    @media (max-width: 768px) {
      background: ${(props) => props.theme.colors.palet.institutional11};
    }

    tr[role='row'] {
      padding: 0px;
      max-width: 100%;
    }

    & > td {
      padding: 0;

      &
        .p-datatable
        .p-datatable-wrapper
        table
        thead.p-datatable-thead
        tr
        th:first-child {
        border-radius: 0;
      }
      &
        .p-datatable
        .p-datatable-wrapper
        table
        thead.p-datatable-thead
        tr
        th:last-child {
        border-radius: 0;
      }
    }
  }

  #authorization .p-datatable-wrapper table tbody tr td.detalhe {
    button {
      text-align: center;
      width: 1rem;
      height: 1rem;
      background-image: url("data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.5 7C13.5 10.59 10.59 13.5 7 13.5C3.41004 13.5 0.5 10.59 0.5 7C0.5 3.41004 3.41004 0.5 7 0.5C10.59 0.5 13.5 3.41004 13.5 7Z' stroke='%235B5C65' strokeLinecap='round' strokeLinejoin='round' /%3E%3Cpath d='M7 4.20117V9.80117' stroke='%235B5C65' strokeLinecap='round' strokeLinejoin='round' /%3E%3Cpath d='M4.2002 7H9.80019' stroke='%235B5C65' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E");
      background-size: cover;
      background-repeat: no-repeat;

      &[aria-expanded='true'] {
        background-image: url("data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.5 7C13.5 10.59 10.59 13.5 7 13.5C3.41004 13.5 0.5 10.59 0.5 7C0.5 3.41004 3.41004 0.5 7 0.5C10.59 0.5 13.5 3.41004 13.5 7Z' stroke='%235B5C65' strokeLinecap='round' strokeLinejoin='round' /%3E%3Cpath d='M4.2002 7H9.80019' stroke='%235B5C65' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E");
      }
      &:focus {
        box-shadow: none !important;
      }
    }
    @media (max-width: 768px) {
      width: 40px;
      text-align: right;
      position: absolute;
      z-index: 1;
      padding: 0;
      min-width: auto;
      height: 100%;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${(props) => props.theme.colors.palet.institutional12};
      border-radius: 10px 0 0 10px;
      > button {
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.5 7C13.5 10.59 10.59 13.5 7 13.5C3.41004 13.5 0.5 10.59 0.5 7C0.5 3.41004 3.41004 0.5 7 0.5C10.59 0.5 13.5 3.41004 13.5 7Z' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round' /%3E%3Cpath d='M7 4.20117V9.80117' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round' /%3E%3Cpath d='M4.2002 7H9.80019' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E");

        &[aria-expanded='true'] {
          background-image: url("data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.5 7C13.5 10.59 10.59 13.5 7 13.5C3.41004 13.5 0.5 10.59 0.5 7C0.5 3.41004 3.41004 0.5 7 0.5C10.59 0.5 13.5 3.41004 13.5 7Z' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round' /%3E%3Cpath d='M4.2002 7H9.80019' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E");
        }
      }
    }
  }
`;

export const ModalDetail = styled.div`
  font-family: 'Unimed-Sans-Book';
  min-width: 280px;
`;

export const ModalDetailRow = styled.div`
  padding: 0px 30px;
  & + div {
    margin-top: 24px;
  }
`;

export const ModalDetailTitle = styled.div`
  font-size: 0.85rem;
  text-align: left;
`;

export const ModalDetailValue = styled.div`
  font-size: 1.55rem;
  text-align: left;
`;

export const ModalObservation = styled.div`
  font-family: 'Unimed-Sans-Book';
`;

export const ModalObservationTitle = styled.div`
  font-family: 'Unimed-Slab-Bold';
  font-size: 1.55rem;
  text-align: left;
  padding: 0 30px;
  color: ${(props) => props.theme.colors.text.primary};
`;

export const ModalObservationContent = styled.div`
  font-size: 1rem;
  text-align: left;
  margin-top: 56px;
  padding: 0 30px 30px;
  color: ${(props) => props.theme.colors.text.primary};
`;

interface StepContainerProps {
  defineHeight?: string;
}

export const Steps = styled.section<StepContainerProps>`
  position: relative;
  grid-column: 2/10;
  margin-bottom: 30px;
  ${(props) =>
    props.defineHeight &&
    css`
      height: ${props.defineHeight};
    `}
  @media(max-width: 768px) {
    grid-column: 1;
  }
`;

interface StepsProps {
  show: boolean;
}

const initialStateSteps = css`
  left: 0;
  top: 0;
  width: 100%;
  position: absolute;
  transform: translateX(-20px);
  visibility: hidden;
  opacity: 0;
  transition: 300ms;
  height: 0;
  overflow: hidden;
`;
const showSteps = css`
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
  height: initial;
  overflow: initial;
`;

export const Step1 = styled.section<StepsProps>`
  ${initialStateSteps}
  position: initial;
  display: none;

  ${(props) => props.show && showSteps}
  ${(props) =>
    props.show &&
    css`
      display: block;
    `}
`;

export const ReportsContainer = styled.div`
  margin-bottom: 24px;
  form {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const CheckboxGroup = styled.div`
  display: flex;
  row-gap: 8px;
  column-gap: 16px;
`;

export const ReportsButton = styled(Button)`
  margin-left: auto;
  margin-top: auto;
`;

export const Step2 = styled.section<StepsProps>`
  ${initialStateSteps}

  ${(props) => props.show && showSteps}

  form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    /* > div {
      flex: 1;
    } */

    @media (max-width: 768px) {
      > div:nth-of-type(1) {
        width: 100%;
        flex: 1 0 100%;
      }
      > button {
        flex: 1;
      }
    }
  }
`;

export const Step3 = styled.section<StepsProps>`
  ${initialStateSteps}

  ${(props) => props.show && showSteps}

  form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    /* > div {
      flex: 1;
    } */

    @media (max-width: 768px) {
      > div:nth-of-type(1) {
        width: 100%;
        flex: 1 0 100%;
      }
      > button {
        flex: 1;
      }
    }
  }
`;

export const ButtonsStep2 = styled.div`
  flex: 1 0 100% !important;
  margin-top: 16px;
  display: flex;
  > button {
    &:nth-child(1) {
      margin-right: auto;
    }
    & + button {
      margin-left: 8px;
    }
  }
`;
