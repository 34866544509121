/* eslint-disable max-len */
import React from 'react';
import { svgProps } from '..';

const BookSvg: React.FC<svgProps> = ({ className }) => {
  return (
    <svg
      width="26"
      height="30"
      viewBox="0 0 26 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7134 19.285V1.07139L24.642 0H3.72851C3.23707 0.00543783 2.75067 0.0998138 2.29285 0.278561C1.83137 0.483644 1.41549 0.778822 1.06961 1.14678C0.723725 1.51474 0.454811 1.94806 0.278638 2.42134C0.103367 2.84282 0.0088726 3.29348 7.71689e-05 3.74986V24.1063C-0.00507348 24.5987 0.0896896 25.0871 0.278638 25.5419C0.658232 26.4576 1.38123 27.1883 2.29285 27.5776C2.75067 27.7563 3.23707 27.8507 3.72851 27.8561H4.28563V25.7133H3.72851C3.5147 25.714 3.30309 25.6702 3.10711 25.5848C2.71917 25.4239 2.41091 25.1156 2.24999 24.7277C2.18153 24.5275 2.14536 24.3178 2.14286 24.1063V23.0349C2.14536 22.8234 2.18153 22.6136 2.24999 22.4135C2.41091 22.0255 2.71917 21.7173 3.10711 21.5564C3.30309 21.4709 3.5147 21.4271 3.72851 21.4278H23.5706V25.7133H14.9995V27.8561H24.642L25.7134 26.7847V21.4278V19.285ZM23.5706 2.14278V19.285H4.28572V2.14278H23.5706ZM2.14286 19.6493L2.14294 19.6492V3.74326L2.14286 3.74986V19.6493ZM6.42822 4.28143H8.571V6.42421H6.42822V4.28143ZM6.42822 8.57092H8.571V10.7137H6.42822V8.57092ZM8.571 12.8594H6.42822V15.0022H8.571V12.8594ZM9.64243 26.7643L7.02824 29.9999H6.42827V23.5716H12.8566V29.9999H12.2566L9.64243 26.7643Z"
        fill="white"
      />
    </svg>
  );
};

export default BookSvg;
