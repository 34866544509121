import React, { useCallback } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
// import './datepicker-light-themestyles.css';
import { ReactDatePickerProps, registerLocale } from 'react-datepicker';

import { ptBR } from 'date-fns/locale';

import { FiChevronDown } from 'react-icons/fi';
import { InputDate, Container, Error, SheduleSvg, Title } from './styles';
import { formatDate } from '../../utils/formatt';

export interface DatePickerProps extends ReactDatePickerProps {
  name: string;
  placeholder?: string;
  error?: string;
  icon?: boolean;
  className?: string;
  title?: string;
  formLook?: boolean;
  disabled?: boolean;
  isValid?: boolean;
  widthContainerDesktop?: string;
  themeColor?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  name,
  placeholder,
  onChange,
  error,
  icon,
  className,
  title,
  formLook,
  disabled,
  isValid,
  widthContainerDesktop,
  showTimeSelectOnly,
  themeColor,
  ...rest
}) => {
  registerLocale('pt-BR', ptBR);

  const handleKeyDown = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const data = event.target.value;
      if (data) {
        if (!showTimeSelectOnly) {
          // eslint-disable-next-line no-param-reassign
          event.target.value = formatDate(data);
        } else {
          // eslint-disable-next-line no-param-reassign
          event.target.value = data;
        }
      }
    },
    [showTimeSelectOnly],
  );

  return (
    <Container
      className={className}
      disabled={disabled}
      isValid={isValid}
      isError={!!error}
      widthContainerDesktop={widthContainerDesktop}
      formLook={formLook}
    >
      {title && formLook && <Title themeColor={themeColor}>{title}</Title>}

      <InputDate
        hasIcon={icon}
        showTimeSelectOnly={showTimeSelectOnly}
        formLook={formLook}
        name={name}
        dateFormat="dd/MM/yyyy"
        placeholderText={placeholder}
        locale="pt-BR"
        onChange={onChange}
        onChangeRaw={handleKeyDown}
        onError={!!error}
        showMonthDropdown
        showYearDropdown
        peekNextMonth
        dropdownMode="select"
        disabled={disabled}
        isValid={isValid}
        {...rest}
      />
      {icon && <SheduleSvg className="shedule" />}
      {formLook && (
        <FiChevronDown
          size={24}
          className={`chevron ${error ? 'onError' : ''}`}
        />
      )}
      {error && (
        <Error>
          <span>{error}</span>
        </Error>
      )}
    </Container>
  );
};

export default DatePicker;
