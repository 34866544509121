/* eslint-disable max-len */
import React from 'react';
import { svgProps } from '..';

const ListSvg: React.FC<svgProps> = () => {
  return (
    <svg
      width="30"
      height="25"
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6364 0H1.36364L0 1.36364V23.1818L1.36364 24.5455H28.6364L30 23.1818V1.36364L28.6364 0ZM27.2727 21.8182H2.72727V2.72727H27.2727V21.8182ZM21.8182 5.45326H5.45459V6.8169H21.8182V5.45326ZM5.45459 9.54674H19.0909V10.9104H5.45459V9.54674ZM13.6364 13.6367H5.45459V15.0003H13.6364V13.6367ZM5.45459 17.7266H16.3637V19.0903H5.45459V17.7266Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};

export default ListSvg;
