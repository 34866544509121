/* eslint-disable max-len */
import React from 'react';

const RefreshSvg: React.FC = () => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 17.9976V11.9976H17"
        stroke="#5B5C65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1.99756V7.99756H7"
        stroke="#5B5C65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.49 6.99767C19.983 5.56467 19.121 4.2827 17.985 3.2727C16.848 2.2627 15.475 1.55767 13.992 1.22167C12.509 0.88667 10.965 0.931666 9.505 1.35467C8.044 1.77667 6.71501 2.56269 5.64001 3.63769L1 7.99767M23 11.9977L18.36 16.3577C17.285 17.4327 15.956 18.2187 14.495 18.6407C13.035 19.0637 11.491 19.1087 10.008 18.7737C8.525 18.4377 7.15201 17.7326 6.01501 16.7226C4.87901 15.7126 4.01701 14.4307 3.51001 12.9977"
        stroke="#5B5C65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefreshSvg;
