/* eslint-disable camelcase */
/* eslint-disable no-new */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FormHandles } from '@unform/core';
import { mask } from 'remask';
import { FaPlus } from 'react-icons/fa';
import Notiflix from 'notiflix';
import { FiXCircle } from 'react-icons/fi';
import {
  ANSnumber,
  ActuationArea,
  Back,
  BackBody,
  BackTooltip,
  BackTooltipContent,
  BeneficiaryCardsSection,
  BeneficiarySelectSection,
  Card,
  CardSection,
  CardWrapper,
  CodANS,
  CodCNS,
  Container,
  FormWrapper,
  Front,
  FrontBody,
  FrontHeader,
  FrontTooltip,
  FrontTooltipContent,
  Infos,
  QRCodeCard,
  QRCodeSection,
  SAC,
  Shortcomings,
  SwitchComponent,
  SwitchContainer,
  Title,
  TooltipButton,
  TabsContainer,
  TabsOptions,
  BackBodyFooter,
  BackBodyFooterCenter,
  BackBodyFooterLeft,
} from './styles';
import { BeneficiaryUser, UserCommon, useAuth } from '../../hooks/auth';
import api from '../../services/api/api';
import SelectUnform, { OptionProps } from '../../components/SelectUnform';
import Timer from './Timer';
import { useOnlineMode } from '../../hooks/useOnlineMode';
import QRCodeComponent from './QRCode';

interface CardInfoItem {
  product: string;
  contractedUnimedDescription: string;
  hiringType: string;
  descriptionTypeOfContract: string;
  cooperated: string;
  userPlanId: string;
  birthDate: string;
  accommodation: string;
  validityStartDate: string;
  cardExpirateDate: string;
  beneficiaryName: string;
  serviceNetwork: string;
  billingLocation: string;
  coverage: string;
  protocolNumber: string;
  cpt: string;
  contractorName: string;
  segmentation: string;
  descTrail1: string;
  descTrail2: string;
  ds_trilha_3: string;
  descTrailQrCode: string;
  cns: string;
  ansProtocol: string;
  descRegulation: string;
  groupCities: Array<string>;
  descriptionShortcomings: Array<{
    shortage: string;
    validityEndDate: any;
  }>;
  dsInfoSOS?: string;
}

interface CardGetInfoResponse {
  result: number;
  message: string;
  data: Array<CardInfoItem>;
}

interface ListBeneficiaryAndDependentResponse {
  userUnimedId: number;
  phisicalPersonCode: string;
  insuredName: string;
  nrCartao: string;
}

const mysqlToPTBR = (date: string) => {
  const dateArray = date.split('T')[0].split('-');
  return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
};

const selectOptionsStorage = () => {
  const storage = localStorage.getItem('@unimedBeneficiaries');
  if (storage) {
    const parsed = JSON.parse(storage);
    return parsed.map((item: ListBeneficiaryAndDependentResponse) => ({
      userUnimedId: item.userUnimedId,
      phisicalPersonCode: item.phisicalPersonCode,
      insuredName: item.insuredName,
      nrCartao: item.nrCartao,
    })) as ListBeneficiaryAndDependentResponse[];
  }
  return [] as ListBeneficiaryAndDependentResponse[];
};

const getOnLocalStorage = (id: string) => {
  try {
    const storage = localStorage.getItem('@unimedBeneficiaries');
    if (storage) {
      const parsed = JSON.parse(storage) as (CardInfoItem & {
        userUnimedId: number;
      })[];
      const find = parsed.find(
        (item) => String(item.userUnimedId) === String(id),
      );
      if (!(find as CardInfoItem).beneficiaryName) return null;
      return find as CardInfoItem;
    }
    return null;
  } catch (err) {
    return null;
  }
};

const NO_SHORTCOMING = ['SEM CARÊNCIAS A CUMPRIR', 'SEM CARÊMCIAS A CUMPRIR']; // unimed send wrong

const Wallet: React.FC = () => {
  const rendered = useRef(false);
  const [
    listOfBeneficiariesAndDependentsLoaded,
    setListOfBeneficiariesAndLoaded,
  ] = useState(false);
  const { isOnline } = useOnlineMode();

  const auth = useAuth();
  const user = useMemo(
    () => auth.user as UserCommon & BeneficiaryUser,
    [auth.user],
  );
  const formRef = useRef<FormHandles | null>(null);
  const cardWrapperRef = useRef<HTMLDivElement | null>(null);
  const frontRef = useRef<HTMLDivElement | null>(null);
  const backRef = useRef<HTMLDivElement | null>(null);

  const [flipped, setFlipped] = useState(false);
  const [beneficiary, setBeneficiary] = useState<CardInfoItem | null>(null);
  const [beneficiaryToken, setBeneficiaryToken] = useState<CardInfoItem | null>(
    null,
  );

  const [beneficiariesOptions, setBeneficiariesOptions] = useState<
    ListBeneficiaryAndDependentResponse[]
  >([]);

  const [frontTooltipOpen, setFrontTooltipOpen] = useState(false);
  const [backTooltipOpen, setBackTooltipOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<'my-card' | 'qr-code'>('my-card');
  const [selectBenefeciaryId, setSelectBenefeciaryId] = useState<string>('');

  const getBeneficiaryAndProductInfos = useCallback(async (id: string) => {
    try {
      const { data } = await api.get<CardGetInfoResponse>(
        `/card/get-info?userUnimedId=${id}`,
      );
      setBeneficiary(data.data[0]);
      return data.data[0];
    } catch (err) {
      try {
        const storage = getOnLocalStorage(id);
        if (storage) {
          setBeneficiary(storage);
          return storage;
        }
      } catch (error) {
        //
      }
      setBeneficiary(null);
      return null;
    }
  }, []);

  const beneficiaryAndDependentsReducer = useCallback((_content: any) => {
    const content = _content as ListBeneficiaryAndDependentResponse[];
    setBeneficiariesOptions(content);
    return content.map((item) => ({
      id: String(item.userUnimedId),
      name: item.insuredName,
    })) as OptionProps[];
  }, []);

  const onListOfBeneficiaryAndDependentsLoaded = useCallback(() => {
    setListOfBeneficiariesAndLoaded(true);
  }, []);

  const selectFirstOptionOnBeneficiarySelect = useCallback(() => {
    const { current } = formRef;
    if (current) {
      current.setFieldValue('typeuser', beneficiariesOptions[0].userUnimedId);
    }
  }, [beneficiariesOptions]);

  const beneficiaryAndDependentsReducerOffline = useMemo(() => {
    if (!isOnline) {
      return beneficiaryAndDependentsReducer(selectOptionsStorage());
    }
    return [];
  }, [beneficiaryAndDependentsReducer, isOnline]);

  const onExpireQRCode = useCallback(async () => {
    const resp = await getBeneficiaryAndProductInfos(selectBenefeciaryId);
    setBeneficiaryToken(resp);
  }, [getBeneficiaryAndProductInfos, selectBenefeciaryId]);

  useEffect(() => {
    if (!rendered.current && listOfBeneficiariesAndDependentsLoaded) {
      rendered.current = true;
      (async () => {
        selectFirstOptionOnBeneficiarySelect();
      })();
    }
  }, [
    listOfBeneficiariesAndDependentsLoaded,
    selectFirstOptionOnBeneficiarySelect,
  ]);

  useEffect(() => {
    if (beneficiary && activeTab === 'my-card') {
      if (frontRef.current && backRef.current && cardWrapperRef.current) {
        const maxHeight = Math.max(
          frontRef.current.clientHeight,
          backRef.current.clientHeight,
          300,
        );
        cardWrapperRef.current.style.height = `calc(${maxHeight}px + 2.5rem)`;
      }
    }
  }, [beneficiary, activeTab]);

  useEffect(() => {
    if (!isOnline) {
      setActiveTab('my-card');
    }
  }, [isOnline]);

  return (
    <Container>
      {/* <Title>
        <h1>Carteirinha</h1>
      </Title> */}
      <BeneficiarySelectSection>
        <h1>Beneficiário</h1>
        <FormWrapper
          ref={formRef}
          onSubmit={() => {
            //
          }}
        >
          <>
            {isOnline ? (
              <SelectUnform
                name="typeuser"
                disabled={!user?.isOwner}
                className="notiflix-parents text-bold"
                widthContainerDesktop="50%"
                formLook
                callbackOnChange={(_, id) => {
                  getBeneficiaryAndProductInfos(id);
                  setSelectBenefeciaryId(id);
                  setActiveTab('my-card');
                  setBeneficiaryToken(null);
                }}
                apiSearch={`/contract/list-holder-and-dependents?userUnimedId=${user.id}`}
                personalReducer={beneficiaryAndDependentsReducer}
                callbackOnLoad={onListOfBeneficiaryAndDependentsLoaded}
              />
            ) : (
              <SelectUnform
                name="typeuser"
                disabled={!user?.isOwner}
                className="notiflix-parents text-bold"
                widthContainerDesktop="50%"
                formLook
                callbackOnChange={(_, id) => {
                  getBeneficiaryAndProductInfos(id);
                  setSelectBenefeciaryId(id);
                  setActiveTab('my-card');
                  setBeneficiaryToken(null);
                }}
                options={beneficiaryAndDependentsReducerOffline}
                callbackOnLoad={onListOfBeneficiaryAndDependentsLoaded}
              />
            )}
          </>
        </FormWrapper>
      </BeneficiarySelectSection>
      {beneficiary && (
        <>
          <TabsContainer>
            <TabsOptions
              className={activeTab === 'my-card' ? 'active' : ''}
              type="button"
              onClick={() => {
                setActiveTab('my-card');
                setFrontTooltipOpen(false);
                setBackTooltipOpen(false);
                setBeneficiaryToken(null);
              }}
            >
              Meu Cartão
            </TabsOptions>
            {isOnline && (
              <TabsOptions
                className={activeTab === 'qr-code' ? 'active' : ''}
                type="button"
                onClick={() => {
                  setActiveTab('qr-code');
                  getBeneficiaryAndProductInfos(selectBenefeciaryId).then(
                    (_beneficiary) => setBeneficiaryToken(_beneficiary),
                  );
                  setFrontTooltipOpen(false);
                  setBackTooltipOpen(false);
                }}
              >
                QRCode/Token
              </TabsOptions>
            )}
          </TabsContainer>

          <BeneficiaryCardsSection>
            {activeTab === 'my-card' && (
              <CardSection>
                <CardWrapper
                  className={flipped ? 'flip' : ''}
                  ref={cardWrapperRef}
                >
                  <Card>
                    <Front ref={frontRef}>
                      <FrontHeader>
                        <div className="images">
                          <img
                            src="/images/logo-unimed-horizontal.png"
                            alt="Unimed Blumenau - Logo"
                            className="logo"
                          />
                        </div>
                        <div className="descriptions">
                          <p>{beneficiary.product}</p>
                          <p>{beneficiary.descriptionTypeOfContract}</p>
                        </div>
                      </FrontHeader>
                      <FrontBody>
                        <div style={{ gridArea: 'usernumber' }}>
                          <h4>
                            {mask(beneficiary.userPlanId, [
                              '9 999 999999999999 9',
                            ])}
                          </h4>
                        </div>
                        <div style={{ gridArea: 'username' }}>
                          <p>{beneficiary.beneficiaryName}</p>
                          <small>Nome do Beneficiário</small>
                        </div>
                        <div style={{ gridArea: 'accommodation' }}>
                          <p>{beneficiary.accommodation}</p>
                          <small>Acomodação</small>
                        </div>
                        <div style={{ gridArea: 'validity' }}>
                          <p>{mysqlToPTBR(beneficiary.cardExpirateDate)}</p>
                          <small>Validade</small>
                        </div>
                        <div style={{ gridArea: 'plan' }}>
                          <p>{beneficiary.descRegulation}</p>
                          <small>Plano</small>
                        </div>
                        <div style={{ gridArea: 'attendantNetwork' }}>
                          <p>{beneficiary.serviceNetwork}</p>
                          <small>Rede de Atendimento</small>
                        </div>
                        <div style={{ gridArea: 'scope' }}>
                          <p>{beneficiary.coverage}</p>
                          <small>Abrangência</small>
                        </div>
                        <div style={{ gridArea: 'attendant' }}>
                          <p>{beneficiary.billingLocation}</p>
                          <small>Atend.</small>
                        </div>
                        <div style={{ gridArea: 'assistance' }}>
                          <p>{beneficiary.segmentation}</p>
                          <small>Segmentação Assistencial do Plano</small>
                        </div>
                        <TooltipButton
                          onClick={() => setFrontTooltipOpen((prev) => !prev)}
                        >
                          <FaPlus />
                          <span>Mais informações</span>
                        </TooltipButton>
                      </FrontBody>
                      <FrontTooltip className={frontTooltipOpen ? 'open' : ''}>
                        <button
                          type="button"
                          onClick={() => setFrontTooltipOpen(false)}
                        >
                          <FiXCircle />
                        </button>
                        <FrontTooltipContent>
                          <div>
                            <p>{mysqlToPTBR(beneficiary.birthDate)}</p>
                            <small>Nascimento</small>
                          </div>
                          <div>
                            <p>{beneficiary.validityStartDate}</p>
                            <small>Vigência</small>
                          </div>
                          <div>
                            <p>{beneficiary.cpt ?? 'Não há'}</p>
                            <small>Cob. Parcial Temp.</small>
                          </div>
                          <div>
                            <p>{beneficiary.protocolNumber ?? '-'}</p>
                            <small>Via</small>
                          </div>
                          <div className="fullLine">
                            <p>{beneficiary.contractorName}</p>
                            <small>Contratante</small>
                          </div>
                        </FrontTooltipContent>
                      </FrontTooltip>
                    </Front>
                    <Back ref={backRef}>
                      <BackBody>
                        <ActuationArea>
                          <p>
                            <strong>Área de Atuação do Produto: </strong>
                            {beneficiary.groupCities
                              .join(', ')
                              .toLocaleLowerCase()}
                            .
                          </p>
                        </ActuationArea>
                        <Shortcomings>
                          {beneficiary.descriptionShortcomings.map((item) => (
                            <React.Fragment key={item.shortage}>
                              {NO_SHORTCOMING.includes(item.shortage) ? (
                                <h3>SEM CARÊNCIAS A CUMPRIR</h3>
                              ) : (
                                <div>
                                  <p>{item.shortage}</p>
                                  <p>{item.validityEndDate}</p>
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                        </Shortcomings>
                        <CodANS>
                          <p>{beneficiary.ansProtocol}</p>
                          <small>Cód. Produto ANS</small>
                        </CodANS>
                        <CodCNS>
                          <p>{beneficiary.cns}</p>
                          <small>CNS</small>
                        </CodCNS>
                        <Infos>
                          Eventuais alterações ocorridas na rede de prestadores
                          poderão ser consultadas no{' '}
                          <a
                            href="www.unimedblumenau.coop.br"
                            target="_blank"
                            rel="noreferrer"
                          >
                            www.unimedblumenau.coop.br
                          </a>{' '}
                          e no telefone{' '}
                          <a
                            href="tel:08006470026"
                            target="_blank"
                            rel="noreferrer"
                          >
                            0800 647-0026
                          </a>
                        </Infos>
                        <BackBodyFooter>
                          {beneficiary.dsInfoSOS && (
                            <BackBodyFooterLeft>
                              <small
                                dangerouslySetInnerHTML={{
                                  __html: beneficiary.dsInfoSOS.replace(
                                    '\r',
                                    '<br />',
                                  ),
                                }}
                              />
                            </BackBodyFooterLeft>
                          )}
                          <BackBodyFooterCenter>
                            <SAC>
                              <small>SAC/Informações:</small>
                              <p>0800 647-0026</p>
                            </SAC>
                            <ANSnumber>
                              <div>
                                <p>ANS - nº 33456-1</p>
                              </div>
                            </ANSnumber>
                          </BackBodyFooterCenter>
                        </BackBodyFooter>

                        <TooltipButton
                          onClick={() => setBackTooltipOpen((prev) => !prev)}
                        >
                          <FaPlus />
                          <span>Mais informações</span>
                        </TooltipButton>
                      </BackBody>
                      <BackTooltip className={backTooltipOpen ? 'open' : ''}>
                        <button
                          type="button"
                          onClick={() => setBackTooltipOpen(false)}
                        >
                          <FiXCircle />
                        </button>
                        <BackTooltipContent>
                          <div className="fullLine">
                            <p>{beneficiary.contractedUnimedDescription}</p>
                            <small>Unimed contratante</small>
                          </div>
                          <div className="fullLine">
                            <p>334561</p>
                            <small>Nº registro ANS</small>
                          </div>
                        </BackTooltipContent>
                      </BackTooltip>
                    </Back>
                  </Card>
                </CardWrapper>
                <SwitchContainer>
                  <span>FRENTE</span>
                  <SwitchComponent
                    onChange={() => {
                      setFlipped((prev) => !prev);
                      setFrontTooltipOpen(false);
                      setBackTooltipOpen(false);
                    }}
                    checked={flipped}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={20}
                    width={40}
                    handleDiameter={20}
                    offColor="#c2c2c2"
                    onColor="#c2c2c2"
                  />
                  <span>VERSO</span>
                </SwitchContainer>
              </CardSection>
            )}

            {activeTab === 'qr-code' && (
              <QRCodeSection>
                <QRCodeCard>
                  {beneficiaryToken ? (
                    <>
                      <h3>{beneficiaryToken.ds_trilha_3}</h3>
                      <div className="qrCode">
                        <QRCodeComponent
                          code={beneficiaryToken.descTrailQrCode}
                          key={beneficiaryToken.ds_trilha_3}
                        />
                      </div>
                      <Timer
                        min={2}
                        onExpire={onExpireQRCode}
                        key={beneficiaryToken.ds_trilha_3}
                      />
                    </>
                  ) : (
                    <h3>Gerando QRCode/Token...</h3>
                  )}
                </QRCodeCard>
              </QRCodeSection>
            )}
          </BeneficiaryCardsSection>
        </>
      )}
    </Container>
  );
};

export default Wallet;
