import React from 'react';
import { svgProps } from '..';

const SunSvg: React.FC<svgProps> = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 21V23"
        stroke="#5B5C65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.36 18.3594L19.78 19.7794"
        stroke="#5B5C65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.21997 19.7794L5.63997 18.3594"
        stroke="#5B5C65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 12H23"
        stroke="#5B5C65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 12H3"
        stroke="#5B5C65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17C14.7614 17 17 14.761 17 12C17 9.239 14.7614 7 12 7C9.2386 7 7 9.239 7 12C7 14.761 9.2386 17 12 17Z"
        stroke="#5B5C65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.36 5.63972L19.78 4.21973"
        stroke="#5B5C65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.21997 4.21973L5.63997 5.63972"
        stroke="#5B5C65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 1V3"
        stroke="#5B5C65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SunSvg;
