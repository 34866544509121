import React, { useState, useCallback, useRef, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import {
  Container,
  ContainerWrapper,
  Header,
  Footer,
  NavigationAside,
  Overlay,
} from './styles';

interface PageWrapperProps {
  gridTemplateAreas?: string[];
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
  className?: string;
  scrollable?: boolean;
}

const PageWrapper: React.FC<PageWrapperProps> = ({
  gridTemplateColumns,
  children,
  gridTemplateAreas,
  gridTemplateRows,
  className,
  scrollable,
}) => {
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const [navAsideHidden, setNavAsideHidden] = useState(true);
  const { pathname } = useLocation();

  const handleToggleNavAside = useCallback(() => {
    setNavAsideHidden((prev) => !prev);
  }, []);

  const closeAsideOverlay = useCallback(() => {
    setNavAsideHidden(true);
  }, []);

  useEffect(() => {
    setNavAsideHidden(true);
  }, [pathname]);

  return (
    <>
      <Overlay
        ref={overlayRef}
        className={navAsideHidden ? 'hidden' : ''}
        data-navclick
        onClick={closeAsideOverlay}
      />
      <Container>
        <NavigationAside
          hidden={navAsideHidden}
          callbackClose={closeAsideOverlay}
        />
        <Header toggleAside={handleToggleNavAside} />
        <ContainerWrapper
          gridTemplateAreas={gridTemplateAreas}
          gridTemplateColumns={gridTemplateColumns}
          gridTemplateRows={gridTemplateRows}
          className={className}
          scrollable={scrollable}
        >
          {children}
        </ContainerWrapper>
        <Footer />
      </Container>
    </>
  );
};

export default PageWrapper;
