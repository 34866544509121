import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const ExternalContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 30px;

  @media (max-width: 768px) {
  }
  hr {
    width: 100%;
    border-color: #c4cbcf;
    opacity: 0.5;
  }
  form {
    > button {
      min-width: 150px;
    }

    &.actions-include-beneficiary {
      > button {
        &.marginLeft {
          margin-left: 10px;
        }
      }
    }
  }
`;

interface loadingProps {
  inLoading?: boolean;
}

export const UnimedLogo = styled.div<loadingProps>`
  width: 100%;
  height: 70px;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  svg {
    height: 50px;
  }
  ${(props) =>
    props.inLoading &&
    css`
      height: 100vh;
    `}
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 16px;
  row-gap: 16px;
  > button {
    flex: 1;
  }
`;

export const QuestionsContainer = styled.div`
  padding: 8px;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 5px 20px
    ${(props) => transparentize(0.4, props.theme.colors.text.lightGrayToBlack)};

  .displayNone {
    display: none !important;
  }

  form {
    max-height: 60vh;
    overflow-y: auto;
  }
`;

export const Label = styled.label`
  font-family: Unimed-Sans-Book;
  font-size: 1.3rem;
  line-height: 1.7rem;
  flex: 1 0 100%;
  transition: all 300ms ease 0s;
  color: rgb(91, 92, 101);
  position: relative;
  padding-left: 10px;
`;

export const ContainerQuestions = styled.div`
  [data-formlook='true'] {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    [data-formlook='true'] {
      flex-wrap: wrap !important;
      justify-content: center !important;
      padding: 20px;
    }
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 27px;
  column-gap: 8px;
  padding: 0;
  width: 100%;

  &[data-actual-step='false'] {
    display: none;
  }
  &[data-show='false'] {
    display: none;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 27px;
  column-gap: 8px;
  padding: 0;

  &[data-actual-step='false'] {
    display: none;
  }
  &[data-show='false'] {
    display: none;
  }
`;
