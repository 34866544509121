import styled from 'styled-components';
import DropdownElement from '../../components/Dropdown';
import DatePickerComponent from '../../components/DatePicker';

export const Container = styled.section`
  grid-column: 2/10;

  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

export const Content = styled.div`
  > form {
    display: flex;
    flex-wrap: wrap;
    & > div {
      flex: 1;
    }

    @media (max-width: 768px) {
      & > div {
        flex: 1 1;
        width: auto;
      }
    }
  }
`;
export const ReleaseContainer = styled.div`
  margin: 30px 0;
  @media (max-width: 768px) {
    margin: 30px;
  }
`;

export const ReleaseTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-right: 8px;
  font-family: 'Unimed-Sans-SemiBold';
  color: ${(props) => props.theme.colors.text.white};
  font-size: 0.85rem;
  h2 {
    font-size: 1rem;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    padding-right: 8px;
    text-transform: capitalize;
    min-width: 50%;
    margin-right: auto;
  }
  > p {
    margin-left: 8px;
  }
  @media (max-width: 768px) {
    font-size: 0.65rem;
    h2 {
      padding-right: 16px;
    }
  }
`;

export const ReleaseBody = styled.div`
  padding: 30px;
  font-family: 'Unimed-Sans-Book';
  color: ${(props) => props.theme.colors.text.grayishToWhite};
  h2 {
    margin-bottom: 16px;
  }
`;
export const Dropdown = styled(DropdownElement)`
  background: ${(props) => props.theme.colors.palet.institutional11};
  border-color: ${(props) => props.theme.colors.palet.institutional11};
  box-shadow: 0px 5px 20px rgb(196 203 207 / 40%);

  overflow: hidden;
  padding: 0;
  > button {
    background: ${(props) => props.theme.colors.palet.institutional11};
    padding: 10px;
    box-shadow: 0 0 10px;
    > svg {
      color: ${(props) => props.theme.colors.text.white};
    }
  }
  background: ${(props) => props.theme.colors.background};
`;

export const DatePicker = styled(DatePickerComponent)``;

export const ReleaseNoContent = styled.h3`
  font-family: 'Unimed-Sans-Book';
  color: ${(props) => props.theme.colors.text.grayishToWhite};
  text-align: center;
`;

export const ShowMoreContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 24px 0 !important;
  > button {
    padding: 8px 24px;
    border-color: ${(props) => props.theme.colors.palet.institutional11};
    color: ${(props) => props.theme.colors.palet.institutional11};
  }
`;
