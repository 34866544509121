import React from 'react';

import * as S from './styles';

export interface TitleProps {
  themeColor?: string;
  style?: React.CSSProperties;
  className?: string;
}

const Title: React.FC<TitleProps> = ({ children, ...rest }) => {
  return <S.Title {...rest}>{children}</S.Title>;
};
export const TitleIntern: React.FC<TitleProps> = ({ children, ...rest }) => {
  return <S.TitleIntern {...rest}>{children}</S.TitleIntern>;
};

export default Title;
