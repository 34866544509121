import styled from 'styled-components';
import DropdownElement from '../../components/Dropdown';
import TableElement from '../../components/Table';
import FormWrapperComponent from '../../components/FormWrapper';
import Button from '../../components/Button';

export const Table = styled(TableElement)`
  background-color: transparent;
  & > .p-datatable-footer {
    & > div {
      & > span {
        color: ${(props) => props.theme.colors.text.white};
      }
    }
  }
`;

export const Container = styled.div`
  margin-bottom: 30px;

  @media (min-width: 769px) and (max-width: 1400px) {
    grid-column: 1/11;
    padding: 0 30px;
  }
  @media (min-width: 1400px) {
    grid-column: 2/10;
  }
  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

export const Title = styled.h1`
  font-family: 'Unimed-Slab-Bold';
  font-size: 2.55rem;
  line-height: 2.4rem;
  text-align: center;
  letter-spacing: -1.19px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional3};
  padding: 30px 0;
  @media (max-width: 1200px) {
    font-size: 2.25rem;
    line-height: 2.1rem;
  }
  @media (max-width: 769px) {
    font-size: 1.7rem;
    line-height: 1.5rem;
    padding: 30px;
  }
`;

export const PeriodContainer = styled.div`
  margin: 8px 0 24px;
`;

export const PeriodButton = styled(Button)`
  @media (max-width: 768px) {
    margin-left: auto;
  }
`;

export const SelectedPeriodContainer = styled.div`
  background: #fff;
  padding: 10px 20px;
  font-family: 'Unimed-Sans-SemiBold';
  color: ${(props) => props.theme.colors.text.primary};
  text-align: center;
  font-size: 0.8rem;
  border-radius: 5px;
`;

export const FormWrapperPeriodDate = styled(FormWrapperComponent)`
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  > button {
    min-width: 150px;
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    > div {
      flex: 1 1;
    }
    > button {
      width: 100%;
    }
  }
`;

export const Dropdown = styled(DropdownElement)`
  background-color: ${(props) => props.theme.colors.text.grayishToBlack};
  padding: 0px;
  border: none;
  > button {
    background-color: ${(props) => props.theme.colors.palet.institutional11};
    padding: 10px;
    border-radius: 5px;
    justify-content: center;
    svg {
      color: white;
    }
  }
`;

export const DropdownTitle = styled.h2`
  color: ${(props) => props.theme.colors.text.white};
  font-size: 1rem;
  background: ${(props) => props.theme.colors.text.grayishToBlack};
  flex: 1;
  text-align: left;
`;
