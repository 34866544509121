import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { transparentize } from 'polished';
import Button from '../../../components/Button';

export const Container = styled.div`
  margin-bottom: 30px;
  grid-column: 2/3;
  padding: 0 2em;
  font-family: 'Unimed-Sans-Book';
  @media (max-width: 768px) {
    grid-column: 1;
  }
  @media (min-width: 769px) and (max-width: 1600px) {
    grid-column: 1/4;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 5em 0;
  margin-top: 5em;
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 5px 20px
    ${(props) => transparentize(0.4, props.theme.colors.text.lightGrayToBlack)};
  border-radius: 1em;
  @media (max-width: 768px) {
    border-radius: 0;
    padding: 1.5em;
  }
`;
export const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8px;
  row-gap: 5em;
  width: 100%;
  padding: 5em 2em;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    row-gap: 0;
    height: 100%;
    padding: 0em;
  }
`;

export const ContentContainer = styled(Link)`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* flex-direction: column; */
  text-align: center;
  word-break: keep-all;
  margin-bottom: auto;
  text-decoration: none;
  gap: 10px;

  h2 {
    font-family: 'Unimed-Sans-SemiBold';

    color: ${(props) => props.theme.colors.text.grayishToWhite};
    transition: 300ms;
    font-size: 1rem;
    line-height: 1.1rem;
    margin-top: 8px;
    text-align: left;
    @media (max-width: 768px) {
      margin-top: 0px;
      margin-left: 10px;
    }
  }
  > div {
    transition: 300ms;
  }

  &:hover {
    > div {
      transform: scale(1.1);
    }
    h2 {
      color: ${(props) =>
        props.theme.title === 'light'
          ? props.theme.colors.palet.institutional2
          : props.theme.colors.text.whiteToGrayish};
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
`;

export const TitlePeriod = styled.h3`
  font-size: 1.5rem;
  font-family: 'Unimed-Sans-Bold';
  color: ${(props) =>
    props.theme.title === 'light'
      ? props.theme.colors.text.primary
      : props.theme.colors.text.white};
  margin-bottom: 1em;
`;

export const IconBackground = styled.div`
  background: ${(props) => props.theme.colors.palet.institutional4};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  width: 60px;
  height: 60px;
  min-height: 60px;
  min-width: 60px;
  @media (max-width: 768px) {
    padding: 12px;
    width: 46px;
    height: 46px;
    min-height: 46px;
    min-width: 46px;
  }
`;

export const Message = styled.p`
  font-family: 'Unimed-Sans-Book';
  color: ${(props) =>
    props.theme.title === 'light'
      ? props.theme.colors.text.primary
      : props.theme.colors.text.whiteToGrayish};
  text-align: center;
`;

export const Photo = styled.img`
  width: 100%;
`;

export const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start !important;
  /* gap: 1em; */
  margin-bottom: 1em;

  &.modalPeriod {
    width: 500px;
    @media (max-width: 768px) {
      width: 320px;
    }
  }

  .buttonModalPeriod {
    margin-top: 1em;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: flex-start !important; */
  gap: 1em;

  &.buttonModal {
    justify-content: space-between;
    padding: 2em 1em 1em 1em;
    button {
      text-transform: capitalize;
    }
    @media (max-width: 768px) {
      padding: 2em 0 1em 0;
      gap: 0.2em;
    }
  }

  &.center {
    justify-content: center;
  }

  &.rowButtonSearch {
    margin-top: 2em;
  }

  &.rowTable {
    width: 80%;
    justify-content: space-between;
    background: ${(props) => props.theme.colors.palet.institutional14};
    padding: 0 1em;
    border-radius: 0.5em;
    margin-bottom: 2px;
    box-shadow: 0px 3px 2px
      ${(props) =>
        transparentize(0.5, props.theme.colors.palet.institutional11)};
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &.rowTable:hover,
  &.rowSelected {
    background: #e2e7e9;
    transition: 0.5s;
  }
`;

export const ButtonPhoto = styled(Button)`
  background: transparent;
  border: none;
  font-family: 'Unimed-Sans-Book';
  font-size: 1.2em;
  color: ${(props) =>
    props.theme.title === 'light'
      ? props.theme.colors.text.primary
      : props.theme.colors.text.whiteToGrayish};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    border: none;
    box-shadow: none;
    color: ${(props) => props.theme.colors.text.black};
  }
`;

export const ButtonGoBack = styled.button`
  float: right;
  border: none;
  padding: 0;
  background: transparent;
  font-family: 'Unimed-Sans-Book';
  color: ${(props) =>
    props.theme.title === 'light'
      ? props.theme.colors.text.primary
      : props.theme.colors.text.white};
  &:hover {
    box-shadow: none;
    border: none;
  }
`;
