import React from 'react';

const InfoSvg: React.FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 21C16.523 21 21 16.523 21 11C21 5.477 16.523 1 11 1C5.477 1 1 5.477 1 11C1 16.523 5.477 21 11 21Z"
        stroke="#5B5C65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 16.5V10"
        stroke="#5B5C65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 6H11.01"
        stroke="#5B5C65"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InfoSvg;
