import api from '../../services/api/api';
import {
  IScheduleType,
  IBeneficiariesOfAnInsured,
  ISpecialty,
  IUserAppointmentSchedule,
  IGetUserTimetableRequestParams,
  IUserTimeTable,
  ICreateUserTimetableRequestBody,
  ICreateUserTimeTableResponse,
  IDeleteUserTimeTableRequestBody,
  IListOfStateResponse,
  IListOfCityResponse,
} from './types';

export async function getSchedulesTypes(
  userUnimedId: number,
): Promise<IScheduleType[]> {
  const { data } = await api.get<IScheduleType[]>(
    '/appointment-schedule/schedule-types',
    { params: { userUnimedId } },
  );

  return data;
}

export async function getSpecialtiesAvailableToBeneficiary(
  beneficiaryNumber: number,
  specialty: string,
): Promise<ISpecialty[]> {
  const { data } = await api.get<ISpecialty[]>(
    '/appointment-schedule/specialties',
    {
      params: {
        userUnimedId: beneficiaryNumber,
        specialty,
      },
    },
  );

  return data;
}

export async function getScheduledAppointments(): Promise<
  IUserAppointmentSchedule[]
> {
  const { data } = await api.get<IUserAppointmentSchedule[]>(
    '/appointment-schedule/scheduled-appointments',
  );

  return data;
}

export async function getUserTimeTable(
  params: Partial<IGetUserTimetableRequestParams>,
): Promise<{
  data: IUserTimeTable[];
  idNextPage: string | null;
  page: number;
}> {
  const response = await api.get<{
    data: IUserTimeTable[];
    idNextPage: string | null;
    page: number;
  }>(`/appointment-schedule/timetable`, { params });

  return response.data;
}

export async function createUserTimeTable(
  body: ICreateUserTimetableRequestBody,
): Promise<ICreateUserTimeTableResponse[]> {
  const { data } = await api.post(
    '/appointment-schedule/scheduled-appointments',
    body,
  );

  return data;
}

export async function deleteUserTimeTable(
  body: IDeleteUserTimeTableRequestBody,
): Promise<void> {
  // console.log(body);
  await api.delete('/appointment-schedule/scheduled-appointments', {
    data: body,
  });
}

export async function getListOfStates(): Promise<
  IListOfStateResponse['content']
> {
  const { data } = await api.get<IListOfStateResponse>(
    '/address/list-of-states',
  );

  return data.content;
}

export async function getListOfCities(
  state: string,
): Promise<IListOfCityResponse['content']> {
  const { data } = await api.get<IListOfCityResponse>(
    `/address/list-of-cities?state=${state}`,
  );

  return data.content;
}
