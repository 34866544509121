/* eslint-disable react/no-danger */
import React, { useEffect, useState, useCallback } from 'react';

import PageWrapper from '../../components/PageWrapper';
import api from '../../services/api/api';
import { ObjectGetValueOfKey } from '../../utils/formatt';
import { TextContainer, Title, LinkToGov } from './styles';

const ProceduresRol: React.FC = () => {
  const [rolText, setRolText] = useState('<div></div>');
  const getRolText = useCallback(async () => {
    const { data } = await api.get('/gw/translation?key=rol-text');
    setRolText(ObjectGetValueOfKey(data.content, 'rol-text'));
  }, []);

  useEffect(() => {
    getRolText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper
      gridTemplateRows="auto auto auto 1fr"
      gridTemplateColumns="repeat(10, 1fr)"
    >
      <Title>Atualização rol de procedimentos médicos</Title>
      <TextContainer>
        <div dangerouslySetInnerHTML={{ __html: rolText }} />
        <LinkToGov href="https://www.ans.gov.br/ROL-web" target="_blank">
          Consultar ROL
        </LinkToGov>
      </TextContainer>
    </PageWrapper>
  );
};

export default ProceduresRol;
