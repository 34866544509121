import styled from 'styled-components';
import SelectComponent from '../../components/Select';

import TableComponent from '../../components/Table';

export const Table = styled(TableComponent)`
  margin-top: 25px;
  @media (max-width: 768px) {
    grid-column: 1;
    margin-bottom: 30px;
  }
`;

export const Container = styled.div`
  grid-column: 2/10;
  display: grid;
  row-gap: 8px;
  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

export const Select = styled(SelectComponent)``;

export const Title = styled.h1`
  align-self: center;
  grid-column: 2/10;
  text-transform: uppercase;
  font-size: 2.55rem;
  line-height: 2.4rem;
  font-family: 'Unimed-Slab-Bold';
  color: ${(props) => props.theme.colors.palet.institutional9};
  text-align: center;
  padding: 30px;

  @media (max-width: 768px) {
    grid-column: 1;
    font-size: 1.7rem;
    line-height: 1.5rem;
  }
`;
