import styled, { css } from 'styled-components';

export const Container = styled.div`
  /* max-width: 800px; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;

  form {
    max-width: 800px;
  }
`;

interface loadingProps {
  inLoading?: boolean;
}

export const UnimedLogo = styled.div<loadingProps>`
  width: 100%;
  height: 70px;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  svg {
    height: 50px;
  }
  ${(props) =>
    props.inLoading &&
    css`
      height: 100vh;
    `}
`;

export const Title = styled.h2`
  font-size: 1.8rem;
  font-family: 'Unimed-Sans-SemiBold';
  color: ${(props) => props.theme.colors.palet.institutional2};
  margin-bottom: 24px;
  /* flex: 1 0 100%; */
`;

export const Observation = styled.p`
  text-align: center;
  font-size: 1rem;
  grid-column: 2/10;
  padding: 0 0 32px 0;
  font-family: 'Unimed-Sans-book';
`;
