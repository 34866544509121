import styled from 'styled-components';
import { FailureSvg } from '../../components/Svg';
import FormWrapper from '../../components/FormWrapper';
import Select from '../../components/Select';

export const FormatedAddress = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > p {
    width: 100%;
  }
`;

export const FailureIcon = styled(FailureSvg)`
  width: 80%;
`;

export const Container = styled.div`
  margin-bottom: 30px;

  @media (min-width: 769px) and (max-width: 1199px) {
    grid-column: 1/11;
    padding: 0 30px;
  }
  @media (min-width: 1200px) {
    grid-column: 2/10;
  }
`;
export const Title = styled.h1`
  font-family: 'Unimed-Slab-Bold';
  font-size: 2.55rem;
  line-height: 2.4rem;
  text-align: center;
  letter-spacing: -1.19px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional6};
  padding: 30px 0;
  @media (max-width: 1200px) {
    font-size: 2.25rem;
    line-height: 2.1rem;
  }
  @media (max-width: 769px) {
    font-size: 1.7rem;
    line-height: 1.5rem;
    padding: 30px;
  }
`;

export const ReasonRequestDC = styled.div`
  margin-bottom: 30px;

  h3 {
    color: ${(props) => props.theme.colors.text.grayishToWhite};
  }
`;

export const RequestDuplicateCard = styled.div`
  text-align: left;
  background: ${(props) => props.theme.colors.text.whiteToBlack};
  padding: 30px;
  border-radius: 5px;
  font-size: 24px;

  h2 {
    margin-bottom: 8px;
  }

  > div {
    margin-bottom: 15px;
  }

  h2,
  h3 {
    font-family: 'Unimed-Sans-SemiBold';
    color: ${(props) => props.theme.colors.palet.institutional11};

    font-size: 1.5rem;
    color: ${(props) => props.theme.colors.text.grayishToWhite};
  }

  p {
    font-family: 'Unimed-Sans-Book';
    font-size: 1rem;
    color: ${(props) => props.theme.colors.text.blackToWhite};
  }

  @media (max-width: 769px) {
    margin: 0;
    margin-bottom: 5px;
  }
`;

export const RequestDuplicateCardForm = styled(FormWrapper)`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;

  > button {
    min-width: 150px;
  }
  @media (max-width: 768px) {
    button {
      width: 100%;
    }
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;

  button {
    width: 48%;
    margin-top: 40px;
    padding: 0 60px;
  }

  @media (max-width: 769px) {
    button {
      padding: 0;
    }
  }
`;

export const ChangeDatasModal = styled.div`
  button {
    width: 49%;
  }

  p {
    font-family: 'Unimed-Sans-Book';
    margin-top: 24px;
    font-size: 1.15rem;
  }

  @media (max-width: 769px) {
    button {
      padding: 0;
    }
  }
`;

export const ModalTitle = styled.h1`
  font-family: 'Unimed-Sans-Bold';
  font-size: 1.71rem;
  color: ${(props) => props.theme.colors.palet.institutional11};
  margin-bottom: 30px;
`;

export const ModalParagraph = styled.p`
  font-family: 'Unimed-Sans-Book';
  font-size: 1.15rem;
`;

export const SelectContent = styled(Select)``;
