import Notiflix from 'notiflix';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import api from '../services/api/api';
import { useAuth } from './auth';

interface ContractProps {
  contractId: string;
  stipulator: string;
  companyCode: string;
  contractDate: string;
  cnpj: string;
  loaded?: boolean;
}

interface ContractContextProps {
  contract: ContractProps;
  defineContract(data: ContractProps): void;
}

const ContractContext = createContext<ContractContextProps>(
  {} as ContractContextProps,
);

export const ContractProvider: React.FC = ({ children }) => {
  const [contract, setContract] = useState<ContractProps>({
    loaded: false,
  } as ContractProps);

  const { user } = useAuth();

  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    const saved = localStorage.getItem('@unimedBlumenauContract');
    if (saved) {
      const json = JSON.parse(saved);
      api.defaults.headers.common.Contract = json.contractId;
      setContract({ ...json, loaded: true });
    } else {
      setContract((prev) => ({ ...prev, loaded: true }));
    }
  }, []);

  const defineContract = useCallback((data: ContractProps) => {
    api.defaults.headers.common.Contract = data.contractId;
    setContract({ ...data, loaded: true });
    localStorage.setItem(
      '@unimedBlumenauContract',
      JSON.stringify({ ...data, loaded: true }),
    );
  }, []);

  const getDetailsOfDefaultContract = useCallback(async (userContractId) => {
    try {
      const { data } = await api.get(
        `/company/my-contract-data?contractId=${userContractId}`,
      );

      const { content: contentMyContract } = data;

      if (contentMyContract[0]) {
        // set default contract to new requests
        defineContract({
          contractId: contentMyContract[0].contractId,
          companyCode: contentMyContract[0].companyCode,
          cnpj: contentMyContract[0].cnpj,
          stipulator: contentMyContract[0].stipulatorName,
          contractDate: contentMyContract[0].contractDate,
          loaded: true,
        });
      }
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos carregar as informações do contrato atual. Por favor, tente novamente mais tarde.',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.profile === 2 && !contract.contractId && contract.loaded) {
      getDetailsOfDefaultContract(user.contractId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.profile, contract.contractId, contract.loaded]);

  const valuesMemo = useMemo(() => {
    return {
      contract,
      defineContract,
    };
  }, [contract, defineContract]);

  return (
    <ContractContext.Provider value={valuesMemo}>
      {children}
    </ContractContext.Provider>
  );
};

export function useContract(): ContractContextProps {
  const context = useContext(ContractContext);

  if (!context) {
    throw new Error('useContract must be used within an ContractProvider');
  }

  return context;
}
