import styled from 'styled-components';

import { WhatsappSvg, PhoneSvg, OmbudsmanSvg } from '../Svg';

export const OmbudsIcon = styled(OmbudsmanSvg)``;
export const WhatsappIcon = styled(WhatsappSvg)``;
export const TelephoneIcon = styled(PhoneSvg)``;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.palet.institutional2};
  border-radius: 10px;
  padding: 23px;

  h2,
  p {
    font-family: 'Unimed-Sans-SemiBold';
    font-weight: 700;
  }

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 1100px) {
    padding: 28px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContactTitle = styled.h1`
  font-family: 'Unimed-Slab-Bold';
  line-height: 2rem;
  margin-bottom: 25px;
  color: ${(props) => props.theme.colors.palet.institutional4};
  text-transform: uppercase;
  @media (max-width: 1100px) {
    font-size: 1.4rem;
  }
`;

export const ContactContainer = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  text-decoration: none;
  p {
    color: ${(props) => props.theme.colors.palet.institutional13};
    font-size: 1.1rem;
  }
  svg {
    margin-right: 15px;
  }

  @media (max-width: 1100px) {
    font-size: 0.8rem;
  }
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const ContatctAdvice = styled.h2`
  color: white;
  font-family: 'Unimed-Slab-Bold';
  font-size: 1.7rem;

  @media (max-width: 1135px) {
    font-size: 1.4rem;
  }
`;

export const TelephoneInfo = styled.div`
  color: ${(props) => props.theme.colors.palet.institutional4};
`;

export const WhatsappInfo = styled.div`
  color: ${(props) => props.theme.colors.palet.institutional4};
`;
