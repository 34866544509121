/* eslint-disable max-len */
import React from 'react';

const CameraSvg: React.FC = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3064 12.0731C17.3064 12.4559 17.1529 12.8243 16.8802 13.0915C16.6075 13.3659 16.2373 13.5176 15.8519 13.5176H2.76094C2.37549 13.5176 2.0053 13.3659 1.73258 13.0915C1.45985 12.8243 1.3064 12.4559 1.3064 12.0731V4.12869C1.3064 3.74591 1.45985 3.37755 1.73258 3.11033C2.0053 2.83589 2.37549 2.68424 2.76094 2.68424H5.67003L7.12458 0.517578H11.4882L12.9428 2.68424H15.8519C16.2373 2.68424 16.6075 2.83589 16.8802 3.11033C17.1529 3.37755 17.3064 3.74591 17.3064 4.12869V12.0731Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.30635 10.6287C10.9129 10.6287 12.2154 9.33591 12.2154 7.7398C12.2154 6.14369 10.9129 4.85091 9.30635 4.85091C7.69981 4.85091 6.39726 6.14369 6.39726 7.7398C6.39726 9.33591 7.69981 10.6287 9.30635 10.6287Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CameraSvg;
