import React, { useCallback, useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import { useHistory } from 'react-router-dom';
import Title from '../../../components/Title';
import { useTheme } from '../../../hooks/theme';
import {
  Container,
  Content,
  WhatsappFirstAidIcon,
  Row,
  Col,
  ScheduleTitle,
  ContentSchedule,
  Message,
  Schedule,
  ButtonGoBack,
} from './styles';
import PageWrapper from '../../../components/PageWrapper';
import Button from '../../../components/Button';
import api from '../../../services/api/api';

// interface ScheduleProp {
//   data: [
//     {
//       dtAgenda: string;
//       dsAgenda: string;
//     },
//   ];
//   message: string;
//   result: number;
// }

interface ScheduleProp {
  date: string;
  hour: string;
  description: string;
  isToday: boolean;
}

const Schedules: React.FC = () => {
  const { theme } = useTheme();
  const [schedules, setSchedules] = useState<ScheduleProp[]>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const newDate = new Date();

  const getSchedules = useCallback(async () => {
    try {
      setLoading(true);
      Notiflix.Block.circle('.notiflix-loading-schedule', 'Carregando', {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
      });
      const { data } = await api.get(`/first-aid/curative-schedule`);
      setSchedules(
        data.content.data.reduce(
          (
            acc: {
              date: string;
              hour: string;
              description: string;
              isToday: boolean;
            }[],
            act: {
              dtAgenda: string;
              dsAgenda: string;
            },
          ) => {
            acc.push({
              date: act.dtAgenda.split(' ')[0],
              hour: act.dtAgenda.split(' ')[1],
              description: act.dsAgenda,
              isToday:
                act.dtAgenda.split(' ')[0] ===
                `${newDate.getDate()}/${
                  newDate.getMonth() + 1 <= 9
                    ? `0${newDate.getMonth() + 1}`
                    : newDate.getMonth() + 1
                }/${newDate.getFullYear()}`,
            });
            return acc;
          },
          [],
        ),
      );
      setLoading(false);
    } catch (err) {
      if (err?.response?.status === 400) {
        Notiflix.Notify.info(err.response.data?.message);
      } else {
        Notiflix.Notify.failure(
          'Não conseguimos buscar seus agendamentos. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-loading-schedule');
    }
  }, []);

  useEffect(() => {
    getSchedules();
  }, []);

  return (
    <PageWrapper
      gridTemplateColumns="1fr"
      gridTemplateRows="1fr"
      className="wrapperSchedule"
    >
      <Container>
        <Title themeColor={theme.colors.palet.institutional2}>
          Agendamentos
        </Title>
        <ButtonGoBack onClick={() => history.push('/ambulatorio-curativos')}>
          Voltar
        </ButtonGoBack>
        <Content>
          <Col>
            <ContentSchedule className="notiflix-loading-schedule">
              <ScheduleTitle>Seus agendamentos</ScheduleTitle>
              {schedules && (
                <Row>
                  {schedules.map((item, i) => {
                    return (
                      <>
                        <Schedule key={item.date}>
                          <span className="dot">∙</span>
                          <span
                            className={
                              item.isToday ||
                              i === 0 ||
                              (i === 1 && schedules[0].isToday)
                                ? 'hour bold'
                                : 'hour'
                            }
                          >
                            {item.date} <br />
                            {item.hour} <br />
                            {item.description}
                          </span>
                        </Schedule>
                      </>
                    );
                  })}
                </Row>
              )}
              {(!schedules && !loading) ||
                (schedules === undefined && (
                  <Message>Você não possui agendamentos</Message>
                ))}
            </ContentSchedule>
            <Row>
              <div className="center">
                <p>
                  Para realizar um agendamento de curativo, entre em contato
                  através do link abaixo: <br />
                  Converse com o Ambulatório Curativos Unimed Blumenau no
                  WhatsApp
                </p>

                <br />
                <Button name="buttonWhatsapp" className="buttonWhatsapp">
                  <a
                    href="https://wa.me/message/4H54KVBIVUO5K1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <WhatsappFirstAidIcon />
                    Entre em contato
                  </a>
                </Button>
              </div>
            </Row>
          </Col>
        </Content>
      </Container>
    </PageWrapper>
  );
};

export default Schedules;
