/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  Select,
  Title,
  Input,
  Button,
  SelectWrapper,
  InputFile,
  FormContent,
  Observation,
} from './styles';
import PageWrapper from '../../components/PageWrapper';
import { BeneficiaryUser, UserCommon, useAuth } from '../../hooks/auth';
import api from '../../services/api/api';
import Modal2 from '../../components/Modal2';
import { InputFileHandles } from '../../components/InputFile';

interface RequestAuthorizationProps {
  email: string;
  phone: number;
  cellphone: number;
  localAttendance: string;
}
interface OptionProps {
  items: { value: string; title: string }[];
  all: any[];
}

const RequestAuthorization: React.FC = () => {
  const { user: userT } = useAuth();
  const user = userT as UserCommon & BeneficiaryUser;
  const { colors } = useTheme();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const inputFileRef = useRef<InputFileHandles>(null);
  const [actualBeneficiary, setActualBeneficiary] = useState<any>();

  const [beneficiariesOptions, setBeneficiariesOptions] = useState<OptionProps>(
    {
      items: [{ title: '', value: '' }],
      all: [],
    },
  );

  const setFieldValues = useCallback((userDetails) => {
    setActualBeneficiary(userDetails[0]);
    // console.log('userDetails', userDetails);
    // Nome
    formRef.current?.setFieldValue('name', userDetails[0].beneficiaryName);
    // Cartão
    formRef.current?.setFieldValue('wallet', userDetails[0].cardNumber);
  }, []);

  const setSecondFieldValues = useCallback((userDetails) => {
    // Email
    const email = userDetails.email.value;
    formRef.current?.setFieldValue('email', email);
    // Celular
    const cellphone =
      userDetails.DDDNumberCellphone.value + userDetails.cellphone.value;
    formRef.current?.setFieldValue('cellphone', cellphone);
    // Telefone
    const phone = userDetails.DDDNumber.value + userDetails.phone.value;
    formRef.current?.setFieldValue('phone', phone);
  }, []);

  const getUserPhones = useCallback(
    async (option) => {
      if (option === '') {
        try {
          const params = `?physicalPersonalCode=${user.physicalPersonalCode}&accessType=B&serviceProtocolNumber=${user.serviceProtocolNumber}`;
          const { data } = await api.get(
            `/beneficiary/data-to-change-fields${params}`,
          );
          const { content } = data;
          setSecondFieldValues(content);
        } catch (error) {
          if (error.response?.status === 400) {
            if (error.response.data.message) {
              Notiflix.Notify.info(error.response.data.message);
            }
          } else {
            Notiflix.Notify.failure(
              'Não foi possível buscar pelos seus números de celular e e-mail. Por favor, insira seus dados.',
            );
          }
        }
      } else {
        try {
          const { data } = await api.get(
            `/beneficiary/data-to-change-fields?physicalPersonalCode=${option}&accessType=B&serviceProtocolNumber=${user.serviceProtocolNumber}`,
          );
          const { content } = data;
          setSecondFieldValues(content);
        } catch (error) {
          if (error.response?.status === 400) {
            if (error.response.data.message) {
              Notiflix.Notify.info(error.response.data.message);
            }
          } else {
            Notiflix.Notify.failure(
              'Não foi possível buscar pelos seus números de celular e e-mail. Por favor, insira seus dados.',
            );
          }
        }
      }
    },
    [setSecondFieldValues, user],
  );

  const getParents = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-parents');
      const { data } = await api.get(
        `/contract/list-holder-and-dependents?userUnimedId=${user.id}`,
      );
      const { content } = data;
      const reduced = content.reduce(
        (
          acc: { title: string; value: string }[],
          act: { insuredName: string; userUnimedId: string },
        ) => {
          acc.push({
            title: act.insuredName,
            value: act.userUnimedId,
          });
          return acc;
        },
        [],
      );
      setBeneficiariesOptions({
        items: reduced,
        all: content,
      });
    } catch (err) {
      if (err?.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dependentes. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-parents');
    }
  }, [user.id]);

  const handleTypeUserChange = useCallback(
    async (option) => {
      try {
        Notiflix.Block.circle('.notiflix-form');
        const { data } = await api.get(
          `/beneficiary/list-beneficiary-and-all-product-data?userUnimedId=${option}`,
        );

        const { content } = data;
        setFieldValues(content);
        const found = beneficiariesOptions.all.find(
          (i) => i.userUnimedId === option,
        );
        getUserPhones(found.phisicalPersonCode);
      } catch (err) {
        if (err?.response?.status === 400) {
          Notiflix.Notify.info(err.response.data.message);
        } else {
          Notiflix.Notify.failure(
            'Não conseguimos buscar seus dados, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove('.notiflix-form');
      }
    },
    [beneficiariesOptions, getUserPhones, setFieldValues],
  );

  const getPersonData = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-form');
      const { data } = await api.get(
        `/beneficiary/list-beneficiary-and-all-product-data?userUnimedId=${user.id}`,
      );
      const { content } = data;
      setFieldValues(content);
    } catch (err) {
      if (err?.response?.status === 400) {
        Notiflix.Notify.info(err.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Não conseguimos buscar seus dados, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-form');
    }
  }, [setFieldValues, user.id]);

  const handleFormSubmit = useCallback(
    async (data: RequestAuthorizationProps) => {
      try {
        Notiflix.Block.circle('.loading-button');

        formRef.current?.setErrors({});

        const phone = formRef.current?.getFieldValue('phone');
        const cell = formRef.current?.getFieldValue('cellphone');
        // eslint-disable-next-line prefer-const
        let cellAndPhone = {};
        if (!phone && !cell) {
          cellAndPhone = {
            phone: Yup.number().typeError(
              'É necessário informar um telefone e/ou celular',
            ),

            cellphone: Yup.number().typeError(
              'É necessário informar um telefone e/ou celular',
            ),
          };
        }
        if (phone && !cell) {
          cellAndPhone = {
            phone: Yup.number()
              .typeError('Informe somente números')
              .min(1000000000, 'É preciso informar no mínimo 10 números'),
          };
        }
        if (!phone && cell) {
          cellAndPhone = {
            cellphone: Yup.number()
              .typeError('Informe somente números')
              .min(10000000000, 'É preciso informar no mínimo 11 números'),
          };
        }
        if (phone && cell) {
          cellAndPhone = {
            phone: Yup.number()
              .typeError('Informe somente números')
              .min(1000000000, 'É preciso informar no mínimo 10 números'),

            cellphone: Yup.number()
              .typeError('Informe somente números')
              .min(10000000000, 'É preciso informar no mínimo 11 números'),
          };
        }

        const schema = Yup.object().shape({
          email: Yup.string().required('Informe o seu email.').email(),
          ...cellAndPhone,
          localAttendance: Yup.string().required(
            'Informe o local de atendimento',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        if (!inputFileRef.current?.files) {
          inputFileRef.current?.setError(
            'É necessário adicionar uma foto da guia de autorização.',
          );
          return;
        }
        // criar formData e incluir os valores de data e inputFileRef.current.files[0]
        const email = formRef.current?.getFieldValue('email');
        const cellphone = formRef.current?.getFieldValue('cellphone');
        const telephone = formRef.current?.getFieldValue('phone');
        const place = formRef.current?.getFieldValue('localAttendance');
        const fileAuthorization = inputFileRef.current?.files[0];
        const formData = new FormData();

        formData.append('email', email);
        formData.append('cellphone', cellphone);
        formData.append('telephone', telephone);
        formData.append('place', place);
        formData.append('fileAuthorization', fileAuthorization);
        formData.append('userUnimedId', actualBeneficiary.userUnimedId);

        // console.log(formData);
        const { data: resposta } = await api.post(
          `/beneficiary/send-data-to-request-authorize`,
          formData,
        );

        Modal2.Success({
          closable: true,
          title: 'Solicitação enviada!',
          text: resposta.message,
        });
        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef?.current?.setErrors(errors);
        } else if (err?.response?.status !== 400) {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos enviar sua requisição para o servidor. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove('.loading-button');
      }
    },
    [actualBeneficiary, history],
  );

  useEffect(() => {
    getParents();
    getPersonData();
    getUserPhones('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper
      gridTemplateColumns="1fr 1fr 1fr 6fr 1fr"
      gridTemplateRows="auto auto auto 1fr"
    >
      <Title>Requisitar autorização</Title>

      <SelectWrapper
        hidden={!user.isOwner}
        onSubmit={() => {
          //
        }}
        formLook
      >
        <Select
          title="Beneficiário:"
          formLook
          hidden={!user.isOwner}
          liveReload
          name="typeuser"
          className="notiflix-parents"
          changeCallback={handleTypeUserChange}
          options={beneficiariesOptions.items}
          themeColor={colors.palet.institutional8}
        />
      </SelectWrapper>

      <FormContent
        ref={formRef}
        onSubmit={handleFormSubmit}
        className="notiflix-form"
        formLook
      >
        <Input
          formLook
          name="name"
          title="Nome Completo:"
          disabled
          widthContainerDesktop="50%"
          themeColor={colors.palet.institutional8}
        />
        <Input
          formLook
          name="wallet"
          title="Carteira:"
          disabled
          widthContainerDesktop="50%"
          themeColor={colors.palet.institutional8}
        />
        <Input
          formLook
          name="email"
          title="E-mail:"
          widthContainerDesktop="33%"
          themeColor={colors.palet.institutional8}
        />
        <Input
          masks={['(99) 9999-9999', '(99) 9 9999-9999']}
          formLook
          name="cellphone"
          title="Celular:"
          type="tell"
          widthContainerDesktop="33%"
          themeColor={colors.palet.institutional8}
        />
        <Input
          masks={['(99) 9999-9999', '(99) 9 9999-9999']}
          formLook
          name="phone"
          title="Telefone:"
          type="tell"
          widthContainerDesktop="33%"
          themeColor={colors.palet.institutional8}
        />
        <Observation>
          * Clientes que estão fora de Blumenau, devem solicitar a autorização
          na Unimed que será feito o atendimento.
        </Observation>
        <Input
          formLook
          name="localAttendance"
          title="Local onde será executado o atendimento:"
          themeColor={colors.palet.institutional8}
        />
        <InputFile
          ref={inputFileRef}
          placeholder="Foto da guia de autorização"
          themeColor={colors.palet.institutional8}
        />

        <Observation>
          <strong>IMPORTANTE</strong>
          <br />
          • Em caso de cirurgia, é obrigatório inserir laudos de exames e/ou
          relatório médico detalhado. <br />
          • Outros documentos podem ser requeridos durante a análise de sua
          solicitação. <br />• O protocolo de sua solicitação será gerado na
          página seguinte, anote e acompanhe o status pelo menu Status de
          Autorização, via APP Unimed Cliente, via Whatsapp{' '}
          <a href="https://wa.me/08006470026" target="_blank" rel="noreferrer">
            0800 647-0026
          </a>{' '}
          ou no <a href="tel:08006470026">0800 647 0026</a> opção 2.”
        </Observation>
        <Button
          type="submit"
          purple2Button
          modal
          autoWidth
          formLook
          className="loading-button"
        >
          Enviar
        </Button>
      </FormContent>
    </PageWrapper>
  );
};

export default RequestAuthorization;
