import styled from 'styled-components';

import ButtonElement from '../Button';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.text.whiteToBlack};
  border-radius: 10px;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const Title = styled.h2`
  font-size: 1.8rem;
  font-family: 'Unimed-Sans-SemiBold';
  color: ${(props) => props.theme.colors.palet.institutional7};
  margin-bottom: 24px;
  flex: 1 0 100%;
`;

export const Release = styled.div`
  position: relative;
  padding-left: 10px;
  font-family: 'Unimed-Sans-Book';
  color: ${(props) => props.theme.colors.text.grayishToWhite};
  font-size: 1rem;
  display: -webkit-box;
  margin-bottom: 18px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding-right: 20px;
  cursor: pointer;
  transition: 300ms;
  flex: 1 0 100%;
  height: 2.7rem;
  &::before {
    content: '';
    width: 1px;
    height: 100%;
    background: ${(props) => props.theme.colors.palet.institutional7};
    position: absolute;
    top: 0;
    left: 0;
    transition: 300ms;
  }
  > div {
    position: absolute;
    top: 1.2rem;
    right: 0;
  }

  &:hover {
    color: ${(props) => props.theme.colors.palet.institutional7};
  }
`;

export const Button = styled(ButtonElement)`
  padding: 6px 36px;
  margin-top: auto;
  margin-left: auto;

  color: ${(props) => props.theme.colors.palet.institutional2};
`;
