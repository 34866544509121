import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import PageWrapper from '../../components/PageWrapper';
import Modal2 from '../../components/Modal2';
import Select, { SelectHandles } from '../../components/Select';
import { BeneficiaryUser, UserCommon, useAuth } from '../../hooks/auth';
import api from '../../services/api/api';
import {
  Container,
  Title,
  RequestDuplicateCard,
  ReasonRequestDC,
  RequestDuplicateCardForm,
  ModalTitle,
  ModalParagraph,
  ModalContent,
  ChangeDatasModal,
  FormatedAddress,
} from './styles';
import Translation from '../../services/api/Translation';

const RequestDuplicatedCard: React.FC = () => {
  const { user: userT } = useAuth();
  const user = userT as UserCommon & BeneficiaryUser;
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const reasonRef = useRef<SelectHandles>(null);
  const beneficiaryRef = useRef<SelectHandles>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [productData, setProductData] = useState({} as any);

  const [reasons, setReasons] = useState([
    {
      title: 'Selecione',
      value: '-1',
    },
  ]);

  const [beneficiaries, setBeneficiaries] = useState(() => {
    const options = [
      {
        title: '',
        value: '',
      },
    ];

    return {
      items: options,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      all: [] as any,
    };
  });

  const getFormattedAddress = useCallback(async () => {
    const found = beneficiaries.all.find(
      (i: { userUnimedId: string | undefined }) =>
        i.userUnimedId === beneficiaryRef.current?.value,
    );

    Notiflix.Block.circle('.notiflix-request-duplicate-card-button');
    const { data } = await api.get(
      `/card/home-address?userUnimedId=${found.userUnimedId}&concatenatedAddress=false`,
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const personAddress: any = data.content[0];

    const formattedAddress = (
      <ModalParagraph>
        {`${personAddress.publicPlace} ${personAddress.address} ${
          personAddress.addressNumber
        } ${personAddress.complement ? personAddress.complement : ''} - ${
          personAddress.neighborhood ? personAddress.neighborhood : ''
        } - ${personAddress.city}/${personAddress.brazilianState}`}
      </ModalParagraph>
    );

    return <FormatedAddress>{formattedAddress}</FormatedAddress>;
  }, [beneficiaries]);

  const successfullOpenModal = useCallback(() => {
    Modal2.Close();

    Modal2.Success({
      closable: true,
      title: 'Solicitação enviada!',
      text: 'A solicitação da segunda via foi enviada com sucesso!',
      children: (
        <Button
          modal
          onClick={() => {
            Modal2.Close();
          }}
        >
          Ok
        </Button>
      ),
    });
    history.push('/');
  }, [history]);

  const openChangeDataOpenModal = useCallback(() => {
    Modal2.Generic({
      autoWidth: true,
      children: (
        <ChangeDatasModal>
          <ModalTitle>
            Você deseja modificar o seu
            <br />
            endereço?
            <p>
              Se sim, você será direcionado para a tela de
              <br /> edição do endereço do beneficiário selecionado
            </p>
          </ModalTitle>
          <Button
            onClick={() => Modal2.Close()}
            modal
            outlined
            autoWidth
            float="left"
          >
            Não
          </Button>
          <Button
            modal
            autoWidth
            float="right"
            onClick={async () => {
              history.push('/alterar-dados-cadastrais?segunda-via');
              Modal2.Close();
            }}
          >
            Sim
          </Button>
        </ChangeDatasModal>
      ),
    });
  }, [history]);

  const handleRequestDuplicatCard = useCallback(async () => {
    try {
      const found = beneficiaries.all.find(
        (i: { userUnimedId: string | undefined }) =>
          i.userUnimedId === beneficiaryRef.current?.value,
      );

      await api.put('/card/request-second-copy-of-the-card', {
        userUnimedId: found.userUnimedId,
        cardId: found.cardId,
        reasonCopy: reasonRef.current?.value,
      });

      successfullOpenModal();
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops.. Algo deu errado.. Tente novamente mais tarde.',
      );
    }
  }, [beneficiaries, successfullOpenModal]);

  const confirmAdressDataOpenModal = useCallback(
    (formattedAddress) => {
      Modal2.Generic({
        closable: true,
        autoWidth: true,
        children: (
          <ModalContent>
            <ModalTitle>
              Confirmo endereço de <br />
              entrega do cartão?
            </ModalTitle>
            {formattedAddress}
            <Button
              outlined
              modal
              autoWidth
              float="left"
              onClick={() => {
                Modal2.Close();
                openChangeDataOpenModal();
              }}
            >
              Não
            </Button>
            <Button
              float="right"
              modal
              autoWidth
              onClick={() => handleRequestDuplicatCard()}
            >
              Sim
            </Button>
          </ModalContent>
        ),
      });
    },
    [openChangeDataOpenModal, handleRequestDuplicatCard],
  );

  const handleSubmit = useCallback(async () => {
    try {
      if (!reasonRef.current?.value) {
        reasonRef.current?.setError('Selecione um motivo');
        return;
      }
      if (!beneficiaryRef.current?.value) {
        beneficiaryRef.current?.setError('Selecione um beneficiário válido.');
        return;
      }
      Notiflix.Block.circle('.notiflix-request-duplicate-card-button');
      const formattedAddress = await getFormattedAddress();

      confirmAdressDataOpenModal(formattedAddress);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops, não conseguimos buscar a lista de motivos. Tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.circle('.notiflix-request-duplicate-card-button');
      Notiflix.Block.remove('.notiflix-request-duplicate-card-button');
    }
  }, [getFormattedAddress, confirmAdressDataOpenModal]);

  const getParents = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-parents');
      const { data } = await api.get(
        `/card/list-of-beneficiaries-with-second-copy-card-right?userUnimedId=${user.id}&serviceProtocolNumber=${user.serviceProtocolNumber}`,
      );
      if (data.content) {
        setBeneficiaries(() => {
          const newValues = data.content.reduce(
            (
              acc: { title: string; value: string }[],
              act: { insuredName: string; userUnimedId: string },
            ) => {
              acc.push({
                title: act.insuredName,
                value: act.userUnimedId,
              });
              return acc;
            },
            [],
          );
          return {
            items: newValues,
            all: data.content,
          };
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        Modal2.Warning({
          closable: true,
          autoWidth: true,
          title: 'Ops...',
          text: err.response.data.message,
          children: (
            <Button
              modal
              onClick={() => {
                Modal2.Close();
                history.goBack();
              }}
            >
              Ok
            </Button>
          ),
        });
      } else {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dependentes. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.circle('.notiflix-parents');
      Notiflix.Block.remove('.notiflix-parents');
    }
  }, [history, user]);

  const getReasons = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-motivo-select');
      const { data } = await api.get('/card/reason-to-get-a-new-card');
      const { content } = data;
      if (content) {
        setReasons(() => {
          return content.map((item: { id: string; name: string }) => {
            return {
              value: item.id,
              title: item.name,
            };
          });
        });
      }
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops, não conseguimos preencher os motivos... Tente novamente mais tarde',
      );
    } finally {
      Notiflix.Block.circle('.notiflix-motivo-select');
      Notiflix.Block.remove('.notiflix-motivo-select');
    }
  }, []);

  const getProductData = useCallback(async () => {
    try {
      const { data } = await api.get(
        'beneficiary/list-beneficiary-and-product',
      );
      const { content } = data;
      if (content && content[0]) {
        setProductData(content[0]);

        if (content[0].hiringType !== 'Individual/Familiar') {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: 'A solicitação de um novo cartão deve ser feita pela Contratante Pessoa Jurídica. Entre em contato com o seu RH!',
            children: (
              <Button
                modal
                onClick={() => {
                  Modal2.Close();
                  history.push('/');
                }}
              >
                Ok
              </Button>
            ),
          });
        }
      }
    } catch (error) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar qual é o seu plano. Por favor, tente novamente mais tarde.',
      );
    }
  }, [history]);

  useEffect(() => {
    Notiflix.Block.init({ svgSize: '10px' });
    getProductData();
    getReasons();
    getParents();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper
      gridTemplateColumns="repeat(10,1fr)"
      gridTemplateRows="1fr auto"
    >
      <Container>
        <Title>Solicitar Segunda via do Cartão</Title>

        <RequestDuplicateCard>
          <ReasonRequestDC>
            <Translation tag="solicitacao-segunda-via-cartao" />
          </ReasonRequestDC>
        </RequestDuplicateCard>

        {productData?.hiringType === 'Individual/Familiar' && (
          <RequestDuplicateCardForm
            onSubmit={handleSubmit}
            ref={formRef}
            formLook
          >
            <Select
              className="notiflix-motivo-select"
              name="reason"
              title="Motivo da via adicional"
              options={reasons}
              ref={reasonRef}
              formLook
              liveReload
              widthContainerDesktop="40%"
            />
            <Select
              className="notiflix-parents"
              name="beneficiary"
              title="Beneficiário"
              ref={beneficiaryRef}
              formLook
              options={beneficiaries.items}
              liveReload
              disabled={!user.isOwner}
              widthContainerDesktop="40%"
            />
            <Button
              className="notiflix-request-duplicate-card-button"
              orangeButton
              formLook
              type="submit"
              autoWidth
            >
              Solicitar
            </Button>
          </RequestDuplicateCardForm>
        )}
      </Container>
    </PageWrapper>
  );
};

export default RequestDuplicatedCard;
