import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import FormWrapper from '../../components/FormWrapper';
import PageWrapper from '../../components/PageWrapper';
import Select, { SelectHandles } from '../../components/Select';
import Table, { Column } from '../../components/Table';
import { Title } from '../../components/Title/styles';
import { EnterpriseUser, UserCommon, useAuth } from '../../hooks/auth';
import { useContract } from '../../hooks/contract';
import api from '../../services/api/api';
import { dateToPTBR } from '../../utils/formatt';
import AddField, { getTag } from '../../utils/tableUtils';

import { Container } from './styles';

interface DataProps {
  value: Date | null;
  error: string;
}
interface ContractRespProps {
  cnpj: string;
  companyCode: number;
  contractId: number;
  stipulatorName: string;
  unit: string | null;
}
interface ItemSelect {
  title: string;
  value: string;
}

const ConsultContractChange: React.FC = () => {
  const { colors } = useTheme();
  const themeColor = colors.palet.institutional9;
  const { contract } = useContract();
  const { user: userT } = useAuth();
  const user = userT as UserCommon & EnterpriseUser;
  const formRef = useRef<FormHandles>(null);
  const contractRef = useRef<SelectHandles>(null);

  const [contractOptions, setContractOptions] = useState([
    { title: 'Contratos', value: '' },
  ]);
  const [fromDate, setFromDate] = useState<DataProps>({
    value: new Date(
      new Date().getFullYear() - 1,
      new Date().getMonth(),
      new Date().getDate(),
    ),
    error: '',
  });
  const [toDate, setToDate] = useState<DataProps>({
    value: new Date(),
    error: '',
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataTable, setDataTable] = useState([] as any);

  const getDataTable = useCallback(
    async (filter = [] as string[]) => {
      try {
        Notiflix.Block.circle('.notiflix-table-loading');
        const params = filter.join('&');

        // Primeira requisição, antes da filtragem.
        if (!params && fromDate.value && toDate.value) {
          const { data } = await api.get(
            `company/migration-request-data-list?contractId=${
              contract.contractId
            }&startDate=${dateToPTBR(fromDate.value)}&finalDate=${dateToPTBR(
              toDate.value,
            )}`,
          );
          const { content } = data;
          setDataTable(content);
          return;
        }

        const { data } = await api.get(
          `company/migration-request-data-list?${params}`,
        );
        const { content } = data;
        setDataTable(content);
      } catch (err) {
        if (err?.response?.status === 400) {
          setDataTable([]);
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos buscar os dados do servidor. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove('.notiflix-table-loading');
      }
    },
    [contract, fromDate, toDate],
  );

  const handleFilter = useCallback(() => {
    if (fromDate.value && !toDate.value) {
      setToDate((prev) => ({
        ...prev,
        error: 'Informe uma intervalo de período.',
      }));
      return;
    }
    if (!fromDate.value && toDate.value) {
      setFromDate((prev) => ({
        ...prev,
        error: 'Informe uma intervalo de período.',
      }));
      return;
    }

    const filters = [] as string[];
    if (contractRef.current?.value) {
      filters.push(`contractId=${contractRef.current.value}`);
    } else {
      filters.push(`contractId=${contract.contractId}`);
    }
    if (fromDate.value && toDate.value) {
      filters.push(`startDate=${dateToPTBR(fromDate.value)}`);
      filters.push(`finalDate=${dateToPTBR(toDate.value)}`);
    }
    getDataTable(filters);
  }, [fromDate, getDataTable, toDate, contract]);

  const getAllContracts = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-contracts');
      const { data } = await api.get(
        `/company/list-of-contracts?groupNumber=${user.groupNumber}`,
      );
      const content = data.content as ContractRespProps[];
      setContractOptions(
        content
          .sort((a) => {
            if (a.contractId.toString() === contract.contractId.toString())
              return -1;
            if (a.contractId.toString() !== contract.contractId.toString())
              return 1;
            return 0;
          })
          .reduce((acc, act) => {
            acc.push({
              title: `${act.contractId} - ${act.stipulatorName}`,
              value: String(act.contractId),
            });
            return acc;
          }, [] as ItemSelect[]),
      );
    } catch (err) {
      // Notiflix.Notify.failure(
      //   'Ops... Não conseguimos pré carregar os contratos do servidor. Por favor, tente novamente mais tarde.',
      // );
    } finally {
      Notiflix.Block.remove('.notiflix-contracts');
    }
  }, [contract, user]);

  const statusTemplate = useCallback((situation) => {
    switch (situation) {
      case 'Solicitação finalizada':
        return getTag('Status', 'Solic. Finalizada', 'green');
      case 'Solicitação em aberto':
        return getTag('Status', 'Solic. em Aberto', 'orange');
      case 'Solicitação rejeitada':
        return getTag('Status', 'Solic. Rejeitada', 'red');
      default:
        return situation;
    }
  }, []);

  useEffect(() => {
    if (contract.contractId) {
      getAllContracts();
      getDataTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <Container>
        <Title themeColor={themeColor}>
          Consulta Alteração de Contrato/CNPJ
        </Title>

        <FormWrapper ref={formRef} formLook onSubmit={handleFilter}>
          {user.groupNumber && (
            <Select
              ref={contractRef}
              options={contractOptions}
              className="notiflix-contracts"
              title="Contrato migração:"
              name="filters"
              widthContainerDesktop="25%"
              liveReload
              formLook
              themeColor={themeColor}
            />
          )}
          <DatePicker
            name="from"
            onChange={(date: Date) => {
              setFromDate({ value: date, error: '' });
              setToDate((prev) => ({ ...prev, error: '' }));
            }}
            selected={fromDate.value}
            error={fromDate.error}
            widthContainerDesktop="25%"
            icon
            formLook
            title="Período de:"
            themeColor={themeColor}
          />

          <DatePicker
            name="to"
            onChange={(date: Date) => {
              setToDate({ value: date, error: '' });
              setFromDate((prev) => ({ ...prev, error: '' }));
            }}
            selected={toDate.value}
            error={toDate.error}
            icon
            formLook
            title="Até:"
            widthContainerDesktop="25%"
            themeColor={themeColor}
          />
          <Button grayButton formLook type="submit">
            Filtrar
          </Button>
        </FormWrapper>
        <Table items={dataTable} className="notiflix-table-loading">
          <Column
            field="beneficiary"
            header="Beneficiário"
            sortable
            body={(data) => AddField(data.beneficiaryName, 'Beneficiário')}
          />
          <Column
            field="descriptionContract"
            header="Contrato"
            sortable
            bodyStyle={{
              wordBreak: 'breakAll',
            }}
            body={(data) => AddField(data.descriptionContract, 'Contrato')}
          />
          <Column
            field="membershipCode"
            header="Cód. carteira"
            sortable
            body={(data) => AddField(data.cardNumber, 'Cód. carteira')}
          />
          <Column
            field="migrationDate"
            header="Data de migração"
            className="date"
            body={(data) => AddField(data.migrationDate, 'Data de migração')}
          />
          <Column
            field="solititationData"
            className="date"
            header="Data de Solicitação"
            body={(data) => AddField(data.requestDate, 'Data de Solicitação')}
          />
          <Column
            field="statusDescription"
            header="Status"
            className="tag"
            sortable
            body={(data) => statusTemplate(data.statusDescription)}
          />
        </Table>
      </Container>
    </PageWrapper>
  );
};

export default ConsultContractChange;
