import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useState, useRef } from 'react';

import produce from 'immer';
import PageWrapper from '../../../components/PageWrapper';
import { Column } from '../../../components/Table';
import api from '../../../services/api/api';
import AddField, { getTag } from '../../../utils/tableUtils';

import {
  ChangeRegisterDataConsultTitle,
  Table,
  CancelButton,
  ModalContainer,
} from './styles';
import Modal2 from '../../../components/Modal2';
import Select, { SelectHandles } from '../../../components/Select';
import Button from '../../../components/Button';
import { BeneficiaryUser, UserCommon, useAuth } from '../../../hooks/auth';

const BeneficiaryChangeRegisterDataConsult: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [consultDatas, setConsultDatas] = useState<any[]>([]);
  const reasonSelectRef = useRef<SelectHandles>(null);
  const { user: userT } = useAuth();
  const user = userT as UserCommon & BeneficiaryUser;

  const handleSendStatusToReprove = useCallback(
    async (row) => {
      try {
        Notiflix.Block.circle('.notiflix-send-reason');
        const reason = reasonSelectRef.current?.value;
        await api.put(`/beneficiary/cancel-registry-update-request`, {
          userUnimedId: user.id,
          protocolId: user.serviceProtocolNumber,
          changeNumber: row.changeId,
          userName: row.requesterUsername,
          observation: row.observation,
          reasonForDisaprovalId: reason,
        });
        Notiflix.Block.remove('.notiflix-send-reason');
        Modal2.Close();

        setConsultDatas((prev) => {
          return produce(prev, (draftState) => {
            const item = draftState.find((i) => i.changeId === row.changeId);
            item.situation = 'Reprovado';
          });
        });

        Modal2.Success({
          closable: false,
          title: 'Perfeito!',
          text: 'Atualizamos o status desta alteração cadastral para cancelado.',
          children: (
            <>
              <CancelButton modal onClick={() => Modal2.Close()}>
                Ok
              </CancelButton>
            </>
          ),
        });
      } catch (err) {
        Notiflix.Block.remove('.notiflix-send-reason');
        Modal2.Close();
        Notiflix.Notify.failure(
          'Opss... Algo deu errado, tente novamente mais tarde.',
        );
      }
    },
    [user],
  );

  const handleUpdateStatusToCanceled = useCallback(
    async (row) => {
      try {
        Notiflix.Block.circle('.notiflix-table-loading');
        const { data } = await api.get(
          '/beneficiary/reason-for-disapproval-of-registration-change',
        );
        const { content } = data;
        const optionsSelect = content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        );
        Modal2.Generic({
          closable: true,
          autoWidth: true,
          children: (
            <ModalContainer>
              <h2>Selecione um motivo</h2>
              <Select
                name="reason"
                title="Motivo"
                options={optionsSelect}
                ref={reasonSelectRef}
                formLook
              />
              <Button
                modal
                onClick={() => handleSendStatusToReprove(row)}
                className="notiflix-send-reason"
              >
                Enviar
              </Button>
            </ModalContainer>
          ),
        });
      } catch (err) {
        if (err.response?.data?.message) {
          Notiflix.Notify.info(err.response.data.message);
        } else {
          Notiflix.Notify.failure(
            'Opss... Algo deu errado, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove('.notiflix-table-loading');
      }
    },
    [handleSendStatusToReprove],
  );

  const situationTemplate = useCallback((situation) => {
    switch (situation) {
      case 'Cancelado':
        return getTag('Status', 'Cancelado', 'red');
      case 'Reprovado':
        return getTag('Status', 'Reprovado', 'red');
      case 'Aprovado':
        return getTag('Status', 'Aprovado', 'green');
      case 'Solicitado':
        return getTag('Status', 'Solicitado', 'orange');
      case 'Pendente':
        return getTag('Status', 'Pendente', 'orange');
      default:
        return situation;
    }
  }, []);

  const getData = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');
      const { data } = await api.get(
        `/beneficiary/requests-list-to-change-the-record?phisicalPersonCode=${user.id}`,
      );
      const { content } = data;
      if (content) {
        setConsultDatas(content);
      }
    } catch (error) {
      Notiflix.Notify.failure(
        'Ops, não conseguimos buscar os dado do servidor. Tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, [user]);

  useEffect(() => {
    if (user.id) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <ChangeRegisterDataConsultTitle>
        Consultar Alterações Cadastrais
      </ChangeRegisterDataConsultTitle>

      <Table
        items={consultDatas}
        className="notiflix-table-loading"
        columnResizeMode="fit"
      >
        <Column
          field="requesterUsername"
          header="Nome do beneficiário"
          sortable
          bodyStyle={{ wordBreak: 'break-word' }}
          body={(data) =>
            AddField(data.requesterUsername, 'Nome do beneficiario')
          }
        />
        <Column
          field="fieldDescription"
          header="Campos"
          sortable
          body={(data) => AddField(data.fieldDescription, 'Campos')}
        />
        <Column
          field="previousValue"
          header="Valor Anterior"
          bodyStyle={{ wordBreak: 'break-word' }}
          sortable
          body={(data) => AddField(data.previousValue, 'Valor Anterior')}
        />

        <Column
          field="requested"
          header="Valor Solicitado"
          sortable
          body={(data) => AddField(data.newValue, 'Valor Solicitado')}
        />
        <Column
          field="requestDate"
          header="Data da Solicitação"
          className="date"
          body={(data) => AddField(data.requestDate, 'Data da Solicitação')}
        />
        <Column
          field="situation"
          header="Situação"
          sortable
          className="tag"
          body={(data) => situationTemplate(data.situation)}
        />
        <Column
          field="botaoCancelar"
          sortable
          bodyClassName="to-bottom"
          body={(data) =>
            data.situation !== 'Cancelado' &&
            data.situation !== 'Reprovado' && (
              <CancelButton
                onClick={() => handleUpdateStatusToCanceled(data)}
                orangeButton
                sausageLook
                noChangeColor
                type="submit"
              >
                Cancelar
              </CancelButton>
            )
          }
        />
      </Table>
    </PageWrapper>
  );
};

export default BeneficiaryChangeRegisterDataConsult;
