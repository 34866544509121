/* eslint-disable max-len */
import React from 'react';
import { svgProps } from '..';

const BookOpenSvg: React.FC<svgProps> = ({ className }) => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9286 0H17.1429L16.3929 0.303848L15 1.60008L13.6071 0.303848L12.8571 0H1.07143L0 1.01266V21.2659L1.07143 22.2785H12.4071L14.2501 24H15.7499L17.5929 22.2785H28.9286L30 21.2659V1.01266L28.9286 0ZM13.9286 20.9012L13.5429 20.5571L12.8571 20.2532H2.14286V2.02532H12.4071L13.9929 3.52404L13.9286 20.9012ZM27.8571 20.2532H17.1429L16.3929 20.5571L16.0929 20.8204V3.44295L17.5929 2.02532H27.8571V20.2532ZM10.7145 6.07453H4.28595V8.09985H10.7145V6.07453ZM10.7145 14.1773H4.28595V16.2027H10.7145V14.1773ZM4.28595 10.1306H10.7145V12.1559H4.28595V10.1306ZM25.7145 6.07453H19.2859V8.09985H25.7145V6.07453ZM19.2859 10.1306H25.7145V12.1559H19.2859V10.1306ZM19.2859 14.1773H25.7145V16.2027H19.2859V14.1773Z"
        fill="white"
      />
    </svg>
  );
};

export default BookOpenSvg;
