import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const LabelTitle = styled.div`
  display: block;
  justify-content: flex-start;
  position: relative;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  font-family: 'Unimed-Sans-Bold';
  font-size: 0.65rem;
  color: #5b5c65;
  top: 26px;
`;
export const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  width: 100px;
  border: 1px solid ${({ theme }) => theme.colors.palet.institutional12};
  border-radius: 6px;
  padding: 0px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 100%;
  }
  transition: background-color 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    background-color: #bde053;
  }

  @media (max-width: 768px) {
    &:hover {
      background-color: #b1d34b;
    }
  }
`;

export const Paragraph = styled.p`
  position: absolute;
  text-align: center;
  width: 100%;
`;

export const RadioBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  transition: background 0.15s, border-color 0.15s;

  &::after {
    content: '';
    width: 100%;
    height: 34px;
    display: block;
    background: ${(props) => props.theme.colors.palet.institutional4};
    cursor: pointer;
    transform: scale(0);
    -webkit-appearance: radio;

    display: block;
  }
`;

export const CustomInput = styled.input`
  display: none;
  &:checked + ${RadioBox} {
    border: transparent;

    &::after {
      transform: scale(1);
      margin-left: -1px;
      width: 100px;
      display: block;
      border-radius: 6px;

      @media (max-width: 760px) {
        width: 100%;
        -webkit-appearance: radio;

        display: block;
      }
    }
  }
`;
