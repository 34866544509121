import React from 'react';

import { useAuth } from '../../hooks/auth';
import HomeBeneficiary from './Beneficiary';
import HomeCompany from './Company';
import { useOnlineMode } from '../../hooks/useOnlineMode';
import Wallet from '../Wallet';

const Home: React.FC = () => {
  const { user } = useAuth();

  const { isOnline } = useOnlineMode();

  return (
    <>
      {user.profile === 1 && isOnline && <HomeBeneficiary />}
      {user.profile === 1 && !isOnline && <Wallet />}
      {user.profile === 2 && <HomeCompany />}
    </>
  );
};

export default Home;
