import styled from 'styled-components';
import ButtonElement from '../../components/Button';
import Table from '../../components/Table';

export const Container = styled.section`
  grid-column: 2/10;
  @media (max-width: 768px) {
    grid-column: 1;
    margin: 30px;
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  iframe {
    width: 100%;
    margin-bottom: 8px;
  }
`;

export const Button = styled(ButtonElement)`
  border-radius: 20px;
`;

export const TitleModal = styled.h3`
  font-family: 'Unimed-Slab-Bold';
  font-size: 1.4rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.text.grayishToWhite};
  margin-bottom: 8px;
  margin-right: auto;
`;

export const TutorialsContainer = styled(Table)``;
