import { Form } from '@unform/web';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled(Form)`
  padding: 8px;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 5px 20px
    ${(props) => transparentize(0.4, props.theme.colors.text.lightGrayToBlack)};

  @media (max-width: 768px) {
    border-radius: 0px;
    padding: 8px;
  }
  &[data-formlook='true'] {
    row-gap: 27px;
    column-gap: 8px;
    padding: 34px 16px 16px 16px;

    > button {
      text-transform: uppercase;
    }

    @media (max-width: 768px) {
      border-radius: 0px;
      padding: 38px 30px 24px 30px;
      row-gap: 24px;
      column-gap: 8px;
    }
  }
`;
