import React from 'react';
import { useAuth } from '../../hooks/auth';
import BeneficiaryDemonstrativeAndDuplicate from './Beneficiary';
import CompanyDemonstrativeAndDuplicate from './Company';

const DemonstrativeAndDuplicate: React.FC = () => {
  const { user } = useAuth();

  if (user.profile === 1) {
    return <BeneficiaryDemonstrativeAndDuplicate />;
  }
  return <CompanyDemonstrativeAndDuplicate />;
};

export default DemonstrativeAndDuplicate;
