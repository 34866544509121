import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import { v4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { RowProps } from '.';
import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import FormWrapper from '../../components/FormWrapper';
import Input from '../../components/Input';
import InputFile, { InputFileHandles } from '../../components/InputFile';
import Modal2 from '../../components/Modal2';
import Select, { SelectHandles } from '../../components/Select';
import { useContract } from '../../hooks/contract';
import api from '../../services/api/api';
import { dateToPTBR, formatDate, ptBRToDate } from '../../utils/formatt';
import { ButtonWrapper } from './styles';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';

interface ChangeProductProps {
  defaultColor: string;
  externalParams: {
    requestId?: string;
    userUnimedId?: string;
    productId?: string;
  };
  callbackSetStep: (prev: number) => void;
  rowsSelecteds: RowProps[];
}
interface DateProps {
  value: Date | null;
  error: string;
}

export function ChangeProduct({
  defaultColor,
  callbackSetStep,
  rowsSelecteds,
  externalParams,
}: ChangeProductProps): JSX.Element {
  const { contract } = useContract();
  const history = useHistory();

  const formInfoRef = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const productRef = useRef<SelectHandles>(null);
  const attachmentsRef = useRef<InputFileHandles>(null);
  const [hasAttachment, setHasAttachment] = useState(false);
  const [attachmentDispatch, setAttachmentDispatch] = useState('');

  const [changeDate, setChangeDate] = useState<DateProps>({
    value: null,
    error: '',
  });
  const [productOptions, setProductOptions] = useState([
    { title: 'Produto', value: '' },
  ]);
  const [isChange, setIsChange] = useState<string | null>(null);

  const handleGenerateReport = useCallback(
    async (requestID: string, notiflix: string) => {
      const solicitationNumber = requestID;
      try {
        Notiflix.Block.circle(notiflix);
        const { data } = await api.get(
          `/report/list-of-company-portal-reports?contractId=${contract.contractId}&exhibitionLocation=5`,
        );
        const { nrSeqRelatorio, Parametros: P } = data.content[0];
        const params = [solicitationNumber];
        const body = {
          nrSeqRelatorio: nrSeqRelatorio ?? '',
          Parametros: [
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...P.map((p: { cdParametro: any }, index: any) => {
              return {
                id: p.cdParametro,
                valor: params[index],
              };
            }),
          ],
        };

        const { data: dataReport } = await api.post(
          `/report/generate-report`,
          body,
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(dataReport);
      } catch (err) {
        if (err?.response?.status === 400) {
          Notiflix.Notify.info(err.response.data?.message);
        } else {
          Notiflix.Notify.failure(
            'Não conseguimos buscar seu relatório. Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [contract],
  );

  const handleSubmit = useCallback(
    async (data) => {
      try {
        Notiflix.Block.circle('.notiflix-send-change-product');
        if (!changeDate.value) {
          setChangeDate((prev) => ({
            ...prev,
            error: 'É necessário informar uma data.',
          }));
          return;
        }
        productRef.current?.setError(
          !productRef.current?.value ? 'É necessário informar um produto.' : '',
        );
        // if (
        //   !attachmentsRef.current?.files ||
        //   attachmentsRef.current.files.length === 0
        // ) {
        //   attachmentsRef.current?.setError('É necessário incluir um anexo');
        //   return;
        // }
        if (!productRef.current?.value) {
          return;
        }

        const params = new FormData();
        params.append('newProductId', productRef.current.value);
        params.append('changeDate', dateToPTBR(changeDate.value));
        params.append('observation', data.observation);
        attachmentsRef.current?.files?.forEach((f, index) => {
          params.append(`contractFiles[${index}]`, f);
        });
        if (isChange) {
          if (externalParams.userUnimedId) {
            params.append('userUnimedId', externalParams.userUnimedId);
          } else {
            params.append(
              'userUnimedId',
              rowsSelecteds[0].userUnimedId.toString(),
            );
          }

          params.append('requestId', isChange);
          const { data: dataApi } = await api.post(
            `company/edit-product-change`,
            params,
          );
          const { message } = dataApi;
          Modal2.Success({
            closable: true,
            autoWidth: true,
            title: 'Perfeito!',
            text: message,
            children: (
              <Button
                modal
                onClick={() => {
                  Modal2.Close();
                }}
              >
                Ok
              </Button>
            ),
          });
          if (externalParams.userUnimedId) {
            history.goBack();
          } else {
            callbackSetStep(1);
          }
        } else if (!isChange) {
          if (externalParams.userUnimedId) {
            params.append('userUnimedId', externalParams.userUnimedId);
          } else {
            params.append(
              'userUnimedId',
              rowsSelecteds[0].userUnimedId.toString(),
            );
          }
          const { data: dataApi } = await api.post(
            `company/send-product-change`,
            params,
          );

          const { message, content } = dataApi;
          setIsChange(content[0].requestId);
          Modal2.Success({
            closable: true,
            autoWidth: true,
            title: 'Perfeito!',
            text: message,
            children: (
              <Button
                modal
                onClick={() => {
                  Modal2.Close();
                }}
              >
                Ok
              </Button>
            ),
          });
          if (externalParams.userUnimedId) {
            history.goBack();
          } else {
            callbackSetStep(1);
          }
        }
      } catch (err) {
        if (err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button
                modal
                onClick={() => {
                  Modal2.Close();
                }}
              >
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Algo deu errado ao tentar realizar essa solicitação no servidor. Por favor, tente novamente mais tarde.',
          );
          console.log(err);
        }
      } finally {
        Notiflix.Block.remove('.notiflix-send-change-product');
      }
    },
    [
      callbackSetStep,
      changeDate.value,
      externalParams.userUnimedId,
      history,
      isChange,
      rowsSelecteds,
    ],
  );

  const buttonSave = useCallback(
    async (data) => {
      try {
        Notiflix.Block.circle('.notiflix-send-product');
        if (!changeDate.value) {
          setChangeDate((prev) => ({
            ...prev,
            error: 'É necessário informar uma data.',
          }));
          return;
        }
        productRef.current?.setError(
          !productRef.current?.value ? 'É necessário informar um produto.' : '',
        );
        if (!productRef.current?.value) {
          return;
        }

        const params = new FormData();
        params.append('newProductId', productRef.current.value);
        params.append('changeDate', dateToPTBR(changeDate.value));
        params.append('observation', data.observation);
        attachmentsRef.current?.files?.forEach((f, index) => {
          params.append(`contractFiles[${index}]`, f);
        });
        if (isChange) {
          params.append('requestId', isChange);
          const { data: dataApi } = await api.post(
            `company/edit-product-change`,
            params,
          );
          const { message } = dataApi;
          Modal2.Success({
            closable: true,
            autoWidth: true,
            title: 'Perfeito!',
            text: message,
            children: (
              <Button
                modal
                onClick={() => {
                  Modal2.Close();
                }}
              >
                Ok
              </Button>
            ),
          });
        } else if (!isChange) {
          if (externalParams.userUnimedId) {
            params.append('userUnimedId', externalParams.userUnimedId);
          } else if (rowsSelecteds[0].userUnimedId) {
            params.append(
              'userUnimedId',
              String(rowsSelecteds[0].userUnimedId),
            );
          }

          const { data: dataApi } = await api.post(
            `company/send-product-change`,
            params,
          );
          const { message, content } = dataApi;
          setIsChange(content[0].requestId);
          Modal2.Success({
            closable: true,
            autoWidth: true,
            title: 'Perfeito!',
            text: message,
            children: (
              <Button
                modal
                onClick={() => {
                  Modal2.Close();
                  // callbackSetStep(1);
                }}
              >
                Ok
              </Button>
            ),
          });
        }
      } catch (err) {
        if (err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button
                modal
                onClick={() => {
                  Modal2.Close();
                }}
              >
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Algo deu errado ao tentar realizar essa solicitação no servidor. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove('.notiflix-send-product');
      }
    },
    [changeDate.value, externalParams.userUnimedId, isChange, rowsSelecteds],
  );

  const getPreviousChanges = useCallback(
    async (userUnimedId = '') => {
      try {
        const { data } = await api.get(
          `/company/product-change?userUnimedId=${
            userUnimedId || rowsSelecteds[0].userUnimedId
          }`,
        );
        const { content } = data;
        const {
          changeDate: cngDate,
          holderName,
          insuredName,
          newProductId,
          observation,
          planId,
          productName,
          requestId,
          userPlanId,
        } = content[0];

        if (productName) {
          formInfoRef.current?.setFieldValue('actualProduct', productName);
        }
        if (cngDate) {
          setChangeDate({
            value: ptBRToDate(cngDate),
            error: '',
          });
        }
        if (observation) {
          formRef.current?.setFieldValue('observation', observation);
        }
        if (newProductId) {
          productRef.current?.setValue('', newProductId);
        }
        if (requestId) {
          setIsChange(requestId);
        }

        if (userPlanId) {
          formInfoRef.current?.setFieldValue('cardNumber', userPlanId);
        }
        if (insuredName) {
          formInfoRef.current?.setFieldValue('beneficiary', insuredName);
        }
        if (holderName || rowsSelecteds[0].beneficiaryName) {
          formInfoRef.current?.setFieldValue(
            'owner',
            holderName ?? rowsSelecteds[0].beneficiaryName,
          );
        }
      } catch (err) {
        // console.log('sem valores anteriores');
      }
    },
    [rowsSelecteds],
  );

  const getInitialData = useCallback(async () => {
    try {
      let productId: string | undefined = '';

      if (rowsSelecteds.length === 0) {
        productId = externalParams.productId;
      } else {
        productId = String(rowsSelecteds[0].productId);
      }

      const { data } = await api.get(
        `/company/get-product-to-edit?contractId=${contract.contractId}&actualProductId=${productId}`,
      );
      const { content } = data;
      setProductOptions(
        content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { productName: string; planId: string },
          ) => {
            acc.push({
              title: act.productName,
              value: act.planId,
            });
            return acc;
          },
          [],
        ),
      );

      getPreviousChanges(
        externalParams.userUnimedId ? externalParams.userUnimedId : '',
      );
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos carregar os produtos disponíveis para seleção. Por favor, tente novamente mais tarde.',
      );
    }
  }, [
    contract.contractId,
    externalParams.productId,
    externalParams.userUnimedId,
    getPreviousChanges,
    rowsSelecteds,
  ]);

  useEffect(() => {
    if (!externalParams.userUnimedId) {
      Modal2.Warning({
        closable: false,
        autoWidth: true,
        title: 'Atenção!',
        text: 'Informamos que a ação será realizada para os dependentes dos titulares selecionados, mesmo que não selecionados',
        children: (
          <Button modal onClick={() => Modal2.Close()}>
            Ok
          </Button>
        ),
      });
    }
    getInitialData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (attachmentsRef.current) {
      if (
        attachmentsRef.current.files &&
        attachmentsRef.current.files.length > 0
      ) {
        setHasAttachment(true);
      } else {
        setHasAttachment(false);
      }
    }
  }, [attachmentDispatch]);

  return (
    <>
      <FormWrapper
        onSubmit={() => console.log('N/A')}
        ref={formInfoRef}
        className="form-change-product-details"
      >
        <Input
          name="cardNumber"
          insideInputTitle="Carteira"
          widthContainerDesktop="50%"
        />
        <Input
          name="beneficiary"
          insideInputTitle="Beneficiário"
          widthContainerDesktop="50%"
        />
        <Input
          name="owner"
          insideInputTitle="Titular"
          widthContainerDesktop="50%"
        />
        <Input
          name="actualProduct"
          insideInputTitle="Produto atual"
          widthContainerDesktop="50%"
        />
      </FormWrapper>
      <FormWrapper
        onSubmit={handleSubmit}
        formLook
        ref={formRef}
        className="form-change-product"
      >
        <DatePicker
          name="changeDate"
          title="Data de Solicitação:"
          selected={changeDate.value}
          error={changeDate.error}
          onChange={(date: Date) => setChangeDate({ value: date, error: '' })}
          formLook
          icon
          widthContainerDesktop="20%"
          themeColor={defaultColor}
        />

        <Select
          name="product"
          title="Produto:"
          ref={productRef}
          options={productOptions}
          formLook
          liveReload
          widthContainerDesktop="80%"
          themeColor={defaultColor}
        />

        <Input
          name="observation"
          title="Observação:"
          type="textarea"
          formLook
          themeColor={defaultColor}
        />

        <InputFile
          name="attachments"
          title="Anexos:"
          placeholder="Anexos"
          multiple
          ref={attachmentsRef}
          callbackHasChanged={() => setAttachmentDispatch(v4())}
          themeColor={defaultColor}
        />

        <Button
          secondary
          autoWidth
          formLook
          onClick={() => {
            return externalParams.userUnimedId
              ? history.goBack()
              : callbackSetStep(1);
          }}
          className="marginRight"
        >
          Voltar
        </Button>
        {isChange && (
          <Button
            orangeButton
            autoWidth
            formLook
            onClick={() =>
              handleGenerateReport(isChange, '.notiflix-change-product-report')
            }
            className="notiflix-change-product-report"
          >
            Imprimir
          </Button>
        )}
        {hasAttachment && (
          <Button
            type="submit"
            autoWidth
            greenCriticalButton
            formLook
            className="notiflix-send-change-product"
          >
            Salvar e enviar
          </Button>
        )}

        <Button
          greenButton
          autoWidth
          formLook
          onClick={() => buttonSave(formRef.current?.getData())}
          className="notiflix-send-product"
        >
          Salvar
        </Button>

        {/* <Button greenButton autoWidth formLook>
          Enviar
        </Button> */}
      </FormWrapper>
    </>
  );
}
