/* eslint-disable max-len */
import React from 'react';
import { svgProps } from '..';

const ImportantSvg: React.FC<svgProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="79"
      height="70"
      viewBox="0 0 79 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.5262 7.10068L3.9363 56.4986C3.32494 57.5467 3.00352 58.7694 3.00003 59.9572C2.99654 61.1799 3.31093 62.4026 3.9153 63.4506C4.51967 64.4987 5.39304 65.4069 6.44458 66.0008C7.49612 66.6297 8.69091 66.9791 9.91014 66.9791H69.0899C70.3091 66.9791 71.5039 66.6297 72.5554 66.0008C73.607 65.4069 74.4803 64.4987 75.0847 63.4506C75.6891 62.4026 76.0035 61.1799 76 59.9572C75.9965 58.7694 75.6751 57.5467 75.0637 56.4986L45.4738 7.10068C44.852 6.08757 43.9751 5.21434 42.9271 4.62044C41.8825 4.06148 40.7018 3.74707 39.5 3.74707C38.2982 3.74707 37.1175 4.06148 36.0729 4.62044C35.0249 5.21434 34.148 6.08757 33.5262 7.10068Z"
        stroke="#F47920"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.5 25.0596V39.0335"
        stroke="#F47920"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.5 53.0059H39.5173"
        stroke="#F47920"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default ImportantSvg;
