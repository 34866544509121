import React, { useCallback, useEffect, useRef, useState } from 'react';
import Notiflix from 'notiflix';

import PageWrapper from '../../components/PageWrapper';
import { Column } from '../../components/Table';
import { BeneficiaryUser, UserCommon, useAuth } from '../../hooks/auth';
import { Term } from '../../hooks/releasesAndTerms';
import api from '../../services/api/api';
import AddField, { getTag } from '../../utils/tableUtils';
import { Title, Table, Observation, ContainerTable } from './styles';

const Res: React.FC = () => {
  const [terms, setTerms] = useState([] as Term[]);
  const [messagePortal, setMessagePortal] = useState('');
  const { user: userT } = useAuth();
  const user = userT as UserCommon & BeneficiaryUser;

  const getMessage = useCallback(async () => {
    const msgPortal = await api.get(
      `/beneficiary/get-res-initial-term?nrSeqSegurado=${user.userUnimedId}`,
    );
    setMessagePortal(msgPortal.data.content[0].dsMensagemPortal);
  }, [user.userUnimedId]);

  const getTerms = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');
      const { data } = await api.get(
        `/beneficiary/get-res-term?nrSeqSegurado=${user.userUnimedId}`,
      );
      const content = data.content as Term[];
      setTerms(content);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops.. Não conseguimos buscar os termos no momento. Tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, [setTerms, user]);

  const statusTemplate = useCallback((status) => {
    switch (status) {
      case 'Aceito':
        return getTag('Status', 'Aceito', 'green', 'Aceito');
      case 'Aguardando ação':
        return getTag('Status', 'Aguard. Ação', 'orange', 'Aguardando Ação');
      case 'Revogado':
        return getTag('Status', 'Revogado', 'red', 'Revogado');
      default:
        return <>{status}</>;
    }
  }, []);

  useEffect(() => {
    getMessage();
    getTerms();
  }, [getTerms, getMessage]);

  return (
    <ContainerTable>
      <PageWrapper gridTemplateRows="auto auto auto 1fr">
        <Title>Acesso ao RES</Title>
        <Observation>
          <div dangerouslySetInnerHTML={{ __html: messagePortal }} />
        </Observation>
        <Table
          items={terms}
          key="termos"
          columnResizeMode="fit"
          className="notiflix-table-loading"
        >
          <Column
            field="insuredName"
            header="Beneficiário"
            sortable
            body={(data: Term) => AddField(data.nmSegurado, 'Beneficiário')}
            bodyClassName="clicable"
          />
          <Column
            field="initialsSituation"
            header="Status"
            headerStyle={{ textAlign: 'center' }}
            sortable
            className="status"
            body={(data: Term) => statusTemplate(data.ieStatus)}
            bodyClassName="clicable"
          />
          <Column
            field="myDate"
            header="Meus dados"
            className="myDate"
            body={(data) =>
              AddField(
                data.ieStatus === 'Aceito' ? data.dsLinkRES : '/termos',
                'Meus Dados',
                true,
                '',
                data.ieStatus === 'Aceito' ? 'Acessar meus dados médicos' : '',
                data.ieStatus !== 'Aceito' ? 'Ver termos' : '',
              )
            }
            bodyClassName="clicable"
          />
        </Table>
      </PageWrapper>
    </ContainerTable>
  );
};

export default Res;
