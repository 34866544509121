import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import { useTheme } from '../../hooks/theme';
import {
  Container,
  ContentContainer,
  Content,
  IconBackground,
  BandaidIcon,
  CalendarIcon,
  ExameIcon,
  FirstIdBoxIcon,
  GalleryIcon,
  PlanIcon,
  WhatsappFirstAidIcon,
} from './styles';
import PageWrapper from '../../components/PageWrapper';
import { FirstAidBoxSvg } from '../../components/Svg';

const DressingsOutpatientClinic: React.FC = () => {
  const { theme } = useTheme();
  const history = useHistory();
  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <Container>
        <Title themeColor={theme.colors.palet.institutional2}>
          Ambulatório de curativos
        </Title>
        <Content>
          <ContentContainer to="/ambulatorio-curativos-agendamento">
            <IconBackground>
              <CalendarIcon />
            </IconBackground>
            <h2>Agendamentos</h2>
          </ContentContainer>
          <ContentContainer to="/ambulatorio-curativos-cuidado">
            <IconBackground>
              <FirstAidBoxSvg />
            </IconBackground>
            <h2>Meta de cuidado</h2>
          </ContentContainer>
          <ContentContainer to="/ambulatorio-curativos-nutricao">
            <IconBackground>
              <PlanIcon />
            </IconBackground>
            <h2>Orientação nutricional</h2>
          </ContentContainer>
          <ContentContainer to="/ambulatorio-curativos-fotos">
            <IconBackground>
              <GalleryIcon />
            </IconBackground>
            <h2>Fotos</h2>
          </ContentContainer>
          <ContentContainer to="/ambulatorio-curativos-exames">
            <IconBackground>
              <ExameIcon />
            </IconBackground>
            <h2>Exames</h2>
          </ContentContainer>
          <ContentContainer to="/ambulatorio-curativos-lesoes">
            <IconBackground>
              <BandaidIcon />
            </IconBackground>
            <h2>Tipos de lesões</h2>
          </ContentContainer>

          <a
            className="contentContainer"
            href="https://wa.me/message/4H54KVBIVUO5K1"
            target="_aboutBlank"
          >
            <IconBackground>
              <WhatsappFirstAidIcon />
            </IconBackground>
            <h2>Whatsapp</h2>
          </a>
        </Content>
      </Container>
    </PageWrapper>
  );
};

export default DressingsOutpatientClinic;
