import styled, { css, keyframes } from 'styled-components';
import { transparentize } from 'polished';
import Button from '../../../components/Button';

interface WrapperProps {
  autoWidth?: boolean;
}

const fadeIn = keyframes`
  from{
    transform: scale(0.9);
    opacity: 0;
  }
  to{
    transform: scale(1);
    opacity: 1;
  }
`;

export const Container = styled.div`
  margin-bottom: 30px;
  grid-column: 2/3;
  padding: 0 2em;
  @media (max-width: 768px) {
    grid-column: 1;
  }
  @media (min-width: 769px) and (max-width: 1600px) {
    grid-column: 1/4;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-rows: repeat(2, 1fr); */
  column-gap: 8px;
  row-gap: 5em;
  width: 100%;
  padding: 3em 0;
  @media (max-width: 768px) {
    row-gap: 8px;
    /* height: 100%; */
    padding: 1.5em;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-break: keep-all;
  margin-bottom: auto;
  text-decoration: none;
  /* gap: 10px; */
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 5px 20px
    ${(props) => transparentize(0.4, props.theme.colors.text.lightGrayToBlack)};
  border-radius: 1em;
  cursor: pointer;

  h2 {
    font-family: 'Unimed-Sans-SemiBold';
    color: ${(props) => props.theme.colors.text.grayishToWhite};
    transition: 300ms;
    font-size: 1rem;
    line-height: 1.1rem;
    margin: 8px 0;
    text-align: left;
    color: ${(props) =>
      props.theme.title === 'light'
        ? props.theme.colors.text.primary
        : props.theme.colors.text.white};
    @media (max-width: 768px) {
      margin-top: 0px;
      margin-left: 10px;
    }
  }
  > div {
    transition: 300ms;
  }

  &:hover {
    h2 {
      color: ${(props) =>
        props.theme.title === 'light'
          ? props.theme.colors.palet.institutional2
          : props.theme.colors.text.secondary};
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
`;

export const IconBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 150px;
  height: 150px;

  @media (max-width: 768px) {
    padding: 12px;
    width: 100px;
    height: 100px;
  }

  img {
    width: 100%;
    border-radius: 1em 1em 0 0;
  }
`;

export const ModalWrapper = styled.div<WrapperProps>`
  padding: 0 5em;
  z-index: 99;
  position: relative;
  margin: 0 auto;
  max-height: calc(89vh - 60px);
  overflow-y: auto;
  border-radius: 30px;
  text-align: center;
  &.small {
    width: 475px;
  }
  ${(props) =>
    props.autoWidth &&
    css`
      width: auto;
    `}

  transform: scale(0.9);
  opacity: 0;
  animation: ${fadeIn} 500ms forwards;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    padding-bottom: 2em;
  }

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    background-position: center;
  }

  p {
    text-align: justify;
    font-family: 'Unimed-Sans-Book';
  }

  @media (max-width: 768px) {
    padding: 0;
    img {
      width: 80%;
      height: 150px;
    }
    div {
      padding-bottom: 0;
    }

    p {
      img {
        width: 324px !important;
        height: 200px !important;
        text-align: center;
      }
    }

    .btn2 {
      margin-bottom: 1em;
    }
  }
`;

export const ButtonGoBack = styled.button`
  float: right;
  border: none;
  padding: 0;
  background: transparent;
  color: ${(props) =>
    props.theme.title === 'light'
      ? props.theme.colors.text.primary
      : props.theme.colors.text.white};
  font-family: 'Unimed-Sans-Book';
  &:hover {
    box-shadow: none;
    border: none;
  }
`;

export const Message = styled.p`
  font-family: 'Unimed-Sans-Book';
  color: #5b5c65;
  text-align: center;
  color: ${(props) =>
    props.theme.title === 'light'
      ? props.theme.colors.text.primary
      : props.theme.colors.text.white};
`;
