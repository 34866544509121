import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';

import { GrupowSvg, LogoUnimedSvg } from '../Svg';
import NavDropdown from './NavDropdown';
import {
  Container,
  Logoff,
  Navigation,
  Link,
  LogoWrapper,
  CloseWrapper,
  ContainerLogoW,
} from './styles';
import { VALIDATE_ROUTE as VR } from '../../routes/validatorRoutes';
import { useOnlineMode } from '../../hooks/useOnlineMode';

interface NavigationAsideProps {
  hidden?: boolean;
  className?: string;
  callbackClose(): void;
}

interface RoutesWithTags {
  [key: string]: { url: string; title: string };
}

const NavigationAside: React.FC<NavigationAsideProps> = ({
  hidden = false,
  className,
  callbackClose,
}) => {
  const { logout, user } = useAuth();
  const containerRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const { isOnline } = useOnlineMode();

  const visibleRoutes = useCallback(
    (routes: RoutesWithTags, tags: string[]) => {
      return tags.reduce((acc, act) => {
        const found = routes[act];
        if (found) {
          acc.push(found);
        }
        return acc;
      }, [] as { url: string; title: string }[]);
    },
    [],
  );

  const handleLogoff = useCallback(() => {
    logout();
  }, [logout]);

  const addTouchAction = useCallback(() => {
    if (containerRef.current) {
      let left: number;
      let right: number;
      containerRef.current.addEventListener('touchstart', (evt) => {
        right = evt.changedTouches[0].pageX;
      });
      containerRef.current.addEventListener('touchend', (evt) => {
        left = evt.changedTouches[0].pageX;
        if (left - right < -100) {
          callbackClose();
        }
      });
    }
  }, [callbackClose]);

  useEffect(() => {
    addTouchAction();
  }, [addTouchAction]);

  const dressingsOutpatientClinic = useMemo(() => {
    if (user.profile === 1) {
      const routes = {
        'AC-ACA': {
          url: '/ambulatorio-curativos-agendamento',
          title: 'Agendamentos',
        },
        'AC-ACC': {
          url: '/ambulatorio-curativos-cuidado',
          title: 'Meta de cuidado',
        },
        'AC-ACN': {
          url: '/ambulatorio-curativos-nutricao',
          title: 'Orientação nutricional',
        },
        'AC-ACF': {
          url: '/ambulatorio-curativos-fotos',
          title: 'Fotos',
        },
        'AC-ACE': {
          url: '/ambulatorio-curativos-exames',
          title: 'Exames',
        },
        'AC-ACL': {
          url: '/ambulatorio-curativos-lesoes',
          title: 'Tipos de lesões',
        },
      };

      return visibleRoutes(routes, user.tags);
    }
    return [
      {
        url: '/ambulatorio-curativos-agendamento',
        title: 'Agendamentos',
      },
      {
        url: '/ambulatorio-curativos-cuidado',
        title: 'Meta de cuidado',
      },
      {
        url: '/ambulatorio-curativos-nutricao',
        title: 'Orientação nutricional',
      },
      {
        url: '/ambulatorio-curativos-fotos',
        title: 'Fotos',
      },
      {
        url: '/ambulatorio-curativos-exames',
        title: 'Exames',
      },
      {
        url: '/ambulatorio-curativos-lesoes',
        title: 'Tipos de lesões',
      },
    ];
  }, [user, visibleRoutes]);

  const registerData = useMemo(() => {
    if (user.profile === 1) {
      const routes = {
        'DC-DBP': {
          url: '/dados-beneficiario-produto',
          title: 'Componente Cadastral',
        },
        'DC-CRA': {
          url: '/carencia',
          title: 'Carência',
        },
        'DC-CPT': {
          url: '/cobertura-parcial-temporaria',
          title: 'Cobertura Parcial Temporária',
        },
        'DC-EDU': {
          url: '/extrato-de-utilizacao',
          title: 'Extrato de Utilização',
        },
        'DC-ADC': {
          url: '/alterar-dados-cadastrais',
          title: 'Alterar Dados Cadastrais',
        },
        'DC-CPA': {
          url: '/consulta-protocolo-atendimento',
          title: 'Protocolos de Atendimento',
        },
      };
      return visibleRoutes(routes, user.tags);
    }
    return [
      {
        url: '/dados-beneficiario-produto',
        title: 'Componente Cadastral',
      },
      {
        url: '/carencia',
        title: 'Carência',
      },
      {
        url: '/cobertura-parcial-temporaria',
        title: 'Cobertura Parcial Temporária',
      },
      {
        url: '/extrato-de-utilizacao',
        title: 'Extrato de Utilização',
      },
      {
        url: '/alterar-dados-cadastrais',
        title: 'Alterar Dados Cadastrais',
      },
      {
        url: '/consulta-protocolo-atendimento',
        title: 'Protocolos de Atendimento',
      },
    ];
  }, [user, visibleRoutes]);

  const authorization = useMemo(() => {
    if (user.profile === 1) {
      const routes = {
        'AU-STO': {
          url: '/autorizacao',
          title: 'Status de Autorização',
        },
        'AU-RAU': {
          url: '/requisitar-autorizacao',
          title: 'Requisitar Autorização',
        },
      };
      return visibleRoutes(routes, user.tags);
    }
    return [
      {
        url: '/autorizacao',
        title: 'Status de Autorização',
      },
      {
        url: '/requisitar-autorizacao',
        title: 'Requisitar Autorização',
      },
    ];
  }, [user, visibleRoutes]);

  const documents = useMemo(() => {
    if (user.profile === 1) {
      const routes = {
        'DA-MAS': {
          url: '/manuais-e-arquivos',
          title: 'Manuais e Arquivos',
        },
        'DA-FAQ': {
          url: '/faq',
          title: 'FAQ',
        },
        'DA-TOS': {
          url: '/tutoriais',
          title: 'Tutoriais',
        },
        'DA-TRS': {
          url: '/termos',
          title: 'Termos',
        },
        'DA-PSA': {
          url: '/privacidade-e-seguranca',
          title: 'Privacidade e Segurança',
        },
      };
      return visibleRoutes(routes, user.tags);
    }
    return [
      {
        url: '/manuais-e-arquivos',
        title: 'Manuais e Arquivos',
      },
      {
        url: '/faq',
        title: 'FAQ',
      },
      {
        url: '/tutoriais',
        title: 'Tutoriais',
      },
      {
        url: '/termos',
        title: 'Termos',
      },
      {
        url: '/privacidade-e-seguranca',
        title: 'Privacidade e Segurança',
      },
    ];
  }, [user, visibleRoutes]);

  const consultSolicitations = useMemo(() => {
    if (user.profile === 1) {
      const routes = {
        'CS-SVC': {
          url: '/consulta-segunda-via-cartao',
          title: 'Consulta 2ª Via do Cartão',
        },
        'CS-CAC': {
          url: '/consulta-alteracoes-cadastrais',
          title: 'Consulta de Alterações Cadastrais',
        },
        'CS-CRB': {
          url: '/consulta-rescisao',
          title: 'Consulta Rescisão de Beneficiário',
        },
        'CS-CPJ': {
          url: '/consulta-alteracoes-contrato-cnpj',
          title: 'Consulta Alterações de Contrato/CNPJ',
        },
        'CS-CAP': {
          url: '/consulta-alteracoes-produto',
          title: 'Consulta Alteração de Produto',
        },
        'CS-CIB': {
          url: '/consulta-inclusao-beneficiario',
          title: 'Consulta Inclusão de Beneficiário',
        },
      };

      return visibleRoutes(routes, user.tags);
    }
    return [
      {
        url: '/consulta-segunda-via-cartao',
        title: 'Consulta 2ª Via do Cartão',
      },
      {
        url: '/consulta-alteracoes-cadastrais',
        title: 'Consulta de Alterações Cadastrais',
      },
      {
        url: '/consulta-rescisao',
        title: 'Consulta Rescisão de Beneficiário',
      },
      {
        url: '/consulta-alteracoes-contrato-cnpj',
        title: 'Consulta Alterações de Contrato/CNPJ',
      },
      {
        url: '/consulta-alteracoes-produto',
        title: 'Consulta Alteração de Produto',
      },
      {
        url: '/consulta-inclusao-beneficiario',
        title: 'Consulta Inclusão de Beneficiário',
      },
    ];
  }, [user, visibleRoutes]);

  const financialInformation = useMemo(() => {
    if (user.profile === 1) {
      const routes = {
        'FI-DVB': {
          url: '/demonstrativos-e-segunda-via-boleto',
          title: 'Demonstrativos e 2ª Via Boleto',
        },
        'FI-IRJ': {
          url: '/indice-reajustes',
          title: 'Índice de Reajustes',
        },
        'FI-SND': {
          url: '/sinistralidade',
          title: 'Sinistralidade',
        },
      };
      return visibleRoutes(routes, user.tags);
    }
    return [
      {
        url: '/demonstrativos-e-segunda-via-boleto',
        title: 'Demonstrativos e 2ª Via Boleto',
      },
      {
        url: '/indice-reajustes',
        title: 'Índice de Reajustes',
      },
      {
        url: '/sinistralidade',
        title: 'Sinistralidade',
      },
    ];
  }, [user, visibleRoutes]);

  const beneficiaryInclude = useMemo(() => {
    if (user.profile === 1) {
      const routes = {
        'IB-IBM': {
          url: '/incluir-beneficiario',
          title: 'Incluir Beneficiário - Manualmente',
        },
        'IB-IBL': {
          url: '/incluir-beneficiario-gerar-link',
          title: 'Incluir Beneficiário - Gerar Link',
        },
        'IB-IBO': {
          url: '/incluir-beneficiario-por-lote',
          title: 'Incluir Beneficiário - Por Lote',
        },
      };
      return visibleRoutes(routes, user.tags);
    }
    return [
      {
        url: '/incluir-beneficiario',
        title: 'Incluir Beneficiário - Manualmente',
      },
      {
        url: '/incluir-beneficiario-gerar-link',
        title: 'Incluir Beneficiário - Gerar Link',
      },
      {
        url: '/incluir-beneficiario-por-lote',
        title: 'Incluir Beneficiário - Por Lote',
      },
    ];
  }, [user, visibleRoutes]);

  const auxiliarDocuments = useMemo(() => {
    if (user.profile === 1) {
      const routes = {
        'DA-FAQ': {
          url: '/faq',
          title: 'FAQ',
        },
        'DA-TOS': {
          url: '/tutoriais',
          title: 'Tutoriais',
        },
        'DA-MAS': {
          url: '/manuais-e-arquivos',
          title: 'Manuais e Arquivos',
        },
        'DA-DCO': {
          url: '/documentos-contrato',
          title: 'Documentos do Contrato',
        },
        'DA-PSA': {
          url: '/privacidade-e-seguranca',
          title: 'Privacidade e Segurança',
        },
      };
      return visibleRoutes(routes, user.tags);
    }
    return [
      {
        url: '/faq',
        title: 'FAQ',
      },
      {
        url: '/tutoriais',
        title: 'Tutoriais',
      },
      {
        url: '/manuais-e-arquivos',
        title: 'Manuais e Arquivos',
      },
      {
        url: '/documentos-contrato',
        title: 'Documentos do Contrato',
      },
      {
        url: '/privacidade-e-seguranca',
        title: 'Privacidade e Segurança',
      },
    ];
  }, [user, visibleRoutes]);

  const scheduleLinks = useMemo(() => {
    if (user.profile === 1) {
      const routes = {
        'AG-RA': {
          url: '/pesquisa-e-marcacao-de-agenda',
          title: 'Realizar Agendamento',
        },
        'AG-CO': {
          url: '/visualizacao-e-cancelamentos-de-agendamentos',
          title: 'Consultar Agendamento',
        },
      };
      return visibleRoutes(routes, user.tags);
    }

    return [];
  }, [user, visibleRoutes]);

  const requestsLinks: { url: string; title: string }[] = useMemo(() => {
    if (user.profile === 1) {
      const routes = {
        'SO-SVC': {
          url: '/solicita-segunda-via-cartao',
          title: '2ª Via do Cartão',
        },
        'SO-RBN': {
          url: '/rescindir-beneficiario',
          title: 'Rescindir Beneficiário',
        },
        'SO-RBO': {
          url: '/reembolso',
          title: 'Reembolso',
        },
        'SO-OVA': {
          url: '/ouvidoria',
          title: 'Ouvidoria',
        },
      };
      return visibleRoutes(routes, user.tags);
    }
    return [
      {
        url: '/ouvidoria',
        title: 'Ouvidoria',
      },
    ];
  }, [user, visibleRoutes]);

  const statusRequests: { url: string; title: string }[] = useMemo(() => {
    if (user.profile === 1) {
      const routes = {
        'CS-SVC': {
          url: '/consulta-segunda-via-cartao',
          title: '2ª Via do Cartão',
        },
        'CS-CAC': {
          url: '/consulta-alteracoes-cadastrais',
          title: 'Alterações Cadastrais',
        },
        'CS-CRB': {
          url: '/consulta-rescisao',
          title: 'Rescisão de Beneficiário',
        },
        'SS-CSR': {
          url: '/consulta-solicitacao-reembolso',
          title: 'Solicitação de Reembolso',
        },
      };
      return visibleRoutes(routes, user.tags);
    }
    return [
      {
        url: '/consulta-alteracoes-cadastrais',
        title: 'Alterações Cadastrais',
      },
    ];
  }, [user, visibleRoutes]);

  const financialLink: { url: string; title: string }[] = useMemo(() => {
    if (user.profile === 1) {
      const routes = {
        'FI-DVB': {
          url: '/demonstrativos-e-segunda-via-boleto',
          title: 'Demonstrativos e 2ª Via Boleto',
        },
        'FI-ECO': {
          url: '/extrato-coparticipacao',
          title: 'Extrato Coparticipação',
        },
        'FI-DE': { url: '/debito-em-conta', title: 'Débito em Conta' },
        'FI-DIR': {
          url: '/declaracao-imposto-renda',
          title: 'Declaração de Imposto de Renda',
        },
      };
      return visibleRoutes(routes, user.tags);
    }

    return [
      {
        url: '/demonstrativos-e-segunda-via-boleto',
        title: 'Demonstrativos e 2ª Via Boleto',
      },
      {
        url: '/extrato-coparticipacao',
        title: 'Extrato Coparticipação',
      },
      {
        url: '/declaracao-imposto-renda',
        title: 'Declaração de Imposto de Renda',
      },
    ];
  }, [user, visibleRoutes]);

  const actualLocale = window.location.pathname
    .split('/')
    .slice(0, 2)
    .join('/');

  return (
    <Container
      collapsed={hidden}
      className={className}
      ref={containerRef}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      data-navigation-aside
    >
      <LogoWrapper>
        <LogoUnimedSvg onClick={() => history.push('/')} />
      </LogoWrapper>
      <CloseWrapper>
        <button type="button" onClick={callbackClose}>
          <FiX size={24} />
        </button>
      </CloseWrapper>

      {user.profile === 1 && (
        <>
          {isOnline ? (
            <Navigation>
              <Link className={`${actualLocale === '/' && 'active'}`} to="/">
                Página inicial
              </Link>
              {user.tags.some((tag) => tag === VR.COMUNICADOS) && (
                <Link
                  className={`${actualLocale === '/comunicados' && 'active'}`}
                  to="/comunicados"
                >
                  Comunicados
                </Link>
              )}
              {user.tags.some((tag) => tag === VR.WALLET) && (
                <Link
                  className={`${actualLocale === '/carteirinha' && 'active'}`}
                  to="/carteirinha"
                >
                  Carteirinha Virtual
                </Link>
              )}
              {user.isFirstAid && dressingsOutpatientClinic.length > 0 && (
                <NavDropdown
                  title="Ambulatório de curativos"
                  titleUrl="/ambulatorio-curativos"
                  links={dressingsOutpatientClinic}
                />
              )}
              {registerData.length > 0 && (
                <NavDropdown
                  title="Dados Cadastrais (PIN-SS)"
                  links={registerData}
                />
              )}
              {scheduleLinks.length > 0 && (
                <NavDropdown
                  title="Agenda de Consulta Médica"
                  links={scheduleLinks}
                />
              )}
              {requestsLinks.length > 0 && (
                <NavDropdown title="Solicitações" links={requestsLinks} />
              )}
              {financialLink.length > 0 && (
                <NavDropdown title="Financeiro" links={financialLink} />
              )}
              {authorization.length > 0 && (
                <NavDropdown title="Autorizações" links={authorization} />
              )}
              {statusRequests.length > 0 && (
                <NavDropdown
                  title="Status das Solicitações"
                  links={statusRequests}
                />
              )}
              {documents.length > 0 && (
                <NavDropdown title="Documentos" links={documents} />
              )}

              <Link
                className={`${actualLocale === '/res' && 'active'}`}
                to="/res"
              >
                RES - Meus Dados Médicos
              </Link>
              {user.tags.some((tag) => tag === VR.RELATORIOS) && (
                <Link
                  className={`${actualLocale === '/relatorios' && 'active'}`}
                  style={{ marginBottom: '0px' }}
                  to="/relatorios"
                >
                  Relatórios
                </Link>
              )}

              <Link
                className={`${actualLocale === '/alterar-senha' && 'active'}`}
                to="/alterar-senha"
              >
                Alterar Senha
              </Link>

              <Logoff type="button" onClick={handleLogoff}>
                Sair
              </Logoff>
            </Navigation>
          ) : (
            <Navigation>
              <Link className={`${actualLocale === '/' && 'active'}`} to="/">
                Página inicial
              </Link>
            </Navigation>
          )}

          <ContainerLogoW>
            <a
              href="https://www.grupow.com.br/"
              title="GrupoW Softwares para Internet"
              target="_blanck"
            >
              Desenvolvido por <br />
              <strong>
                GrupoW Softwares para Internet <GrupowSvg />
              </strong>
            </a>
          </ContainerLogoW>
        </>
      )}

      {user.profile === 2 && (
        <>
          <Navigation>
            <Link className={`${actualLocale === '/' && 'active'}`} to="/">
              Página Inicial
            </Link>
            <Link
              className={`${actualLocale === '/comunicados' && 'active'}`}
              to="/comunicados"
            >
              Comunicados
            </Link>
            <Link
              className={`${actualLocale === '/beneficiarios' && 'active'}`}
              to="/beneficiarios"
            >
              Gerenciar Beneficiários
            </Link>
            <NavDropdown
              title="Consultar Solicitações"
              links={consultSolicitations}
            />
            <NavDropdown
              title="Informações Financeiras"
              links={financialInformation}
            />
            <NavDropdown
              title="Inclusão de Beneficiário"
              links={beneficiaryInclude}
            />

            <NavDropdown
              title="Documentos Auxiliares"
              links={auxiliarDocuments}
            />
            <Link
              className={`${actualLocale === '/rol-procedimentos' && 'active'}`}
              to="/rol-procedimentos"
            >
              Rol de Procedimentos
            </Link>
            <Link
              className={`${actualLocale === '/relatorios' && 'active'}`}
              to="/relatorios"
            >
              Relatórios
            </Link>
            <Link
              className={`${actualLocale === '/ouvidoria' && 'active'}`}
              to="/ouvidoria"
            >
              Ouvidoria
            </Link>
            <Link
              className={`${actualLocale === '/listagem-usuarios' && 'active'}`}
              to="/listagem-usuarios"
            >
              Gerenciar Usuários
            </Link>
            <Link
              className={`${actualLocale === '/alterar-senha' && 'active'}`}
              to="/alterar-senha"
            >
              Alterar Senha
            </Link>
            <Logoff type="button" onClick={handleLogoff}>
              Sair
            </Logoff>
            <ContainerLogoW>
              <a
                href="https://www.grupow.com.br/"
                title="GrupoW Softwares para Internet"
                target="_blanck"
              >
                Desenvolvido por <br />
                <strong>GrupoW Softwares para Internet</strong>
                <GrupowSvg />
              </a>
            </ContainerLogoW>
          </Navigation>
        </>
      )}
    </Container>
  );
};

export default memo(NavigationAside);
