/* eslint-disable max-len */
import React from 'react';
import { svgProps } from '..';

const ChecklistSvg: React.FC<svgProps> = ({ className }) => {
  return (
    <svg
      width="30"
      height="27"
      viewBox="0 0 30 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.04155 5.72746H2.40184L0 3.30253L1.63972 1.6859L3.23324 3.25634L6.48958 0L8.1293 1.63972L4.04155 5.72746ZM11.5244 2.31243H30.0001V4.62189H11.5244V2.31243ZM11.5244 9.23256H30.0001V11.542H11.5244V9.23256ZM30.0001 16.1699H11.5244V18.4793H30.0001V16.1699ZM11.5244 23.09H30.0001V25.3994H11.5244V23.09ZM2.40184 12.6508H4.04155L8.1293 8.56301L6.48958 6.94639L3.23324 10.2027L1.63972 8.6092L0 10.2489L2.40184 12.6508ZM4.04155 19.6111H2.40184L0 17.2093L1.63972 15.5696L3.23324 17.1631L6.48958 13.8837L8.1293 15.5234L4.04155 19.6111ZM2.40184 26.5575H4.04155L8.1293 22.4698L6.48958 20.8301L3.23324 24.1095L1.63972 22.516L0 24.1326L2.40184 26.5575Z"
        fill="white"
      />
    </svg>
  );
};

export default ChecklistSvg;
