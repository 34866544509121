import React, { useState, useEffect } from 'react';
import {
  Container,
  Label,
  Paragraph,
  RadioBox,
  CustomInput,
  LabelTitle,
} from './styles';

interface CustomRadioTimeProps {
  hoursAvailable?: any[] | any;
  selectedHour?: string;
  onHourChange?: (hour: string) => void; // Adicionando a nova prop
}

const RadioSelectHour: React.FC<CustomRadioTimeProps> = (
  { hoursAvailable, selectedHour, onHourChange },
  ref,
) => {
  const [activeHour, setActiveHour] = useState(selectedHour);

  useEffect(() => {
    setActiveHour(selectedHour);
  }, [selectedHour]);

  const handleRadioChange = (hour: string) => {
    setActiveHour(hour);
    if (onHourChange) {
      onHourChange(hour); // Chamando a função passada como prop
    }
  };
  return (
    <>
      <LabelTitle>Selecioe um horário</LabelTitle>
      <Container>
        {hoursAvailable?.map((hour: any) => (
          <Label id={activeHour} key={hour}>
            <CustomInput
              type="radio"
              name="hoursAvailable"
              id={hour}
              value={hour}
              onClick={() => handleRadioChange(hour)}
            />
            <RadioBox />
            <Paragraph>{hour}</Paragraph>
          </Label>
        ))}
      </Container>
    </>
  );
};

export default RadioSelectHour;
