import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import { FormHandles, FormProps } from '@unform/core';

import { Container } from './styles';

export interface Props extends FormProps {
  formLook?: boolean;
  className?: string;
}

const FormWrapper: ForwardRefRenderFunction<FormHandles, Props> = (
  { children, formLook, onSubmit, className, initialData, style },
  ref,
) => {
  return (
    <Container
      onSubmit={onSubmit}
      className={className}
      data-formlook={formLook ? 'true' : 'false'}
      initialData={initialData}
      ref={ref}
      style={style}
    >
      <>{children}</>
    </Container>
  );
};

export default forwardRef(FormWrapper);
