import styled from 'styled-components';

export const Title = styled.h1`
  grid-column: 1/11;
  margin: 30px 0;
  font-family: 'Unimed-Slab-Bold';
  text-align: center;
  text-transform: uppercase;
  font-size: 2.55rem;
  color: ${(props) => props.theme.colors.palet.institutional};
  @media (max-width: 768px) {
    grid-column: 1;
    margin: 30px;
  }
`;
export const Container = styled.div`
  grid-column: 1/11;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    grid-column: 1;
  }
  > form {
    display: flex;
    flex-wrap: wrap;
    > button {
      margin-left: auto;
      width: 150px;
    }
    margin-bottom: 24px;

    @media (max-width: 768px) {
      > div {
        &:nth-child(3),
        &:nth-child(4) {
          width: auto;
          flex: 1 1;
        }
      }
      > button {
        width: 100%;
      }
    }
  }
`;

export const ModalContainer = styled.div`
  padding-bottom: 22px;
`;

export const ModalRow = styled.div`
  font-size: 1rem;
  text-align: left;
  color: ${(props) => props.theme.colors.text.primary};
  strong {
    font-family: 'Unimed-Sans-Bold';
  }
  font-family: 'Unimed-Sans-Book';
  & + div {
    margin-top: 8px;
  }
`;
