import React, { createContext, useCallback, useContext } from 'react';

import {
  DefaultTheme,
  ThemeProvider as ThemeProviderStyledComponents,
} from 'styled-components';
import light from '../global/theme/light';
import dark from '../global/theme/dark';
import useStatePersisted from '../utils/useStatePersisted';

interface ContextProps {
  toggleTheme(): void;
  theme: DefaultTheme;
}

const themeContext = createContext<ContextProps>({} as ContextProps);

export const ThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useStatePersisted<DefaultTheme>(
    '@themeUnimed',
    light,
  );

  const toggleTheme = useCallback(() => {
    setTheme(theme.title === 'light' ? dark : light);
  }, [theme, setTheme]);

  return (
    <themeContext.Provider value={{ toggleTheme, theme }}>
      <ThemeProviderStyledComponents theme={theme}>
        {children}
      </ThemeProviderStyledComponents>
    </themeContext.Provider>
  );
};

export function useTheme(): ContextProps {
  const context = useContext(themeContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
