import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useState } from 'react';
import PageWrapper from '../../../components/PageWrapper';
import { Column } from '../../../components/Table';
import api from '../../../services/api/api';
import AddField, { getTag } from '../../../utils/tableUtils';
import { Title, Table } from './styles';

const BeneficiaryTerminationConsult: React.FC = () => {
  interface TableProps {
    beneficiaryName: string;
    protocolId: string;
    requestDate: string;
    sequencialNumber: string;
    status: string;
  }
  const [consultDatas, setConsultDatas] = useState<TableProps[]>([]);

  const getTableItems = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');
      const response = await api.get(`/beneficiary/list-terminations`);
      const { data } = response;
      const { content } = data;

      setConsultDatas(content);
    } catch (err) {
      if (err?.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Não foi possível buscar os dados da tabela. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, []);

  useEffect(() => {
    getTableItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusTemplate = useCallback((situation) => {
    switch (situation) {
      case 'Solicitação rejeitada':
        return getTag('Status', 'Solicitação rejeitada', 'red', situation);
      case 'Cancelado':
        return getTag('Status', 'Cancelado', 'red', situation);
      case 'Liberado':
        return getTag('Status', 'Liberado', 'green', situation);
      case 'Solicitado':
        return getTag('Status', 'Solicitado', 'orange', situation);
      default:
        return getTag('Status', situation, 'gray', situation);
    }
  }, []);

  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <Title>Consulta de rescisão do beneficiário</Title>

      <Table
        items={consultDatas}
        key="consulta-recisao-beneficiario-table"
        className="notiflix-table-loading"
        columnResizeMode="fit"
        id="consulta-recisao-beneficiario-table"
      >
        <Column
          field="protocolId"
          header="Protocolo de atendimento"
          sortable
          body={(data) => AddField(data.protocolId, 'Protocolo de Atendimento')}
        />
        <Column
          field="beneficiaryName"
          header="Nome do beneficiário"
          sortable
          body={(data) =>
            AddField(data.beneficiaryName, 'Nome do beneficiário')
          }
        />
        <Column
          field="requestDate"
          header="Data Da Solicitação"
          className="date"
          body={(data) => AddField(data.requestDate, 'Data Da Solicitação')}
        />
        <Column
          field="status"
          header="Status"
          sortable
          className="tag"
          body={(data) => statusTemplate(data.status)}
        />
      </Table>
    </PageWrapper>
  );
};

export default BeneficiaryTerminationConsult;
