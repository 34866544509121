import React from 'react';
import { svgProps } from '..';

const SheduleSvg: React.FC<svgProps> = ({ className }) => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="1" y="2" width="20" height="18" rx="3" stroke="#5B5C65" />
      <line x1="1" y1="7.5" x2="21" y2="7.5" stroke="#5B5C65" />
      <line
        x1="4.5"
        y1="11.5"
        x2="17.5"
        y2="11.5"
        stroke="#5B5C65"
        strokeMiterlimit="2.61313"
        strokeLinecap="round"
        strokeDasharray="2 2"
      />
      <line
        x1="4.5"
        y1="15.5"
        x2="17.5"
        y2="15.5"
        stroke="#5B5C65"
        strokeMiterlimit="2.61313"
        strokeLinecap="round"
        strokeDasharray="2 2"
      />
      <line
        x1="5.5"
        y1="1.5"
        x2="5.5"
        y2="0.5"
        stroke="#5B5C65"
        strokeLinecap="round"
      />
      <line
        x1="15.5"
        y1="1.5"
        x2="15.5"
        y2="0.5"
        stroke="#5B5C65"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SheduleSvg;
