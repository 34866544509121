import styled from 'styled-components';
import ButtonComponent from '../../components/Button';

export const Button = styled(ButtonComponent)`
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.h2`
  grid-column: 2/10;
  font-family: 'Unimed-Slab-Bold';
  text-align: center;
  text-transform: uppercase;
  font-size: 2.55rem;
  margin-top: 25px;
  line-height: 2.4rem;

  color: ${(props) => props.theme.colors.palet.institutional};
  @media (max-width: 768px) {
    grid-column: 1;
    font-size: 1.7rem;
  }
`;

export const TextContainer = styled.div`
  margin-top: 20px;
  background: ${(props) => props.theme.colors.text.whiteToBlack};
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  grid-column: 2/10;
  padding: 30px;
  font-family: 'Unimed-Sans-Book';
  margin-bottom: 30px;

  > div {
    * {
      color: ${(props) => props.theme.colors.text.grayishToWhite};
      font-size: 1rem;
      font-family: 'Unimed-Sans-Book';
    }

    p + p {
      margin-top: 8px;
    }
  }

  @media (max-width: 768px) {
    grid-column: 1;
    margin: 30px;
    margin-bottom: 30px;
  }
`;

export const LinkToGov = styled.a`
  text-align: center;
  margin-left: auto;
  background: #00995d;
  padding: 8px;
  font-size: 1rem;
  border-radius: 30px;
  color: white;
  font-weight: bolder;
  text-decoration: none;

  @media (max-width: 768px) {
    margin: 24px 0 0;
    width: 100%;
  }
`;
