import Notiflix from 'notiflix';
import React, { useState, useEffect, useCallback } from 'react';

import { useTheme } from 'styled-components';
import Modal2 from '../../components/Modal2';

import PageWrapper from '../../components/PageWrapper';
import { Column } from '../../components/Table';
import api from '../../services/api/api';
import AddField from '../../utils/tableUtils';
import {
  Button,
  ModalContainer,
  TutorialsContainer,
  TitleModal,
  Container,
} from './styles';

import Title from '../../components/Title';

const Tutorials: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tutorials, setTutorials] = useState([] as any[]);
  const { colors } = useTheme();

  const handleOpenModal = useCallback((item) => {
    Modal2.Generic({
      closable: true,
      autoWidth: true,
      children: (
        <ModalContainer>
          <TitleModal>{item.title}</TitleModal>
          <iframe
            title={item.title}
            width="420"
            height="315"
            src={item.videoUrl}
          />
          <Button onClick={() => Modal2.Close()} outlined float="right">
            Fechar
          </Button>
        </ModalContainer>
      ),
    });
  }, []);

  const getTutorials = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-tutorials-container');
      const { data } = await api.get('tutorial/list-tutorial');
      const { content } = data;

      setTutorials(content);
    } catch (error) {
      Notiflix.Notify.failure(
        'Não conseguimos buscar pelos tutoriais. Tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-tutorials-container');
    }
  }, []);

  useEffect(() => {
    getTutorials();
  }, [getTutorials]);

  return (
    <PageWrapper gridTemplateRows="auto 1fr">
      <Container>
        <Title themeColor={colors.palet.institutional}>Tutoriais</Title>
        <TutorialsContainer
          className="notiflix-tutorials-container"
          items={tutorials}
        >
          <Column
            field="title"
            header="Título"
            body={(content) => AddField(content.title, 'Título')}
          />
          <Column
            headerStyle={{ width: '150px' }}
            body={(content) => {
              return (
                <Button
                  onClick={() => handleOpenModal(content)}
                  greenPrimary
                  noChangeColor
                  sausageLook
                >
                  Assistir
                </Button>
              );
            }}
          />
        </TutorialsContainer>
      </Container>
    </PageWrapper>
  );
};

export default Tutorials;
