import styled, { css } from 'styled-components';

import { FiPlusCircle as FiPlusCircleSVg } from 'react-icons/fi';

interface ErrorProps {
  error?: boolean;
}

export const Container = styled.div<ErrorProps>`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.palet.institutional12};
  background: ${(props) => props.theme.colors.text.white};
  padding: 8px;
  border-radius: 5px;
  position: relative;
  > input {
    display: none;
  }
  ${(props) =>
    props.error &&
    css`
      border-color: ${props.theme.colors.palet.institutional7};
    `}
`;
export const Content = styled.div`
  display: flex;
  row-gap: 16px;
  column-gap: 16px;
  flex-wrap: wrap;
  transition: 300ms;
`;

export const Placeholder = styled.label`
  font-family: 'Unimed-Sans-Book';
  color: ${(props) => props.theme.colors.palet.institutional11};
  font-size: 1rem;
  width: 100%;
`;

export const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  padding: 16px 0;
  > svg {
    color: ${(props) => props.theme.colors.palet.institutional11};
    &.trash {
      transform: 300ms;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
`;

export const FileTitle = styled.span`
  max-width: 100px;
  text-overflow: ellipsis;
  font-family: 'Unimed-Sans-Book';
  color: ${(props) => props.theme.colors.palet.institutional11};
  font-size: 1rem;
  position: relative;
  &::before {
    opacity: 0;
    visibility: hidden;
    transition: 300ms;
    z-index: 1;
    position: absolute;
    content: attr(aria-valuetext);
    color: #fff;
    background: ${(props) => props.theme.colors.palet.institutional11};
    padding: 4px 8px;
    border-radius: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover::before {
    opacity: 1;
    visibility: visible;
  }
`;

export const FiPlusCircle = styled(FiPlusCircleSVg)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  color: ${(props) => props.theme.colors.text.primary};
  background: ${(props) => props.theme.colors.text.white};
  border-radius: 50%;
  cursor: pointer;
  stroke-width: 1px;
  &[data-error] {
    color: ${(props) => props.theme.colors.palet.institutional7};
    &:hover ~ span {
      transform: translate(-16px, -50%);
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const Error = styled.span`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0px, -50%);
  font-family: 'Unimed-Sans-SemiBold';
  padding: 6px 20px;
  border-radius: 10px;
  font-size: 1rem;
  transition: 300ms;
  background: ${(props) => props.theme.colors.palet.institutional7};
  color: ${(props) => props.theme.colors.text.white};
`;

interface PlaceholderExternalProps {
  themeColor?: string;
}

export const PlaceholderExternal = styled.span<PlaceholderExternalProps>`
  text-align: left;
  font-family: 'Unimed-Sans-Bold';
  font-size: 0.65rem;
  color: ${(props) => props.theme.colors.text.grayishToWhite};

  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(calc(-100% - 6px));
  ${(props) =>
    props.themeColor &&
    props.theme.title === 'light' &&
    css`
      color: ${props.themeColor};
    `}
  ${(props) =>
    props.theme.title === 'dark' &&
    css`
      color: ${props.theme.colors.text.grayishToWhite};
    `}
`;
