import React from 'react';
import { useAuth } from '../../hooks/auth';
import BeneficiaryChangeRegisterDataConsult from './Beneficiary';
import CompanyChangeRegisterDataConsult from './Company';

const ChangeRegisterDataConsult: React.FC = () => {
  const { user } = useAuth();

  if (user.profile === 1) {
    return <BeneficiaryChangeRegisterDataConsult />;
  }
  return <CompanyChangeRegisterDataConsult />;
};

export default ChangeRegisterDataConsult;
