/* eslint-disable no-else-return */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ThemeContext } from 'styled-components';
import Notiflix from 'notiflix';
import { useHistory } from 'react-router-dom';
import ButtonWithOptions from '../../../components/ButtonWithOptions';
import QuickAccess from '../../../components/QuickAccess';
import ContactBlock from '../../../components/ContactBlock';
import {
  Container,
  Carousel,
  ButtonsWithOptionsContainer,
  ReleasesContainer,
  QuickAccessContainer,
  QuickAccessContainer2,
  ContactContainer,
  Title,
  Button,
} from './styles';
import Releases from '../../../components/Releases';
import { BeneficiaryUser, UserCommon, useAuth } from '../../../hooks/auth';
import api from '../../../services/api/api';
import { BannerProps } from '../../../components/Carousel';
import { ObjectGetValueOfKey } from '../../../utils/formatt';
import { VALIDATE_ROUTE as VR } from '../../../routes/validatorRoutes';
import Modal2 from '../../../components/Modal2';

interface RoutesWithTags {
  [key: string]: { url: string; title: string };
}

const gridArea = [
  'carousel carousel carousel',
  'a txt b',
  'releases buttons quick2',
  'releases buttons quick',
  'contact buttons quick',
  'contact buttons quick',
  'contact buttons quick',
  'q q q2',
  's s s',
];

const HomeBeneficiary: React.FC = () => {
  const { isAuthendicated, user } = useAuth();
  const [banners, setBanners] = useState([] as BannerProps[]);
  const { colors } = useContext(ThemeContext);
  const history = useHistory();
  const [guideLink, setGuideLink] = useState('');
  const [showIntervalModal, setShowIntervalModal] = useState(false);
  const [needUpdateUser, setNeedUpdateUser] = useState<boolean | undefined>(
    false,
  );

  const beneficiaryUser = useMemo(
    () => user as UserCommon & BeneficiaryUser,
    [user],
  );

  const visibleRoutes = useCallback(
    (routes: RoutesWithTags, tags: string[]) => {
      return tags.reduce((acc, act) => {
        const found = routes[act];
        if (found) {
          acc.push(found);
        }
        return acc;
      }, [] as { url: string; title: string }[]);
    },
    [],
  );

  const getGuideLink = useCallback(async () => {
    const { data } = await api.get('/gw/translation?key=link-guia-medico');
    setGuideLink(ObjectGetValueOfKey(data.content, 'link-guia-medico'));
  }, []);

  const getBanners = useCallback(async () => {
    try {
      const { data } = await api.get<{ content: BannerProps[] }>(
        '/banner/list-banner',
      );
      const { content } = data;
      const canSee = beneficiaryUser.tags
        .filter((tag) => tag.includes('BN-'))
        .map((tag) => tag.replace('BN-', ''));
      setBanners(
        content.filter((banner) => canSee.includes(String(banner.id))),
      );
    } catch (error) {
      Notiflix.Notify.failure(
        'Não conseguimos carregar os banners do servidor...',
      );
    }
  }, [beneficiaryUser]);

  const setNeedsUpdate = useCallback(() => {
    const needUp = localStorage.getItem('@unimedBlumenauNeedsUpdate');
    const lastLoginUser = localStorage.getItem('@unimedBlumenauLastAccess');

    if (needUp && lastLoginUser) {
      const { needUp: needUpUser, showModal } = JSON.parse(needUp);
      setShowIntervalModal(showModal);
      setNeedUpdateUser(Boolean(needUpUser));

      // convert timestamp
      const lastLoginUserDate = new Date(parseInt(lastLoginUser, 10));
      const currentDate = new Date();

      const differenceInMilliseconds =
        currentDate.getTime() - lastLoginUserDate.getTime();
      const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
    }
  }, [needUpdateUser, showIntervalModal]);

  useEffect(() => {
    if (isAuthendicated) {
      getGuideLink();
      getBanners();
      setNeedsUpdate();
    }
  }, [getBanners, getGuideLink, isAuthendicated, beneficiaryUser]);

  const beneficiaryData = useMemo(() => {
    const routes = {
      [VR.COMPONENTE_CADASTRAL]: {
        url: '/dados-beneficiario-produto',
        title: 'Componente Cadastral',
      },
      [VR.CARENCIA]: { url: '/carencia', title: 'Carência' },
      [VR.COBERTURA_PARCIAL_TEMPORARIA]: {
        url: '/cobertura-parcial-temporaria',
        title: 'Cobertura parcial temporária',
      },
      [VR.EXTRATO_DE_UTILIZACAO]: {
        url: '/extrato-de-utilizacao',
        title: 'Extrato de Utilização',
      },
    } as RoutesWithTags;

    return visibleRoutes(routes, beneficiaryUser.tags);
  }, [beneficiaryUser.tags, visibleRoutes]);

  const solicitations = useMemo(() => {
    const routes = {
      [VR.SOLICITAR_2_VIA_DO_CARTAO]: {
        url: '/solicita-segunda-via-cartao',
        title: 'Solicitar 2ª via do cartão',
      },
      [VR.ALTERAR_DADOS_CADASTRAIS]: {
        url: '/alterar-dados-cadastrais',
        title: 'Alterar dados cadastrais',
      },
      [VR.RESCINDIR_BENEFICIARIO]: {
        url: '/rescindir-beneficiario',
        title: 'Rescindir beneficiário',
      },
      [VR.SOLICITAR_REEMBOLSO]: {
        url: '/reembolso',
        title: 'Solicitar reembolso',
      },
    } as RoutesWithTags;
    return visibleRoutes(routes, beneficiaryUser.tags);
  }, [beneficiaryUser.tags, visibleRoutes]);

  const financial = useMemo(() => {
    const routes = {
      [VR.DEMONSTRATIVOS_E_2_VIA_BOLETO]: {
        url: '/demonstrativos-e-segunda-via-boleto',
        title: 'Demonstrativos e 2ª via boleto',
      },
      [VR.EXTRATO_COPARTICIPACAO]: {
        url: '/extrato-coparticipacao',
        title: 'Extrato coparticipação',
      },
      [VR.DEBITO_EM_CONTA]: {
        url: '/debito-em-conta',
        title: 'Débito em conta',
      },
    } as RoutesWithTags;
    return visibleRoutes(routes, beneficiaryUser.tags);
  }, [beneficiaryUser.tags, visibleRoutes]);

  const authorization = useMemo(() => {
    const routes = {
      [VR.STATUS_DE_AUTORIZACAO]: {
        url: '/autorizacao',
        title: 'Status de Autorização',
      },
      [VR.REQUISITAR_AUTORIZACAO]: {
        url: '/requisitar-autorizacao',
        title: 'Requisitar autorização',
      },
    } as RoutesWithTags;
    return visibleRoutes(routes, beneficiaryUser.tags);
  }, [beneficiaryUser.tags, visibleRoutes]);

  const solicitationStatus = useMemo(() => {
    const routes = {
      [VR.CONSULTAR_2_VIA_DO_CARTAO]: {
        url: '/consulta-segunda-via-cartao',
        title: 'Consultar 2ª via do cartão',
      },
      [VR.CONSULTA_DE_ALTERACOES_CADASTRAIS]: {
        url: '/consulta-alteracoes-cadastrais',
        title: 'Consulta de alterações cadastrais',
      },
      [VR.CONSULTA_RESCISAO_DE_BENEFICIARIO]: {
        url: '/consulta-rescisao',
        title: 'Consulta rescisão de beneficiário',
      },
      [VR.CONSULTA_DE_SOLICITACAO_DE_REEMBOLSO]: {
        url: '/consulta-solicitacao-reembolso',
        title: 'Consulta de solicitação de reembolso',
      },
    } as RoutesWithTags;
    return visibleRoutes(routes, beneficiaryUser.tags);
  }, [beneficiaryUser.tags, visibleRoutes]);

  const AccessRes = useCallback(() => {
    history.push('/res');
  }, []);

  const hasButtonsWithOptions =
    beneficiaryData.length > 0 ||
    solicitations.length > 0 ||
    financial.length > 0 ||
    authorization.length > 0 ||
    solicitationStatus.length > 0;

  return (
    <Container
      gridTemplateAreas={gridArea}
      gridTemplateColumns="3fr 4fr 3fr"
      gridTemplateRows="auto auto auto auto auto auto auto auto 1fr"
    >
      <Carousel autoplayInterval={6000} imgs={banners} />
      <Title>ACESSOS RÁPIDOS</Title>
      {hasButtonsWithOptions && (
        <ButtonsWithOptionsContainer>
          {beneficiaryData.length > 0 && (
            <ButtonWithOptions
              colorBase={colors.palet.institutional10}
              title="Dados Cadastrais"
              detail
              options={beneficiaryData}
            />
          )}
          {solicitations.length > 0 && (
            <ButtonWithOptions
              colorBase={colors.palet.institutional6}
              title="Solicitações"
              options={solicitations}
            />
          )}
          {financial.length > 0 && (
            <ButtonWithOptions
              colorBase={colors.palet.institutional}
              title="Financeiro"
              options={financial}
            />
          )}
          {authorization.length > 0 && (
            <ButtonWithOptions
              colorBase={colors.palet.institutional8}
              title="Autorizações"
              options={authorization}
            />
          )}
          {solicitationStatus.length > 0 && (
            <ButtonWithOptions
              colorBase={colors.palet.institutional9}
              title="Status das Solicitações"
              options={solicitationStatus}
            />
          )}
        </ButtonsWithOptionsContainer>
      )}

      <QuickAccessContainer2>
        <Button className="acesso-res" orangeButton onClick={AccessRes}>
          RES - Meus Dados Médicos
        </Button>
        <Button
          className="guia-medico"
          onClick={() => window.open(`${guideLink}`, '_blank')}
        >
          Guia Médico
        </Button>
      </QuickAccessContainer2>

      <QuickAccessContainer>
        <QuickAccess />
      </QuickAccessContainer>

      <ContactContainer>
        <ContactBlock />
      </ContactContainer>

      {beneficiaryUser.tags.some((tag) => tag === VR.COMUNICADOS) && (
        <ReleasesContainer>
          <Releases />
        </ReleasesContainer>
      )}
      {needUpdateUser &&
        showIntervalModal &&
        Modal2.Warning({
          closable: true,
          title: `Aviso`,
          text: `Olá ${beneficiaryUser.nameLogin}, você precisa atualizar seus dados cadastrais.`,
          children: (
            <Button
              orangeButton
              onClick={() => {
                Modal2.Close();
                history.push('/alterar-dados-cadastrais');
              }}
            >
              Atualizar agora
            </Button>
          ),
        })}
    </Container>
  );
};

export default HomeBeneficiary;
