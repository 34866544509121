import styled from 'styled-components';
import FormWrapper from '../../components/FormWrapper';
import TableComponent from '../../components/Table';

export const Table = styled(TableComponent)`
  grid-column: 2/3;

  @media (max-width: 768px) {
    grid-column: 1;
    margin-bottom: 30px;
  }
`;

export const PartialTitle = styled.h1`
  grid-column: 2/3;
  text-transform: uppercase;
  font-size: 2.55rem;
  line-height: 2.4rem;
  font-family: 'Unimed-Slab-Bold';
  color: ${(props) => props.theme.colors.palet.institutional3};
  text-align: center;
  padding: 30px;

  @media (max-width: 768px) {
    grid-column: 1;
    font-size: 1.7rem;
    line-height: 1.5rem;
  }
`;

export const ContainerTitleIntern = styled(FormWrapper)`
  grid-column: 2/3;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px !important;
  margin-bottom: 24px;
  > button {
    text-transform: uppercase;
  }
  h2 {
    margin: 0;
    font-size: 1.2rem;
    font-family: 'Unimed-Sans-Book';
  }
`;
