import { MutableRefObject } from 'react';

export interface RefundFormItem {
  id: string;
  name: string;
  serviceDate: string;
  careValue: string;
  protocolNumber: string;
  documentType: string;
  formRef: MutableRefObject<null> | null;
  document: {
    documentNumber: string;
    documentDate: string;
    documentLocale: string;
    attachmentList: Array<unknown>;
  };
}

export const refundFormItem: RefundFormItem = {
  id: '1',
  serviceDate: '',
  name: '',
  careValue: '',
  protocolNumber: '',
  documentType: '',
  formRef: null,
  document: {
    documentNumber: '',
    documentDate: '',
    documentLocale: '',
    attachmentList: [],
  },
};

export const initialRefundConsultFormList = [refundFormItem];
