import styled from 'styled-components';
import { ResponsiveContainer, PieChart } from 'recharts';

export const Container = styled.div`
  min-width: 200px;
  margin: 30px;
  position: relative;

  transform: translateY(10%);

  @media (max-width: 768px) {
    margin: 30px 0 0;
  }
`;

export const ChartContainer = styled(ResponsiveContainer)`
  /* padding-top: 50px; */
  .color-referenceline {
    tspan {
      color: green;
    }
  }

  .recharts-surface {
    overflow: visible;
  }

  /* @media (max-width: 768px) { */
  /* height: 200px !important;
    width: 300px !important;
  } */
`;

export const PieChartContainer = styled(PieChart)`
  position: relative;
`;

interface PointerLevelProps {
  pointerValue: number;
  pointerContract: string;
}

export const PointerPie = styled.div<PointerLevelProps>`
  height: 34%;
  background-color: black;
  z-index: 5;
  position: absolute;
  background-color: red;
  background: transparent;
  left: 50%;
  bottom: 50%;
  transform: rotate(${(props) => props.pointerValue}deg) translateX(-50%);
  transform-origin: bottom center;
  width: 1px;
  transition: 300ms;

  svg {
    position: absolute;
    transform: rotate(315deg);
    left: -12px;
  }

  @media (max-width: 768px) {
    height: 30%;
  }
  &::before {
    content: '';
    margin-top: 20px;
    margin-left: 8px;
    position: absolute;
    z-index: -1;
    transform-origin: center;
  }

  h2 {
    position: absolute;
  }
`;

export const ZeroToTwoHundredTitle = styled.div`
  position: absolute;
  font-family: 'Unimed-Sans-Book';
  display: flex;
  justify-content: space-between;

  width: 100%;
  bottom: 27%;
  color: ${(props) => props.theme.colors.text.grayishToWhite};

  @media (max-width: 768px) {
    width: 65%;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (min-width: 450px) and (max-width: 768px) {
    width: 30%;
  }
`;

export const ValuesContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;

export const Title = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-family: 'Unimed-Sans-Book';
  color: ${(props) => props.theme.colors.text.grayishToWhite};
`;
