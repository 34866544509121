import React from 'react';

import { FaAddressCard } from 'react-icons/fa';
import {
  Container,
  ContentContainer,
  IconBackground,
  BookIcon,
  ChecklistIcon,
  OpenBookIcon,
  Content,
  EditIcon,
  BarcodeIcon,
  ListIcon,
  CurativeIcon,
  SchedulingIcon,
} from './styles';
import { BeneficiaryUser, UserCommon, useAuth } from '../../hooks/auth';
import { VALIDATE_ROUTE as VR } from '../../routes/validatorRoutes';
import { CalendarSvg } from '../Svg';
import { SheduleSvg } from '../DatePicker/styles';

const UpdateData = () => (
  <ContentContainer to="/alterar-dados-cadastrais">
    <IconBackground>
      <EditIcon />
    </IconBackground>
    <h2>
      Atualize <br />
      seus dados
    </h2>
  </ContentContainer>
);

const StatetmentAndDuplicateInvoice = () => (
  <ContentContainer to="/demonstrativos-e-segunda-via-boleto">
    <IconBackground>
      <BarcodeIcon />
    </IconBackground>
    <h2>
      2ª Via <br />
      de boleto
    </h2>
  </ContentContainer>
);

const CooparticipationStatement = () => (
  <ContentContainer to="/extrato-coparticipacao">
    <IconBackground>
      <ChecklistIcon />
    </IconBackground>
    <h2>
      Extrato <br /> de Copartipação
    </h2>
  </ContentContainer>
);

const IncomeTax = () => (
  <ContentContainer to="/declaracao-imposto-renda">
    <IconBackground>
      <ListIcon />
    </IconBackground>
    <h2>
      Declaração <br /> de Imposto de Renda
    </h2>
  </ContentContainer>
);

const ListOfProcedures = () => (
  <ContentContainer to="/rol-procedimentos">
    <IconBackground>
      <OpenBookIcon />
    </IconBackground>
    <h2>Rol de Procedimentos</h2>
  </ContentContainer>
);
const CreateSheduling = () => (
  <ContentContainer to="/pesquisa-e-marcacao-de-agenda">
    <IconBackground>
      <SchedulingIcon />
    </IconBackground>
    <h2>Realizar Agendamento</h2>
  </ContentContainer>
);

const Outpatient = () => (
  <ContentContainer to="/ambulatorio-curativos">
    <IconBackground className="iconSvg">
      <CurativeIcon />
    </IconBackground>
    <h2>
      Ambulatório de <br />
      Curativos
    </h2>
  </ContentContainer>
);

const Wallet = () => (
  <ContentContainer to="/carteirinha">
    <IconBackground>
      <FaAddressCard size={30} color="#fff" />
    </IconBackground>
    <h2>
      Carteirinha
      <br /> Virtual
    </h2>
  </ContentContainer>
);

const QuickAccessTags = {
  [VR.ALTERAR_DADOS_CADASTRAIS]: UpdateData,
  [VR.DEMONSTRATIVOS_E_2_VIA_BOLETO]: StatetmentAndDuplicateInvoice,
  [VR.EXTRATO_COPARTICIPACAO]: CooparticipationStatement,
  [VR.DECLARACAO_DE_IMPOSTO_DE_RENDA]: IncomeTax,
  [VR.ROL_DE_PROCEDIMENTOS]: ListOfProcedures,
  [VR.CREATE_SCHEDULING]: CreateSheduling,
  [VR.WALLET]: Wallet,
} as { [s: string]: () => JSX.Element };

const QuickAccess: React.FC = () => {
  const { user: userT } = useAuth();
  const user = userT as UserCommon & BeneficiaryUser;
  return (
    <Container>
      <Content>
        {user.isFirstAid && <Outpatient />}
        {user.tags.map((tag) => {
          const Component = QuickAccessTags[tag];

          if (Component) {
            return <Component key={tag} />;
          }
          return null;
        })}
        <ContentContainer to="/tabela-referencia-unimed">
          <IconBackground>
            <BookIcon />
          </IconBackground>
          <h2>
            Tabela Referência
            <br /> Unimed (T.R.U)
          </h2>
        </ContentContainer>
      </Content>
    </Container>
  );
};

export default QuickAccess;
