import React from 'react';
import { useTheme } from 'styled-components';
import PageWrapper from '../../components/PageWrapper';
import Title from '../../components/Title';
import { Container, Translations } from './styles';

const PrivacyAndCookies: React.FC = () => {
  const { title, colors } = useTheme();
  return (
    <PageWrapper gridTemplateColumns="1fr" gridTemplateRows="1fr">
      <Title
        themeColor={
          title === 'light' ? colors.palet.institutional : colors.text.white
        }
      >
        Privacidade e Cookies
      </Title>
      <Container>
        <Translations tag="privacidade-e-seguranca" />
      </Container>
    </PageWrapper>
  );
};

export default PrivacyAndCookies;
