import styled, { css } from 'styled-components';
import TableComponent from '../../../components/Table';
import FormElement from '../../../components/FormWrapper';

export const Container = styled.section`
  grid-column: 2/3;
  @media (max-width: 768px) {
    grid-column: 1;
    margin-bottom: 30px;
  }
`;

export const Table = styled(TableComponent)`
  margin-top: 24px;
`;

export const FormWrapper = styled(FormElement)`
  grid-column: 2/3;
  grid-row: 2/3;
  display: flex;
  flex-wrap: wrap;
  > div > div > span {
    color: ${(props) => props.theme.colors.text.grayishToWhite};
  }
`;

interface StepContainerProps {
  defineHeight?: string;
}

export const Steps = styled.section<StepContainerProps>`
  position: relative;
  grid-column: 2/3;
  margin-bottom: 30px;
  ${(props) =>
    props.defineHeight &&
    css`
      height: ${props.defineHeight};
    `}
  @media(max-width: 768px) {
    grid-column: 1;
  }
`;

interface StepsProps {
  show: boolean;
}

const initialStateSteps = css`
  left: 0;
  top: 0;
  width: 100%;
  position: absolute;
  transform: translateX(-20px);
  visibility: hidden;
  opacity: 0;
  transition: 300ms;
  height: 0;
  overflow: hidden;
`;
const showSteps = css`
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
  height: initial;
  overflow: initial;
`;

export const Step1 = styled.section<StepsProps>`
  ${initialStateSteps}
  position: initial;
  display: none;

  ${(props) => props.show && showSteps}
  ${(props) =>
    props.show &&
    css`
      display: block;
    `}
`;

export const Step2 = styled.section<StepsProps>`
  ${initialStateSteps}

  ${(props) => props.show && showSteps}

  form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    > div {
      flex: 1;
    }
    > button {
      margin-left: 8px;
    }
    @media (max-width: 768px) {
      > div {
        flex: 1 0 100%;
      }
      > button {
        margin: 8px 0 0 0;
      }
    }
  }
`;
