/* eslint-disable max-len */
import React from 'react';
import { svgProps } from '..';

const BarcodeSvg: React.FC<svgProps> = ({ className }) => {
  return (
    <svg
      width="30"
      height="16"
      viewBox="0 0 30 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 16L30 0L27.8571 -9.36663e-08L27.8571 16L30 16ZM16.0714 14.2222L16.0714 -6.08837e-07L13.9286 -7.02504e-07L13.9286 14.2222L16.0714 14.2222ZM2.1429 -1.21767e-06L2.1429 16L4.5077e-05 16L4.57764e-05 -1.31134e-06L2.1429 -1.21767e-06ZM19.2371 12L19.2371 -4.7046e-07L18.1657 -5.17293e-07L18.1657 12L19.2371 12ZM22.4514 -3.2996e-07L22.4514 12L21.38 12L21.38 -3.76793e-07L22.4514 -3.2996e-07ZM25.6657 12L25.6657 -1.89457e-07L24.5943 -2.36291e-07L24.5943 12L25.6657 12ZM8.54717 -9.37732e-07L8.54717 12L7.47575 12L7.47575 -9.84565e-07L8.54717 -9.37732e-07ZM11.7371 12L11.7371 -7.98295e-07L10.6657 -8.45128e-07L10.6657 12L11.7371 12ZM5.33285 -1.07823e-06L5.33285 12L4.26142 12L4.26142 -1.12507e-06L5.33285 -1.07823e-06Z"
        fill="white"
      />
    </svg>
  );
};

export default BarcodeSvg;
