/* eslint-disable no-constant-condition */
/* eslint-disable no-cond-assign */
/* eslint-disable react/no-children-prop */
import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import PageWrapper from '../../../components/PageWrapper';
import api from '../../../services/api/api';
import { Title, TitleIntern, Form } from './styles';
import getValidationErrors from '../../../utils/getValidationErrors';
import Modal2 from '../../../components/Modal2';
import Button from '../../../components/Button';
import { useContract } from '../../../hooks/contract';
import Input from '../../../components/Input';
import { EnterpriseUser, UserCommon, useAuth } from '../../../hooks/auth';
import Select, { SelectHandles } from '../../../components/Select';
import { InputFile } from '../../RequestAuthorization/styles';
import { InputFileHandles } from '../../../components/InputFile';

const CompanyChangeRegisterData: React.FC = () => {
  const { contract } = useContract();
  const { user: userT } = useAuth();
  const user = userT as UserCommon & EnterpriseUser;
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const stateRef = useRef<SelectHandles>(null);
  const countyRef = useRef<SelectHandles>(null);
  const attachmentRef = useRef<InputFileHandles[]>([]);

  const [initialValue, setInitialValue] = useState({
    dsRazaoSocial: '',
    email: '',
    phone: '',
    dddTelefone: '',
    cdCEP: '',
    neighborhood: '',
    address: '',
    addressNumber: '',
    complement: '',
    dsEstado: '',
    city: '',
  });

  const [stateOptions, setStateOptions] = useState([
    { title: 'Estado', value: '' },
  ]);
  const [cityOptions, setCityOptions] = useState([
    { title: 'Município', value: '' },
  ]);

  const handleFormSubmit = useCallback(
    async (data) => {
      try {
        Notiflix.Block.circle('.notiflix-send');
        const shape = Yup.object().shape({
          socialReason: Yup.string().required('Informe sua Razão Social'),
          email: Yup.string().required('Informe seu email'),
          // .email('Informe um email válido'),
          cepCode: Yup.number()
            .typeError('Informe seu CEP')
            .min(100000, 'Informe um CEP válido'),
          neighborhood: Yup.string().required('Informe seu bairro'),
          address: Yup.string().required('Informe seu endereço'),
          addressNumber: Yup.string().required(
            'Informe o número do seu endereço',
          ),
        });
        stateRef.current?.setError(
          !stateRef.current.value ? 'Informe seu Estado' : '',
        );
        countyRef.current?.setError(
          !countyRef.current.value ? 'Informe sua cidade' : '',
        );

        if (
          formRef.current?.getFieldValue('socialReason') !==
            initialValue.dsRazaoSocial ||
          formRef.current?.getFieldValue('address') !== initialValue.address ||
          formRef.current?.getFieldValue('addressComplement') !==
            initialValue.complement ||
          formRef.current?.getFieldValue('cepCode') !== initialValue.cdCEP ||
          formRef.current?.getFieldValue('neighborhood') !==
            initialValue.neighborhood ||
          formRef.current?.getFieldValue('addressNumber') !==
            initialValue.addressNumber ||
          stateRef.current?.value !== initialValue.dsEstado ||
          countyRef.current?.value !== initialValue.city
        ) {
          if (!attachmentRef.current[0]?.files) {
            attachmentRef.current[0]?.setError(
              'Ao realizar uma alteração é necessário incluir um anexo.',
            );
            return;
          }
        }
        await shape.validate(data, {
          abortEarly: false,
        });
        if (
          !stateRef.current?.value ||
          !countyRef.current?.value
          // ||
          // !attachmentRef.current?.files
        )
          return;

        const {
          socialReason,
          address,
          addressNumber,
          addressComplement,
          neighborhood,
          cepCode,
          tell,
          email,
        } = data;

        const { webUsername } = user;

        const DDDNumber = tell.slice(0, 2);
        const phone = tell.slice(2);

        const formData = new FormData();

        formData.append('corporateName', socialReason);
        formData.append('address', address);
        formData.append('addressNumber', addressNumber);
        formData.append('webUsername', webUsername);
        formData.append('addressComplement', addressComplement);
        formData.append('neighborhood', neighborhood);
        formData.append('cityName', countyRef.current?.value);
        formData.append('state', stateRef.current?.value);
        formData.append('zipCode', cepCode);
        formData.append('DDDNumber', DDDNumber);
        formData.append('phone', phone);
        formData.append('email', email);

        if (attachmentRef?.current[0]?.files) {
          formData.append('files[0]', attachmentRef?.current[0]?.files[0]);
        }

        await api.post('/company/register-change-request', formData);

        Modal2.Success({
          closable: true,
          title: 'Perfeito!',
          subtitle: `Contrato: ${contract.contractId}`,
          text: 'Seus dados foram enviados para a alteração.',
          children: (
            <Button
              onClick={() => {
                Modal2.Close();
                history.push('/');
              }}
              modal
            >
              Ok
            </Button>
          ),
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response?.data?.message) {
          Notiflix.Notify.failure(err.response.data.message);
        } else {
          Notiflix.Notify.failure(
            'Ops... não conseguimos nos conectar ao servidor...',
          );
        }
      } finally {
        Notiflix.Block.remove('.notiflix-send');
      }
    },
    [contract.contractId, history, user, initialValue],
  );

  const handleChangeSelectedState = useCallback(async (option) => {
    try {
      Notiflix.Block.circle('.notiflix-county');
      const { data: dataCity } = await api.get(
        `/address/list-of-cities?state=${option}`,
      );
      const { content: contentCity } = dataCity;

      setCityOptions(
        contentCity.reduce(
          (
            acc: { title: string; value: string }[],
            act: { city: string; ibgeCityCode: string },
          ) => {
            acc.push({
              title: act.city,
              value: act.city,
            });
            return acc;
          },
          [],
        ),
      );
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops, não conseguimos carregar os municípios do estado selecionado. Tente novamente.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-county');
    }
  }, []);

  const getInitialData = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-form');
      const [respData, respStates] = await Promise.all([
        api.get('/company/data-to-change-fields'),
        api.get('/address/list-of-states'),
      ]);
      const {
        dsRazaoSocial,
        email,
        phone,
        dddTelefone,
        cdCEP,
        neighborhood,
        address,
        addressNumber,
        complement,
        dsEstado,
        city,
      } = respData.data.content[0];

      setInitialValue({
        dsRazaoSocial: respData.data.content[0].dsRazaoSocial,
        email: respData.data.content[0].email,
        phone: respData.data.content[0].phone,
        dddTelefone: respData.data.content[0].dddTelefone,
        cdCEP: respData.data.content[0].cdCEP,
        neighborhood: respData.data.content[0].neighborhood,
        address: respData.data.content[0].address,
        addressNumber: respData.data.content[0].addressNumber,
        complement: respData.data.content[0].complement || '',
        dsEstado: respData.data.content[0].dsEstado,
        city: respData.data.content[0].city,
      });

      formRef.current?.setData({
        socialReason: dsRazaoSocial,
        email: email ?? '',
        tell: `${dddTelefone}${phone}`,
        cepCode: cdCEP ?? '',
        neighborhood: neighborhood ?? '',
        address: address ?? '',
        addressNumber: addressNumber ?? '',
        addressComplement: complement ?? '',
      });

      const { content: contentStates } = respStates.data;
      setStateOptions(
        contentStates.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      );
      stateRef.current?.setValue('', dsEstado);

      const { data: dataCity } = await api.get(
        `/address/list-of-cities?state=${dsEstado}`,
      );
      const { content: contentCity } = dataCity;

      setCityOptions(
        contentCity.reduce(
          (
            acc: { title: string; value: string }[],
            act: { city: string; ibgeCityCode: string },
          ) => {
            acc.push({
              title: act.city,
              value: act.city,
            });
            return acc;
          },
          [],
        ),
      );
      countyRef.current?.setValue(city);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar suas informações de cadastro no momento. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-form');
    }
  }, []);

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper
      gridTemplateColumns="1fr 8fr 1fr"
      gridTemplateRows="auto auto auto 1fr"
    >
      <Title>Alterar Dados Cadastrais</Title>

      <TitleIntern>Contrato: {contract.contractId}</TitleIntern>
      <Form
        ref={formRef}
        onSubmit={handleFormSubmit}
        className="notiflix-form"
        formLook
      >
        <Input
          formLook
          name="socialReason"
          title="Razão Social:"
          widthContainerDesktop="60%"
        />
        <Input
          formLook
          name="email"
          title="E-mail:"
          widthContainerDesktop="40%"
        />
        <Input
          formLook
          name="tell"
          title="DDD/Telefone:"
          masks={['(99) 9999-9999', '(99) 9 9999-9999']}
          widthContainerDesktop="25%"
        />
        <Input
          formLook
          name="cepCode"
          title="CEP:"
          widthContainerDesktop="25%"
        />

        <Select
          name="state"
          title="Estado:"
          formLook
          liveReload
          ref={stateRef}
          options={stateOptions}
          changeCallback={handleChangeSelectedState}
          widthContainerDesktop="25%"
        />
        <Select
          name="county"
          title="Município:"
          formLook
          liveReload
          inputMode
          ref={countyRef}
          options={cityOptions}
          className="notiflix-county"
          widthContainerDesktop="25%"
        />
        <Input
          formLook
          name="neighborhood"
          title="Bairro:"
          widthContainerDesktop="30%"
        />
        <Input
          formLook
          name="address"
          title="Endereço:"
          widthContainerDesktop="30%"
        />
        <Input
          formLook
          name="addressNumber"
          title="Nº:"
          type="number"
          widthContainerDesktop="10%"
        />
        <Input
          formLook
          name="addressComplement"
          title="Complemento:"
          widthContainerDesktop="30%"
        />
        <InputFile
          // themeColor={defaultColor}
          name="attachments"
          // ref={attachmentRef}
          ref={(el) => {
            if (el) {
              attachmentRef.current[0] = el;
            }
          }}
          placeholder="Anexos"
        />
        <Button onClick={() => history.goBack()} secondary autoWidth formLook>
          Voltar
        </Button>
        <Button
          type="submit"
          grayButton
          autoWidth
          formLook
          className="notiflix-send"
        >
          Salvar
        </Button>
      </Form>
    </PageWrapper>
  );
};

export default CompanyChangeRegisterData;
