/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import { useTheme } from 'styled-components';
import Button from '../../components/Button';
import Input from '../../components/Input';
import PageWrapper from '../../components/PageWrapper';
import api from '../../services/api/api';
import {
  Container,
  NoContent,
  FaqTitle,
  Dropdown,
  Form,
  FaqBody,
  DocumentsContainer,
} from './styles';

import Title from '../../components/Title';

interface FaqProps {
  answer: string;
  id: string;
  question: string;
}

const Faq: React.FC = () => {
  const [faq, setFaq] = useState([] as FaqProps[]);
  const [filter, setFilter] = useState('');
  const formRef = useRef<FormHandles>(null);
  const { colors } = useTheme();

  const getFaq = useCallback(async () => {
    try {
      const { data } = await api.get('/faq/list-faq');
      const { content } = data;
      if (content) {
        setFaq(content);
      }
    } catch (err) {
      if (err?.response?.message) {
        Notiflix.Notify.info(err.response.message);
      } else {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os FAQs do servidor. Tente novamente mais tarde',
        );
      }
    }
  }, []);

  const faqs = useMemo(() => {
    const filtered = faq.filter(
      (item) =>
        item.answer.toLowerCase().includes(filter.toLowerCase()) ||
        item.question.toLowerCase().includes(filter.toLowerCase()),
    );
    if (!filtered || filtered.length === 0) {
      return <NoContent>Não encontramos nenhum item.</NoContent>;
    }
    return (
      <>
        {filtered.map((faqItem) => {
          return (
            <Dropdown
              key={faqItem.id}
              titleJSX={
                <FaqTitle>
                  <h2>{faqItem.question}</h2>
                </FaqTitle>
              }
              body={<FaqBody>{faqItem.answer}</FaqBody>}
            />
          );
        })}
      </>
    );
  }, [faq, filter]);

  useEffect(() => {
    getFaq();
  }, [getFaq]);

  const handleFilter = useCallback(async (data) => {
    formRef.current?.setErrors({});
    if (data.filters === '') {
      setFilter('');
    } else {
      setFilter(data.filters);
    }
  }, []);

  return (
    <PageWrapper gridTemplateRows="auto auto 1fr">
      <Container>
        <Title themeColor={colors.palet.institutional}>FAQ</Title>
        <Form ref={formRef} formLook onSubmit={handleFilter}>
          <Input
            title="Procure por um FAQ:"
            name="filters"
            formLook
            themeColor={colors.palet.institutional}
          />
          <Button greenPrimary formLook float="right" type="submit">
            Filtrar
          </Button>
        </Form>
        <DocumentsContainer className="notiflix-manuals-conteiner">
          {faqs}
        </DocumentsContainer>
      </Container>
    </PageWrapper>
  );
};

export default Faq;
