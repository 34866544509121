import React, { useCallback, useEffect, useRef, useState } from 'react';
import Notiflix from 'notiflix';

import Button from '../../components/Button';
import Modal2 from '../../components/Modal2';
import PageWrapper from '../../components/PageWrapper';
import { Column } from '../../components/Table';
import { BeneficiaryUser, UserCommon, useAuth } from '../../hooks/auth';
import { Term } from '../../hooks/releasesAndTerms';
import api from '../../services/api/api';
import AddField, { getTag } from '../../utils/tableUtils';
import {
  Title,
  Table,
  ModalTitle,
  ModalContent,
  ModalContainer,
  ContainerCheck,
  ContentCheck,
  ButtonScroll,
} from './styles';
import imgScroll from '../../assets/images/scrollToBottom.png';

const Terms: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [terms, setTerms] = useState([] as Term[]);
  const { user: userT } = useAuth();
  const user = userT as UserCommon & BeneficiaryUser;
  const ACCEPTED = 'C';
  const REVOGUED = 'R';

  const getTerms = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');
      const { data } = await api.get(
        `/beneficiary/list-acceptance-terms?userUnimedId=${user.userUnimedId}`,
      );
      const content = data.content as Term[];
      setTerms(content);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops.. Não conseguimos buscar os termos no momento. Tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, [setTerms, user]);

  const handleRejectTerm = useCallback(
    async (term: Term) => {
      Modal2.Close();
      try {
        Notiflix.Loading.circle();
        await api.put(`/beneficiary/agreement-acceptance-terms`, {
          termUserUnimedId: term.termUserUnimedId,
          acceptanceTermId: term.acceptanceTermId,
          registerAcceptanceTermId: term.registerAcceptanceTermId ?? '',
          status: REVOGUED,
        });
        getTerms();
        // setTerms((prev) =>
        //   produce(prev, (draft) => {
        //     const changedItem = draft.find(
        //       (item) => item.acceptanceTermId === term.acceptanceTermId,
        //     );
        //     if (changedItem) {
        //       changedItem.revogationDate = dateToPTBR(new Date());
        //       changedItem.initialsSituation = 'Revogado';
        //     }
        //   }),
        // );
      } catch (err) {
        if (err.response?.data?.message) {
          Notiflix.Notify.failure(err.response.data.message);
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos recusar esse termo no momento. Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Loading.remove();
      }
    },
    [getTerms],
  );

  const handleAcceptTerm = useCallback(
    async (term: Term) => {
      Modal2.Close();
      try {
        Notiflix.Loading.circle();
        await api.put(`/beneficiary/agreement-acceptance-terms`, {
          termUserUnimedId: term.termUserUnimedId,
          acceptanceTermId: term.acceptanceTermId,
          registerAcceptanceTermId: term.registerAcceptanceTermId,
          status: ACCEPTED,
        });
        getTerms();
        // setTerms((prev) =>
        //   produce(prev, (draft) => {
        //     const changedItem = draft.find(
        //       (item) => item.acceptanceTermId === term.acceptanceTermId,
        //     );
        //     if (changedItem) {
        //       changedItem.confirmationDate = dateToPTBR(new Date());
        //       changedItem.initialsSituation = 'Aceito';
        //     }
        //   }),
        // );
      } catch (err) {
        if (err.response?.data?.message) {
          Notiflix.Notify.failure(err.response.data.message);
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos aceitar esse termo no momento. Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Loading.remove();
      }
    },
    [getTerms],
  );

  const ativar = useCallback(() => {
    const button = document.querySelector('#acceptTerms');
    button?.toggleAttribute('disabled');
  }, []);

  const scrollToBottom = useCallback(() => {
    inputRef.current?.scrollIntoView({ behavior: 'smooth' });
    // if (inputRef.current) {
    //   inputRef.current.checked = true;
    // }

    // ativar();
    const button = document.querySelector('#scrollToBottom');
    if (button) {
      button.setAttribute('disabled', '');
      button.classList.add('removeDisplay');
    }
  }, []);

  const handleRowClicked = useCallback(
    async (event) => {
      const term: Term = event.data;
      // if (term.changeable === 'N') {
      //   Notiflix.Notify.info('Esse termo não pode ser alterado.');
      //   return;
      // }

      // remove tags img que estão quebrando o layout do termo RES
      setTimeout(() => {
        const imgElement1 = document.querySelector(
          'img[alt="Narrativa estrutura de tópicos"]',
        );
        const imgElement2 = document.querySelector(
          'img[alt="Interface do Usuário/Experiência do Usuário estrutura de tópicos"]',
        );
        const imgElement3 = document.querySelector(
          'img[alt="Rede social estrutura de tópicos"]',
        );
        const imgElement4 = document.querySelector(
          'img[alt="Nenhum sinal com preenchimento sólido"]',
        );
        const imgElement5 = document.querySelector(
          'img[alt="Relógio estrutura de tópicos"]',
        );

        if (imgElement1) {
          imgElement1.classList.add('removeDisplay');
        }
        if (imgElement2) {
          imgElement2.classList.add('removeDisplay');
        }
        if (imgElement3) {
          imgElement3.classList.add('removeDisplay');
        }
        if (imgElement4) {
          imgElement4.classList.add('removeDisplay');
        }
        if (imgElement5) {
          imgElement5.classList.add('removeDisplay');
        }
      }, 200);
      try {
        Notiflix.Loading.circle();
        Modal2.Generic({
          closable: true,
          children: (
            <ModalContainer>
              <ModalTitle
                dangerouslySetInnerHTML={{ __html: term.acceptanceTermTitle }}
              />
              <ModalContent>
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: term.acceptanceTerm }}
                />
                <br />
                <ContainerCheck>
                  <ContentCheck>
                    <input
                      type="checkbox"
                      ref={inputRef}
                      name="concordo"
                      id="checkboxTerms"
                      onChange={() => ativar()}
                    />
                  </ContentCheck>
                  <span>Li e concordo</span>
                </ContainerCheck>
                {term.changeable !== 'N' && (
                  <>
                    <Button
                      modal
                      id="acceptTerms"
                      greenCriticalButton
                      float="right"
                      autoWidth
                      onClick={() => handleAcceptTerm(term)}
                    >
                      Aceitar
                    </Button>
                    {term.required !== 'S' && (
                      <Button
                        modal
                        float="right"
                        autoWidth
                        secondary
                        onClick={() => handleRejectTerm(term)}
                      >
                        Recusar
                      </Button>
                    )}
                  </>
                )}
              </ModalContent>
              <ButtonScroll
                type="button"
                onClick={scrollToBottom}
                id="scrollToBottom"
              >
                <img src={imgScroll} alt="" />
              </ButtonScroll>
            </ModalContainer>
          ),
        });
        const btn = document.querySelector<HTMLButtonElement>('#acceptTerms');
        if (btn) {
          btn.disabled = true;
        }
      } catch (err) {
        Notiflix.Notify.failure(
          'Ops, não conseguimos exibir esse termo no momento... Tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Loading.remove();
      }
    },
    [ativar, handleAcceptTerm, handleRejectTerm, scrollToBottom],
  );

  const statusTemplate = useCallback((status) => {
    switch (status) {
      case 'Aceito':
        return getTag('Status', 'Aceito', 'green', 'Aceito');
      case 'Aguardando ação':
        return getTag('Status', 'Aguard. Ação', 'orange', 'Aguardando Ação');
      case 'Revogado':
        return getTag('Status', 'Revogado', 'red', 'Revogado');
      default:
        return <>{status}</>;
    }
  }, []);

  useEffect(() => {
    getTerms();
  }, [getTerms]);

  return (
    <PageWrapper gridTemplateRows="auto auto auto 1fr">
      <Title>Termos</Title>
      <Table
        items={terms}
        key="termos"
        columnResizeMode="fit"
        className="notiflix-table-loading"
        onRowClick={handleRowClicked}
      >
        <Column
          field="acceptanceTermTitle"
          header="Termo"
          sortable
          body={(data: Term) => AddField(data.acceptanceTermTitle, 'Termo')}
          bodyClassName="clicable"
        />
        <Column
          field="insuredName"
          header="Beneficiário"
          sortable
          body={(data: Term) => AddField(data.insuredName, 'Beneficiário')}
          bodyClassName="clicable"
        />
        <Column
          field="confirmationDate"
          header="Data de Confirmação"
          className="date"
          body={(data: Term) =>
            AddField(data.confirmationDate ?? '', 'Data de Confirmação')
          }
          bodyClassName="clicable"
        />
        <Column
          field="revogationDate"
          header="Data de Revogação"
          className="date"
          body={(data: Term) =>
            AddField(data.revogationDate ?? '', 'Data de Revogação')
          }
          bodyClassName="clicable"
        />
        <Column
          field="initialsSituation"
          header="Status"
          headerStyle={{ textAlign: 'center' }}
          sortable
          body={
            (data: Term) => statusTemplate(data.initialsSituation)
            // AddField(data.pending ? 'Pendente' : 'Concluído', 'Status')
          }
          bodyClassName="clicable"
        />
      </Table>
    </PageWrapper>
  );
};

export default Terms;
