import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { RowProps } from '.';
import PageWrapper from '../../components/PageWrapper';
import Title from '../../components/Title';
import { TerminateBeneficiary } from './TerminateBeneficiary';
import { Container } from './styles';

const CallTerminate: React.FC = () => {
  const { params } = useParams<{ params: string }>();
  const [externalParams] = useState(() => {
    if (params) {
      return {
        requestId: String(params).split('/')[0],
        userUnimedId: String(params).split('/')[1],
      };
    }
    return {};
  });
  const { colors } = useTheme();
  const defaultColor = colors.palet.institutional3;
  const history = useHistory();

  const [actualStep, setActualStep] = useState(3);
  const [rowsSelecteds] = useState([] as RowProps[]);

  useEffect(() => {
    if (actualStep !== 3) {
      history.push('/beneficiarios');
    }
  }, [actualStep, history]);

  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <Container>
        <Title themeColor={defaultColor}>Rescindir beneficiário</Title>
        <TerminateBeneficiary
          defaultColor={defaultColor}
          callbackSetStep={setActualStep}
          rowsSelecteds={rowsSelecteds}
          onEdit
          externalParams={externalParams}
        />
      </Container>
    </PageWrapper>
  );
};

export default CallTerminate;
