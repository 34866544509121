import styled from 'styled-components';
import FormWrapperElement from '../../components/FormWrapper';

export const Container = styled.div`
  margin-bottom: 30px;

  @media (min-width: 769px) and (max-width: 1199px) {
    grid-column: 1/11;
    padding: 0 30px;
  }
  @media (min-width: 1200px) {
    grid-column: 2/10;
  }

  > form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    h2 {
      font-family: 'Unimed-Sans-SemiBold';
      font-size: 1.6rem;
      line-height: 1.6rem;
      width: 100%;

      text-align: center;
      letter-spacing: -1.19px;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.palet.institutional};
      margin-bottom: 8px;
      @media (max-width: 768px) {
        font-family: 'Unimed-Sans-Bold';
        font-size: 1rem;
        line-height: 1rem;
        text-align: center;
        margin-bottom: 30px;
      }
    }
    div.normalize-text {
      width: 100%;
      font-family: 'Unimed-Sans-Book';
      font-size: 1rem;
      line-height: 1rem;
      color: ${(props) => props.theme.colors.text.grayishToWhite};
      p,
      span {
        font-family: 'Unimed-Sans-Book';
        font-size: 1rem;
        line-height: 1rem;
        color: ${(props) => props.theme.colors.text.grayishToWhite};
      }
    }

    > button {
      text-transform: uppercase;
    }
  }
  @media (max-width: 768px) {
    > form {
      > button {
        width: 100%;
      }
    }
  }
`;

export const ContainerImport = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: 200px;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin: 0 20px 10px 20px;
  }
`;

export const ContainerImportButton = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-end;
  justify-content: flex-end;
  gap: 10px;
  height: 13vh;
  margin-top: 30px;
  &.mt-40 {
    margin-bottom: 40px;
  }
`;

export const FormWrapper = styled(FormWrapperElement)`
  grid-column: 2;
  @media (max-width: 768px) {
    grid-column: 1;
  },
  /* &.mt-40 {
    margin-top: 40px;
  }
  &.mb-40 {
    margin-bottom: 40px;
  } */
`;
