/* eslint-disable react/no-this-in-sfc */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';
import {
  Paginator,
  PaginatorPageState,
} from 'primereact/components/paginator/Paginator';
import { useLocation } from 'react-router-dom';
import Button from '../../../components/Button';
import DatePickerUnform from '../../../components/DatePickerUnform';
import FormWrapper from '../../../components/FormWrapper';
import PageWrapper from '../../../components/PageWrapper';
import SelectUnform from '../../../components/SelectUnform';
import { SheduleSvg } from '../../../components/Svg';
import Table, { Column } from '../../../components/Table';
import Title from '../../../components/Title';
import { personalReducerForSelectCities } from '../../../utils/formatt';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Input } from '../../BeneficiaryAndProductDatas/styles';
import * as Helper from '../helpers';
import {
  IBeneficiariesOfAnInsured,
  IGetUserTimetableRequestParams,
  IPagination,
  IScheduleType,
  ISpecialty,
  IUserTimeTable,
} from '../types';
import * as S from './styles';
import {
  loadingDataFilterLocalStorage,
  saveFilterLocalStorage,
} from '../../../utils/localStorageConsulta';
import { TableFooter } from '../../../components/Table/styles';

const AppointmentSchedulingPage: React.FC = () => {
  const { colors } = useTheme();

  const formRef = useRef<FormHandles>(null);

  const beneficiariesRef = useRef<IBeneficiariesOfAnInsured[]>([]);
  const [isElective, setIsElective] = useState(false);
  const [timeTables, setTimeTables] = useState<
    { nextPageId: string | null | undefined; data: IUserTimeTable[] }[]
  >([]);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [selectedBeneficiary, setSelectedBeneficiary] =
    useState<IBeneficiariesOfAnInsured>();
  const [showCityOnline, setShowCityOnline] = useState(true);

  const [filterValues, setFilterValues] = useState({
    doctor: '',
    startDate: '',
    endDate: '',
    speciality: '',
    city: '',
    typeId: '',
  });
  const location = useLocation();
  const tableResponseView = document.getElementById('tableResponse');

  const handleOnLoadBeneficiariesList = useCallback(
    (data: IBeneficiariesOfAnInsured[]) => {
      beneficiariesRef.current = data;
    },
    [],
  );

  const handleOnLoadScheduleTypeList = useCallback((data: IScheduleType[]) => {
    if (data) {
      window.dispatchEvent(
        new CustomEvent('onLoadScheduleTypeList', { detail: data }),
      );
      //   formRef.current?.setFieldValue('typeId', data[0].typeId.toString());
    } else {
      window.dispatchEvent(
        new CustomEvent('onLoadScheduleTypeList', { detail: [] }),
      );
    }
  }, []);

  const handleReduceBeneficiaryOptions = useCallback(
    (result: IBeneficiariesOfAnInsured[]) =>
      result.map((item) => ({
        name: item.name,
        id: item.userUnimedId.toString(),
      })),
    [],
  );

  const handleReduceScheduleTypeOptions = useCallback(
    (result: IScheduleType[]) =>
      result.map((item) => {
        return {
          name: item.typeDescription,
          id: item.typeId.toString(),
        };
      }),
    [],
  );

  const handleReduceSpecialtiesOptions = useCallback((result: ISpecialty[]) => {
    const blankOption = { name: '', id: '' };
    return [
      blankOption,
      ...result.map((item) => ({
        name: item.doctorSpecialtie,
        id: item.id.toString(),
      })),
    ];
  }, []);

  const handleChangeScheduleTypeCallback = useCallback(
    (name: string, id: string) => {
      const result = id === '3' || id === '4';
      setIsElective(result);
      setCanSubmit(true);
      if (id === '3') {
        setShowCityOnline(true);
      }
      if (id === '4') {
        setShowCityOnline(false);
      }
    },
    [],
  );

  const handleChangeBeneficiaryCallback = useCallback(
    (name: string, id: string) => {
      const beneficiary = beneficiariesRef.current.find(
        (item) => item.userUnimedId.toString() === id,
      );

      setSelectedBeneficiary(beneficiary);
      /**
       * when changing the beneficiary, the select with the name 'typeId' will be updated
       * After loading the list, if you have not defined the value then it will default to the first option
       */
      window.addEventListener(
        'onLoadScheduleTypeList',
        (event) => {
          try {
            const data = (event as CustomEvent).detail;
            if (!formRef.current?.getFieldValue('typeId')) {
              formRef.current?.setFieldValue(
                'typeId',
                data[0].typeId.toString(),
              );
            }
          } catch (err) {
            //
          }
        },
        { once: true },
      );
    },
    [],
  );

  const getAddressLine = useCallback((timetable: IUserTimeTable) => {
    const { houseNumber, streetDescription, district, zipCode, city } =
      timetable;

    if (houseNumber && streetDescription && district && zipCode && city) {
      return `${timetable.houseNumber} ${timetable.streetDescription}, ${timetable.district}, ${timetable.zipCode} - ${timetable.city}`;
    }
    return timetable.doctorOffice;
  }, []);

  const fetchNextPage = useCallback(async (pageId: string) => {
    try {
      Notiflix.Block.circle('.notiflix-next-page');
      const data = formRef.current?.getData();
      const parsedData: Partial<IGetUserTimetableRequestParams> = {
        userUnimedId: data?.userUnimedId,
        typeId: data?.typeId,
        ...(data?.typeId && {
          city: data?.city,
          doctorName: data?.doctorName ?? '',
          startDate: data?.startDate,
          endDate: data?.endDate,
          specialty: data?.specialty,
        }),
        IdNextPage: pageId,
      };

      const result = await Helper.getUserTimeTable(parsedData);
      const dataTable = {
        data: result.data,
        nextPageId: result.idNextPage,
      };
      setTimeTables((prev) => [...prev, dataTable]);
    } catch (err) {
      console.log(err);
    } finally {
      Notiflix.Block.remove('.notiflix-next-page');
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const data = formRef.current?.getData();
      formRef.current?.setErrors({});

      const schema = Yup.object().shape<NonNullable<typeof data>>({
        specialty: Yup.string().test(
          'required',
          'Selecione uma especialidade ou informe o nome do médico e tente novamente',
          (value, context) => {
            return !!value || !!context.parent?.doctorName;
          },
        ),
        doctorName: Yup.string().test(
          'required',
          'Selecione uma especialidade ou informe o nome do médico e tente novamente',
          (value, context) => {
            return !!value || !!context.parent?.specialty;
          },
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      Notiflix.Block.circle('.notiflix-form-loading');
      const parsedData: Partial<IGetUserTimetableRequestParams> = {
        userUnimedId: data?.userUnimedId,
        typeId: data?.typeId,
        ...(data?.typeId && {
          city: data?.city,
          doctorName: data?.doctorName ?? '',
          startDate: data?.startDate,
          endDate: data?.endDate,
          specialty: data?.specialty,
        }),
      };

      const result = await Helper.getUserTimeTable(parsedData);
      const dataTable = {
        data: result.data,
        nextPageId: result.idNextPage,
      };
      setTimeTables([dataTable]);

      setIsElective(false);
    } catch (error) {
      console.log(error);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
      }
      // console.log("error", error.response);
      if (error?.response?.data.message) {
        Notiflix.Notify.failure(error?.response?.data.message);
      }
      if (error?.response?.status !== 404) {
        Notiflix.Notify.failure('Nenhum dado encontrado para esta busca');
      }

      setTimeTables([]);
    } finally {
      Notiflix.Block.remove('.notiflix-form-loading');
      if (tableResponseView) {
        scrollIntoView(tableResponseView, {
          behavior: 'smooth',
          scrollMode: 'if-needed',
        });
      }
    }
  }, [tableResponseView]);

  const handleShowSearch = useCallback(() => {
    setIsElective((prev) => !prev);
  }, []);

  const handleSearchClick = useCallback(() => {
    const filterToSave = formRef.current?.getData();
    if (filterToSave) {
      saveFilterLocalStorage(filterToSave);
    }
    handleSubmit();
  }, [handleSubmit]);

  const tableItems = useMemo(
    () => timeTables.map((item) => item.data).flat(),
    [timeTables],
  );

  const lastPage = useMemo(() => {
    return timeTables[timeTables.length - 1]?.nextPageId;
  }, [timeTables]);

  useEffect(() => {
    const formattedData = loadingDataFilterLocalStorage();
    if (Object.values(formattedData).length > 0) {
      if (formattedData.typeId) {
        /**
         * when this select is ready, it will set the value
         */
        window.addEventListener(
          'onLoadScheduleTypeList',
          () => {
            formRef.current?.setFieldValue('typeId', formattedData.typeId);
          },
          { once: true },
        );
      }
      setFilterValues(formattedData);
      formRef.current?.setData(formattedData);
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formRef.current?.setErrors({});
    setFilterValues((prev) => ({
      ...prev,
      doctor: e.target.value,
    }));
  };

  return (
    <PageWrapper
      gridTemplateColumns="repeat(10,1fr)"
      gridTemplateRows="auto auto 1fr"
    >
      <S.Container>
        <Title themeColor={colors.palet.institutional}>
          Realizar agendamento
        </Title>

        <S.Wrapper className="notiflix-form-loading">
          <FormWrapper
            formLook
            ref={formRef}
            onSubmit={() => {
              //
            }}
          >
            <S.FormContainer>
              <SelectUnform
                title="Beneficiário"
                name="userUnimedId"
                apiSearch="/appointment-schedule/beneficiaries"
                personalReducer={handleReduceBeneficiaryOptions}
                callbackOnLoad={handleOnLoadBeneficiariesList}
                callbackOnChange={handleChangeBeneficiaryCallback}
                formLook
              />

              <SelectUnform
                title="Tipo de atendimento"
                disabled={!selectedBeneficiary}
                name="typeId"
                apiSearch={
                  selectedBeneficiary
                    ? `/appointment-schedule/schedule-types?userUnimedId=${selectedBeneficiary.userUnimedId}`
                    : undefined
                }
                personalReducer={handleReduceScheduleTypeOptions}
                callbackOnLoad={handleOnLoadScheduleTypeList}
                callbackOnChange={handleChangeScheduleTypeCallback}
                themeColor={colors.palet.institutional11}
                formLook
              />

              <div style={{ display: isElective ? 'contents' : 'none' }}>
                <DatePickerUnform
                  formLook
                  title="Data Inicial"
                  onChangeDate={(e) =>
                    setFilterValues((prev) => ({
                      ...prev,
                      startDate: e ? e.value : '',
                    }))
                  }
                  name="startDate"
                  icon
                  themeColor={colors.palet.institutional11}
                  minDate={new Date()}
                  value={filterValues.startDate}
                />
                <DatePickerUnform
                  formLook
                  title="Data Final"
                  name="endDate"
                  icon
                  themeColor={colors.palet.institutional11}
                  minDate={new Date()}
                  onChangeDate={(date) =>
                    setFilterValues((prev) => ({
                      ...prev,
                      endDate: date,
                    }))
                  }
                  value={filterValues.endDate}
                />

                <SelectUnform
                  title="Especialidade"
                  name="specialty"
                  apiSearch={
                    selectedBeneficiary
                      ? `/appointment-schedule/specialties?userUnimedId=${selectedBeneficiary.userUnimedId}`
                      : undefined
                  }
                  callbackOnChange={(e) => {
                    formRef.current?.setErrors({});
                    setFilterValues((prev) => ({
                      ...prev,
                      speciality: e,
                    }));
                  }}
                  personalReducer={handleReduceSpecialtiesOptions}
                  themeColor={colors.palet.institutional11}
                  formLook
                />

                <Input
                  name="doctorName"
                  title="Nome do médico"
                  formLook
                  onChange={(e) => {
                    formRef.current?.setErrors({});
                    setFilterValues((prev) => ({
                      ...prev,
                      doctor: e.target.value,
                    }));
                  }}
                  themeColor={colors.palet.institutional11}
                />

                {showCityOnline && (
                  <SelectUnform
                    title="Cidade"
                    name="city"
                    formLook
                    apiSearch={`/appointment-schedule/get-cities?userUnimedId=${selectedBeneficiary?.userUnimedId}`}
                    personalReducer={personalReducerForSelectCities}
                    themeColor={colors.palet.institutional11}
                    callbackOnChange={(e) =>
                      setFilterValues((prev) => ({
                        ...prev,
                        city: e,
                      }))
                    }
                  />
                )}
              </div>

              <S.ButtonWrapper style={{ gap: '24px' }}>
                <Button
                  formLook
                  grayButton
                  type="button"
                  autoWidth
                  onClick={handleShowSearch}
                >
                  {isElective ? 'Fechar filtros' : 'Mostrar filtros'}
                </Button>
                <Button
                  formLook
                  greenPrimary
                  type="submit"
                  autoWidth
                  onClick={handleSearchClick}
                  disabled={!canSubmit}
                >
                  PESQUISAR
                </Button>
              </S.ButtonWrapper>
            </S.FormContainer>
          </FormWrapper>
        </S.Wrapper>

        {timeTables.length > 0 && selectedBeneficiary && (
          <Table
            items={tableItems}
            id="tableResponse"
            footer={
              <TableFooter>
                <span>{`Exibindo ${tableItems.length} registro${
                  tableItems.length !== 1 ? 's' : ''
                }`}</span>
                {lastPage && (
                  <Button
                    outlined
                    formLook
                    autoWidth
                    onClick={() => fetchNextPage(lastPage)}
                    className="notiflix-next-page"
                  >
                    Ver mais
                  </Button>
                )}
              </TableFooter>
            }
          >
            <Column field="doctorName" header="Médico" />
            <Column field="doctorSpecialtie" header="Especialidade" />
            <Column
              header="Endereço"
              body={(timetable) => getAddressLine(timetable)}
            />

            <Column
              header="Consultar Disponibilidade"
              body={(row) => (
                <>
                  <S.ButtonSelectHour
                    to={{
                      pathname: `/marcacao-de-agenda`,
                      state: {
                        beneficiary: selectedBeneficiary,
                        timetable: row,
                      },
                    }}
                  >
                    <SheduleSvg />
                    Selecionar horário
                  </S.ButtonSelectHour>
                </>
              )}
            />
          </Table>
        )}
        {/* {!!timeTables.length && selectedBeneficiary && (
          <Paginator
            first={pagination.currentPage}
            rows={pagination.rows}
            totalRecords={pagination.totalRecords}
            onPageChange={handlePaginate}
          />
        )} */}
      </S.Container>
    </PageWrapper>
  );
};

export default AppointmentSchedulingPage;
