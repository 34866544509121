import React from 'react';
import { useAuth } from '../../hooks/auth';

import BeneficaryRequestCardConsult from './Beneficiary';
import CompanyRequestCardConsult from './Company';

const RequestDuplicateCardConsult: React.FC = () => {
  const { user } = useAuth();

  if (user.profile === 1) {
    return <BeneficaryRequestCardConsult />;
  }
  return <CompanyRequestCardConsult />;
};

export default RequestDuplicateCardConsult;
