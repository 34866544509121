import React, { useCallback, useEffect, useRef } from 'react';
import { useAuth } from './auth';
import api from '../services/api/api';

interface ListHolderAndDependentsResponse {
  content: {
    userUnimedId: number;
    phisicalPersonCode: string;
    insuredName: string;
    nrCartao: string;
  }[];
}

interface CardInfoItem {
  product: string;
  contractedUnimedDescription: string;
  hiringType: string;
  descriptionTypeOfContract: string;
  cooperated: string;
  userPlanId: string;
  birthDate: string;
  accommodation: string;
  validityStartDate: string;
  cardExpirateDate: string;
  beneficiaryName: string;
  serviceNetwork: string;
  billingLocation: string;
  coverage: string;
  protocolNumber: string;
  cpt: string;
  contractorName: string;
  segmentation: string;
  descTrail1: string;
  descTrail2: string;
  descTrailQrCode: string;
  cns: string;
  ansProtocol: string;
  descRegulation: string;
  groupCities: Array<string>;
  descriptionShortcomings: Array<{
    shortage: string;
    validityEndDate: any;
  }>;
}

interface CardGetInfoResponse {
  result: number;
  message: string;
  data: Array<CardInfoItem>;
}

const BENEFICIARIES_CACHE_KEY = '@unimedBeneficiaries';

const MAX_RETRIES = 5;
const RETRY_DELAY = 1000;

async function fetchDataWithRetry(
  endpoint: string,
  userUnimedId: string,
  retries = 0,
): Promise<CardInfoItem[]> {
  try {
    const { data: response } = await api.get<CardGetInfoResponse>(
      `${endpoint}?userUnimedId=${userUnimedId}`,
    );

    // Verifica se a resposta é um objeto com a chave 'data'
    if ('data' in response) {
      return response.data; // Dados obtidos com sucesso
    }
    throw new Error('Empty response');
  } catch (error) {
    if (retries < MAX_RETRIES) {
      // Se ainda não atingiu o limite de tentativas, aguarda um pouco e tenta novamente
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      return fetchDataWithRetry(endpoint, userUnimedId, retries + 1);
    }
    // Se atingiu o limite de tentativas, lança o erro
    throw new Error(
      `Failed after ${MAX_RETRIES} retries: ${(error as Error).message}`,
    );
  }
}

const PWACacheProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user, isAuthendicated } = useAuth();
  const executed = useRef(false);

  const loadDataToStorage = useCallback(async () => {
    try {
      const { data } = await api.get<ListHolderAndDependentsResponse>(
        `/contract/list-holder-and-dependents?userUnimedId=${user.id}`,
      );

      // const allBeneficiariesInfos = await Promise.all(
      //   data.content.map((beneficiary) => {
      //     return api.get<CardGetInfoResponse>(
      //       `/card/get-info?userUnimedId=${beneficiary.userUnimedId}`,
      //     );
      //   }),
      // );

      const allBeneficiariesInfos = await Promise.all(
        data.content.map(async (beneficiary) => {
          try {
            return await fetchDataWithRetry(
              '/card/get-info',
              beneficiary.userUnimedId.toString(),
            );
          } catch (error) {
            console.error((error as Error).message);
            return null; // Ou outra ação apropriada para lidar com a falha
          }
        }),
      );
      localStorage.setItem(
        BENEFICIARIES_CACHE_KEY,
        JSON.stringify(
          data.content.map((beneficiary, index) => ({
            ...beneficiary,
            ...allBeneficiariesInfos[index]?.[0],
          })),
        ),
      );
    } catch (err) {
      console.error('Error fetching beneficiaries infos: ', err);
    }
  }, [user]);

  useEffect(() => {
    if (isAuthendicated && !executed.current && user.profile === 1) {
      executed.current = true;
      loadDataToStorage();
    }
  }, [user, isAuthendicated, loadDataToStorage]);

  return <>{children}</>;
};

export default PWACacheProvider;
