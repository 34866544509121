import styled, { css } from 'styled-components';
import { CameraSvg } from '../../../components/Svg';
import SelectElement from '../../../components/Select';
import InputElement from '../../../components/Input';
import FormWrapper from '../../../components/FormWrapper';

export const Title = styled.h1`
  font-family: 'Unimed-Slab-Bold';
  font-size: 2.55rem;
  line-height: 2.4rem;
  grid-column: 1/6;

  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional6};
  padding: 30px 0;
  @media (max-width: 1200px) {
    font-size: 2.25rem;
    line-height: 2.1rem;
  }
  @media (max-width: 768px) {
    grid-column: 1;
    font-size: 1.7rem;
    line-height: 1.5rem;
    padding: 30px;
  }
`;

export const CameraIcon = styled(CameraSvg)``;

export const AvatarContainer = styled.div`
  grid-column: 2/3;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  z-index: 2;
  border: 8px solid ${(props) => props.theme.colors.text.whiteToBlack};
  overflow: hidden;
  transition: 0.3s background;
  ${(props) =>
    props.theme.title === 'light' &&
    css`
      box-shadow: 0px 5px 10px rgba(196, 203, 207, 0.5);
    `};
  ${(props) =>
    props.theme.title === 'dark' &&
    css`
      box-shadow: 0px 5px 10px rgba(24, 24, 24, 0.6);
    `};
  > div {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 35%;
    z-index: 2;
    position: absolute;

    background-color: transparent;
    color: transparent;

    span {
      color: transparent;
      margin-left: 5px;
      font-family: 'Unimed-Sans-SemiBold';
      z-index: 4;
      margin-bottom: 10px;
      font-size: 0.85rem;
    }

    svg {
      margin-bottom: 10px;
      display: none;
    }

    label {
      width: 100%;
      height: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 300ms;

      input {
        display: none;
      }
    }
  }
  img {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &:hover {
    > div {
      label {
        background: rgba(66, 66, 66, 0.4);
        color: white;
        span {
          color: white;
        }
      }
      svg {
        margin-bottom: 10px;
        display: block;
      }
    }
  }

  @media (max-width: 769px) {
    grid-column: 1;
    margin: 0 auto;
    height: 120px;
    width: 120px;

    img {
      height: 110px;
      width: 110px;
    }

    div {
      opacity: 0.4;
      span {
        font-size: 0.6rem;
      }
    }

    &::after {
      width: 90%;
      height: 90%;
    }
  }
`;
interface SelectProps {
  hidden?: boolean;
}

export const SelectWrapper = styled(FormWrapper)<SelectProps>`
  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
  grid-column: 3/5;
  width: 100%;
  align-self: center;
  @media (max-width: 768px) {
    grid-column: 1;
  }
  .text-bold {
    div {
      input {
        font-weight: bold;
      }
    }
  }
`;

export const FormSelect = styled(SelectElement)`
  button {
    margin-top: 0 !important;
    border-radius: 2px !important;
  }
`;

export const Select = styled(SelectElement)``;

export const Input = styled(InputElement)`
  ${(props) =>
    props.disabled &&
    css`
      cursor: initial;
      & label {
        cursor: initial;
      }

      & input {
        cursor: initial;
      }
    `}
`;

export const Form = styled(FormWrapper)`
  grid-column: 2/5;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    grid-column: 1;
    > div {
      &:nth-child(2),
      &:nth-child(3) {
        width: auto;
        flex: 1 1;
      }

      &:nth-child(7) {
        width: auto;
        flex: 1 1 70%;
      }
      &:nth-child(8) {
        width: 20%;
        flex: 1 1 20%;
      }
    }
  }
  .react-datepicker-wrapper,
  .selectMedium {
    input {
      text-align: left;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  column-gap: 16px;

  > button {
    width: 150px;
    text-transform: uppercase;
    @media (max-width: 768px) {
      width: auto;
      flex: 1 1 0;
    }
  }
`;
