/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import Notiflix from 'notiflix';
import jwtDecode from 'jwt-decode';
// import { useHistory } from 'react-router-dom';
import { boolean } from 'yup';
import api from '../services/api/api';
// import { getParams } from '../utils/Url';

interface LoginProps {
  document: string;
  password: string;
  user: string;
  typeUser: string;
}

export interface BeneficiaryUser {
  token: string;
  refreshToken: string;
  userUnimedId: number;
  nameLogin: string;
  name: string;
  email: string;
  card: string;
  nameProduct: string;
  protocolNumber: string;
  serviceProtocolNumber: number;
  birthDate: string;
  isOwner?: boolean;
  isPayer: boolean;
  isDependent: boolean;
  physicalPersonalCode: string;
  isFirstAid: boolean;
  tags: string[];
  lastAccessDateTimeUnimed: string;
}

export interface EnterpriseUser {
  name: string;
  cnpj: string;
  webUsername: string;
  webUserId: number;
  webProfileId?: any;
  groupNumber: number;
}

export type UserCommon = {
  sub: string;
  iss: string;
  aud: string;
  iat: number;
  exp: number;
  jti: string;
  id: number;
  profile: number;

  mustUpdatePassword: boolean | string;
  lastAccessDateTime: string;
  payerNumber: number;
  contractId: number;
};
type UserProfile =
  | (UserCommon & { profile: 1 } & BeneficiaryUser)
  | (UserCommon & { profile: 2 } & EnterpriseUser);

interface ContextProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: UserProfile;
  login(props: LoginProps): Promise<boolean>;
  loginJoinIn(hash: string): Promise<boolean>;
  logout(): Promise<boolean>;
  changeAvatar(file: File): Promise<void>;
  changePass(currentPassword: string, password: string): Promise<void>;
  hasPrimaryToken: boolean;
  isAuthendicated: boolean;
  updateUser: (values: any) => void;
  lastAccess: number | undefined;
}

const userContext = createContext<ContextProps>({} as ContextProps);

export const AuthProvider: React.FC = ({ children }) => {
  // const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [user, setUser] = useState<UserProfile>(() => {
    const savedSession = localStorage.getItem('@unimedBlumenau');
    const tokenSavedSession = localStorage.getItem('@unimedBlumenauToken');
    if (savedSession && tokenSavedSession) {
      const userSaved = JSON.parse(savedSession);
      const tokenSaved = JSON.parse(tokenSavedSession);
      if (userSaved && tokenSaved) {
        api.defaults.headers.common.Authorization = `Bearer ${tokenSaved.token}`;
        return userSaved as UserProfile;
      }
    }
    return {} as UserProfile;
  });
  const [hasPrimaryToken, setHasPrimaryToken] = useState(false);
  const [lastAccess, setLastAccess] = useState<number>();
  const apiKey = process.env.REACT_APP_JWT_API_KEY;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const saveOnSession = useCallback(
    (
      userData: any,
      tokens: any,
      needsUpdateRegister: any,
      _lastAccess: any,
    ) => {
      localStorage.setItem('@unimedBlumenauToken', JSON.stringify(tokens));
      localStorage.setItem('@unimedBlumenau', JSON.stringify(userData));
      localStorage.setItem(
        '@unimedBlumenauNeedsUpdate',
        JSON.stringify(needsUpdateRegister),
      );
      localStorage.setItem(
        '@unimedBlumenauLastAccess',
        JSON.stringify(_lastAccess),
      );
    },
    [],
  );

  const removeOnSession = useCallback(() => {
    window.location.reload();
    localStorage.removeItem('@unimedBlumenau');
    localStorage.removeItem('@unimedBlumenauToken');
    localStorage.removeItem('@unimedBlumenauContract');
    localStorage.removeItem('@unimedBlumenauNeedsUpdate');
    localStorage.removeItem('@unimedBlumenauLastAccess');
    localStorage.removeItem('@unimedBeneficiaries');
    sessionStorage.removeItem('@themeUnimed');
  }, []);

  /**
   * @function login
   * @description Method for beneficiary or company
   * @param document For beneficiary
   * @param user For company
   * @param typeUser 1 beneficiary | 2 company
   * @param password
   * @param updatePassword
   * @returns boolean for authenticated
   */
  const login = useCallback(
    async ({ document, password, user: userString, typeUser }: LoginProps) => {
      let valid = false;
      const ipUser = window.ipify;

      const { data } = await api.put(`/auth/authentication`, {
        document,
        password,
        user: userString,
        typeUser,
        originIp: ipUser,
      });

      const { content } = data;

      if (content.token) {
        valid = true;
        const {
          token,
          refreshToken,
          mustUpdatePassword,
          lastAccessDateTime,
          needsUpdateRegister,
        } = content;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rest: any = jwtDecode(token);
        api.defaults.headers.common.Authorization = `Bearer ${token}`;
        const setDataNow = new Date().getTime();
        const needUpAndModal = {
          needUp: needsUpdateRegister,
          showModal: needsUpdateRegister === true,
        };
        saveOnSession(
          rest,
          { token, refreshToken },
          needUpAndModal,
          setDataNow,
        );
        setUser({
          token,
          refreshToken,
          mustUpdatePassword,
          lastAccessDateTime,
          needsUpdateRegister,
          ...rest,
        } as UserProfile);
      }

      if (!valid) {
        Notiflix.Notify.failure(
          'Suas credenciais não conferem. Por favor tente novamente.',
        );
      }
      return valid;
    },
    [saveOnSession],
  );
  const updateUser = useCallback((values: UserProfile) => {
    setUser((prev) => ({ ...prev, ...values }));
  }, []);

  /**
   * @function logout
   * @description Method for logout
   * @returns true
   */
  const logout = useCallback(async () => {
    const data = localStorage.getItem('@unimedBlumenau');
    if (data) {
      const userData = JSON.parse(data);
      const { webUsername } = userData;
      if (!webUsername) {
        // Beneficiário
        localStorage.setItem('@userType', '1');
      } else {
        localStorage.setItem('@userType', '2');
        // Empresa
      }
    }

    setUser({} as UserProfile);
    removeOnSession();
    return true;
  }, [removeOnSession]);

  /**
   * @function loginJoinIn
   * @description Method for beneficiary change hash for token and refresh
   * @param hash For beneficiary
   * @returns token for authenticated
   */
  const loginJoinIn = useCallback(
    async (hash: string) => {
      let valid = false;

      const { data } = await api.get(`/auth/auth-by-hash?hash=${hash}`);
      const { content } = data;
      if (content.token) {
        valid = true;
        const { token, refreshToken, needsUpdateRegister } = content;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rest: any = jwtDecode(token);
        api.defaults.headers.common.Authorization = `Bearer ${token}`;
        const needUpAndModal = {
          needUp: needsUpdateRegister,
          showModal: true,
        };
        saveOnSession(
          rest,
          { token, refreshToken },
          needUpAndModal,
          lastAccess,
        );
        setUser({
          token,
          refreshToken,
          needsUpdateRegister,
          ...rest,
        } as UserProfile);
      }
      if (!valid) {
        Notiflix.Notify.failure(
          'Suas credenciais não conferem. Por favor tente novamente.',
        );
      }
      return valid;
    },
    [saveOnSession],
  );

  const changePass = useCallback(
    async (currentPassword: string, newPassword: string) => {
      try {
        Notiflix.Block.circle('.notiflix-change-password-button');
        if (user.profile === 1) {
          await api.put(`/user/change-password`, {
            currentPassword,
            newPassword,
          });
        } else {
          await api.put(`/user/change-password`, {
            currentPassword,
            newPassword,
          });
        }
      } catch (error) {
        Notiflix.Notify.failure(
          'Ops. Ocorreu um erro ao tentar processar sua alteração de senha. Por favor, tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.remove('.notiflix-change-password-button');
      }
    },
    [user],
  );

  /**
   * @function changeAvatar
   * @description Method for changing Avatar
   * @param file New Avatar
   * @returns void
   */
  const changeAvatar = useCallback(async (file: File) => {
    try {
      Notiflix.Loading.circle();
      const form = new FormData();
      form.append('avatar', file);
      const { data } = await api.post('/changeAvatar', form);
      setUser((prev) => ({ ...prev, avatar: data }));
    } catch (error) {
      Notiflix.Notify.failure(
        'Não conseguimos atualizar sua imagem, tente novamente.',
      );
    } finally {
      Notiflix.Loading.remove();
    }
  }, []);

  const getPrimaryToken = useCallback(async () => {
    try {
      const { data } = await api.post('/auth/generate-primary-token', {
        apiKey,
      });
      const { content } = data;
      const { token } = content;
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      setHasPrimaryToken(true);
      localStorage.setItem('@unimedBlumenauPrimaryToken', token);
    } catch (error) {
      Notiflix.Notify.failure(
        'Servidor Indisponível no momento, tente mais tarde.',
      );
    }
  }, [apiKey]);

  useEffect(() => {
    if (!isAuthendicated) {
      getPrimaryToken().then(() => {
        // tryGetSavedUserOnLocalStorage();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAuthendicated = useMemo(() => {
    return user && Object.keys(user).length > 0;
  }, [user]);

  return (
    <userContext.Provider
      value={{
        user,
        login,
        logout,
        loginJoinIn,
        changeAvatar,
        changePass,
        isAuthendicated,
        hasPrimaryToken,
        updateUser,
        lastAccess,
      }}
    >
      {children}
    </userContext.Provider>
  );
};

export function useAuth(): ContextProps {
  const context = useContext(userContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
