import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import Notiflix from 'notiflix';
// import ipify from 'ipify';
import Input from '../../components/Input';

import {
  Container,
  Background,
  Content,
  ContainerFooter,
  BemVindo,
  BemVindoMobile,
  ContentMobile,
  ModalContent,
  ModalButton,
  ButtonLogin,
  ContainerInfoSvg,
  ContainerLogoW,
} from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import Select, { SelectHandles } from '../../components/Select';
import {
  GrupowSvg,
  LogoUnimedSvg,
  InfoSvg,
  CardUnimed,
} from '../../components/Svg';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api/api';
import Modal2 from '../../components/Modal2';
import { ObjectGetValueOfKey } from '../../utils/formatt';
import { getParams } from '../../utils/Url';
import Button from '../../components/Button';

const Login: React.FC = () => {
  const { login, hasPrimaryToken, loginJoinIn } = useAuth();
  const { params } = useParams<{ params: string }>();
  const history = useHistory();
  const typeUserRef = useRef<SelectHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const [typeUser, setTypeUser] = useState('1');
  const { hashJoinIn } = getParams();

  const handleToogleModal = useCallback(async () => {
    try {
      Notiflix.Loading.circle();
      const { data } = await api.get(
        '/gw/translation?key=termo-de-cookies&language=PT',
      );
      const { content } = data;
      const text = `${ObjectGetValueOfKey(content, 'termo-de-cookies')}`;

      Modal2.Generic({
        closable: false,
        children: (
          <>
            {/* <ModalTitle>Aviso de Cookies</ModalTitle> */}
            <ModalContent dangerouslySetInnerHTML={{ __html: text }} />
            <ModalButton
              onClick={() => Modal2.Close()}
              modal
              outlined
              autoWidth
            >
              Fechar
            </ModalButton>
          </>
        ),
      });
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Estamos com algum erro no servidor... tente mais tarde...',
      );
    } finally {
      Notiflix.Loading.remove();
    }
  }, []);

  const handleTypeUserChange = useCallback((value) => {
    setTypeUser(value);
    formRef.current?.setFieldValue('document', '');
  }, []);

  const handleFormSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          document:
            typeUser === '1'
              ? Yup.number()
                  .required('Informe seu CPF ou número da Carteirinha')
                  .typeError('Informe seu CPF ou número da Carteirinha')
              : Yup.string().required('Informe seu usuário'),
          password: Yup.string().required('Informe sua senha'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        Notiflix.Loading.circle();
        const toSend =
          typeUser === '1'
            ? { ...data, typeUser }
            : { user: data.document, password: data.password, typeUser };
        const success = await login(toSend);
        if (success) {
          const getRedirect = window.location.href.split('?redirect=')[1];
          if (getRedirect !== 'undefined') {
            setTimeout(() => {
              history.push(`/${getRedirect}`);
            }, 200);
          } else {
            history.push('/');
          }
          setTimeout(() => {
            if (getRedirect === 'alterar-senha') {
              history.push(`/alterar-senha`);
            } else {
              history.push('/');
            }
          }, 200);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (
          err.response?.data?.message &&
          err.response?.status !== 503
        ) {
          formRef.current?.setFieldError(
            'document',
            'Usuario e/ou senha inválidos',
          );
          Notiflix.Notify.failure(err.response.data.message);
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos nos conectar ao servidor. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Loading.remove();
      }
    },
    [typeUser, login, history],
  );

  const verifyHomologation = useCallback(async () => {
    const { hash } = getParams();
    const ipUser = window.ipify;
    if (hash) {
      try {
        Notiflix.Loading.circle('Autenticando...');
        await api.get(
          `/user/activation-by-clicking-on-email?uid=${hash}&originIp=${ipUser}`,
        );
        Modal2.Success({
          closable: true,
          title: 'Perfeito!',
          text: 'Você foi homologado com sucesso!',
        });
      } catch (err) {
        if (err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            subtitle: 'Ops... algo deu errado.',
            text: err.response.data.message,
            children: (
              <div>
                <Button
                  type="button"
                  onClick={() => {
                    window.location.search = '';
                  }}
                >
                  Ok
                </Button>
              </div>
            ),
          });
        } else {
          Modal2.Failure({
            closable: true,
            subtitle: 'Ops... algo deu errado.',
            text: 'Algo aconteceu com seu Link de Homologação. Não conseguimos prosseguir.',
            children: (
              <div>
                <Button
                  type="button"
                  onClick={() => {
                    window.location.search = '';
                  }}
                >
                  Ok
                </Button>
              </div>
            ),
          });
        }
      } finally {
        Notiflix.Loading.remove();
        // window.location.search = '';
      }
    }
  }, []);

  const getJoinJWT = async (hash: string) => {
    try {
      const success = await loginJoinIn(hash);
      if (success) {
        history.push('/');
      }
    } catch (err) {
      if (err.response?.data?.message) {
        Modal2.Failure({
          closable: true,
          subtitle: 'Ops... algo deu errado.',
          text: err.response.data.message,
        });
      } else {
        Modal2.Failure({
          closable: true,
          subtitle: 'Ops... algo deu errado.',
          text: 'Algo aconteceu na sua autenticação. Não conseguimos prosseguir.',
        });
      }
    }
  };

  useEffect(() => {
    const paramsHash = getParams();

    const ipUser = window.ipify;

    if (hasPrimaryToken) {
      verifyHomologation();
      if (paramsHash.hashJoinIn) {
        getJoinJWT(paramsHash.hashJoinIn);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPrimaryToken]);

  useEffect(() => {
    const userType = localStorage.getItem('@userType');
    if (!userType) {
      return;
    }

    typeUserRef.current?.setValue(
      userType === '1' ? 'Beneficiário' : 'Empresa',
      userType,
    );

    setTypeUser(userType);
  }, []);

  const bemVindoDesktop = useMemo(() => {
    if (typeUser === '1') {
      return (
        <BemVindo>
          Bem-vindo <br />
          ao portal do <br />
          beneficiário <br />
        </BemVindo>
      );
    }
    if (typeUser === '2') {
      return (
        <BemVindo>
          Bem-vindo <br />
          ao portal Unimed <br />
          Empresas
        </BemVindo>
      );
    }
    return <></>;
  }, [typeUser]);

  const bemVindoMobile = useMemo(() => {
    if (typeUser === '1') {
      return (
        <BemVindoMobile>
          Bem-vindo ao
          <br />
          portal do beneficiário
        </BemVindoMobile>
      );
    }
    if (typeUser === '2') {
      return (
        <BemVindoMobile>
          Bem-vindo ao portal
          <br />
          Unimed Empresas
        </BemVindoMobile>
      );
    }
    return <></>;
  }, [typeUser]);

  return (
    <Container>
      <Background background={typeUser}>
        {bemVindoDesktop}
        <LogoUnimedSvg />
        <ContainerLogoW>
          <a
            href="https://www.grupow.com.br/"
            title="GrupoW Softwares para Internet"
            target="_blanck"
          >
            Desenvolvido por <strong>GrupoW Softwares para Internet</strong>
            <GrupowSvg />
          </a>
        </ContainerLogoW>
      </Background>
      <Content>
        <Form ref={formRef} onSubmit={handleFormSubmit}>
          <ContentMobile background={typeUser}>
            <h2>
              Selecione seu perfil <br />e digite seus dados
            </h2>
            <LogoUnimedSvg />
            {bemVindoMobile}
            <Select
              name="typeuser"
              ref={typeUserRef}
              changeCallback={handleTypeUserChange}
              options={[
                { value: '1', title: 'Beneficiário' },
                { value: '2', title: 'Empresa' },
              ]}
            >
              {typeUser === '1' && (
                <ContainerInfoSvg>
                  <InfoSvg />
                  <div>
                    <CardUnimed />
                  </div>
                </ContainerInfoSvg>
              )}
            </Select>
            <Input
              name="document"
              placeholder={
                // eslint-disable-next-line no-nested-ternary
                typeUser === '1'
                  ? 'CPF ou Carteirinha'
                  : typeUser === '2'
                  ? 'Usuário'
                  : ''
              }
              masks={
                typeUser === '1'
                  ? ['999.999.999-99', '9 999 999999999999 9']
                  : undefined
              }
            />

            <Input name="password" type="password" placeholder="Senha" />
          </ContentMobile>

          <ButtonLogin type="submit">Entrar</ButtonLogin>
          <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
        </Form>

        <ContainerFooter>
          <div>
            <button
              type="button"
              onClick={handleToogleModal}
              className={typeUser === '2' ? '-center' : ''}
            >
              Aviso de Cookies
            </button>
            {typeUser === '1' && (
              <div>
                <span>É a sua primeira vez aqui?</span>
                <br />
                <Link to="/cadastre-se">Cadastre-se</Link>
              </div>
            )}
          </div>

          <ContainerLogoW className="mobile">
            <a
              href="https://www.grupow.com.br/"
              title="GrupoW Softwares para Internet"
              target="_blanck"
            >
              Desenvolvido por <strong>GrupoW Softwares para Internet</strong>
              <GrupowSvg />
            </a>
          </ContainerLogoW>
        </ContainerFooter>
      </Content>
    </Container>
  );
};

export default Login;
