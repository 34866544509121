import React, { useCallback, useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import { useHistory } from 'react-router-dom';
import Title from '../../../components/Title';
import { useTheme } from '../../../hooks/theme';
import {
  Container,
  Content,
  Dropdown,
  NutritionalOrientationTitle,
  NutritionalOrientationBody,
  Message,
  Observation,
  ButtonGoBack,
  Col,
} from './styles';
import PageWrapper from '../../../components/PageWrapper';
import api from '../../../services/api/api';

interface NutritionalProp {
  data: [
    {
      Campos: [
        {
          dsCampo: string;
          dsConteudo: string;
          dsTipoCampo: string;
        },
      ];
      dsTitulo: string;
      dtInformacao: string;
    },
  ];
  message: string;
  result: number;
}

const NutritionalOrientation: React.FC = () => {
  const { theme } = useTheme();
  const [nutritional, setNutritional] = useState<NutritionalProp>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const getNutritional = useCallback(async () => {
    try {
      setLoading(true);
      Notiflix.Block.circle(
        '.notiflix-loading-nutritional-orientation',
        'Carregando',
        {
          backgroundColor: 'rgba(255, 255, 255, 0.6)',
        },
      );

      const { data } = await api.get(
        `/first-aid/curative-nutritional-orientation`,
      );
      setNutritional(data.content);
      setLoading(false);
    } catch (err) {
      if (err?.response?.status === 400) {
        Notiflix.Notify.info(err.response.data?.message);
      } else {
        Notiflix.Notify.failure(
          'Não conseguimos buscar sua orientação nutricional. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-loading-nutritional-orientation');
    }
  }, []);

  useEffect(() => {
    getNutritional();
  }, []);

  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <Container>
        <Title themeColor={theme.colors.palet.institutional2}>
          Orientação nutricional
        </Title>
        <Col className="alignEnd">
          <ButtonGoBack onClick={() => history.push('/ambulatorio-curativos')}>
            Voltar
          </ButtonGoBack>
        </Col>
        {nutritional?.data && !loading && (
          <Col className="alignCenter">
            <Observation>
              Clique nos botões abaixo para expandir e visualizar
            </Observation>
          </Col>
        )}
        <Content className="notiflix-loading-nutritional-orientation">
          <span />
          {nutritional?.data && (
            <>
              {nutritional?.data.map((item, i) => {
                return (
                  <>
                    <Dropdown
                      className={`notiflix-meta-care-${item.dsTitulo}`}
                      key={item.dsTitulo}
                      titleJSX={
                        <NutritionalOrientationTitle>
                          <h2>{item.dsTitulo}</h2>
                          <p>{item.dtInformacao}</p>
                        </NutritionalOrientationTitle>
                      }
                      body={
                        <>
                          <NutritionalOrientationBody>
                            {item.Campos && (
                              <>
                                {item.Campos.map((subItem, index) => {
                                  return (
                                    <>
                                      <h2
                                        dangerouslySetInnerHTML={{
                                          __html: subItem.dsCampo,
                                        }}
                                      />{' '}
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: subItem.dsConteudo,
                                        }}
                                      />
                                    </>
                                  );
                                })}
                              </>
                            )}
                          </NutritionalOrientationBody>
                        </>
                      }
                    />
                  </>
                );
              })}
            </>
          )}
          {!nutritional?.data && !loading && (
            <Message>Você não possui dados de orientação nutricional</Message>
          )}
        </Content>
      </Container>
    </PageWrapper>
  );
};

export default NutritionalOrientation;
