/* eslint-disable max-len */
import styled, { css, keyframes } from 'styled-components';
import DatePickerOrigin, { ReactDatePickerProps } from 'react-datepicker';
import { SheduleSvg as Shadule } from '../Svg';

const animValid = keyframes`
  from{
    transform: translate(0,-30%);
    opacity: 0;
  }to{
    transform: translate(0,-50%);
    opacity: 1;
  }
`;
interface DateProps extends ReactDatePickerProps {
  onError?: boolean;
  hasIcon?: boolean;
  formLook?: boolean;
  isValid?: boolean;
}

export const InputDate = styled(DatePickerOrigin)<DateProps>`
  width: 100%;
  height: 100%;
  border: 1px solid ${(props) => props.theme.colors.text.secondary};
  box-sizing: border-box;
  border-radius: 10px;
  background: #fff;
  padding: 15px 20px;
  position: relative;
  display: flex;
  color: ${(props) => props.theme.colors.palet.institutional11};
  font-family: 'Unimed-Sans-SemiBold';
  outline: none;
  /* min-width: 120px; */
  &::placeholder {
    opacity: 0.5;
  }

  ${(props) =>
    props.formLook &&
    css`
      padding: 5px 40px 5px 20px;
      border-radius: 5px;
      min-height: 36px;
      text-align: right;
      background: #fff;
      border-color: ${props.theme.colors.palet.institutional12};
      font-family: 'Unimed-Sans-Book';
      text-align: left;

      &:disabled {
        background: ${props.theme.colors.palet.institutional14};
        border-color: ${props.theme.colors.palet.institutional14};
      }
    `}
  ${(props) =>
    props.onError &&
    css`
      border-color: ${props.theme.colors.palet.institutional7};
    `}
    ${(props) =>
    props.isValid &&
    !props.onError &&
    css`
      border-color: ${props.theme.colors.palet.institutional4};
    `}
  ${(props) =>
    props.hasIcon &&
    css`
      padding-left: 38px;
    `}
`;

interface ContainerProps {
  disabled?: boolean;
  isValid?: boolean;
  isError?: boolean;
  widthContainerDesktop?: string;
  formLook?: boolean;
}

const reducedOnePercent = (width: string) => {
  return `calc(${width} - 1%)`;
};
const containerCssWithClass = css<ContainerProps>`
  .react-datepicker-wrapper {
    width: 100%;
    /* height: 100%; */
  }

  .react-datepicker__tab-loop {
    z-index: 20;
    /* max-height: 100px; */
  }

  .react-datepicker__tab-loop .react-datepicker__header {
    text-align: center;
    border-bottom: none;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
    background: ${(props) => props.theme.colors.text.whiteToBlack};
  }
  .react-datepicker-time__header,
  .react-datepicker__time-list-item {
    color: ${(props) => props.theme.colors.text.blackToWhite};
  }
  .react-datepicker__time-container .react-datepicker__time {
    background: ${(props) => props.theme.colors.text.whiteToBlack};
  }

  .react-datepicker__tab-loop .react-datepicker__month-select {
    border: none;
    font-weight: bold;
  }

  .react-datepicker__day--disabled {
    color: transparent !important;
  }

  .react-datepicker__day {
    color: ${(props) => props.theme.colors.text.blackToWhite};
  }

  .react-datepicker__day--outside-month {
    color: rgb(163, 163, 163);
  }

  .react-datepicker__tab-loop .react-datepicker__year-select {
    border: none;
  }

  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    text-align: left;
    padding: 10px;
    outline: none;
  }

  /* adicionaod padding no datepicker inteiro */
  .react-datepicker__month-container {
    padding: 5px 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: ${(props) => props.theme.colors.text.whiteToBlack};

    outline: none;
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.colors.text.blackToWhite};
  }

  /* estilizando o select que captura o dia atual */
  .react-datepicker__tab-loop
    .react-datepicker__day.react-datepicker__day--031.react-datepicker__day--selected {
    background-color: #00995d;
    border-radius: 50%;
  }

  /* para removenr outline do select do ano */
  .react-datepicker__tab-loop select.react-datepicker__year-select {
    outline: none;
    padding: 5px;
    font-weight: bold;
    border: 1px solid ${(props) => props.theme.colors.text.blackToWhite};
    border-radius: 5px;
    background: ${(props) => props.theme.colors.text.whiteToBlack};

    color: ${(props) => props.theme.colors.text.blackToWhite};
  }

  /* colocando background no select do ano */
  .react-datepicker__tab-loop select.react-datepicker__year-select:hover {
    background-color: #eaedef;
    background: ${(props) => props.theme.colors.text.grayToHeavyGray};

    cursor: pointer;
    border-radius: 5px;
  }

  /* Posicionar os elementos dropdonw ano e mes na header em space-between */
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    display: flex;
    justify-content: space-between;
  }

  /* colocando a cor branca do dropdown do elemento acima */
  .react-datepicker__tab-loop
    select.react-datepicker__year-select:hover
    option {
    background: ${(props) => props.theme.colors.text.whiteToGrayish};

    color: ${(props) => props.theme.colors.text.blackToWhite};
  }

  /* Estilizanod o select do month */
  .react-datepicker__tab-loop select.react-datepicker__month-select {
    padding: 5px;
    outline: none;
    cursor: pointer;
    color: ${(props) => props.theme.colors.text.blackToWhite};
    border: 1px solid ${(props) => props.theme.colors.text.blackToWhite};
    background: ${(props) => props.theme.colors.text.whiteToBlack};

    border-radius: 5px;
  }

  /* hover no select do mes */
  .react-datepicker__tab-loop select.react-datepicker__month-select:hover {
    background: ${(props) => props.theme.colors.text.whiteToBlack};
    border-radius: 5px;
  }

  /* items do select do mês */
  .react-datepicker__tab-loop
    select.react-datepicker__month-select:hover
    option {
    background-color: ${(props) => props.theme.colors.text.grayToHeavyGray};
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    &.react-datepicker__time-list-item--selected {
      background-color: #00995d;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.text.grayToHeavyGray};
    }
  }

  /* Selected do usuário o qual ele vai escolher qual dia vai ser */
  .react-datepicker__day.react-datepicker__day--selected {
    background: #00995d !important;
    border-radius: 50% !important;
  }

  /* O selected que já vem pré-selecionado */
  .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: #00995d !important;
    border-radius: 50% !important;
  }

  .react-datepicker__month-container {
    max-height: 283px;
    overflow: hidden;
  }

  /* tirar o quadrado de antes do hover */
  .react-datepicker__day:hover {
    border-radius: 50%;
    color: black;
  }

  /* estilizando o dom, seg, ter, quar... */
  .react-datepicker__day-name {
    text-transform: capitalize;
    font-weight: bolder;
  }

  /* nome dos dias da semana */
  .react-datepicker__day-name {
    color: ${(props) => props.theme.colors.text.blackToWhite};
  }

  .react-datepicker__current-month {
    color: ${(props) => props.theme.colors.text.blackToWhite};
  }
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  width: 100%;

  ${containerCssWithClass}

  svg.chevron {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    /* z-index: 1; */
    pointer-events: none;
    stroke-width: 1px;
    color: ${(props) => props.theme.colors.palet.institutional11};
    transition: 300ms;
    &.onError {
      right: 40px;
    }
    ${(props) =>
      props.disabled &&
      css`
        display: none;
      `}
  }
  ${(props) =>
    props.isValid &&
    !props.isError &&
    css`
      &::before {
        z-index: 1;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 1L6 12L1 7' stroke='%23B1D34B' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 20px;
        width: 20px;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
        animation: ${animValid} forwards 300ms;
      }
    `};

  ${(props) =>
    props.widthContainerDesktop &&
    css`
      @media (min-width: 769px) {
        width: auto;
        flex: 1 1 ${reducedOnePercent(props.widthContainerDesktop)};
      }
    `}
  ${(props) =>
    props.formLook &&
    css`
      height: 36px;
    `}
`;

export const Error = styled.div`
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='19' viewBox='0 0 22 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.36333 1.9188L1.25652 15.4525C1.08903 15.7396 1.00097 16.0746 1.00001 16.4C0.999051 16.735 1.08519 17.07 1.25077 17.3572C1.41635 17.6443 1.65563 17.8931 1.94372 18.0558C2.23181 18.2281 2.55915 18.3239 2.89319 18.3239H19.1068C19.4408 18.3239 19.7682 18.2281 20.0563 18.0558C20.3444 17.8931 20.5836 17.6443 20.7492 17.3572C20.9148 17.07 21.0009 16.735 21 16.4C20.999 16.0746 20.911 15.7396 20.7435 15.4525L12.6367 1.9188C12.4663 1.64123 12.2261 1.40199 11.9389 1.23928C11.6527 1.08614 11.3292 1 11 1C10.6708 1 10.3473 1.08614 10.0611 1.23928C9.77394 1.40199 9.5337 1.64123 9.36333 1.9188Z' stroke='%23ED1651' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11 6.83853V10.667' stroke='%23ED1651' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11 14.4955H11.01' stroke='%23ED1651' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  right: 16px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  top: 50%;
  transform: translateY(-50%);

  span {
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
    transition: 300ms !important;
    position: absolute;
    white-space: nowrap;
    right: -20px;
    top: -54px;
    background: ${(props) => props.theme.colors.palet.institutional7};
    color: ${(props) => props.theme.colors.text.inverse};
    font-family: 'Unimed-Sans-SemiBold';
    font-size: 1rem;
    padding: 6px 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(255, 15, 100, 0.3);

    &::before {
      content: '';
      position: absolute;
      bottom: -10px;
      right: 10px;
      border: 5px solid ${(props) => props.theme.colors.palet.institutional7};
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
  }

  &:hover {
    span {
      transition: 300ms;
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const SheduleSvg = styled(Shadule)`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  /* z-index: 1; */
  pointer-events: none;
`;

interface TitleProps {
  themeColor?: string;
}

export const Title = styled.span<TitleProps>`
  text-align: left;
  font-family: 'Unimed-Sans-Bold';
  font-size: 0.65rem;
  color: ${(props) => props.theme.colors.text.grayishToWhite};

  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(calc(-100% - 6px));
  ${(props) =>
    props.themeColor &&
    props.theme.title === 'light' &&
    css`
      color: ${props.themeColor};
    `}
  ${(props) =>
    props.theme.title === 'dark' &&
    css`
      color: ${props.theme.colors.text.grayishToWhite};
    `}
`;
