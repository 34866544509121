import React from 'react';

interface IOnlineMode {
  isOnline: boolean;
}
const OnlineModeContext = React.createContext<IOnlineMode>({} as IOnlineMode);

export const OnlineModeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isOnline, setIsOnline] = React.useState(true);

  React.useEffect(() => {
    const updateOnline = () => {
      setIsOnline(window.navigator.onLine);
    };

    window.addEventListener('online', updateOnline);
    window.addEventListener('offline', updateOnline);
    updateOnline();

    return () => {
      window.removeEventListener('online', updateOnline);
      window.removeEventListener('offline', updateOnline);
    };
  }, []);

  return (
    <OnlineModeContext.Provider value={{ isOnline }}>
      {children}
    </OnlineModeContext.Provider>
  );
};

export const useOnlineMode = () => {
  const context = React.useContext(OnlineModeContext);
  if (!context)
    throw new Error('useOnlineMode must be used within a OnlineModeProvider');
  return context;
};
