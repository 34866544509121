import React, { useEffect, useMemo, useState } from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import { ListContainer } from './styles';

interface PasswordProps {
  query: string;
  validation: React.Dispatch<React.SetStateAction<boolean>>;
}

const PasswordList: React.FC<PasswordProps> = ({ query, validation }) => {
  const [inputValue, setInputValue] = useState('');

  const verifyLength = useMemo(() => {
    if (inputValue.length > 7) {
      return true;
    }
    return false;
  }, [inputValue]);

  const verifyUppercase = useMemo(() => {
    if (inputValue.match(/^(?=.*[A-Z])/)) {
      return true;
    }
    return false;
  }, [inputValue]);

  const verifyLowercase = useMemo(() => {
    if (inputValue.match(/^(?=.*[a-z])/)) {
      return true;
    }
    return false;
  }, [inputValue]);

  const verifyNumber = useMemo(() => {
    if (inputValue.match(/^(?=.*[0-9])/)) {
      return true;
    }
    return false;
  }, [inputValue]);

  const verifySpecialChar = useMemo(() => {
    // eslint-disable-next-line no-useless-escape
    if (inputValue.match(/[-._!"`'#%&,:;<>=@{}~\?$\(\)\´*\+\/\\\?\[\]\^\|]+/)) {
      return true;
    }
    return false;
  }, [inputValue]);

  useEffect(() => {
    const input =
      document.querySelector<HTMLInputElement>(`#${query}`) ??
      document.querySelector<HTMLInputElement>(`[name="${query}"]`);
    const settingValue = () => {
      setInputValue(input?.value || '');
    };

    input?.addEventListener('keyup', settingValue);
    return () => {
      input?.removeEventListener('keyup', settingValue);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    validation(
      verifySpecialChar &&
        verifyNumber &&
        verifyLowercase &&
        verifyUppercase &&
        verifyLength,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    verifyLength,
    verifyLowercase,
    verifyNumber,
    verifySpecialChar,
    verifyUppercase,
  ]);

  return (
    <ListContainer>
      <h3>Sua senha deve conter:</h3>
      <ol>
        <li className={verifyLength ? 'check' : ''} id="digits">
          <FiCheckCircle height={24} /> <span> Mais de 8 dígitos </span>
        </li>
        <li className={verifyUppercase ? 'check' : ''} id="upper">
          <FiCheckCircle height={24} /> <span> Letra maiúscula </span>
        </li>
        <li className={verifyLowercase ? 'check' : ''} id="lower">
          <FiCheckCircle height={24} /> <span> Letra minúscula </span>
        </li>
        <li className={verifyNumber ? 'check' : ''} id="number">
          <FiCheckCircle height={24} /> <span> Numeral </span>
        </li>
        <li className={verifySpecialChar ? 'check' : ''} id="special">
          <FiCheckCircle height={24} /> <span> Caractere especial </span>
        </li>
      </ol>
    </ListContainer>
  );
};

export default PasswordList;
