/* eslint-disable prettier/prettier */
import React,{useEffect} from "react";
import { Switch, Route as RouteDom , useLocation } from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import ChangeMyPassword from "../pages/ChangeMyPassword";
import ChangePassword from "../pages/ChangePassword";
import registerBeneficiary from "../pages/RegisterBeneficiary";
import BeneficiaryAndProduct from "../pages/BeneficiaryAndProductDatas";
import DemonstrativeAndDuplicate from "../pages/DemonstrativeAndDuplicate";
import ChangeRegisterData from "../pages/ChangeRegisterData";
import RequestDuplicateCardConsult from "../pages/RequestDuplicateCardConsult";
import RequestDuplicateCard from "../pages/RequestDuplicateCard";
import FAQ from "../pages/Faq";
import Releases from "../pages/Releases";
import Terms from "../pages/Terms";
import Tutorials from "../pages/Tutorials";
import Lack from "../pages/Lack";
import ManualAndFiles from "../pages/ManualAndFiles";
import PartialTemporaryCover from "../pages/PartialTemporaryCover";
import AccountDebit from "../pages/AccountDebit";
import TerminationConsult from "../pages/TerminationConsult";
import ProducersRol from "../pages/ProceduresRol";
import TerminateBeneficiary from "../pages/TerminateBeneficiary";
import ChangeRegisterDataConsult from "../pages/ChangeRegisterDataConsult";
import RequestAuthorization from "../pages/RequestAuthorization";
import RequestRefundConsult from "../pages/RequestRefundConsult";
import Authorization from "../pages/Authorization";
import ReferenceUnimedTable from "../pages/ReferenceUnimedTable";
import IncomeTaxReturn from "../pages/IncomeTaxReturn";
import UsagesServicesComponent from "../pages/UsageServicesComponent";
import Refund from "../pages/Refund";
import Route from "./Route";
import Ombudsman from "../pages/Ombudsman";
import ConsultServiceProtocol from "../pages/ConsultServiceProtocol";
import CoparticipationExtract from "../pages/CoparticipationExtract";
import BeneficiaryInclusionGenerateLink from "../pages/BeneficiaryInclusionGenerateLink";
// import ContractChange from '../pages/ContractChange';
import ConsultProductChange from "../pages/ConsultProductChange";
// import InclusionOfBeneficiaries from '../pages/InclusionOfBeneficiaries';
import Lossratio from "../pages/Lossratio";
import ReadjustmentsIndex from "../pages/ReadjustmentsIndex";
import ContractDocuments from "../pages/ContractDocuments";
import UsersList from "../pages/UsersList";
import AddUser from "../pages/AddUser";
import ConsultInclusionOfBeneficiaries from "../pages/ConsultInclusionOfBeneficiaries";
import PrivacyAndCookies from "../pages/PrivacyAndCookies";

import Beneficiaries from "../pages/Beneficiaries";
import ConsultContractChange from "../pages/ConsultContractChange";
import BeneficiaryInclude from "../pages/BeneficiaryInclude";
import Report from "../pages/Report";
import CallTerminate from "../pages/Beneficiaries/CallTerminate";
import CallEditBeneficiaryInclude from "../pages/BeneficiaryInclude/CallEditBeneficiaryInclude";
import CallBeneficiaryIncludeExternal from "../pages/BeneficiaryInclude/CallBeneficiaryIncludeExternal";
import BeneficiaryInclusionPerLot from "../pages/BeneficiaryInclusionPerLot";
import LotDetails from "../pages/LotDetails";
import Error404 from "../pages/Error404";
import Res from "../pages/Res";
import DressingsOutpatientClinic from "../pages/DressingsOutpatientClinic";
import Exams from "../pages/DressingsOutpatientClinic/Exams";
import MetaCare from "../pages/DressingsOutpatientClinic/MetaCare";
import NutritionalOrientation from "../pages/DressingsOutpatientClinic/NutritionalOrientation";
import Photos from "../pages/DressingsOutpatientClinic/Photos";
import Schedules from "../pages/DressingsOutpatientClinic/Schedules";
import TypesOfInjuries from "../pages/DressingsOutpatientClinic/TypesOfInjuries";
import WhatsappLink from "../pages/DressingsOutpatientClinic/WhatsappLink";
import HealthDeclaration from "../pages/HealthDeclaration";
import HealthDeclarationForm from "../pages/HealthDeclaration/Form";
import InterviewSchedule from "../pages/HealthDeclaration/InterviewSchedule";
import SchedulingPage from "../pages/Scheduling";
import AppointmentSchedulingPage from "../pages/Scheduling/Appointment";
import SchedulingCreate from "../pages/SchedulingCreate";
import Wallet from "../pages/Wallet";

// import {STORAGE_FILTER} from '../utils/localStorageConsulta'

const Routes: React.FC = () => {
  // const { user, isAuthendicated } = useAuth();
  // const location = useLocation()
  // useEffect(() => {
  //   const allowedRoutes = [
  //     '/pesquisa-e-marcacao-de-agenda',
  //     '/marcacao-de-agenda',
  //   ];

  //   if (!allowedRoutes.includes(location?.pathname)) {
  //     localStorage?.removeItem(STORAGE_FILTER);
  //   }
  // }, [location]);
  return (
    <>
      <Switch>
        <Route path="/" exact component={Home} isPrivate />
        <Route path="/carteirinha" component={Wallet} isPrivate/>
        <Route path="/login" component={Login} />
        <Route path="/esqueci-minha-senha" component={ForgotPassword} />
        <Route path="/cadastre-se" component={registerBeneficiary} />
        <Route path="/trocar-minha-senha" component={ChangeMyPassword} />
        <Route path="/agendamento-entrevista" component={InterviewSchedule} />
        <Route
          path="/alterar-dados-cadastrais"
          component={ChangeRegisterData}
          isPrivate
        />
        <Route path="/alterar-senha" component={ChangePassword} isPrivate />
        <Route
          path="/ambulatorio-curativos"
          component={DressingsOutpatientClinic}
          isPrivate
        />
        <Route
          path="/ambulatorio-curativos-exames"
          component={Exams}
          isPrivate
        />
        <Route
          path="/ambulatorio-curativos-nutricao"
          component={NutritionalOrientation}
          isPrivate
        />
        <Route
          path="/ambulatorio-curativos-cuidado"
          component={MetaCare}
          isPrivate
        />
        <Route
          path="/ambulatorio-curativos-fotos"
          component={Photos}
          isPrivate
        />
        <Route
          path="/ambulatorio-curativos-agendamento"
          component={Schedules}
          isPrivate
        />
        <Route
          path="/ambulatorio-curativos-lesoes"
          component={TypesOfInjuries}
          isPrivate
        />
        <Route
          path="/ambulatorio-curativos-whatsapp"
          component={WhatsappLink}
          isPrivate
        />
        <Route path="/autorizacao" component={Authorization} isPrivate />
        <Route
          path="/beneficiarios"
          exact
          component={Beneficiaries}
          isPrivate
        />
        <Route
          path="/beneficiarios/3/:params+"
          component={CallTerminate}
          isPrivate
        />
        <Route
          path="/beneficiarios/:step/:params+"
          component={Beneficiaries}
          isPrivate
        />
        <Route path="/carencia" exact component={Lack} isPrivate />
        <Route
          path="/carencia/:userUnimedId/:beneficiaryName"
          component={Lack}
          isPrivate
        />
        <Route
          path="/cobertura-parcial-temporaria"
          component={PartialTemporaryCover}
          isPrivate
        />
        <Route
          path="/cobertura-parcial-temporaria/:id"
          component={PartialTemporaryCover}
          isPrivate
        />
        <Route path="/comunicados" component={Releases} isPrivate />
        <Route
          path="/consulta-alteracoes-cadastrais"
          component={ChangeRegisterDataConsult}
          isPrivate
        />
        <Route
          path="/consulta-alteracoes-contrato-cnpj"
          component={ConsultContractChange}
          isPrivate
        />
        <Route
          path="/consulta-alteracoes-produto"
          component={ConsultProductChange}
          isPrivate
        />
        <Route
          path="/consulta-inclusao-beneficiario"
          component={ConsultInclusionOfBeneficiaries}
          isPrivate
        />
        <Route
          path="/consulta-protocolo-atendimento"
          component={ConsultServiceProtocol}
          isPrivate
        />
        <Route
          path="/consulta-rescisao"
          component={TerminationConsult}
          isPrivate
        />
        <Route
          path="/consulta-segunda-via-cartao"
          component={RequestDuplicateCardConsult}
          isPrivate
        />
        <Route
          path="/consulta-solicitacao-reembolso"
          component={RequestRefundConsult}
          isPrivate
        />
        <Route
          path="/dados-beneficiario-produto"
          component={BeneficiaryAndProduct}
          isPrivate
        />
        <Route path="/debito-em-conta" component={AccountDebit} isPrivate />
        <Route
          path="/declaracao-imposto-renda"
          component={IncomeTaxReturn}
          isPrivate
        />
        <Route
          path="/demonstrativos-e-segunda-via-boleto"
          component={DemonstrativeAndDuplicate}
          isPrivate
        />
        <Route path="/declaracao-saude/:hash" component={HealthDeclaration} />
        <Route
          path="/declaracao-saude-formulario"
          component={HealthDeclarationForm}
        />
        <Route
          path="/detalhes-lote/:id/:situation"
          component={LotDetails}
          isPrivate
        />
        <Route
          path="/documentos-contrato"
          component={ContractDocuments}
          isPrivate
        />
        <Route
          path="/extrato-coparticipacao"
          component={CoparticipationExtract}
          isPrivate
        />
        <Route
          path="/extrato-de-utilizacao"
          component={UsagesServicesComponent}
          isPrivate
        />
        <Route path="/faq" component={FAQ} isPrivate />
        <Route
          path="/incluir-beneficiario-gerar-link"
          component={BeneficiaryInclusionGenerateLink}
          isPrivate
        />
        <Route
          path="/incluir-beneficiario-por-lote"
          component={BeneficiaryInclusionPerLot}
          isPrivate
        />
        <Route path="/incluir-usuario" component={AddUser} isPrivate />
        <Route
          path="/indice-reajustes"
          component={ReadjustmentsIndex}
          isPrivate
        />
        <Route path="/listagem-usuarios" component={UsersList} isPrivate />
        <Route
          path="/manuais-e-arquivos"
          component={ManualAndFiles}
          isPrivate
        />
        <Route path="/ouvidoria" component={Ombudsman} isPrivate />
        <Route
          path="/privacidade-e-seguranca"
          component={PrivacyAndCookies}
          isPrivate
        />
        <Route
          path="/requisitar-autorizacao"
          component={RequestAuthorization}
          isPrivate
        />
        <Route
          path="/rescindir-beneficiario"
          component={TerminateBeneficiary}
          isPrivate
        />
        <Route path="/rol-procedimentos" component={ProducersRol} isPrivate />
        <Route path="/sinistralidade" component={Lossratio} isPrivate />
        <Route
          path="/solicita-segunda-via-cartao"
          component={RequestDuplicateCard}
          isPrivate
        />
        <Route path="/reembolso" component={Refund} isPrivate />
        <Route path="/relatorios" component={Report} isPrivate />
        <Route
          path="/tabela-referencia-unimed"
          component={ReferenceUnimedTable}
          isPrivate
        />
        <Route path="/termos" component={Terms} isPrivate />
        <Route path="/tutoriais" component={Tutorials} isPrivate />
        <Route path="/res" component={Res} isPrivate />
        <Route
          path="/incluir-beneficiario"
          exact
          component={BeneficiaryInclude}
          isPrivate
        />
        <Route
          path="/incluir-beneficiario/:requestId/:params+"
          component={CallEditBeneficiaryInclude}
          isPrivate
        />
        <Route
          path="/visualizacao-e-cancelamentos-de-agendamentos"
          exact
          component={SchedulingPage}
          isPrivate
        />
        <Route
          path="/pesquisa-e-marcacao-de-agenda"
          exact
          component={AppointmentSchedulingPage}
          isPrivate
        />
        <Route
          path="/marcacao-de-agenda"
          exact
          component={SchedulingCreate}
          isPrivate
        />

        <RouteDom
          path="/incluir-beneficiario-externo/:hash+"
          component={CallBeneficiaryIncludeExternal}
        />

        {/* <Route path="/alterar-contrato-cnpj"  component={ContractChange} isPrivate/> */}
        {/* criar 404 */}
        <Route path="*" component={Error404} />
      </Switch>
    </>
  );
};

export default Routes;
