/* eslint-disable no-shadow */
export enum VALIDATE_ROUTE {
  PAGINA_INICIAL = 'PI',
  TABELA_REFERENCIA_UNIMED = 'PI-TRU',
  ROL_DE_PROCEDIMENTOS = 'RP',
  CREATE_SCHEDULING = 'AG-RA',
  COMPONENTE_CADASTRAL = 'DC-DBP',
  CARENCIA = 'DC-CRA',
  COBERTURA_PARCIAL_TEMPORARIA = 'DC-CPT',
  EXTRATO_DE_UTILIZACAO = 'DC-EDU',
  ALTERAR_DADOS_CADASTRAIS = 'DC-ADC',
  PROTOCOLOS_DE_ATENDIMENTO = 'DC-CPA',
  SOLICITAR_2_VIA_DO_CARTAO = 'SO-SVC',
  RESCINDIR_BENEFICIARIO = 'SO-RBN',
  SOLICITAR_REEMBOLSO = 'SO-RBO',
  OUVIDORIA = 'SO-OVA',
  DEMONSTRATIVOS_E_2_VIA_BOLETO = 'FI-DVB',
  EXTRATO_COPARTICIPACAO = 'FI-ECO',
  DEBITO_EM_CONTA = 'FI-DEC',
  DECLARACAO_DE_IMPOSTO_DE_RENDA = 'FI-DIR',
  INDICE_DE_REAJUSTES = 'FI-IRJ',
  SINISTRALIDADE = 'FI-SND',
  STATUS_DE_AUTORIZACAO = 'AU-STO',
  REQUISITAR_AUTORIZACAO = 'AU-RAU',
  CONSULTAR_2_VIA_DO_CARTAO = 'CS-SVC',
  CONSULTA_DE_ALTERACOES_CADASTRAIS = 'CS-CAC',
  CONSULTA_RESCISAO_DE_BENEFICIARIO = 'CS-CRB',
  CONSULTA_ALTERACOES_DE_CONTRATO_CNPJ = 'CS-CPJ',
  CONSULTA_ALTERACAO_DE_PRODUTO = 'CS-CAP',
  CONSULTA_INCLUSAO_DE_BENEFICIARIO = 'CS-CIB',
  CONSULTA_DE_SOLICITACAO_DE_REEMBOLSO = 'SS-CSR',
  COMUNICADOS = 'CO',
  MANUAIS_E_ARQUIVOS = 'DA-MAS',
  FAQ = 'DA-FAQ',
  TUTORIAIS = 'DA-TOS',
  TERMOS = 'DA-TRS',
  DOCUMENTOS_DO_CONTRATO = 'DA-DCO',
  PRIVACIDADE_E_SEGURANCA = 'DA-PSA',
  RELATORIOS = 'RE',
  ALTERAR_SENHA = 'AS',
  GERENCIAR_BENEFICIARIOS = 'BE',
  INCLUIR_BENEFICIARIO_MANUALMENTE = 'IB-IBM',
  INCLUIR_BENEFICIARIO_GERAR_LINK = 'IB-IBL',
  INCLUIR_BENEFICIARIO_POR_LOTE = 'IB-IBO',
  GERENCIAR_USUARIOS = 'GU',
  ADICIONAR_NOVO_USUARIO = 'GU-IUS',
  WALLET = 'CA',
}
