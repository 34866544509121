import styled from 'styled-components';
import FormWrapper from '../../components/FormWrapper';

export const Container = styled.div`
  margin-bottom: 30px;

  @media (min-width: 769px) and (max-width: 1199px) {
    grid-column: 1/11;
    padding: 0 30px;
  }
  @media (min-width: 1200px) {
    grid-column: 2/10;
  }
`;

export const TextAuthorization = styled.div`
  p,
  span {
    font-family: 'Unimed-Sans-Book' !important;
    font-size: 1rem !important;
    color: ${(props) => props.theme.colors.text.primary} !important;
    color: ${(props) => props.theme.colors.text.grayishToWhite} !important;
  }
  b,
  u {
    font-family: 'Unimed-Slab-Bold' !important;
  }

  li {
    list-style: none;

    span {
      font-family: 'Unimed-Sans-Book' !important;
      color: ${(props) => props.theme.colors.text.grayishToWhite} !important;
    }
  }
`;

export const Title = styled.h1`
  font-family: 'Unimed-Slab-Bold';
  font-size: 2.55rem;
  line-height: 2.4rem;
  text-align: center;
  letter-spacing: -1.19px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional};
  padding: 30px 0;
  @media (max-width: 1200px) {
    font-size: 2.25rem;
    line-height: 2.1rem;
  }
  @media (max-width: 769px) {
    font-size: 1.7rem;
    line-height: 1.5rem;
    padding: 30px;
  }
`;

export const DebtAccountForm = styled(FormWrapper)`
  display: flex;
  flex-wrap: wrap;

  label {
    color: ${(props) => props.theme.colors.text.grayishToWhite} !important;
  }

  > button {
    margin-left: auto;
  }
  @media (max-width: 768px) {
    > div {
      &:nth-child(7),
      &:nth-child(10) {
        width: calc(40% - 8px);
        flex: 0 1 calc(40% - 8px);
      }

      &:nth-child(6),
      &:nth-child(9) {
        width: 60%;
        flex: 0 1 60%;
      }
    }
  }
`;
