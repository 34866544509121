/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';
import { useTheme } from 'styled-components';
import { v4 } from 'uuid';
import { ptBRToDate } from '../../utils/formatt';
import { ChartContainer, UlPointGraphic } from './styles';

interface lossRatioDataProps {
  dateOfCompetence: string;
  realLossRate: number;
  idealLossRate: number;
}

interface allLossRatioDataProps {
  allLossratioDatas: lossRatioDataProps[];
  lossRatioGoal: number;
}

const LossRatioEvolutionGraphic: React.FC<allLossRatioDataProps> = ({
  allLossratioDatas,
}) => {
  const { colors } = useTheme();
  const toPercent = (decimal: number) => `${decimal}%`;

  const renderTooltipContent = useCallback((o: any) => {
    const { payload, label } = o;
    return (
      <div className="customized-tooltip-content">
        <UlPointGraphic className="list">
          {payload.map((entry: any) => (
            <React.Fragment key={`item-${v4()}`}>
              <li style={{ color: entry.color }}>
                {`${entry.name}: ${entry.value}%`}
              </li>
              {entry.name === 'Meta de Sinistralidade' && <li>{`${label}`}</li>}
            </React.Fragment>
          ))}
        </UlPointGraphic>
      </div>
    );
  }, []);

  return (
    <ChartContainer>
      <LineChart
        data={allLossratioDatas.sort((i, j) => {
          const dateI = ptBRToDate(i.dateOfCompetence);
          const dateJ = ptBRToDate(j.dateOfCompetence);
          if (dateI.getTime() > dateJ.getTime()) {
            return 1;
          }
          if (dateI.getTime() < dateJ.getTime()) {
            return -1;
          }
          return 0;
        })}
      >
        <Line
          type="monotone"
          dataKey="realLossRate"
          stroke={colors.palet.institutional3}
          width={730}
          height={250}
          name="Sinistralidade Real"
        />
        <Line
          type="monotone"
          dataKey="idealLossRate"
          stroke="red"
          width={730}
          height={250}
          name="Meta de Sinistralidade"
        />

        <CartesianGrid stroke="#ccc" strokeDasharray="0 0" />
        <Legend verticalAlign="top" height={36} />

        <XAxis angle={-15} interval={2} dy={3} dataKey="dateOfCompetence" />
        <YAxis domain={[0, 100]} tickFormatter={toPercent} height={100} />
        <Tooltip content={renderTooltipContent} />
      </LineChart>
    </ChartContainer>
  );
};

export default LossRatioEvolutionGraphic;
