export default {
  title: 'light',

  colors: {
    background: '#fff',

    text: {
      normal: '#000',
      black: '#000',
      blackToWhite: '#000',
      primary: '#5B5C65',
      grayishToWhite: '#5B5C65',
      grayishToBlack: '#5B5C65',
      grayishToLightGray: '#5B5C65',
      secondary: '#C4CBCF',
      lightGrayToBlack: '#C4CBCF',
      grayToHeavyGray: '#EAEDEF',
      grayToHeavyGrayish: '#EAEDEF',
      grayToGrayish: '#EAEDEF',
      white: '#fff',
      inverse: '#fff',
      whiteToGrayish: '#fff',
      whiteToHeavyGray: '#fff',
      whiteToBlack: '#fff',
    },
    palet: {
      institutional: '#00995D',
      institutional2: '#00401A',
      institutional3: '#0A5F55',
      institutional4: '#B1D34B',
      institutional5: '#FFF0C7',
      institutional6: '#F47920',
      institutional7: '#ED1651',
      institutional8: '#411564',
      institutional9: '#A3238E',
      institutional10: '#0A5F55',
      institutional11: '#5B5C65',
      institutional12: '#C4CBCF',
      institutional13: '#EAEDEF',
      institutional14: '#F4F6F6',
      institutional15: '#4F1BBE',
    },
  },
};
