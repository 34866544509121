/* eslint-disable no-param-reassign */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useCallback,
  ChangeEvent,
} from 'react';

import { IconBaseProps } from 'react-icons';

import { useField } from '@unform/core';
import { mask, unMask } from 'remask';
import {
  Container,
  ContainerCheck,
  Error,
  InsideInputTitle,
  Title,
  ContainerLabel,
  Label,
} from './styles';
import { removeDiacritics } from '../../utils/formatt';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  isValid?: boolean;
  label?: string | JSX.Element;
  className?: string;
  containerClassName?: string;
  masks?: string[];
  insideInputTitle?: string;
  title?: string;
  widthContainerDesktop?: string;
  formLook?: boolean;
  disabled?: boolean;
  themeColor?: string;
  noSpecialChars?: boolean;
}

const Input: React.FC<InputProps> = ({
  name,
  label,
  type,
  isValid,
  title,
  insideInputTitle,
  masks,
  className,
  containerClassName,
  widthContainerDesktop,
  formLook,
  disabled = false,
  themeColor,
  hidden,
  noSpecialChars,
  value: valueOriginal,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const areaRef = useRef<HTMLTextAreaElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);
  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      clearError();
      const specialChar = removeDiacritics(ev.target.value);
      if (!masks) {
        if (inputRef.current) {
          if (noSpecialChars) {
            inputRef.current.value = specialChar;
          } else {
            inputRef.current.value = ev.target.value;
          }
        }
        return;
      }
      const originalValue = unMask(ev.target.value);
      const maskedValue = mask(originalValue, masks);
      if (inputRef.current) {
        inputRef.current.value = maskedValue;
      }
    },
    [clearError, masks, noSpecialChars],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: type !== 'textarea' ? inputRef : areaRef,
      getValue: (ref) => {
        if (type === 'checkbox') {
          return ref.current.checked;
        }
        if (masks && ref.current.value) {
          return unMask(ref.current.value, masks);
        }
        return ref.current.value;
      },
      setValue: (ref, value) => {
        if (type === 'checkbox') {
          ref.current.checked = value;
          return;
        }
        if (masks?.length && value) {
          ref.current.value = mask(value, masks);
          if (spanRef.current) {
            spanRef.current.innerHTML = mask(value, masks);
          }
          return;
        }
        if (spanRef.current) {
          spanRef.current.innerHTML = String(value);
        }
        ref.current.value = value;
      },
      clearValue: (ref) => {
        if (type === 'checkbox') {
          ref.current.checked = false;
          return;
        }
        ref.current.value = '';
      },
    });
  }, [clearError, fieldName, masks, registerField, type]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.disabled = disabled;
    }
  }, [disabled]);

  if (type === 'checkbox') {
    return (
      <ContainerLabel className={className}>
        <ContainerCheck>
          <input
            ref={inputRef}
            defaultValue={defaultValue}
            type={type}
            value={valueOriginal}
            {...rest}
          />
        </ContainerCheck>
        {label}
      </ContainerLabel>
    );
  }

  return (
    <Label
      widthContainerDesktop={widthContainerDesktop}
      formLook={formLook}
      className={containerClassName}
      hidden={hidden}
    >
      {title && <Title themeColor={themeColor}>{title}</Title>}
      <Container
        isErrored={!!error}
        isValid={isValid}
        className={className}
        dataSheet={!!insideInputTitle}
        formLook={formLook}
        disabled={disabled}
        textArea={type === 'textarea'}
      >
        {insideInputTitle && (
          <InsideInputTitle themeColor={themeColor}>
            {insideInputTitle}
          </InsideInputTitle>
        )}

        {type !== 'textarea' && (
          <>
            <input
              ref={inputRef}
              defaultValue={
                // eslint-disable-next-line no-nested-ternary
                masks
                  ? defaultValue
                    ? mask(defaultValue, masks)
                    : defaultValue
                  : defaultValue
              }
              name={name}
              type={type}
              onChange={handleChange}
              // disabled={disabled || inputRef.current?.disabled}
              value={valueOriginal}
              {...rest}
              style={insideInputTitle ? { display: 'none' } : {}}
            />
            {!!insideInputTitle && <span ref={spanRef}>{valueOriginal}</span>}
          </>
        )}
        {type === 'textarea' && (
          <textarea
            ref={areaRef}
            defaultValue={defaultValue}
            // disabled={disabled || inputRef.current?.disabled}
            value={valueOriginal}
            maxLength={rest.maxLength}
            name={name}
          />
        )}
        {error && (
          <Error>
            <span dangerouslySetInnerHTML={{ __html: error }} />
          </Error>
        )}
      </Container>
    </Label>
  );
};

export default Input;
