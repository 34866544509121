/* eslint-disable @typescript-eslint/no-explicit-any */
interface ResponseGetParamsProps {
  [key: string]: string;
}

/**
 * Get all params in the url
 * @returns Object containing valus in params url
 */
export const getParams = (): ResponseGetParamsProps => {
  const allSearch = window.location.search
    .substr(1)
    .split('&')
    .reduce((acc, act) => {
      const [index, value] = act.split('=');
      acc[index] = value;
      return acc;
    }, {} as ResponseGetParamsProps);
  return allSearch;
};

/**
 *
 * @param obj Object to convert in url params
 * @returns url encoded
 */
export const ObjectToUrlParams = (
  obj: { [s: string]: unknown } | ArrayLike<unknown>,
): string => {
  return Object.entries(obj).reduce((acc, act) => {
    const newString = `${acc}${act[0]}=${act[1]}&`;
    return newString;
  }, '?');
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getParam = (url: string) => {
  const params = url.split('?')[1];
  const paramsSeparated = params.split('&');
  const temp = [] as any;
  paramsSeparated.forEach((p) => {
    const [key, value] = p.split('=');
    temp[key] = value;
  });
  return temp;
};
