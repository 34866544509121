import { ResponsiveContainer } from 'recharts';
import styled from 'styled-components';

export const ChartContainer = styled(ResponsiveContainer)`
  flex: 1;
  height: 300px !important;

  @media (max-width: 768px) {
    /* width: calc(100% - 60px) !important; */
    > div {
      transform: translateX(-5vw);
    }
  }

  .color-referenceline {
    tspan {
      color: green;
    }
  }
  .recharts-surface {
    overflow: visible;
  }
`;

export const UlPointGraphic = styled.ul`
  border: 1px solid gray;
  padding: 10px;
  background: #fff;
  border-radius: 3px;
  li {
    display: flex;
    flex-direction: column;
    color: gray;
  }
`;
