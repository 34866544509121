import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import PageWrapper from '../../components/PageWrapper';
import {
  Container,
  Title,
  GenerateIncomeContainer,
  GenerateButton,
  YearSelect,
} from './styles';
import api from '../../services/api/api';
import Modal2 from '../../components/Modal2';
import { useAuth } from '../../hooks/auth';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';
import Button from '../../components/Button';

const IncomeTaxReturn: React.FC = () => {
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [years, setYears] = useState([
    {
      title: '',
      value: '',
    },
  ]);

  const [selectedYear, setSelectedYear] = useState<string>();

  const handleChangeYearSelected = useCallback((value) => {
    setSelectedYear(value);
  }, []);

  const getYears = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-year-select');
      const { data } = await api.get('/report/base-year-parameter');
      const { content } = data;
      setYears(
        content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      );
      setSelectedYear(content[0].id);
    } catch (error) {
      if (error?.response?.status === 400) {
        Notiflix.Notify.info(error.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Não foi possível buscar pelos anos. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-year-select');
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      if (!user.payerNumber) {
        Modal2.Failure({
          closable: true,
          title: 'Ops...',
          text: 'Apenas o pagante pode acessar este recurso.',
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });
        return;
      }
      Notiflix.Block.circle('.loading-button');
      const { data } = await api.get(
        `/report/list-of-beneficiary-reports?contractId=${user.id}&exhibitionLocation=6`,
      );
      const { nrSeqRelatorio, Parametros: P } = data.content[0];
      const params = [user.payerNumber, selectedYear];
      // const params = [135337, 2020];
      const body = {
        nrSeqRelatorio: nrSeqRelatorio ?? '',
        Parametros: [
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...P.map((p: { cdParametro: any }, index: any) => {
            return {
              id: p.cdParametro,
              valor: params[index],
            };
          }),
        ],
      };

      const { data: dataReport } = await api.post(
        `/report/generate-report`,
        body,
        {
          responseType: 'blob',
        },
      );
      downloadFileOfBlob(dataReport);
    } catch (err) {
      if (err?.response?.status === 400) {
        Notiflix.Notify.info(err.response.data?.message);
      } else {
        Notiflix.Notify.failure(
          'Não conseguimos buscar seu relatório. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.circle('.loading-button');
      Notiflix.Block.remove('.loading-button');
    }
  }, [selectedYear, user]);

  useEffect(() => {
    getYears();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper gridTemplateColumns="repeat(10,1fr)" gridTemplateRows="400px">
      <Container>
        <Title>Declaração de imposto de renda</Title>

        <GenerateIncomeContainer formLook onSubmit={handleSubmit} ref={formRef}>
          <YearSelect
            className="notiflix-year-select"
            name="baseYear"
            title="Ano base:"
            changeCallback={handleChangeYearSelected}
            options={years}
            formLook
            liveReload
          />
          <GenerateButton
            type="submit"
            formLook
            greenPrimary
            className="loading-button"
          >
            Gerar
          </GenerateButton>
        </GenerateIncomeContainer>
      </Container>
    </PageWrapper>
  );
};

export default IncomeTaxReturn;
