import React, { useCallback, useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import { useHistory } from 'react-router-dom';
import Title from '../../../components/Title';
import { useTheme } from '../../../hooks/theme';
import {
  Container,
  ContentBox,
  IconBackground,
  Content,
  ModalWrapper,
  Message,
  ButtonGoBack,
} from './styles';
import PageWrapper from '../../../components/PageWrapper';
import api from '../../../services/api/api';
import Modal2 from '../../../components/Modal2';
import Button from '../../../components/Button';

interface TypeOfInjuryProp {
  id: number;
  image: string;
  text: string;
  title: string;
  icon: string;
}

const TypeOfInjuries: React.FC = () => {
  const { theme } = useTheme();
  const [typesOfInjuries, setTypesOfInjuries] = useState<TypeOfInjuryProp[]>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const getTypesOfInjuries = useCallback(async () => {
    try {
      setLoading(true);
      Notiflix.Block.circle('.notiflix-loading-type-injury', 'Carregando', {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
      });
      const { data } = await api.get(`/injury-type/list`);
      setTypesOfInjuries(data.content);
      setLoading(false);
    } catch (err) {
      if (err?.response?.status === 400) {
        Notiflix.Notify.info(err.response.data?.message);
      } else {
        Notiflix.Notify.failure(
          'Não conseguimos buscar sua orientação nutricional. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-loading-type-injury');
    }
  }, []);

  const viewTypeOfInjury = useCallback((injuryItem) => {
    // Notiflix.Block.circle(notiflix);
    // const { data } = await api.get(
    //   `financial/beneficiary-invoice-barcode?titleNumber=${titleNumber}`,
    // );
    Modal2.Generic({
      closable: true,
      children: (
        <ModalWrapper>
          <div>
            <Title>{injuryItem.title}</Title>
            {injuryItem.image && <img src={injuryItem.image} alt="" />}
            <span dangerouslySetInnerHTML={{ __html: injuryItem.text }} />
          </div>
          <Button
            type="submit"
            className="btn2"
            onClick={() => {
              Modal2.Close();
            }}
          >
            Fechar
          </Button>
        </ModalWrapper>
      ),
    });
  }, []);

  useEffect(() => {
    getTypesOfInjuries();
  }, []);

  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <Container>
        <Title themeColor={theme.colors.palet.institutional2}>
          Tipos de Lesões
        </Title>
        <ButtonGoBack onClick={() => history.push('/ambulatorio-curativos')}>
          Voltar
        </ButtonGoBack>
        {!typesOfInjuries && !loading && (
          <Message>Não há tipos de lesões a serem exibidas</Message>
        )}
        <Content className="notiflix-loading-type-injury">
          {typesOfInjuries?.map((item) => {
            return (
              <ContentBox
                key={item.id}
                onClick={() => {
                  viewTypeOfInjury(item);
                }}
              >
                <IconBackground>
                  <img src={item.icon} alt="" />
                </IconBackground>
                <h2>{item.title}</h2>
              </ContentBox>
            );
          })}
        </Content>
      </Container>
    </PageWrapper>
  );
};

export default TypeOfInjuries;
