import styled from 'styled-components';
import TableComponent from '../../components/Table';

export const Container = styled.section`
  grid-column: 2/10;
  @media (max-width: 768px) {
    grid-column: 1;
    margin: 30px 0px;
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & form {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
`;

export const FormContainer = styled.div`
  display: grid;
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Table = styled(TableComponent)`
  grid-column: 2/10;
  @media (max-width: 768px) {
    grid-column: 1;
    margin: 0;
    margin-bottom: 30px;
  }
  td.clicable {
    cursor: pointer;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

interface IconButtonProps {
  backgroundColor: string;
}

export const IconButton = styled.button<IconButtonProps>`
  all: unset;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
  padding: 6px;
  color: #fff;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${({ theme }) => theme.colors.palet.institutional12};
  }
`;

export const AppoitmentModalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  grid-row-gap: 1rem;
  max-width: 750px;
`;

export const AppointmentInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  padding: 4px;
  border-radius: 4px;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.palet.institutional11};
  font-family: 'Unimed-Sans-Book';
  margin-bottom: 8px;

  flex: 1 0 100%;
  &.w-full {
    flex: 1 0 100%;
  }

  & .label {
    font-weight: 600;
    font-size: 0.8rem;
  }

  & .info {
    font-size: 1.6rem;
    font-weight: 300;
  }

  @media (min-width: 1024px) {
    flex: 1 0 50%;
  }
`;

export const ColumnLink = styled.a`
  all: unset;
  color: ${({ theme }) => theme.colors.palet.institutional11};
  text-align: center;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.palet.institutional12};
  }
`;

export const DeleteFormContainer = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 32px;
  max-width: 800px;
  flex-direction: column;
`;
