import styled, { css } from 'styled-components';

interface DropdownColapse {
  height: number;
}

export const Container = styled.div`
  width: 100%;
  position: relative;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  & + div {
    margin-top: 8px;
  }
  & > button {
    outline: none;
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-family: 'Unimed-Sans-Book';
    color: ${(props) => props.theme.colors.text.grayishToWhite};

    svg {
      transform-origin: center;
      transition: 300ms;
      &.open {
        transform: rotate(180deg);
      }
    }
  }
`;

export const Content = styled.div<DropdownColapse>`
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: hidden;
  transition: 300ms;
  &.open {
    ${(props) =>
      css`
        height: ${props.height}px;
      `}
  }
`;
