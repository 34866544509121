import styled from 'styled-components';
import TableComponent from '../../components/Table';
import FormWrapperComponent from '../../components/FormWrapper';
import { TitleIntern as TitleInternComponent } from '../../components/Title';

export const Container = styled.div`
  /* grid-column: 2/3; */
  grid-column: 1;
  @media (max-width: 768px) {
    grid-column: 1;
  }
  /* display: grid;
  row-gap: 24px; */
`;

export const Table = styled(TableComponent)`
  margin-top: 24px;
  clear: both;
`;

export const TitleIntern = styled(TitleInternComponent)`
  /* margin-bottom: 24px; */
`;

export const ModalContainer = styled.div`
  h2 {
    font-family: 'Unimed-Sans-SemiBold';
    color: ${(props) => props.theme.colors.text.primary};
  }

  display: flex;
  flex-direction: column;
  position: relative;

  &.report {
    width: 320px;
    h2 {
      margin-bottom: 24px;
    }
    form {
      & + form {
        margin-top: 24px;
      }
      > button {
        margin-top: 24px;
      }
    }
  }
`;

export const FormWrapper = styled(FormWrapperComponent)`
  display: flex;
  flex-wrap: wrap;

  &.mt-24 {
    margin-top: 24px;
  }
  &.mb-24 {
    margin-bottom: 24px;
  }
  &.wd-70 {
    width: calc(70% - 4px);
    float: left;
    @media (max-width: 768px) {
      width: 100%;
      float: none;
    }
  }
  &.wd-30 {
    width: calc(30% - 4px);
    float: right;
    @media (max-width: 768px) {
      width: 100%;
      float: none;
    }
  }
  &.mob-mt-24 {
    @media (max-width: 768px) {
      margin-top: 24px;
    }
  }
  &.hiring-form {
    > button {
      margin-left: auto;
    }
  }
  &.form-attachments {
    > button {
      margin-left: auto;
    }
    @media (min-width: 769px) {
      align-items: flex-end;
      > div {
        flex: 1;
        margin-right: 16px;
      }
    }
  }
  &.clear-both {
    clear: both;
  }
`;

export const ChangeHiringDateActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 8px;
  row-gap: 8px;
  justify-content: center;
`;
