import React, {
  memo,
  useState,
  useCallback,
  useRef,
  MouseEvent,
  useLayoutEffect,
  useMemo,
} from 'react';

import { Link, useHistory } from 'react-router-dom';
import { NavDropdownContainer, NavCollapse, IconDrop } from './styles';

interface NavDropdownProps {
  title: string;
  titleUrl?: string;
  links: {
    url: string;
    title: string;
  }[];
}

const NavDropdown: React.FC<NavDropdownProps> = ({
  links,
  title,
  titleUrl,
}) => {
  const [calculatedHeight, setCalculatedHeight] = useState(0);
  const collapseRef = useRef<HTMLDivElement>(null);
  const buttonDispathRef = useRef<HTMLButtonElement>(null);

  const actualLocale = window.location.pathname
    .split('/')
    .slice(0, 2)
    .join('/');

  const activeLink = links.some((i) => actualLocale === i.url);

  const history = useHistory();

  const closeParents = useCallback((current: HTMLButtonElement) => {
    document.querySelectorAll('[data-navdropdown]')?.forEach((otherButton) => {
      if (otherButton !== current) {
        otherButton.children[0].classList.remove('open');
        otherButton.nextElementSibling?.classList.remove('open');
      }
    });
  }, []);

  const handleToggleCollapse = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (titleUrl) {
        history.push(titleUrl);
      }

      if (collapseRef.current?.classList.contains('open')) {
        collapseRef.current?.classList.remove('open');
        event.currentTarget.children[0].classList.remove('open');
      } else {
        collapseRef.current?.classList.add('open');
        event.currentTarget.children[0].classList.add('open');
        if (collapseRef.current) {
          setCalculatedHeight(collapseRef.current.scrollHeight);
        }
      }
      closeParents(event.currentTarget);
    },
    [closeParents, history, titleUrl],
  );

  useLayoutEffect(() => {
    if (collapseRef.current) {
      setCalculatedHeight(collapseRef.current.scrollHeight);
    }
  }, []);

  const allLinks = useMemo(() => {
    return (
      <>
        {links.map(
          (link) =>
            link && (
              <Link
                to={link.url}
                key={link.url}
                className={`${actualLocale === link.url && 'active'}`}
              >
                {link.title}
              </Link>
            ),
        )}
      </>
    );
  }, [actualLocale, links]);

  return (
    <NavDropdownContainer className={`${activeLink && 'active'}`}>
      <button
        type="button"
        onClick={handleToggleCollapse}
        ref={buttonDispathRef}
        data-navdropdown
      >
        {title}
        <IconDrop className={`${activeLink && 'open'}`} />
        {/* <IconDrop /> */}
      </button>
      <NavCollapse
        height={calculatedHeight}
        ref={collapseRef}
        className={`${activeLink && 'open'}`}
      >
        {allLinks}
      </NavCollapse>
    </NavDropdownContainer>
  );
};

export default memo(NavDropdown);
