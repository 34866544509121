import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface ColorsProps {
  greenButton?: boolean;
  whiteButton?: boolean;
  blueButton?: boolean;
  grayButton?: boolean;
  orangeButton?: boolean;
  purpleButton?: boolean;
  purple2Button?: boolean;
  greenCriticalButton?: boolean;
  outlined?: boolean;
  secondary?: boolean;
  inverse?: boolean;
  greenPrimary?: boolean;
  noChangeColor?: boolean;
}
export interface ContainerProps extends ColorsProps {
  modal?: boolean;
  // disabled?: boolean;
  autoWidth?: boolean;
  float?: 'right' | 'left';
  sausageLook?: boolean;
  formLook?: boolean;
  marginLeft?: boolean;
}

const defineColor = (color: ColorsProps) => {
  if (color.blueButton) {
    return css``;
  }
  if (color.grayButton) {
    return css`
      background: ${(props) => props.theme.colors.palet.institutional11};
      border-color: ${(props) => props.theme.colors.text.grayishToWhite};
      color: ${(props) => props.theme.colors.text.white};
      font-size: 1rem;
      text-transform: uppercase;
      &:hover {
        border-color: ${(props) => props.theme.colors.text.whiteToHeavyGray};
        box-shadow: 0px 0px 8px 0
          ${(props) => shade(0.005, props.theme.colors.palet.institutional11)};
      }
    `;
  }
  if (color.greenButton) {
    return css`
      background: ${(props) => props.theme.colors.palet.institutional3};
      border: ${(props) => props.theme.colors.palet.institutional10};
      border-radius: 5px;
      color: #fff;
    `;
  }
  if (color.greenCriticalButton) {
    return css``; // default color
  }
  if (color.orangeButton) {
    return css`
      border: 1px solid ${(props) => props.theme.colors.palet.institutional6};
      background: ${(props) => props.theme.colors.palet.institutional6};
      &:hover {
        box-shadow: 0px 0px 8px 0
          ${(props) => shade(0.05, props.theme.colors.palet.institutional6)};
      }
    `;
  }
  if (color.purpleButton) {
    return css`
      border: 1px solid ${(props) => props.theme.colors.palet.institutional9};
      background: ${(props) => props.theme.colors.palet.institutional9};
      &:hover {
        box-shadow: 0px 0px 8px 0
          ${(props) => shade(0.05, props.theme.colors.palet.institutional9)};
      }
    `;
  }
  if (color.purple2Button) {
    return css`
      color: #fff;
      border: 1px solid ${(props) => props.theme.colors.palet.institutional8};
      background: ${(props) => props.theme.colors.palet.institutional8};
      &:hover {
        box-shadow: 0px 0px 8px 0
          ${(props) => shade(0.05, props.theme.colors.palet.institutional8)};
      }
    `;
  }
  if (color.whiteButton) {
    return css`
      background: ${(props) => props.theme.colors.background};
      color: ${(props) => props.theme.colors.text.primary};
      border-color: ${(props) => props.theme.colors.background};
      &:hover {
        background: ${(props) => props.theme.colors.palet.institutional4};
        color: ${(props) => props.theme.colors.palet.institutional2};
        border-color: ${(props) => props.theme.colors.palet.institutional4};
        box-shadow: none;
      }
    `;
  }
  if (color.secondary) {
    return css`
      background-color: ${(props) => props.theme.colors.palet.institutional13};
      border-color: ${(props) => props.theme.colors.palet.institutional13};
      /* color: ${(props) => props.theme.colors.text.inverse}; */
      color: ${(props) => props.theme.colors.palet.institutional11};
      font-size: 1rem;
      &:hover {
        box-shadow: 0px 0px 8px 0
          ${(props) => shade(0.05, props.theme.colors.palet.institutional13)};
      }
    `;
  }
  if (color.inverse) {
    return css`
      background: #fff;
      border-color: #fff;
      color: ${(props) => props.theme.colors.text.black};
      &:hover {
        color: ${(props) => props.theme.colors.palet.institutional2};
        background: ${(props) => props.theme.colors.palet.institutional4};
        border-color: ${(props) => props.theme.colors.palet.institutional4};
        box-shadow: none;
      }
    `;
  }
  if (color.outlined) {
    return css`
      background: #fff;
      border-color: ${(props) => props.theme.colors.palet.institutional12};
      color: ${(props) => props.theme.colors.text.primary};
      font-size: 1rem;
      &:hover {
        border-color: ${(props) => props.theme.colors.palet.institutional12};
        box-shadow: 0px 0px 8px 0
          ${(props) => shade(0.05, props.theme.colors.palet.institutional12)};
      }
    `;
  }
  if (color.greenPrimary) {
    return css`
      background: ${(props) => props.theme.colors.palet.institutional};
      border: ${(props) => props.theme.colors.palet.institutional};
      border-radius: 5px;
      color: #fff;
    `;
  }
  return css``;
};

export const Container = styled.button<ContainerProps>`
  border-radius: 10px;
  min-width: ${(props) => !props.sausageLook && '150px'};
  padding: 8px;
  border: 1px solid ${(props) => props.theme.colors.palet.institutional4};
  background: ${(props) => props.theme.colors.palet.institutional4};
  color: ${(props) => props.theme.colors.text.inverse};
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 1.4rem;
  transition: 300ms;
  min-height: 48px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
  }

  &:hover {
    border-color: #fff;
    box-shadow: 0px 0px 8px 0
      ${(props) => shade(0.05, props.theme.colors.palet.institutional4)};
  }

  ${(props) => defineColor(props)}

  //modal
  ${(props) =>
    props.modal &&
    css`
      border-radius: 50px;
      padding: 8px 44px;
      font-size: 1rem;
    `}

  &[disabled] {
    cursor: not-allowed;
    background-color: ${(props) => props.theme.colors.palet.institutional11};
    border-color: ${(props) => props.theme.colors.palet.institutional11};
    color: ${(props) => props.theme.colors.palet.institutional12};
    &:hover {
      background-color: ${(props) => props.theme.colors.palet.institutional11};
      border-color: ${(props) => props.theme.colors.palet.institutional11};
      box-shadow: none;
      color: ${(props) => props.theme.colors.palet.institutional12};
    }
  }
  //disable
  /* ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: ${props.theme.colors.palet.institutional11};
      border-color: ${props.theme.colors.palet.institutional11};
      color: ${props.theme.colors.palet.institutional12};
      &:hover {
        background-color: ${props.theme.colors.palet.institutional11};
        border-color: ${props.theme.colors.palet.institutional11};
        box-shadow: none;
        color: ${props.theme.colors.palet.institutional12};
      }
    `}

  &.enable {
    ${(props) =>
    css`
      cursor: pointer;
      background-color: ${props.theme.colors.palet.institutional};
      border-color: ${props.theme.colors.palet.institutional};
      color: ${props.theme.colors.palet.institutional12};
      &:hover {
        background-color: ${props.theme.colors.palet.institutional};
        border-color: ${props.theme.colors.palet.institutional};
        box-shadow: none;
        color: ${props.theme.colors.palet.institutional};
      }
    `}
  } */

  //auto width
  ${(props) =>
    props.autoWidth &&
    css`
      width: auto;
    `}

  //margin left
  ${(props) =>
    props.marginLeft &&
    css`
      margin-left: auto;
    `}

    //float
  ${(props) =>
    props.float &&
    (props.float === 'left'
      ? css`
          float: left;
        `
      : css`
          float: right;
        `)}

    //sausuageLook
  ${(props) =>
    props.sausageLook &&
    css`
      padding: 2px 18px;
      font-size: 0.8rem;
      min-height: auto;
      white-space: nowrap;
      text-transform: capitalize;
    `}
    ${(props) =>
    props.sausageLook &&
    props.theme.title === 'dark' &&
    !props.noChangeColor &&
    defineColor({ inverse: true })}

//formLook
${(props) =>
    props.formLook &&
    css`
      font-size: 1rem;
      height: 30px;
      min-height: 30px;
      padding: 8px 12px;
      min-height: auto;
      white-space: nowrap;
      border-radius: 154px;
      height: 36px;
    `}
`;
