import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.text.whiteToBlack};
  padding: 20px;
  box-shadow: 0px 5px 10px rgba(196, 203, 207, 0.1);
  ${(props) =>
    props.theme.title === 'dark' &&
    css`
      box-shadow: 0px 2px 5px rgba(24, 24, 24, 0.6);
    `}
  border-radius: 10px;
  display: flex;

  & + div {
    margin-top: 8px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (min-width: 769px) {
    align-items: center;
  }
  &.beneficiary-button {
  }
`;

export const Title = styled.h3`
  font-family: 'Unimed-Sans-Bold';
  font-size: 1.8rem;
  line-height: 1.9rem;
  width: 45%;
  display: flex;
  align-items: center;
  position: relative;

  svg {
    display: none;
    @media (max-width: 768px) {
      display: initial;
      margin-left: 8px;
      transition: 300ms;
      position: absolute;
      right: 0;
    }
  }

  p {
    margin-left: 8px;
    @media (min-width: 769px) {
      margin-left: 0px;
      position: absolute;
      font-size: 1rem;
      bottom: 0;
      transform: translateY(100%);
      font-family: 'Unimed-Sans-Book';
    }
    @media (max-width: 768px) {
      font-size: 0.55rem;
      line-height: 0.55rem;
      align-self: flex-end;
    }
  }

  &.rotateSvg {
    svg {
      transform: rotate(180deg);
    }
  }
  @media (max-width: 768px) {
    font-size: 1.55rem;
    line-height: 1.1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
  }
`;

interface OptionsProps {
  colorHover?: string;
  calculatedHeight?: number;
}

export const Options = styled.div<OptionsProps>`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  a {
    text-decoration: none;
    font-family: 'Unimed-Sans-Book';
    font-size: 1rem;
    line-height: 1.7rem;
    flex: 1 0 100%;
    /* border-bottom: 1px solid
      ${(props) => props.theme.colors.palet.institutional13}; */
    transition: 300ms;

    color: ${(props) => props.theme.colors.text.grayishToWhite};

    position: relative;
    padding-left: 10px;
    &::before {
      content: '';
      width: 4px;
      height: 4px;
      background: ${(props) => props.theme.colors.text.grayishToWhite};
      position: absolute;
      top: 50%;
      left: 0;
      border-radius: 50%;
      transform: translateY(-50%);
      transition: 300ms;
    }
    &::after {
      content: attr(title);
      position: absolute;
      bottom: 0;
      left: 8px;
      border-bottom: 1px solid
        ${(props) => props.theme.colors.text.grayToHeavyGray};
      color: transparent;
      pointer-events: none;
      @media (max-width: 768px) {
        left: 0px;
        width: 100%;
      }
    }

    &:hover {
      color: ${(props) => props.colorHover};
      &::before {
        background: ${(props) => props.colorHover};
      }
    }
    @media (max-width: 768px) {
      text-align: center;
      padding-left: 0;
      line-height: 2.5rem;
      &::before {
        content: initial;
      }
      &::after {
        display: none;
      }
    }
    &.full-line {
      width: 100%;
      flex: 1 0 100% !important;
      margin-bottom: 8px;
      @media (max-width: 768px) {
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        order: 1;
        margin-bottom: 0px;
        margin-top: 8px;
        color: ${(props) => props.theme.colors.palet.institutional3};
      }
    }
  }
  > p {
    text-decoration: none;
    font-family: 'Unimed-Sans-Book';
    font-size: 0.78rem;
    line-height: 1.21rem;
    flex: 1 0 50%;
    /* border-bottom: 1px solid
      ${(props) => props.theme.colors.palet.institutional13}; */
    transition: 300ms;

    color: ${(props) => props.theme.colors.text.grayishToWhite};

    position: relative;
    padding-left: 10px;
    @media (max-width: 768px) {
      flex: 1 0 100%;
      font-size: 0.85rem;
      text-align: center;
      padding-left: 0;
      line-height: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 768px) {
    overflow: hidden;
    height: 0px;
    flex: none;
    transition: 300ms;
    &.open {
      margin-top: 16px;
      height: ${(props) => props.calculatedHeight}px;
    }
  }
`;
