import React, { useCallback, useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import { useHistory } from 'react-router-dom';
import Title from '../../../components/Title';
import { useTheme } from '../../../hooks/theme';
import {
  Container,
  Content,
  MetaCareTitle,
  MetaCareBody,
  Dropdown,
  Message,
  Observation,
  ButtonGoBack,
  Row,
  Col,
} from './styles';
import PageWrapper from '../../../components/PageWrapper';
import api from '../../../services/api/api';

interface MetaCareProp {
  data: [
    {
      Campos: [
        {
          dsCampo: string;
          dsConteudo: string;
          dsTipoCampo: string;
        },
        {
          dsCampo: string;
          dsConteudo: string;
          dsTipoCampo: string;
        },
      ];
      dsTitulo: string;
      dtInformacao: string;
    },
  ];
  message: string;
  result: number;
}

const MetaCare: React.FC = () => {
  const { theme } = useTheme();
  const [metaCare, setMetaCare] = useState<MetaCareProp>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const getNutritional = useCallback(async () => {
    try {
      setLoading(true);
      Notiflix.Block.circle('.notiflix-loading-meta-care', 'Carregando', {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
      });
      const { data } = await api.get(`/first-aid/curative-meta-care`);
      setMetaCare(data.content);
      setLoading(false);
    } catch (err) {
      if (err?.response?.status === 400) {
        Notiflix.Notify.info(err.response.data?.message);
      } else {
        Notiflix.Notify.failure(
          'Não conseguimos buscar sua orientação nutricional. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-loading-meta-care');
    }
  }, []);

  useEffect(() => {
    getNutritional();
  }, []);

  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <Container>
        <Title themeColor={theme.colors.palet.institutional2}>
          Meta de cuidado
        </Title>
        <Col className="alignEnd">
          <ButtonGoBack onClick={() => history.push('/ambulatorio-curativos')}>
            Voltar
          </ButtonGoBack>
        </Col>
        {metaCare?.data && !loading && (
          <Col className="alignCenter">
            <Observation>
              Clique nos botões abaixo para expandir e visualizar
            </Observation>
          </Col>
        )}
        <Content className="notiflix-loading-meta-care">
          {metaCare?.data && (
            <>
              {metaCare?.data.map((item, i) => {
                return (
                  <>
                    <Dropdown
                      className={`notiflix-meta-care-${item.dsTitulo}`}
                      key={item.dsTitulo}
                      titleJSX={
                        <MetaCareTitle>
                          <h2>{item.dsTitulo}</h2>
                          <p>{item.dtInformacao}</p>
                        </MetaCareTitle>
                      }
                      body={
                        <>
                          <MetaCareBody>
                            <h2>{item.dsTitulo}</h2>

                            {item.Campos && (
                              <ul>
                                {item.Campos.map((subItem, index) => {
                                  return (
                                    <li>
                                      {subItem.dsCampo} &nbsp;
                                      {subItem.dsConteudo}
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </MetaCareBody>
                        </>
                      }
                    />
                  </>
                );
              })}
            </>
          )}
          {!metaCare?.data && !loading && (
            <Message>Você não possui dados de meta de cuidado</Message>
          )}
        </Content>
      </Container>
    </PageWrapper>
  );
};

export default MetaCare;
