import React, { useCallback, useRef, useState, useEffect } from 'react';
import Notiflix from 'notiflix';
import Button from '../../components/Button';

import FormWrapper from '../../components/FormWrapper';
import Modal2 from '../../components/Modal2';
import Select, { SelectHandles } from '../../components/Select';
import { EnterpriseUser, useAuth, UserCommon } from '../../hooks/auth';
import api from '../../services/api/api';
import Translation from '../../services/api/Translation';

import { Term } from './styles';

interface RequestCopyCardProps {
  defaultColor: string;
  callbackSetStep: (prev: number) => void;
  rowsSelecteds: any[];
}

export function RequestCopyCard({
  defaultColor,
  callbackSetStep,
  rowsSelecteds,
}: RequestCopyCardProps): JSX.Element {
  const { user: userT } = useAuth();
  const user = userT as UserCommon & EnterpriseUser;
  const reasonRef = useRef<SelectHandles>(null);
  const [reasonOptions, setReasonOptions] = useState([
    { title: 'Motivos', value: '' },
  ]);

  const handleSubmit = useCallback(async () => {
    if (!reasonRef.current?.value) {
      reasonRef.current?.setError('É necessário informar um motivo.');
      return;
    }
    reasonRef.current.setError('');
    try {
      Notiflix.Block.circle('.notiflix-request-card-copy-send-button');
      const reasonId = reasonRef.current.value;
      const { webUsername, contractId } = user;
      const { data } = await api.post('/company/request-second-copy-card', {
        userUnimedId: rowsSelecteds.map((item) => item.userUnimedId),
        reasonId,
        webUsername,
        contractId,
      });
      const { message } = data;
      Modal2.Success({
        closable: true,
        autoWidth: true,
        title: 'Perfeito!',
        text: message,
        children: (
          <Button modal onClick={() => Modal2.Close()}>
            Ok
          </Button>
        ),
      });
      callbackSetStep(1);
    } catch (err) {
      if (err.response?.data?.message) {
        Modal2.Failure({
          closable: true,
          autoWidth: true,
          title: 'Ooops...',
          text: err.response.data.message,
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos enviar sua solicitação ao servidor. Por favor, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-request-card-copy-send-button');
    }
  }, [callbackSetStep, rowsSelecteds, user]);

  const getReasonOptions = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-reason');
      const { data } = await api.get('/card/reason-to-get-a-new-card');
      const { content } = data;
      setReasonOptions(
        content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      );
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... não conseguimos buscar os motivos no servidor... Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-reason');
    }
  }, []);

  useEffect(() => {
    getReasonOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Term>
        <Translation tag="solicitacao-segunda-via-cartao" />
      </Term>

      <FormWrapper
        onSubmit={handleSubmit}
        formLook
        className="request-copy-card"
      >
        <Select
          name="reason"
          title="Motivo da via adicional:"
          ref={reasonRef}
          options={reasonOptions}
          className="notiflix-reason"
          liveReload
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="40%"
        />
        <Button secondary autoWidth formLook onClick={() => callbackSetStep(1)}>
          Voltar
        </Button>
        <Button
          type="submit"
          greenButton
          autoWidth
          formLook
          className="notiflix-request-card-copy-send-button"
        >
          Solicitar
        </Button>
      </FormWrapper>
    </>
  );
}
