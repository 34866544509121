import styled from 'styled-components';
import DropdownElement from '../../../components/Dropdown';

export const Container = styled.div`
  margin-bottom: 30px;
  grid-column: 2/3;
  padding: 0 2em;
  @media (max-width: 768px) {
    grid-column: 1;
    /* padding: 0 1em; */
  }
  @media (min-width: 769px) and (max-width: 1600px) {
    grid-column: 1/4;
  }
`;

export const Observation = styled.p`
  text-align: center;
  font-family: 'Unimed-Sans-Book';
  color: ${(props) =>
    props.theme.title === 'light'
      ? props.theme.colors.text.primary
      : props.theme.colors.text.white};
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(1fr);
  /* grid-template-rows: repeat(2, 1fr); */
  column-gap: 8px;
  row-gap: 1em;
  width: 100%;
  padding: 3em 0;
  @media (max-width: 768px) {
    row-gap: 8px;
    padding: 1em 0;
  }
  @media (max-width: 320px) {
    grid-template-columns: 1fr;
  }
`;

export const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start !important;
  /* gap: 1em; */

  &.alignEnd {
    align-items: flex-end;
    margin: 1em 0;
  }

  &.alignCenter {
    align-items: center;
  }
`;

export const Dropdown = styled(DropdownElement)`
  background: ${(props) => props.theme.colors.palet.institutional11};
  border-color: ${(props) => props.theme.colors.palet.institutional11};
  box-shadow: 0px 5px 20px rgb(196 203 207 / 40%);

  overflow: hidden;
  padding: 0;
  > button {
    background: ${(props) => props.theme.colors.palet.institutional11};
    padding: 10px;
    box-shadow: 0 0 10px;
    > svg {
      color: ${(props) => props.theme.colors.text.white};
    }
  }
  background: ${(props) => props.theme.colors.background};
`;

export const NutritionalOrientationTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-right: 8px;
  font-family: 'Unimed-Sans-SemiBold';
  color: ${(props) => props.theme.colors.text.white};
  font-size: 0.85rem;
  h2 {
    font-size: 1rem;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    padding-right: 8px;
    text-transform: capitalize;
    min-width: 50%;
    margin-right: auto;
  }
  > p {
    margin-left: 8px;
  }
  @media (max-width: 768px) {
    font-size: 0.65rem;
    h2 {
      padding-right: 16px;
    }
  }
`;

export const NutritionalOrientationBody = styled.div`
  padding: 30px;
  font-family: 'Unimed-Sans-Book';
  color: ${(props) => props.theme.colors.text.grayishToWhite};
  h2 {
    margin-bottom: 16px;
  }
`;

export const Message = styled.p`
  font-family: 'Unimed-Sans-Book';
  color: #5b5c65;
  text-align: center;
`;

export const ButtonGoBack = styled.button`
  float: right;
  border: none;
  padding: 0;
  background: transparent;
  font-family: 'Unimed-Sans-Book';
  color: ${(props) =>
    props.theme.title === 'light'
      ? props.theme.colors.text.primary
      : props.theme.colors.text.white};
  &:hover {
    box-shadow: none;
    border: none;
  }
`;
