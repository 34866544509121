import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import PageWrapper from '../../components/PageWrapper';
import { Column } from '../../components/Table';
import api from '../../services/api/api';
import AddField from '../../utils/tableUtils';
import LossRatioEvolutionGraphic from '../../components/LossRatioEvolutionGraphic';
import LossRatioGeneralGraphic from '../../components/LossRatioGeneralGraphic';

import Title, { TitleIntern } from '../../components/Title';
import {
  FormWrapperPeriodDate,
  GraphicsContainer,
  Table,
  Container,
} from './styles';
import { dateToPTBR } from '../../utils/formatt';
import { useContract } from '../../hooks/contract';
import { EnterpriseUser, UserCommon, useAuth } from '../../hooks/auth';

interface DateProps {
  value: Date | null;
  error: string;
}

interface lossRatioDataProps {
  dateOfCompetence: string;
  realLossRate: number;
  idealLossRate: number;
}

interface LossRatioGeneralValues {
  idealLossRate: number;
  realLossRate: number;
}

const Lossratio: React.FC = () => {
  const { contract } = useContract();
  const { colors } = useTheme();
  const { user } = useAuth();
  const { groupNumber } = user as UserCommon & EnterpriseUser;

  const { contractId } = contract;

  const [selectedFromDate, setSelectedFromDate] = useState<DateProps>({
    value: new Date(
      new Date().getFullYear() - 1,
      new Date().getMonth(),
      new Date().getDate(),
    ),
    error: '',
  });
  const [selectedToDate, setSelectedToDate] = useState<DateProps>({
    value: new Date(),
    error: '',
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [contractAndVrtableItems, setContractAndVrTableItems] = useState<any>(
    [],
  );

  const [adjustesHistoryTableItems, setAdjustesHistoryTableItems] =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useState<any>([]);
  const [lossrationEvolutionData, setLossrationEvolutionData] = useState<
    lossRatioDataProps[]
  >([{ dateOfCompetence: '', realLossRate: 0, idealLossRate: 0 }]);
  const [lossRationGereralValues, setLossRationGereralValues] =
    useState<LossRatioGeneralValues>({
      idealLossRate: 0,
      realLossRate: 0,
    });

  const getContractVrTableItems = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');

      const startDate =
        selectedFromDate.value && dateToPTBR(selectedFromDate.value);
      const finalDate =
        selectedToDate.value && dateToPTBR(selectedToDate.value);
      if (!selectedFromDate.value) {
        setSelectedFromDate((prev) => ({
          value: prev.value,
          error: 'Selecione uma data',
        }));
      }
      if (!selectedToDate.value) {
        setSelectedToDate((prev) => ({
          value: prev.value,
          error: 'Selecione uma data',
        }));
      }
      if (!selectedFromDate.value || !selectedToDate.value) return;

      let route = `/loss-ratio/list?startDate=${startDate}&finalDate=${finalDate}`;

      if (groupNumber) {
        route += `&groupNumber=${groupNumber}&contractId=`;
      } else if (contractId) {
        route += `&contractId=${contractId}&groupNumber=`;
      }

      const { data } = await api.get(route);
      const { content } = data;

      setContractAndVrTableItems(content);
    } catch (error) {
      if (error?.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dados do servidor... Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, [contractId, selectedFromDate.value, selectedToDate.value, groupNumber]);

  const getReadjustmentHistory = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');
      const startDate =
        selectedFromDate.value && dateToPTBR(selectedFromDate.value);
      const finalDate =
        selectedToDate.value && dateToPTBR(selectedToDate.value);
      if (!selectedFromDate.value) {
        setSelectedFromDate((prev) => ({
          value: prev.value,
          error: 'Selecione uma data',
        }));
      }
      if (!selectedToDate.value) {
        setSelectedToDate((prev) => ({
          value: prev.value,
          error: 'Selecione uma data',
        }));
      }
      if (!selectedFromDate.value || !selectedToDate.value) return;

      let route = `/loss-ratio/readjustment-history?startDate=${startDate}&finalDate=${finalDate}`;

      if (groupNumber) {
        route += `&groupNumber=${groupNumber}&contractId=`;
      } else if (contractId) {
        route += `&contractId=${contractId}&groupNumber=`;
      }

      const { data } = await api.get(route);
      const { content } = data;

      if (content.length !== 0) {
        setAdjustesHistoryTableItems(content);
      }
    } catch (error) {
      if (error?.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dados do servidor... Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, [contractId, groupNumber, selectedFromDate.value, selectedToDate.value]);

  const getLossratioEvolutionDataTableItems = useCallback(async () => {
    try {
      Notiflix.Block.circle('.graphics-loading');
      const startDate =
        selectedFromDate.value && dateToPTBR(selectedFromDate.value);
      const finalDate =
        selectedToDate.value && dateToPTBR(selectedToDate.value);
      if (!selectedFromDate.value) {
        setSelectedFromDate((prev) => ({
          value: prev.value,
          error: 'Selecione uma data',
        }));
      }
      if (!selectedToDate.value) {
        setSelectedToDate((prev) => ({
          value: prev.value,
          error: 'Selecione uma data',
        }));
      }
      if (!selectedFromDate.value || !selectedToDate.value) return;

      let route = `/loss-ratio/monthly-chart?startDate=${startDate}&finalDate=${finalDate}`;

      if (groupNumber) {
        route += `&groupNumber=${groupNumber}&contractId=`;
      } else if (contractId) {
        route += `&contractId=${contractId}&groupNumber=`;
      }
      const { data } = await api.get(route);
      const { content } = data;

      setLossrationEvolutionData(content);
    } catch (err) {
      if (err?.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dados do servidor... Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.graphics-loading');
    }
  }, [selectedFromDate.value, selectedToDate.value, contractId, groupNumber]);

  const getLossratioGeneralData = useCallback(async () => {
    try {
      Notiflix.Block.circle('.graphics-loading');
      const startDate =
        selectedFromDate.value && dateToPTBR(selectedFromDate.value);
      const finalDate =
        selectedToDate.value && dateToPTBR(selectedToDate.value);
      if (!selectedFromDate.value) {
        setSelectedFromDate((prev) => ({
          value: prev.value,
          error: 'Selecione uma data',
        }));
      }
      if (!selectedToDate.value) {
        setSelectedToDate((prev) => ({
          value: prev.value,
          error: 'Selecione uma data',
        }));
      }
      if (!selectedFromDate.value || !selectedToDate.value) return;

      let route = `/loss-ratio/thermometer?startDate=${startDate}&finalDate=${finalDate}`;

      if (groupNumber) {
        route += `&groupNumber=${groupNumber}&contractId=`;
      } else if (contractId) {
        route += `&contractId=${contractId}&groupNumber=`;
      }

      const { data } = await api.get(route);
      const { content } = data;

      setLossRationGereralValues(content[0]);
    } catch (err) {
      if (err?.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dados do servidor... Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.graphics-loading');
    }
  }, [selectedFromDate.value, selectedToDate.value, contractId, groupNumber]);

  const handleSearchDatas = useCallback(async () => {
    Notiflix.Block.circle('.notiflix-loading');
    await Promise.all([
      getContractVrTableItems(),
      getLossratioEvolutionDataTableItems(),
      getLossratioGeneralData(),
    ]);
    Notiflix.Block.remove('.notiflix-loading');
  }, [
    getLossratioEvolutionDataTableItems,
    getContractVrTableItems,
    getLossratioGeneralData,
  ]);

  useEffect(() => {
    if (contract.contractId) {
      Notiflix.Block.circle('.notiflix-loading');
      Promise.all([
        getContractVrTableItems(),
        getReadjustmentHistory(),
        getLossratioEvolutionDataTableItems(),
        getLossratioGeneralData(),
      ]).finally(() => {
        Notiflix.Block.remove('.notiflix-loading');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId]);

  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto auto">
      <Container>
        <Title themeColor={colors.palet.institutional3}>Sinistralidade</Title>

        <FormWrapperPeriodDate onSubmit={handleSearchDatas} formLook>
          <DatePicker
            name="from"
            onChange={(date: Date) =>
              setSelectedFromDate({ value: date, error: '' })
            }
            selected={selectedFromDate.value}
            icon
            formLook
            title="Período de:"
            widthContainerDesktop="20%"
          />
          <DatePicker
            name="to"
            onChange={(date: Date) =>
              setSelectedToDate({ value: date, error: '' })
            }
            selected={selectedToDate.value}
            icon
            formLook
            title="Até:"
            widthContainerDesktop="20%"
          />

          <Button
            type="submit"
            autoWidth
            grayButton
            formLook
            className="notiflix-loading"
          >
            Buscar
          </Button>
        </FormWrapperPeriodDate>

        <TitleIntern themeColor={colors.palet.institutional3}>
          Tabela de acompanhamento
        </TitleIntern>
        <Table
          items={contractAndVrtableItems}
          className="notiflix-table-comp-loading"
          columnResizeMode="fit"
        >
          <Column
            field="contrato"
            header="Contrato"
            sortable
            body={(data) => AddField(data.contractNumber, 'Contrato')}
          />
          <Column
            field="quantidadeBeneficiario"
            header="Quantidade Beneficiario"
            sortable
            body={(data) =>
              AddField(data.beneficiaryQuantity, 'Quantidade Beneficiario')
            }
          />
          <Column
            field="vrMensalidade"
            header="Vr. Mensalidade"
            sortable
            body={(data) => AddField(data.tuitionValue, 'Vr. Mensalidade')}
            className="currency"
          />
          <Column
            field="vrCoparticipação"
            header="Vr. Coparticipação"
            sortable
            style={{ width: '160px' }}
            body={(data) =>
              AddField(data.coParticipationValue, 'Vr. Coparticipação')
            }
            className="currency"
          />
          <Column
            field="vrDespesaLiquida"
            header="Vr. Despesa Líquida"
            sortable
            body={(data) =>
              AddField(data.valueOfNetExpense, 'Vr. Despesa Líquida')
            }
            className="currency"
          />
          <Column
            field="percentLossratio"
            header="% Sinistralidade"
            sortable
            style={{ width: '150px' }}
            body={(data) => AddField(data.lossRatio, '% Sinistralidade')}
          />
        </Table>

        <TitleIntern themeColor={colors.palet.institutional3}>
          Evolução Mensal da Sinistralidade / Sinistralidade Geral
        </TitleIntern>
        <GraphicsContainer className="graphics-loading">
          <LossRatioEvolutionGraphic
            allLossratioDatas={lossrationEvolutionData}
            lossRatioGoal={70}
          />
          <LossRatioGeneralGraphic
            goalContractPercent={lossRationGereralValues.idealLossRate}
            pointerPercentContract={lossRationGereralValues.realLossRate}
          />
        </GraphicsContainer>

        <TitleIntern themeColor={colors.palet.institutional3}>
          Histórico de Sinistralidade
        </TitleIntern>
        <Table
          items={adjustesHistoryTableItems}
          className="notiflix-table-loading"
          columnResizeMode="fit"
        >
          <Column
            field="vigenciaReajuste"
            header="Vivência Reajuste"
            sortable
            className="date"
            body={(data) =>
              AddField(data.validityOfTheReadjustment, 'Vivência Reajuste')
            }
          />
          <Column
            field="sinistralidadeReal"
            header="Sinistralidade real %"
            sortable
            body={(data) =>
              AddField(`${data.realLossRate}%`, 'Sinistralidade real %')
            }
            className="align_col_lossratio"
          />
          <Column
            field="sinistralidadeIdeal"
            header="Sinistralidade ideal %"
            sortable
            body={(data) =>
              AddField(`${data.idealLossRate}%`, 'Sinistralidade ideal %')
            }
            className="align_col_lossratio"
          />
          <Column
            field="reajusteProposto"
            header="Reajuste Proposto %"
            sortable
            body={(data) =>
              AddField(`${data.proposedLossRate}%`, 'Reajuste Proposto %')
            }
            className="align_col_lossratio"
          />
          <Column
            field="reajusteAplicado"
            header="Reajuste Aplicado %"
            sortable
            body={(data) =>
              AddField(`${data.appliedLossRate}%`, 'Reajuste Aplicado %')
            }
            className="align_col_lossratio"
          />
        </Table>
      </Container>
    </PageWrapper>
  );
};

export default Lossratio;
