import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body{
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
  }
  #root{
    width: 100%;
    height: 100%;

    @media(max-width: 768px){
      width: 100vw;
      overflow: hidden auto;
    }
  }
  button,a,input{
    cursor: pointer;
  }

  button{
    outline: none;
  }
  .notiflix-block-position{
    min-height: 10px !important;
  }

`;
