import axios, { AxiosError } from 'axios';
import Notiflix from 'notiflix';

const url = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: url,
});

api.interceptors.response.use(
  (response) => {
    // console.log('response', response);
    return response;
  },
  async (error: AxiosError) => {
    if (error.response?.status !== 401 && error.response?.status !== 500) {
      return Promise.reject(error);
    }
    if (error.response?.status === 500) {
      // fix for php "expired token"
      if (error.response?.data?.message !== 'Expired token') {
        return Promise.reject(error);
      }
    }
    // console.log('middleware 401/500 Expired token', {
    //   response: error.response,
    //   confg: error.config,
    // });
    const originalRequest = error.config;

    if (originalRequest.headers.inRefresh) {
      delete originalRequest.headers.inRefresh;
      return Promise.reject(error);
    }

    const newToken = await tryRefreshToken();
    if (newToken) {
      originalRequest.headers.Authorization = `Bearer ${newToken}`;
      return api.request(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default api;

const tryRefreshToken = async (): Promise<string> => {
  api.defaults.headers.common.inRefresh = true;
  try {
    const savedSession = localStorage.getItem('@unimedBlumenauToken');
    if (savedSession) {
      const refreshTokenSaved = JSON.parse(savedSession);
      const { data } = await api.get('/auth/refresh-token', {
        headers: {
          Authorization: `Bearer ${refreshTokenSaved.refreshToken}`,
        },
      });
      const { content } = data;
      const { token, refreshToken } = content;

      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      delete api.defaults.headers.common.inRefresh;

      localStorage.setItem(
        '@unimedBlumenauToken',
        JSON.stringify({
          token,
          refreshToken,
        }),
      );
      return token;
    }
    return '';
  } catch (err) {
    Notiflix.Notify.info('Sessão Expirada.');
    localStorage.removeItem('@unimedBlumenau');
    localStorage.removeItem('@unimedBlumenauToken');
    localStorage.removeItem('@unimedBlumenauContract');
    localStorage.removeItem('@unimedBeneficiaries');
    sessionStorage.removeItem('@themeUnimed');
    window.location.replace('/login');
    // throw new Error('expired session');
    return '';
  }
};
