import React from 'react';
import { useAuth } from '../../hooks/auth';
import BeneficiaryChangeRegisterData from './Beneficiary';
import CompanyChangeRegisterData from './Company';

const ChangeRegisterData: React.FC = () => {
  const { user } = useAuth();

  if (user.profile === 1) {
    return <BeneficiaryChangeRegisterData />;
  }
  return <CompanyChangeRegisterData />;
};

export default ChangeRegisterData;
