import styled from 'styled-components';

import TableComponent from '../../../components/Table';

export const Table = styled(TableComponent)`
  grid-column: 2/3;

  @media (max-width: 768px) {
    grid-column: 1;
    margin-bottom: 30px;
  }
`;

export const Title = styled.h1`
  grid-column: 2/3;
  text-transform: uppercase;
  font-size: 2.55rem;
  line-height: 2.4rem;
  font-family: 'Unimed-Slab-Bold';
  color: ${(props) => props.theme.colors.palet.institutional9};
  text-align: center;
  padding: 30px;

  @media (max-width: 768px) {
    grid-column: 1;
    font-size: 1.7rem;
    line-height: 1.5rem;
  }
`;

export const Tag = styled.span`
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 0.7rem;
  position: relative;
  padding-left: 16px;
  display: flex;
  white-space: nowrap;
  > svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &.cancelado {
    color: ${(props) => props.theme.colors.palet.institutional7};
    > svg {
      path {
        stroke: ${(props) => props.theme.colors.palet.institutional7};
      }
      circle {
        fill: ${(props) => props.theme.colors.palet.institutional7};
      }
    }
  }

  &.solicitado {
    color: ${(props) => props.theme.colors.palet.institutional6};
    > svg {
      path {
        stroke: ${(props) => props.theme.colors.palet.institutional6};
      }
      circle {
        fill: ${(props) => props.theme.colors.palet.institutional6};
      }
    }
  }
  &.confirmado {
    color: ${(props) => props.theme.colors.palet.institutional};
    > svg {
      path {
        stroke: ${(props) => props.theme.colors.palet.institutional};
      }
      circle {
        fill: ${(props) => props.theme.colors.palet.institutional};
      }
    }
  }
`;
