/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { produce } from 'immer';
import Button from '../../components/Button';
import Input from '../../components/Input';
import PageWrapper from '../../components/PageWrapper';
import Table, { Column } from '../../components/Table';
import AddField, { AddField as addField } from '../../utils/tableUtils';
import api from '../../services/api/api';
import { Title, Container, Form, TableContainer } from './styles';
import { useContract } from '../../hooks/contract';
import Modal2 from '../../components/Modal2';

interface UserListProps {
  userName: string;
  email: string;
  webUsername: string;
  status: string;
}

const UsersList: React.FC = () => {
  const [contractDocuments, setContractDocuments] = useState<UserListProps[]>(
    [],
  );
  const [filter, setFilter] = useState('');
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { contract } = useContract();

  const getUserList = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');
      const { contractId } = contract;
      const beneficiaryName = filter;
      if (beneficiaryName) {
        const { data } = await api.get(
          `/company/list-users?contractId=${contractId}&beneficiaryName=${beneficiaryName}`,
        );
        const { content } = data;
        if (content) {
          setContractDocuments(content);
        }
      } else {
        const { data } = await api.get(
          `/company/list-users?contractId=${contractId}`,
        );
        const { content } = data;
        if (content) {
          setContractDocuments(content);
        }
      }
    } catch (err) {
      if (err?.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar a lista de usuários do servidor. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, [contract, filter]);

  const handleFilter = useCallback(async (data) => {
    formRef.current?.setErrors({});
    if (data.filters === '') {
      setFilter('');
    } else {
      setFilter(data.filters);
    }
  }, []);

  const turnUserInactive = useCallback(async (data) => {
    const { webUsername, contractId } = data;
    const body = { webUsername, contractId };
    try {
      const { data: dataApi } = await api.put(`/company/disable-user`, body);
      const { message } = dataApi;
      Modal2.Success({
        closable: true,
        autoWidth: true,
        title: 'Perfeito!',
        text: message,
        children: (
          <Button modal onClick={() => Modal2.Close()}>
            Ok
          </Button>
        ),
      });

      setContractDocuments((prev) =>
        produce(prev, (draft) => {
          const foundItem = draft.find(
            (i) => i.webUsername === data.webUsername,
          );
          if (foundItem) {
            foundItem.status = 'Inativo';
          }
        }),
      );
    } catch (error) {
      if (error?.response?.data.message) {
        Notiflix.Notify.info(error.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar a lista de usuários do servidor. Tente novamente mais tarde.',
        );
      }
    }
  }, []);

  const handleSwitch = useCallback(
    (data) => {
      if (!data.status || data.status === 'Inativo') {
        return null;
      }
      return (
        <Button sausageLook onClick={() => turnUserInactive(data)}>
          Inativar
        </Button>
      );
    },
    [turnUserInactive],
  );

  useEffect(() => {
    if (contract.contractId) {
      getUserList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, contract]);

  return (
    <PageWrapper gridTemplateRows="auto auto auto 1fr">
      <Title>Listagem de Usuários</Title>
      <Container>
        <Form ref={formRef} formLook onSubmit={handleFilter}>
          <Input
            title="Procure por um usuário:"
            name="filters"
            formLook
            widthContainerDesktop="50%"
          />
          <Button grayButton autoWidth formLook float="right" type="submit">
            Filtrar
          </Button>
          <Button
            greenCriticalButton
            autoWidth
            formLook
            onClick={() => history.push('/incluir-usuario')}
          >
            Novo Usuário
          </Button>
        </Form>
        <TableContainer>
          <Table
            items={contractDocuments}
            key="contractDocuments"
            className="notiflix-table-loading"
            columnResizeMode="fit"
            id="contractDocuments"
          >
            <Column
              field="name"
              header="Nome do Beneficiário"
              sortable
              body={(row) => addField(row.name, 'Nome do Beneficiário')}
            />
            <Column
              field="email"
              header="e-mail"
              sortable
              body={(row) => addField(row.email, 'e-mail')}
            />
            <Column
              field="webUsername"
              header="Usuário"
              sortable
              body={(row) => AddField(row.webUsername, 'Usuário')}
            />
            <Column
              field="status"
              header="Status"
              sortable
              body={(row) => addField(row.status, 'Status')}
            />
            <Column
              field="download"
              style={{ width: '120px' }}
              body={(row) => handleSwitch(row)}
            />
          </Table>
        </TableContainer>
      </Container>
    </PageWrapper>
  );
};

export default UsersList;
