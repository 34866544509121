import React from 'react';
import {
  Container,
  ContactContainer,
  TelephoneIcon,
  TelephoneInfo,
  WhatsappIcon,
  WhatsappInfo,
  ContactTitle,
} from './styles';

const ContactBlock: React.FC = () => {
  return (
    <Container>
      <ContactTitle>Nossos canais de contatos</ContactTitle>

      <ContactContainer href="tel:08006470026" target="_blank">
        <TelephoneIcon />
        <TelephoneInfo>
          <p>Fale Conosco:</p>
          <h2>0800 647.0026</h2>
        </TelephoneInfo>
      </ContactContainer>

      <ContactContainer href="https://wa.me/47999010459" target="_blank">
        <WhatsappIcon />
        <WhatsappInfo>
          <p>Whatsapp:</p>
          <h2>(47) 99901-0459</h2>
        </WhatsappInfo>
      </ContactContainer>
    </Container>
  );
};

export default ContactBlock;
