import Notiflix from 'notiflix';
import React, { useState, useCallback, useRef, useMemo } from 'react';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import Modal2 from '../../../components/Modal2';
import { LogoUnimedSvg } from '../../../components/Svg';
import Title from '../../../components/Title';
import api from '../../../services/api/api';

import { ExternalContainer, FormContent, UnimedLogo } from './style';
import { ReleasesProvider } from '../../../hooks/releasesAndTerms';
import FormWrapper from '../../../components/FormWrapper';
import SelectUnform from '../../../components/SelectUnform';
import Select from '../../../components/Select';

interface PeriodProp {
  name: string;
  id: string;
}

const InterviewSchedule: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [interviewSchedule, setInterviewSchedule] = useState<PeriodProp[]>();
  const [periodInterview, setPeriodInterview] = useState('');
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [periodInterviewUnimed, setPeriodInterviewUnimed] = useState(false);

  const userDSlocalStorage = localStorage.getItem(
    '@unimedBlumenauDeclaracaoSaude',
  );

  const userDs = JSON.parse(userDSlocalStorage as string);

  const periodSelect = [
    {
      title: '',
      value: '',
    },
    {
      title: 'Período da manhã',
      value: 'M',
    },
    {
      title: 'Período da tarde',
      value: 'T',
    },
    {
      title: 'Aguardar contato Unimed',
      value: 'U',
    },
  ];

  const getInterviewSchedule = async (period: string) => {
    try {
      setPeriodInterviewUnimed(false);
      setLoadingSchedule(false);
      const { data } = await api.get(
        `/health-declaration/list-appointment-scheduling?declarationId=${userDs.healthDeclarationId}&period=${period}`,
      );
      const { content } = data;

      setPeriodInterview(period);
      setInterviewSchedule(
        content.reduce(
          (
            acc: { id: string; name: string }[],
            act: {
              scheduleDate: string;
              scheduleId: string;
            },
          ) => {
            acc.push({
              id: String(act.scheduleId),
              name: `${act.scheduleDate}`,
            });
            return acc;
          },
          [],
        ),
      );
      setLoadingSchedule(true);
    } catch (err) {
      if (err.response) {
        setInterviewSchedule(undefined);
        Modal2.Failure({
          closable: true,
          autoWidth: true,
          title: 'Ooops...',
          text: err.response.data.message,
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });
      }
    }
  };

  const removeOnSession = useCallback(() => {
    window.location.reload();
    localStorage.removeItem('@unimedBlumenauDeclaracaoSaude');
    localStorage.removeItem('@unimedBlumenauPrimaryToken');
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const data = formRef.current?.getData();

      // const userDSlocalStorage = localStorage.getItem(
      //   '@unimedBlumenauDeclaracaoSaude',
      // );
      // const userDs = JSON.parse(userDSlocalStorage as string);
      Notiflix.Block.circle('.notiflix-save-interview-schedule');
      const params = {
        declarationId: userDs.healthDeclarationId,
        period: data?.periodInterview,
        scheduleId: data?.hour,
      };

      const { data: dataApi } = await api.post(
        `/health-declaration/send-appointment-scheduling`,
        params,
      );

      const { message } = dataApi;

      if (message) {
        Modal2.Success({
          closable: false,
          autoWidth: true,
          title: 'Perfeito!',
          subtitle: message,
          // text: 'Sua entrevista está agendada',
          children: (
            <Button
              modal
              outlined
              onClick={() => {
                Modal2.Close();
                history.push('/');
                removeOnSession();
              }}
            >
              Ok
            </Button>
          ),
        });
      }
      return null;
    } catch (err) {
      if (err.response?.data?.message) {
        Modal2.Failure({
          closable: true,
          autoWidth: true,
          title: 'Ooops...',
          text: err.response.data.message,
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos salvar sua requisição no servidor. Por favor, tente novamente mais tarde.',
        );
      }
      return null;
    } finally {
      Notiflix.Block.remove('.notiflix-save-beneficiary');
    }
  }, []);

  return (
    <ExternalContainer>
      <UnimedLogo inLoading={loading}>
        <LogoUnimedSvg />
      </UnimedLogo>

      <Title>Agendamento de entrevista</Title>
      <FormContent>
        <FormWrapper
          onSubmit={() => handleSubmit()}
          formLook
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          ref={formRef}
          className=""
        >
          <SelectUnform
            name="periodInterview"
            widthContainerDesktop="50%"
            title="Em qual período deseja marcar a entrevista?"
            options={periodSelect.reduce((acc, act) => {
              acc.push({
                name: act.title,
                id: act.value,
              });
              return acc;
            }, [] as { id: string; name: string }[])}
            formLook
            callbackOnChange={(name, id) => {
              getInterviewSchedule(id);
              setPeriodInterview(id);
              if (id === 'U') {
                setPeriodInterviewUnimed(true);
              }
            }}
          />

          {((interviewSchedule && periodInterview === 'T') ||
            (interviewSchedule && periodInterview === 'M')) &&
            loadingSchedule !== false && (
              <SelectUnform
                name="hour"
                title="Horário:"
                options={interviewSchedule}
                formLook
                widthContainerDesktop="33.33%"
                callbackOnChange={() => {
                  setPeriodInterviewUnimed(true);
                }}
              />
            )}
          <Button
            type="submit"
            className="notiflix-save-interview-schedule"
            autoWidth
            formLook
            disabled={!periodInterviewUnimed}
            greenPrimary
          >
            Enviar
          </Button>
        </FormWrapper>
      </FormContent>
    </ExternalContainer>
  );
};

export default InterviewSchedule;
