import styled, { css } from 'styled-components';
import { Form } from '@unform/web';
import { ANSSvg, CameraSvg } from '../../components/Svg';
import InputElement from '../../components/Input';
import FormWrapper from '../../components/FormWrapper';
import Button from '../../components/Button';

export const ANSIcon = styled(ANSSvg)``;
export const CameraIcon = styled(CameraSvg)``;
export const MobileContainer = styled.div``;

export const Input = styled(InputElement)`
  & input {
    background: transparent;
    color: ${(props) => props.theme.colors.text.grayishToWhite};
  }
  ${(props) =>
    props.disabled &&
    css`
      cursor: initial !important;
      & label {
        cursor: initial !important;
      }

      & input {
        cursor: initial !important;
      }
    `}
`;

export const BeneficiaryAndProductTitle = styled.div`
  display: flex;
  justify-content: center;

  h1 {
    font-size: 2.57rem;

    color: ${(props) => props.theme.colors.palet.institutional3};
    font-family: 'Unimed-Slab-Bold';
    font-size: 2.55rem;
    line-height: 2.4rem;

    text-align: center;
    text-transform: uppercase;
    padding: 30px 0;
    @media (max-width: 1200px) {
      font-size: 2.25rem;
      line-height: 2.1rem;
    }
    @media (max-width: 768px) {
      font-size: 1.7rem;
      line-height: 1.5rem;
      padding: 30px;
    }
  }

  @media (min-width: 769px) {
    grid-area: b;
  }

  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    text-align: center;
    /*
    h1 {
      margin: 20px 30px 0;
      font-size: 1.7rem;
    } */
  }
`;

export const BeneficiaryFormContainer = styled.div`
  h1 {
    color: ${(props) => props.theme.colors.palet.institutional3};
    text-transform: uppercase;
    font-family: 'Unimed-Sans-SemiBold';
    font-size: 1.62rem;
  }

  & div button {
    border-color: ${(props) => props.theme.colors.palet.institutional12};
    background: ${(props) => props.theme.colors.text.whiteToBlack};
    color: ${(props) => props.theme.colors.text.grayishToWhite};
  }

  & > form > button {
    width: 100%;
    margin-top: 8px;
    border-radius: 5px;
    @media (min-width: 769px) {
      margin-top: 0px;
      width: 25%;
      margin-left: 1%;
    }
  }

  @media (min-width: 769px) {
    grid-area: f;
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    h1 {
      width: 100%;
    }
    form {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    display: block;
    padding: 0 20px;
    margin: 0 auto;
    h1 {
      text-align: center;
      margin-top: 15px;
      font-size: 1rem;
    }
  }
`;

export const BeneficiaryFormDatas = styled(Form)`
  margin-top: 8px;
  background-color: ${(props) => props.theme.colors.text.whiteToBlack};
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(196, 203, 207, 0.5);

  @media (min-width: 769px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 100%;
    }
    > div:nth-child(1) {
      max-width: 45%;
    }
    > div:nth-child(2) {
      max-width: 55%;
    }
    > div:nth-child(3) {
      max-width: 52%;
    }
    > div:nth-child(4) {
      max-width: 48%;
    }
  }
`;

export const AvatarContainer = styled.div`
  margin: 0 auto;
  height: 10vw;
  width: 10vw;
  min-width: 200px;
  min-height: 200px;
  border-radius: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  position: relative;
  z-index: 0;
  border: 8px solid ${(props) => props.theme.colors.text.whiteToBlack};
  overflow: hidden;
  transition: 0.3s background;
  box-shadow: 0px 5px 10px rgba(196, 203, 207, 0.5);
  > div.avatar-notiflix-loading {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 35%;
    z-index: 2;
    position: absolute;

    background-color: transparent;
    color: transparent;

    span {
      color: transparent;
      margin-left: 5px;
      font-family: 'Unimed-Sans-SemiBold';
      z-index: 4;
      margin-bottom: 10px;
      font-size: 0.85rem;
    }

    svg {
      margin-bottom: 10px;
      display: none;
    }

    label {
      width: 100%;
      height: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 300ms;

      input {
        display: none;
      }
    }
  }
  img {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &:hover {
    > div {
      label {
        background: rgba(66, 66, 66, 0.4);
        color: white;
        span {
          color: white;
        }
      }
      svg {
        margin-bottom: 10px;
        display: block;
      }
    }
  }
  > div.notiflix-block-position {
    label {
      background: rgba(66, 66, 66, 0.4);
      color: white;
      span {
        color: white;
      }
    }
    svg {
      margin-bottom: 10px;
      display: block;
    }
  }
  @media (min-width: 769px) {
    grid-area: avatar;
    align-items: flex-end;
    justify-content: flex-end;
  }
  @media (max-width: 768px) {
    margin: 0 auto;
    /* height: 120px;
    width: 120px; */

    div {
      opacity: 0.4;
      span {
        font-size: 0.6rem;
      }
    }

    &::after {
      width: 90%;
      height: 90%;
    }
  }
`;

export const ImformationsAdjustsContainer = styled.div`
  > h2 {
    color: ${(props) => props.theme.colors.palet.institutional3};
    text-transform: uppercase;
    font-family: 'Unimed-Sans-SemiBold';
    font-size: 1rem;
  }

  @media (min-width: 769px) {
    /* align-self: flex-end; */
    grid-area: i;
  }

  @media (max-width: 768px) {
    > h2 {
      text-align: center;
      font-size: 1rem;
      margin: 10px 0;
    }
    padding: 0 20px;
  }
`;

export const InformartionAdjustForm = styled(FormWrapper)`
  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;

    > div:nth-child(1),
    > div:nth-child(2) {
      width: 50%;
    }
  }
`;

export const ConsultButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  @media (min-width: 769px) {
    grid-area: x;
    margin-top: 40px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    > a,
    > button {
      width: calc(100% - 40px);
      margin: 0 auto;

      @media (max-width: 768px) {
        border-radius: 126px !important;
      }
    }

    a + a,
    a + button {
      margin-top: 15px;
    }
  }
`;

export const ConsultButton = styled(Button)`
  height: 51px;
  border-radius: 126px !important;

  @media (min-width: 769px) {
    border-radius: 5px !important;
    width: 100%;
  }
`;

export const ProductContainer = styled.div`
  > h2 {
    color: ${(props) => props.theme.colors.palet.institutional3};
    text-transform: uppercase;
    font-family: 'Unimed-Sans-SemiBold';

    font-size: 1.62rem;
  }

  @media (min-width: 769px) {
    grid-area: d;
    align-self: flex-start;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;

    h2 {
      text-align: center;
      font-size: 1rem;
      margin-top: 15px;
      margin-bottom: 5px;
    }
  }
`;

export const ProductForm = styled(FormWrapper)`
  display: flex;
  flex-wrap: wrap;
  min-width: 300px;

  flex: 1;
  > div > div {
    min-width: 10px;
  }

  @media (max-width: 768px) {
    min-width: 270px;

    div:nth-child(1),
    div:nth-child(4),
    div:nth-child(5),
    div:nth-child(10),
    div:nth-child(12),
    div:nth-child(13),
    div:nth-child(14),
    div:nth-child(15) {
      width: 100%;
    }
    div:nth-child(2),
    div:nth-child(3),
    div:nth-child(6),
    div:nth-child(7),
    div:nth-child(8),
    div:nth-child(9),
    div:nth-child(11) {
      width: 50%;
    }
  }
`;

export const ContactServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 25px;

  @media (min-width: 769px) {
    grid-area: g;
  }

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

export const ServiceAttendanceConsumerContact = styled.div`
  text-align: center;
  h3 {
    font-family: 'Unimed-Slab-Bold';
    font-size: 1.3rem;
  }

  a {
    text-align: center;
    font-family: 'Unimed-Sans-Book';
    text-decoration: none;
    color: ${(props) => props.theme.colors.text.grayishToWhite};
    display: block;
  }

  a:nth-child(2) {
    font-size: 1.5rem;
    margin: 5px 0;
    font-family: 'Unimed-Slab-Bold';
  }

  h3 {
    color: ${(props) => props.theme.colors.palet.institutional3};
  }

  h2 {
    font-family: 'Unimed-Slab-Bold';
    margin: 5px 0;
    color: ${(props) => props.theme.colors.text.grayishToWhite};
  }

  @media (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

export const ANSContact = styled.div`
  margin-top: 50px;
  text-align: center;

  a {
    display: block;
    text-decoration: none;
    color: ${(props) => props.theme.colors.text.grayishToWhite};
    font-family: 'Unimed-Sans-Book';
    margin-bottom: 10px;
  }

  a:nth-child(2) {
    font-size: 1.5rem;
    margin: 5px 0;
    font-family: 'Unimed-Slab-Bold';
  }

  h2 {
    font-family: 'Unimed-Slab-Bold';
    margin: 5px 0;
    color: ${(props) => props.theme.colors.text.grayishToWhite};
    font-family: 'Unimed-Sans-Book';
  }

  p {
    font-family: 'Unimed-Sans-Book';
    color: ${(props) => props.theme.colors.text.grayishToWhite};
  }

  @media (max-width: 768px) {
    margin: 0 auto;
    p {
      margin-bottom: 20px;
    }
  }
`;

export const RiskSharingContainer = styled.div`
  @media (min-width: 769px) {
    grid-area: h;

    margin-top: 20px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
    margin: 20px 0;
    display: grid;
    grid-column: 1fr 1fr;
  }
`;

export const RiskSharingTitle = styled.h1`
  color: ${(props) => props.theme.colors.palet.institutional3};
  text-transform: uppercase;
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 1.62rem;
  text-align: center;

  @media (min-width: 769px) {
    text-align: left;
  }

  @media (max-width: 768px) {
    text-align: center;
    font-size: 1rem;
  }
`;

export const RiskForm = styled(FormWrapper)`
  flex-wrap: wrap;
  display: flex;

  > div > div {
    min-width: 10px;
    color: ${(props) => props.theme.colors.text.grayishToWhite} !important;
  }

  > div {
    width: 50%;
  }
`;
