import styled from 'styled-components';
import FormWrapper from '../../components/FormWrapper';
import TableComponent from '../../components/Table';

export const Container = styled.section`
  grid-column: 1/4;
  @media (max-width: 768px) {
    grid-column: 1;
  }
  @media (min-width: 1600px) {
    grid-column: 2/3;
  }
`;

export const MonthAndGroupContainer = styled.div`
  h2 {
    color: ${(props) => props.theme.colors.text.grayishToWhite};
  }
  background: ${(props) => props.theme.colors.background};
  padding: 10px 20px;
  font-family: 'Unimed-Sans-SemiBold';
  color: ${(props) => props.theme.colors.text.primary};
  text-align: center;
  font-size: 0.8rem;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    display: grid;
    margin: 0 30px;
  }
`;

export const FormWrapperPeriodDate = styled(FormWrapper)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 100px;
  margin-bottom: 30px;

  h2 {
    color: ${(props) => props.theme.colors.text.primary};
  }

  > button {
    text-transform: uppercase;
    &:first-of-type {
      margin-left: auto;
    }
  }
  @media (max-width: 768px) {
    max-height: 160px;

    > div {
      flex: 1 1;
    }
  }
`;

export const Table = styled(TableComponent)`
  .p-column-title {
    text-align: left !important;
    color: white;
  }
  margin-bottom: 30px;
`;

export const GraphicsContainer = styled.div`
  background: ${(props) => props.theme.colors.background};
  border-radius: 5px;
  display: flex;
  overflow: visible;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 30px;
    display: grid;
    grid-template-rows: 1fr 1fr;
  }
`;
