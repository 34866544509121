import styled, { css } from 'styled-components';
import { CameraSvg } from '../../components/Svg';
import SelectElement from '../../components/Select';
import InputElement from '../../components/Input';
import InputFileElement from '../../components/InputFile';
import ButtonElement from '../../components/Button';
import FormWrapper from '../../components/FormWrapper';

export const CameraIcon = styled(CameraSvg)``;

export const SelectWrapper = styled(FormWrapper)`
  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
  grid-column: 2/5;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    grid-column: 1;
  }
`;
export const Select = styled(SelectElement)``;

export const FormSelect = styled(SelectElement)`
  button {
    margin-top: 0 !important;
    border-radius: 2px !important;
  }
`;

export const FormContent = styled(FormWrapper)`
  grid-column: 2/5;
  display: flex;
  flex-wrap: wrap;

  .react-datepicker-wrapper,
  .selectMedium {
    border: 1px solid #c4cbcf;
    border-radius: 4px;
    input {
      text-align: left;
      padding: 8px 10px;
    }
    button {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
  @media (max-width: 768px) {
    grid-column: 1;
    margin-bottom: 30px;
  }
`;

export const Button = styled(ButtonElement)`
  @media (max-width: 768px) {
    margin-left: auto;
    width: 100%;
  }
  @media (min-width: 769px) {
    margin-left: auto;
    width: 150px;
  }
`;
export const Input = styled(InputElement)``;

export const InputFile = styled(InputFileElement)`
  grid-column: 1/3;
  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

export const Title = styled.h1`
  font-family: 'Unimed-Slab-Bold';
  font-size: 2.55rem;
  line-height: 2.4rem;
  grid-column: 1/6;

  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional8};
  padding: 30px 0;
  @media (max-width: 1200px) {
    font-size: 2.25rem;
    line-height: 2.1rem;
  }
  @media (max-width: 768px) {
    font-size: 1.7rem;
    line-height: 1.5rem;
    padding: 30px;
    grid-column: 1;
  }
`;

export const Observation = styled.p`
  font-family: 'Unimed-Sans-Book';
  font-size: 1rem;
  color: ${(props) => props.theme.colors.text.primary};

  a {
    font-family: 'Unimed-Sans-Book';
    font-size: 1rem;
    color: ${(props) => props.theme.colors.text.primary};
  }
  strong {
    color: ${(props) => props.theme.colors.palet.institutional8};
  }
`;
