import styled from 'styled-components';

import TableComponent from '../../../components/Table';

export const Table = styled(TableComponent)`
  grid-column: 2/10;

  @media (max-width: 768px) {
    grid-column: 1;
    margin-bottom: 30px;
  }
  @media (min-width: 769px) and (max-width: 1400px) {
    grid-column: 1/11;
    margin-bottom: 30px;
  }
`;

export const RequestDuplicateCardConsultTitle = styled.h1`
  grid-column: 2/10;

  text-transform: uppercase;
  font-size: 2.55rem;
  line-height: 2.4rem;
  font-family: 'Unimed-Slab-Bold';
  color: ${(props) => props.theme.colors.palet.institutional9};
  text-align: center;
  padding: 30px;

  @media (max-width: 768px) {
    grid-column: 1;
    font-size: 1.7rem;
    line-height: 1.5rem;
  }
  @media (min-width: 769px) and (max-width: 1400px) {
    grid-column: 1/11;
  }
`;

export const ActionsButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 8px;
  > button {
    text-transform: capitalize;
    white-space: normal;
  }
`;
