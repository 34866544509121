import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  BookSvg,
  ChecklistSvg,
  BookOpenSvg,
  EditSquare,
  BarcodeSvg,
  ListSvg,
  BandaidSvg,
  SchedulingHome,
} from '../Svg';

export const BookIcon = styled(BookSvg)``;
export const ChecklistIcon = styled(ChecklistSvg)``;
export const OpenBookIcon = styled(BookOpenSvg)``;
export const EditIcon = styled(EditSquare)``;
export const BarcodeIcon = styled(BarcodeSvg)``;
export const ListIcon = styled(ListSvg)``;
export const CurativeIcon = styled(BandaidSvg)``;
export const SchedulingIcon = styled(SchedulingHome)``;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 8px;
  row-gap: 30px;
  width: 100%;
  @media (max-width: 768px) {
    row-gap: 8px;
    height: 100%;
    padding: 0px;
  }
  @media (max-width: 320px) {
    grid-template-columns: 1fr;
  }
`;

export const ContentContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  word-break: keep-all;
  margin-bottom: auto;
  text-decoration: none;

  h2 {
    font-family: 'Unimed-Sans-SemiBold';

    color: ${(props) => props.theme.colors.text.grayishToWhite};
    transition: 300ms;
    font-size: 1rem;
    line-height: 1.1rem;
    margin-top: 8px;
    @media (max-width: 768px) {
      margin-top: 0px;
      text-align: left;
      margin-left: 10px;
    }
  }
  > div {
    transition: 300ms;
  }

  &:hover {
    > div {
      transform: scale(1.1);
    }
    h2 {
      color: ${(props) =>
        props.theme.title === 'light'
          ? props.theme.colors.palet.institutional2
          : props.theme.colors.text.whiteToGrayish};
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
`;

export const IconBackground = styled.div`
  background: ${(props) => props.theme.colors.palet.institutional4};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  width: 60px;
  height: 60px;
  min-height: 60px;
  min-width: 60px;
  @media (max-width: 768px) {
    padding: 12px;
    width: 46px;
    height: 46px;
    min-height: 46px;
    min-width: 46px;
  }

  &.iconSvg {
    padding: 0.8em;
  }
`;
