/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import Button from '../../components/Button';
import PageWrapper from '../../components/PageWrapper';
import Table, { Column } from '../../components/Table';
import AddField, { AddField as addField } from '../../utils/tableUtils';
import api from '../../services/api/api';
import { Title, Container, Form, TableContainer } from './styles';
import { useContract } from '../../hooks/contract';
import Select, { SelectHandles } from '../../components/Select';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';

interface ContractProps {
  filename: string;
  observation: string;
  documentType: string;
  documentDate: string;
  documentId: string;
  documentTypeId: string;
}

const ContractDocuments: React.FC = () => {
  const { contract } = useContract();
  const [contractDocuments, setContractDocuments] = useState<ContractProps[]>(
    [],
  );
  const [documentTypeOptions, setDocumentTypeOptions] = useState([
    {
      title: '',
      value: '',
    },
  ]);

  const [filter, setFilter] = useState('');

  const selectRef = useRef<SelectHandles>(null);
  const formRef = useRef<FormHandles>(null);

  const getDocumentTypeOptions = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/company/list-contract-document-types?contractId=${contract.contractId}`,
      );
      const { content } = data;
      setDocumentTypeOptions(
        content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      );
    } catch (err) {
      if (err?.response?.message) {
        Notiflix.Notify.info(err.response.message);
      } else {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os seus Documentos do servidor. Tente novamente mais tarde.',
        );
      }
    }
  }, [contract]);

  const getContractDocuments = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/company/list-contract-documents?contractId=${contract.contractId}`,
      );
      const { content } = data;
      setContractDocuments(content);
    } catch (err) {
      if (err?.response?.message) {
        Notiflix.Notify.info(err.response.message);
      } else {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os seus Documentos do servidor. Tente novamente mais tarde.',
        );
      }
    }
  }, [contract]);

  const tableData = useMemo(() => {
    if (!filter) {
      return contractDocuments;
    }

    return contractDocuments.filter((i) => i.documentTypeId === filter);
  }, [contractDocuments, filter]);

  const handlePrint = useCallback(
    async (rowData, notiflix: string) => {
      const { documentId, filename } = rowData;
      try {
        Notiflix.Block.circle(notiflix);
        const { data } = await api.get(
          `/company/get-contract-document?contractId=${contract.contractId}&documentId=${documentId}&fileName=${filename}`,
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(data);
      } catch (err) {
        if (err?.response?.status === 400) {
          Notiflix.Notify.info(err.response.data?.message);
        } else {
          Notiflix.Notify.failure(
            'Não conseguimos buscar seu relatório. Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [contract],
  );

  useEffect(() => {
    if (contract.contractId) {
      getDocumentTypeOptions();
      getContractDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  const handleFilter = useCallback(async () => {
    setFilter(selectRef.current?.value ?? '');
  }, []);

  return (
    <PageWrapper gridTemplateRows="auto auto auto 1fr">
      <Title>Documentos do Contrato</Title>
      <Container>
        <Form ref={formRef} formLook onSubmit={handleFilter}>
          <Select
            options={documentTypeOptions}
            ref={selectRef}
            title="Procure por um documento:"
            liveReload
            name="filters"
            formLook
          />
          <Button grayButton formLook float="right" type="submit">
            Filtrar
          </Button>
        </Form>
        <TableContainer>
          <Table
            items={tableData}
            key="contractDocuments"
            className="notiflix-table-loading"
            columnResizeMode="fit"
            id="contractDocuments"
          >
            <Column
              field="filename"
              header="Nome"
              sortable
              body={(row) => addField(row.filename, 'Nome')}
            />
            <Column
              field="documentType"
              header="Tipo de Documento"
              sortable
              body={(row) => AddField(row.documentType, 'Tipo de Documento')}
            />
            <Column
              field="documentDate"
              header="Data da Solicitação"
              className="date"
              style={{ width: '200px' }}
              body={(row) => addField(row.documentDate, 'Data da Solicitação')}
            />
            <Column
              field="observation"
              header="Observações"
              sortable
              body={(row) => addField(row.observation, 'Observações')}
            />
            <Column
              style={{ width: '120px' }}
              field="download"
              body={(row) => (
                <Button
                  sausageLook
                  noChangeColor
                  className={`notiflix-${row.documentId}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePrint(row, `.notiflix-${row.documentId}`);
                  }}
                  float="right"
                >
                  Download
                </Button>
              )}
            />
          </Table>
        </TableContainer>
      </Container>
    </PageWrapper>
  );
};

export default ContractDocuments;
