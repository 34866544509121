import styled, { keyframes } from 'styled-components';
import ReactSwitch from 'react-switch';
import PageWrapper from '../../components/PageWrapper';
import Form from '../../components/FormWrapper';

export const Container = styled(PageWrapper)`
  margin-top: 0;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    margin-top: 20px;
    padding-right: 20px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  h1 {
    font-size: 2.57rem;

    color: ${(props) => props.theme.colors.palet.institutional3};
    font-family: 'Unimed-Slab-Bold';
    font-size: 2.55rem;
    line-height: 2.4rem;

    text-align: center;
    text-transform: uppercase;
    padding: 30px 0;
    @media (max-width: 1200px) {
      font-size: 2.25rem;
      line-height: 2.1rem;
    }
    @media (max-width: 768px) {
      font-size: 1.7rem;
      line-height: 1.5rem;
      padding: 30px;
    }
  }

  @media (min-width: 769px) {
    grid-area: b;
  }

  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const BeneficiarySelectSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    color: ${(props) => props.theme.colors.palet.institutional3};
    text-transform: uppercase;
    font-family: 'Unimed-Sans-SemiBold';
    font-size: 1.62rem;
  }

  padding: 0 1.5rem;
  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const BeneficiaryCardsSection = styled.div`
  display: flex;
  width: 100vw;
  padding-top: 2rem;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  @media (min-width: 768px) {
    width: 100%;
    overflow: initial;
  }

  @media (min-width: 1200px) {
    gap: 2rem;
    align-items: flex-start;
    flex-direction: row;
  }
`;

export const CardSection = styled.section`
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  transition: 300ms;

  @media (max-width: 768px) {
    scale: 0.8;
    margin-top: -10%;
    margin-bottom: -10%;
  }

  @media (max-width: 330px) {
    scale: 0.7;
    margin-top: -15%;
    margin-bottom: -15%;
  }
`;

export const FormWrapper = styled(Form)`
  display: flex !important;
  flex-wrap: wrap;
  .text-bold {
    div {
      input {
        font-weight: bold;
      }
    }
  }
`;

export const CardWrapper = styled.div`
  background-color: transparent;
  width: 30rem;
  height: 36rem;
  perspective: 1000px;

  filter: drop-shadow(-28px 22px 14px rgba(0, 0, 0, 0.17));

  &.flip {
    & > div {
      transform: rotateY(180deg);
    }
  }
`;

export const Card = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;

  font-family: 'Unimed-Sans-SemiBold';
`;

export const Front = styled.div`
  position: absolute;
  width: 100%;
  backface-visibility: hidden;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
`;

export const FrontHeader = styled.div`
  background: #008c50;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
  border-radius: 10px 10px 0 0;

  .images {
    display: flex;
    align-items: center;
    gap: 2rem;

    img {
      &.logo {
        height: 5.71rem;
        display: flex;
        object-fit: contain;
        mix-blend-mode: lighten;
      }
    }
  }
  .descriptions {
    margin-top: 1rem;
    p {
      text-align: center;
      font-weight: 600;
      text-transform: uppercase;
      & + p {
        margin-top: 1rem;
      }
    }
  }
`;

export const FrontBody = styled.div`
  background: #b0d249;
  color: #000;
  border-top: 8px solid white;
  padding: 1.5rem;
  border-radius: 0 0 10px 10px;

  display: grid;
  grid-template-columns: 3fr 2fr;

  position: relative;

  grid-template-areas:
    'usernumber usernumber'
    'username username'
    'accommodation validity'
    'plan attendantNetwork'
    'scope attendant'
    'assistance assistance';
  gap: 1.5rem;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    small {
      text-align: left;
      line-height: 0.8rem;
      opacity: 0.5;
    }
    p {
      text-align: left;
      text-transform: uppercase;
      line-height: 1rem;
    }
    h4 {
      width: 100%;
      font-weight: bold;
      font-size: 2rem;
      text-align: center;
    }
  }
`;

export const TooltipButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 8rem;
  transform: translateY(50%);

  width: 3rem;
  height: 3rem;
  border-radius: 50%;

  background: #fee393;
  border: 4px solid #f47e32;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: 300ms;

  svg {
    transition: 300ms;
    color: #f47e32;
    font-size: 1.5rem;
  }

  span {
    position: absolute;
    bottom: 0%;
    left: 100%;
    white-space: nowrap;
    margin-left: 8px;
    color: ${(props) => props.theme.colors.text.normal};
  }

  &:hover {
    background: #b0d249;
    border-color: #008c50;
    svg {
      color: #008c50;
    }
  }
`;

export const Back = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 2.5rem);
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;

  background-color: white;
  color: #000;
  transform: rotateY(180deg);
  overflow: visible;
`;

export const FrontTooltip = styled.div`
  border-radius: 12px;
  padding: 1rem;
  padding-right: 2rem;

  position: absolute;
  bottom: 3rem;
  right: 6rem;

  background: #fff;

  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.27));

  &::before {
    content: '';
    position: absolute;
    bottom: 0%;
    right: 2.5rem;
    transform: translateY(100%);
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1rem solid #fff;
  }

  button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    outline: none;
    background: transparent;
    border: none;

    svg {
      color: #c1c1c1;
      font-size: 1.5rem;
    }
  }

  transition: 300ms;

  &:not(.open) {
    opacity: 0;
    visibility: hidden;
    transform: translateY(1rem);
  }
`;

export const FrontTooltipContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  color: #000;

  div.fullLine {
    grid-column: 1/3;
  }
  div {
    width: 100%;
    p,
    small {
      text-align: left;
      display: block;
    }
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Unimed-Sans-SemiBold';

  span {
    color: ${(props) => props.theme.colors.text.normal};
  }
`;

export const SwitchComponent = styled(ReactSwitch)`
  margin: 0 5px;
`;

export const BackBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  align-items: flex-start;
  position: relative;
  padding: 1rem 1rem 2rem;
  height: 100%;
`;

export const ActuationArea = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  p {
    text-align: start;
    text-transform: capitalize;
    strong {
      text-transform: initial;
    }
    font-family: 'Unimed-Sans-Book';
  }
`;

export const Shortcomings = styled.div`
  width: 100%;
  margin: auto 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  h3 {
    width: 100%;
    text-align: center;
  }

  div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 0.5rem;
    p {
      text-transform: uppercase;
      font-family: 'Unimed-Sans-Book';
      font-size: 1rem;
      text-align: left;

      & + p {
        text-align: right;
      }
    }
  }
`;

export const CodANS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  small {
    font-family: 'Unimed-Sans-Book';
  }
`;

export const CodCNS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  small {
    font-family: 'Unimed-Sans-Book';
  }
`;

export const Infos = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
  display: inline;

  a {
    display: inline;
    text-decoration: none;
    color: inherit;
    transition: 300ms;
    &:hover {
      color: ${(props) => props.theme.colors.text.primary};
    }
  }
`;

export const BackBodyFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.1fr 1fr;
  width: 100%;
  align-items: center;
`;

export const BackBodyFooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  grid-column: 1/2;
`;
export const BackBodyFooterCenter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  grid-column: 2/3;
`;

export const SAC = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ANSnumber = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  div {
    background: #18181a;
    padding: 5px;
    p {
      font-family: 'Unimed-Sans-SemiBold';
      font-size: 1rem;
      color: #fff;
      text-decoration: none;
      padding: 2px 6px;
      border: 2px solid #fff;
      white-space: nowrap;
    }
  }
`;

export const BackTooltip = styled.div`
  border-radius: 12px;
  padding: 1rem;
  padding-right: 2rem;

  position: absolute;
  bottom: 3rem;
  right: 6rem;

  background: #fff;

  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.27));

  &::before {
    content: '';
    position: absolute;
    bottom: 0%;
    right: 2.5rem;
    transform: translateY(100%);
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1rem solid #fff;
  }

  button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    outline: none;
    background: transparent;
    border: none;

    svg {
      color: #c1c1c1;
      font-size: 1.5rem;
    }
  }

  transition: 300ms;

  &:not(.open) {
    opacity: 0;
    visibility: hidden;
    transform: translateY(1rem);
  }
`;

export const BackTooltipContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  color: #000;

  div.fullLine {
    grid-column: 1/3;
  }
  div {
    width: 100%;
    p,
    small {
      text-align: left;
      display: block;
    }
  }
`;

export const QRCodeSection = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
`;

export const QRCodeCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 300px;
  border-radius: 10px;
  gap: 1rem;

  background: #b0d249;

  h3 {
    font-family: 'Unimed-Sans-SemiBold';
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    color: #004d4a;
  }
  div.qrCode {
    width: 100%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;

    canvas {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  div {
    width: 100%;
    span {
      display: block;
      width: 100%;
      text-align: right;
      color: #004d4a;
      font-family: 'Unimed-Sans-SemiBold';
    }
  }
`;

const tabAnimation = keyframes`
  0%{
    width: 0%;
  }
  100%{
    width: 100%;
  }
`;

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

export const TabsOptions = styled.button`
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 1.2rem;
  padding: 1rem 1.5rem;
  color: #000;
  transition: 300ms;
  position: relative;
  border: none;
  background: transparent;

  cursor: pointer;

  &::after {
    content: '';
    display: block;
    width: 0%;
    border-bottom: 4px solid #a6d05d;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: 300ms;
  }

  &.active {
    &::after {
      width: 100%;
    }
  }
  &:hover {
    &::after {
      width: 100%;
    }
  }
`;
