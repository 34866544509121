/* eslint-disable @typescript-eslint/no-explicit-any */
import produce from 'immer';
import Notiflix from 'notiflix';
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import FormWrapper from '../../components/FormWrapper';
import Modal2 from '../../components/Modal2';
import PageWrapper from '../../components/PageWrapper';
import { SelectHandles } from '../../components/Select';
import { Column } from '../../components/Table';
import { Title } from '../../components/Title/styles';
import { useContract } from '../../hooks/contract';
import api from '../../services/api/api';
import { dateToPTBR } from '../../utils/formatt';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';
import AddField, {
  AddField as addField,
  getTag,
  setPositionOfRowExpanded,
} from '../../utils/tableUtils';
import { Select } from '../ChangeRegisterData/Beneficiary/styles';
import { ActionsContainer, Container, Table } from './styles';

interface DateProps {
  value: Date | null;
  error: string;
}
interface RowProps {
  solicitationNumber: number;
  solicitationDate: string;
  dateOfChange: string;
  beneficiaryName: string;
  productName: string;
  observation: string;
  status: string;
  newProductCode: number;
  rejectionDate: string | null;
  rejectObservation: string | null;
  allowsCancel: string;
  hasAttachment: string;
  nrSeqSegurado: number;
  userUnimedId: string;
}

const ConsultProductChange: React.FC = () => {
  const { colors } = useTheme();
  const themeColor = colors.palet.institutional9;
  const history = useHistory();

  const { contract } = useContract();

  const [tableData, setTableData] = useState([] as RowProps[]);
  const selectRef = useRef<SelectHandles>(null);

  const [reportFrom, setReportFrom] = useState<DateProps>({
    value: new Date(`01/01/${new Date().getFullYear()}`),
    error: '',
  });
  const [reportTo, setReportTo] = useState<DateProps>({
    value: new Date(`12/31/${new Date().getFullYear()}`),
    error: '',
  });
  const [options, setOptions] = useState([
    {
      title: 'Status',
      value: '',
    },
  ]);

  const getOptions = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-option-code');
      const { data } = await api.get(`/company-product/list-of-status`);
      const { content } = data;
      setOptions(
        content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      );
    } catch (error) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar a lista de status para seleção. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.circle('.notiflix-option-code');
      Notiflix.Block.remove('.notiflix-option-code');
    }
  }, []);

  const getTableData = useCallback(
    async (filters = [] as string[]) => {
      try {
        Notiflix.Block.circle('.notiflix-table-loading');
        const params =
          filters.join('&') ||
          `startDate=01/01/${new Date().getFullYear()}&endDate=31/12/${new Date().getFullYear()}`;
        const { data } = await api.get(
          `/company-product/product-changes?contractId=${contract.contractId}&${params}`,
        );
        const { content } = data;
        setTableData(content);
      } catch (err) {
        setTableData([]);
        if (!err?.response?.data?.message) {
          Notiflix.Notify.failure(
            'Ops, não conseguimos buscar os dados do servidor.Por favor, tente novamente mais tarde',
          );
        }
      } finally {
        Notiflix.Block.remove('.notiflix-table-loading');
      }
    },
    [contract],
  );

  const handleCancelRegister = useCallback(async (row: RowProps) => {
    const cancelRegister = async () => {
      try {
        Notiflix.Block.circle('.notiflix-cancel-register');
        const { data } = await api.put(`/company-product/cancel-solicitation`, {
          solicitationNumber: row.solicitationNumber,
        });
        Notiflix.Block.remove('.notiflix-cancel-register');
        Modal2.Close();
        const { message } = data;
        Notiflix.Notify.success(message);

        setTableData((prev) =>
          produce(prev, (draft) => {
            const rowFinded = draft.find(
              (i) => i.solicitationNumber === row.solicitationNumber,
            );
            if (rowFinded) {
              rowFinded.status = 'Solicitação Cancelada';
              rowFinded.allowsCancel = 'N';
            }
          }),
        );
      } catch (err) {
        Notiflix.Block.remove('.notiflix-cancel-register');
        if (err.response?.data?.message) {
          Notiflix.Notify.failure(err.response?.data?.message);
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos cancelar o registro no servidor. Por favor, tente novamente mais tarde.',
          );
        }
      }
    };

    Modal2.Warning({
      closable: true,
      autoWidth: true,
      title: 'Confirmação',
      text: 'Deseja realmente cancelar esse registro?',
      children: (
        <>
          <Button
            modal
            secondary
            onClick={() => Modal2.Close()}
            style={{ marginBottom: '8px' }}
          >
            Não
          </Button>
          <Button
            modal
            onClick={() => cancelRegister()}
            className="notiflix-cancel-register"
          >
            Sim
          </Button>
        </>
      ),
    });
  }, []);

  const editAlterationProduct = useCallback(
    async (row: RowProps) => {
      const { userUnimedId, newProductCode: productId } = row;

      history.push(`/beneficiarios/5/${userUnimedId}/${productId}`);
    },
    [history],
  );

  const handleGenerateReport = useCallback(
    async (row: RowProps, notiflix: string) => {
      const { solicitationNumber } = row;
      try {
        Notiflix.Block.circle(notiflix);
        const { data } = await api.get(
          `/report/list-of-company-portal-reports?contractId=${contract.contractId}&exhibitionLocation=5`,
        );
        const { nrSeqRelatorio, Parametros: P } = data.content[0];
        const params = [solicitationNumber];
        const body = {
          nrSeqRelatorio: nrSeqRelatorio ?? '',
          Parametros: [
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...P.map((p: { cdParametro: any }, index: any) => {
              return {
                id: p.cdParametro,
                valor: params[index],
              };
            }),
          ],
        };

        const { data: dataReport } = await api.post(
          `/report/generate-report`,
          body,
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(dataReport);
      } catch (err) {
        if (err?.response?.status === 400) {
          Notiflix.Notify.info(err.response.data?.message);
        } else {
          Notiflix.Notify.failure(
            'Não conseguimos buscar seu relatório. Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [contract],
  );

  const handleFilter = useCallback(async () => {
    if (!reportTo.value) {
      setReportTo((prev) => ({
        ...prev,
        error: 'Informe uma intervalo de período.',
      }));
      return;
    }
    if (!reportFrom.value && reportTo.value) {
      setReportFrom((prev) => ({
        ...prev,
        error: 'Informe uma intervalo de período.',
      }));
      return;
    }

    const filters = [] as string[];
    if (selectRef.current?.value) {
      filters.push(`status=${selectRef.current.value}`);
    }
    if (reportFrom.value && reportTo.value) {
      filters.push(`startDate=${dateToPTBR(reportFrom.value)}`);
      filters.push(`endDate=${dateToPTBR(reportTo.value)}`);
    }
    getTableData(filters);
  }, [getTableData, reportFrom.value, reportTo.value]);

  useEffect(() => {
    if (contract.contractId) {
      getOptions();
      getTableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  const [rowExpanded, setRowExpanded] = useState([] as Row[]);

  const templateRowExpanded = useCallback(
    (row: RowProps) => {
      return (
        <ActionsContainer>
          <Button
            sausageLook
            autoWidth
            className={`notiflix-${row.solicitationNumber}`}
            whiteButton
            onClick={() =>
              handleGenerateReport(row, `.notiflix-${row.solicitationNumber}`)
            }
          >
            Imprimir
          </Button>
          {row.status === 'Solicitação Rejeitada' && (
            <Button
              style={{
                marginLeft: '5px',
              }}
              sausageLook
              autoWidth
              whiteButton
              onClick={() =>
                Modal2.Generic({
                  closable: true,
                  subtitle: 'Detalhes',
                  autoWidth: true,
                  text: row.rejectObservation
                    ? row.rejectObservation
                    : 'Nenhum detalhe para exibir.',
                })
              }
            >
              Detalhes
            </Button>
          )}
          {row.allowsCancel === 'S' && (
            <Button
              float="right"
              sausageLook
              autoWidth
              whiteButton
              onClick={() => handleCancelRegister(row)}
            >
              Cancelar
            </Button>
          )}
          {row.hasAttachment === 'N' && row.status === 'Pendente de Análise' && (
            <Button
              float="right"
              sausageLook
              autoWidth
              whiteButton
              onClick={() => editAlterationProduct(row)}
              style={{
                marginRight: '5px',
              }}
            >
              Inserir Anexo
            </Button>
          )}
        </ActionsContainer>
      );
    },
    [editAlterationProduct, handleCancelRegister, handleGenerateReport],
  );

  interface Row {
    solicitationNumber: string;
    solititationData: string;
    beneficiary: string;
    product: string;
    observations: string;
    status: string;
  }

  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <Container>
        <Title themeColor={themeColor}>Consultar Alteração de Produto</Title>
        <FormWrapper formLook onSubmit={handleFilter}>
          <Select
            ref={selectRef}
            options={options}
            className="notiflix-option-code"
            title="Status:"
            name="filters"
            formLook
            themeColor={themeColor}
          />
          <DatePicker
            name="from"
            onChange={(date: Date) => setReportFrom({ value: date, error: '' })}
            selected={reportFrom.value}
            error={reportFrom.error}
            icon
            formLook
            title="Período de:"
            widthContainerDesktop="40%"
            themeColor={themeColor}
          />

          <DatePicker
            name="to"
            onChange={(date: Date) => setReportTo({ value: date, error: '' })}
            selected={reportTo.value}
            error={reportTo.error}
            icon
            formLook
            title="Até:"
            widthContainerDesktop="40%"
            themeColor={themeColor}
          />
          <Button grayButton formLook type="submit">
            Filtrar
          </Button>
        </FormWrapper>
        <Table
          items={tableData}
          className="notiflix-table-loading"
          rowExpansionTemplate={templateRowExpanded}
          // onRowToggle={(e) => setExpandedRows(e.data)}
          onRowToggle={(e) => setRowExpanded([e.data[e.data.length - 1]])}
          onRowClick={(e) => {
            if (
              rowExpanded.length > 0 &&
              rowExpanded[0]?.solicitationNumber !== e.data?.solicitationNumber
            ) {
              setRowExpanded([]);
              return setRowExpanded([e.data]);
            }
            return rowExpanded.length === 0
              ? setRowExpanded([e.data])
              : setRowExpanded([]);
          }}
          expandedRows={rowExpanded}
          onRowExpand={(e) =>
            window.innerWidth <= 768 &&
            setPositionOfRowExpanded(e.originalEvent)
          }
        >
          <Column
            field="solititationNumber"
            header="Número solicitação"
            sortable
            style={{ width: '140px' }}
            body={(data: RowProps) =>
              AddField(String(data.solicitationNumber), 'Número solicitação')
            }
          />
          <Column
            field="solititationData"
            header="Data solicitação"
            className="date"
            body={(data: RowProps) =>
              addField(data.solicitationDate, 'Data solicitação')
            }
          />
          <Column
            field="beneficiary"
            header="Beneficiário"
            sortable
            body={(data: RowProps) =>
              addField(data.beneficiaryName, 'Beneficiário')
            }
          />
          <Column
            field="product"
            header="Produto"
            sortable
            body={(data: RowProps) => addField(data.productName, 'Produto')}
          />
          {/* <Column
            field="contract"
            header="Contrato"
            sortable
            body={(data: RowProps) =>
              addField(String(data.newProductCode), 'Contrato')
            }
          /> */}
          <Column
            field="observations"
            header="Observações"
            sortable
            body={(data: RowProps) => addField(data.observation, 'Observações')}
          />
          <Column
            field="status"
            header="Status"
            className="tag"
            style={{ width: '160px' }}
            sortable
            body={(data: RowProps) => {
              switch (data.status) {
                case 'Solicitação Cancelada':
                  return getTag('Status', data.status, 'red', data.status);
                case 'Solicitação Confirmada':
                  return getTag('Status', data.status, 'green', data.status);
                case 'Pendente de Análise':
                  return getTag('Status', data.status, 'orange', data.status);
                case 'Solicitação Rejeitada':
                  return getTag('Status', data.status, 'red', data.status);
                default:
                  return getTag('Status', data.status, 'orange', data.status);
              }
            }}
          />
          <Column sortable expander headerStyle={{ width: '60px' }} />
        </Table>
      </Container>
    </PageWrapper>
  );
};

export default ConsultProductChange;
