import styled from 'styled-components';
import TitleComponent from '../../components/Title';
import FormWrapperContainer from '../../components/FormWrapper';
import ButtonComponent from '../../components/Button';

export const Container = styled.div`
  grid-column: 2/3;

  @media (max-width: 769px) {
    grid-column: 1;
  }
`;

export const Title = styled(TitleComponent)``;

export const FormWrapper = styled(FormWrapperContainer)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;

export const Button = styled(ButtonComponent)`
  margin-left: auto;
  margin-right: 10px;
`;
