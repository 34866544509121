import styled from 'styled-components';
import ButtonWithOptionsComponent from '../../../components/ButtonWithOptions';
import BWOBeneficiaryComponent from '../../../components/ButtonWithOptions/ButtonWithOptionsBeneficiary';
import CarouselImage from '../../../components/Carousel';

import PageWrapper from '../../../components/PageWrapper';

export const Container = styled(PageWrapper)`
  margin-top: 20px;
  @media (max-width: 768px) {
    margin-top: 0px;
    row-gap: 30px;
    margin-bottom: 30px;
  }
`;

export const Carousel = styled(CarouselImage)`
  @media (min-width: 769px) {
    grid-area: cl;
  }
  @media (max-width: 768px) {
    grid-column: 1/2;
  }
`;

export const MyContract = styled.div`
  @media (min-width: 769px) {
    grid-area: mc;
    margin-top: 12px;
    > div {
      display: flex;
      flex-wrap: wrap;
      column-gap: 8px;
    }
  }
  @media (max-width: 768px) {
    grid-column: 1/2;
    padding: 0 30px;
  }
  form {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
    & + form {
      @media (max-width: 768px) {
        margin-top: 8px;
      }
    }
  }
`;
export const MyContractHeader = styled.div`
  width: 100%;
  display: flex;
  h4 {
    font-size: 1rem;
    line-height: 22px;
    font-family: 'Unimed-Sans-Bold';
    color: ${(props) => props.theme.colors.palet.institutional};
    text-transform: uppercase;
    display: inline;
  }
  > button {
    margin-left: auto;
    width: 180px;
    font-size: 0.65rem;
    padding: 0;
    line-height: 22px;
    height: 24px;
    margin-bottom: 8px;
  }
  > div {
    margin-left: auto;
    min-width: 180px;
    width: auto;
    > div {
      min-height: 24px;
      > button {
        font-size: 0.65rem;
        padding: 0 8px;
        padding-right: 30px;
        line-height: 22px;
        height: auto;
        min-height: 24px;
        margin-bottom: 8px;
        font-family: 'Unimed-Sans-SemiBold';
        > svg {
          right: 8px;
        }
      }
    }
    > div.min-select {
      input {
        font-size: 0.65rem;
        line-height: 0.65rem;
        padding: 4px 46px 4px 8px;
        min-height: 24px;
      }
    }
  }
`;

export const Title = styled.h2`
  font-size: 1.7rem;
  line-height: 1.7rem;
  letter-spacing: -1.19209px;
  text-transform: uppercase;
  font-family: 'Unimed-Slab-Bold';
  color: ${(props) => props.theme.colors.text.primary};
  margin-bottom: 4px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const QuickAccessContainer = styled.div`
  @media (min-width: 769px) {
    grid-area: qa;
    margin-top: 12px;
  }
  @media (max-width: 768px) {
    grid-column: 1/2;
    padding: 0 30px;
  }
`;

export const QuickRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
  & + div {
    margin-top: 8px;
  }
`;

export const QuickColumn = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 8px;
  flex: 1 1;
  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`;

export const ButtonWithOptions = styled(ButtonWithOptionsComponent)`
  margin-top: 0px !important;
  flex-wrap: wrap;
  width: 100%;
  h3 {
    line-height: 1.55rem;
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
  }
  &.title-full-width {
    h3 {
      width: 100%;
    }
    > div {
      a {
        flex: 1 1 50%;
        @media (max-width: 768px) {
          flex: 1 1 100%;
        }
      }
    }
  }
`;
export const ButtonWithOptionsBeneficiary = styled(BWOBeneficiaryComponent)`
  margin-top: 0px !important;
  flex-wrap: wrap;
  width: 100%;
  h3 {
    line-height: 1.55rem;
    cursor: default !important;
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
  }
  &.title-full-width {
    h3 {
      width: 100%;
    }
    > div {
      a {
        flex: 1 1 50%;
        @media (max-width: 768px) {
          flex: 1 1 100%;
        }
      }
    }
  }

  /* &.beneficiary-button {
    svg {
      display: none;
    }
  } */
`;

export const Contract = styled.div`
  @media (min-width: 769px) {
    grid-area: ct;
    margin-top: 12px;
  }
  @media (max-width: 768px) {
    grid-column: 1/2;
    /* padding: 0 30px; */
  }
`;

export const TableContent = styled.div`
  @media (max-width: 768px) {
    td.home-buttons-company {
      width: 100%;
    }
  }
`;

export const TableButtons = styled.div`
  display: flex;
  /* column-gap: 8px; */
  > button {
    width: 100px;
    & + button {
      margin-left: 8px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    > button {
      & + button {
        margin-left: 0px;
      }
    }
  }
`;

export const ModalUnitContent = styled.div`
  h2 {
    font-family: 'Unimed-Slab-Bold';
    font-size: 1.7rem;
    color: ${(props) => props.theme.colors.text.primary};
    margin-bottom: 30px;
  }
  form {
    button {
      margin-top: 8px;
      height: 48px;
    }
  }
`;
