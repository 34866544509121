import styled, { css } from 'styled-components';
import { TitleProps } from '.';

export const Title = styled.h1<TitleProps>`
  font-family: 'Unimed-Slab-Bold';
  font-size: 2.55rem;
  line-height: 2.4rem;

  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional2};
  padding: 30px 0;
  @media (max-width: 1200px) {
    font-size: 2.25rem;
    line-height: 2.1rem;
  }
  @media (max-width: 768px) {
    font-size: 1.7rem;
    line-height: 1.5rem;
    padding: 30px;
  }
  ${(props) =>
    props.themeColor &&
    css`
      color: ${props.themeColor};
    `};
`;

export const TitleIntern = styled.h2<TitleProps>`
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 1.6rem;
  line-height: 1.6rem;

  text-align: left;
  letter-spacing: -1.19px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional};
  margin-bottom: 8px;
  @media (max-width: 768px) {
    font-family: 'Unimed-Sans-Bold';
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
  }
  ${(props) =>
    props.themeColor &&
    css`
      color: ${props.themeColor};
    `};
`;
