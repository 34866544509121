/* eslint-disable no-unused-expressions */
import React, { useCallback, useRef, useState, useEffect } from 'react';
import Notiflix from 'notiflix';
import { v4 } from 'uuid';
import { produce } from 'immer';
import { useHistory } from 'react-router-dom';
import {
  Container,
  FormWrapper,
  ContainerImport,
  ContainerImportButton,
} from './styles';
import PageWrapper from '../../components/PageWrapper';
import { TitleIntern } from '../../components/Title/styles';
import Title from '../../components/Title';
import Button from '../../components/Button';
import api from '../../services/api/api';
import InputFile, { InputFileHandles } from '../../components/InputFile';
import { useTheme } from '../../hooks/theme';
import Table, { Column } from '../../components/Table';
import AddField, {
  getTag,
  setPositionOfRowExpanded,
} from '../../utils/tableUtils';
import { ButtonsActionsContainer } from '../../components/Table/styles';
import Modal2 from '../../components/Modal2';
import { useAuth } from '../../hooks/auth';
import { useContract } from '../../hooks/contract';
import ModalHtml from '../../components/ModalHtml';

interface RowProps {
  accessionDate: string;
  bulkId: string;
  reasonForRejection: string;
  releaseDate: string;
  requesterUsername: string;
  sentDate: string;
  situation: string;
}

const BeneficiaryInclusionPerLot: React.FC = () => {
  const { theme } = useTheme();
  const history = useHistory();
  const { user } = useAuth();
  const { contract } = useContract();
  const inputfileRef = useRef<InputFileHandles>(null);
  const [dataTable, setDataTable] = useState<RowProps[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [expandedRows, setExpandedRows] = useState([] as any[]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [translations, setTranslations] = useState([] as any[]);
  const [open, setOpen] = useState(false);

  const getDocuments = useCallback(async () => {
    try {
      const { data } = await api.get(
        `gw/translation?key=modelo-inclusao-lote-xlsx%2Cmodelo-inclusao-lote-txt`,
      );
      const { content } = data;
      const values = Object.values(content);
      setTranslations(values);
    } catch (error) {
      console.log(error);
    }
  }, []);

  /**
   * @description Get API data to populate table
   */
  const getDataTable = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table');

      const { data } = await api.get(
        // `beneficiary-inclusion-bulk-import/list-inclusion-bulk?contractId=142330`,
        `beneficiary-inclusion-bulk-import/list-inclusion-bulk?contractId=${contract.contractId}`,
      );
      setDataTable(data?.content);
    } catch (err) {
      if (err.response.status === 400) return;
      console.log(err);
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os dados do servidor. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-table');
    }
  }, [contract.contractId]);

  const handleSendFile = useCallback(async () => {
    try {
      if (!inputfileRef.current?.files) {
        inputfileRef.current?.setError('É necessário enviar um arquivo.');
        return;
      }
      Notiflix.Loading.circle();
      const form = new FormData();
      form.append('contractNumber', contract.contractId);
      if (inputfileRef.current.files[0].type === 'text/plain') {
        form.append('textFile', inputfileRef.current.files[0]);
        const { data } = await api.post(
          '/beneficiary-inclusion/include-by-txt',
          form,
        );
        if (data.result === 1) {
          Notiflix.Notify.success(data.message);
          setOpen(true);
          getDataTable();
          // setExpandedRows(data.content[0]);
          inputfileRef.current.setFiles([]); // limpar input anexo
        } else {
          Notiflix.Notify.failure(data.message);
        }
      } else {
        form.append('excelFile', inputfileRef.current.files[0]);
        const { data } = await api.post(
          '/beneficiary-inclusion/include-by-excel',
          form,
        );
        if (data.result === 1) {
          Notiflix.Notify.success(data.message);
          setOpen(true);
          getDataTable();
          // setExpandedRows(data.content[0]);
          inputfileRef.current.setFiles([]); // limpar input anexo
        } else {
          // report
          Notiflix.Notify.failure(data.message);
        }
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 400) {
        const errors = error?.response?.data?.message;
        if (errors && Array.isArray(errors)) {
          const formattedMessage = `<ul class="listaerros-uplote">${errors
            .map((errorlista) => `<li>${errorlista}</li>`)
            .join('')}</ul>`;

          ModalHtml.Failure({
            subtitle: 'Verifique os problemas apontados abaixo:',
            text: formattedMessage,
            title: 'Ops..',
            closable: true,
          });
        } else {
          Notiflix.Notify.failure(errors);
        }

        Notiflix.Loading.remove();
      } else {
        console.log(error);
      }
    } finally {
      Notiflix.Loading.remove();
    }
  }, [contract.contractId, getDataTable]);

  useEffect(() => {
    if (open) {
      setExpandedRows(
        dataTable.filter((el) => el.bulkId === dataTable[0].bulkId),
      );
    }
  }, [open, dataTable]);
  /**
   * @description Function to call API to remove register and remove the table line
   */
  const handleExcludeRow = useCallback(
    async (row: RowProps, notiflixClass: string) => {
      try {
        Notiflix.Block.circle(notiflixClass);
        const data = {
          bulkId: row.bulkId,
          webUsername: row.requesterUsername,
        };
        const response = await api.put(
          `beneficiary-inclusion-bulk-import/delete-inclusion-bulk`,
          data,
        );
        Notiflix.Notify.success(response.data.message);
        setDataTable((prev) => prev.filter((i) => i.bulkId !== row.bulkId));
      } catch (err) {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos excluir o registro no servidor. Por favor, tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.remove(notiflixClass);
      }
    },
    [],
  );

  /**
   * @description Function to go to details of the table line
   */
  const handleGoToDetailsOfRow = useCallback(
    async (row: RowProps, notiflixClass: string) => {
      try {
        Notiflix.Block.circle(notiflixClass);
        history.push(`/detalhes-lote/${row.bulkId}/${row.situation}`);
      } catch (err) {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos acessar os detalhes do registro no servidor. Por favor, tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.remove(notiflixClass);
      }
    },
    [history],
  );

  /**
   * @description Function to call API to release of the table line and refresh info on the table line
   */
  const handleReleaseRow = useCallback(
    async (row: RowProps, notiflixClass: string) => {
      try {
        Notiflix.Block.circle(notiflixClass);
        const data = {
          bulkId: row.bulkId,
          webUsername: row.requesterUsername,
        };
        const response = await api.put(
          `beneficiary-inclusion-bulk-import/release-inclusion-bulk`,
          data,
        );
        Notiflix.Notify.success('Lote liberado com sucesso.');
        setDataTable((prev) =>
          produce(prev, (draft) => {
            const found = draft.find((i) => i.bulkId === row.bulkId);
            if (found) {
              found.reasonForRejection = 'N';
              found.situation = 'Liberado';
            }
          }),
        );
      } catch (err) {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos liberar o registro no servidor. Por favor, tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.remove(notiflixClass);
        getDataTable();
      }
    },
    [],
  );

  const handleGetReasonForRejection = useCallback(
    (reasonForRejection: string) => {
      Modal2.Generic({
        autoWidth: true,
        closable: true,
        title: `Motivo para Rejeição`,
        children: (
          <>
            <p style={{ marginBottom: '16px' }}>{reasonForRejection}</p>
            <Button autoWidth outlined secondary onClick={() => Modal2.Close()}>
              Ok
            </Button>
          </>
        ),
      });
    },
    [],
  );

  /**
   * @description Template of expanded row
   */
  const rowExpansionTemplate = useCallback(
    (row: RowProps) => {
      const buttons = [
        {
          title: row.situation.toLowerCase() !== 'liberado' ? 'Excluir' : '',
          onClick: handleExcludeRow,
        },
        {
          title: 'Detalhes',
          onClick: handleGoToDetailsOfRow,
        },
        {
          title:
            row.situation.toLowerCase() === 'pendente para liberação'
              ? 'Liberar Lote'
              : '',
          onClick: handleReleaseRow,
        },
        // {
        //   title:
        //     row.situation.toLowerCase() === 'com inconsistências'
        //       ? 'Ver Inconsistências'
        //       : '',
        //   onClick: handleGetInconsistencies,
        // },
        {
          title:
            row.situation.toLowerCase() ===
            'solicitação rejeitada pela operadora'
              ? 'Observações'
              : '',
          onClick: () => handleGetReasonForRejection(row.reasonForRejection),
        },
      ].filter((i) => !!i.title);
      return (
        <ButtonsActionsContainer>
          {buttons.map((button, index) => {
            const uuid = v4();
            return (
              <Button
                key={button.title}
                modal
                autoWidth
                sausageLook
                whiteButton
                className={`notiflix-${uuid} ${index === 0 && 'marginLeft'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  button.onClick(row, `.notiflix-${uuid}`);
                }}
              >
                {button.title}
              </Button>
            );
          })}
        </ButtonsActionsContainer>
      );
    },
    [
      handleExcludeRow,
      handleGoToDetailsOfRow,
      handleReleaseRow,
      // handleGetInconsistencies,
      handleGetReasonForRejection,
    ],
  );

  useEffect(() => {
    getDocuments();
    getDataTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <Container>
        <Title themeColor={theme.colors.palet.institutional}>
          Importação por Lote
        </Title>
        <ContainerImport>
          <div>
            <FormWrapper
              formLook
              onSubmit={() => {
                console.log('N/A');
              }}
            >
              <TitleIntern style={{ textAlign: 'center' }}>
                Modelos de Importação
              </TitleIntern>
              <ContainerImportButton>
                <Button
                  formLook
                  greenPrimary
                  autoWidth
                  className="spreadsheet"
                  onClick={() => window.open(translations[0])}
                >
                  MODELO DE PLANILHA
                </Button>

                <Button
                  formLook
                  autoWidth
                  type="button"
                  className="text"
                  onClick={() => window.open(translations[1])}
                >
                  MODELO ARQUIVO DE TEXTO
                </Button>
              </ContainerImportButton>
            </FormWrapper>
          </div>
          <div>
            <FormWrapper formLook onSubmit={() => handleSendFile()}>
              <TitleIntern style={{ textAlign: 'center' }}>
                Envie seu arquivo
              </TitleIntern>
              <ContainerImportButton>
                <InputFile
                  className="mt-40"
                  placeholder="Aceita apenas .xlsx, .xls e .txt"
                  ref={inputfileRef}
                  accept="text/plain, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <Button type="submit" className="submit-button" formLook>
                  ENVIAR
                </Button>
              </ContainerImportButton>
            </FormWrapper>
          </div>
        </ContainerImport>
        <Table
          items={dataTable}
          className="notiflix-table"
          // scrollable={window.innerWidth > 768}
          expandedRows={expandedRows}
          onRowToggle={(e) => {
            setExpandedRows(e.data);
          }}
          onRowExpand={(e) =>
            window.innerWidth <= 768 &&
            setPositionOfRowExpanded(e.originalEvent)
          }
          rowExpansionTemplate={rowExpansionTemplate}
          onRowClick={(e) => {
            if (e.data) {
              setExpandedRows([e.data]);
            } else {
              setExpandedRows([]);
            }
          }}
        >
          <Column
            field="bulkId"
            header="Lote"
            body={(row) => AddField(row.bulkId, 'Lote')}
            headerClassName="no-rounded"
            sortable
            style={{
              // minWidth: '100px',
              overflow: 'initial',
              textOverflow: 'initial',
              whiteSpace: 'pre-wrap',
            }}
          />
          <Column
            field="requesterUsername"
            header="Usuário envio"
            sortable
            style={{
              // minWidth: '200px',
              overflow: 'initial',
              textOverflow: 'initial',
              whiteSpace: 'pre-wrap',
            }}
            body={(row) => AddField(row.requesterUsername, 'Usuário envio')}
          />
          <Column
            field="sentDate"
            header="Data Envio"
            className="date"
            body={(row) => AddField(row.sentDate, 'Data Envio')}
          />
          <Column
            field="accessionDate"
            header="Data Adesão"
            className="date"
            body={(row) => AddField(row.accessionDate, 'Data Adesão')}
          />
          <Column
            field="releaseDate"
            header="Data Liberação"
            className="date"
            body={(row) => AddField(row.releaseDate, 'Data Liberação')}
          />
          <Column
            field="situation"
            header="Situação"
            className="tag"
            sortable
            style={{ width: '120px' }}
            body={(row) => {
              switch (row.situation.toLowerCase()) {
                case 'solicitação rejeitada pela operadora':
                  return getTag(
                    'Situação',
                    'Sol/Rejeitada',
                    'red',
                    'Solicitação rejeitada pela operadora',
                  );
                case 'aguardando ação usuário':
                  return getTag(
                    'Situação',
                    'Aguard. ação usuário',
                    'orange',
                    'Aguardando ação usuário',
                  );
                case 'pendente para liberação':
                  return getTag(
                    'Situação',
                    'Pend/Liberação',
                    'orange',
                    'Pendente para liberação',
                  );
                case 'integrado a proposta':
                  return getTag(
                    'Situação',
                    'Integ. Proposta',
                    'orange',
                    'Integrado a proposta',
                  );
                case 'com inconsistências':
                  return getTag(
                    'Situação',
                    'C/Inconsistências',
                    'orange',
                    'Com inconsistências',
                  );
                case 'liberado':
                  return getTag('Situação', 'Liberado', 'green', 'Liberado');
                default:
                  return <>{row.situation}</>;
              }
            }}
          />

          <Column
            header=""
            expander
            headerStyle={{ width: '60px' }}
            // className="only-mobile"
          />
        </Table>
      </Container>
    </PageWrapper>
  );
};

export default BeneficiaryInclusionPerLot;
