export const STORAGE_FILTER = '@UnimedFilterConsulta';
export function saveFilterLocalStorage(data: Record<string, any>): void {
  try {
    localStorage.setItem(
      STORAGE_FILTER,
      JSON.stringify({
        prevFilter: data,
        goBackClicked: false,
      }),
    );
  } catch (err) {
    console.error('Erro ao salvar dados no localStorage', err);
  }
}

export const removeFilterLocalStorage = (): void => {
  localStorage.removeItem(STORAGE_FILTER);
};

interface ReturnLoadingDataFilterLocalStorage {
  doctor: string;
  startDate: string;
  endDate: string;
  speciality: string;
  city: string;
  typeId: string;
}
export function loadingDataFilterLocalStorage(): ReturnLoadingDataFilterLocalStorage {
  try {
    const local = localStorage.getItem(STORAGE_FILTER);
    if (local) {
      const parsed = JSON.parse(local);
      const { prevFilter, goBackClicked } = parsed;
      if (!goBackClicked) {
        return {} as ReturnLoadingDataFilterLocalStorage;
      }
      const formattedData = {
        ...prevFilter,
        startDate: prevFilter.startDate,
        endDate: prevFilter.endDate,
        typeId: prevFilter.typeId,
        doctor: prevFilter.doctorName ?? '',
      };

      return formattedData as ReturnLoadingDataFilterLocalStorage;
    }
    return {} as ReturnLoadingDataFilterLocalStorage;
  } catch (error) {
    console.error('Erro ao buscar os dados do localStorage', error);
    return {} as ReturnLoadingDataFilterLocalStorage;
  } finally {
    removeFilterLocalStorage();
  }
}

export const getTypeIdFromLocalStorage = (): string | null => {
  try {
    const local = localStorage.getItem(STORAGE_FILTER);
    if (!local) return null;
    const parsed = JSON.parse(local);
    const { prevFilter } = parsed;
    return prevFilter.typeId ?? null;
  } catch (err) {
    return null;
  }
};

export const handleGoBackClicked = (): void => {
  try {
    const local = localStorage.getItem(STORAGE_FILTER);
    if (!local) return;
    const parsed = JSON.parse(local);
    localStorage.setItem(
      STORAGE_FILTER,
      JSON.stringify({
        ...parsed,
        goBackClicked: true,
      }),
    );
  } catch (err) {
    //
  }
};
