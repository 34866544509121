import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import FormWrapper from '../../components/FormWrapper';

import PageWrapper from '../../components/PageWrapper';
import { Column } from '../../components/Table';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api/api';
import AddField, { getTag } from '../../utils/tableUtils';

import { Title, Table, Select, Container } from './styles';

const RequestRefundConsult: React.FC = () => {
  const { user } = useAuth();
  const { colors } = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [consultDatas, setConsultDatas] = useState<any[]>([]);

  const [beneficiaries, setBeneficiariesOptions] = useState(() => {
    const options = [
      {
        title: user.name,
        value: `${user.id}`,
      },
    ];

    return options;
  });

  const getParents = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-parents');
      const { data } = await api.get(
        `/beneficiary/dependent-listing?userUnimedId=${user.id}`,
      );

      if (data.content) {
        setBeneficiariesOptions((prev) => {
          const newValues = data.content.reduce(
            (
              acc: { title: string; value: string }[],
              act: { dsNomeDependente: string; nrSeqDependente: string },
            ) => {
              acc.push({
                title: act.dsNomeDependente,
                value: act.nrSeqDependente,
              });
              return acc;
            },
            [],
          );
          return [...prev, ...newValues];
        });
      }
    } catch (err) {
      if (err.response.status !== 400) {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dependentes... Tente novamente mais tarde',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-parents');
    }
  }, [user]);

  const getData = useCallback(async (id) => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');
      const { data } = await api.get(
        `/beneficiary/list-refund-requests?userUnimedId=${id}`,
      );

      const { content } = data;
      setConsultDatas(content);
    } catch (error) {
      setConsultDatas([]);
      if (error.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Ops.. Não conseguimos buscar os dados do servidor. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, []);

  useEffect(() => {
    getData(user.id);
    getParents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusTemplate = useCallback((situation) => {
    switch (situation) {
      case 'Recebido':
        return getTag('Status', 'Recebido', 'gray', situation);
      case 'Em análise':
        return getTag('Status', 'Em análise', 'yellow', situation);
      case 'Analisando e aguardando liberação':
        return getTag(
          'Status',
          'Analisando e aguardando liberação',
          'blue',
          situation,
        );
      case 'Liberado para pagamento':
        return getTag('Status', 'Liberado para pagamento', 'green', situation);
      case 'Encerrado sem pagamento':
        return getTag('Status', 'Encerrado sem pagamento', 'red', situation);
      case 'Pago':
        return getTag('Status', 'Pago', 'indigo', situation);
      case 'Encerrado A700':
        return getTag('Status', 'Encerrado A700', 'indigo', situation);
      default:
        return getTag('Status', 'Solicitado', 'orange', situation);
    }
  }, []);

  return (
    <PageWrapper
      gridTemplateColumns="repeat(10,1fr)"
      gridTemplateRows="auto auto 1fr"
    >
      <Title>Consulta de Solicitação de reembolso</Title>

      <Container>
        <FormWrapper
          formLook
          onSubmit={() => {
            //
          }}
        >
          <Select
            formLook
            className="notiflix-parents"
            title="Beneficiário"
            name="typeuser"
            changeCallback={getData}
            options={beneficiaries}
            disabled={user.profile === 1 && !user.isOwner}
            themeColor={colors.palet.institutional9}
          />
        </FormWrapper>
        <Table items={consultDatas} className="notiflix-table-loading">
          <Column
            field="beneficiaryName"
            header="Beneficiário"
            sortable
            body={(data) => AddField(data.beneficiaryName, 'Beneficiário')}
          />
          <Column
            field="protocolId"
            header="Protocolo de atend."
            sortable
            body={(data) => AddField(data.protocolId, 'Protocolo de Atend.')}
          />
          <Column
            field="protocolDate"
            header="Data do Protocolo"
            className="date"
            body={(data) => AddField(data.protocolDate, 'Data do Protocolo')}
          />
          <Column
            field="presentedValue"
            className="currency"
            header="Valor Apresentado"
            sortable
            body={(data) =>
              AddField(
                data.presentedValue.toString(),
                'Valor Apresentado',
                true,
              )
            }
          />
          <Column
            field="releasedAmount"
            header="Valor Liberado"
            className="currency"
            sortable
            body={(data) =>
              AddField(data.releasedAmount.toString(), 'Valor Liberado', true)
            }
          />
          <Column
            field="initialsSituation"
            header="Status"
            sortable
            className="tag"
            body={(data) => statusTemplate(data?.initialsSituation)}
          />
        </Table>
      </Container>
    </PageWrapper>
  );
};

export default RequestRefundConsult;
