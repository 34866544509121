import React, { useCallback, useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import { useHistory } from 'react-router-dom';
import Title from '../../../components/Title';
import { useTheme } from '../../../hooks/theme';
import {
  Col,
  Container,
  Content,
  Message,
  Photo,
  Row,
  TitlePeriod,
  ButtonPhoto,
  ContentGrid,
  ButtonGoBack,
} from './styles';
import PageWrapper from '../../../components/PageWrapper';
import api from '../../../services/api/api';
import SelectUnform from '../../../components/SelectUnform';
import Modal2 from '../../../components/Modal2';
import Button from '../../../components/Button';
import FormWrapper from '../../../components/FormWrapper';

interface periodProp {
  id: string;
  name: string;
}
interface PhotoPeriodProp {
  cdFoto: number;
  dtFoto: string;
  imgFoto: string;
}
interface PhotoProp {
  id: number;
  image: string;
  text: string;
  title: string;
}

const Photos: React.FC = () => {
  const { theme } = useTheme();
  const defaultColor = theme.colors.palet.institutional8;
  const history = useHistory();

  const [period, setPeriod] = useState<periodProp[]>();
  const [periodId, setPeriodId] = useState('');
  const [photoPeriod, setPhotoPeriod] = useState<PhotoPeriodProp[]>();
  const [titlePeriod, setTitlePeriod] = useState('Março/2023');
  const [photo, setPhoto] = useState('');
  const [viewPhoto, setViewPhoto] = useState('');
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const [loadingPeriod, setLoadingPeriod] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [visited, setVisited] = useState(false);
  const [codePhoto, setCodePhoto] = useState(0);

  const getPhotos = useCallback(async (photoId) => {
    try {
      setLoadingPhoto(true);
      Notiflix.Block.circle('.notiflix-loading-photos', 'Carregando', {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
      });
      const { data } = await api.get(
        `/first-aid/curative-photo-code?photoId=${photoId}`,
      );
      setViewPhoto(data.content.data[0].imgFoto);
      setLoadingPhoto(false);
    } catch (err) {
      if (err?.response?.status === 400) {
        Notiflix.Notify.info(err.response.data?.message);
      } else {
        Notiflix.Notify.failure(
          'Não conseguimos buscar a foto selecionada. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-loading-photos');
    }
  }, []);

  const getPhotosPeriod = useCallback(async (periodPhotoId) => {
    try {
      setLoadingPhoto(true);
      Notiflix.Block.circle('.notiflix-loading-photos', 'Carregando', {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
      });
      const { data } = await api.get(
        `/first-aid/curative-photo?periodDate=${periodPhotoId}`,
      );
      setPhotoPeriod(data.content.data);
      setViewPhoto(data.content.data[0].imgFoto);
      setLoadingPhoto(false);
    } catch (err) {
      if (err?.response?.status === 400) {
        Notiflix.Notify.info(err.response.data?.message);
      } else {
        Notiflix.Notify.failure(
          'Não conseguimos buscar as imagens. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-loading-photos');
    }
  }, []);

  const getPeriod = useCallback(async () => {
    try {
      // setLoadingPeriod(true);
      const { data } = await api.get(`/first-aid/curative-photo-period`);
      const { content } = data;
      setPeriod(
        content.data.reduce(
          (
            acc: { id: string; name: string }[],
            act: {
              dtPeriodo: string;
              dsPeriodo: string;
            },
          ) => {
            acc.push({
              id: act.dtPeriodo,
              name: act.dsPeriodo,
            });
            return acc;
          },
          [],
        ),
      );
      // setLoadingPeriod(false);
    } catch (err) {
      if (err.response) {
        Modal2.Failure({
          closable: true,
          autoWidth: true,
          title: 'Ooops...',
          text: err.response.data.message,
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });
      }
    }
  }, []);

  const handleOpenModal = useCallback(() => {
    if (period) {
      Modal2.Generic({
        autoWidth: true,
        title: 'Selecione o período',
        children: (
          <Col className="modalPeriod">
            <FormWrapper onSubmit={() => console.log()}>
              <SelectUnform
                name="periodExam"
                options={period}
                formLook
                widthContainerDesktop="33.33%"
                callbackOnChange={(name, id) => {
                  setPeriodId(id);
                  setTitlePeriod(name);
                  setDisabled(false);
                }}
              />
              <Row className="buttonModal">
                <Button
                  modal
                  grayButton
                  onClick={() => {
                    Modal2.Close();
                  }}
                >
                  Fechar
                </Button>
                <Button
                  modal
                  disabled={disabled}
                  onClick={() => {
                    getPhotosPeriod(periodId);
                    Modal2.Close();
                  }}
                >
                  Procurar
                </Button>
              </Row>
            </FormWrapper>
          </Col>
        ),
      });
    }
  }, [getPhotosPeriod, periodId, period, disabled]);

  useEffect(() => {
    getPeriod();
  }, [getPeriod]);

  useEffect(() => {
    if (period) {
      handleOpenModal();
    }
  }, [handleOpenModal, period]);

  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <Container>
        <Title themeColor={theme.colors.palet.institutional2}>Fotos</Title>
        <ButtonGoBack onClick={() => history.push('/ambulatorio-curativos')}>
          Voltar
        </ButtonGoBack>
        <Content className="notiflix-loading-photos">
          {!photoPeriod && !loadingPeriod && (
            <Message>Você não possui fotos a serem exibidas</Message>
          )}
          <ContentGrid>
            {photoPeriod && (
              <>
                <Col>
                  <TitlePeriod>{titlePeriod}</TitlePeriod>
                  {photoPeriod?.map((item, i) => {
                    return (
                      <Row
                        key={item.cdFoto}
                        className={
                          item.cdFoto === codePhoto
                            ? 'rowTable rowSelected'
                            : 'rowTable'
                        }
                      >
                        <b>Data: {item.dtFoto}</b>
                        <ButtonPhoto
                          onClick={() => {
                            getPhotos(item.cdFoto);
                            setCodePhoto(item.cdFoto);
                          }}
                        >
                          {item.cdFoto === codePhoto
                            ? 'Visualizando'
                            : 'Visualizar'}
                        </ButtonPhoto>
                      </Row>
                    );
                  })}
                </Col>
                {viewPhoto && !loadingPhoto && (
                  <Photo src={`data:image/png;base64,${viewPhoto}`} alt="" />
                )}
              </>
            )}
          </ContentGrid>
          <Row className="center rowButtonSearch">
            <Button
              modal
              onClick={() => {
                handleOpenModal();
                setDisabled(true);
              }}
            >
              Pesquisar novo período
            </Button>
          </Row>
        </Content>
      </Container>
    </PageWrapper>
  );
};

export default Photos;
