import Notiflix from 'notiflix';
import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { FiDownload } from 'react-icons/fi';
import { useTheme } from 'styled-components';

import Button from '../../components/Button';
import Input from '../../components/Input';
import PageWrapper from '../../components/PageWrapper';
import { Column } from '../../components/Table';
import { BeneficiaryUser, UserCommon, useAuth } from '../../hooks/auth';
import api from '../../services/api/api';
import AddField from '../../utils/tableUtils';
import { Document, Table, Form, Container } from './styles';

import Title from '../../components/Title';

const ManualAndFiles: React.FC = () => {
  const { user: userT } = useAuth();
  const user = userT as UserCommon & BeneficiaryUser;
  const { colors } = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [documents, setDocuments] = useState([] as any[]);
  const [filter, setFilter] = useState('');

  const handleSearch = useCallback((data) => {
    setFilter(data.search);
  }, []);

  const documentsContent = useMemo(() => {
    const filtered =
      filter === ''
        ? documents
        : documents.filter((item) =>
            item.title.toLowerCase().includes(filter.toLowerCase()),
          );
    return filtered;
  }, [filter, documents]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadBodyTemplate = useCallback((data: any) => {
    return (
      <Document href={data.fileUrl} download target="_blank">
        <FiDownload size={24} />
      </Document>
    );
  }, []);

  const getManualAndFiles = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-manuals-container');
      if (user.userUnimedId) {
        const { data } = await api.get(
          `/guide/list-guide?userUnimedId=${user.userUnimedId}`,
        );
        const { content } = data;
        if (content) {
          setDocuments(content);
        }
        setDocuments(content);
      } else if (user.contractId) {
        const { data } = await api.get(
          `/guide/list-guide?contractId=${user.contractId}`,
        );
        const { content } = data;
        if (content) {
          setDocuments(content);
        }
      }
    } catch (error) {
      if (error.respose?.status !== 400) {
        Notiflix.Notify.failure(
          'Ops.. Algo deu errado ao tentar buscar os manuais e arquivos no momento... Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-manuals-container');
    }
  }, [user]);

  useEffect(() => {
    getManualAndFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper gridTemplateRows="auto 1fr">
      <Container>
        <Title themeColor={colors.palet.institutional}>
          Manuais e Arquivos
        </Title>
        <Form onSubmit={handleSearch} formLook>
          <Input
            name="search"
            title="Busca:"
            formLook
            themeColor={colors.palet.institutional}
          />
          <Button type="submit" greenPrimary autoWidth formLook>
            Buscar
          </Button>
        </Form>
        <Table items={documentsContent} className="notiflix-manuals-container">
          <Column
            field="title"
            header="Nome"
            sortable
            body={(data) => AddField(data.title, 'Nome')}
          />
          <Column
            field="file_url"
            body={downloadBodyTemplate}
            bodyClassName="download"
          />
        </Table>
      </Container>
    </PageWrapper>
  );
};

export default ManualAndFiles;
