/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { unMask } from 'remask';
import { v4 } from 'uuid';
import PageWrapper from '../../../components/PageWrapper';
import { BeneficiaryUser, UserCommon, useAuth } from '../../../hooks/auth';
import api from '../../../services/api/api';
import {
  AvatarContainer,
  CameraIcon,
  Select,
  Title,
  Form,
  Input,
  SelectWrapper,
  ButtonContainer,
} from './styles';
import Datepicker from '../../../components/DatePicker';
import {
  dateToPTBR,
  getAllDDIForSelectOptions,
  ObjectGetValueOfKey,
  ptBRToDate,
} from '../../../utils/formatt';
import { SelectHandles } from '../../../components/Select';
import Modal2 from '../../../components/Modal2';
import Button from '../../../components/Button';
import InputFile, { InputFileHandles } from '../../../components/InputFile';
import { FieldsApi } from './types';

const BeneficiaryChangeRegisterData: React.FC = () => {
  const { user: userT } = useAuth();
  const user = userT as UserCommon & BeneficiaryUser;
  const inputFileRef = useRef<InputFileHandles>(null);
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const stateRef = useRef<SelectHandles>(null);
  const genderRef = useRef<SelectHandles>(null);
  const countyRef = useRef<SelectHandles>(null);
  const phoneDDIRef = useRef<SelectHandles>(null);
  const cellDDIRef = useRef<SelectHandles>(null);

  const aged = useMemo(() => {
    const dataAtual = new Date();
    return dataAtual.getFullYear() - Number(user.birthDate.slice(-4)) >= 18;
  }, [user]);

  const [fieldsAPI, setFieldsAPI] = useState({} as FieldsApi);

  const [birthdayValue, setBirthdayValue] = useState({
    date: new Date(),
    disabled: false,
  });
  const [needUpdateAfterSubmit, setNeedUpdateAfterSubmit] = useState({
    needUp: false,
    showModal: false,
  });

  const [genderOptions, setGenderOptions] = useState([
    {
      title: 'Sexo',
      value: '',
    },
  ]);

  const [stateOptions, setStateOptions] = useState([
    {
      title: 'Estados',
      value: '',
    },
  ]);

  const [countyOptions, setCountyOptions] = useState([
    {
      title: 'Município',
      value: '',
    },
  ]);

  const [beneficiariesOptions, setBeneficiariesOptions] = useState([
    {
      title: user.name,
      value: user.physicalPersonalCode,
      userUnimedId: user.userUnimedId,
    },
  ]);

  const [selectedPhisicalPersonCode, setSelectedPhisicalPersonCode] = useState(
    user.physicalPersonalCode,
  );

  const [dispatch, setDispatch] = useState('');
  const [selectedOptionBeneficiary, setSelectedOptionBeneficiary] = useState();

  // Chages
  const handleAvatarChange = useCallback(
    async (e) => {
      try {
        Notiflix.Block.circle('.avatar-notiflix-loading');
        if (e.currentTarget) {
          const file = e.currentTarget.files[0];
          if (!file) return;
          const form = new FormData();

          const founded = beneficiariesOptions.find((i: any) => {
            return i.value === selectedOptionBeneficiary;
          }) ?? { value: '' };

          form.append('beneficiaryImage', file);
          form.append('phisicalPersonCode', founded.value);
          await api.post('/beneficiary/send-beneficiary-image', form);

          // Modal2.Success({
          //   closable: false,
          //   title: 'Perfeito!',
          //   subtitle: 'Enviamos sua imagem',
          //   text: 'A sua imagem passará por um processo de avaliação',
          //   children: (
          //     <>
          //       <Button modal onClick={() => Modal2.Close()}>
          //         Ok
          //       </Button>
          //     </>
          //   ),
          // });
          setDispatch(v4());
        }
      } catch (err) {
        if (err?.response?.status === 400) {
          Notiflix.Notify.info(err.response.data?.message);
        } else {
          Notiflix.Notify.failure('Oops, Não foi posível trocar de imagem.');
        }
      } finally {
        Notiflix.Block.remove('.avatar-notiflix-loading');
      }
    },
    [beneficiariesOptions, selectedOptionBeneficiary],
  );

  const [cepToSearch, setCepToSearch] = useState('');
  const [fieldList, setFieldList] = useState<Array<any>>([]);
  /**
   * @description Search CEP and in success set default values to State, County, Street and District
   */
  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    const timer = window.setTimeout(() => {
      if (!cepToSearch) {
        source.cancel();
        return;
      }
      Notiflix.Block.circle('.notiflix-cep-search');
      api
        .get(`/address/search-address-by-zip-code?zipCode=${cepToSearch}`, {
          cancelToken: source.token,
        })
        .then((resp) => {
          const { data } = resp;
          const { content } = data;
          if (content && content[0]) {
            formRef.current?.setFieldValue('street', content[0].streetName);
            formRef.current?.setFieldValue('neighborhood', content[0].district);
            formRef.current?.setFieldValue('address', content[0].streetName);
            stateRef.current?.setValue('', content[0].states);
            if (content[0].city) {
              api
                .get(`/address/list-of-cities?city=${content[0].city}`)
                .then((respCity) => {
                  if (respCity.data.content && respCity.data.content[0]) {
                    setCountyOptions([
                      {
                        title: respCity.data.content[0].city,
                        value: respCity.data.content[0].ibgeCityCode,
                      },
                    ]);
                  }
                });
            }
            // countyRef.current?.setValue(content[0].city, );
          }
        })
        .finally(() => {
          Notiflix.Block.circle('.notiflix-cep-search');
          Notiflix.Block.remove('.notiflix-cep-search');
        });
    }, 1000);

    return () => {
      source.cancel();
      window.clearTimeout(timer);
    };
  }, [cepToSearch]);

  /**
   * @description get All data of Beneficiary selected
   */
  const getFieldsAPI = useCallback(
    async (userId = user.physicalPersonalCode) => {
      try {
        Notiflix.Block.circle('.notiflix-form');
        const fields = await api.get<{ content?: FieldsApi }>(
          `/beneficiary/data-to-change-fields?physicalPersonalCode=${userId}&serviceProtocolNumber=${user.serviceProtocolNumber}&accessType=B`,
          // `/beneficiary/data-to-change-fields?accessType=B&physicalPersonalCode=${userId}`,
        );
        const { content } = fields.data;
        if (content) {
          const { brazilianState } = content;
          const { data: allCities } = await api.get(
            `/address/list-of-cities?state=${brazilianState.value}`,
          );
          const { content: cities } = allCities;
          setCountyOptions(
            cities.reduce(
              (
                acc: { title: string; value: string }[],
                act: { city: string; ibgeCityCode: string },
              ) => {
                acc.push({
                  title: act.city,
                  value: act.ibgeCityCode,
                });
                return acc;
              },
              [],
            ),
          );
          setFieldsAPI(content);
        }
      } catch (error) {
        Notiflix.Notify.failure(
          'Não conseguimos buscar seus dados, tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.remove('.notiflix-form');
      }
    },
    [user],
  );

  const getParents = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-parents');
      const { data } = await api.get(
        `/contract/list-holder-and-dependents?userUnimedId=${user.id}`,
      );
      const { content } = data;
      setBeneficiariesOptions(() => {
        const dependent = content.reduce(
          (
            acc: { title: string; value: string; userUnimedId: string }[],
            act: {
              insuredName: string;
              phisicalPersonCode: string;
              userUnimedId: string;
            },
          ) => {
            acc.push({
              title: act.insuredName,
              value: act.phisicalPersonCode,
              userUnimedId: act.userUnimedId,
            });
            return acc;
          },
          [],
        );
        return dependent;
      });

      setSelectedOptionBeneficiary(content[0].phisicalPersonCode);
    } catch (err) {
      if (err?.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dependentes. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-parents');
    }
  }, [user.id]);

  const getStates = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-state-request');
      const { data } = await api.get(`/address/list-of-states`);
      const { content } = data;
      if (content) {
        setStateOptions(
          content.reduce(
            (
              acc: { title: string; value: string }[],
              act: { name: string; id: string },
            ) => {
              acc.push({
                title: act.name,
                value: act.id,
              });
              return acc;
            },
            [],
          ),
        );
      }
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Algo deu errado ao tentar buscar os Estados. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-state-request');
    }
  }, []);

  const getGender = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-gender-search');
      const { data } = await api.get(`/generic/list-of-phisical-sex`);
      const { content } = data;
      if (content) {
        setGenderOptions(
          content.reduce(
            (
              acc: { title: string; value: string }[],
              act: { name: string; id: string },
            ) => {
              acc.push({
                title: act.name,
                value: act.id,
              });
              return acc;
            },
            [],
          ),
        );
      }
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Algo deu errado ao tentar buscar algumas informações. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-gender-search');
    }
  }, []);

  const getNeedUp = useCallback(async () => {
    const needUp = localStorage.getItem('@unimedBlumenauNeedsUpdate');
    if (needUp) {
      setNeedUpdateAfterSubmit(JSON.parse(needUp));
    }
  }, []);

  /**
   * @description if url contain any params, in submit will go redirect to go back
   */
  const redirectIfParamsInUrl = useCallback(() => {
    if (window.location.search !== '') {
      history.goBack();
    }
  }, [history]);

  const getFieldList = useCallback(() => {
    const userName = ObjectGetValueOfKey(fieldsAPI, 'beneficiaryName').value;

    const allPromises = [];

    const userUnimedId = beneficiariesOptions.find(
      (i) => i.value === selectedPhisicalPersonCode,
    )?.userUnimedId ?? { value: '' };
    const protocolId = user.serviceProtocolNumber;
    const phisicalPersonCode = selectedPhisicalPersonCode;
    const { contractId } = user;

    const data: any = formRef.current?.getData();
    // eslint-disable-next-line no-param-reassign
    data.DDDNumberCellphone = data.cellphone.substring(0, 2);
    // eslint-disable-next-line no-param-reassign
    data.DDDNumberCellphone = data.phone.substring(0, 2);
    // eslint-disable-next-line no-param-reassign
    data.cellphone = data.cellphone.substring(2);
    // eslint-disable-next-line no-param-reassign
    data.phone = data.phone.substring(2);
    // eslint-disable-next-line no-param-reassign
    data.DDINumber = phoneDDIRef.current?.value;
    // eslint-disable-next-line no-param-reassign
    data.DDINumberCellphone = cellDDIRef.current?.value;

    // check which inputs were exchanged
    Object.entries(data).forEach((fieldArray) => {
      const field = fieldArray[0];
      const newValue = fieldArray[1];
      const oldValue = ObjectGetValueOfKey(fieldsAPI, field)?.value;

      allPromises.push({
        userUnimedId,
        protocolId,
        phisicalPersonCode,
        contractId,
        userName,
        field,
        oldValue,
        newValue,
        accessType: 'B',
        hasChanges:
          !(newValue === '' && oldValue === null) &&
          String(newValue) !== String(oldValue),
      });
    });

    // check which selects were exchanged
    const birthDate = birthdayValue.date ? dateToPTBR(birthdayValue.date) : '';
    const oldBirhDate = ObjectGetValueOfKey(fieldsAPI, 'birthDate').value;

    allPromises.push({
      userUnimedId,
      protocolId,
      phisicalPersonCode,
      contractId,
      userName,
      field: 'birthDate',
      oldValue: oldBirhDate,
      newValue: birthDate,
      accessType: 'B',
      hasChanges:
        !(birthDate === '' && oldBirhDate === null) &&
        birthDate !== oldBirhDate,
    });

    const physicalSex = genderRef.current?.value;
    const oldPhysicalSex = ObjectGetValueOfKey(fieldsAPI, 'physicalSex').value;

    allPromises.push({
      userUnimedId,
      protocolId,
      phisicalPersonCode,
      contractId,
      userName,
      field: 'physicalSex',
      oldValue: oldPhysicalSex,
      newValue: physicalSex,
      accessType: 'B',
      hasChanges:
        !(physicalSex === '' && oldPhysicalSex === null) &&
        physicalSex !== oldPhysicalSex,
    });

    const state = stateRef.current?.value;
    const oldState = ObjectGetValueOfKey(fieldsAPI, 'brazilianState').value;

    allPromises.push({
      userUnimedId,
      protocolId,
      phisicalPersonCode,
      contractId,
      userName,
      field: 'brazilianState',
      oldValue: oldState,
      newValue: state,
      accessType: 'B',
      hasChanges: !(state === '' && oldState === null) && state !== oldState,
    });

    const cityId = countyRef.current?.value;
    const oldCityId = ObjectGetValueOfKey(fieldsAPI, 'cityId').value;

    allPromises.push({
      userUnimedId,
      protocolId,
      phisicalPersonCode,
      contractId,
      userName,
      field: 'cityId',
      oldValue: oldCityId,
      newValue: cityId,
      accessType: 'B',
      hasChanges:
        !(cityId === '' && oldCityId === null) && cityId !== oldCityId,
    });

    return allPromises;
  }, [
    beneficiariesOptions,
    birthdayValue.date,
    fieldsAPI,
    selectedPhisicalPersonCode,
    user,
  ]);

  const handleHasChanges = useCallback(() => {
    const auxFieldList = getFieldList();
    setFieldList(auxFieldList);
  }, [getFieldList]);

  const checkHasChanges = useCallback(() => {
    return fieldList.filter((item: any) => item.hasChanges).length > 0;
  }, [fieldList]);

  const getBeneficiaryPhisicalPersonCode = useCallback(
    async (option) => {
      const founded = beneficiariesOptions.find(
        (i: any) => i.value === option,
      ) ?? { value: '' };
      setSelectedPhisicalPersonCode(founded?.value);
    },
    [beneficiariesOptions],
  );

  // consult new Beneficiary data
  const handleChangeActualBeneficiary = useCallback(
    (option) => {
      getFieldsAPI(option);
      getBeneficiaryPhisicalPersonCode(option);
      setSelectedOptionBeneficiary(option);
    },
    [getFieldsAPI, getBeneficiaryPhisicalPersonCode],
  );

  /**
   * @description Submit form
   */
  const handleFormSubmit = useCallback(
    async (data) => {
      const isLoading = document.querySelector('.notiflix-block-position');

      if (isLoading) {
        return;
      }

      // if there is a flag requesting update
      if (needUpdateAfterSubmit.needUp === true) {
        await localStorage.setItem(
          '@unimedBlumenauNeedsUpdate',
          JSON.stringify({ needUp: true, showModal: false }),
        );
      }

      const userName = ObjectGetValueOfKey(fieldsAPI, 'beneficiaryName').value;
      const allPromises = fieldList.length > 0 ? fieldList : getFieldList();

      const userUnimedId = beneficiariesOptions.find(
        (i) => i.value === selectedPhisicalPersonCode,
      )?.userUnimedId ?? { value: '' };

      // eslint-disable-next-line no-param-reassign
      data.DDDNumberCellphone = data.cellphone.substring(0, 2);
      // eslint-disable-next-line no-param-reassign
      data.DDDNumberCellphone = data.phone.substring(0, 2);
      // eslint-disable-next-line no-param-reassign
      data.cellphone = data.cellphone.substring(2);
      // eslint-disable-next-line no-param-reassign
      data.phone = data.phone.substring(2);
      // eslint-disable-next-line no-param-reassign
      data.DDINumber = phoneDDIRef.current?.value;
      // eslint-disable-next-line no-param-reassign
      data.DDINumberCellphone = cellDDIRef.current?.value;

      try {
        Notiflix.Block.circle('.notiflix-submit-form');

        const { data: response } = await api.put(
          '/beneficiary/register-change-request',
          [...allPromises],
        );

        const { content } = response;

        const requestIdApi = content[0].requestId;
        if (inputFileRef.current?.files && inputFileRef.current?.files[0]) {
          // const file = inputFileRef.current.files[0];
          const formData = new FormData();
          formData.append('requestId', requestIdApi);
          formData.append('userUnimedId', `${userUnimedId}`);
          formData.append('userName', userName.slice(0, 15));
          inputFileRef.current.files.forEach((file, index) => {
            formData.append(`changeFile[${index}]`, file);
          });

          await api.post(`/company/register-attachment-change`, formData);
        }

        Modal2.Success({
          closable: true,
          title: 'Perfeito!',
          text: 'Seus dados cadastrais foram confirmados com sucesso',
          children: (
            <>
              <Button
                onClick={() => {
                  Modal2.Close();
                  history.push('/');
                }}
                modal
              >
                Ok
              </Button>
            </>
          ),
        });
      } catch (err) {
        console.log(err);
        Notiflix.Notify.failure(
          'Ops, não pudemos atualizar algum dado no servidor... Por favor, tente novamente mais tarde...',
        );
      } finally {
        Notiflix.Block.remove('.notiflix-submit-form');
      }
    },
    [
      needUpdateAfterSubmit.needUp,
      fieldsAPI,
      fieldList,
      getFieldList,
      beneficiariesOptions,
      selectedPhisicalPersonCode,
      history,
    ],
  );

  /**
   * @description when change selected state, search county of the new state selected
   */
  const handleChangeSelectedState = useCallback(
    async (state) => {
      try {
        Notiflix.Block.circle('.notiflix-county-request');
        const { data } = await api.get(
          `/address/list-of-cities?state=${state}`,
        );
        const { content } = data;
        if (content) {
          setCountyOptions(
            content.reduce(
              (
                acc: { title: string; value: string }[],
                act: { city: string; ibgeCityCode: string },
              ) => {
                acc.push({
                  title: act.city,
                  value: act.ibgeCityCode,
                });
                return acc;
              },
              [],
            ),
          );
        }
      } catch (err) {
        Notiflix.Notify.failure(err);
      } finally {
        Notiflix.Block.remove('.notiflix-county-request');
        handleHasChanges();
      }
    },
    [handleHasChanges],
  );

  /**
   * @description Get initial values of Beneficiary logged, and values to selects...
   */
  useEffect(() => {
    getFieldsAPI();
    getParents();
    getStates();
    getGender();
  }, [getFieldsAPI, getParents, getStates, getGender]);

  /**
   * @description When changing the value of "fieldsAPI", it handles the new values for the form
   */
  useEffect(() => {
    if (Object.keys(fieldsAPI).length > 0) {
      formRef.current?.setErrors({});
      // start initial data for Inputs
      Object.entries(fieldsAPI).forEach((field: any) => {
        if (field[0] === 'cellphone') {
          if (field[1].value) {
            formRef.current?.setFieldValue(
              field[0],
              `${fieldsAPI.DDDNumberCellphone.value}${field[1].value}`,
            );
          } else {
            formRef.current?.setFieldValue(field[0], '');
          }
        } else if (field[0] === 'phone') {
          if (field[1].value) {
            formRef.current?.setFieldValue(
              field[0],
              `${fieldsAPI.DDDNumber.value}${field[1].value}`,
            );
          } else {
            formRef.current?.setFieldValue(field[0], '');
          }
        } else {
          formRef.current?.setFieldValue(field[0], field[1].value ?? '');
        }
      });
      // end initial data for Inputs

      // start inputs were editable?
      const data = formRef.current?.getData();
      if (data) {
        Object.entries(data).forEach((row) => {
          const ref = formRef.current?.getFieldRef(row[0]);
          if (ref) {
            const inpDisabled = ObjectGetValueOfKey(fieldsAPI, row[0]);
            if (inpDisabled) {
              ref.current.disabled = !inpDisabled.isEditable;
            }
          }
        });
      }
      // end inputs were editable?

      // start initial values for datepicker
      const { birthDate } = fieldsAPI;
      if (birthDate?.value) {
        try {
          const birthDayDate = ptBRToDate(birthDate.value);
          setBirthdayValue({
            date: birthDayDate,
            disabled: !birthDate.isEditable,
          });
        } catch (err) {
          console.log(
            'Não conseguimos formatar a data vinda da api , esperado => DD/MM/YYYY, recebido => ',
            birthDate.value,
          );
        }
      }
      // end initial values for datepicker

      // start initial values selected for selects
      const {
        physicalSex,
        brazilianState,
        cityId,
        DDINumber,
        DDINumberCellphone,
      } = fieldsAPI;
      if (physicalSex) {
        genderRef.current?.setValue('', physicalSex.value);
        genderRef.current?.setDisabled(!physicalSex.isEditable);
      }
      if (brazilianState) {
        stateRef.current?.setValue('', brazilianState.value);
        stateRef.current?.setDisabled(!brazilianState.isEditable);
      }
      if (cityId) {
        countyRef.current?.setValue('', cityId.value);
        countyRef.current?.setDisabled(!cityId.isEditable);
      }
      if (DDINumber) {
        phoneDDIRef.current?.setValue('', DDINumber.value);
        phoneDDIRef.current?.setDisabled(!DDINumber.isEditable);
      }
      if (DDINumberCellphone) {
        cellDDIRef.current?.setValue('', DDINumberCellphone.value);
        cellDDIRef.current?.setDisabled(!DDINumberCellphone.isEditable);
      }
    }
    // end initial values selected for selects
  }, [fieldsAPI]);

  useEffect(() => {
    getNeedUp();
  }, [getNeedUp]);

  const urlImg = useMemo(() => {
    if (selectedPhisicalPersonCode === user?.physicalPersonalCode) {
      const userAvatar =
        document.querySelector<HTMLImageElement>('#user_avatar');
      if (userAvatar) {
        userAvatar.src = `https://api.unimedblumenau.com.br/beneficiary/get-beneficiary-image?phisicalPersonCode=${user.physicalPersonalCode}&dispatch=${dispatch}`;
      }
    }
    return (
      <img
        src={`https://api.unimedblumenau.com.br/beneficiary/get-beneficiary-image?phisicalPersonCode=${selectedPhisicalPersonCode}&dispatch=${dispatch}`}
        alt={`imagem de usuário - ${selectedPhisicalPersonCode}`}
      />
    );
  }, [selectedPhisicalPersonCode, dispatch, user]);

  return (
    <PageWrapper
      gridTemplateColumns="1fr 1fr 1fr 6fr 1fr"
      gridTemplateRows="auto auto auto 1fr"
    >
      <Title>Alterar Dados Cadastrais</Title>

      <AvatarContainer>
        <div className="avatar-notiflix-loading">
          <label htmlFor="avatar">
            <CameraIcon />
            <span>Trocar Foto</span>
            <input
              type="file"
              id="avatar"
              onChange={handleAvatarChange}
              accept="image/*"
            />
          </label>
        </div>
        {urlImg}
      </AvatarContainer>

      <SelectWrapper
        hidden={!user.isOwner || !aged}
        formLook
        onSubmit={() => console.log('change beneficiary')}
      >
        <Select
          title="Beneficiário:"
          className="notiflix-parents text-bold"
          formLook
          hidden={!user.isOwner}
          name="typeuser"
          changeCallback={handleChangeActualBeneficiary}
          options={beneficiariesOptions}
          liveReload
        />
      </SelectWrapper>

      <Form
        ref={formRef}
        onSubmit={handleFormSubmit}
        className="notiflix-form"
        formLook
      >
        <Input
          formLook
          name="beneficiaryName"
          title="Nome Completo:"
          widthContainerDesktop="80%"
          onChange={handleHasChanges}
        />
        <Datepicker
          name="birthDate"
          title="Data de Nascimento"
          icon
          formLook
          onChange={(date: Date) => {
            handleHasChanges();
            setBirthdayValue({ date, disabled: false });
          }}
          selected={birthdayValue.date}
          disabled={birthdayValue.disabled}
          widthContainerDesktop="20%"
          maxDate={new Date()}
        />
        <Select
          name="physicalSex"
          title="Sexo:"
          ref={genderRef}
          formLook
          liveReload
          options={genderOptions}
          widthContainerDesktop="20%"
          className="notiflix-gender-search"
          changeCallback={handleHasChanges}
        />
        <Input
          formLook
          name="email"
          title="Email"
          widthContainerDesktop="80%"
          onChange={handleHasChanges}
        />
        <Input
          formLook
          name="motherName"
          title="Nome da Mãe:"
          widthContainerDesktop="50%"
          onChange={handleHasChanges}
        />
        <Input
          formLook
          name="fatherName"
          title="Nome do Pai:"
          widthContainerDesktop="50%"
          onChange={handleHasChanges}
        />
        <Input
          masks={['99999-999']}
          formLook
          name="zipCode"
          title="CEP:"
          widthContainerDesktop="25%"
          onInput={(e) => setCepToSearch(unMask(e.currentTarget.value))}
          className="notiflix-cep-search"
          onChange={handleHasChanges}
        />
        <Select
          ref={stateRef}
          options={stateOptions}
          changeCallback={handleChangeSelectedState}
          name="brazilianState"
          formLook
          title="Estado:"
          liveReload
          widthContainerDesktop="25%"
          className="notiflix-state-request"
        />
        <Select
          ref={countyRef}
          options={countyOptions}
          inputMode
          // apiSearch={handleSearchCounty}
          name="county"
          formLook
          title="Município:"
          liveReload
          widthContainerDesktop="25%"
          className="notiflix-county-request"
          changeCallback={handleHasChanges}
        />
        <Input
          formLook
          name="neighborhood"
          title="Bairro:"
          widthContainerDesktop="25%"
          onChange={handleHasChanges}
        />
        <Input
          formLook
          name="address"
          title="Rua:"
          widthContainerDesktop="50%"
          onChange={handleHasChanges}
        />
        <Input
          formLook
          name="addressNumber"
          title="Número:"
          widthContainerDesktop="15%"
          onChange={handleHasChanges}
        />
        <Input
          formLook
          name="complement"
          title="Complemento:"
          widthContainerDesktop="35%"
          onChange={handleHasChanges}
        />

        <Select
          name="phoneDDI"
          title="DDI - Telefone:"
          ref={phoneDDIRef}
          inputMode
          options={[{ title: '', value: '' }, ...getAllDDIForSelectOptions()]}
          widthContainerDesktop="15%"
          formLook
          changeCallback={handleHasChanges}
        />
        <Input
          masks={['(99) 9999-9999', '(99) 9 9999-9999']}
          formLook
          name="phone"
          title="DDD/Telefone:"
          widthContainerDesktop="35%"
          onChange={handleHasChanges}
        />
        <Select
          name="cellDDI"
          title="DDI - Celular:"
          ref={cellDDIRef}
          inputMode
          options={[{ title: '', value: '' }, ...getAllDDIForSelectOptions()]}
          widthContainerDesktop="15%"
          formLook
          changeCallback={handleHasChanges}
        />
        <Input
          masks={['(99) 9999-9999', '(99) 9 9999-9999']}
          formLook
          name="cellphone"
          title="DDD/Celular:"
          widthContainerDesktop="35%"
          onChange={handleHasChanges}
        />
        <InputFile
          ref={inputFileRef}
          name="attachments"
          title="Anexos:"
          placeholder="Anexos"
          multiple
        />
        <ButtonContainer>
          <Button
            type="button"
            onClick={() => history.goBack()}
            secondary
            autoWidth
            formLook
          >
            Voltar
          </Button>
          <Button
            type="submit"
            orangeButton
            modal
            autoWidth
            formLook
            className="notiflix-submit-form"
          >
            {checkHasChanges() ? 'Salvar' : 'Confirmar'}
          </Button>
        </ButtonContainer>
      </Form>
    </PageWrapper>
  );
};

export default BeneficiaryChangeRegisterData;
