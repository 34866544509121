import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.section`
  grid-column: 2/10;
  @media (max-width: 768px) {
    grid-column: 1;
    margin: 0px;
    /* padding: 20px; */
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & form {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
`;

export const FormContainer = styled.div`
  display: grid;
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  grid-column: 1/-1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const ButtonSelectHour = styled(Link)`
  all: unset;
  cursor: pointer;
  width: auto;
  border-radius: 6px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 12px;
  background: ${(props) => props.theme.colors.palet.institutional12};
  color: ${(props) => props.theme.colors.text.black};
  gap: 4px;
  font-weight: 600;
  width: auto;
  max-width: 140px;
  svg {
    color: ${(props) => props.theme.colors.text.white};
  }
  &:hover {
    background: ${(props) => props.theme.colors.palet.institutional4};
    color: #fff;
  }
`;
