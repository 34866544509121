/* eslint-disable max-len */
import styled, { css } from 'styled-components';
import { DataTable as DataTablePrime } from 'primereact/datatable';
import { shade } from 'polished';
import { SVGContainerProps } from '.';

export const DataTable = styled(DataTablePrime)`
  font-family: 'Unimed-Sans-Book', sans-serif;
  font-size: 1rem;
  line-height: 1rem;

  /* header */
  .p-datatable-thead {
    th[role='columnheader'] {
      background: ${(props) => props.theme.colors.palet.institutional11};
      color: ${(props) => props.theme.colors.text.white};
      text-transform: uppercase;
      font-size: 0.9rem;
      letter-spacing: 0.05rem;
      transition: 300ms;
      border: none;

      &:first-child {
        border-radius: 10px 0 0 0;
      }
      &:last-child {
        border-radius: 0 10px 0 0;
      }
      &.p-sortable-column {
        &:hover {
          background: ${(props) =>
            shade(0.05, props.theme.colors.palet.institutional11)};
          color: ${(props) => props.theme.colors.text.inverse};
        }
        span.p-sortable-column-icon {
          display: none;
        }
      }
      &.p-selection-column {
        .p-column-title {
          display: none;
        }
      }
      &.date {
        @media (min-width: 769px) {
          width: 120px;
          text-align: center;
        }
      }

      &.currency {
        @media (min-width: 769px) {
          width: 140px;
          text-align: center;
        }

        @media (min-width: 769px) and (max-width: 1400px) {
          width: 120px;
          /* word-break: break-word; */
        }
      }
      &.align_col_lossratio {
        @media (min-width: 769px) {
          width: 140px;
          text-align: center;
        }

        @media (min-width: 769px) and (max-width: 1400px) {
          width: 120px;
          /* word-break: break-word; */
        }
      }
      &.tag {
        @media (min-width: 769px) {
          text-align: right;
        }
      }
      &.only-mobile {
        @media (min-width: 769px) {
          max-width: 0px !important;
          width: 0px !important;
          opacity: 0;
          visibility: hidden;
          /* display: none; */
        }
      }

      &:hover {
        color: #fff !important;
      }

      @media (max-width: 768px) {
        &:not(.p-selection-column) {
          padding: 0;
          border: none;
          display: none;

          .p-column-title {
            display: none;
          }
        }
        &.p-selection-column {
          width: 100% !important;
          border-radius: 10px 10px 0 0;

          .p-column-title {
            display: initial;
            margin-left: 22px;
            line-height: 20px;
          }
          .p-checkbox {
            float: left;
            margin-left: 8px;
          }
        }
      }
    }
  }
  /* body */
  .p-datatable-tbody {
    tr[role='row'] {
      position: relative;
      border: none !important;
      transition: 300ms;

      > td {
        transition: 300ms;

        @media (max-width: 1200px) {
          white-space: nowrap;
          overflow: auto;
        }
        &.date {
          @media (min-width: 769px) {
            width: 120px;
            text-align: center;
          }
        }
        &.currency {
          @media (min-width: 769px) {
            width: 140px;
            text-align: right;
            padding-right: 40px;
          }
          @media (min-width: 769px) and (max-width: 1400px) {
            width: 120px;
            padding-right: 20px;
          }
        }
        &.align_col_lossratio {
          @media (min-width: 769px) {
            width: 140px;
            text-align: center;
            padding-right: 40px;
          }
          @media (min-width: 769px) and (max-width: 1400px) {
            width: 120px;
            padding-right: 20px;
          }
        }
        &.only-mobile {
          @media (min-width: 769px) {
            max-width: 0px !important;
            width: 0px !important;
            opacity: 0;
            visibility: hidden;
            /* display: none; */
          }
        }
      }

      /* striped */
      &:nth-child(2n + 1) {
        background: ${(props) => props.theme.colors.palet.institutional14};
      }

      /* with dropwon */
      &[expand] {
        @media (min-width: 769px) {
          background: ${(props) =>
            `linear-gradient(to top, transparent 0,transparent 36px, ${props.theme.colors.background} 36px); `};
          filter: drop-shadow(0px 0px 10px #00000029);
          /* all td */
          & > td {
            padding-bottom: calc(36px + 1rem) !important;
          }
          &:hover {
            background: ${(props) =>
              `linear-gradient(to top, transparent 0,transparent 36px, ${props.theme.colors.background} 36px); `};
            filter: drop-shadow(0px 0px 10px #00000029);
          }
        }
        @media (max-width: 768px) {
          .options {
            width: 100%;
          }
        }
      }

      /* selected */
      &.p-highlight {
        background: ${(props) => props.theme.colors.background};
        filter: drop-shadow(0px 0px 10px #00000029);
        color: ${(props) => props.theme.colors.text.grayishToWhite};
        font-family: 'Unimed-Sans-Bold';
        &[expand] {
          background: ${(props) =>
            `linear-gradient(to top, transparent 0,transparent 36px, ${props.theme.colors.background} 36px);`};
        }
        @media (min-width: 769px) {
          border-left: 1px solid
            ${(props) => props.theme.colors.palet.institutional} !important;
        }
      }

      /*default component expander */
      > td {
        > button.p-row-toggler.p-link {
          width: 16px;
          height: 16px;
          &:focus {
            box-shadow: none;
          }
          > span {
            width: 16px;
            height: 16px;
            background-position: center;
            background-repeat: no-repeat;
            &.p-row-toggler-icon.pi.pi-fw.p-clickable.pi-chevron-right {
              background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5 7a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zM7 4.201v5.6M4.2 7h5.6' stroke='%235B5C65' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            }
            &.p-row-toggler-icon.pi.pi-fw.p-clickable.pi-chevron-down {
              background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5 7a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zM4.2 7h5.6' stroke='%235B5C65' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            }
          }
          @media (max-width: 768px) {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 40px;
            border-radius: 0px;
            background: ${(props) => props.theme.colors.palet.institutional11};
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px 0 0 20px;
            transition: 300ms;
            &[aria-expanded='true'] {
              border-radius: 0;
            }
            pointer-events: none;
            > span {
              pointer-events: all;
              width: 40px;
              height: 40px;
              /* width: 20px;
              height: 20px; */
              &.p-row-toggler-icon.pi.pi-fw.p-clickable.pi-chevron-right {
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.5 10a9.5 9.5 0 01-9.5 9.5A9.5 9.5 0 01.5 10 9.5 9.5 0 0110 .5a9.5 9.5 0 019.5 9.5zM10 6.003v8M6 10h8' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
              }
              &.p-row-toggler-icon.pi.pi-fw.p-clickable.pi-chevron-down {
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.5 10a9.5 9.5 0 01-9.5 9.5A9.5 9.5 0 01.5 10 9.5 9.5 0 0110 .5a9.5 9.5 0 019.5 9.5zM6 10h8' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
              }
            }
          }
        }
      }
      &.p-row-expanded {
        ${(props) => css`
          background: ${`
            linear-gradient(90deg, transparent 10px,
           ${props.theme.colors.palet.institutional11} 10px,
           ${props.theme.colors.palet.institutional11} calc(100% - 10px),
            transparent calc(100% - 10px))
          `};
        `};

        &:hover {
          ${(props) => css`
            background: ${`
              linear-gradient(90deg, transparent 10px,
            ${props.theme.colors.palet.institutional11} 10px,
            ${props.theme.colors.palet.institutional11} calc(100% - 10px),
              transparent calc(100% - 10px))
            `};
          `};
        }
        @media (max-width: 768px) {
          display: none;
          background: ${(props) => props.theme.colors.palet.institutional11};
          &:hover {
            background: ${(props) => props.theme.colors.palet.institutional11};
          }
          padding: 30px 0 30px 30px;
        }
        > td {
          padding: 7px 17px;
        }
      }

      @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        width: 100vw;
        max-width: 100vw;
        padding: 14px 50px;
        border: 1px solid ${(props) => props.theme.colors.palet.institutional12} !important;

        .p-selection-column {
          position: absolute;
          left: 8px;
        }
        .options {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          /* width: 40px; */
          min-width: 40px;
          border-radius: 10px 0px 0px 10px;
          background: ${(props) => props.theme.colors.palet.institutional12};
          padding: 0;
        }
        > td {
          border: none;
          min-width: 50%;
        }
      }
    }
  }
  .p-datatable-tfoot {
    display: none;
  }

  colgroup.p-datatable-scrollable-colgroup {
    col {
      &.only-mobile {
        @media (min-width: 769px) {
          max-width: 0px !important;
          width: 0px !important;
          opacity: 0;
          visibility: hidden;
          /* display: none; */
        }
      }
    }
  }
  .p-datatable-scrollable-header {
    background: ${(props) => props.theme.colors.palet.institutional11};
    border-radius: 10px 10px 0 0;
  }

  /* special for checkbox */
  tr {
    @media (max-width: 768px) {
      width: 100% !important;
    }
    .p-checkbox-box {
      position: relative;
      &:hover,
      &.p-focus {
        border-color: ${(props) =>
          props.theme.colors.palet.institutional} !important;
        box-shadow: 0px 0px 1rem
          ${(props) => shade(0.05, props.theme.colors.palet.institutional)} !important;
      }
      &.p-highlight {
        border-color: ${(props) =>
          props.theme.colors.palet.institutional} !important;
        background: ${(props) =>
          props.theme.colors.palet.institutional} !important;
        &::before {
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.3 5.72C-.6 4.68.74 3.1 1.63 4.14L4.38 7.3 10.35.34c.9-1.04 2.25.54 1.36 1.59L5.07 9.67a.86.86 0 01-1.36 0L.3 5.72z' fill='%23fff'/%3E%3C/svg%3E");
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          position: absolute;
        }
      }
    }
  }

  .p-paginator.p-component.p-paginator-bottom {
    /* justify-content: space-between; */
    background: ${(props) => props.theme.colors.palet.institutional11};
    border-radius: 0px 0px 10px 10px;

    .p-paginator-current {
      margin-right: auto;
      color: ${(props) => props.theme.colors.text.inverse};
      font-family: 'Unimed-Sans-Book';
      font-size: 1rem;
      font-weight: 600;
    }
    .p-paginator-pages {
      .p-paginator-page.p-paginator-element.p-link {
        font-family: 'Unimed-Sans-Book';
        color: ${(props) => props.theme.colors.text.inverse};
        transition: 300ms;
        font-size: 1rem;
        font-weight: 600;
        &.p-highlight {
          background: ${(props) => props.theme.colors.palet.institutional13};
          border-color: ${(props) => props.theme.colors.palet.institutional13};
          color: ${(props) => props.theme.colors.text.primary};
        }
        &:hover {
          color: ${(props) => props.theme.colors.text.primary};
        }
        &:focus {
          ${(props) => css`
            box-shadow: 0 0 0 0.2rem
              ${props.theme.colors.palet.institutional11}20;
          `}
        }
      }
    }
    svg.p-paginator-element.p-link {
      min-width: initial;
      transition: 300ms;
      color: ${(props) => props.theme.colors.text.inverse};
      &:hover {
        transform: scale(1.3);
        color: ${(props) => props.theme.colors.text.primary};
      }
    }
  }
  .p-datatable-footer {
    background: transparent;
    border: none;
  }
`;

export const HeaderMobile = styled.span`
  display: none;
  @media (max-width: 768px) {
    font-family: 'Unimed-Sans-Bold';
    font-size: 1rem;
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }
`;

export const Option = styled.div`
  padding: 10px;
  > svg {
    cursor: pointer;
    path:nth-child(2) {
      transform-origin: center;
      transition: 300ms;
    }
    &.open {
      path:nth-child(2) {
        transform: rotate(90deg);
      }
    }
  }
  @media (max-width: 768px) {
    display: flex;
    height: 100%;
    width: 100%;
    min-width: 40px;
    max-width: calc(100 %-30px);
    padding: 0;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.4));
    border-radius: 10px 0px 0px 10px;
    overflow: hidden;
    > svg {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 8px;
      path {
        stroke: ${(props) => props.theme.colors.text.inverse};
      }
    }
  }
`;
export const Options = styled.div`
  width: calc(100% - 20px);
  position: absolute;
  left: 10px;
  bottom: 0;
  background: ${(props) => props.theme.colors.palet.institutional11};
  transition: 300ms;
  height: 0;
  overflow: hidden;
  padding: 0px;
  > button + button {
    margin-left: 8px;
  }

  &.open {
    padding: 9.5px;
    height: auto;
  }
  > button {
    font-size: 0.65rem;
  }
  @media (max-width: 1200px) {
    display: flex;
    overflow: auto;
  }
  @media (max-width: 768px) {
    position: initial;
    width: 0;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    row-gap: 12px;
    column-gap: 12px;

    > button {
      opacity: 0;
      visibility: hidden;
      transition: 100ms;
      transition-delay: 300ms;
      flex: 1 0 100%;
      font-size: 1rem;
      padding: 6px;
      & + button {
        margin-left: 0;
      }
      &.last {
        font-size: 0.65rem;
        margin-top: 8px;
        order: 2;
        flex: 1 0 calc(50% - 6px);
      }
    }
    &.open {
      padding: 30px 40px;
      height: 100%;
      width: calc(100%);
      > button {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;

export const SvgContainerElement = styled.div<SVGContainerProps>``;

export const NoContent = styled.p`
  color: ${(props) => props.theme.colors.text.primary};
  text-align: center;
`;

export const TableFooter = styled.div`
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span {
    color: ${(props) => props.theme.colors.text.grayishToLightGray};
  }

  > button {
    padding: 8px 24px;
    border-color: ${(props) => props.theme.colors.palet.institutional11};
    color: ${(props) => props.theme.colors.palet.institutional11};
  }
`;

export const Tag = styled.div<{
  borderColor:
    | 'green'
    | 'red'
    | 'orange'
    | 'gray'
    | 'olive'
    | 'blue'
    | 'indigo'
    | 'yellow';
}>`
  position: relative;
  display: flex;
  min-width: 50px;
  /* max-width: 72px; */
  height: 20px;
  text-align: right;
  /* margin-left: 20px; */
  float: right;
  /* margin: 0 20px; */
  justify-content: flex-end;
  align-items: center;
  padding: 5px 8px;
  font-family: 'Unimed-Sans-Semibold';
  font-size: 0.65rem;
  line-height: 0.65rem;
  border: 1px solid;
  border-left: none;
  border-radius: 4px;
  /* overflow: hidden; */

  white-space: nowrap;

  ${(props) =>
    props.borderColor === 'red' &&
    css`
      color: ${props.theme.colors.palet.institutional7};
      border-color: ${props.theme.colors.palet.institutional7};
    `};
  ${(props) =>
    props.borderColor === 'green' &&
    css`
      color: ${props.theme.colors.palet.institutional};
      border-color: ${props.theme.colors.palet.institutional};
    `};
  ${(props) =>
    props.borderColor === 'orange' &&
    css`
      color: ${props.theme.colors.palet.institutional6};
      border-color: ${props.theme.colors.palet.institutional6};
    `};
  ${(props) =>
    props.borderColor === 'gray' &&
    css`
      color: ${props.theme.colors.palet.institutional12};
      border-color: ${props.theme.colors.palet.institutional12};
    `};

  &::after {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    border: 1px solid;
    color: transparent;
    pointer-events: none;
    left: -5px;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    border-bottom: none;
    border-right: none;
    border-radius: 4px;
  }
  &::before {
    content: '';
    font-size: 16px;
    position: absolute;
    height: 5px;
    width: 5px;
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  }
  &::after {
    ${(props) =>
      props.borderColor === 'red' &&
      css`
        border-color: ${props.theme.colors.palet.institutional7};
      `};
    ${(props) =>
      props.borderColor === 'green' &&
      css`
        border-color: ${props.theme.colors.palet.institutional};
      `};
    ${(props) =>
      props.borderColor === 'orange' &&
      css`
        border-color: ${props.theme.colors.palet.institutional6};
      `};
    ${(props) =>
      props.borderColor === 'gray' &&
      css`
        border-color: ${props.theme.colors.palet.institutional12};
      `};
  }
  &::before {
    ${(props) =>
      props.borderColor === 'red' &&
      css`
        background-color: ${props.theme.colors.palet.institutional7};
      `};
    ${(props) =>
      props.borderColor === 'green' &&
      css`
        background-color: ${props.theme.colors.palet.institutional};
      `};
    ${(props) =>
      props.borderColor === 'orange' &&
      css`
        background-color: ${props.theme.colors.palet.institutional6};
      `};
    ${(props) =>
      props.borderColor === 'gray' &&
      css`
        background-color: ${props.theme.colors.palet.institutional12};
      `};
  }

  @media (max-width: 768px) {
    float: left;
    margin-top: 0;
    margin-left: 6px;
  }
`;

export const ButtonsActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 8px;
  row-gap: 8px;

  @media (min-width: 769px) {
    > button.marginRight {
      margin-right: auto;
    }
    > button.marginLeft {
      margin-left: auto;
    }
  }

  @media (max-width: 768px) {
    height: 100%;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    row-gap: 12px;
    > button {
      width: 100%;
      height: 32px;
      font-size: 1rem;
    }
  }
`;
