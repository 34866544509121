import Notiflix from 'notiflix';
import React, { useState, useEffect, useCallback } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Button from '../../components/Button';
import PageWrapper from '../../components/PageWrapper';
import { Column } from '../../components/Table';
import { HeaderMobile } from '../../components/Table/styles';
import Title, { TitleIntern } from '../../components/Title';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api/api';
import { dateToPTBR } from '../../utils/formatt';
import { Table, ContainerTitleIntern, Container } from './styles';

const Lack: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [lack, setLack] = useState([] as any[]);
  const { user } = useAuth();
  const { userUnimedId, beneficiaryName } =
    useParams<{ userUnimedId: string; beneficiaryName: string }>();
  const { colors } = useTheme();
  const history = useHistory();

  const getLackData = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');
      const transformToBolder = (dataFromContent: any) => {
        const [day, month, year] = dataFromContent.finalDate
          ? dataFromContent.finalDate.split('/')
          : dateToPTBR(new Date()).split('/');
        return {
          ...dataFromContent,
          toBolder: new Date(year, month, day) > new Date(),
        };
      };

      const { data } = await api.get(
        `/beneficiary/lack-of-plan?userUnimedId=${userUnimedId ?? user.id}`,
      );
      const { content } = data;

      setLack(content.map(transformToBolder));
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops, não conseguimos buscar os dados do servidor. Tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, [user.id, userUnimedId]);

  useEffect(() => {
    getLackData();
  }, [getLackData]);

  return (
    <PageWrapper
      gridTemplateColumns="repeat(1fr, 10)"
      gridTemplateRows="auto 1fr"
    >
      <Container>
        <Title>Consultar Carências</Title>

        {userUnimedId && beneficiaryName && (
          <ContainerTitleIntern formLook onSubmit={() => history.goBack()}>
            <TitleIntern
              themeColor={colors.palet.institutional3}
            >{`Beneficiário: ${beneficiaryName}`}</TitleIntern>
            <Button autoWidth secondary formLook type="submit">
              Voltar
            </Button>
          </ContainerTitleIntern>
        )}
        <Table
          items={lack}
          key="lack-table"
          className="notiflix-table-loading"
          columnResizeMode="fit"
          id="lack-table"
        >
          <Column
            field="descriptionLackOfPlan"
            header="Classificação"
            sortable
            body={(data) => {
              return (
                <>
                  <HeaderMobile>Classificação</HeaderMobile>
                  {(data.toBolder && (
                    <strong>{data.descriptionLackOfPlan}</strong>
                  )) ||
                    data.descriptionLackOfPlan}
                </>
              );
            }}
          />
          <Column
            field="startDate"
            header="Data de Vigência"
            className="date"
            body={(data) => {
              return (
                <>
                  <HeaderMobile>Data de Vigência</HeaderMobile>
                  {(data.toBolder && <strong>{data.startDate}</strong>) ||
                    data.startDate}
                </>
              );
            }}
          />
          <Column
            field="finalDate"
            header="Data fim de vigência"
            className="date"
            body={(data) => {
              return (
                <>
                  <HeaderMobile>Data Fim de Vigência</HeaderMobile>
                  {(data.toBolder && <strong>{data.finalDate}</strong>) ||
                    data.finalDate}
                </>
              );
            }}
          />
        </Table>
      </Container>
    </PageWrapper>
  );
};

export default Lack;
