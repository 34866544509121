import styled from 'styled-components';
import DropdownElement from '../../components/Dropdown';
import FormWrapper from '../../components/FormWrapper';

export const FaqTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-right: 8px;
  font-family: 'Unimed-Sans-SemiBold';
  color: ${(props) => props.theme.colors.text.white};
  background: ${(props) => props.theme.colors.text.grayishToBlack};
  font-size: 0.85rem;
  h2 {
    font-size: 1rem;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    padding-right: 32px;
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    font-size: 0.65rem;
    h2 {
      padding-right: 16px;
    }
  }
`;

export const FaqBody = styled.p`
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 0.85rem;
  color: ${(props) => props.theme.colors.text.blackToWhite};
`;

export const Form = styled(FormWrapper)`
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
  width: 100%;

  @media (min-width: 769px) {
    flex-wrap: nowrap;
  }
`;

export const NoContent = styled.h3`
  font-family: 'Unimed-Sans-Book';
  color: ${(props) => props.theme.colors.text.grayishToWhite};
  text-align: center;
`;

export const Container = styled.div`
  grid-column: 2/10;
  grid-template-columns: repeat(10, 1fr);
  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

export const DocumentsContainer = styled.div`
  background-color: transparent;
  grid-column: 2/10;

  @media (max-width: 768px) {
    grid-column: 1;
    margin: 0 30px 30px;
  }
`;

export const Dropdown = styled(DropdownElement)`
  background: ${(props) => props.theme.colors.palet.institutional11};
  border-color: ${(props) => props.theme.colors.palet.institutional11};
  box-shadow: 0px 5px 20px rgb(196 203 207 / 40%);

  overflow: hidden;
  padding: 0;
  > div.open {
    margin: 16px 24px;
  }
  > button {
    background: ${(props) => props.theme.colors.palet.institutional11};
    padding: 10px;
    box-shadow: 0 0 10px;
    > svg {
      color: ${(props) => props.theme.colors.text.white};
    }
  }
  background: ${(props) => props.theme.colors.background};
`;
