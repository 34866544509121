import styled from 'styled-components';
import DropdownElement from '../../components/Dropdown';

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  & > button {
    margin-right: 8px;
  }
`;

export const InsideText = styled.p`
  color: ${(props) => props.theme.colors.text.grayishToWhite};
  font-size: 1rem;
  font-family: 'Unimed-Sans-Bold';
`;

export const Container = styled.div`
  grid-column: 2/10;
  margin-bottom: 30px;
  padding: 0 30px;
  grid-template-columns: repeat(10, 1fr);
  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

export const DropdownTitle = styled.h2`
  color: ${(props) => props.theme.colors.text.white};
  background: ${(props) => props.theme.colors.text.grayishToBlack};
  font-size: 1rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    height: 50px;
  }

  @media (min-width: 769px) {
    width: 250px;
    flex-direction: row-reverse;
  }
`;

export const Dropdown = styled(DropdownElement)`
  background: ${(props) => props.theme.colors.palet.institutional11};
  border-color: ${(props) => props.theme.colors.palet.institutional11};
  box-shadow: 0px 5px 20px rgb(196 203 207 / 40%);

  overflow: hidden;
  padding: 0;
  > div.open {
    margin: 16px 24px;
  }
  > button {
    background: ${(props) => props.theme.colors.palet.institutional11};
    padding: 10px;
    box-shadow: 0 0 10px;
    > svg {
      color: ${(props) => props.theme.colors.text.white};
    }
  }
  background: ${(props) => props.theme.colors.background};
`;
