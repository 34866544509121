import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';
import { TitleIntern } from '../../../../components/Title/styles';
import {
  initialRefundConsultFormList,
  refundFormItem,
} from '../../__mocks__/formList';
import { Container, FormWrapper, ModalButtonContainer } from '../../styles';
import { RefundNoteFormProps } from '../../__types__/refundNoteFormProps';
import { ContainerBlock, IncludeNoteText } from './styles';
import FormItem, { HandleRefundFormItem } from './FormItem';
import api from '../../../../services/api/api';
import DatePicker from '../../../../components/DatePicker';
import Input from '../../../../components/Input';
import { BeneficiaryUser, UserCommon, useAuth } from '../../../../hooks/auth';
import getValidationErrors from '../../../../utils/getValidationErrors';
import {
  isValidDate,
  oneYearBefore,
  oneYearLater,
} from '../../../../utils/date';
import {
  cleanCurrencyMask,
  maskCurrencyOnChange,
} from '../../../../utils/formatt';
import Modal2 from '../../../../components/Modal2';
import Button from '../../../../components/Button';

export interface FormConsultList {
  formSubmit: () => void;
  reset: () => void;
  resetNotes: () => void;
}

const RefundForm: React.ForwardRefRenderFunction<
  FormConsultList,
  RefundNoteFormProps
> = (
  {
    documentTypeList,
    documentAttachmentsList,
    type,
    newRequestProtocolNumber = '',
    formTitle,
  },
  ref,
) => {
  const { user: userT } = useAuth();
  const user = userT as UserCommon & BeneficiaryUser & { productType: number };
  const { colors } = useTheme();
  const [formList, setFormList] = useState(initialRefundConsultFormList);
  const [careValue, setCareValue] = useState('');
  const [serviceDateState, setServiceDateState] = useState<{
    value: null | Date;
    error: string;
  }>({
    value: null,
    error: '',
  });

  const showProtocolNumberField = type !== '1' && type !== '3' && type !== '5';
  const neverShowProtocolNumberField = type !== '5' && type !== '1';

  const itemsRef = useRef<Array<HandleRefundFormItem>>([]);
  const formRef = useRef<FormHandles>(null);

  const requestValidProtocolNumber = async (protocolNumber: string) => {
    try {
      const bodyData = {
        protocolArray: [protocolNumber],
      };
      const { data } = await api.post(`/refund/protocol-validation`, bodyData);
      return data.content;
    } catch (error) {
      return {
        result: 0,
      };
    }
  };

  const validateParentForm = useCallback(async () => {
    try {
      let schema: Yup.ObjectSchema<any> | null;

      if (Number(user.productType) === 1 && showProtocolNumberField) {
        schema = Yup.object().shape({
          careValue: Yup.string().required('Informe o valor do atendimento'),
          protocolNumber: Yup.string()
            .required('Informe o número do protocolo')
            .min(20, 'Mínimo de 20 caracteres.'),
        });
      } else if (Number(user.productType) !== 1 || !showProtocolNumberField) {
        schema = Yup.object().shape({
          careValue: Yup.string().required('Informe o valor do atendimento'),
        });
      }

      const validateDate =
        !serviceDateState.value ||
        !isValidDate(String(serviceDateState.value).toString());
      const validatePeriod = false;

      if (validateDate && !validatePeriod) {
        setServiceDateState(() => ({
          value: serviceDateState.value,
          error: 'Informe uma data do serviço válida',
        }));

        return false;
      }

      setServiceDateState(() => ({
        value: serviceDateState.value,
        error: '',
      }));

      if (
        parseFloat(cleanCurrencyMask(careValue)) <= 0 ||
        Number.isNaN(parseFloat(cleanCurrencyMask(careValue)))
      ) {
        formRef.current?.setErrors({
          careValue: 'Informe um valor para o atendimento',
        });

        return false;
      }

      formRef.current?.setErrors({
        careValue: '',
      });

      const data = formRef.current?.getData() ?? {};

      await schema!.validate(data, {
        abortEarly: false,
      });

      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      }
      return false;
    }
  }, [
    careValue,
    showProtocolNumberField,
    user.productType,
    serviceDateState.value,
  ]);

  const validateRefundFormFields = async () => {
    try {
      const allValidForm = itemsRef.current.map(async (form) => {
        const valid = await form.validForm();
        return valid;
      });

      const isFormListValidResults = await Promise.all(allValidForm);
      const isFormListValid = isFormListValidResults.every((result) => result);

      const allValidFiles = itemsRef.current.map((form) => {
        const valid = form.validFiles();
        return valid;
      });
      const isFormFilesListValid = allValidFiles.every((result) => result);
      return isFormFilesListValid && isFormListValid;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const validateProtocolNumber = useCallback(async (notify = false) => {
    let response;
    const protocolNumber = formRef.current?.getData().protocolNumber;

    if (protocolNumber) {
      response = await requestValidProtocolNumber(protocolNumber);

      if (response.result === 0) {
        return false;
      }

      if (response.filter((item: any) => !item.value).length > 0) {
        if (!notify) {
          formRef.current?.setErrors({
            protocolNumber: response[0].message,
          });
        } else {
          Modal2.Failure({
            closable: true,
            title: 'Erro!',
            text: response[0].message,
            children: (
              <ModalButtonContainer>
                <Button
                  onClick={() => {
                    Modal2.Close();
                  }}
                  modal
                >
                  Fechar
                </Button>
              </ModalButtonContainer>
            ),
          });
        }
      }
      return (
        response.filter((item: { value: string }) => item.value).length > 0
      );
    }
    return false;
  }, []);

  const submitRefundForm = useCallback(async () => {
    const isParentFormValid = await validateParentForm();
    const isFormListValid = await validateRefundFormFields();
    let isProtocolNumberListValid = true;
    if (
      Number(user.productType) === 1 &&
      showProtocolNumberField &&
      neverShowProtocolNumberField
    ) {
      isProtocolNumberListValid = await validateProtocolNumber();
    }

    if (isFormListValid && isProtocolNumberListValid && isParentFormValid) {
      const payload = await itemsRef.current.map(async (item) => {
        const itemData = item.formData();
        const { document } = item.getDates();
        itemData.documentDate = document ?? new Date();

        itemData.attachments = item
          .getFiles()
          .files.filter((_item) => _item != null);
        return itemData;
      });

      const cleanCareValue =
        parseFloat(cleanCurrencyMask(formRef.current?.getData().careValue)) /
        100;
      const protocolNumber = formRef.current?.getData().protocolNumber;

      const formResult = await Promise.all(payload);

      return {
        notes: formResult,
        serviceDate: serviceDateState.value,
        careValue: cleanCareValue,
        protocolNumber,
      };
    }

    return false;
  }, [
    neverShowProtocolNumberField,
    serviceDateState.value,
    showProtocolNumberField,
    user.productType,
    validateParentForm,
    validateProtocolNumber,
  ]);

  const submitForm = useCallback(async () => {
    return submitRefundForm();
  }, [submitRefundForm]);

  const resetForm = async () => {
    setFormList(initialRefundConsultFormList);
    setCareValue('');
    setServiceDateState({
      error: '',
      value: new Date(),
    });
    itemsRef.current.forEach((form) => {
      form.reset();
    });
    formRef.current?.reset();
  };

  const resetNotes = () => {
    setFormList(initialRefundConsultFormList);
    itemsRef.current.forEach((form) => {
      form.reset();
    });
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        formSubmit: submitForm,
        reset: resetForm,
        resetNotes,
      };
    },
    [submitForm],
  );

  const onRemoveFormItem = (index: number) => {
    const newFormList: any = [...formList];
    if (index > -1 && index < formList.length) {
      newFormList.splice(index, 1);
      itemsRef.current.splice(index, 1);
    }

    setFormList([...newFormList]);
  };

  const onBlurProtocolNumber = async () => {
    try {
      return await validateProtocolNumber(true);
    } catch (err) {
      return false;
    }
  };

  return (
    <ContainerBlock>
      <Container>
        <TitleIntern themeColor={colors.palet.institutional6}>
          {formTitle}
        </TitleIntern>

        <FormWrapper
          ref={formRef}
          style={{
            paddingInline: 16,
            paddingTop: 32,
            paddingBottom: 16,
            display: 'flex',
          }}
          onSubmit={() => {
            console.log('N/A');
          }}
        >
          <DatePicker
            name="serviceDate"
            formLook
            maxDate={oneYearLater()}
            minDate={oneYearBefore()}
            title="Data do serviço:"
            themeColor="red"
            widthContainerDesktop="30%"
            onBlur={(e) => {
              let error = '';

              let value: string | null = e.target?.value;
              if (!isValidDate(e.target.value)) {
                error = 'Informe uma data do serviço válida';
                value = null;
              }
              if (value) {
                const date = new Date(value).getTime();
                const min = oneYearBefore().getTime();
                const max = oneYearLater().getTime();
                if (date > max || date < min) {
                  error = 'Informe uma data dentro do período válido';
                  value = null;
                }
              }

              const [day, month, year] = (
                value?.split('/') ?? [1, 1, 1970]
              ).map(Number);

              setServiceDateState({
                value: value ? new Date(year, month - 1, day, 0, 0, 0) : null,
                error,
              });
            }}
            icon
            error={serviceDateState.error}
            selected={serviceDateState.value}
            onChange={(date: Date) => {
              setServiceDateState({
                value: date,
                error: '',
              });
            }}
          />
          <div style={{ height: 28, width: 8 }} />
          <Input
            formLook
            name="careValue"
            title="Valor do atendimento (R$):"
            themeColor="red"
            widthContainerDesktop="70%"
            onChange={(text) =>
              setCareValue(maskCurrencyOnChange(text.currentTarget.value))
            }
            value={careValue}
          />
          <div style={{ height: 28, width: 24 }} />
          {((Number(user.productType) === 1 && showProtocolNumberField) ||
            (!!newRequestProtocolNumber && neverShowProtocolNumberField)) && (
            <Input
              formLook
              name="protocolNumber"
              title="Protocolo de Atendimento SAC:"
              themeColor="red"
              widthContainerDesktop="100%"
              onBlur={async () => onBlurProtocolNumber()}
            />
          )}
        </FormWrapper>

        {formList &&
          formList.map((item, index) => {
            return (
              <FormItem
                item={item}
                key={item.id}
                type={type}
                index={index + 1}
                ref={(el) => {
                  if (el) {
                    itemsRef.current![index] = el;
                  }
                }}
                documentTypeList={documentTypeList}
                documentAttachmentsList={documentAttachmentsList}
                onRemoveFormItem={(id: number) => onRemoveFormItem(id)}
              />
            );
          })}

        {type !== '5' && type !== '6' && (
          <IncludeNoteText
            onClick={() =>
              setFormList((prev) => [
                ...prev,
                {
                  ...refundFormItem,
                  id: (Math.floor(Math.random() * 10000) + 1).toString(),
                },
              ])
            }
          >
            + incluir Nota para Reembolso
          </IncludeNoteText>
        )}
      </Container>
    </ContainerBlock>
  );
};

export default forwardRef(RefundForm);
