import React from 'react';
import { useAuth } from '../../hooks/auth';

import BeneficiaryTerminationConsult from './Beneficiary';
import CompanyTerminationConsult from './Company';

const TerminationConsult: React.FC = () => {
  const { user } = useAuth();

  if (user.profile === 1) {
    return <BeneficiaryTerminationConsult />;
  }

  return <CompanyTerminationConsult />;
};

export default TerminationConsult;
