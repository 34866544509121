import axios from 'axios';
import Notiflix from 'notiflix';
import React, { useState, useEffect, useCallback } from 'react';

import { useParams } from 'react-router-dom';
import Button from '../../components/Button';
import Modal2 from '../../components/Modal2';
import { LogoUnimedSvg } from '../../components/Svg';
import Title from '../../components/Title';
import api from '../../services/api/api';
import { RowProps } from '../Beneficiaries';

import Form from './Form';
import { ExternalContainer, UnimedLogo, ModalButtonContainer } from './styles';

const CallBeneficiaryIncludeExternal: React.FC = () => {
  const { hash } = useParams<{ hash: string }>();

  const [loading, setLoading] = useState(true);
  const [showDependent, setShowDependent] = useState(false);

  const [simulatedRows, setSimulatedRows] = useState<RowProps>({} as RowProps);
  const [ownerSaved, setOwnerSaved] = useState(false);

  const handleSubmit = useCallback(
    async (dataSubmitParam, files) => {
      try {
        Notiflix.Block.circle('.notiflix-save-beneficiary');
        const NOT_MAPPED = '';
        const params = {
          voterTitleNumber: NOT_MAPPED,
          adoptionDate: NOT_MAPPED,
          brazilianstatesWhichIssuedCtps: NOT_MAPPED,
          weddingDate: NOT_MAPPED,
          shortName: NOT_MAPPED,
          marriageCertificateNumber: NOT_MAPPED,
          numberPisPasep: NOT_MAPPED,
          portabilityProtocolNumber: NOT_MAPPED,
          numberCtps: NOT_MAPPED,
          numberSeriesCtps: NOT_MAPPED,

          requestHolderId: '',
          nameRequestHolder: '',
          previousRequestId: '',
          contractId: simulatedRows.contractId, // contractId
          requestId: simulatedRows.requestId,
          uid: hash,

          beneficiaryName: dataSubmitParam.beneficiaryName,
          email: dataSubmitParam.email,
          birthDate: dataSubmitParam.birthDate,
          hiringDate: dataSubmitParam.hiringDate,
          ddiPhone: dataSubmitParam.DDIPhone,
          dddPhone: dataSubmitParam.phone?.slice(0, 2),
          phone: dataSubmitParam.phone?.slice(2),
          birthCityCode: dataSubmitParam.cityOfBirth,
          initialsBirthState: dataSubmitParam.stateOfBirth,
          cpf: dataSubmitParam.cpf,
          rg: dataSubmitParam.rg,
          issuingBody: dataSubmitParam.rgAgencyIssuer,
          brazilianstatesWhichIssuedRg: dataSubmitParam.rgIssuerState,
          emissionDate: dataSubmitParam.rgIssuerDate,
          issuingAgencyCountry: dataSubmitParam.rgIssuerCountry,
          maritalStatus: dataSubmitParam.maritalStatus,
          physicalSex: dataSubmitParam.phisicalSex,
          motherName: dataSubmitParam.motherName,
          fatherName: dataSubmitParam.fatherName,
          motiveIncludeId: dataSubmitParam.includeReason,
          initialsState: dataSubmitParam.state,
          birthCertificateCodeAlive: dataSubmitParam.declarationOfLive,
          neighborhood: dataSubmitParam.neighborhood,
          ddiNumberCellphone: dataSubmitParam.DDICellphone,
          dddCellPhone: dataSubmitParam.cellphone?.slice(0, 2),
          cellphone: dataSubmitParam.cellphone?.slice(2),
          zipCode: dataSubmitParam.zipCode,
          nationalityCode: dataSubmitParam.nationality,
          address: dataSubmitParam.address,
          complement: dataSubmitParam.complement,
          addressNumber: dataSubmitParam.addressNumber,
          nationalSusCardNumber: dataSubmitParam.susCard,
          socialName: dataSubmitParam.socialName,
          admissionDate: dataSubmitParam.holderHiringDate,
          addressTypePublicPlace: dataSubmitParam.placeType,
          ibgeCityCode: dataSubmitParam.city,
          planId: dataSubmitParam.product,
          observation: dataSubmitParam.observation,
          descriptionCountyBirthForeign: dataSubmitParam.foreignBirthCity,
          foreignRgId: dataSubmitParam.rgForeign,
          registrationCode: dataSubmitParam.registrationCode,
          datePlanAgreementOrigin: dataSubmitParam.contractDateOfOriginPlan,
          sourcePlanPriceValue: dataSubmitParam.priceOfOriginPlan,
          ansGuideProtocolNumber: dataSubmitParam.ANSGuideProtocolNumber,
          protocolIssueDate: dataSubmitParam.issueProtocolDate,
          descriptionOperatorOrigin: dataSubmitParam.originOperator,
          codeCoveragePlanOrigin:
            dataSubmitParam.coverageGeographicalOriginalPlan,
          sourcePlanRegistrationCode: dataSubmitParam.registerOfOriginalProduct,
          typePlanContractOrigin: dataSubmitParam.typeOfContract,
          // relationshipCode: dataSubmitParam.relationship,
        };
        const formData = new FormData();
        Object.entries(params).forEach((ar) => {
          formData.append(ar[0], ar[1]);
        });
        Object.keys(files).forEach((key, index) => {
          if (files[key]) {
            formData.append(`changeFile[${index}]`, files[key]);
          }
        });
        const { data: dataApi } = await api.post(
          `/beneficiary-inclusion/save-details-changes-through-link`,
          formData,
        );

        const { message } = dataApi;
        Modal2.Success({
          closable: false,
          autoWidth: true,
          title: 'Perfeito!',
          subtitle: message,
          text: 'Deseja adicionar um dependente?',
          children: (
            <ModalButtonContainer>
              <Button
                modal
                outlined
                onClick={() => {
                  Modal2.Close();
                  window.location.href = 'https://portal.unimedblumenau.com.br';
                }}
              >
                Sair
              </Button>
              <Button
                modal
                onClick={() => {
                  Modal2.Close();
                  setShowDependent(true);
                }}
              >
                Sim
              </Button>
            </ModalButtonContainer>
          ),
        });
        setOwnerSaved(true);
        return null;
      } catch (err) {
        if (err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos salvar sua requisição no servidor. Por favor, tente novamente mais tarde.',
          );
        }
        return null;
      } finally {
        Notiflix.Block.remove('.notiflix-save-beneficiary');
      }
    },
    [hash, simulatedRows],
  );

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/generate-primary-token`, {
        apiKey: process.env.REACT_APP_JWT_API_KEY,
      })
      .then((resp) => {
        const { token } = resp.data.content;
        api.defaults.headers.common.Authorization = `Bearer ${token}`;
        api
          .get(`/beneficiary-inclusion/validate-link-hash?uid=${hash}`)
          .then((r) => {
            const { data } = r;
            const { content } = data;

            setSimulatedRows((prev) => ({
              ...prev,
              contractId: content.contractId,
              webUsername: content.webUsername,
              requestId: content.requestId,
            }));

            setLoading(false);
          })
          .catch(() => {
            Modal2.Failure({
              closable: true,
              autoWidth: true,
              title: 'Ooops...',
              subtitle: 'Parece que sua chave não é mais válida.',
              text: 'Para prosseguir é necessário solicitar uma nova chave.',
              children: (
                <Button modal onClick={() => Modal2.Close()}>
                  Ok
                </Button>
              ),
            });
          });
      });
  }, [hash]);

  return (
    <ExternalContainer>
      <UnimedLogo inLoading={loading}>
        <LogoUnimedSvg />
      </UnimedLogo>
      {!loading && (
        <>
          <Title>Inclusão de Beneficiário</Title>
          <Form
            dependentParams={simulatedRows}
            className="external-include"
            contractId={simulatedRows.contractId}
            callbackSubmit={handleSubmit}
            isOwner
            showDependentIncludeButton={showDependent}
            isExternal
            preloadOfRequestId={simulatedRows.requestId}
            webUsername={simulatedRows.webUsername}
            requestHolderId={simulatedRows.requestId}
            hiddenOwnerSave={ownerSaved}
          />
        </>
      )}
    </ExternalContainer>
  );
};

export default CallBeneficiaryIncludeExternal;
