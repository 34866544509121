import styled, { css, keyframes } from 'styled-components';
import DatePickerComponent from '../../components/DatePicker';
import Form from '../../components/FormWrapper';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const FormWrapper = styled(Form)`
  display: flex;
  flex-wrap: wrap;

  > h2 {
    color: ${(props) => props.theme.colors.palet.institutional3};
    text-transform: uppercase;
    font-family: 'Unimed-Sans-SemiBold';
    font-size: 1rem;
  }
`;

export const Container = styled.div`
  margin-bottom: 30px;

  @media (min-width: 769px) and (max-width: 1199px) {
    grid-column: 1/11;
    padding: 0 30px;
  }
  @media (min-width: 1200px) {
    grid-column: 2/10;
  }
`;

export const Title = styled.h1`
  font-family: 'Unimed-Slab-Bold';
  font-size: 2.55rem;
  line-height: 2.4rem;
  text-align: center;
  letter-spacing: -1.19px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional6};
  padding: 30px 0;
  @media (max-width: 1200px) {
    font-size: 2.25rem;
    line-height: 2.1rem;
  }
  @media (max-width: 768px) {
    font-size: 1.7rem;
    line-height: 1.5rem;
    padding: 30px;
  }
`;

export const CheckboxGroup = styled.div`
  display: flex;
  row-gap: 8px;
  column-gap: 16px;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const SubTitle = styled.p`
  grid-column: 2/10;
  font-family: 'Unimed-Slab-Bold';
  text-align: center;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 12px;
  font-size: 1.5rem;
  color: ${(props) => props.theme.colors.text.grayishToWhite};
  cursor: pointer;
  @media (max-width: 768px) {
    grid-column: 1;
    margin: 30px;
  }
`;

export const TitleIntern = styled.h2`
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 1.6rem;
  line-height: 1.6rem;

  text-align: left;
  letter-spacing: -1.19px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional};
  margin-bottom: 8px;
  @media (max-width: 768px) {
    font-family: 'Unimed-Sans-Bold';
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
  }
`;

export const DatePicker = styled(DatePickerComponent)``;

interface StepContainerProps {
  defineHeight?: string;
}

export const Steps = styled.section<StepContainerProps>`
  position: relative;
  grid-column: 2/10;
  margin-bottom: 30px;
  ${(props) =>
    props.defineHeight &&
    css`
      height: ${props.defineHeight};
    `}
  @media(max-width: 768px) {
    grid-column: 1;
  }
`;

interface StepsProps {
  show: boolean;
}

const initialStateSteps = css`
  left: 0;
  top: 0;
  width: 100%;
  position: absolute;
  transform: translateX(-20px);
  visibility: hidden;
  opacity: 0;
  transition: 300ms;
  height: 0;
  overflow: hidden;
`;

const showSteps = css`
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
  height: initial;
  overflow: initial;
`;

export const Step1 = styled.section<StepsProps>`
  ${initialStateSteps}
  ${(props) => props.show && showSteps}
  >form {
    & + form {
      margin-top: 24px;
      > button {
        width: 150px;
        margin-left: auto;
      }
    }
  }
`;

export const Step2 = styled.section<StepsProps>`
  ${initialStateSteps}
  ${(props) => props.show && showSteps}
  form {
    > button {
      width: 150px;
      & + button {
        margin-left: auto;
      }
    }
    > div {
      @media (max-width: 768px) {
        &:nth-child(2),
        &:nth-child(3) {
          flex: 1 1;
          width: auto;
        }
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          flex: 1 1;
          width: auto;
          max-width: calc(50% - 4px);
        }
      }
    }
  }
`;

export const ModalButtonsStep2 = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
`;

export const Step3 = styled.section<StepsProps>`
  ${initialStateSteps}
  ${(props) => props.show && showSteps}
  form {
    > button {
      width: 150px;
      & + button {
        margin-left: auto;
      }
    }
  }
`;

export const AcceptTermsContainer = styled.div`
  padding: 11px 26px 26px;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const AcceptTermsTitle = styled.h3`
  font-size: 1.7rem;
  line-height: 1.7rem;
  font-family: 'Unimed-Slab-Bold';
  text-align: left;
  color: ${(props) => props.theme.colors.text.primary};
  margin-bottom: 56px;

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

export const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.palet.institutional11};
  font-family: 'Unimed-Sans-Book';
  font-size: 1.2rem;
  font-weight: 300;
  max-height: calc(100vh - 450px);
  overflow: auto;
`;

export const AcceptTermsContent = styled.section`
  max-height: calc(100vh - 360px);
  overflow-y: auto;
  p {
    font-size: 1rem;
    text-align: left;
    font-family: 'Unimed-Sans-Book';
    color: ${(props) => props.theme.colors.text.primary};
    /* & + p {
      margin-top: 1rem;
    } */
  }
`;
export const AcceptTermsFooter = styled.footer`
  margin-top: 32px;
  form {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      > button {
        margin-top: 16px;
        width: 100%;
      }
    }
  }
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 16px;
  row-gap: 16px;
  > button {
    flex: 1;
  }
`;

export const ErrorRequiredRefundType = styled.span`
  position: relative;
  width: 240px;
  color: ${(props) => 'red'};
  text-transform: uppercase;
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 0.9rem;
  margin-top: -6px;
  animation: ${fadeIn} 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
`;
