import Notiflix from 'notiflix';
import React, { useCallback, useEffect, memo, useState } from 'react';
import {
  Route as RouteDom,
  RouteProps as RoutePropsDom,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import { getParams } from '../utils/Url';

interface RouteProps extends RoutePropsDom {
  isPrivate?: boolean;
  component: React.ComponentType;
}
const Route: React.FC<RouteProps> = ({
  isPrivate,
  component: Component,
  path,
  ...rest
}) => {
  const { isAuthendicated, user, loginJoinIn } = useAuth();
  const redirect = rest.location?.search.split('?redirect=/')[1];

  const joinIn = useCallback(() => {
    const paramsHash = getParams();

    if (paramsHash.hashJoinIn) {
      loginJoinIn(paramsHash.hashJoinIn);
    }
  }, [loginJoinIn]);

  if (path === '/login' && isAuthendicated) {
    joinIn();
    return (
      <RouteDom path={redirect} {...rest}>
        <Redirect to={`/?redirect=${redirect}`} />
      </RouteDom>
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const saveRedirect = useCallback((pathRedirect: any) => {
  //   localStorage.setItem(
  //     '@unimedBlumenauRedirect',
  //     JSON.stringify(pathRedirect),
  //   );
  // }, []);

  // saveRedirect(redirect);

  if (
    isPrivate &&
    isAuthendicated &&
    user.mustUpdatePassword === true &&
    path !== '/alterar-senha'
  ) {
    Notiflix.Notify.info(
      'Como é seu primeiro acesso, é necessário alterar senha',
    );

    return (
      <RouteDom path="/alterar-senha" {...rest}>
        <Redirect to="/alterar-senha" />
      </RouteDom>
    );
  }

  return (
    <RouteDom path={path} {...rest}>
      {isPrivate && !isAuthendicated ? (
        <Redirect to={`/login?redirect=${path}`} />
      ) : (
        <Component />
      )}
    </RouteDom>
  );
};

export default memo(Route);
