/* eslint-disable no-unused-expressions */
import React, { useCallback, useRef } from 'react';
import Notiflix from 'notiflix';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Container, Title, FormWrapper, Button } from './styles';
import { useTheme } from '../../hooks/theme';
import Input from '../../components/Input';
import PageWrapper from '../../components/PageWrapper';
import api from '../../services/api/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { useContract } from '../../hooks/contract';
import Modal2 from '../../components/Modal2';
import DatePickerUnform from '../../components/DatePickerUnform';

const BeneficiaryInclusionGenerateLink: React.FC = () => {
  const { contract } = useContract();
  const { theme } = useTheme();
  const formRef = useRef<FormHandles>(null);

  const resetForm = useCallback(() => {
    if (formRef.current) {
      formRef.current.setFieldValue('beneficiaryName', '');
      formRef.current.setFieldValue('cpf', '');
      formRef.current.setFieldValue('birthDate', null);
      formRef.current.setFieldValue('email', '');
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        Notiflix.Block.circle('.button-notiflix-loading');

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          beneficiaryName: Yup.string().required(
            'Informe o nome do beneficiário.',
          ),
          cpf: Yup.string().required('Informe o seu cpf.'),
          email: Yup.string()
            .required('Informe o seu E-mail.')
            .email('Informe um email válido'),
          birthDate: Yup.string().required('Informe uma data').nullable(true),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { data: dataApi } = await api.post(
          `/beneficiary-inclusion/send-information-before-link`,
          {
            contractId: contract.contractId,
            beneficiaryName: data.beneficiaryName,
            cpf: data.cpf,
            birthDate: data.birthDate,
            email: data.email,
          },
        );

        const { message, content } = dataApi;
        const { requestId } = content[0];

        Modal2.Success({
          closable: false,
          title: 'Perfeito!',
          subtitle: `Requisição: ${requestId}, link foi gerado a qual foi encaminhado para o teu e-mail.`,
          text: message,
          children: (
            <>
              <Button modal outlined onClick={() => Modal2.Close()}>
                Voltar
              </Button>
              <Button
                modal
                onClick={() => {
                  resetForm();
                  Modal2.Close();
                }}
              >
                Ok
              </Button>
            </>
          ),
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err?.response?.status === 400) {
          Modal2.Close();
          Modal2.Failure({
            closable: false,
            title: 'Ooops..',
            text: err.response.data.message,
            children: (
              <>
                <Button modal onClick={() => Modal2.Close()}>
                  Ok
                </Button>
              </>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos processar sua solicitação no servidor. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove('.button-notiflix-loading');
      }
    },
    [contract.contractId, resetForm],
  );

  return (
    <PageWrapper
      gridTemplateColumns="1fr 8fr 1fr"
      gridTemplateRows="auto auto auto"
    >
      <Container>
        <Title themeColor={theme.colors.palet.institutional8}>
          Inclusão de funcionário - Gerar Link
        </Title>
        <FormWrapper ref={formRef} formLook onSubmit={handleSubmit}>
          <Input
            id="beneficiaryName"
            name="beneficiaryName"
            type="text"
            title="Nome"
            formLook
            widthContainerDesktop="50%"
            themeColor={theme.colors.palet.institutional8}
          />
          <Input
            id="CPF"
            name="cpf"
            type="text"
            title="CPF"
            masks={['999.999.999-99', '99.999.999/9999-99']}
            formLook
            widthContainerDesktop="50%"
            themeColor={theme.colors.palet.institutional8}
          />
          <DatePickerUnform
            name="birthDate"
            title="Data de nascimento:"
            widthContainerDesktop="50%"
            themeColor={theme.colors.palet.institutional8}
            icon
            formLook
            maxDate={new Date()}
          />
          <Input
            id="email"
            name="email"
            type="e-mail"
            title="E-mail"
            formLook
            widthContainerDesktop="50%"
            themeColor={theme.colors.palet.institutional8}
          />
          <Button
            type="submit"
            grayButton
            autoWidth
            formLook
            className="button-notiflix-loading"
          >
            Enviar
          </Button>
        </FormWrapper>
      </Container>
    </PageWrapper>
  );
};

export default BeneficiaryInclusionGenerateLink;
