import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { useTheme } from 'styled-components';
import { useContract } from '../../hooks/contract';
import api from '../../services/api/api';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';
import PageWrapper from '../../components/PageWrapper';
import Title from '../../components/Title';
import Button from '../../components/Button';

import {
  Container,
  InsideText,
  Dropdown,
  DropdownTitle,
  TitleWrapper,
  ButtonContainer,
} from './styles';

interface readjustmentsProps {
  readjustmentDocumentNumber: number;
  statementReleaseDate: string;
  titleDescription: string;
  document: string;
  file: string;
  reportNumber: number;
  adjustmentRateApplied: string;
  proposedReadjustmentRate: number;
  readjustmentNumber: number;
}

const ReadjustmentsIndex: React.FC = () => {
  const { contract } = useContract();
  const { colors } = useTheme();
  const [readjustments, setReadjustments] = useState<readjustmentsProps[]>([]);

  const handleReadjustmentDownload = useCallback(
    async (rowData, notiflix: string) => {
      const { readjustmentNumber } = rowData;
      try {
        Notiflix.Block.circle(notiflix);
        const { data } = await api.get(
          `/readjustment-index/download?readjustmentNumber=${readjustmentNumber}`,
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(data);
      } catch (err) {
        if (err?.response?.status === 400) {
          Notiflix.Notify.info(err.response.data?.message);
        } else {
          Notiflix.Notify.failure(
            'Não conseguimos buscar seu relatório. Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [],
  );

  const getReadjustmentData = useCallback(async () => {
    try {
      Notiflix.Loading.circle();
      const { data } = await api.get(
        `/readjustment-index/list?contractId=${contract.contractId}`,
      );
      const { content } = data;
      setReadjustments(content);
    } catch (err) {
      if (err?.response?.message) {
        Notiflix.Notify.info(err.response.message);
      } else {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os índices de reajuste do servidor. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Loading.remove();
    }
  }, [contract]);

  const handlePrint = useCallback(
    async (rowData, notiflix: string) => {
      const { readjustmentDocumentNumber } = rowData;
      try {
        Notiflix.Block.circle(notiflix);
        const { data } = await api.get(
          `/report/list-of-company-portal-reports?contractId=${contract.contractId}&exhibitionLocation=8`,
        );
        const { nrSeqRelatorio, Parametros: P } = data.content[0];
        const params = [readjustmentDocumentNumber];
        const body = {
          nrSeqRelatorio: nrSeqRelatorio ?? '',
          Parametros: [
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...P.map((p: { cdParametro: any }, index: any) => {
              return {
                id: p.cdParametro,
                valor: params[index],
              };
            }),
          ],
        };

        const { data: dataReport } = await api.post(
          `/report/generate-report`,
          body,
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(dataReport);
      } catch (err) {
        if (err?.response?.status === 400) {
          Notiflix.Notify.info(err.response.data?.message);
        } else {
          Notiflix.Notify.failure(
            'Não conseguimos buscar seu relatório. Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [contract],
  );

  useEffect(() => {
    if (contract.contractId) {
      getReadjustmentData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  return (
    <PageWrapper gridTemplateRows="auto auto auto 1fr">
      <Container>
        <Title themeColor={colors.palet.institutional}>
          Histórico de Reajustes Anuais
        </Title>
        {readjustments.map((reajuste) => {
          const uuid1 = v4();
          const uuid2 = v4();
          return (
            <Dropdown
              key={reajuste.readjustmentDocumentNumber}
              titleJSX={
                <TitleWrapper>
                  <DropdownTitle>{reajuste.titleDescription}</DropdownTitle>
                  <ButtonContainer>
                    {reajuste.readjustmentNumber && (
                      <Button
                        sausageLook
                        className={`notiflix-${uuid2} donwload-button`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleReadjustmentDownload(
                            reajuste,
                            `.notiflix-${uuid2}`,
                          );
                        }}
                        float="right"
                        autoWidth
                        style={{ maxHeight: '21px' }}
                      >
                        Baixar Arquivo
                      </Button>
                    )}
                    {!reajuste.readjustmentNumber && (
                      <Button
                        sausageLook
                        className={`notiflix-${uuid1} `}
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePrint(reajuste, `.notiflix-${uuid1}`);
                        }}
                        float="right"
                        autoWidth
                        style={{ maxHeight: '21px' }}
                      >
                        Imprimir Extrato
                      </Button>
                    )}
                  </ButtonContainer>
                </TitleWrapper>
              }
              className="table-notiflix"
              body={
                <>
                  {reajuste.document && (
                    <InsideText>{reajuste.document}</InsideText>
                  )}
                  {reajuste.adjustmentRateApplied && (
                    <InsideText>
                      Reajuste Aplicado: {reajuste.adjustmentRateApplied}%
                    </InsideText>
                  )}
                  {reajuste.proposedReadjustmentRate && (
                    <InsideText>
                      Reajuste proposto: {reajuste.proposedReadjustmentRate}%
                    </InsideText>
                  )}
                  {reajuste.statementReleaseDate && (
                    <InsideText>
                      Data da Liberação: {reajuste.statementReleaseDate}
                    </InsideText>
                  )}
                </>
              }
            />
          );
        })}
      </Container>
    </PageWrapper>
  );
};

export default ReadjustmentsIndex;
