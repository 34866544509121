/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
// import './datepicker-light-themestyles.css';
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';

import { ptBR } from 'date-fns/locale';

import { FiChevronDown } from 'react-icons/fi';
import { useField } from '@unform/core';
import {
  InputDate,
  Container,
  Error,
  SheduleSvg,
  Title,
} from '../DatePicker/styles';
import { dateToPTBR, formatDate, ptBRToDate } from '../../utils/formatt';

export interface DatePickerProps
  extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  placeholder?: string;
  icon?: boolean;
  className?: string;
  title?: string;
  formLook?: boolean;
  widthContainerDesktop?: string;
  themeColor?: string;
  onChangeDate?: (e: any) => void;
}

const DatePickerUnform: React.FC<DatePickerProps> = ({
  name,
  placeholder,
  icon,
  className,
  title,
  formLook,
  widthContainerDesktop,
  showTimeSelectOnly,
  themeColor,
  onChangeDate,
  ...rest
}) => {
  registerLocale('pt-BR', ptBR);

  const datepickerRef = useRef<ReactDatePicker>(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);

  const [date, setDate] = useState<Date | null | undefined>(
    defaultValue ?? null,
  );

  const handleKeyDown = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const data = event.target.value;
      clearError();
      if (data) {
        if (!showTimeSelectOnly) {
          // eslint-disable-next-line no-param-reassign
          event.target.value = formatDate(data);
        }
        // else {
        //   // eslint-disable-next-line no-param-reassign
        //   event.target.value = data;
        // }
        if (data.length === 10) {
          if (rest.minDate || rest.maxDate) {
            const prevDate = ptBRToDate(data);
            if (prevDate) {
              if (rest.minDate && rest.maxDate) {
                if (
                  prevDate.getTime() >= rest.minDate.getTime() &&
                  prevDate.getTime() <= rest.maxDate.getTime()
                ) {
                  setDate(prevDate);
                } else if (prevDate.getTime() < rest.minDate.getTime()) {
                  setDate(rest.minDate);
                } else if (prevDate.getTime() > rest.maxDate.getTime()) {
                  setDate(rest.maxDate);
                }
              } else if (rest.minDate) {
                if (prevDate.getTime() >= rest.minDate.getTime()) {
                  setDate(prevDate);
                } else if (prevDate.getTime() < rest.minDate.getTime()) {
                  setDate(rest.minDate);
                }
              } else if (rest.maxDate) {
                if (prevDate.getTime() <= rest.maxDate.getTime()) {
                  setDate(prevDate);
                } else if (prevDate.getTime() > rest.maxDate.getTime()) {
                  setDate(rest.maxDate);
                }
              }
            }
          } else {
            setDate(ptBRToDate(data));
          }
        }
      }
    },
    [clearError, showTimeSelectOnly, rest],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current?.props,
      clearValue: (ref) => {
        ref.clear();
      },
      getValue: (ref) => {
        // eslint-disable-next-line no-nested-ternary
        return ref.selected
          ? dateToPTBR(ref.selected)
          : date
          ? dateToPTBR(date)
          : '';
      },
      setValue: (ref, value) => {
        try {
          // eslint-disable-next-line eqeqeq
          if (typeof value === 'string' && value != '') {
            const temp = ptBRToDate(value);
            setDate(temp);
          } else {
            // eslint-disable-next-line eqeqeq
            setDate(value != '' ? (value as Date) : null);
          }
        } catch (err) {
          console.log(err);
          setDate(null);
        }
        clearError();
      },
    });
  }, [clearError, date, fieldName, registerField]);
  return (
    <Container
      className={className}
      isError={!!error}
      widthContainerDesktop={widthContainerDesktop}
      formLook={formLook}
    >
      {title && formLook && <Title themeColor={themeColor}>{title}</Title>}

      <InputDate
        selected={date}
        onChange={(e) => {
          if (onChangeDate) {
            onChangeDate(e);
          }

          setDate(e as unknown as Date);
        }}
        onChangeRaw={handleKeyDown}
        hasIcon={icon}
        showTimeSelectOnly={showTimeSelectOnly}
        formLook={formLook}
        name={name}
        dateFormat="dd/MM/yyyy"
        placeholderText={placeholder}
        locale="pt-BR"
        onError={!!error}
        showMonthDropdown
        showYearDropdown
        peekNextMonth
        dropdownMode="select"
        {...rest}
        ref={datepickerRef}
      />
      {icon && <SheduleSvg className="shedule" />}
      {formLook && (
        <FiChevronDown
          size={24}
          className={`chevron ${error ? 'onError' : ''}`}
        />
      )}
      {error && (
        <Error>
          <span>{error}</span>
        </Error>
      )}
    </Container>
  );
};

export default DatePickerUnform;
