export function isGreatherThanOneYear(date: Date): boolean {
  const currentDate = new Date();
  const oneYearFromNow = new Date(
    currentDate.getFullYear() + 1,
    currentDate.getMonth(),
    currentDate.getDate(),
  );
  return date > oneYearFromNow;
}

export function oneYearLater(): Date {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const yearLater = new Date(
    currentYear + 1,
    currentDate.getMonth(),
    currentDate.getDate(),
  );

  return yearLater;
}

export function oneYearBefore(): Date {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const yearBefore = new Date(
    currentYear - 1,
    currentDate.getMonth(),
    currentDate.getDate(),
  );

  return yearBefore;
}

export function isValidDate(date: string): boolean {
  const parsedDate = new Date(date);
  return parsedDate instanceof Date && !Number.isNaN(parsedDate.getTime());
}
