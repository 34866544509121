/* eslint-disable max-len */
import React, { memo } from 'react';

const Failure: React.FC = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 0C13.4316 0 0 13.4316 0 30C0 46.5684 13.4316 60 30 60C46.5684 60 60 46.5684 60 30C60 13.4316 46.5684 0 30 0ZM30 54C16.7448 54 6 43.2552 6 30C6 16.7448 16.7448 6 30 6C43.2552 6 54 16.7448 54 30C54 43.2552 43.2552 54 30 54Z"
        fill="#ED1651"
      />
      <path
        d="M20.0004 24C21.6573 24 23.0004 22.6569 23.0004 21C23.0004 19.3431 21.6573 18 20.0004 18C18.3436 18 17.0004 19.3431 17.0004 21C17.0004 22.6569 18.3436 24 20.0004 24Z"
        fill="#ED1651"
      />
      <path
        d="M39.9996 24C41.6565 24 42.9996 22.6569 42.9996 21C42.9996 19.3431 41.6565 18 39.9996 18C38.3428 18 36.9996 19.3431 36.9996 21C36.9996 22.6569 38.3428 24 39.9996 24Z"
        fill="#ED1651"
      />
      <path
        d="M42.4356 35.952C40.1373 36.4266 38.0282 37.5638 36.3689 39.2234C34.7096 40.8829 33.5726 42.9921 33.0984 45.2905C32.9642 45.969 32.5997 46.5803 32.0666 47.0209C31.5334 47.4616 30.8644 47.7044 30.1728 47.7085H30.1284C29.6886 47.7093 29.2542 47.6126 28.8563 47.4253C28.4584 47.2381 28.107 46.965 27.8273 46.6256C27.5476 46.2863 27.3467 45.8891 27.2389 45.4628C27.1311 45.0365 27.1192 44.5916 27.204 44.1601C27.9056 40.6821 29.6182 37.4887 32.1271 34.98C34.636 32.4713 37.8296 30.7589 41.3076 30.0576C41.739 29.9735 42.1838 29.9859 42.6098 30.094C43.0359 30.2021 43.4328 30.4033 43.7719 30.683C44.111 30.9627 44.384 31.314 44.5712 31.7117C44.7585 32.1094 44.8553 32.5437 44.8548 32.9832V33.0264C44.8507 33.7183 44.6077 34.3874 44.1668 34.9206C43.7259 35.4537 43.1143 35.8181 42.4356 35.952Z"
        fill="#ED1651"
      />
    </svg>
  );
};
export default memo(Failure);
