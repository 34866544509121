import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { transparentize } from 'polished';
import {
  BandaidSvg,
  ExameSvg,
  CalendarSvg,
  FirstAidBoxSvg,
  WhatsappFirstAidSvg,
  GallerySvg,
  PlanSvg,
} from '../../components/Svg';

export const BandaidIcon = styled(BandaidSvg)``;
export const ExameIcon = styled(ExameSvg)``;
export const CalendarIcon = styled(CalendarSvg)``;
export const FirstIdBoxIcon = styled(FirstAidBoxSvg)``;
export const WhatsappFirstAidIcon = styled(WhatsappFirstAidSvg)``;
export const GalleryIcon = styled(GallerySvg)``;
export const PlanIcon = styled(PlanSvg)``;

export const Container = styled.div`
  margin-bottom: 30px;
  grid-column: 2/3;
  @media (max-width: 768px) {
    grid-column: 1;
  }
  @media (min-width: 769px) and (max-width: 1600px) {
    grid-column: 1/4;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 8px;
  row-gap: 5em;
  width: 100%;
  padding: 5em 2em;
  @media (max-width: 1024px) {
    row-gap: 8px;
    padding: 1.5em;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    row-gap: 8px;
    /* height: 100%; */

    grid-template-columns: repeat(2, 1fr);
  }

  .contentContainer {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    text-align: center;
    word-break: keep-all;
    margin-bottom: auto;
    text-decoration: none;
    gap: 10px;
    background: ${(props) => props.theme.colors.background};
    box-shadow: 0px 5px 20px
      ${(props) =>
        transparentize(0.4, props.theme.colors.text.lightGrayToBlack)};
    border-radius: 1em;
    padding: 3em 0;
    h2 {
      font-family: 'Unimed-Sans-SemiBold';

      color: ${(props) => props.theme.colors.text.grayishToWhite};
      transition: 300ms;
      font-size: 1rem;
      line-height: 1.1rem;
      margin-top: 8px;
      text-align: left;
      @media (max-width: 768px) {
        margin-top: 0px;
        margin-left: 10px;
      }
    }
    > div {
      transition: 300ms;
    }

    &:hover {
      > div {
        transform: scale(1.1);
      }
      h2 {
        color: ${(props) =>
          props.theme.title === 'light'
            ? props.theme.colors.palet.institutional2
            : props.theme.colors.text.whiteToGrayish};
      }
    }
  }
`;

export const ContentContainer = styled(Link)`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  text-align: center;
  word-break: keep-all;
  margin-bottom: auto;
  text-decoration: none;
  gap: 10px;
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 5px 20px
    ${(props) => transparentize(0.4, props.theme.colors.text.lightGrayToBlack)};
  border-radius: 1em;
  padding: 3em 0;

  h2 {
    font-family: 'Unimed-Sans-SemiBold';

    color: ${(props) => props.theme.colors.text.grayishToWhite};
    transition: 300ms;
    font-size: 1rem;
    line-height: 1.1rem;
    margin-top: 8px;
    text-align: left;
    @media (max-width: 768px) {
      margin-top: 0px;
      margin-left: 10px;
    }
  }
  > div {
    transition: 300ms;
  }

  &:hover {
    > div {
      transform: scale(1.1);
    }
    h2 {
      color: ${(props) =>
        props.theme.title === 'light'
          ? props.theme.colors.palet.institutional2
          : props.theme.colors.text.whiteToGrayish};
    }
  }
`;

export const IconBackground = styled.div`
  background: ${(props) => props.theme.colors.palet.institutional4};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  width: 80px;
  height: 80px;
  padding: 0.8em;
  min-height: 80px;
  min-width: 80px;
`;
