/* eslint-disable max-len */
import React from 'react';
import { svgProps } from '..';

const EditSquare: React.FC<svgProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.58064 0.838867H16.2581V2.93564H2.62903V17.6131H21.5V8.17783H23.5968V18.6614L22.5484 19.7098H1.58064L0.532257 18.6614V1.88725L1.58064 0.838867ZM21.9904 0.838867L23.5967 2.44847L23.5752 3.18882L17.6854 9.09068L17.5462 9.21957L17.15 9.6166L17.0429 9.71314L13.8303 11.3227L13.1128 10.6037L14.7191 7.38454L14.8156 7.27726L15.2118 6.88023L15.3403 6.74073L21.2301 0.838867H21.9904ZM15.3296 8.60785L14.7727 9.68091L15.8864 9.16586L15.3296 8.60785ZM16.0684 7.50256L16.9144 8.35032L22.4295 2.82401L21.5834 1.97638L16.0684 7.50256Z"
        fill="white"
      />
    </svg>
  );
};

export default EditSquare;
