import Notiflix from 'notiflix';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { v4 } from 'uuid';
import { useTheme } from 'styled-components';
import PageWrapper from '../../../components/PageWrapper';
import { Column } from '../../../components/Table';
import api from '../../../services/api/api';
import AddField, { getTag } from '../../../utils/tableUtils';
import { Container, Table, FormWrapper, Step1, Step2, Steps } from './styles';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { useContract } from '../../../hooks/contract';
import Title, { TitleIntern } from '../../../components/Title';

interface RowProps {
  phisicalPersonCode: string;
  changeNumber: number;
  solicitationDate: string;
  beneficiaryName: string;
  cardNumber: string;
  holderName: string;
  requesterUsername: string;
}
interface RowDetailProps {
  changeNumber: number;
  fieldDescription: string;
  previousValue: string;
  requestedValue: string;
  situation: string;
  observation: string | null;
  reasonForRejection: string | null;
  extended: RowProps;
}

const CompanyChangeRegisterDataConsult: React.FC = () => {
  const { contract } = useContract();
  const { colors } = useTheme();
  const formRef = useRef<FormHandles>(null);
  const [filter, setFilter] = useState({
    beneficiaryFilter: '',
    membershipFilter: '',
  });
  const step1Ref = useRef<HTMLDivElement>(null);
  const step2Ref = useRef<HTMLDivElement>(null);
  const [actualStep, setActualStep] = useState(1);
  const [heightStep, setHeightStep] = useState(['']);

  const [dataTable, setDataTable] = useState([] as RowProps[]);
  const [dataTable2, setDataTable2] = useState([] as RowDetailProps[]);

  const handleOpenDetails = useCallback(
    async (rowData: RowProps, notiflix: string) => {
      try {
        Notiflix.Block.circle(notiflix);
        const { data } = await api.get(
          `/company-rescission/registration-change-details?changeNumber=${rowData.changeNumber}`,
        );
        Notiflix.Block.remove(notiflix);
        const content = data.content as RowDetailProps[];
        setDataTable2(
          content.reduce((acc, act) => {
            acc.push({
              ...act,
              extended: rowData,
            });
            return acc;
          }, [] as RowDetailProps[]),
        );
        setActualStep(2);
      } catch (err) {
        Notiflix.Block.remove(notiflix);
        Notiflix.Notify.failure(
          'Ops.. Não conseguimos buscar os detalhes do registro no servidor. Por favor, tente novamente mais tarde.',
        );
      }
    },
    [],
  );

  const getCompanyChangeRegisterData = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');
      const { data } = await api.get(
        `/company-rescission/registration-change-request?contractId=${contract.contractId}&cardNumber=${filter.membershipFilter}&beneficiaryName=${filter.beneficiaryFilter}`,
      );
      const { content } = data;
      setDataTable(content);
    } catch (err) {
      setDataTable([]);
      if (!err.response?.data?.message) {
        Notiflix.Notify.failure('Ops não conseguimos buscar pelos dados');
      }
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, [contract.contractId, filter.beneficiaryFilter, filter.membershipFilter]);

  const handleFilter = useCallback((data) => {
    setFilter(data);
  }, []);

  const defineHeightOfSteps = useMemo(() => {
    switch (actualStep) {
      case 1:
        return heightStep[0];
      case 2:
        return heightStep[1];
      default:
        return '0px';
    }
  }, [actualStep, heightStep]);

  const calculateHeightOfSteps = useCallback(() => {
    setHeightStep([
      `${step1Ref.current?.scrollHeight}px`,
      `${step2Ref.current?.scrollHeight}px`,
    ]);
  }, []);

  useLayoutEffect(() => {
    calculateHeightOfSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTable, dataTable2]);

  useEffect(() => {
    window.addEventListener('resize', calculateHeightOfSteps);
    return () => {
      window.removeEventListener('resize', calculateHeightOfSteps);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contract.contractId) {
      getCompanyChangeRegisterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract, filter]);

  return (
    <PageWrapper
      gridTemplateColumns="1fr 8fr 1fr"
      gridTemplateRows="auto auto 1fr"
    >
      <Container>
        <Title themeColor={colors.palet.institutional9}>
          Consultar Alterações Cadastrais
        </Title>
        <Steps defineHeight={defineHeightOfSteps}>
          <Step1 show={actualStep === 1} ref={step1Ref}>
            <FormWrapper formLook onSubmit={handleFilter} ref={formRef}>
              <Input
                formLook
                title="Beneficiário"
                name="beneficiaryFilter"
                widthContainerDesktop="33%"
                themeColor={colors.palet.institutional9}
              />
              <Input
                formLook
                title="Carteirinha"
                name="membershipFilter"
                widthContainerDesktop="33%"
                themeColor={colors.palet.institutional9}
              />
              <Button type="submit" formLook grayButton>
                Filtrar
              </Button>
            </FormWrapper>

            <Table items={dataTable} className="notiflix-table-loading">
              <Column
                field="solicitationDate"
                header="Data da Solicitação"
                body={(data) =>
                  AddField(data.solicitationDate, 'Data da Solicitação')
                }
              />
              <Column
                field="beneficiaryName"
                header="Nome"
                sortable
                body={(data) => AddField(data.beneficiaryName, 'Nome')}
              />
              <Column
                field="requesterUsername"
                header="Usuário Solicitação"
                sortable
                body={(data) =>
                  AddField(data.requesterUsername, 'Usuário Solicitação')
                }
              />
              <Column
                style={{ width: '120px' }}
                body={(data) => {
                  const uniqueId = v4();
                  return (
                    <Button
                      sausageLook
                      grayButton
                      autoWidth
                      onClick={() =>
                        handleOpenDetails(data, `.notiflix-details-${uniqueId}`)
                      }
                      className={`notiflix-details-${uniqueId}`}
                      noChangeColor
                    >
                      Detalhes
                    </Button>
                  );
                }}
              />
            </Table>
          </Step1>
          <Step2 show={actualStep === 2} ref={step2Ref}>
            <TitleIntern themeColor={colors.palet.institutional9}>
              Consulta - Detalhes
            </TitleIntern>
            <FormWrapper onSubmit={() => setActualStep(1)}>
              <Input
                insideInputTitle="Nome do beneficiário"
                name="beneficiaryName"
                value={
                  dataTable2 &&
                  dataTable2[0] &&
                  dataTable2[0].extended.beneficiaryName
                }
                themeColor={colors.palet.institutional9}
              />
              <Input
                insideInputTitle="Data da solicitação"
                name="dateRequest"
                value={
                  dataTable2 &&
                  dataTable2[0] &&
                  dataTable2[0].extended.solicitationDate
                }
                themeColor={colors.palet.institutional9}
              />
              <Button secondary autoWidth type="submit" formLook>
                Voltar
              </Button>
            </FormWrapper>

            <Table items={dataTable2} className="notiflix-table-loading">
              <Column
                field="fieldDescription"
                header="Campo"
                sortable
                body={(data: RowDetailProps) =>
                  AddField(data.fieldDescription, 'Campo')
                }
              />
              <Column
                field="previousValue"
                header="Valor Anterior"
                sortable
                body={(data: RowDetailProps) =>
                  AddField(data.previousValue, 'Valor Anterior')
                }
              />
              <Column
                field="requestedValue"
                header="Valor Solicitado"
                sortable
                body={(data: RowDetailProps) =>
                  AddField(data.requestedValue, 'Valor Solicitado')
                }
              />
              <Column
                field="situation"
                header="Situação"
                headerStyle={{ textAlign: 'right' }}
                sortable
                body={(data: RowDetailProps) => {
                  switch (data.situation) {
                    case 'Pendente':
                      return getTag('Situação', 'Pendente', 'orange');
                    case 'Reprovado':
                      return getTag('Situação', 'Reprovado', 'red');
                    case 'Aprovado':
                      return getTag('Situação', 'Aprovado', 'green');
                    default:
                      return <>{data.situation}</>;
                  }
                }}
              />
            </Table>
          </Step2>
        </Steps>
      </Container>
    </PageWrapper>
  );
};

export default CompanyChangeRegisterDataConsult;
