import styled from 'styled-components';
import TableComponent from '../../components/Table';

export const Container = styled.section`
  grid-column: 2/3;
  @media (max-width: 768px) {
    grid-column: 1;
    margin-bottom: 30px;
  }
  @media (min-width: 769px) and (max-width: 1600px) {
    grid-column: 1/4;
  }
  > form {
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      > div {
        &:nth-child(2),
        &:nth-child(3) {
          width: auto;
          flex: 1 1 0;
        }
      }
    }
  }
`;

export const Table = styled(TableComponent)``;

export const ActionsContainer = styled.div`
  @media (max-width: 768px) {
    width: calc(100vw - 100px);
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    row-gap: 16px;
    > button {
      margin: 0;
      width: 100%;
    }
  }
`;
