import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { v4 } from 'uuid';
import api from './api';

interface TranslationProps {
  tag: string;
  lang?: string;
  className?: string;
}

const Translation: React.FC<TranslationProps> = ({
  tag,
  lang = 'PT',
  className,
}) => {
  const [translation, setTranslation] = useState('<p>...</p>');
  const uuid = useMemo(() => {
    return v4();
  }, []);

  const getTranslation = useCallback(async () => {
    try {
      Notiflix.Block.circle(`.notiflix-translation-${uuid}`);
      const { data } = await api.get(
        `/gw/translation?key=${tag}&language=${lang}`,
      );
      const { content } = data;
      setTranslation(content[tag].replaceAll('&nbsp;', ' '));
    } catch (err) {
      console.log('etiqueta nao carregada =>', tag);
      Notiflix.Notify.failure(
        'Ops... Não foi possível carregar uma etiqueta...',
      );
    } finally {
      Notiflix.Block.remove(`.notiflix-translation-${uuid}`);
    }
  }, [lang, tag, uuid]);

  useEffect(() => {
    getTranslation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: translation }}
      className={`notiflix-translation-${uuid} ${className}`}
    />
  );
};
export default Translation;
