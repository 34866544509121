/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useLayoutEffect,
} from 'react';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Notiflix from 'notiflix';
import axios from 'axios';
import { unMask } from 'remask';
import PageWrapper from '../../components/PageWrapper';
import {
  Container,
  Title,
  ReportsContainer,
  Steps,
  Step1,
  Step2,
  Step3,
  ButtonsWrapper,
} from './styles';
import FormWrapper from '../../components/FormWrapper';
import Input from '../../components/Input';
import Select, { SelectHandles } from '../../components/Select';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import DatePicker from '../../components/DatePicker';
import Modal2 from '../../components/Modal2';
import api from '../../services/api/api';
import { useContract } from '../../hooks/contract';
import { EnterpriseUser, UserCommon, useAuth } from '../../hooks/auth';

const AddUser: React.FC = () => {
  const { contract } = useContract();
  const { user } = useAuth();
  const beneficiaryStatus = [
    {
      title: 'Selecione...',
      value: '',
    },
    {
      title: 'Sim',
      value: '1',
    },
    {
      title: 'Não',
      value: '2',
    },
  ];

  const [zipCodeSearch, setZipCodeSearch] = useState('');
  const genderOptions = [
    {
      title: 'Selecione...',
      value: '',
    },
    {
      title: 'Masculino',
      value: '1',
    },
    {
      title: 'Feminino',
      value: '2',
    },
  ];
  const [stateOptions, setStateOptions] = useState([
    {
      title: '',
      value: '',
    },
  ]);
  const [cityOptions, setCityOptions] = useState([
    {
      title: '',
      value: '',
    },
  ]);

  const [birthday, setBirthday] = useState(
    new Date(`01/01/${new Date().getFullYear()}`),
  );
  const history = useHistory();
  const step1Ref = useRef<HTMLDivElement>(null);
  const step2Ref = useRef<HTMLDivElement>(null);
  const step3Ref = useRef<HTMLDivElement>(null);
  const beneficiaryStatusRef = useRef<SelectHandles>(null);
  const beneficiaryRef = useRef<SelectHandles>(null);
  const genderRef = useRef<SelectHandles>(null);
  const stateRef = useRef<SelectHandles>(null);
  const cityRef = useRef<SelectHandles>(null);
  const formRefStep1 = useRef<FormHandles>(null);
  const formRefStep2 = useRef<FormHandles>(null);
  const formRefStep3 = useRef<FormHandles>(null);
  const [actualStep, setActualStep] = useState(1);
  const [heightStep, setHeightStep] = useState(['']);
  const [beneficiaryParentsDetails, setBeneficiaryParentsDetails] = useState(
    [],
  );
  const [beneficiaryParents, setBeneficiaryParents] = useState([
    {
      title: 'Selecione...',
      value: '',
    },
  ]);

  const defineHeightOfSteps = useMemo(() => {
    switch (actualStep) {
      case 1:
        return heightStep[0];
      case 2:
        return heightStep[1];
      case 3:
        return heightStep[2];
      default:
        return '0px';
    }
  }, [actualStep, heightStep]);

  const calculateHeightOfSteps = useCallback(() => {
    setHeightStep([
      `${step1Ref.current?.scrollHeight}px`,
      `${step2Ref.current?.scrollHeight}px`,
      `${step3Ref.current?.scrollHeight}px`,
    ]);
  }, []);

  const getBeneficiaryParents = useCallback(async () => {
    try {
      const { data } = await api.get(`/company/list-person-to-link`);
      const { content } = data;
      setBeneficiaryParentsDetails(content);

      setBeneficiaryParents(
        data.content.reduce(
          (
            acc: { title: string; value: string }[],
            act: {
              physicalPersonName: string;
              physicalPersonCode: string;
            },
          ) => {
            acc.push({
              title: act.physicalPersonName,
              value: act.physicalPersonCode,
            });
            return acc;
          },
          [],
        ),
      );
    } catch (error) {
      Notiflix.Notify.failure(
        'Houve um problema ao fazer sua requisição. Tente novamente mais tarde.',
      );
    }
  }, []);

  const handleFirstStepForm = useCallback(
    async (data) => {
      try {
        beneficiaryStatusRef.current?.setError('');
        formRefStep1.current?.setErrors({});
        if (beneficiaryStatusRef.current?.value === '') {
          beneficiaryStatusRef.current.setError('Selecione uma opção');
        }
        const schema = Yup.object().shape({
          userName: Yup.string()
            .required('Informe o usuário')
            .max(15, 'Digite no máximo 15 caracteres'),
          email: Yup.string()
            .required('Informe o e-mail')
            .email('Informe um e-mail válido'),
          password: Yup.string().required('Informe a senha'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        if (!beneficiaryStatusRef.current?.value) {
          return;
        }

        const step = beneficiaryStatusRef.current.value;
        if (step === '1') {
          setActualStep(2);
          getBeneficiaryParents();
        } else if (step === '2') {
          setActualStep(3);
        } else {
          return;
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefStep1.current?.setErrors(errors);
        }
      }
    },
    [getBeneficiaryParents],
  );

  const handleSecondStepForm = useCallback(async () => {
    beneficiaryRef?.current?.setError('');
    if (!beneficiaryRef.current?.value) {
      beneficiaryRef.current?.setError('Selecione um beneficiário');
      return;
    }
    const firstStepContent = formRefStep1.current?.getData();
    const secondStepContent = formRefStep2.current?.getData();
    const beneficiary = beneficiaryRef.current?.value;
    const { contractId } = contract;
    const { webUsername } = user as UserCommon & EnterpriseUser;
    const foundItem: any = beneficiaryParentsDetails.find(
      (item: any) => item.physicalPersonCode === beneficiary,
    );
    if (!foundItem) return;

    const name = foundItem.physicalPersonName;
    delete foundItem.physicalPersonName;

    const formResult = {
      ...firstStepContent,
      ...secondStepContent,
      ...foundItem,
      contractId,
      webUsername,
      name,
      cpf: '',
      sex: '',
      addressTypePublicPlace: '',
      address: '',
      complement: '',
      addressNumber: '',
      neighborhood: '',
      zipcode: '',
      ibgeCityCode: '',
      brazilianState: '',
    };

    try {
      Notiflix.Block.circle('.step2-button-notiflix');
      const response = await api.post(
        '/company/send-user-data-to-include',
        formResult,
      );

      Modal2.Success({
        closable: false,
        subtitle: 'Perfeito!',
        text: response.data.message,
        children: (
          <Button
            modal
            onClick={() => {
              Modal2.Close();
              history.push('/listagem-usuarios');
            }}
          >
            Ok
          </Button>
        ),
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRefStep2.current?.setErrors(errors);
      } else if (err?.response?.data?.message) {
        Modal2.Failure({
          closable: false,
          subtitle: 'Ops!',
          text: err.response.data.message,
          children: (
            <Button
              modal
              onClick={() => {
                Modal2.Close();
              }}
            >
              Ok
            </Button>
          ),
        });
      }
    } finally {
      Notiflix.Block.remove('.step2-button-notiflix');
    }
  }, [beneficiaryParentsDetails, contract, history, user]);

  const handleThirdStepForm = useCallback(
    async (data) => {
      const firstStepContent = formRefStep1.current?.getData();
      const thirdStepContent = formRefStep3.current?.getData();
      const gender = genderRef.current?.value;
      const state = stateRef.current?.value;
      const city = cityRef.current?.value;
      const { contractId } = contract;
      const { webUsername } = user as UserCommon & EnterpriseUser;

      const formResult = {
        ...firstStepContent,
        ...thirdStepContent,
        webUsername,
        contractId,
        gender,
        state,
        city,
      };

      try {
        Notiflix.Block.circle('step3-button-notiflix');
        formRefStep3.current?.setErrors({});
        genderRef?.current?.setError('');
        stateRef?.current?.setError('');
        cityRef?.current?.setError('');
        if (!genderRef.current?.value) {
          genderRef.current?.setError('Selecione um gênero');
        }
        if (!stateRef.current?.value) {
          stateRef.current?.setError('Selecione um estado');
        }
        if (!cityRef.current?.value) {
          cityRef.current?.setError('Selecione uma cidade');
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Informe o Nome'),
          cpf: Yup.string().required('Informe o CPF'),
          cep: Yup.string().required('Informe a CEP'),
          district: Yup.string().required('Informe o bairro'),
          address: Yup.string().required('Informe o endereço da rua'),
          number: Yup.string().required('Informe número da rua'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        if (
          !genderRef.current?.value ||
          !stateRef.current?.value ||
          !cityRef.current?.value
        ) {
          return;
        }

        const response = await api.post(
          '/company/send-user-data-to-include',
          formResult,
        );

        Modal2.Success({
          closable: false,
          subtitle: 'Perfeito!',
          text: response?.data.message,
          children: (
            <Button
              modal
              onClick={() => {
                Modal2.Close();
                history.push('/listagem-usuarios');
              }}
            >
              Ok
            </Button>
          ),
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefStep3.current?.setErrors(errors);
        } else if (err?.response?.data.message) {
          Modal2.Failure({
            closable: false,
            subtitle: 'Ops!',
            text: err?.response?.data.message,
            children: (
              <Button
                modal
                onClick={() => {
                  Modal2.Close();
                }}
              >
                Ok
              </Button>
            ),
          });
        }
      } finally {
        Notiflix.Block.remove('step3-button-notiflix');
      }
    },
    [contract, history, user],
  );

  const getStates = useCallback(async () => {
    try {
      const { data } = await api.get('address/list-of-states');
      setStateOptions(
        data.content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      );
    } catch (error) {
      Notiflix.Notify.failure(error);
    }
  }, []);

  const handleBirthday = useCallback((value) => {
    setBirthday(value);
  }, []);

  useLayoutEffect(() => {
    calculateHeightOfSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* ZipCode Writting */
  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    const timer = window.setTimeout(() => {
      if (!zipCodeSearch) {
        source.cancel();
        return;
      }
      Notiflix.Block.circle('.notiflix-cep-search');
      api
        .get(`/address/search-address-by-zip-code?zipCode=${zipCodeSearch}`, {
          cancelToken: source.token,
        })
        .then((resp) => {
          const { data } = resp;
          const { content } = data;
          if (content && content[0]) {
            // rua
            formRefStep3.current?.setFieldValue(
              'address',
              content[0].streetName,
            );
            // bairro
            formRefStep3.current?.setFieldValue(
              'district',
              content[0].district,
            );

            stateRef.current?.setValue('', content[0].states);

            if (content[0].city) {
              api
                .get(`/address/list-of-cities?state=${content[0].states}`)
                .then((respCity) => {
                  if (respCity.data.content) {
                    setCityOptions(
                      respCity.data.content.reduce(
                        (
                          acc: { title: string; value: string }[],
                          act: { city: string; ibgeCityCode: string },
                        ) => {
                          acc.push({
                            title: act.city,
                            value: act.ibgeCityCode,
                          });
                          return acc;
                        },
                        [],
                      ),
                    );
                    cityRef.current?.setValue(content[0].city, '');
                  }
                });
            }
            // countyRef.current?.setValue(content[0].city, );
          }
        })
        .finally(() => {
          Notiflix.Block.circle('.notiflix-cep-search');
          Notiflix.Block.remove('.notiflix-cep-search');
        });
    }, 1000);

    return () => {
      source.cancel();
      window.clearTimeout(timer);
    };
  }, [zipCodeSearch]);

  useEffect(() => {
    getStates();

    window.addEventListener('resize', calculateHeightOfSteps);
    return () => {
      window.removeEventListener('resize', calculateHeightOfSteps);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper gridTemplateColumns="repeat(10,1fr)">
      <Container>
        <Title>Adicionar Novo Usuário</Title>
        <Steps defineHeight={defineHeightOfSteps}>
          <Step1 show={actualStep === 1} ref={step1Ref}>
            <ReportsContainer>
              <FormWrapper
                ref={formRefStep1}
                onSubmit={handleFirstStepForm}
                formLook
              >
                <Input
                  widthContainerDesktop="50%"
                  title="Usuário"
                  name="userName"
                  maxLength={15}
                  formLook
                />
                <Input
                  widthContainerDesktop="50%"
                  title="E-mail"
                  name="email"
                  formLook
                />
                <Input
                  widthContainerDesktop="50%"
                  title="Senha"
                  type="password"
                  name="password"
                  formLook
                />
                <Select
                  options={beneficiaryStatus}
                  widthContainerDesktop="50%"
                  title="Usuário Cadastrado?"
                  name="beneficiaryStatus"
                  ref={beneficiaryStatusRef}
                  formLook
                />
                <ButtonsWrapper>
                  <Button formLook secondary onClick={() => history.goBack()}>
                    Voltar
                  </Button>
                  <Button grayButton formLook type="submit">
                    Próximo
                  </Button>
                </ButtonsWrapper>
              </FormWrapper>
            </ReportsContainer>
          </Step1>
          <Step2 show={actualStep === 2} ref={step2Ref}>
            <Container>
              <FormWrapper
                formLook
                ref={formRefStep2}
                onSubmit={handleSecondStepForm}
              >
                <Select
                  title="Selecione o beneficiário"
                  name="beneficiaryParents"
                  options={beneficiaryParents}
                  ref={beneficiaryRef}
                  formLook
                />
                <ButtonsWrapper>
                  <Button formLook secondary onClick={() => setActualStep(1)}>
                    Voltar
                  </Button>
                  <Button
                    className="step2-button-notiflix"
                    formLook
                    grayButton
                    type="submit"
                  >
                    Adicionar
                  </Button>
                </ButtonsWrapper>
              </FormWrapper>
            </Container>
          </Step2>
          <Step3 show={actualStep === 3} ref={step3Ref}>
            <Container>
              <FormWrapper
                ref={formRefStep3}
                formLook
                onSubmit={handleThirdStepForm}
              >
                <Input
                  widthContainerDesktop="40%"
                  title="Nome:"
                  name="name"
                  formLook
                />
                <Input
                  title="CPF:"
                  name="cpf"
                  widthContainerDesktop="20%"
                  masks={['999.999.999-99']}
                  formLook
                />
                <Select
                  ref={genderRef}
                  options={genderOptions}
                  title="Gênero:"
                  name="gender"
                  widthContainerDesktop="20%"
                  formLook
                />
                <DatePicker
                  widthContainerDesktop="20%"
                  formLook
                  selected={birthday}
                  icon
                  onChange={handleBirthday}
                  name="birthday"
                  title="Data de Nascimento:"
                  maxDate={new Date()}
                />
                <Input
                  widthContainerDesktop="33.33%"
                  title="CEP:"
                  name="cep"
                  className="notiflix-cep-search"
                  masks={['99999-999']}
                  formLook
                  onInput={(e) =>
                    setZipCodeSearch(unMask(e.currentTarget.value))
                  }
                />
                <Select
                  ref={stateRef}
                  options={stateOptions}
                  widthContainerDesktop="33.33%"
                  title="Estado:"
                  name="state"
                  formLook
                  inputMode
                />
                <Select
                  ref={cityRef}
                  options={cityOptions}
                  widthContainerDesktop="33.33%"
                  title="Município:"
                  name="city"
                  formLook
                  inputMode
                />
                <Input
                  widthContainerDesktop="30%"
                  title="Bairro:"
                  name="district"
                  formLook
                />
                <Input
                  widthContainerDesktop="30%"
                  title="Rua:"
                  name="address"
                  formLook
                />
                <Input
                  widthContainerDesktop="10%"
                  title="Número:"
                  name="number"
                  formLook
                />
                <Input
                  widthContainerDesktop="30%"
                  title="Complemento:"
                  name="complement"
                  formLook
                />
                <ButtonsWrapper>
                  <Button formLook secondary onClick={() => setActualStep(1)}>
                    Voltar
                  </Button>
                  <Button
                    className="step3-button-notiflix"
                    grayButton
                    formLook
                    type="submit"
                  >
                    Adicionar
                  </Button>
                </ButtonsWrapper>
              </FormWrapper>
            </Container>
          </Step3>
        </Steps>
      </Container>
    </PageWrapper>
  );
};

export default AddUser;
