import React, { useState, useMemo, useEffect } from 'react';
import { DataTableProps } from 'primereact/datatable';

import {
  Column as ColumnPrime,
  ColumnProps as ColumnPropsPrime,
} from 'primereact/column';

// import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import {
  PaginatorProps,
  // PaginatorTemplate,
} from 'primereact/components/paginator/Paginator';
import { DataTable, NoContent, TableFooter } from './styles';

import Button from '../Button';

interface ColumnProps extends ColumnPropsPrime {
  field?: string;
  header?: string;
}

export const Column: React.FC<ColumnProps> = ({ field, header, ...rest }) => {
  return <ColumnPrime field={field} header={header} {...rest} />;
};

interface Props extends DataTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any;
  callbackShowMore?(rowsShowed: number): void;
  maxItems?: number;
  showFooterForever?: boolean;
}

export interface SVGContainerProps {
  onClick(event: React.SyntheticEvent): void;
  className: string;
  iconClassName: string;
  disabled: boolean;
  element: JSX.Element;
  props: PaginatorProps;
}

const Table: React.FC<Props> = ({
  items,
  children,
  callbackShowMore,
  maxItems,
  showFooterForever,
  ...rest
}) => {
  const [rows, setRows] = useState(10);
  const footer = useMemo(() => {
    return (
      <TableFooter>
        <span>
          {`Exibindo ${
            rows < (items?.length ?? 0) ? rows : items?.length ?? 0
          } de ${items?.length || 0} registros`}
        </span>
        {(items?.length > rows ||
          (maxItems ? rows < maxItems : false) ||
          showFooterForever) && (
          <Button
            outlined
            formLook
            autoWidth
            onClick={() => {
              if (callbackShowMore) {
                callbackShowMore(rows + 10);
              }
              setRows((prev) => prev + 10);
            }}
          >
            Ver mais
          </Button>
        )}
      </TableFooter>
    );
  }, [items, rows, maxItems, showFooterForever, callbackShowMore]);

  useEffect(() => {
    setRows((prev) => (prev > (items?.lenght ?? 0) ? 10 : prev));
  }, [items]);

  return (
    <DataTable
      value={items}
      className=""
      // paginator
      rows={rows}
      // paginatorTemplate={template2}
      // currentPageReportTemplate="Exibindo {first}-{last} de {totalRecords}"
      footer={footer}
      emptyMessage={<NoContent>Ops... Não encontramos nada...</NoContent>}
      {...rest}
    >
      {children}
    </DataTable>
  );
};

export default Table;
