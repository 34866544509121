import styled, { keyframes } from 'styled-components';

import BackgroundBeneficiarioImg from '../../assets/images/background-cadastro-beneficiario.jpg';
import Button from '../../components/Button';
import Input from '../../components/Input';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 8px;
`;

export const Background = styled.div`
  background: url(${BackgroundBeneficiarioImg}) no-repeat center,
    linear-gradient(to top, #00401a, #00401a);
  background-size: cover;
  background-blend-mode: multiply;
  position: relative;
  grid-column: 1/8;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 10%;
  > svg {
    margin-top: 24px;
  }
  > a {
    font-family: 'Unimed-Sans-Book';
    color: ${(props) => props.theme.colors.text.inverse};
    text-decoration: none;
    margin: 5% 0;
    font-size: 0.85rem;
    strong {
      font-family: 'Unimed-Sans-SemiBold';
    }
    svg {
      margin-left: 8px;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const BemVindo = styled.h1`
  font-size: 4rem;
  width: 100%;
  color: ${(props) => props.theme.colors.text.inverse};
  font-family: 'Unimed-Slab-Bold';
  @media (max-width: 1200px) {
    font-size: 3rem;
  }
`;

export const BemVindoMobile = styled.h1`
  display: none;
  @media (max-width: 768px) {
    display: block;
    width: 100%;
    color: ${(props) => props.theme.colors.text.inverse};
    font-family: 'Unimed-Slab-Bold';
    font-size: 2.1rem;
    text-align: left;
    margin-bottom: 24px;
  }
`;

const fadeToRight = keyframes`
  from{
    opacity: 0;
    transform: translateX(-10px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const ContentMobile = styled.div`
  > div {
    margin-top: 8px;
  }

  > div:last-child {
    margin-top: 24px;
    margin-left: 2px;
  }
  @media (max-width: 768px) {
    background: url(${BackgroundBeneficiarioImg}) no-repeat center,
      linear-gradient(to top, #00401a, #00401a);
    background-size: cover;
    background-blend-mode: multiply;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    position: relative;

    order: 1;
    row-gap: 8px;
    > div {
      margin-top: 0;
    }

    > div:last-child {
      margin-top: 24px;
      margin-left: -60px;
      width: calc(100% - 60px);
      > h3 {
        color: #fff;
      }
    }
  }
`;

export const Content = styled.div`
  grid-column: 9/12;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  animation: ${fadeToRight} forwards ease-in 300ms;

  @media (max-width: 768px) {
    grid-column: 1/13;
    height: 100%;
    flex-direction: column;
    animation: none;
  }

  form {
    width: 100%;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
`;

export const InputTerms = styled(Input)`
  font-size: 0.8rem;
  margin-top: 16px;
  @media (max-width: 768px) {
    order: 3;
    margin: 38px auto 16px;
    width: calc(100% - 60px);
  }
`;

export const Terms = styled.button`
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.text.primary};
  text-align: left;
  background: transparent;
  border: none;

  @media (max-width: 768px) {
    font-size: 0.65rem;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  @media (max-width: 768px) {
    order: 3;
    flex-direction: column;
    height: fit-content;
    margin-top: 15%;
  }
`;

export const ButtonGoBack = styled(Button)`
  @media (max-width: 768px) {
    margin: auto 30px 0;
    width: calc(100% - 60px);
    order: 3;
  }
`;

export const ButtonSubmit = styled(Button)`
  @media (max-width: 768px) {
    margin: 16px 30px auto;
    width: calc(100% - 60px);
    order: 3;
  }
`;

export const ModalTitle = styled.h1`
  font-family: 'Unimed-Slab-Bold';
  text-align: left;
  font-style: normal;
  font-size: 1.7rem;
  color: ${(props) => props.theme.colors.text.primary};
  @media (min-width: 769px) {
    margin-top: 11px;
    margin-left: 26px;
  }
`;

export const ModalContent = styled.p`
  font-family: 'Unimed-Sans-Book';
  color: ${(props) => props.theme.colors.text.primary};
  text-align: left;
  @media (min-width: 769px) {
    margin: 56px 26px;
    max-height: calc(100vh - 350px);
    overflow-y: auto;
  }
  @media (max-width: 768px) {
    max-height: calc(100vh - 440px);
    word-break: break-word;
    overflow-y: auto;
  }
`;

export const ModalButton = styled(Button)`
  margin-left: auto;
  display: block;
  margin-top: 22px;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  > button {
    width: auto;
  }
`;

export const ContainerLogoW = styled.div`
  margin: 5% 0;
  a {
    font-family: 'Unimed-Sans-Book';
    text-decoration: none;
    font-size: 0.85rem;
    color: ${(props) => props.theme.colors.text.inverse};

    svg {
      margin-left: 8px;
    }
    strong {
      font-family: 'Unimed-Sans-SemiBold';
    }
  }
  @media (max-width: 768px) {
    width: calc(100% - 60px);
    display: block;
    margin: 32px 0 16px;
  }
  &.mobile {
    display: none;
    @media (max-width: 768px) {
      display: flex;
      a {
        font-family: 'Unimed-Sans-Book';
        color: ${(props) => props.theme.colors.text.primary};
        display: flex;
        text-decoration: none;
        align-items: baseline;
        width: 100%;
        font-size: 0.75rem;
        strong {
          margin-left: 6px;
        }
        svg {
          margin-left: auto;
          path {
            fill: ${(props) => props.theme.colors.text.primary};
          }
        }
      }
    }
  }
`;
