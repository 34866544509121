import Notiflix from 'notiflix';
import React, { useCallback, useMemo, useRef } from 'react';

import Button from '../../../components/Button';
import Modal2 from '../../../components/Modal2';
import {
  ModalTitle,
  ModalContent,
  ModalContainer,
  ContainerCheck,
  ContentCheck,
  ButtonScroll,
} from './style';
import imgScroll from '../../../assets/images/scrollToBottom.png';
import api from '../../../services/api/api';

interface ParamsReceived {
  values: {
    terms: string;
    needDs?: boolean;
    beneficiaryId: string;
    name: string;
    telephone: string;
    healthDeclarationId: string;
    modelType: number;
    cpf: number;
    birthdate: string;
  };
}

const TermsDS: React.FC<ParamsReceived> = ({ values }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleRejectTerm = useCallback(() => {
    Modal2.Close();
    Modal2.Failure({
      closable: false,
      autoWidth: true,
      title: 'Recusado',
      text: 'Termo da Declaração de Saúde recusado!',
      children: (
        <Button
          modal
          onClick={() => {
            Modal2.Close();
            window.location.href = 'https://portal.unimedblumenau.com.br';
          }}
        >
          Finalizar
        </Button>
      ),
    });
  }, []);

  const handleAcceptTerm = useCallback(async () => {
    if (values.modelType === 2) {
      Modal2.Close();
      Notiflix.Loading.circle();
      Modal2.Success({
        closable: false,
        autoWidth: true,
        title: 'Perfeito!',
        text: 'Termo da Declaração de Saúde aceito!',
        children: (
          <Button
            modal
            onClick={() => {
              Modal2.Close();
              window.location.href = 'https://portal.unimedblumenau.com.br';
            }}
          >
            Continuar
          </Button>
        ),
      });
    } else {
      try {
        Notiflix.Loading.circle();
        const params = {
          beneficiaryId: values.beneficiaryId,
          beneficiaryName: values.name,
          telephone: values.telephone,
          birthDate: values.birthdate,
          cpf: values.cpf,
          acceptTerms: true,
        };

        const { data } = await api.post(
          `/health-declaration/send-health-declaration`,
          params,
        );

        if (data.message === 'success') {
          Modal2.Success({
            closable: false,
            autoWidth: true,
            title: 'Perfeito!',
            text: 'Termo da Declaração de Saúde aceito!',
            children: (
              <Button
                modal
                onClick={() => {
                  Modal2.Close();
                  window.location.href = 'https://portal.unimedblumenau.com.br';
                }}
              >
                Finalizar
              </Button>
            ),
          });
        }
      } catch (err) {
        if (err.response?.data?.message) {
          Notiflix.Notify.failure(err.response.data.message);
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos aceitar esse termo no momento. Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Loading.remove();
      }
    }
  }, [
    values.beneficiaryId,
    values.birthdate,
    values.cpf,
    values.modelType,
    values.name,
    values.telephone,
  ]);

  const ativar = useCallback(() => {
    const button = document.querySelector('#acceptTerms');
    button?.toggleAttribute('disabled');
  }, []);

  const scrollToBottom = useCallback(() => {
    inputRef.current?.scrollIntoView({ behavior: 'smooth' });
    const button = document.querySelector('#scrollToBottom');
    if (button) {
      button.classList.add('removeDisplay');
    }
  }, []);

  const termsShow = useMemo(async () => {
    try {
      Notiflix.Loading.circle();
      Modal2.Generic({
        closable: true,
        children: (
          <ModalContainer>
            <ModalTitle>Carta de Orientação ao Beneficiário</ModalTitle>
            <ModalContent>
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: values.terms }}
              />
              <br />
              <ContainerCheck>
                <ContentCheck>
                  <input
                    type="checkbox"
                    ref={inputRef}
                    name="concordo"
                    id="checkboxTerms"
                    onChange={() => ativar()}
                  />
                </ContentCheck>
                <span>Li e concordo</span>
              </ContainerCheck>
              <>
                <Button
                  modal
                  id="acceptTerms"
                  greenCriticalButton
                  float="right"
                  autoWidth
                  onClick={() => {
                    if (values.modelType === 0 || values.modelType === 1) {
                      window.location.href = '/declaracao-saude-formulario';
                    } else {
                      handleAcceptTerm();
                    }
                  }}
                >
                  Aceitar
                </Button>
                <Button
                  modal
                  float="right"
                  autoWidth
                  secondary
                  onClick={() => handleRejectTerm()}
                >
                  Recusar
                </Button>
              </>
            </ModalContent>
            <ButtonScroll
              type="button"
              onClick={scrollToBottom}
              id="scrollToBottom"
            >
              <img src={imgScroll} alt="" />
            </ButtonScroll>
          </ModalContainer>
        ),
      });
      setTimeout(() => {
        const btn = document.querySelector<HTMLButtonElement>('#acceptTerms');
        if (btn) {
          btn.disabled = true;
        }
      }, 200);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops, não conseguimos exibir esse termo no momento... Tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Loading.remove();
    }
  }, [
    ativar,
    handleAcceptTerm,
    handleRejectTerm,
    scrollToBottom,
    values.terms,
  ]);

  return <>{termsShow}</>;
};

export default TermsDS;
