/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as Yup from 'yup';
import { useTheme } from 'styled-components';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import Button from '../../../components/Button';
import FormWrapper from '../../../components/FormWrapper';
import Input from '../../../components/Input';
import api from '../../../services/api/api';
import getValidationErrors from '../../../utils/getValidationErrors';

import { QuestionsContainer, Label, Row, Col } from './styles';
import Checkbox from '../../../components/Checkbox';
import { getParams } from '../../../utils/Url';

interface ParamsReceived {
  callbackSubmit?: (data: any) => Promise<string | null>;
  contractId?: string;
  webUsername?: string;
  isExternal?: boolean;
  requestHolderId?: string;
  declarationId: number;
  isAdditional?: boolean;
}

interface PerguntaProp {
  questionId: string;
  questionDescription: string;
  itemNumber: string;
  inputType: string;
  options: [
    {
      optionValue: string;
      optionText: string;
    },
    {
      optionValue: string;
      optionText: string;
    },
  ];
  SubItens: [
    {
      questionId: string;
      questionDescription: string;
      itemNumber: string;
      inputType: string;
      higherNumber: number;
      options: [
        {
          optionValue: string;
          optionText: string;
        },
        {
          optionValue: string;
          optionText: string;
        },
      ];
    },
  ];
}

const HealthDeclarationForm: React.FC<ParamsReceived> = ({
  callbackSubmit,
  isExternal = false,
  isAdditional,
}) => {
  const { colors } = useTheme();
  const defaultColor = colors.palet.institutional8;
  const formRef = useRef<FormHandles>(null);
  const [questions, setQuestions] = useState<PerguntaProp[]>([]);
  const [lastQuestion, setLastQuestion] = useState(false);
  const [nextStep, setNextStep] = useState(0);

  const getQuestions = useCallback(async () => {
    try {
      const token = localStorage.getItem('@unimedBlumenauPrimaryToken');

      const { data } = await api.get('/health-declaration/list-questions', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { content } = data;
      setQuestions(content);
    } catch (error) {
      Notiflix.Notify.failure(
        'Ops.. Não conseguimos buscar os termos no momento. Tente novamente mais tarde.',
      );
    }
  }, []);
  const handleSubmit = useCallback(async () => {
    try {
      formRef.current?.setErrors({});
      const data = formRef.current?.getData();

      if (callbackSubmit) {
        const resp = await callbackSubmit(data);

        return resp;
      }
      return null;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        const scrollToFirstError = formRef.current?.getFieldRef(
          Object.keys(errors)[0],
        );
        if (scrollToFirstError) {
          const el: HTMLElement = scrollToFirstError.current;
          if (el) {
            smoothScrollIntoView(el, { behavior: 'smooth' });
          }
        }
      }
      return null;
    }
  }, [callbackSubmit]);

  useEffect(() => {
    getQuestions();
    // Terms();
    const buttonPrev = document.querySelector('.notiflix-question-back');
    if (buttonPrev) {
      buttonPrev.setAttribute('disabled', 'true');
    }
  }, [getQuestions]);

  const handleNextStep = useCallback(
    (type) => {
      if (type === 'next') {
        window.scrollTo(0, 0);
        const buttonNext = document.querySelector('.notiflix-question-next');
        const buttonPrev = document.querySelector('.notiflix-question-back');

        if (questions?.length > nextStep + 1) {
          setNextStep(nextStep + 1);
          if (buttonPrev) {
            buttonPrev.removeAttribute('disabled');
          }
          if (buttonNext) {
            buttonNext?.removeAttribute('disabled');
          }
        }
        if (questions?.length - 1 === nextStep + 1) {
          setLastQuestion(true);
          if (buttonNext) {
            buttonNext.setAttribute('disabled', 'true');
          }
        }
      }

      if (type === 'prev') {
        const buttonPrev = document.querySelector('.notiflix-question-back');
        const buttonNext = document.querySelector('.notiflix-question-next');
        if (nextStep !== 0) {
          setNextStep(nextStep - 1);
          if (buttonNext) {
            buttonNext.removeAttribute('disabled');
          }
        }
        if (nextStep === 1) {
          if (buttonPrev) {
            buttonPrev.setAttribute('disabled', 'true');
          }
        }
      }
    },
    [nextStep, questions],
  );

  const validateStepForm = useCallback(() => {
    if (questions[nextStep].questionId) {
      let allRight = true;

      formRef.current?.setErrors({});
      const inputQuestion = document.querySelector<HTMLInputElement>(
        `[name=inputTextQuestion-${questions[nextStep].questionId}]`,
      );
      const inputQuestionSubItem = document.querySelector<HTMLInputElement>(
        `#subItemPergunta-Altura`,
      );
      const inputQuestionS = formRef.current?.getFieldValue(
        `optionQuestion-${questions[nextStep].questionId}-S`,
      );
      const inputQuestionN = formRef.current?.getFieldValue(
        `optionQuestion-${questions[nextStep].questionId}-N`,
      );
      const questionS = document.querySelector<HTMLInputElement>(
        `#optionQuestion-${questions[nextStep].questionId}-S`,
      );
      const questionN = document.querySelector<HTMLInputElement>(
        `#optionQuestion-${questions[nextStep].questionId}-N`,
      );
      const questionA = document.querySelector<HTMLInputElement>(
        `#optionQuestion-${questions[nextStep].questionId}-A`,
      );
      const questionB = document.querySelector<HTMLInputElement>(
        `#optionQuestion-${questions[nextStep].questionId}-B`,
      );
      const questionC = document.querySelector<HTMLInputElement>(
        `#optionQuestion-${questions[nextStep].questionId}-C`,
      );
      let inputSubitemObrigatoria;
      if (questions[nextStep].SubItens[0]) {
        if (questions[nextStep].SubItens[0].questionId) {
          inputSubitemObrigatoria = document.querySelector<HTMLInputElement>(
            `[name=subItemPergunta-${questions[nextStep].SubItens[0].questionId}-obrigatoria]`,
          );
        }
      }

      if (inputQuestion?.value === '') {
        formRef.current?.setFieldError(
          inputQuestion?.name,
          '*É necessário preencher este campo',
        );
        allRight = false;
      }

      if (inputQuestionSubItem?.value === '') {
        formRef.current?.setFieldError(
          inputQuestionSubItem?.name,
          '*É necessário preencher este campo',
        );
        allRight = false;
      }

      if (questionN || questionS) {
        if (!inputQuestionN && !inputQuestionS) {
          if (questionS) {
            formRef.current?.setFieldError(
              questionS?.name,
              '*É necessário selecionar um dos campos',
            );
            window.scrollTo(0, 0);
          }
          allRight = false;
        }
      }

      if (inputQuestionS === true && inputQuestionN === false) {
        let isAnyMarked = false;
        const questionsYes = [];
        const inputSubQuestions = document.querySelectorAll<HTMLInputElement>(
          `[id^='optionQuestionSubitem-${questions[nextStep].questionId}-']`,
        );

        const arrSubQuestions = Array.from(inputSubQuestions);
        for (let i = 0; i < arrSubQuestions.length; i += 1) {
          questionsYes.push(i);
          if (arrSubQuestions[i].type !== 'checkbox') {
            i -= 1;
            return;
          }
          const optS = arrSubQuestions[i].checked;

          if (optS) {
            isAnyMarked = true;
          }
        }

        if (inputSubitemObrigatoria?.value === '') {
          formRef.current?.setFieldError(
            inputSubitemObrigatoria?.name,
            '*Descreva sua doença/diagnóstico',
          );
          allRight = false;
        }

        if (!isAnyMarked && !inputSubitemObrigatoria?.value) {
          window.scrollTo(0, 0);
          allRight = false;

          questionsYes.forEach((element) => {
            formRef.current?.setFieldError(
              arrSubQuestions[element].name,
              '*É necessário marcar SIM em um dos campos',
            );
          });
        }
      }
      // console.log('igual1? ', questions[nextStep].questionId);
      // console.log('igual2? ', Number(questionA?.name.split('-')[1]));
      // console.log(
      //   'igual3? ',
      //   Number(questions[nextStep].questionId) ===
      //     Number(questionA?.name.split('-')[1]),
      // );

      if (
        Number(questions[nextStep].questionId) ===
        Number(questionA?.name.split('-')[1])
      ) {
        if (!questionA?.checked && !questionB?.checked && !questionC?.checked) {
          if (questionA) {
            formRef.current?.setFieldError(
              questionA?.name,
              '*É necessário selecionar um dos campos',
            );
          }
          allRight = false;
        }
      }
      if (allRight) {
        handleNextStep('next');
      }
    }
  }, [handleNextStep, nextStep, questions]);

  const unCheck = useCallback((questionIdFather: string) => {
    const inputSubQuestions = document.querySelectorAll<HTMLInputElement>(
      `[id^='optionQuestionSubitem-${questionIdFather}-']`,
    );
    inputSubQuestions?.forEach((checkbox) => {
      const temp = checkbox;
      temp.checked = false;
    });
  }, []);

  return (
    <QuestionsContainer>
      <FormWrapper
        onSubmit={() => {
          //
        }}
        formLook
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        ref={formRef}
        className="full-form-beneficiary"
      >
        {!!questions.length && (
          <>
            {questions.map((item, index) => {
              return (
                <>
                  <Label
                    key={`question-${item.questionId}`}
                    hidden={index !== nextStep}
                  >
                    {`${index + 1} - ${item.questionDescription}`}
                  </Label>

                  {item.inputType === 'Edit' && (
                    <Input
                      key={`pergunta-${item.questionId}`}
                      type={
                        item.questionDescription === 'Peso' ? 'number' : 'text'
                      }
                      name={`inputTextQuestion-${item.questionId}`}
                      hidden={index !== nextStep}
                      autoFocus={false}
                    />
                  )}

                  {item.inputType === 'LookupComboBox' && item.options && (
                    <>
                      {item?.options?.map((opcao) => (
                        <>
                          <Checkbox
                            key={`optionQuestion${item.questionId}-${opcao.optionValue}`}
                            name={`optionQuestion-${item.questionId}-${opcao.optionValue}`}
                            value={opcao.optionValue}
                            title={opcao.optionText}
                            className={index !== nextStep ? 'displayNone' : ''}
                            themeColor={defaultColor}
                            onClick={(e) => {
                              const self = e.currentTarget;

                              // disable B and C in question 25
                              if (!isAdditional) {
                                if (opcao.optionValue === 'B') {
                                  const checkboxToDisable =
                                    document.querySelector<HTMLInputElement>(
                                      `input#optionQuestion-${item.questionId}-B`,
                                    );
                                  if (checkboxToDisable) {
                                    checkboxToDisable.disabled = true;
                                    checkboxToDisable.checked = false;
                                  }
                                }
                                if (opcao.optionValue === 'C') {
                                  const checkboxToDisable =
                                    document.querySelector<HTMLInputElement>(
                                      `input#optionQuestion-${item.questionId}-C`,
                                    );
                                  if (checkboxToDisable) {
                                    checkboxToDisable.disabled = true;
                                    checkboxToDisable.checked = false;
                                  }
                                }
                              }

                              // case A B C selected one option => Question 25
                              if (isAdditional) {
                                const optionsToUncheck = item.options.filter(
                                  (option) =>
                                    option.optionValue !== opcao.optionValue,
                                );
                                optionsToUncheck.forEach((option) => {
                                  const checkboxToUncheck =
                                    document.querySelector<HTMLInputElement>(
                                      `input[name="optionQuestion-${item.questionId}-${option.optionValue}"]`,
                                    );
                                  if (checkboxToUncheck) {
                                    checkboxToUncheck.checked = false;
                                  }
                                });
                              }

                              if (
                                self ===
                                document.querySelector<HTMLInputElement>(
                                  `input#optionQuestion-${item.questionId}-A`,
                                )
                              ) {
                                setTimeout(() => {
                                  handleNextStep('next');
                                }, 300);
                              }

                              const optionSibling =
                                document.querySelector<HTMLInputElement>(
                                  `input#optionQuestion-${item.questionId}-${
                                    opcao.optionValue === 'S' ? 'N' : 'S'
                                  }`,
                                );
                              if (optionSibling) {
                                optionSibling.checked = false;
                              }

                              if (
                                self ===
                                document.querySelector<HTMLInputElement>(
                                  `input#optionQuestion-${item.questionId}-N`,
                                )
                              ) {
                                setTimeout(() => {
                                  handleNextStep('next');
                                }, 300);
                              }

                              const rowSiblings = document.querySelector(
                                `div[data-children="${index + 1}"]`,
                              );
                              if (opcao.optionValue === 'S') {
                                if (self.checked) {
                                  rowSiblings?.setAttribute(
                                    'data-show',
                                    'true',
                                  );
                                } else {
                                  rowSiblings?.setAttribute(
                                    'data-show',
                                    'false',
                                  );
                                  unCheck(item.questionId);
                                }
                              } else {
                                rowSiblings?.setAttribute('data-show', 'false');
                                unCheck(item.questionId);
                              }
                            }}
                          />
                        </>
                      ))}
                    </>
                  )}
                  <Col
                    data-actual-step={nextStep === index}
                    data-children={index + 1}
                    data-show={item.inputType !== 'LookupComboBox'}
                  >
                    {nextStep > 0 && item.SubItens.length > 1 && (
                      <Label>Selecione uma ou mais das opções abaixo:</Label>
                    )}
                    {item.SubItens.map((subItem, i) => {
                      return (
                        <>
                          <Row>
                            {subItem?.inputType === 'CheckBox' &&
                              subItem.options && (
                                <Col>
                                  {subItem.options.map((opcao) => {
                                    return (
                                      <>
                                        {opcao.optionValue === 'S' && (
                                          <Checkbox
                                            key={`optionQuestionSubitem-${item.questionId}-${opcao.optionValue}`}
                                            name={`optionQuestionSubitem-${item.questionId}-${subItem.questionId}-${opcao.optionValue}`}
                                            value={opcao.optionValue}
                                            title={subItem.questionDescription}
                                            className={
                                              index !== nextStep
                                                ? 'displayNone'
                                                : ''
                                            }
                                            onClick={() => {
                                              const optionSibling =
                                                document.querySelector<HTMLInputElement>(
                                                  `input#optionQuestionSubitem-${
                                                    item.questionId
                                                  }-${subItem.questionId}-${
                                                    opcao.optionValue === 'S'
                                                      ? 'N'
                                                      : 'S'
                                                  }`,
                                                );
                                              if (optionSibling) {
                                                optionSibling.checked = false;
                                              }
                                            }}
                                            themeColor={defaultColor}
                                          />
                                        )}
                                      </>
                                    );
                                  })}
                                </Col>
                              )}
                          </Row>
                          {subItem.inputType === 'Edit' && (
                            <>
                              <Label
                                key={subItem.questionId}
                                hidden={index !== nextStep}
                              >
                                {subItem.questionDescription}
                              </Label>
                              <Input
                                key={subItem.itemNumber}
                                type={
                                  subItem.questionDescription === 'Altura'
                                    ? 'number'
                                    : 'text'
                                }
                                name={
                                  item.SubItens.length <= 1 &&
                                  subItem.inputType === 'Edit'
                                    ? `subItemPergunta-${subItem.questionId}-obrigatoria`
                                    : `subItemPergunta-${subItem.questionId}`
                                }
                                id={
                                  subItem.questionDescription === 'Altura'
                                    ? `subItemPergunta-${subItem.questionDescription}`
                                    : ''
                                }
                                hidden={index !== nextStep}
                                autoFocus={false}
                              />
                            </>
                          )}
                        </>
                      );
                    })}
                  </Col>
                </>
              );
            })}
          </>
        )}
      </FormWrapper>

      <FormWrapper
        style={
          isExternal
            ? {
                // height: '82px',
                marginTop: '24px',
                display: 'flex',
                flexWrap: window.screen.width > 768 ? 'nowrap' : 'wrap',
                justifyContent:
                  window.screen.width > 768 ? 'flex-end' : 'center',
                padding: '20px',
              }
            : {}
        }
        onSubmit={() => {
          //
        }}
        formLook
        className="actions-include-beneficiary"
      >
        <Button
          autoWidth
          formLook
          greenPrimary
          style={{
            width: window.screen.width < 768 ? '100%' : '',
          }}
          float={isExternal ? 'right' : 'left'}
          className="notiflix-question-back"
          onClick={() => handleNextStep('prev')}
        >
          Anterior
        </Button>
        <Button
          autoWidth
          formLook
          greenPrimary
          style={{
            width: window.screen.width < 768 ? '100%' : '',
          }}
          float={isExternal ? 'right' : 'left'}
          className={
            window.screen.width > 768
              ? 'notiflix-question-next marginLeft'
              : 'notiflix-question-next'
          }
          onClick={() => {
            validateStepForm();
          }}
        >
          Próximo
        </Button>
        {lastQuestion && (
          <Button
            onClick={() => {
              handleSubmit();
            }}
            autoWidth
            formLook
            greenPrimary
            style={{
              width: window.screen.width < 768 ? '100%' : '',
            }}
            float={isExternal ? 'right' : 'left'}
            className={
              window.screen.width > 768
                ? 'notiflix-save-beneficiary marginLeft'
                : 'notiflix-save-beneficiary'
            }
          >
            Salvar
          </Button>
        )}
      </FormWrapper>
    </QuestionsContainer>
  );
};

export default HealthDeclarationForm;
