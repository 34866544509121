import styled, { css } from 'styled-components';
import Switch from 'react-switch';
import { FiMenu } from 'react-icons/fi';
import { shade } from 'polished';
import { Link } from 'react-router-dom';
import { SunSvg, MoonSvg, HamburgerSvg, RefreshSvg } from '../Svg';

export const SunIcon = styled(SunSvg)`
  ${(props) =>
    props.theme.title === 'dark' &&
    css`
      path {
        stroke: ${props.theme.colors.text.grayishToWhite};
      }
    `}
`;

export const MoonIcon = styled(MoonSvg)`
  ${(props) =>
    props.theme.title === 'dark' &&
    css`
      path {
        fill: ${props.theme.colors.text.grayishToWhite};
      }
    `}
`;

export const HamburgerIcon = styled(HamburgerSvg)``;
export const Hamburger = styled(FiMenu)``;
export const RefreshIcon = styled(RefreshSvg)``;

export const Container = styled.div`
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  width: 100%;
  background: ${(props) => props.theme.colors.text.whiteToGrayish};

  @media (min-width: 769px) {
    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 100%;
      left: -20px;
      top: 0;
      background: ${(props) => props.theme.colors.text.whiteToGrayish};
    }
  }
  @media (max-width: 768px) {
    height: 50px;
  }
`;

export const HamburgerButton = styled.button`
  display: none;
  @media (max-width: 768px) {
    display: block;
    border: none;
    background: none;

    > svg {
      margin-left: 15px;
      cursor: pointer;
      path {
        stroke: ${(props) => props.theme.colors.text.grayishToWhite};
      }
    }
  }
`;

export const AccessibilityWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: auto;
`;

export const AcessibilityTitle = styled.h1`
  cursor: pointer;
  font-size: 0.7rem;
  font-family: 'Unimed-Sans-SemiBold';
  color: ${(props) => props.theme.colors.text.grayishToWhite};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  svg {
    display: none;
  }
  @media (max-width: 768px) {
    margin-left: 15px;
    font-size: 0.6rem;
    svg {
      display: block;
      transition: 300ms;
      &.open {
        transform: rotate(180deg);
      }
    }
  }
`;

interface AcessibilityDropdown {
  acessibilityCollapse: boolean;
}

export const AcessibilityContent = styled.div<AcessibilityDropdown>`
  display: flex;
  align-items: center;
  z-index: 1;
  transition: 300ms;

  @media (max-width: 768px) {
    position: absolute;
    top: 100%;
    left: 0;
    visibility: hidden;
    opacity: 0;

    align-items: flex-start;
    padding: 24px 26px;
    border-radius: 0 0px 10px 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    background: ${(props) => props.theme.colors.background};
    ${(props) =>
      props.acessibilityCollapse &&
      css`
        visibility: visible;
        opacity: 1;
      `}
  }
`;

export const SwitcherTheme = styled.div`
  position: relative;
  margin: 0 10px;
  color: ${(props) => props.theme.colors.text.normal};
  display: flex;

  button {
    border: none;
    background: none;
    span {
      display: none;
      color: ${(props) => props.theme.colors.text.normal};
    }
  }

  svg {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    margin: 0;
    flex-direction: column;
    svg {
      width: 20px;
    }
    button {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      font-weight: bold;

      span {
        display: initial;
        align-items: center;
        font-weight: 700;
        color: ${(props) => props.theme.colors.text.normal};
        margin-left: 20px;
        font-size: 1.2rem;
      }
    }
  }
  @media (min-width: 769px) {
    align-items: center;
  }
`;

export const SwitchComponent = styled(Switch)`
  margin: 0 5px;

  @media (max-width: 768px) {
    margin: 0 0px;
    visibility: hidden;
    display: none;
    width: 0;
    height: 0;
  }
`;

export const FontAjust = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.colors.text.grayishToWhite};
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 0.8rem;

  button {
    border: none;
    background: none;
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.text.grayishToWhite};
    padding: 5px;
    display: flex;
    align-items: center;
    span,
    > div {
      display: none;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    > h2 {
      display: none;
    }

    button {
      color: ${(props) => props.theme.colors.text.normal};
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      margin-bottom: 36px;
      width: 100%;
      padding: 0;

      > svg {
        display: none;
      }
      span,
      > div {
        display: initial;
        font-size: 1.2rem;
        font-weight: 600;
      }
      > div {
        width: 20px;
        color: ${(props) => props.theme.colors.text.grayishToWhite};
      }
      span {
        margin-left: 20px;
      }
    }
  }
`;

export const ButtonReset = styled.button`
  display: none !important;

  @media (max-width: 768px) {
    display: flex !important;
    margin-bottom: 0 !important;
    svg {
      display: initial !important;
      width: 20px;
    }
  }
`;

export const UserInfoContent = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
  align-items: center;
  position: relative;
  margin-left: 24px;
  cursor: pointer;

  svg {
    cursor: pointer;
    stroke: ${(props) => props.theme.colors.text.grayishToWhite};
    margin-right: 16px;
    transition: 300ms;
    &.open {
      transform: rotate(180deg);
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-end;
    margin-left: auto;
    svg {
      display: none;
    }
  }
  &.company {
    border: 1px solid #eaedef;
    border-radius: 5px;
    padding: 6px 8px;
    margin: 0 24px 0 5%;
    width: 266px;
    justify-content: space-between;
    > svg {
      margin-right: 0px;
      margin-left: 4px;
      display: initial;
    }
    @media (max-width: 768px) {
      border: none;
      justify-content: flex-end;
      margin: 0 0 0 auto;
      width: auto;
    }
  }
`;

export const AvatarImage = styled.div`
  position: relative;
  padding-right: 15px;
  img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    @media (max-width: 768px) {
      width: 36px;
      height: 36px;
    }
  }
`;

export const Bullet = styled.div`
  .company {
    max-width: 13px;
    width: 13px;
    height: 13px;
    max-height: 13px;
    p {
      font-size: 6px;
    }
  }
  position: absolute;
  top: 0;
  right: 10px;
  max-width: 21px;
  width: 21px;
  height: 21px;
  max-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 2px; */
  p {
    font-size: 10px;
    font-weight: 900;
    text-align: center;
    color: white;
    font-family: 'Unimed-Sans-SemiBold';
  }

  background: ${(props) => props.theme.colors.palet.institutional7};
  border-radius: 50%;
  &.company {
    position: initial;
    /* display: inline-block; */
    margin-left: 10px;
  }
`;

export const ProtocolInfo = styled.div`
  margin-left: auto;
  p {
    font-size: 0.8rem;
    font-family: 'Unimed-Sans-Book';
    color: ${(props) => (props.theme.title === 'light' ? '#252733' : ' #fff')};
    strong {
      font-family: 'Unimed-Sans-SemiBold';
    }
  }

  @media (max-width: 768px) {
    position: absolute;
    margin: 0;
    top: 100%;
    right: 0;
    width: 100%;

    display: flex;
    justify-content: space-between;
    padding: 6px 14px;
    background-color: ${(props) => props.theme.colors.palet.institutional13};
    color: ${(props) => props.theme.colors.text.normal};

    p {
      font-size: 0.65rem;
      word-break: keep-all;
      display: inline-block;
      color: ${(props) => props.theme.colors.text.primary};
    }
  }
  @media (max-width: 320px) {
    overflow-x: auto;
    p {
      white-space: nowrap;
      font-size: 0.55rem;
    }
  }
`;
export const WelcomeUserText = styled.p`
  font-size: 1rem;
  color: ${(props) => props.theme.colors.text.grayishToLightGray};
  display: flex;
  align-items: center;

  font-family: 'Unimed-Sans-Book';
  strong {
    font-family: 'Unimed-Sans-SemiBold';
    margin-left: 4px;
  }

  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
  &.welcome-company {
    @media (max-width: 768px) {
      font-family: 'Unimed-Sans-SemiBold';
      text-transform: uppercase;
      display: flex;
      font-size: 0.6rem;
      strong {
        margin-left: 2px;
      }
    }
  }
`;
interface UserDropDownProps {
  userCollapse: boolean;
}
export const UserDropDown = styled.div<UserDropDownProps>`
  z-index: 3;
  position: absolute;
  padding: 30px;
  top: 100%;
  right: 0;
  text-align: end;
  width: 290px;
  background-color: white;
  border-radius: 0 0 10px 10px;
  transition: 300ms;
  visibility: hidden;
  opacity: 0;
  background-color: ${(props) => props.theme.colors.text.whiteToGrayish};
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

  ${(props) =>
    props.userCollapse &&
    css`
      visibility: visible;
      opacity: 1;
    `}
  > div {
    & + div {
      margin-top: 16px;
    }
    > h3 {
      font-family: 'Unimed-Sans-SemiBold';
      font-size: 1.3rem;
      color: ${(props) => props.theme.colors.text.grayishToLightGray};
    }

    small {
      font-family: 'Unimed-Sans-book';
      color: ${(props) => props.theme.colors.text.grayishToLightGray};
    }
  }
`;

export const Communications = styled(Link)`
  border-top: 1px gray solid;
  border-bottom: 1px gray solid;
  padding: 16px 0;
  margin-top: 16px;
  cursor: pointer;
  text-decoration: none;
  display: block;

  h3 {
    font-family: 'Unimed-Sans-SemiBold';
    color: ${(props) => props.theme.colors.palet.institutional7};
    transition: 300ms;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 21px;
      max-width: 21px;
      max-height: 21px;
      height: 21px;
      transition: 300ms;
      background-color: ${(props) => props.theme.colors.palet.institutional7};
      color: white;
      border-radius: 50%;
      margin-right: 8px;
      p {
        font-family: 'Unimed-Sans-Book';
        font-size: 10px;
      }
    }
  }
  &:hover {
    h3 {
      color: ${(props) => shade(0.2, props.theme.colors.palet.institutional7)};
    }
    span {
      background-color: ${(props) =>
        shade(0.2, props.theme.colors.palet.institutional7)};
    }
  }
`;

export const ExitButtonUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  margin-top: 16px;
  margin-bottom: 0px;

  svg {
    margin-left: 8px;
    margin-top: 4px;
    color: ${(props) => props.theme.colors.text.normal};
  }

  &:hover {
    color: ${(props) => shade(0.3, props.theme.colors.text.normal)};

    svg {
      color: ${(props) => shade(0.3, props.theme.colors.text.normal)};
    }
  }
`;

export const LogoWrapper = styled(Link)`
  @media (min-width: 769px) {
    display: none;
  }
  width: 72px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 60px;
  svg {
    height: 100%;
    width: 100%;
  }
  @media (max-width: 320px) {
    width: 50px;
  }
`;
