/* eslint-disable max-len */
import React from 'react';
import { svgProps } from '..';

const MoonSvg: React.FC<svgProps> = ({ className }) => {
  return (
    <svg
      width="14"
      height="20"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15 8.62111C14.8689 10.1063 14.3352 11.5217 13.4617 12.7024C12.589 13.8822 11.4115 14.7784 10.0695 15.2854C8.72665 15.7915 7.27354 15.8883 5.88058 15.5637C4.48678 15.2391 3.21075 14.507 2.2011 13.452C1.19145 12.397 0.490785 11.0636 0.180124 9.60718C-0.130538 8.15164 -0.0378402 6.63328 0.446524 5.2301C0.931723 3.82779 1.78937 2.59738 2.91844 1.68548C4.04835 0.772717 5.4029 0.215127 6.82426 0.078125C5.99165 1.25442 5.59165 2.70388 5.69604 4.16291C5.79959 5.62194 6.40171 6.9937 7.39131 8.02864C8.38175 9.0627 9.69454 9.69183 11.0908 9.80004C12.4872 9.90912 13.8743 9.49112 15 8.62111Z"
        fill="#5B5C65"
      />
    </svg>
  );
};

export default MoonSvg;
