import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Notiflix from 'notiflix';
import produce from 'immer';
import axios from 'axios';
import PageWrapper from '../../components/PageWrapper';

import { Container, ActionsContainer } from './styles';
import Form from './Form';
import { useContract } from '../../hooks/contract';
import api from '../../services/api/api';
import Modal2 from '../../components/Modal2';
import Button from '../../components/Button';
import Title from '../../components/Title';
import Table, { Column } from '../../components/Table';
import AddField, {
  getTag,
  setPositionOfRowExpanded,
} from '../../utils/tableUtils';
import { useTheme } from '../../hooks/theme';
import FormWrapper from '../../components/FormWrapper';
import { RowProps } from '../Beneficiaries';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';

interface TableRow {
  allowEdit: 'S' | 'N';
  beneficiaryName: string;
  disapproved: 'S' | 'N';
  holderName: null | string;
  observation: null | string;
  reasonForDisapproval: null | string;
  requestId: number;
  solicitationDate: string;
  status: string;
}

const BeneficiaryInclude: React.FC = () => {
  const { contract } = useContract();
  const history = useHistory();
  const { theme } = useTheme();
  const [requestId, setRequestIdForEdit] = useState('');

  const [showForm, setShowForm] = useState({
    contractId: '',
    editMode: false,
    newMode: false,
    isOwner: true,
  });
  const [showDependentIncludeButton, setShowDependentIncludeButton] =
    useState(false);

  const [dependentParams, setDependentParams] = useState({} as RowProps);

  const [dataTable, setDataTable] = useState([] as TableRow[]);

  const getDataToTable = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');
      const { data } = await api.get(
        `beneficiary-inclusion/accessing-add-beneficiary-function?contractId=${contract.contractId}`,
      );

      const { content } = data;

      setDataTable(content);

      if (content.length === 0) {
        setShowForm({
          contractId: contract.contractId,
          editMode: false,
          newMode: true,
          isOwner: true,
        });
      }
    } catch (error) {
      setShowForm({
        contractId: contract.contractId,
        editMode: false,
        newMode: true,
        isOwner: false,
      });
      if (axios.isAxiosError(error) && error?.response?.status === 400) {
        setDataTable([]);
      } else {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dados do servidor... Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, [contract.contractId]);

  useEffect(() => {
    if (contract.contractId) {
      getDataToTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  const handleOpenReport = useCallback(async (data: TableRow) => {
    try {
      Notiflix.Block.circle(`.notiflix-report-${data.requestId}`);
      const { data: dataApi } = await api.get(
        '/report/list-of-company-portal-reports?exhibitionLocation=2',
      );
      const { content } = dataApi;
      const { nrSeqRelatorio, Parametros: P } = content[0];

      const { data: dataReport } = await api.post(
        `/report/generate-report`,
        {
          nrSeqRelatorio: nrSeqRelatorio ?? '',
          Parametros: [
            {
              id: P[0].cdParametro,
              valor: data.requestId,
            },
          ],
        },
        {
          responseType: 'blob',
        },
      );
      downloadFileOfBlob(dataReport);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.data?.message) {
        Notiflix.Notify.failure(err.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar o relatório no servidor. Por favor, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove(`.notiflix-report-${data.requestId}`);
    }
  }, []);

  const handleOpenDetailAndEdit = useCallback(
    (data) => {
      setRequestIdForEdit(data.requestId);
      setDependentParams((prev) => ({
        ...prev,
        contractId: contract.contractId,
        beneficiaryName: data.holderName,
        requestId: data.requestId,
      }));
      setShowForm({
        contractId: contract.contractId,
        editMode: true,
        newMode: false,
        isOwner: data.inclusionType === 1 || data.holderName === null,
      });
    },
    [contract],
  );

  const handleRelease = useCallback(
    async (row: TableRow) => {
      try {
        Notiflix.Block.circle(`.notiflix-release-${row.requestId}`);
        const { data } = await api.put(`company/release-dependent-registered`, {
          requestId: row.requestId,
          origin: 'Inclusao',
        });
        const { message } = data;
        Modal2.Success({
          closable: true,
          autoWidth: true,
          title: 'Perfeito!',
          text: message,
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });

        getDataToTable();
        setDataTable((prev) =>
          produce(prev, (draft) => {
            const found = draft.find((i) => i.requestId === row.requestId);
            if (found) {
              found.status = 'Aguardando análise Operadora';
            }
          }),
        );
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos enviar sua solicitação para o servidor. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove(`.notiflix-release-${row.requestId}`);
      }
    },
    [getDataToTable],
  );

  const handleUndoRelease = useCallback(
    async (row: TableRow) => {
      try {
        Notiflix.Block.circle(`.notiflix-release-${row.requestId}`);
        const resp = localStorage.getItem('@unimedBlumenau');
        if (resp) {
          const userData = JSON.parse(resp);
          const { webUsername } = userData;

          const { data } = await api.put(
            `beneficiary-inclusion/undo-release-solicitation-request`,
            {
              requestId: row.requestId,
              origin: 'Inclusao',
              webUsername,
            },
          );

          const { message } = data;
          Modal2.Success({
            closable: true,
            autoWidth: true,
            title: 'Perfeito!',
            text: message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });

          getDataToTable();
          setDataTable((prev) =>
            produce(prev, (draft) => {
              const found = draft.find((i) => i.requestId === row.requestId);
              if (found) {
                found.status = 'Aguardando liberação';
              }
            }),
          );
        }
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos enviar sua solicitação para o servidor. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove(`.notiflix-release-${row.requestId}`);
      }
    },
    [getDataToTable],
  );

  const handleOpenInconsistency = useCallback(async (row) => {
    try {
      Notiflix.Block.circle('notiflix-button');
      const { data } = await api.get(
        `/company/get-inconsistency-inclusion?requestNumber=${row}`,
      );

      const { data: realData } = data;

      if (!realData) {
        return;
      }

      const text = realData.map((e: any) => {
        return (
          <p key={e.inconsistencyDescription.slice(0, 10)}>
            {e.inconsistencyDescription}
          </p>
        );
      });

      Modal2.Generic({
        closable: true,
        title: 'Inconsistências',
        autoWidth: true,
        text,
      });
    } catch (err: any) {
      if (err.response?.data?.message) {
        Notiflix.Notify.info(err.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar o relatório no servidor. Por favor, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('notiflix-button');
    }
  }, []);

  const [rowExpanded, setRowExpanded] = useState([] as TableRow[]);

  const templateRowExpanded = useCallback(
    (rowData: TableRow) => {
      return (
        <ActionsContainer className="spaceBetween">
          <Button
            sausageLook
            whiteButton
            autoWidth
            onClick={() => handleOpenReport(rowData)}
            className={`notiflix-report-${rowData.requestId} marginRight`}
          >
            Relatório
          </Button>
          {rowData.status === 'Aguardando liberação' && (
            <Button
              sausageLook
              whiteButton
              autoWidth
              onClick={() => handleRelease(rowData)}
            >
              Liberar
            </Button>
          )}
          {rowData.status === 'Aguardando análise Operadora' && (
            <Button
              sausageLook
              whiteButton
              autoWidth
              onClick={() => handleUndoRelease(rowData)}
            >
              Desfazer liberação
            </Button>
          )}
          {rowData.status === 'Solicitação com inconsistência' && (
            <Button
              sausageLook
              whiteButton
              autoWidth
              onClick={() => handleOpenInconsistency(rowData.requestId)}
            >
              Inconsistências
            </Button>
          )}
          {rowData.status !==
            'Aguardando preenchimento da Declaração de Saúde' && (
            <>
              {rowData.allowEdit === 'S' && (
                <Button
                  sausageLook
                  whiteButton
                  autoWidth
                  onClick={() => handleOpenDetailAndEdit(rowData)}
                  className={`notiflix-reject-${rowData.requestId}`}
                >
                  Editar
                </Button>
              )}
            </>
          )}
        </ActionsContainer>
      );
    },
    [
      handleOpenDetailAndEdit,
      handleOpenReport,
      handleRelease,
      handleOpenInconsistency,
    ],
  );

  const handleSubmitEditRequest = useCallback(
    async (data, files) => {
      Notiflix.Block.circle('.notiflix-save-beneficiary');
      try {
        const cityTitle = data.city;
        const NOT_MAPPED = '';
        const params = {
          voterTitleNumber: NOT_MAPPED,
          adoptionDate: NOT_MAPPED,
          brazilianstatesWhichIssuedCtps: NOT_MAPPED,
          weddingDate: NOT_MAPPED,
          shortName: NOT_MAPPED,
          marriageCertificateNumber: NOT_MAPPED,
          numberPisPasep: NOT_MAPPED,
          portabilityProtocolNumber: NOT_MAPPED,
          numberCtps: NOT_MAPPED,
          numberSeriesCtps: NOT_MAPPED,
          requestId,

          requestHolderId: data?.requestHolderId,
          nameRequestHolder: data?.nameRequestHolder,
          previousRequestId: data?.previousRequestId,

          contractId: contract.contractId,
          beneficiaryName: data.beneficiaryName,
          email: data.email,
          birthDate: data.birthDate,
          hiringDate: data.hiringDate,
          ddiPhone: data.DDIPhone,
          dddPhone: data.phone?.slice(0, 2),
          phone: data.phone?.slice(2),
          birthCityCode: data.cityOfBirth,
          initialsBirthState: data.stateOfBirth,
          cpf: data.cpf,
          rg: data.rg,
          issuingBody: data.rgAgencyIssuer,
          brazilianstatesWhichIssuedRg: data.rgIssuerState,
          emissionDate: data.rgIssuerDate,
          issuingAgencyCountry: data.rgIssuerCountry,
          maritalStatus: data.maritalStatus,
          physicalSex: data.phisicalSex,
          motherName: data.motherName,
          fatherName: data.fatherName,
          motiveIncludeId: data.includeReason,
          initialsState: data.state,
          birthCertificateCodeAlive: data.declarationOfLive,
          neighborhood: data.neighborhood,
          ddiNumberCellphone: data.DDICellphone,
          dddCellPhone: data.cellphone?.slice(0, 2),
          cellphone: data.cellphone?.slice(2),
          zipCode: data.zipCode,
          nationalityCode: data.nationality,
          address: data.address,
          complement: data.complement,
          addressNumber: data.addressNumber,
          nationalSusCardNumber: data.susCard,
          socialName: data.socialName,
          admissionDate: data.holderHiringDate,
          addressTypePublicPlace: data.placeType,
          ibgeCityCode: cityTitle,
          planId: data.product,
          observation: data.observation,
          descriptionCountyBirthForeign: data.foreignBirthCity,
          foreignRgId: data.rgForeign,
          registrationCode: data.registrationCode,
          datePlanAgreementOrigin: data.contractDateOfOriginPlan,
          sourcePlanPriceValue: data.priceOfOriginPlan,
          ansGuideProtocolNumber: data.ANSGuideProtocolNumber,
          protocolIssueDate: data.issueProtocolDate,
          descriptionOperatorOrigin: data.originOperator,
          codeCoveragePlanOrigin: data.coverageGeographicalOriginalPlan,
          sourcePlanRegistrationCode: data.registerOfOriginalProduct,
          typePlanContractOrigin: data.typeOfContract,
          // relationshipCode: data.relationship,
        };
        const formData = new FormData();
        Object.entries(params).forEach((ar) => {
          formData.append(ar[0], ar[1]);
        });
        Object.keys(files).forEach((key, index) => {
          if (files[key]) {
            formData.append(`changeFile[${index}]`, files[key]);
          }
        });
        const { data: dataApi } = await api.post(
          `/beneficiary-inclusion/save-details-changes`,
          formData,
        );
        // Notiflix.Block.remove('.notiflix-save-beneficiary');
        const { message } = dataApi;
        Notiflix.Notify.success(message);

        handleOpenInconsistency(requestId);
        return requestId;
      } catch (err) {
        Notiflix.Block.remove('.notiflix-save-beneficiary');
        if (axios.isAxiosError(err) && err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos salvar sua requisição no servidor. Por favor, tente novamente mais tarde.',
          );
        }
        return null;
      } finally {
        Notiflix.Block.remove('.notiflix-save-beneficiary');
      }
    },
    [contract, requestId],
  );

  const handleSubmitNewRequest = useCallback(
    async (data, files) => {
      const NOT_MAPPED = '';
      try {
        const cityTitle = data.city;
        Notiflix.Block.circle('.notiflix-save-beneficiary');
        const params = {
          voterTitleNumber: NOT_MAPPED,
          adoptionDate: NOT_MAPPED,
          brazilianstatesWhichIssuedCtps: NOT_MAPPED,
          weddingDate: NOT_MAPPED,
          shortName: NOT_MAPPED,
          marriageCertificateNumber: NOT_MAPPED,
          numberPisPasep: NOT_MAPPED,
          portabilityProtocolNumber: NOT_MAPPED,
          numberCtps: NOT_MAPPED,
          numberSeriesCtps: NOT_MAPPED,

          contractId: contract.contractId,
          beneficiaryName: data.beneficiaryName,
          email: data.email,
          birthDate: data.birthDate,
          hiringDate: data.hiringDate,
          ddiPhone: data.DDIPhone,
          dddPhone: data.phone?.slice(0, 2),
          phone: data.phone?.slice(2),
          birthCityCode: data.cityOfBirth,
          initialsBirthState: data.stateOfBirth,
          cpf: data.cpf,
          rg: data.rg,
          issuingBody: data.rgAgencyIssuer,
          brazilianstatesWhichIssuedRg: data.rgIssuerState,
          emissionDate: data.rgIssuerDate,
          issuingAgencyCountry: data.rgIssuerCountry,
          maritalStatus: data.maritalStatus,
          physicalSex: data.phisicalSex,
          motherName: data.motherName,
          fatherName: data.fatherName,
          motiveIncludeId: data.includeReason,
          initialsState: data.state,
          birthCertificateCodeAlive: data.declarationOfLive,
          neighborhood: data.neighborhood,
          ddiNumberCellphone: data.DDICellphone,
          dddCellPhone: data.cellphone?.slice(0, 2),
          cellphone: data.cellphone?.slice(2),
          zipCode: data.zipCode,
          nationalityCode: data.nationality,
          address: data.address,
          complement: data.complement,
          addressNumber: data.addressNumber,
          nationalSusCardNumber: data.susCard,
          socialName: data.socialName,
          admissionDate: data.holderHiringDate,
          addressTypePublicPlace: data.placeType,
          ibgeCityCode: cityTitle,
          planId: data.product,
          observation: data.observation,
          descriptionCountyBirthForeign: data.foreignBirthCity,
          foreignRgId: data.rgForeign,
          registrationCode: data.registrationCode,
          datePlanAgreementOrigin: data.contractDateOfOriginPlan,
          sourcePlanPriceValue: data.priceOfOriginPlan,
          ansGuideProtocolNumber: data.ANSGuideProtocolNumber,
          protocolIssueDate: data.issueProtocolDate,
          descriptionOperatorOrigin: data.originOperator,
          codeCoveragePlanOrigin: data.coverageGeographicalOriginalPlan,
          sourcePlanRegistrationCode: data.registerOfOriginalProduct,
          typePlanContractOrigin: data.typeOfContract,
          // relationshipCode: data.relationship,
        };
        const formData = new FormData();
        Object.entries(params).forEach((ar) => {
          formData.append(ar[0], ar[1]);
        });
        Object.keys(files).forEach((key, index) => {
          if (files[key]) {
            formData.append(`beneficiaryFile[${index}]`, files[key]);
          }
        });
        const { data: dataApi } = await api.post(
          `/beneficiary-inclusion/include-beneficiary`,
          formData,
        );
        // Notiflix.Block.remove('.notiflix-save-beneficiary');
        const { message, data: data2 } = dataApi;
        Notiflix.Notify.success(message);
        // Modal2.Success({
        //   closable: true,
        //   autoWidth: true,
        //   title: 'Perfeito!',
        //   subtitle: message,
        //   text: 'Deseja continuar editando e/ou adicionar um dependente?',
        //   children: (
        //     <Button
        //       modal
        //       onClick={() => {
        //         Modal2.Close();
        //       }}
        //     >
        //       Ok
        //     </Button>
        //   ),
        // });
        setRequestIdForEdit(data2[0].requestId);
        setDependentParams((prev) => ({
          ...prev,
          contractId: contract.contractId,
          userUnimedId: data2[0].requestId,
          webUsername: data2[0].userNumber,
        }));
        setShowDependentIncludeButton(true);
        handleOpenInconsistency(data2[0].requestId);
        return data2[0].requestId;
      } catch (err) {
        Notiflix.Block.remove('.notiflix-save-beneficiary');
        if (axios.isAxiosError(err) && err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos salvar sua requisição no servidor. Por favor, tente novamente mais tarde.',
          );
        }
        return null;
      } finally {
        Notiflix.Block.remove('.notiflix-save-beneficiary');
      }
    },
    [contract],
  );

  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <Container>
        <Title themeColor={theme.colors.palet.institutional8}>
          Inclusão de Beneficiário
        </Title>
        {!showForm.editMode && !showForm.newMode && (
          <>
            <Table
              items={dataTable}
              className="notiflix-table-loading"
              rowExpansionTemplate={templateRowExpanded}
              onRowToggle={(e) => setRowExpanded([e.data[e.data.length - 1]])}
              expandedRows={rowExpanded}
              onRowExpand={(e) =>
                window.innerWidth <= 768 &&
                setPositionOfRowExpanded(e.originalEvent)
              }
              onRowClick={(e) => {
                if (
                  rowExpanded.length > 0 &&
                  rowExpanded[0]?.requestId !== e.data?.requestId
                ) {
                  setRowExpanded([]);
                  return setRowExpanded([e.data]);
                }
                return rowExpanded.length === 0
                  ? setRowExpanded([e.data])
                  : setRowExpanded([]);
              }}
            >
              <Column
                field="requestId"
                header="Solicitação"
                sortable
                body={(data) => AddField(data.requestId, 'Solicitação')}
              />
              <Column
                field="beneficiaryName"
                header="Beneficiário"
                sortable
                body={(data) => AddField(data.beneficiaryName, 'Beneficiário')}
              />
              <Column
                field="holderName"
                header="Nome do Titular"
                sortable
                body={(data) => AddField(data.holderName, 'Nome do Titular')}
              />
              <Column
                field="solicitationDate"
                header="Data de Solicitação"
                className="date"
                body={(data) =>
                  AddField(data.solicitationDate, 'Data de Solicitação')
                }
              />
              <Column
                field="solicitationDate"
                header="Data de Liberação"
                className="date"
                body={(data) =>
                  AddField(data.releaseDate, 'Data de Solicitação')
                }
              />
              <Column
                field="status"
                header="Status"
                className="tag"
                sortable
                body={(data) => {
                  switch (data.status) {
                    case 'Aguardando análise da Operadora':
                    case 'Aguardando análise Operadora':
                      return getTag(
                        'Status',
                        'Aguard. Análi. Operadora',
                        'orange',
                        data.status,
                      );
                    case 'Aguardando liberação':
                      return getTag(
                        'Status',
                        'Aguard. Liberação',
                        'orange',
                        data.status,
                      );
                    case 'Aguardando preenchimento da Declaração de Saúde':
                      return getTag(
                        'Status',
                        'Aguard. Preench. Declar. Saúde',
                        'orange',
                        data.status,
                      );
                    case 'Contratação concluída':
                      return getTag(
                        'Status',
                        'Contrat. Cocluída',
                        'green',
                        data.status,
                      );
                    case 'Em processo de finalização':
                      return getTag(
                        'Status',
                        'Em Proces. Finalização',
                        'orange',
                        data.status,
                      );
                    case 'Solicitação com inconsistência':
                      return getTag(
                        'Status',
                        'Solic. c/ Inconsistência',
                        'orange',
                        data.status,
                      );
                    case 'Solicitação em andamento':
                      return getTag(
                        'Status',
                        'Solic. Em Andamento',
                        'orange',
                        data.status,
                      );
                    case 'Solicitação rejeitada pela operadora':
                      return getTag(
                        'Status',
                        'Solic. Rejeitata',
                        'red',
                        data.status,
                      );
                    case 'Aguardando Análise da Declaração de Saúde':
                      return getTag(
                        'Status',
                        'Aguard. Análi. Declar. Saúde',
                        'orange',
                        data.status,
                      );
                    case 'Solicitação cancelada':
                      return getTag(
                        'Status',
                        'Solicitação cancelada',
                        'gray',
                        data.status,
                      );
                    default:
                      return <>{data.status}</>;
                  }
                }}
              />
              <Column expander headerStyle={{ width: '40px' }} />
            </Table>
            <FormWrapper
              formLook
              onSubmit={() =>
                setShowForm({
                  contractId: contract.contractId,
                  editMode: false,
                  newMode: true,
                  isOwner: true,
                })
              }
              className="actions-include-beneficiary"
            >
              <Button type="submit" grayButton autoWidth formLook>
                Novo
              </Button>
            </FormWrapper>
          </>
        )}
        {showForm.newMode && (
          <Form
            callbackSubmit={
              !requestId ? handleSubmitNewRequest : handleSubmitEditRequest
            }
            contractId={showForm.contractId}
            isOwner
            callbackButtonGoBack={() => {
              setShowForm({
                contractId: '',
                editMode: false,
                newMode: false,
                isOwner: false,
              });
              setShowDependentIncludeButton(false);
              setRequestIdForEdit('');
              getDataToTable();
            }}
            showDependentIncludeButton={showDependentIncludeButton}
            dependentParams={dependentParams}
            requestHolderId={requestId}
          />
        )}
        {showForm.editMode && (
          <Form
            callbackSubmit={handleSubmitEditRequest}
            contractId={showForm.contractId}
            // showReleaseButton={requestId}
            showDependentIncludeButton={showForm.isOwner}
            isOwner={showForm.isOwner}
            preloadOfRequestId={requestId}
            callbackButtonGoBack={() => {
              setShowForm({
                contractId: '',
                editMode: false,
                newMode: false,
                isOwner: false,
              });
              setShowDependentIncludeButton(false);
              setRequestIdForEdit('');
              getDataToTable();
            }}
            requestHolderId={requestId}
            dependentParams={dependentParams}
          />
        )}
      </Container>
    </PageWrapper>
  );
};

export default BeneficiaryInclude;
