/* eslint-disable max-len */
import styled from 'styled-components';
import DropdownElement from '../../components/Dropdown';
import FormWrapper from '../../components/FormWrapper';

export const Title = styled.h1`
  grid-column: 2/10;
  margin: 30px 0;
  font-family: 'Unimed-Slab-Bold';
  text-align: center;
  font-size: 2.55rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional};
  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

export const Form = styled(FormWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 30px;
  > div {
    grid-column: 1/3;
  }
  > button {
    grid-column: 4;
    @media (min-width: 769px) {
      margin-left: auto;
    }
  }
`;

export const Container = styled.div`
  grid-column: 2/10;
  grid-template-columns: repeat(10, 1fr);
  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

export const TableContainer = styled.div`
  .positionRight {
    text-align: right !important;
    padding-right: 1vw !important;
  }

  #authorization .p-datatable-wrapper > table {
    /* width: calc(100% - 16px);
    margin-left: 8px; */
    tbody {
      tr {
        &.p-row-expanded {
          & > td > div {
            margin: 0 10px;
            & > div > div.p-datatable-footer {
              display: none;
            }
          }
        }
      }
    }
  }

  #authorization .p-datatable-wrapper table tbody .p-row-expanded {
    background: transparent;
    padding: 24px;
    @media (max-width: 768px) {
      background: ${(props) => props.theme.colors.palet.institutional11};
    }

    tr[role='row'] {
      padding: 0px;
      max-width: 100%;
    }

    & > td {
      padding: 0;

      &
        .p-datatable
        .p-datatable-wrapper
        table
        thead.p-datatable-thead
        tr
        th:first-child {
        border-radius: 0;
      }
      &
        .p-datatable
        .p-datatable-wrapper
        table
        thead.p-datatable-thead
        tr
        th:last-child {
        border-radius: 0;
      }
    }
  }

  #authorization .p-datatable-wrapper table tbody tr td.detalhe {
    button {
      text-align: center;
      width: 1rem;
      height: 1rem;
      background-image: url("data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.5 7C13.5 10.59 10.59 13.5 7 13.5C3.41004 13.5 0.5 10.59 0.5 7C0.5 3.41004 3.41004 0.5 7 0.5C10.59 0.5 13.5 3.41004 13.5 7Z' stroke='%235B5C65' strokeLinecap='round' strokeLinejoin='round' /%3E%3Cpath d='M7 4.20117V9.80117' stroke='%235B5C65' strokeLinecap='round' strokeLinejoin='round' /%3E%3Cpath d='M4.2002 7H9.80019' stroke='%235B5C65' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E");
      background-size: cover;
      background-repeat: no-repeat;

      &[aria-expanded='true'] {
        background-image: url("data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.5 7C13.5 10.59 10.59 13.5 7 13.5C3.41004 13.5 0.5 10.59 0.5 7C0.5 3.41004 3.41004 0.5 7 0.5C10.59 0.5 13.5 3.41004 13.5 7Z' stroke='%235B5C65' strokeLinecap='round' strokeLinejoin='round' /%3E%3Cpath d='M4.2002 7H9.80019' stroke='%235B5C65' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E");
      }
      &:focus {
        box-shadow: none !important;
      }
    }
    @media (max-width: 768px) {
      width: 40px;
      text-align: right;
      position: absolute;
      z-index: 1;
      padding: 0;
      min-width: auto;
      height: 100%;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${(props) => props.theme.colors.palet.institutional12};
      border-radius: 10px 0 0 10px;
      > button {
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.5 7C13.5 10.59 10.59 13.5 7 13.5C3.41004 13.5 0.5 10.59 0.5 7C0.5 3.41004 3.41004 0.5 7 0.5C10.59 0.5 13.5 3.41004 13.5 7Z' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round' /%3E%3Cpath d='M7 4.20117V9.80117' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round' /%3E%3Cpath d='M4.2002 7H9.80019' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E");

        &[aria-expanded='true'] {
          background-image: url("data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.5 7C13.5 10.59 10.59 13.5 7 13.5C3.41004 13.5 0.5 10.59 0.5 7C0.5 3.41004 3.41004 0.5 7 0.5C10.59 0.5 13.5 3.41004 13.5 7Z' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round' /%3E%3Cpath d='M4.2002 7H9.80019' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E");
        }
      }
    }
  }
`;

export const DocumentsContainer = styled.div`
  background-color: transparent;
  grid-column: 2/10;

  @media (max-width: 768px) {
    grid-column: 1;
    margin: 0 30px 30px;
  }
`;

export const Dropdown = styled(DropdownElement)`
  background: ${(props) => props.theme.colors.palet.institutional11};
  border-color: ${(props) => props.theme.colors.palet.institutional11};
  box-shadow: 0px 5px 20px rgb(196 203 207 / 40%);

  overflow: hidden;
  padding: 0;
  > div.open {
    margin: 16px 24px;
  }
  > button {
    background: ${(props) => props.theme.colors.palet.institutional11};
    padding: 10px;
    box-shadow: 0 0 10px;
    > svg {
      color: ${(props) => props.theme.colors.text.white};
    }
  }
  background: ${(props) => props.theme.colors.background};
`;
