import React, { useCallback, useRef, useState } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Notiflix from 'notiflix';
import Input from '../../components/Input';

import {
  Container,
  Background,
  Content,
  BemVindo,
  BemVindoMobile,
  ContentMobile,
  ButtonGoBack,
  ButtonSubmit,
  ContainerLogoW,
  ContentButton,
} from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import { GrupowSvg, LogoUnimedSvg } from '../../components/Svg';
import Modal2 from '../../components/Modal2';
import Button from '../../components/Button';
import { getParams } from '../../utils/Url';
import api from '../../services/api/api';
import PasswordList from '../../components/PasswordList';

interface FormNewPassword {
  newPassword: string;
  confirmNewPassword: string;
}

const ChangeMyPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const [allValid, setAllValid] = useState(false);
  const { hash } = getParams();

  const handleFormSubmit = useCallback(
    async (data: FormNewPassword) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          newPassword: Yup.string()
            .required('Senha obrigatória.')
            .min(6, 'Mínimo 6 caracteres'),
          confirmNewPassword: Yup.string().oneOf(
            [Yup.ref('newPassword'), null],
            'Suas senhas não coincidem',
          ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const { newPassword } = data;
        await api.put('/user/change-password-when-forgot-your-password', {
          uid: hash,
          newPassword,
        });

        Modal2.Success({
          closable: false,
          subtitle: 'Perfeito!',
          text: 'Sua senha foi alterada, vamos para a pagina de login?!',
          children: (
            <Button
              modal
              onClick={() => {
                history.push('/login');
                Modal2.Close();
              }}
            >
              Ok
            </Button>
          ),
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          Notiflix.Notify.failure(
            'Ops, algo deu errado. Por favor tente novamente mais tarde.',
          );
        }
      }
    },
    [hash, history],
  );

  return (
    <Container>
      <Background>
        <BemVindo>Nova Senha</BemVindo>
        <LogoUnimedSvg />
        <ContainerLogoW>
          <a
            href="https://www.grupow.com.br/"
            title="GrupoW Softwares para Internet"
            target="_blanck"
          >
            Desenvolvido por <strong>GrupoW Softwares para Internet</strong>
            <GrupowSvg />
          </a>
        </ContainerLogoW>
      </Background>
      <Content>
        <Form ref={formRef} onSubmit={handleFormSubmit}>
          <ContentMobile>
            <h2>Digite a sua nova senha</h2>
            <LogoUnimedSvg />
            <BemVindoMobile>
              Digite a sua <br />
              nova senha
            </BemVindoMobile>

            <Input
              name="newPassword"
              id="newPassword"
              placeholder="Nova senha"
              type="password"
            />
            <Input
              type="password"
              name="confirmNewPassword"
              placeholder="Confirme a sua nova senha"
            />
            <div className="buttonsMobile">
              <PasswordList query="newPassword" validation={setAllValid} />
              <ContentButton>
                <ButtonGoBack
                  onClick={() => history.push('/login')}
                  type="button"
                >
                  Voltar
                </ButtonGoBack>
                <ButtonSubmit disabled={!allValid} type="submit">
                  Salvar
                </ButtonSubmit>
              </ContentButton>
            </div>
          </ContentMobile>
        </Form>
        <ContainerLogoW className="mobile">
          <a
            href="https://www.grupow.com.br/"
            title="GrupoW Softwares para Internet"
            target="_blanck"
          >
            Desenvolvido por <strong>GrupoW Softwares para Internet</strong>
            <GrupowSvg />
          </a>
        </ContainerLogoW>
      </Content>
    </Container>
  );
};

export default ChangeMyPassword;
